import React from 'react';

import { Box } from '@rbilabs/universal-components';
import { GestureResponderEvent } from 'react-native';

import { IBaseProps } from '@rbi-ctg/frontend';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { CustomEventNames } from 'state/mParticle';

interface IAuthActionsProps extends IBaseProps {
  eventName?: CustomEventNames;
  primaryActionDisabled?: boolean;
  primaryActionIsLoading?: boolean;
  primaryActionLabel: string;
  primaryActionQaId?: string;
  onPress?: (ev?: GestureResponderEvent) => void | Promise<void>;
}

export const AuthLinkAction = ({
  primaryActionDisabled = false,
  primaryActionIsLoading = false,
  primaryActionLabel,
  primaryActionQaId,
  onPress,
}: IAuthActionsProps) => (
  <ActionButton
    variant={ActionButtonVariants.TEXT_ONLY}
    testID={primaryActionQaId}
    isLoading={primaryActionIsLoading}
    disabled={primaryActionDisabled}
    onPress={onPress}
  >
    {primaryActionLabel}
  </ActionButton>
);

const AuthActions = ({
  eventName,
  primaryActionDisabled = false,
  primaryActionIsLoading = false,
  primaryActionLabel,
  primaryActionQaId,
  onPress,
}: IAuthActionsProps) => (
  <Box mt="$4">
    <ActionButton
      fullWidth
      onPress={onPress}
      eventName={eventName}
      testID={primaryActionQaId}
      isLoading={primaryActionIsLoading}
      disabled={primaryActionDisabled}
    >
      {primaryActionLabel}
    </ActionButton>
  </Box>
);

export default React.memo(AuthActions);
