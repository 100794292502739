import { useCallback, useEffect, useState } from 'react';

import { OperationStatus, useLatestPaymentTransactionQuery } from 'generated/graphql-gateway';

import { MAP_ISOs_TO_ISO_COUNTRY_CODE } from '../../../../../utils/form/constants';
import { useLocale } from '../../../../intl';

import {
  IUseShortCodePaymentResultPollStatus,
  IUseShortCodePaymentResultPollStatusParams,
  ShortCodePaymentResultPollingStatus,
} from './types';

export const useShortCodePaymentResultPollStatus = ({
  loyaltyId,
  skipPolling,
  pollInterval,
  currentDateTime,
}: IUseShortCodePaymentResultPollStatusParams): IUseShortCodePaymentResultPollStatus => {
  const [pollingStatus, setPollingStatus] = useState<ShortCodePaymentResultPollingStatus>(
    ShortCodePaymentResultPollingStatus.None
  );

  const [paymentResult, setPaymentResult] = useState<OperationStatus>(OperationStatus.REQUESTED);

  const resetPollingPaymentResultStatus = useCallback(() => {
    setPollingStatus(ShortCodePaymentResultPollingStatus.None);
  }, []);

  const { feCountryCode } = useLocale();

  // setting pending code state
  useEffect(() => {
    if (!skipPolling) {
      setPollingStatus(ShortCodePaymentResultPollingStatus.Pending);
    }
  }, [skipPolling]);

  const skip =
    skipPolling || !loyaltyId || pollingStatus === ShortCodePaymentResultPollingStatus.None;
  // short code polling
  const { error, startPolling, stopPolling } = useLatestPaymentTransactionQuery({
    variables: {
      feCountryCode: MAP_ISOs_TO_ISO_COUNTRY_CODE[feCountryCode],
    },
    fetchPolicy: 'no-cache',
    skip,
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      if (
        data.latestPaymentTransaction &&
        data.latestPaymentTransaction.status !== OperationStatus.REQUESTED &&
        new Date(data.latestPaymentTransaction.createdAt) >= currentDateTime
      ) {
        setPaymentResult(data.latestPaymentTransaction.status);
        setPollingStatus(ShortCodePaymentResultPollingStatus.Completed);
      }
    },
  });

  useEffect(() => {
    // skip is going to be true, if the polling status is 'pending' after 5 minutes
    // for example when the user is in a different screen
    if (skip || pollingStatus === ShortCodePaymentResultPollingStatus.Completed) {
      stopPolling();
    } else {
      startPolling(pollInterval);
    }
  }, [pollInterval, skip, pollingStatus, startPolling, stopPolling]);

  useEffect(() => {
    setPollingStatus(ShortCodePaymentResultPollingStatus.NotFound);
  }, [error]);

  return {
    pollingShortCodePaymentResultStatus: pollingStatus,
    resetPollingPaymentResultStatus,
    paymentResult,
  };
};
