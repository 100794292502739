import { VFC, useCallback, useEffect, useRef, useState } from 'react';

import { LayoutChangeEvent, Platform } from 'react-native';
import Carousel from 'react-native-snap-carousel';

import { addWithConfig } from '../../utils';
import { Box } from '../box';
import { HStack } from '../h-stack';
import { Header } from '../header';
import { Icon } from '../icon';
import { Pressable } from '../pressable';
import { Text } from '../text';

import { CarouselSkeletonItem } from './CarouselSkeleton';
import type { ICarouselProps } from './types';

const SKELETON_ITEMS = [null, null, null, null, null] as const;

const defaultSkeletonItem = () => <CarouselSkeletonItem />;

// eslint-disable-next-line complexity
export const ResponsiveCarousel: VFC<ICarouselProps<unknown>> = ({
  header,
  linkText,
  linkAction,
  data: propData,
  renderItem: propRenderItem,
  keyExtractor,
  itemWidth,
  noBGColor = false,
  isLoading,
  customSkeletonItem,
  ...boxProps
}) => {
  const [containerWidth, setContainerWidth] = useState<number>(1);
  const carouselRef = useRef<Carousel<any>>(null);
  const skeletonItem = customSkeletonItem ?? defaultSkeletonItem;
  const data = isLoading && !propData?.length ? SKELETON_ITEMS : propData ?? SKELETON_ITEMS;
  const renderItem = propRenderItem ?? defaultSkeletonItem;

  // When the number of cards change, reset current page
  useEffect(() => {
    carouselRef?.current?.snapToItem(0);
  }, []);

  const updateWidth = useCallback(({ nativeEvent }: LayoutChangeEvent) => {
    setContainerWidth(nativeEvent.layout.width);
  }, []);

  // react-native-snap-carousel is only available for web
  if (Platform.OS === 'web') {
    return null;
  }

  return (
    <Box
      bg={noBGColor ? 'transparent' : 'token.background-pattern'}
      w="full"
      maxW="1050px"
      py="$6"
      mx="auto"
      mb="$5"
      pl="$4"
      onLayout={updateWidth}
      {...boxProps}
    >
      {header && (
        <HStack mr="$4" justifyContent="space-between" alignItems="baseline">
          <Header accessibilityLabel="2" accessibilityHint="Carousel title">
            {header}
          </Header>
          {linkText && linkAction && (
            <Pressable onPress={linkAction} accessibilityRole="link" flexDir="row">
              <Text mr="$2">{linkText}</Text>
              <Icon
                variant="forward"
                size="$5"
                importantForAccessibility="no-hide-descendants"
                accessibilityElementsHidden
              />
            </Pressable>
          )}
        </HStack>
      )}
      <Carousel
        data={data as any[]}
        // @ts-ignore
        renderItem={isLoading ? skeletonItem : renderItem}
        keyExtractor={keyExtractor}
        ref={carouselRef}
        itemWidth={itemWidth || 164}
        sliderWidth={containerWidth}
        // the following props are not supposed to be changed
        // this is a design a decision in order to maintain carousel consistent
        activeSlideAlignment="start"
        inactiveSlideScale={1}
        inactiveSlideOpacity={0.7}
        loop={false}
      />
    </Box>
  );
};

export default addWithConfig(ResponsiveCarousel);
