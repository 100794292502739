import React, { FC } from 'react';

import { InputMessageText } from './input-message.styled';
import { IInputMessageProps } from './types';

/**
 * Show error or hint messages for form field components.
 */
const InputMessage: FC<React.PropsWithChildren<IInputMessageProps>> = ({
  id,
  hasError,
  disabled,
  children,
  disableScreenReaderAlert,
  testID,
}) => {
  if (!children) {
    return null;
  }

  const props = {
    id,
    $hasError: hasError,
    $disabled: disabled,
    testID: testID ?? 'input-message',
  };

  return (
    <InputMessageText
      {...props}
      variant="formUtility"
      accessibilityRole={hasError && !disableScreenReaderAlert ? 'alert' : undefined}
    >
      {children}
    </InputMessageText>
  );
};

export default InputMessage;
