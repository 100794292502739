import { region } from 'utils/environment';

// @ts-ignore - this isn't always available on CI so ignore it
import { sanityAssets as generatedSanityAssets } from './assets';

type SanityAssets = {
  current: Record<string, any>;
};

// TODO rework this once we consolidate BK + Frontend
export const sanityAssets: SanityAssets = { current: generatedSanityAssets?.[region] };
