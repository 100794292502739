import { IComboSlotOption, IPrices } from '@rbi-ctg/menu';
import { IWithVendorConfig, PosVendors } from 'utils/vendor-config';

import { pricingFunctionNoSelections } from './pricing-function-no-selections';

export const priceComboSlotDelta = (
  defaultComboSlotOption: IComboSlotOption | undefined,
  selectedItem: IWithVendorConfig,
  prices: IPrices,
  vendor: PosVendors | null
) => {
  // get price of default option
  const defaultPrice = defaultComboSlotOption
    ? pricingFunctionNoSelections(defaultComboSlotOption?.option, prices, vendor)
    : 0;
  // subtract the price of the default from the premiumOption
  const delta = pricingFunctionNoSelections(selectedItem, prices, vendor) - defaultPrice;
  // If delta is negative the price is zero
  return delta > 0 ? delta : 0;
};
