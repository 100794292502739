import React, { Suspense, memo, useEffect, useState } from 'react';

import { View } from '@rbilabs/universal-components';
import { isNil } from 'lodash-es';
import { useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';

import { SetState } from '@rbi-ctg/frontend';
import { AddPaymentMethodModal } from 'components/add-payment-method-modal';
import { AddPaymentMethodModalRedesign } from 'components/add-payment-method-modal-redesign';
import { AddGiftCardModal, TransferGiftCardModal } from 'components/gift-card-modal';
import { SuccessGiftCardModal } from 'components/gift-card-modal/success-gift-card';
import { SuccessTransferGiftCardModal } from 'components/gift-card-modal/success-transfer-gift-card';
import { LayoutFabButton } from 'components/layout/layout-fab-button';
import useLogPageView from 'components/layout/use-log-page-view';
import { ModalAuth } from 'components/modal-auth';
import { QuestDetailsPage } from 'components/quests/quest-details-page';
import { QuestLibrary } from 'components/quests/quests-library';
import { RemoveUnavailableItemsModal } from 'components/remove-unavailable-items-modal';
import { TopServiceModeNotification } from 'components/top-service-mode-notification';
import { CurbsideConfirmationModal } from 'components/top-service-mode/curbside-confirmation-modal';
import { VisuallyHidden } from 'components/ucl';
import { useContentCardsNotificationsExperiment } from 'experiments/use-content-cards-notifications';
import { usePaymentCheckoutRedesignExperiment } from 'experiments/use-payment-checkout-redesign-experiment';
import { useEffectOnUrlChange } from 'hooks/use-effect-on-url-change';
import { NotificationCenter } from 'pages/account/notification-center';
import { DialogStaticPage } from 'pages/authentication/components/dialog-static-page';
import { AddGiftCardRedesign } from 'pages/cart/payment/order-payment/add-gift-card-redesign/add-gift-card-redesign';
import { TransferGiftCardRedesign } from 'pages/cart/payment/order-payment/transfer-gift-card-redesign/transfer-gift-card-redesign';
import { LoyaltyOfferDetail } from 'pages/loyalty/loyalty-offers/loyalty-offer-detail';
import { OffersGroupsDetails } from 'pages/loyalty/loyalty-offers/loyalty-offers-groups-details';
import LoyaltyOptInModal from 'pages/loyalty/loyalty-opt-in-modal';
import { WalmartLandingPage } from 'pages/loyalty/loyalty-partners';
import { WalmartLocation } from 'pages/loyalty/loyalty-partners/walmart-location';
import { WalmartOffer } from 'pages/loyalty/loyalty-partners/walmart-offer';
import { WalmartRedirect } from 'pages/loyalty/loyalty-partners/walmart-redirect';
import { useAuthContext } from 'state/auth';
import { ModalAuthTrigger } from 'state/auth/types';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { Route, Routes } from 'state/location';
import { useLoyaltyContext } from 'state/loyalty';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { OnboardingContent } from 'state/onboarding';
import { useOrderContext } from 'state/order';
import { usePaymentContext } from 'state/payment';
import { useScrollContext } from 'state/scroll';
import { useUIContext } from 'state/ui';
import { getAllLocalizedVersionsOfRoute, routes } from 'utils/routing';

import brandRoutes from './brand-routes';
import { HelmetHTML } from './helmet-html';
import { ScrollContainer } from './scroll-container';
import { LazyRoute, lazyWithFallback } from './util';

const Cart: LazyRoute = lazyWithFallback(() => import('pages/cart'));
const Home: LazyRoute = lazyWithFallback(() => import('pages/home'));
const Account: LazyRoute = lazyWithFallback(() => import('pages/account'));
const MenuPage: LazyRoute = lazyWithFallback(() => import('pages/menu'));
const CommPreferences: LazyRoute = lazyWithFallback(() =>
  import('pages/account/comm-preferences-v2')
);
const AccountInfo: LazyRoute = lazyWithFallback(() => import('pages/account/account-info'));
const PaymentMethods: LazyRoute = lazyWithFallback(() => import('pages/account/payment-methods'));
const Diagnostics: LazyRoute = lazyWithFallback(() => import('pages/diagnostics'));
const AccountOrders: LazyRoute = lazyWithFallback(() => import('pages/account/account-orders'));
const Offers: LazyRoute = lazyWithFallback(() => import('pages/offers'));
const LoyaltyOffers: LazyRoute = lazyWithFallback(() => import('pages/loyalty/loyalty-offers'));
const LoyaltyRewards: LazyRoute = lazyWithFallback(() => import('pages/loyalty/loyalty-rewards'));
const LoyaltyDashboard: LazyRoute = lazyWithFallback(() =>
  import('pages/loyalty/loyalty-dashboard')
);
const LoyaltyInRestaurantRedemption: LazyRoute = lazyWithFallback(() =>
  import('pages/loyalty/loyalty-in-restaurant-redemption')
);
const LoyaltyClaimPoints: LazyRoute = lazyWithFallback(() =>
  import('pages/loyalty/loyalty-claim-points')
);
const SignupHome: LazyRoute = lazyWithFallback(() => import('pages/authentication/sign-up-home'));

const AuthChallengeScreenJwt: LazyRoute = lazyWithFallback(() =>
  import('pages/authentication/auth-email-jwt')
);
const ConfirmJwt: LazyRoute = lazyWithFallback(() => import('pages/authentication/confirm-jwt'));
const SignOut: LazyRoute = lazyWithFallback(() => import('pages/authentication/sign-out'));
const StoreLocator: LazyRoute = lazyWithFallback(() => import('pages/store-locator'));

const OrderConfirmation: LazyRoute = lazyWithFallback(() =>
  import('pages/order-confirmation/order-confirmation')
);
const StaticPage: LazyRoute = lazyWithFallback(() => import('pages/static'));

const CampaignsPage: LazyRoute = lazyWithFallback(() => import('pages/campaigns'));
const NotFound: LazyRoute = lazyWithFallback(() => import('pages/not-found'));
const Pypestream: LazyRoute = lazyWithFallback(() => import('pages/pypestream'));
const SupportV2CategoriesPage: LazyRoute = lazyWithFallback(() =>
  import('pages/support-v2/support-v2-categories-page')
);
const SupportV2CategoryPage: LazyRoute = lazyWithFallback(() =>
  import('pages/support-v2/support-v2-category-page')
);
const SupportV2FormPage: LazyRoute = lazyWithFallback(() =>
  import('pages/support-v2/support-v2-form-page')
);
const SupportV2OrdersListPage: LazyRoute = lazyWithFallback(() =>
  import('pages/support-v2/support-v2-orders-list-page')
);

// OrderContext changes a lot, and we should isolate the effect of re-rendering
// into isolation. We do not want the OrderContext to cause a re-render of the entire layout.
const ClearServerOrderContainer = () => {
  const { clearServerOrder } = useOrderContext(); // @todo type order context

  useEffect(() => {
    // reset the server order when a user navigate into the menu no matter what
    clearServerOrder();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

const ContentRoutingContainer = () => {
  // useGoogleAnalytics();
  // TODO: RN - web - may need to add this back for web, but removing for now
  // useSetUserLocale();
  const { formatMessage } = useIntl();
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { claimPointsEnabled, setAddOffersCallbackUrl } = useLoyaltyContext();

  return (
    <>
      <ClearServerOrderContainer />

      <ContentRouting
        formatMessage={formatMessage}
        claimPointsEnabled={claimPointsEnabled}
        loyaltyEnabled={loyaltyEnabled}
        setAddOffersCallbackUrl={setAddOffersCallbackUrl}
      />
    </>
  );
};

type ContentRoutingProps = {
  formatMessage: ReturnType<typeof useIntl>['formatMessage'];
  claimPointsEnabled: boolean;
  loyaltyEnabled: boolean;
  setAddOffersCallbackUrl: SetState<string>;
};

const ContentRouting = memo(
  ({
    formatMessage,
    claimPointsEnabled,
    loyaltyEnabled,
    setAddOffersCallbackUrl,
  }: ContentRoutingProps) => {
    const enablePypestream = useFlag(LaunchDarklyFlag.ENABLE_PYPESTREAM);
    const enableSignUpFlag = useFlag(LaunchDarklyFlag.ENABLE_SIGN_UP);
    const enableSignUp = isNil(enableSignUpFlag) || enableSignUpFlag;
    const enableRewardPageStandAlone = useFlag(LaunchDarklyFlag.ENABLE_REWARD_PAGE_STAND_ALONE);
    const enableRewardsStaticPage = useFlag(LaunchDarklyFlag.ENABLE_REWARDS_STATIC_PAGE);
    const enableOffersStaticPage = useFlag(LaunchDarklyFlag.ENABLE_OFFERS_STATIC_PAGE);
    const enableQuests = useFlag(LaunchDarklyFlag.ENABLE_QUEST_TILES);
    const enableWalmartPlusIntegration = useFlag(LaunchDarklyFlag.ENABLE_WALMART_PLUS_INTEGRATION);
    const isOffersV2Enabled = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_OFFERS_V2_FE);
    const enableContentCards = useContentCardsNotificationsExperiment();

    return (
      <Routes>
        <Route path={'/:staticPagePath'} element={<StaticPage />} />
        <Route path={`${routes.campaigns}/:campaignSlug`} element={<CampaignsPage />} />
        <Route path={routes.notFound404} element={<NotFound />} />
        <Route path={routes.confirmJwt} element={<ConfirmJwt />} />
        {loyaltyEnabled && (
          <Route
            path={`${formatMessage({ id: 'routes.loyaltyDashboard' })}`}
            element={<LoyaltyDashboard />}
          />
        )}
        <Route
          path={formatMessage({ id: 'routes.redemptionInRestaurant' })}
          element={<LoyaltyInRestaurantRedemption />}
        />
        {claimPointsEnabled && (
          <Route
            path={`${formatMessage({ id: 'routes.claimPointsBase' })}/*`}
            element={<LoyaltyClaimPoints />}
          />
        )}
        {enableSignUp && (
          <>
            <Route path={formatMessage({ id: 'routes.signUp' })} element={<SignupHome />} />
            {getAllLocalizedVersionsOfRoute('signUp').map((path, index) => (
              <Route path={path} key={`sign-up-${index}`} element={<SignupHome />} />
            ))}
            <Route path={formatMessage({ id: 'routes.signIn' })} element={<SignupHome />} />
            {getAllLocalizedVersionsOfRoute('signIn').map((path, index) => (
              <Route path={path} key={`sign-in-${index}`} element={<SignupHome />} />
            ))}
            <Route path={routes.confirmOtp} element={<SignupHome />} />
            <Route path={formatMessage({ id: 'routes.account' })} element={<Account />} />
            {getAllLocalizedVersionsOfRoute('account').map((path, index) => (
              <Route path={path} key={`account-${index}`} element={<Account />} />
            ))}
          </>
        )}
        <Route path={routes.authChallengeJwt} element={<AuthChallengeScreenJwt />} />
        <Route path={routes.signOut} element={<SignOut />} />
        <Route path={`${routes.menu}/*`} element={<MenuPage />} />
        <Route path={`${routes.browseMenu}/*`} element={<MenuPage />} />

        <Route path={routes.base} element={<Home />} />
        {!enableOffersStaticPage && <Route path={`${routes.offers}/*`} element={<Offers />} />}
        {enableRewardPageStandAlone && (
          <Route
            path={formatMessage({ id: 'routes.loyaltyHome' })}
            element={<LoyaltyDashboard />}
          />
        )}
        {!enableRewardsStaticPage && (
          <>
            <Route
              path={`${formatMessage({ id: 'routes.loyaltyOfferList' })}`}
              element={<LoyaltyOffers />}
            />
            <Route path={routes.loyaltyChallengeDetails} element={<QuestDetailsPage />} />
            <Route
              path={`${formatMessage({ id: 'routes.loyaltyActivateQuest' })}`}
              element={<LoyaltyRewards />}
            />
            <Route
              path={`${formatMessage({ id: 'routes.loyaltyOfferList' })}/:offerEngineId`}
              element={isOffersV2Enabled ? <LoyaltyOfferDetail /> : <LoyaltyOffers />}
            />
            <Route
              path={`${routes.loyaltyOfferGroup}/:offerGroupId`}
              element={<OffersGroupsDetails setAddOffersCallbackUrl={setAddOffersCallbackUrl} />}
            />
            <Route
              path={formatMessage({ id: 'routes.loyaltyRewardList' })}
              element={<LoyaltyRewards />}
            />
            <Route
              path={`${formatMessage({ id: 'routes.loyaltyRewardList' })}/:rewardId`}
              element={<LoyaltyRewards />}
            />
            <Route path={`${routes.rewards}/*`} element={<LoyaltyRewards />} />
          </>
        )}

        <Route path={routes.accountInfo} element={<AccountInfo />} />
        {enableWalmartPlusIntegration && (
          <>
            <Route path={routes.partnerships} element={<WalmartOffer />} />
            <Route path={routes.walmart} element={<WalmartLandingPage />} />
            <Route path={routes.walmartLocation} element={<WalmartLocation />} />
            <Route path={routes.walmartRedirect} element={<WalmartRedirect />} />
          </>
        )}
        {enableContentCards && <Route path={routes.news} element={<NotificationCenter />} />}
        <Route path={routes.communication} element={<CommPreferences />} />
        <Route path={routes.orders} element={<AccountOrders />} />
        <Route path={`${routes.orders}/:rbiOrderId`} element={<AccountOrders />} />
        <Route path={`${routes.payment}/*`} element={<PaymentMethods />} />
        <Route path={`${routes.diagnostics}/*`} element={<Diagnostics />} />

        {enablePypestream ? (
          // show pypestream and redirect all other support links to pypestream
          <>
            <Route path={routes.support} element={<Pypestream />} />
            <Route
              path={`${routes.support}/*`}
              element={<Navigate replace to={routes.support} />}
            />
          </>
        ) : (
          // general support requests
          <>
            {/* // general support requests */}
            <Route path={routes.supportForm} element={<SupportV2FormPage />} />
            <Route path={routes.supportOrders} element={<SupportV2OrdersListPage />} />
            <Route path={routes.supportCategories} element={<SupportV2CategoriesPage />} />
            <Route path={routes.supportCategory} element={<SupportV2CategoryPage />} />
            <Route path={routes.support} element={<SupportV2CategoriesPage />} />
          </>
        )}

        {/* We only want brand-specific routes to be available for those brands */}
        {/* `brandRoutes` can be thought of a Map of routes (string) to Components (React component) */}
        {Object.entries(brandRoutes).map(([path, Component]) => (
          <Route path={formatMessage({ id: path })} element={<Component key={path} />} key={path} />
        ))}
        {enableQuests && <Route path={routes.questLibrary} element={<QuestLibrary />} />}
      </Routes>
    );
  }
);

export default function LayoutContainerComponent() {
  const { modalAuthState } = useAuthContext();
  const { scrollTo, scrollRef } = useScrollContext();
  const {
    isPreConfirmCurbside,
    recent: { unavailablePendingRecentItem, setUnavailablePendingRecentItem },
  } = useOrderContext();

  const [cardSuccessfullyAdded, setCardSuccessfullyAdded] = useState(false);
  const { setCheckoutPaymentMethodId } = usePaymentContext();
  const isPaymentRedesignEnabled = usePaymentCheckoutRedesignExperiment();
  const {
    isUpdateModalOpen,
    shouldShowPreConfirmCurbsideModal,
    setShouldShowPreConfirmCurbsideModal,
  } = useUIContext();
  const enableTopOrderStatusBar = useFlag(LaunchDarklyFlag.ENABLE_TOP_ORDER_STATUS_BAR);
  const onboardingEnabled = useFlag(LaunchDarklyFlag.ENABLE_FEATURE_ONBOARDING);

  const handleSelect = (newFdAccountId?: string) => {
    if (!newFdAccountId) {
      setCheckoutPaymentMethodId('');
      return;
    }

    setCheckoutPaymentMethodId(newFdAccountId);
  };
  // Track all page views on mParticle
  useLogPageView();

  useEffectOnUrlChange(() => {
    scrollTo({ animated: false });
  });

  const { formatMessage } = useIntl();

  const curbsideModalDismiss = () => {
    setShouldShowPreConfirmCurbsideModal(false);
  };

  return (
    <>
      <View flex="1" overflowX="hidden">
        {cardSuccessfullyAdded && (
          <VisuallyHidden
            role="alert"
            accessibilityLabel={formatMessage({
              id: 'yourNewPaymentMethodHasBeenSuccessfullySaved',
            })}
          />
        )}
        <ScrollContainer scrollRef={scrollRef}>
          <Suspense>
            <Routes>
              <Route path={`${routes.cart}/*`} element={<Cart />} />
              <Route
                path={`${routes.orderConfirmation}/:rbiOrderId`}
                element={<OrderConfirmation />}
              />
              <Route path={`${routes.storeLocator}/*`} element={<StoreLocator />} />
              <Route
                path={`${formatMessage({ id: 'routes.storeLocator' })}/*`}
                element={<StoreLocator />}
              />
              {isPaymentRedesignEnabled ? (
                <Route path={routes.addGiftCard} element={<AddGiftCardRedesign />} />
              ) : (
                <Route
                  path={routes.addGiftCard}
                  element={
                    <AddGiftCardModal
                      // @ts-expect-error TS(2322) FIXME: Type '(newFdAccountId?: string) => void' is not as... Remove this comment to see the full error message
                      handleSelect={handleSelect}
                      onCardAdded={() => setCardSuccessfullyAdded(true)}
                    />
                  }
                />
              )}
              <Route
                path={routes.accountPaymentTransferSuccessGiftCard}
                element={<SuccessTransferGiftCardModal />}
              />
              <Route
                path={routes.accountPaymentSuccessGiftCard}
                element={<SuccessGiftCardModal />}
              />
              {isPaymentRedesignEnabled ? (
                <Route path={routes.transferGiftCard} element={<TransferGiftCardRedesign />} />
              ) : (
                <Route path={routes.transferGiftCard} element={<TransferGiftCardModal />} />
              )}
              {isPaymentRedesignEnabled ? (
                <Route
                  path={routes.addCard}
                  element={
                    <AddPaymentMethodModalRedesign
                      onCardAdded={() => setCardSuccessfullyAdded(true)}
                    />
                  }
                />
              ) : (
                <Route
                  path={routes.addCard}
                  element={
                    <AddPaymentMethodModal onCardAdded={() => setCardSuccessfullyAdded(true)} />
                  }
                />
              )}
              {/* Regular routes (with normal nav, header, footer, etc) */}
              <Route path="*" element={<ContentRoutingContainer />} />
            </Routes>
          </Suspense>
          {/* Modals and things that don't render "In flow" */}
          <HelmetHTML />
          <LoyaltyOptInModal />
          {unavailablePendingRecentItem && (
            <RemoveUnavailableItemsModal
              unavailableCartEntries={[unavailablePendingRecentItem]}
              onConfirmCb={() => setUnavailablePendingRecentItem(null)}
            />
          )}
        </ScrollContainer>
      </View>
      {!enableTopOrderStatusBar && (
        <View>
          <TopServiceModeNotification />
        </View>
      )}
      <View>
        <LayoutFabButton />
      </View>
      {modalAuthState.trigger === ModalAuthTrigger.Global && <ModalAuth />}
      {!isUpdateModalOpen && onboardingEnabled && <OnboardingContent />}
      {!isUpdateModalOpen && shouldShowPreConfirmCurbsideModal && isPreConfirmCurbside() && (
        <CurbsideConfirmationModal showDialog onDismiss={curbsideModalDismiss} />
      )}
      <DialogStaticPage />
    </>
  );
}
