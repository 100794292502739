import { getParentChildPlu } from './get-parent-child-plu';
import { getVendorConfig } from './get-vendor-config';
import { shouldUseMainItemCompositePlu } from './should-use-main-item-composite-plu';
import { IWithVendorConfig, PosVendors } from './types';

interface IComputeUpsellModifierPlu {
  modifier: IWithVendorConfig;
  vendor: PosVendors | null;
}

export const computeUpsellModifierPlu = ({
  modifier,
  vendor,
}: IComputeUpsellModifierPlu): string | null => {
  if (!vendor || !shouldUseMainItemCompositePlu(vendor)) {
    return null;
  }

  const vendorConfig = getVendorConfig(modifier, vendor);
  return getParentChildPlu(vendorConfig);
};
