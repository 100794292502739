import { useEffect, useRef } from 'react';

import { Box, Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';
import { Animated } from 'react-native';

import ActionButton from 'components/action-button';
import { LogUserErrorSeverity, useLogUserErrorMessage } from 'hooks/log-user-error-message';
import { useOrderContext } from 'state/order';
import { theme } from 'styles/configure-theme';
import { isNative } from 'utils/environment';

import { useMessage } from '../../../../hooks/use-message';

import { StoreOrderButtonProps } from './types';

const CtaWrapper = Box.withConfig({
  paddingTop: '$6',
});

export const StoreOrderButton = ({
  restaurant,
  onOrderPress,
  isInSupportForm,
  restaurantCanBeSelected,
}: StoreOrderButtonProps) => {
  const { formatMessage } = useIntl();
  const { fetchingPosData } = useOrderContext();

  const message = useMessage({ restaurant });

  useLogUserErrorMessage({
    message,
    severity: LogUserErrorSeverity.Irrecoverable,
    context: {
      moreInfo: 'store-actions.tsx',
      storeId: restaurant?.storeId,
    },
  });

  /**
   * FADE-IN ReactNative ANIMATION
   */
  const fadeAnim = useRef(new Animated.Value(0));
  useEffect(() => {
    Animated.timing(fadeAnim.current, {
      toValue: 1,
      duration: 200,
      useNativeDriver: isNative,
    }).start();
  }, []);

  const buttonTextId = isInSupportForm ? 'select' : 'orderHere';

  return (
    <Animated.View style={[{ opacity: fadeAnim.current }]}>
      <CtaWrapper>
        <ActionButton
          isLoading={fetchingPosData}
          testID="store-modal-order-here"
          fullWidth
          onPress={onOrderPress}
          disabled={!restaurantCanBeSelected}
          leftIcon={
            <Box justifyContent="center">
              <Icon
                size="$5"
                color={theme.token('icon-button-primary')}
                variant="location"
                aria-hidden
              />
            </Box>
          }
        >
          {formatMessage({ id: buttonTextId })}
        </ActionButton>
      </CtaWrapper>
    </Animated.View>
  );
};
