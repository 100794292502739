import * as React from 'react';

import capitalize from 'lodash/capitalize';
import { useIntl } from 'react-intl';

import type { IQuestIncentiveRewardFragment } from 'generated/sanity-graphql';
import { maybeLocale } from 'utils/graphql';

import { QuestItemIncentive } from './quest-item-incentive';

export const QuestRewardIncentive = ({
  cmsQuestRewardIncentive,
  loyaltyRewardId = '',
  onCloseDrawer,
}: {
  cmsQuestRewardIncentive: IQuestIncentiveRewardFragment;
  loyaltyRewardId?: string;
  onCloseDrawer: () => void;
}) => {
  const { formatMessage } = useIntl();
  const image = maybeLocale(cmsQuestRewardIncentive.image);
  const name = cmsQuestRewardIncentive.name?.en || '';
  const link = `${formatMessage({ id: 'routes.loyaltyRewardList' })}/${loyaltyRewardId}`;

  return (
    <QuestItemIncentive
      title={formatMessage({ id: 'reward' })}
      linkTo={link}
      image={image}
      altImage={name}
      name={name}
      price={capitalize(formatMessage({ id: 'free' }))}
      onCloseDrawer={onCloseDrawer}
    />
  );
};
