import { LogsEvent } from '@datadog/browser-logs';

/**
 * returns a function for the DD `beforeSend` property with built in session-based sampling functionality
 * @param {number} sampleRate - a number between 0 and 100.
 *  0 = only logs with `bypassSampling: true` go to DataDog.
 *  100 = all logs go to DataDog.
 *  1 - 99 = all logs with `bypassSampling: true` go to DataDog, and _maybe_ all other logs will as well
 *
 * @returns {function} a function suitable to use as the `beforeSend` parameter in DataDog initialization
 */
export const makeBeforeSendCallback = (sampleRate: number) => {
  const includeAllMessagesFromThisSession = sampleRate !== 0 && sampleRate >= Math.random() * 100;

  return (event: LogsEvent): void | false => {
    if (includeAllMessagesFromThisSession || event.bypassSampling) {
      // send log to DD
      return;
    }
    // do not send log to DD
    return false;
  };
};
