import { ApolloError } from '@apollo/client';

import {
  ILoyaltyQuestCompletion,
  LoyaltyQuestIncentiveType,
  LoyaltyQuestStatus,
  LoyaltyQuestStepStatus,
} from 'generated/graphql-gateway';

export type QuestCartRequirementEntry = {
  id: string;
  quantity: number;
};

export type QuestCartRequirement = QuestCartRequirementEntry[];

export interface IQuestStep {
  id: string;
  status: LoyaltyQuestStepStatus;
  cartRequirements?: QuestCartRequirement[];
}

export enum QuestCmsType {
  CONFIG = 'configQuest',
  SYSTEMWIDE = 'systemwideQuest',
}

export interface IQuest {
  id: string;
  type: string;
  startDate?: string;
  endDate?: string;
  incentiveType: LoyaltyQuestIncentiveType;
  cmsId: string;
  cmsType: QuestCmsType | '';
  status: LoyaltyQuestStatus;
  lastCompletion: ILoyaltyQuestCompletion | null;
  steps: IQuestStep[] | null;
  bonusPoints?: number | null;
}

export interface IQuestContext {
  quest: IQuest;
}

export interface IUseLoyaltyQuestQuery {
  error?: ApolloError;
  quest: IQuest | null;
  loadingQuests: boolean;
  refetchQuests: () => void;
}

export enum QuestValidationError {
  INVALID_CMS_DOCUMENT = 'INVALID_CMS_DOCUMENT',
}
export interface QuestError {
  error: QuestValidationError;
  message: string;
}
