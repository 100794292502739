import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'state/global-state';
import { IncentiveEvaluationErrorCodes } from 'state/loyalty/hooks/types';
import { LoyaltyOffer } from 'state/loyalty/types';
import { getCmsOffersMapByCmsId, isDiscountLoyaltyOffer } from 'state/loyalty/utils';
import { DIFFERENCE_TIME_UNITS, getDifferenceToNow } from 'utils/dateTime';

import { incentiveErrorsFilteredList } from './offers.utils';

export const selectAppliedOffers = ({ loyalty }: RootState) => {
  return loyalty.offers.appliedOffers;
};

export const selectOfferFeedbackMap = ({ loyalty }: RootState) => {
  return loyalty.offers.offersFeedbackMap;
};

export const selectOffersLoading = ({ loyalty }: RootState) => {
  return loyalty.offers.offersLoading;
};

export const selectOffers = ({ loyalty }: RootState) => {
  return loyalty.offers.offers;
};

export const selectUserOffers = ({ loyalty }: RootState) => {
  return loyalty.offers.userOffers;
};

export const selectPersonalizedOffers = ({ loyalty }: RootState) => {
  return loyalty.offers.personalizedOffers;
};

export const selectSelectedOffer = ({ loyalty }: RootState) => {
  return loyalty.offers.selectedOffer;
};

export const selectIncentiveErrorsFilteredByCode = (
  { loyalty }: RootState,
  errorCodeToFilter: IncentiveEvaluationErrorCodes
) => {
  return incentiveErrorsFilteredList(
    loyalty.offers.offersFeedbackMap,
    evaluationError => evaluationError.code !== errorCodeToFilter
  );
};

export const selectIncentiveErrorsWithCode = (
  { loyalty }: RootState,
  errorCodeToFilter: IncentiveEvaluationErrorCodes
) => {
  return incentiveErrorsFilteredList(
    loyalty.offers.offersFeedbackMap,
    evaluationError => evaluationError.code === errorCodeToFilter
  );
};

export const selectCmsOffers = ({ loyalty }: RootState) => loyalty.offers.cmsOffers;

export const selectAppliedCmsOffers = createSelector(
  (root: RootState) => root.loyalty.offers.cmsOffers,
  (root: RootState) => root.loyalty.offers.appliedOffers,
  (cmsOffers, appliedOffers) => {
    const set = new Set(appliedOffers.map(({ id }) => id));
    return cmsOffers?.filter(cmsOffer => set.has(cmsOffer.loyaltyEngineId));
  }
);

export const selectDiscountAppliedCmsOffer = createSelector(
  selectAppliedCmsOffers,
  appliedCmsOffers =>
    //Return first match because there should never be two applied offer discounts
    appliedCmsOffers?.find(isDiscountLoyaltyOffer)
);

export const selectLoyaltyOffersInCooldown = ({ loyalty }: RootState) => {
  return loyalty.offers.offerRedemptionAvailableAfter
    ? getDifferenceToNow(
        DIFFERENCE_TIME_UNITS.SECONDS,
        loyalty.offers.offerRedemptionAvailableAfter
      ) > 0
    : false;
};

export const selectExtendedCmsOffersMap = ({ loyalty }: RootState) => (
  newCmsOffers: LoyaltyOffer[]
) => {
  const cmsOffersMap = getCmsOffersMapByCmsId(loyalty.offers.cmsOffers);
  const newCmsOffersMap = getCmsOffersMapByCmsId(newCmsOffers);

  return { ...cmsOffersMap, ...newCmsOffersMap };
};

export const selectValidAppliedOffers = ({ loyalty }: RootState) => (offersMap: {
  [key: string]: LoyaltyOffer;
}) => {
  return loyalty.offers.appliedOffers.filter(offer => !!offersMap[offer.cmsId || '']);
};

export const selectIncentivesIds = createSelector(
  (rootState: RootState) => rootState?.loyalty?.offers?.incentivesIds,
  incentivesIds => new Set(incentivesIds)
);

export const selectShouldRefetchOffers = ({ loyalty }: RootState) => {
  return loyalty.offers.shouldRefetchOffers;
};
