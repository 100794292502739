import { Box, Icon, Image, Text } from '@rbilabs/universal-components';

import { ResizeMode } from 'components/picture/types';
import { useIsMobileBreakpoint } from 'hooks/use-media-query';
import { primitive } from 'styles/constants/primitives';

export const Container = Box.withConfig({
  paddingBottom: '$6',
  width: '100%',
  backgroundColor: primitive.$white,
  borderRadius: 'xl',
  overflow: 'hidden',
});

export const ContentContainer = Box.withConfig({
  paddingX: '$6',
});

export const Title = Text.withConfig({
  variant: 'heading',
  fontSize: 'md',
  marginY: '$6',
  textAlign: 'center',
});

export const ItemsSection = Box.withConfig({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
});

export const ItemsContainer = Box.withConfig({
  width: '100%',
  maxWidth: '400px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const ItemContainer = Box.withConfig({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ItemIconContainer = Box.withConfig({
  borderRadius: 'full',
  background: primitive.bk.$bbqBrown,
  width: '$18',
  height: '$18',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StyledIcon = Icon.withConfig({
  size: '$8',
  color: primitive.bk.$meltyYellow,
});

export const ItemText = Text.withConfig(() => {
  const isMobile = useIsMobileBreakpoint();

  return {
    variant: 'headerThree',
    fontSize: isMobile ? 'xs' : 'sm',
    marginBottom: 'unset',
    marginTop: '$4',
    textAlign: 'center',
  };
});

export const StyledImage = Image.withConfig(() => {
  const isMobile = useIsMobileBreakpoint();

  return {
    resizeMode: ResizeMode.cover,
    height: isMobile ? '198px' : '341px',
  };
});

export const SectionTitle = Text.withConfig({
  variant: 'headerThree',
  fontSize: 'xl',
  marginBottom: 'unset',
  marginY: '$6',
  textAlign: 'center',
});
