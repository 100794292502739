import { LazyQueryHookOptions, QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { merge } from 'lodash-es';

import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { usePrevious } from 'hooks/use-previous';
import { useLocale } from 'state/intl';
import { useNetworkContext } from 'state/network';

const useSanityGqlEndpoint = (isV2: boolean = false) => {
  const { sanityEndpoints } = useNetworkContext();
  return isV2 ? sanityEndpoints.graphqlV2 : sanityEndpoints.graphql;
};

// Factory function to create the hook with a high level configuration and be able to reuse the generated hook
export function createUseQueryHook() {
  return function useSanityQuery<TData = any, TVariables = Record<string, any>>(
    query: DocumentNode,
    options: QueryHookOptions<TData, TVariables> = {},
    sanityOptions: { isV2: boolean } = { isV2: false }
  ) {
    const uri = useSanityGqlEndpoint(sanityOptions.isV2);
    const { language, region } = useLocale();
    const prevLanguage = usePrevious(language);
    const prevRegion = usePrevious(region);

    const queryOptions = merge(options, { context: { uri } });

    const queryResult = useQuery<TData, TVariables>(query, queryOptions);

    const { refetch } = queryResult;

    useEffectOnUpdates(() => {
      if (prevLanguage !== language || prevRegion !== region) {
        refetch();
      }
    }, [prevLanguage, prevRegion, language, region, refetch]);

    return queryResult;
  };
}

// Factory function to create the hook with a high level configuration and be able to reuse the generated hook
export function createUseLazyQueryHook() {
  return function useLazySanityQuery<TData = any, TVariables = Record<string, any>>(
    query: DocumentNode,
    options: LazyQueryHookOptions<TData, TVariables> = {},
    sanityOptions: { isV2: boolean } = { isV2: false }
  ) {
    const uri = useSanityGqlEndpoint(sanityOptions.isV2);

    const queryOptions = merge(options, { context: { uri } });

    return useLazyQuery<TData, TVariables>(query, queryOptions);
  };
}
