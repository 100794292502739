import React from 'react';

import { Box, Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import { ModalContent } from 'components/modal';
import Modal from 'components/modal/modal-screen';
import { BackArrowHistory } from 'components/navigation/back-arrow';
import { KeyboardAwareView } from 'components/ucl/keyboard-aware-view';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import { theme } from 'styles/configure-theme';
import { primitive } from 'styles/constants/primitives';

import { Heading, Info, ModalBody, SuccessIcon } from './styled';

export const SuccessGiftCardModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const { goBack } = useNavigation();
  const {
    params: { currentBalance },
  } = useRoute<{ currentBalance: string }>();

  const closeModal = () => {
    return goBack();
  };

  const cardAlias = formatMessage({ id: 'cardAlias.bk' });
  const yourNewCardBalance = formatMessage(
    { id: 'yourNewCardBalance' },
    { cardAlias, amount: currentBalance }
  );

  return (
    <>
      <BackArrowHistory />
      <Modal
        onDismiss={closeModal}
        mParticleEventData={{
          modalAppearanceEventMessage: 'Success gift card',
        }}
      >
        <KeyboardAwareView withScroll>
          <ModalContent paddingX="$0">
            <ModalBody>
              <SuccessIcon />
              <Heading>{formatMessage({ id: 'success' })}</Heading>
              <Box
                bgColor={primitive.$blackOpacity10}
                flexDirection="row"
                width="full"
                paddingY="$3"
                paddingX="$6"
                alignItems="center"
              >
                <Icon variant="infoFill" size="$5" color={theme.token('icon-default')} />
                <Info>{yourNewCardBalance}</Info>
              </Box>
              <Box marginTop="$20" paddingX="$6" width="full">
                <ActionButton testID="close-now" onPress={closeModal}>
                  {formatMessage({ id: 'close' })}
                </ActionButton>
              </Box>
            </ModalBody>
          </ModalContent>
        </KeyboardAwareView>
      </Modal>
    </>
  );
};
