import React, { ReactElement } from 'react';

import { Box, Text } from '@rbilabs/universal-components';

import LoadingAnimation from 'components/loading-animation';

const AutButtonContainer = Box.withConfig({
  width: {
    base: 355,
    sm: 420,
    md: 500,
  },
  justifyContent: 'center',
  alignContent: 'center',
  flex: 1,
  flexDirection: 'row',
});

const IconContainer = Box.withConfig({
  width: '$6',
  height: '$6',
  alignContent: 'center',
  justifyContent: 'center',
});

const LabelStyled = Text.withConfig({
  color: Styles.color.black,
  alignSelf: 'center',
});

const AuthButton = ({
  icon,
  label,
  loading,
}: {
  icon: ReactElement;
  label: string;
  loading: boolean;
}) => {
  return (
    <AutButtonContainer>
      <IconContainer>{loading ? <LoadingAnimation size="sm" mr="$1" /> : icon}</IconContainer>
      <LabelStyled>{label}</LabelStyled>
    </AutButtonContainer>
  );
};

export default AuthButton;
