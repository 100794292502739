import { IRewardFragment, LoyaltyRewardType } from 'generated/graphql-gateway';
import { IEngineRewardsMap, IPersonalizedRewardMap } from 'state/loyalty/types';

interface IParsedEngineRewards {
  engineRewardIds: string[];
  engineRewardsMap: IEngineRewardsMap;
  personalizedRewardIds: string[];
  personalizedRewardMap: IPersonalizedRewardMap;
}

export const parseEngineRewards = (
  rewards:
    | readonly ({
        readonly __typename?: 'LoyaltyReward' | undefined;
      } & IRewardFragment)[]
    | null
    | undefined
) => {
  return (rewards || []).reduce(
    (acc: IParsedEngineRewards, reward) => {
      // Extract engineIds to query for rewards in sanity
      if (reward?.id) {
        if (reward?.type === LoyaltyRewardType.PERSONALIZED && reward.sanityId) {
          acc.personalizedRewardIds.push(reward.sanityId);
          acc.engineRewardsMap[reward.sanityId] = reward;
          acc.personalizedRewardMap[reward.id] = reward;
        } else {
          acc.engineRewardIds.push(reward.id);
          acc.engineRewardsMap[reward.id] = reward;
        }
      }
      return acc;
    },
    {
      engineRewardIds: [],
      engineRewardsMap: {},
      personalizedRewardIds: [],
      personalizedRewardMap: {},
    }
  );
};
