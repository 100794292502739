import { useFeatureLoyaltyUiNoRewardQuery } from 'generated/sanity-graphql';
import { FEATURE_LOYALTY_UI_SINGLETON } from 'hooks/use-feature-loyalty-ui/use-feature-loyalty-ui';

export const useFeaturesLoyaltyNoReward = () => {
  const { data, loading } = useFeatureLoyaltyUiNoRewardQuery({
    variables: { featureLoyaltyUIId: FEATURE_LOYALTY_UI_SINGLETON },
  });
  return {
    data: data?.LoyaltyUI?.noRewardPoints,
    loading,
  };
};
