import { IItemOptionModifier, IItemOptionModifierWithQuantity } from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';
import {
  GetVariantContentOptionsFn,
  IMenuObjectWithContentOptions,
} from 'pages/menu/hooks/use-get-variant-content-options';
import { IMenuCombo, IMenuItem, IMenuObject, IMenuPicker } from 'state/menu/types';
import { mergeVendorConfigs } from 'utils/vendor-config/merge-vendor-configs';

const isCombo = (menuObject: any): menuObject is IMenuCombo =>
  menuObject && '_type' in menuObject && menuObject._type === MenuObjectTypes.COMBO;
const isItem = (menuObject: any): menuObject is IMenuItem =>
  menuObject && '_type' in menuObject && menuObject._type === MenuObjectTypes.ITEM;

type RemapFn<T, R> = (args: {
  menuObject: T;
  getVariantContentOptions: GetVariantContentOptionsFn;
}) => R;

const remapPicker: RemapFn<IMenuPicker, IMenuPicker> = ({
  menuObject: picker,
  getVariantContentOptions,
}) => {
  const { name, image, description } = getVariantContentOptions(
    picker as IMenuObjectWithContentOptions
  );
  return {
    ...picker,
    name,
    image,
    description,
    options: (picker.options ?? []).map(pickerOption =>
      pickerOption
        ? {
            ...pickerOption,
            option: isCombo(pickerOption.option)
              ? remapCombo({ menuObject: pickerOption.option, getVariantContentOptions })
              : isItem(pickerOption.option)
              ? remapItem({ menuObject: pickerOption.option, getVariantContentOptions })
              : null,
          }
        : null
    ),
  };
};

const remapCombo: RemapFn<IMenuCombo, IMenuCombo> = ({
  menuObject: combo,
  getVariantContentOptions,
}) => {
  const mainItem = combo.mainItem
    ? remapItem({ menuObject: combo.mainItem as IMenuItem, getVariantContentOptions })
    : null;
  const options = (combo.options ?? []).map(comboSlot =>
    comboSlot
      ? {
          ...comboSlot,
          options: comboSlot?.options?.map(comboSlotOption =>
            comboSlotOption
              ? {
                  ...comboSlotOption,
                  option: remapItem({
                    menuObject: comboSlotOption.option as IMenuItem,
                    getVariantContentOptions,
                  }),
                }
              : null
          ),
        }
      : null
  );

  const { name, image, description } = getVariantContentOptions(
    combo as IMenuObjectWithContentOptions
  );

  return {
    ...combo,
    name,
    image,
    description,
    mainItem,
    options,
  };
};

const remapItemOptionModifier: RemapFn<IItemOptionModifier, IItemOptionModifierWithQuantity> = ({
  menuObject: itemOptionModifier,
  getVariantContentOptions,
}) => ({
  ...itemOptionModifier,
  name: itemOptionModifier.name ?? {
    locale: `${itemOptionModifier.modifierMultiplier?.prefix?.locale || ''} ${
      itemOptionModifier.modifierMultiplier?.modifier?.name?.locale || ''
    }`,
  },
  quantity: 1,
  vendorConfigs:
    mergeVendorConfigs([
      itemOptionModifier.vendorConfigs,
      itemOptionModifier.modifierMultiplier?.vendorConfigs,
      itemOptionModifier.modifierMultiplier?.modifier?.vendorConfigs,
    ]) ?? undefined,
});

// @ts-expect-error Unsafe cast due to IItemOptionModifier differences with generated schema
const remapItem: RemapFn<IMenuItem, IMenuItem> = ({
  menuObject: item,
  getVariantContentOptions,
}) => {
  const { name, image, description } = getVariantContentOptions(
    item as IMenuObjectWithContentOptions
  );
  return {
    ...item,
    name,
    image,
    description,
    options: (item.options ?? []).map(itemOption =>
      itemOption
        ? {
            ...itemOption,
            options: (itemOption?.options ?? []).map(itemOptionModifier =>
              itemOptionModifier
                ? remapItemOptionModifier({
                    menuObject: itemOptionModifier as IItemOptionModifier,
                    getVariantContentOptions,
                  })
                : null
            ),
          }
        : null
    ),
  };
};

/**
 * Iterate over MenuObjects remapping Modifiers
 *    - Merges up vendorConfigs
 *    - Compose the Modifier name
 *    - Adds quantity
 *
 * @param menuObject
 * @returns
 */
export const remapMenuObject: RemapFn<IMenuObject | null | undefined, IMenuObject | null> = ({
  menuObject,
  getVariantContentOptions,
}) => {
  if (!menuObject) {
    return null;
  }

  switch (menuObject._type) {
    case MenuObjectTypes.PICKER: {
      return remapPicker({ menuObject, getVariantContentOptions });
    }
    case MenuObjectTypes.COMBO: {
      return remapCombo({ menuObject, getVariantContentOptions });
    }
    case MenuObjectTypes.ITEM: {
      return remapItem({ menuObject, getVariantContentOptions });
    }

    default:
      return null;
  }
};
