import { trimEnd } from 'lodash-es';

import { useGetFeatureExternalCampaignBySlugQuery } from 'generated/sanity-graphql';

const removeTrailingSlash = (url: string) => trimEnd(url, '/');

export const useGetExternalCampaignBySlug = ({ slug }: { slug: string }) => {
  const { data, loading } = useGetFeatureExternalCampaignBySlugQuery({
    skip: !slug,
    variables: { slug },
    fetchPolicy: 'network-only',
  });

  const campaign = data?.allFeatureExternalCampaigns[0]
    ? {
        ...data.allFeatureExternalCampaigns[0],
        externalUrl: removeTrailingSlash(data.allFeatureExternalCampaigns[0].externalUrl || ''),
      }
    : null;

  return {
    isLoading: loading,
    campaign,
  };
};
