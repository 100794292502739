import * as Linking from 'expo-linking';
import { PermissionStatus } from 'expo-modules-core';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';

import { ILocation } from '@rbi-ctg/frontend';
import {
  GeolocationPermissionStates,
  checkIfLocationPermissionIsPreapproved,
  getUsersCurrentPosition,
  promptUserForLocationPermission,
} from 'utils/geolocation';
import logger from 'utils/logger';

export const enablePush = async (): Promise<Notifications.NotificationPermissionsStatus | null> => {
  try {
    const settings = await Notifications.getPermissionsAsync();
    if (
      settings.status === PermissionStatus.UNDETERMINED ||
      settings.ios?.status === Notifications.IosAuthorizationStatus.NOT_DETERMINED ||
      settings.canAskAgain
    ) {
      return await Notifications.requestPermissionsAsync();
    }
    return settings;
  } catch (error) {
    logger.error({ error, message: 'Prompt For Push Permissions error' });
    return null;
  }
};

export const openPushSetting = () => {
  if (Platform.OS === 'android') {
    Linking.openSettings();
  } else if (Platform.OS === 'ios') {
    Linking.openURL('app-settings:');
  }
};

/**
 * enable location services and retrieve the user's current location.
 * It checks the current state of location permissions and either directly retrieves the location
 * or prompts the user for permission first.
 *
 * @returns A Promise resolving to the user's location if permissions are granted, or undefined otherwise.
 * Throws a LocationPermissionError if permission is explicitly denied by the user.
 */
export const enableLocation = async (): Promise<ILocation | void> => {
  try {
    const state = await checkIfLocationPermissionIsPreapproved();

    if (state === GeolocationPermissionStates.GRANTED) {
      return getUsersCurrentPosition();
    }

    if (state === GeolocationPermissionStates.PROMPT) {
      const permissionState = await promptUserForLocationPermission();

      if (permissionState === GeolocationPermissionStates.DENIED) {
        return logger.warn({
          message: 'User denied location permission',
        });
      }

      return getUsersCurrentPosition();
    }
  } catch (error) {
    logger.error({
      error,
      message: 'Error while enabling location',
    });
  }
};
