import React, { FC } from 'react';

import { Box } from '@rbilabs/universal-components';
import { Field, FieldProps, Formik } from 'formik';
import { useIntl } from 'react-intl';

import LoadingAnimation from 'components/loading-animation';
import { FormikOTPInput } from 'components/otp-input';
import { VisuallyHidden } from 'components/ucl/visually-hidden';
import { useAuthContext } from 'state/auth';

import { ErrorMessage } from './otp.styled';

const OTP_FIELD_NAME = 'otp-code';

interface IOtpForm {
  errorMessage?: string | React.ReactNode;
  initialCode?: string | null;
  loading?: boolean;
  otpCodeLength?: number;
  onSubmit(otpValues: string): Promise<void>;
}

export const LoadingContainer = Box.withConfig({
  height: '13.5',
  marginY: '4',
  marginX: '0',
  alignItems: 'center',
  justifyContent: 'center',
});

export const FieldWrapper = Box.withConfig({
  marginTop: '$4',
  marginBottom: '$5',
  marginX: '$0',
  paddingY: '$0',
  paddingX: '$1',
  width: 'full',
  _web: {
    marginY: '$8',
    paddingX: '$0',
  },
});

const OTP_CODE_LENGTH = 6; // the number of digits in a generated code

export const OtpForm: FC<IOtpForm> = ({
  errorMessage,
  initialCode,
  loading,
  otpCodeLength = OTP_CODE_LENGTH,
  onSubmit,
}) => {
  const { formatMessage } = useIntl();
  const initialOtpFormValues = { [OTP_FIELD_NAME]: initialCode || '' };
  const { loading: authLoading, otpAuthError } = useAuthContext();

  const otpErrorMessage = errorMessage || otpAuthError;

  const handleOtpCodeSubmit = async (
    formValues: { [OTP_FIELD_NAME]: string },
    { setStatus }: FieldProps['form']
  ) => {
    try {
      await onSubmit(formValues[OTP_FIELD_NAME]);
    } catch {
      setStatus({ success: false });
    }
  };

  return (
    <Formik
      initialValues={initialOtpFormValues}
      // @ts-expect-error TS(2322) FIXME: Type '(formValues: {    [OTP_FIELD_NAME]: string;}... Remove this comment to see the full error message
      onSubmit={handleOtpCodeSubmit}
      validateOnChange={false}
    >
      {({ isSubmitting, setFieldValue, touched, values }) => (
        <>
          {authLoading || loading || isSubmitting ? (
            <LoadingContainer
              accessibilityRole="alert"
              accessibilityLabel={formatMessage({ id: 'visuallyHiddenVerifyingOTP' })}
            >
              <LoadingAnimation />
            </LoadingContainer>
          ) : (
            <>
              <VisuallyHidden
                accessibilityLabel={formatMessage({ id: 'youHaveSuccessfullySubmittedTheForm' })}
                aria-live="polite"
              />
              <FieldWrapper>
                <Field
                  name={OTP_FIELD_NAME}
                  forceErrorState={
                    touched[OTP_FIELD_NAME] &&
                    !!otpErrorMessage &&
                    values[OTP_FIELD_NAME].length === otpCodeLength
                  }
                  setFieldValue={setFieldValue}
                  component={FormikOTPInput}
                  disabled={isSubmitting}
                  otpCodeLength={otpCodeLength}
                  submitOnFill
                  autoFocus
                />
                {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              </FieldWrapper>
            </>
          )}
        </>
      )}
    </Formik>
  );
};
