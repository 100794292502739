import { FC } from 'react';

import { Box } from '@rbilabs/universal-components';

import Picture from 'components/picture';
import { useFeatureLoyaltyUi } from 'hooks/use-feature-loyalty-ui';

import { HeaderVersion, WalmartHeaderProps } from '../walmart/types';
import { useLoyaltyWalmart } from '../walmart/use-loyalty-walmart';

import { DividerLogos, HeaderContainer, LogosContainer } from './styled';

export const WalmartLandingHeader: FC<React.PropsWithChildren<WalmartHeaderProps>> = ({
  headerVersion,
}) => {
  const { featureLoyaltyUiLoading, featureLoyaltyUi } = useFeatureLoyaltyUi();
  const { data, loading } = useLoyaltyWalmart();

  if (featureLoyaltyUiLoading || loading) {
    return null;
  }

  const royalLogo = featureLoyaltyUi?.rewardsLogo?.locale;
  const walmartLogo = data?.logo?.locale;
  const { linkedHeaderImage, onLinkingHeaderImage, defaultHeaderImage } = data?.landingPage || {};
  const headerImage = {
    [HeaderVersion.Version1]: onLinkingHeaderImage,
    [HeaderVersion.Version2]: defaultHeaderImage,
    [HeaderVersion.Version3]: linkedHeaderImage,
  }[headerVersion];

  return (
    <Box>
      <HeaderContainer>
        <Picture
          image={headerImage?.locale}
          alt={'WalmartHeader'}
          objectFitContain={false}
          lazy={false}
          width="100%"
          height="100%"
        />
      </HeaderContainer>
      <LogosContainer>
        <Picture
          image={royalLogo}
          alt={'Royal Perks'}
          objectFitContain
          lazy={false}
          width="$40"
          height="$10"
        />
        <DividerLogos />
        <Picture
          image={walmartLogo}
          alt={'WalmartLogo'}
          objectFitContain
          lazy={false}
          width="$32"
          height="$12"
        />
      </LogosContainer>
    </Box>
  );
};
