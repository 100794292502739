import {
  Svg as RnSvg,
  Circle as RnSvgCircle,
  ClipPath as RnSvgClipPath,
  Defs as RnSvgDefs,
  Ellipse as RnSvgEllipse,
  ForeignObject as RnSvgForeignObject,
  G as RnSvgG,
  Image as RnSvgImage,
  Line as RnSvgLine,
  LinearGradient as RnSvgLinearGradient,
  Marker as RnSvgMarker,
  Mask as RnSvgMask,
  Path as RnSvgPath,
  Pattern as RnSvgPattern,
  Polygon as RnSvgPolygon,
  Polyline as RnSvgPolyline,
  RadialGradient as RnSvgRadialGradient,
  Rect as RnSvgRect,
  Stop as RnSvgStop,
  Symbol as RnSvgSymbol,
  TSpan as RnSvgTSpan,
  Text as RnSvgText,
  TextPath as RnSvgTextPath,
  Use as RnSvgUse,
} from 'react-native-svg';

import { addWithConfig, makeNBComponent } from '../../utils';

export const Svg = makeNBComponent(RnSvg, 'Svg');
export default addWithConfig(Svg);

export const Circle = RnSvgCircle;
export const ClipPath = RnSvgClipPath;
export const Defs = RnSvgDefs;
export const Ellipse = RnSvgEllipse;
export const ForeignObject = RnSvgForeignObject;
export const G = RnSvgG;
export const Image = RnSvgImage;
export const Line = RnSvgLine;
export const LinearGradient = RnSvgLinearGradient;
export const Marker = RnSvgMarker;
export const Mask = RnSvgMask;
export const Path = RnSvgPath;
export const Pattern = RnSvgPattern;
export const Polygon = RnSvgPolygon;
export const Polyline = RnSvgPolyline;
export const RadialGradient = RnSvgRadialGradient;
export const Rect = RnSvgRect;
export const Stop = RnSvgStop;
export const Symbol = RnSvgSymbol;
export const TSpan = RnSvgTSpan;
export const Text = RnSvgText;
export const TextPath = RnSvgTextPath;
export const Use = RnSvgUse;
