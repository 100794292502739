export const BranchEventNames = {
  // Branch Supported Events
  ADD_TO_CART: 'ADD_TO_CART',
  COMPLETE_REGISTRATION: 'COMPLETE_REGISTRATION',
  LOGIN: 'LOGIN',
  PURCHASE: 'PURCHASE',

  // Custom Events
  PAGE_VIEW: 'PAGE_VIEW',
};
