import { useControllableState } from '../../hooks';
import { addWithConfig } from '../../utils';

import { ListItemCheckbox } from './ListItemCheckbox';
import type { ListItemCheckboxGroupProps } from './types';

export function ListItemCheckboxGroup<ListItemValuesType extends string>({
  value,
  onChange,
  listItems,
}: ListItemCheckboxGroupProps<ListItemValuesType>) {
  const [selectedValues, setSelectedValues] = useControllableState({
    defaultValue: [],
    value,
    handleValueChange: onChange,
  });

  const selectedValuesSet = new Set(selectedValues);

  return (
    <>
      {listItems.map((listItem, index) => {
        const isChecked = selectedValuesSet.has(listItem.value);
        const lastItem = index === listItems.length - 1;
        return (
          <ListItemCheckbox
            key={listItem.value}
            value={listItem.value}
            label={listItem.label}
            disabled={listItem.disabled}
            accessibilityLabel={listItem.accessibilityLabel}
            accessibilityHint=""
            isLastItem={lastItem}
            onChange={() => {
              if (isChecked) {
                selectedValuesSet.delete(listItem.value);
              } else {
                selectedValuesSet.add(listItem.value);
              }
              setSelectedValues(Array.from(selectedValuesSet));
            }}
            isChecked={isChecked}
          />
        );
      })}
    </>
  );
}

export default addWithConfig(ListItemCheckboxGroup);
