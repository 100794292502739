import { FC, useCallback, useState } from 'react';

import {
  Box,
  Divider,
  Header,
  Icon,
  Image,
  Pressable,
  ScrollView,
  Switch,
  Text,
} from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import Currency from 'components/currency';
import { useCardNumber } from 'components/gift-card-modal/use-giftcard-number';
import { LayoutContainer } from 'components/layout/styled';
import { CurrentSelectedMethodType } from 'components/payment-method/types';
import { KeyboardAwareView } from 'components/ucl/keyboard-aware-view';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { usePrepaidByNumber } from 'hooks/prepaid';
import useErrorModal from 'hooks/use-error-modal';
import { useLoyaltyUserState } from 'state/loyalty/hooks/use-loyalty-user-state';
import { portalToFooter } from 'state/mobile-footer';
import { portalToReplaceHeader } from 'state/mobile-header-nav';
import { usePaymentContext } from 'state/payment';
import { IPaymentMethod } from 'state/payment/types';
import { primitive } from 'styles/constants/primitives';

import { AddGiftCardInput } from '../../add-gift-card-input';
import {
  BackgroundAndLogoContainer,
  DefaultContainer,
  FieldsContainer,
  GiftCardContainer,
  LogoAndNameContainer,
  StyledActionButton,
  StyledBackIcon,
} from '../add-gift-card-redesign/styled';

import { TransferBalanceContainer } from './transfer-balance';

const crownsImg = require('../add-gift-card-redesign/Frame.png');

export const TransferGiftCardRedesign: FC<React.PropsWithChildren<{}>> = () => {
  const { goBack } = useNavigation();
  const { formatMessage } = useIntl();

  const { loading: loyaltyUserLoading } = useLoyaltyUserState();
  const {
    giftCardNumber,
    errors,
    handleCardNumberChange,
    isValidGiftCardNumberCheck,
  } = useCardNumber();
  const {
    getPrepaidPaymentMethod,
    getBalanceFromPaymentMethods,
    mergePrepaidCardBalances,
    setCheckoutPaymentMethodId,
    setDefaultPaymentMethodId,
  } = usePaymentContext();
  const paymentMethod: IPaymentMethod | null = getPrepaidPaymentMethod();
  const currentAmount = (paymentMethod && getBalanceFromPaymentMethods(paymentMethod)) || 0;
  const { getPrepaidByNumber } = usePrepaidByNumber();
  const [cardBalance, setCardBalance] = useState(0);
  const [isCardLoading, setIsCardLoading] = useState(false);
  const [oldGiftCardNumber, setOldGiftCardNumber] = useState('');
  const [isDefaultPayment, setIsDefaultPayment] = useState(false);
  const [showBalance, setShowBalance] = useState(
    !isCardLoading && !errors.giftCardNumber && cardBalance > 0
  );
  // @ts-ignore
  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Vaulting Gift Card',
  });

  const showGiftCardAddingErrorModal = (error: unknown) => {
    // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Error | ... Remove this comment to see the full error message
    openErrorDialog({ message: formatMessage({ id: 'giftCardAddingError' }), error });
  };

  const transferCard = useCallback(
    async (newGiftCardNumber: string) => {
      if (newGiftCardNumber) {
        const { data } = await getPrepaidByNumber({ cardNumber: newGiftCardNumber });
        if (!data) {
          showGiftCardAddingErrorModal(errors);
          setCardBalance(0);
        } else {
          if (data?.balance === 0) {
            openErrorDialog({ message: formatMessage({ id: 'giftCardAlreadyRedeem' }) });
          } else {
            setCardBalance(data?.balance || 0);
            setShowBalance(true);
          }
        }
        setIsCardLoading(false);
      }
    },
    [errors, formatMessage, getPrepaidByNumber, openErrorDialog, showGiftCardAddingErrorModal]
  );

  const handleGiftCardChange = useCallback(
    async (newGiftCardNumber: string) => {
      setIsCardLoading(true);
      await handleCardNumberChange(newGiftCardNumber);

      if (isValidGiftCardNumberCheck(newGiftCardNumber)) {
        transferCard(newGiftCardNumber?.replace(/\s+/g, ''));
      } else if (errors) {
        setIsCardLoading(false);
      }
    },
    [errors, handleCardNumberChange, isValidGiftCardNumberCheck, transferCard]
  );

  const confirmTransferCard = async () => {
    setIsCardLoading(true);
    try {
      if (!isCardLoading && !errors.giftCardNumber && cardBalance > 0) {
        await mergePrepaidCardBalances({
          sourceCardNumber: giftCardNumber || '',
        });
        // Setting Default Payment method
        if (isDefaultPayment) {
          setCheckoutPaymentMethodId(CurrentSelectedMethodType.ADD_NEW_GIFT_CARD);
          setDefaultPaymentMethodId(CurrentSelectedMethodType.ADD_NEW_GIFT_CARD);
        }
        goBack();
      }
    } catch (error) {
      // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Error | ... Remove this comment to see the full error message
      openErrorDialog({ message: formatMessage({ id: 'giftCardAddingError' }), error });
    } finally {
      setIsCardLoading(false);
    }
  };

  const showBalanceContainer = useCallback(() => {
    setOldGiftCardNumber(giftCardNumber);
    handleGiftCardChange(giftCardNumber);
  }, [giftCardNumber, handleGiftCardChange]);

  const HiddenAppHeader = portalToReplaceHeader(() => {
    return (
      <Box _ios={{ marginTop: '$12' }} _android={{ marginTop: '$8' }} marginTop="$0">
        <Pressable
          onPress={() => {
            if (!showBalance) {
              goBack();
            } else {
              setShowBalance(false);
            }
          }}
          padding="$4"
        >
          <StyledBackIcon variant="back" />
        </Pressable>
        <Header alignSelf="center" variant="headerTwo" fontSize="lg" top="$4" position="absolute">
          {formatMessage({ id: 'transferGiftCard' })}
        </Header>
      </Box>
    );
  });

  const HiddenAppFooter = portalToFooter(() => {
    if (loyaltyUserLoading || isCardLoading) {
      return (
        <Box padding="$4" bgColor={primitive.$white}>
          <StyledActionButton isLoading={true}></StyledActionButton>
        </Box>
      );
    }

    return (
      <Box padding="$4" bgColor={primitive.$white}>
        {showBalance && (
          <StyledActionButton onPress={confirmTransferCard}>
            {formatMessage({ id: 'addToCart' })}
          </StyledActionButton>
        )}
        {!showBalance && (
          <StyledActionButton onPress={showBalanceContainer}>
            {formatMessage({ id: 'addToCart' })}
          </StyledActionButton>
        )}
      </Box>
    );
  });

  return (
    <LayoutContainer>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }} height="full" width="full">
        <GiftCardContainer>
          <BackgroundAndLogoContainer>
            <LogoAndNameContainer>
              <Icon variant="giftCard" size="xl" mb="$1" />
              <Text
                color={primitive.bk.$bbqBrown}
                fontFamily="heading"
                lineHeight="24px"
                fontSize="2xl"
                mb="$2"
              >
                {formatMessage({ id: 'giftCard' })}
              </Text>
              <Box width="full" flexDirection="row" mb="$1">
                <Text fontSize="sm">{formatMessage({ id: 'currentBalance' })}</Text>

                <Currency
                  amount={currentAmount}
                  textProps={{
                    margin: 0,
                    fontSize: 'sm',
                  }}
                />
              </Box>
              {showBalance && (
                <Box width="full" flexDirection="row" mt="$1">
                  <Text fontSize="sm" fontWeight="bold">
                    {formatMessage({ id: 'newBalance' })}
                  </Text>

                  <Currency
                    amount={cardBalance}
                    textProps={{
                      margin: 0,
                      fontSize: 'sm',
                    }}
                    isBold
                  />
                </Box>
              )}
            </LogoAndNameContainer>
            <Image source={crownsImg} width={162} height={145} marginTop="$7" />
          </BackgroundAndLogoContainer>
        </GiftCardContainer>
        {!showBalance && (
          <KeyboardAwareView withScroll>
            <FieldsContainer>
              <AddGiftCardInput
                onChange={handleCardNumberChange}
                errorMessage={errors?.giftCardNumber}
                label={formatMessage({ id: 'cardNumber' })}
                testID="giftcard-input"
                initialValue={oldGiftCardNumber}
                isRedesign={true}
              />
              <Divider mb="$6" backgroundColor="blackOpacity.30" />
              <DefaultContainer>
                <Text>{formatMessage({ id: 'setAsDefaultPayment' })}</Text>
                <Switch
                  value={isDefaultPayment}
                  onToggle={() => {
                    setIsDefaultPayment(!isDefaultPayment);
                  }}
                  offTrackColor={primitive.$blackOpacity50}
                  onTrackColor={primitive.bk.$crunchyGreen}
                />
              </DefaultContainer>
            </FieldsContainer>
          </KeyboardAwareView>
        )}
        {showBalance && (
          <TransferBalanceContainer cardBalance={cardBalance} giftCardNumber={giftCardNumber} />
        )}

        <HiddenAppHeader />
        <HiddenAppFooter />
        <ErrorDialog />
      </ScrollView>
    </LayoutContainer>
  );
};
