import React, { Suspense } from 'react';

import { Icon } from '@rbilabs/universal-components';

import { StaticImage, StaticImages } from 'components/picture/static-image';
import { theme } from 'styles/configure-theme';

import { LoadingContainer } from './styled';
import { IFavoriteIcon } from './types';

export const FavoriteIcon: React.FC<IFavoriteIcon> = ({
  isFavorite,
  disabled,
  loading,
  iconName = 'favoriteFill',
}) => {
  const color = !isFavorite || disabled ? theme.token('icon-disabled') : theme.token('icon-active');
  const icon = <Icon variant={iconName} color={color} />;
  const loadingIcon = (
    <Suspense fallback={icon}>
      <LoadingContainer>
        <StaticImage
          alt="favoriting loader"
          source={StaticImages.FavoriteLoader}
          style={{ width: 32, height: 32 }}
        />
      </LoadingContainer>
    </Suspense>
  );
  return loading ? loadingIcon : icon;
};
