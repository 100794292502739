import { Header, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { ActionButtonVariants } from 'components/action-button';
import { ActionsheetContent, ActionsheetWrapper } from 'components/action-sheet';

import { ButtonsContainer, DrawerContainer, StyledActionButton } from './styles';

interface NonTraditionalStoreDialogDrawerProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  accessInstructions: string;
}

export const NonTraditionalStoreDialogDrawer = ({
  isOpen,
  onConfirm,
  onCancel,
  accessInstructions,
}: NonTraditionalStoreDialogDrawerProps) => {
  const { formatMessage } = useIntl();

  return (
    <ActionsheetWrapper
      onClose={onCancel}
      isOpen={isOpen}
      onOpenEventMessage="Open Non Traditional Store Drawer"
    >
      <ActionsheetContent>
        <DrawerContainer>
          <Header textAlign="center">
            {formatMessage({ id: 'nonTraditionalStoreDrawerHeader' })}
          </Header>
          <Text marginBottom="$4" textAlign="center">
            {accessInstructions}
          </Text>
          <ButtonsContainer>
            <StyledActionButton onPress={onConfirm}>
              {formatMessage({ id: 'selectAnyways' })}
            </StyledActionButton>
            <StyledActionButton onPress={onCancel} variant={ActionButtonVariants.OUTLINE}>
              {formatMessage({ id: 'chooseAnotherLocation' })}
            </StyledActionButton>
          </ButtonsContainer>
        </DrawerContainer>
      </ActionsheetContent>
    </ActionsheetWrapper>
  );
};
