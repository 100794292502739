import * as React from 'react';
import { FC, useMemo } from 'react';

import { useIntl } from 'react-intl';

import Dialog from 'components/dialog';
import { ILoyaltyWalmartEligibilityQuery } from 'generated/graphql-gateway';
import { UserDetails } from 'state/auth/hooks/types';

import {
  CloseButton,
  ConfirmButton,
  DialogContent,
  DialogIconContainer,
  DialogTitle,
  ErrorIcon,
  ModalActionsContainer,
} from './styled';

type EligibilityErrorModalProps = {
  errorCode?: WalmartEligibilityErrors;
  onTryAgain: () => void;
  onDismiss: () => void;
  user?: UserDetails | null;
  eligibility?: ILoyaltyWalmartEligibilityQuery['loyaltyGetWalmartEligibility'];
};

export enum WalmartEligibilityErrors {
  LOYALTY_ID_ALREADY_LINKED = 'LOYALTY_ID_ALREADY_LINKED',
  PARTNER_USER_ALREADY_LINKED = 'PARTNER_USER_ALREADY_LINKED',
  WRONG_TIER = 'WRONG_TIER',
  DEFAULT = 'DEFAULT',
}

export const EligibilityErrorModal: FC<React.PropsWithChildren<EligibilityErrorModalProps>> = ({
  errorCode,
  onTryAgain,
  onDismiss,
  user,
  eligibility,
}) => {
  const { formatMessage } = useIntl();

  const modalMessageError = useMemo(() => {
    switch (errorCode) {
      case WalmartEligibilityErrors.LOYALTY_ID_ALREADY_LINKED:
        return formatMessage({ id: 'walmartAlreadyLinked' }, { firstName: user?.details.name });
      case WalmartEligibilityErrors.PARTNER_USER_ALREADY_LINKED:
        const email = eligibility?.linkedAccountEmail;
        return formatMessage({ id: 'walmartPartnerUserAlreadyLinked' }, { email });
      case WalmartEligibilityErrors.WRONG_TIER:
        return formatMessage({ id: 'walmartAccountLinkingInterrupted' });
      default:
        return formatMessage({ id: 'walmartAccountLinkingInterrupted' });
    }
  }, [eligibility?.linkedAccountEmail, errorCode, formatMessage, user?.details.name]);

  return (
    !!errorCode && (
      <Dialog
        showDialog
        headingComponent={
          <DialogTitle>{formatMessage({ id: 'walmartErrorLinkingAccounts' })}</DialogTitle>
        }
        bodyComponent={<DialogContent>{modalMessageError}</DialogContent>}
        onDismiss={onDismiss}
        modalAppearanceEventMessage="Walmart+ eligibility error modal"
        image={
          <DialogIconContainer>
            <ErrorIcon />
          </DialogIconContainer>
        }
        actions={
          <ModalActionsContainer>
            {[WalmartEligibilityErrors.DEFAULT, WalmartEligibilityErrors.WRONG_TIER].includes(
              errorCode
            ) ? (
              <>
                <ConfirmButton onPress={onTryAgain}>
                  {formatMessage({ id: 'tryAgain' })}
                </ConfirmButton>
                <CloseButton onPress={onDismiss}>{formatMessage({ id: 'close' })}</CloseButton>
              </>
            ) : (
              <ConfirmButton onPress={onDismiss}>{formatMessage({ id: 'okay' })}</ConfirmButton>
            )}
          </ModalActionsContainer>
        }
      />
    )
  );
};
