import * as React from 'react';

import { Skeleton, VStack } from '@rbilabs/universal-components';

import { QuestCardSkeleton } from 'components/quests/quest-card';

export const QuestCardListSkeleton = () => {
  return (
    <VStack space="$8">
      <Skeleton alignSelf="center" rounded="full" h="$4" w="50%" />
      <QuestCardSkeleton />
      <QuestCardSkeleton />
      <QuestCardSkeleton />
    </VStack>
  );
};
