import { Box } from '@rbilabs/universal-components';

import { primitive } from 'styles/constants/primitives';

export const GeneralTopContainer = Box.withConfig<{
  isNative?: boolean;
}>(p => ({
  backgroundColor: primitive.bk.$blueWalmart,
  flexDirection: 'row',
  justifyContent: 'center',
  paddingTop: p.isNative ? '$20' : '$10',
  paddingLeft: '$2.5',
  paddingRight: '$2.5',
}));

export const GeneralBottomContainer = Box.withConfig<{
  isNative?: boolean;
}>(p => ({
  paddingTop: 25,
  paddingBottom: p.isNative ? 80 : 25,
  alignItems: 'center',
}));

export const LeftButtonOne = Box.withConfig({
  width: 1,
  height: 23,
  marginTop: 100,
  backgroundColor: 'white',
});

export const LeftButtonTwo = Box.withConfig({
  width: 1,
  height: 50,
  marginTop: '$8',
  backgroundColor: 'white',
});

export const LeftButtonThree = Box.withConfig({
  width: 1,
  height: 50,
  marginTop: '$2.5',
  backgroundColor: 'white',
});

export const RightButton = Box.withConfig({
  width: 1,
  height: 70,
  marginTop: 190,
  backgroundColor: 'white',
});

export const InnerWhiteContainer = Box.withConfig({
  height: 400,
  width: 306,
  borderTopLeftRadius: 52,
  borderTopRightRadius: 52,
  borderBottomWidth: 0,
  borderWidth: 5,
  borderColor: 'white',
});

export const InnerGrayContainer = Box.withConfig({
  height: 395,
  width: 296,
  borderTopLeftRadius: 46,
  borderTopRightRadius: 46,
  borderWidth: 10,
  borderBottomWidth: 0,
  borderColor: '#D2D2D9',
});

export const WalmartLogoContainer = Box.withConfig({
  width: 140,
  height: 52,
  backgroundColor: 'white',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 34,
  position: 'absolute',
  top: 320,
  left: 60,
});

export const InnerBottomContainer = Box.withConfig({
  maxWidth: '$80',
});
