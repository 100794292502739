import React, {
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import { Box } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';
import { FlatList } from 'react-native';

import { LayoutContainer } from 'components/layout/styled';
import useFilterModal from 'components/nutrition/use-filter-modal';
import { useIsMobileBreakpoint } from 'hooks/use-media-query';
import { LoadingIndicator } from 'pages/store-locator/components';
import { INutritionExplorerWidget } from 'remote/queries/fragments/nutrition-explorer-widget';
import { useErrorContext } from 'state/errors';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { Accordion } from './accordion';
import { MoreOptions } from './more-options';
import { Tags } from './tags';
import { IFilter, NutritionExplorerMenuObjectProductsTuple } from './types';
import { filterData, getFilterGroup, getParsedMenuData } from './utils';

const MadLibSelect = lazy(() => import('./mad-lib-select'));

const BlackBackground = Box.withConfig({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  backgroundColor: Styles.color.black,
  height: {
    base: '$32',
    md: '$48',
  },
});

const Content = Box.withConfig({
  alignItems: 'center',
  width: '100%',
  paddingTop: {
    base: '$12',
    md: '$12',
  },
  paddingBottom: {
    base: '$13.5',
    md: '$20',
  },
  paddingX: {
    base: '$4',
    md: '$0',
  },
});

const InnerContainer = Box.withConfig({
  width: '100%',
  paddingBottom: {
    base: '$4',
    md: '$5',
  },
});

const FiltersContainer = InnerContainer.withConfig({
  width: '100%',
  backgroundColor: '__legacyToken.background-pattern',
  borderRadius: '8px',
  shadow: '2',
  paddingY: {
    base: '$2',
    md: '$8',
  },
  paddingX: {
    base: '$2',
    md: '$13.5',
  },
});

const MadLibSelectPlaceholder = Box.withConfig({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',

  height: {
    base: '58px',
    md: '82px',
  },
});

const NutritionExplorerWidget: React.FC<React.PropsWithChildren<INutritionExplorerWidget>> = ({
  menu,
  categoryWhitelist,
  viewMenuButtonText,
  moreOptionsButtonText,
  madLibFilterGroup: sanityMadLibFilterGroup,
  modalFilterGroups: sanityModalFilterGroups,
}) => {
  const { setSanityDataRef } = useErrorContext();
  const enableNutritionExplorerAllergens = useFlag(
    LaunchDarklyFlag.ENABLE_NUTRITION_EXPLORER_ALLERGENS
  );

  const isMobile = useIsMobileBreakpoint();

  useEffect(() => {
    setSanityDataRef({
      data: {
        menu,
        categoryWhitelist,
        viewMenuButtonText,
        moreOptionsButtonText,
        sanityMadLibFilterGroup,
        sanityModalFilterGroups,
      },
    });
  }, [
    menu,
    categoryWhitelist,
    viewMenuButtonText,
    moreOptionsButtonText,
    sanityMadLibFilterGroup,
    sanityModalFilterGroups,
    setSanityDataRef,
  ]);
  const { formatMessage } = useIntl();
  const {
    NutritionFilterModal,
    openModal: openFilterModal,
    closeModal: closeFilterModal,
    isModalOpen: isFilterModalOpen,
  } = useFilterModal();

  const [activeMadLibFilter, setActiveMadLibFilter] = useState<IFilter | null>(null);
  const [activeModalFilters, setActiveModalFilters] = useState<IFilter[]>([]);

  const parsedData: NutritionExplorerMenuObjectProductsTuple[] = useMemo(
    () =>
      getParsedMenuData({
        menu,
        categoryWhitelist,
        enableNutritionExplorerAllergens,
      }),
    [menu, categoryWhitelist, enableNutritionExplorerAllergens]
  );

  const madLibFilterGroup = useMemo(
    () => (sanityMadLibFilterGroup ? getFilterGroup(sanityMadLibFilterGroup) : null),
    [sanityMadLibFilterGroup]
  );

  const modalFilterGroups = useMemo(() => (sanityModalFilterGroups ?? []).map(getFilterGroup), [
    sanityModalFilterGroups,
  ]);

  const filteredData: NutritionExplorerMenuObjectProductsTuple[] = useMemo(() => {
    const activeFilters: IFilter[] = activeModalFilters.concat(
      activeMadLibFilter ? [activeMadLibFilter] : []
    );

    return filterData(parsedData, activeFilters);
  }, [parsedData, activeModalFilters, activeMadLibFilter]);

  const productsCount = useMemo(
    () => filteredData.reduce((totalCount, [, products]) => totalCount + products.length, 0),
    [filteredData]
  );

  const onMadLibFilterChange = useCallback((newFilter: IFilter | null) => {
    setActiveMadLibFilter(newFilter);
  }, []);

  const onOpenFilterModal = useCallback(() => {
    openFilterModal();
  }, [openFilterModal]);

  useLayoutEffect(() => {
    // when showProductAccordions changes value, scroll to top
    // scrollTo({ top: 0, behavior: 'auto' });
  }, []);

  return (
    <LayoutContainer testID="nutrition-explorer" isFullContainer={isMobile}>
      <BlackBackground />
      <Content>
        <InnerContainer zIndex={2}>
          <FiltersContainer>
            <Suspense
              fallback={
                <MadLibSelectPlaceholder>
                  <LoadingIndicator />
                </MadLibSelectPlaceholder>
              }
            >
              <MadLibSelect
                prompt={madLibFilterGroup?.description?.locale}
                selectedFilter={activeMadLibFilter}
                filters={madLibFilterGroup?.filters ?? []}
                // @ts-expect-error TS(2322) FIXME: Type '(newFilter: IFilter | null) => void' is not ... Remove this comment to see the full error message
                onFilterChange={onMadLibFilterChange}
              />
              <Tags activeModalFilters={activeModalFilters} openFilterModal={onOpenFilterModal} />
              <MoreOptions
                openFilterModal={onOpenFilterModal}
                buttonText={moreOptionsButtonText?.locale || formatMessage({ id: 'more' })}
              />
            </Suspense>
          </FiltersContainer>
        </InnerContainer>
        <InnerContainer>
          <FlatList
            data={filteredData}
            keyExtractor={item => item[0]._id}
            renderItem={({ item: [parentMenuObject, products], index }) => (
              <Accordion
                hasBorderTop={index > 0}
                parentMenuObject={parentMenuObject}
                products={products}
                startsOpen={index === 0}
                key={parentMenuObject._id}
              />
            )}
          />
        </InnerContainer>
      </Content>
      <NutritionFilterModal
        resultsLeft={productsCount}
        isModalOpen={isFilterModalOpen}
        filterGroups={modalFilterGroups}
        activeFilters={activeModalFilters}
        onDismiss={closeFilterModal}
        applyFilters={setActiveModalFilters}
      />
    </LayoutContainer>
  );
};

export default NutritionExplorerWidget;
