import { Svg, SvgPath, SvgProps, registerSVG } from '../../../components/svg';

export function BkSunrise(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <SvgPath d="M22.2999 17.5H17.9C17.9 17.3 18 17 18 16.7C18 13.4 15.3 10.7 12 10.7C8.69995 10.7 5.99995 13.4 5.99995 16.7C5.99995 17 5.99995 17.2 6.09995 17.5H1.59995C1.09995 17.5 0.699951 17.9 0.699951 18.5C0.699951 19.1 1.09995 19.5 1.59995 19.5H22.2C22.7 19.5 23.1 19.1 23.1 18.5C23.1 17.9 22.7999 17.5 22.2999 17.5ZM7.89995 16.7C7.89995 14.5 9.69995 12.7 11.9 12.7C14.1 12.7 15.9 14.5 15.9 16.7C15.9 17 15.9 17.2 15.8 17.5H7.99995C7.99995 17.2 7.89995 17 7.89995 16.7Z" />
      <SvgPath d="M7.70003 9.4C7.80003 9.6 8.00003 9.8 8.20003 9.9C8.40003 10 8.50003 10 8.60003 10C8.70003 10 8.90003 10 9.00003 9.9C9.50003 9.7 9.70003 9.1 9.50003 8.6L8.10003 5.6C7.90003 5.1 7.30003 4.9 6.80003 5.1C6.30003 5.3 6.10003 5.9 6.30003 6.4L7.70003 9.4Z" />
      <SvgPath d="M23.9 11.9C23.7 11.4 23.2 11.1 22.6 11.3L18.8 12.5C18.5 12.6 18.3 12.8 18.2 13C18.1 13.2 18.1 13.5 18.1 13.8C18.2 14.1 18.4 14.3 18.6 14.4C18.7 14.5 18.9 14.5 19 14.5C19.1 14.5 19.2 14.5 19.3 14.4L23.1 13.2C23.8 13 24.1 12.4 23.9 11.9Z" />
      <SvgPath d="M14.9 9.9C15 9.9 15.2 10 15.4 10C15.5 10 15.6 10 15.7 10C16 9.9 16.2 9.7 16.3 9.5L17.9 6.5C17.9 6.2 18 6 17.9 5.7C17.8 5.4 17.6 5.2 17.4 5.1C17.2 5 16.9 5 16.6 5C16.3 5.1 16.1 5.3 16 5.5L14.4 8.5C14.3 8.7 14.2 9 14.3 9.3C14.5 9.5 14.7 9.7 14.9 9.9Z" />
      <SvgPath d="M4.50002 15C4.90002 15 5.20002 14.8 5.40002 14.5C5.60002 14 5.40002 13.4 5.00002 13.2L1.50002 11.3C1.00002 11 0.400024 11.2 0.100024 11.7C2.44156e-05 12 2.44156e-05 12.2 0.100024 12.5C0.100024 12.8 0.300024 13 0.600024 13.1L4.10002 14.9C4.20002 14.9 4.40002 15 4.50002 15Z" />
    </Svg>
  );
}

registerSVG('BkSunrise', BkSunrise);
