import { get } from 'lodash-es';

import { IPluWithQuantity } from '@rbi-ctg/menu';

export const sortQuantityBasedPlus = (plus: IPluWithQuantity[]) => {
  return plus.slice().sort((left, right) => (left.quantity > right.quantity ? 1 : -1));
};

export const minQuantityPlu = (plus: IPluWithQuantity[] | undefined = []) => {
  const sorted = sortQuantityBasedPlus(plus);
  return sorted[0];
};

export const getQuantityBasedPlu = (plus: IPluWithQuantity[], quantity?: number): string | null => {
  if (!quantity) {
    return minQuantityPlu(plus)?.plu || null;
  }

  return get(plus.find(plu => plu.quantity === quantity)!, 'plu', null);
};
