export const units = {
  calories: 'cal',
  'calories-from-fat': 'cal',
  fat: 'g',
  'saturated-fat': 'g',
  'trans-fat': 'g',
  saturatedfat: 'g',
  transfat: 'g',
  weight: 'g',
  cholesterol: 'mg',
  sodium: 'mg',
  salt: 'g',
  carbohydrates: 'g',
  fibre: 'g',
  sugar: 'g',
  proteins: 'g',
  fiber: 'g',
  'vitamin-a': '%DV',
  'vitamin-c': '%DV',
  calcium: '%DV',
  iron: '%DV',
  caffeine: 'Mg',
  wheat: 'Y/N',
  milk: 'Y/N',
  eggs: 'Y/N',
  soy: 'Y/N',
  fish: 'Y/N',
  peanuts: 'Y/N',
  nuts: 'Y/N',
  sesame: 'Y/N',
  mustard: 'Y/N',
  sulphites: 'Y/N',
  vegetarian: 'Y/N',
  'low-in-fat': 'Y/N',
  'source-of-fiber': 'Y/N',
  'source-of-calcium': 'Y/N',
};

export const getUnitForNutrient = (nutrient: string): string => {
  const normalizedNutrient = nutrient.toLowerCase().replace(/\\s+/, '-');
  return ['calories', 'calories-from-fat'].includes(normalizedNutrient)
    ? 'Cal'
    : units[normalizedNutrient];
};
