import type { CustomTextFn } from './types';

export const getStartQuestText: CustomTextFn = formatMessage =>
  formatMessage({ id: 'startChallenge' });

export const getQuestText: CustomTextFn = formatMessage => formatMessage({ id: 'challenge' });
export const getQuestsText: CustomTextFn = formatMessage => formatMessage({ id: 'challenges' });
export const getContinueQuestText: CustomTextFn = formatMessage =>
  formatMessage({ id: 'continueChallenge' });
export const getQuestTermsText: CustomTextFn = formatMessage =>
  formatMessage({ id: 'questTerms' }, { quest: formatMessage({ id: 'challenge' }) });

export const getQuestCompletedDescriptionText: CustomTextFn = formatMessage =>
  formatMessage({ id: 'questCompletedDescriptionPlk' });

export const getLinkText: CustomTextFn = formatMessage => formatMessage({ id: 'viewTerms' });
export const getQuestStartedBadgeText: CustomTextFn = formatMessage =>
  formatMessage({ id: 'challengeStarted' });

export const getQuestLoadErrorText: CustomTextFn = formatMessage =>
  formatMessage({ id: 'questLoadError' }, { quest: formatMessage({ id: 'challenge' }) });
export const getQuestCheckConnectionText: CustomTextFn = formatMessage =>
  formatMessage({ id: 'questCheckNetworkText' });
