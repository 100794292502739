import { DAY_PART_SELECTIONS, IDayPartBoundary } from 'state/menu/main-menu/types';

export const findBurgersForBreakfastDayPart = (
  dayParts: IDayPartBoundary[]
): IDayPartBoundary | undefined => {
  return dayParts.find(({ key: dayPart }) => dayPart === DAY_PART_SELECTIONS.BURGERS_FOR_BREAKFAST);
};

export const findDayPart = (
  dayPartToFind: string,
  dayParts: IDayPartBoundary[]
): IDayPartBoundary | undefined => {
  return dayParts.find(({ key }) => key === dayPartToFind);
};
