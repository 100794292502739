import { useSyncExternalStore } from 'react';

import AuthStorage from 'utils/cognito/storage';
import { StorageKeys } from 'utils/local-storage';

class AuthSyncModule {
  private cognitoId: null | string = null;
  private listeners = new Set<Function>();

  private _setItem: typeof AuthStorage.setItem;
  private _getItem: typeof AuthStorage.getItem;
  private _sync: typeof AuthStorage.sync;

  constructor() {
    this.cognitoId = null;
    this.listeners = new Set();

    this._setItem = AuthStorage.setItem;
    this._getItem = AuthStorage.getItem;
    this._sync = AuthStorage.sync;

    // Bind to the app start sync so we can get the user auth token
    // as soon as its loaded from memory
    AuthStorage.sync = async () => {
      const syncedValues = await this._sync();

      this.cognitoId = this._getItem(StorageKeys.USER_AUTH_TOKEN);

      return syncedValues;
    };

    AuthStorage.getItem = (key: string) => {
      const value = this._getItem(key);

      if (key === StorageKeys.USER_AUTH_TOKEN) {
        this.handleValue(value);
      }

      return value;
    };

    AuthStorage.setItem = (key: string, value: any) => {
      this._setItem(key, value);

      if (key === StorageKeys.USER_AUTH_TOKEN) {
        this.handleValue(value);
      }

      return value;
    };
  }

  destroy() {
    AuthStorage.setItem = this._setItem;
    AuthStorage.getItem = this._getItem;
  }

  transformValue = (value: any) => {
    return typeof value === 'string' && value === 'null'
      ? null
      : value === undefined
      ? null
      : value;
  };

  handleValue = (value: any) => {
    const transformedValue = this.transformValue(value);
    if (transformedValue !== this.cognitoId) {
      this.cognitoId = transformedValue;
      this.listeners.forEach(fn => fn());
    }
  };

  subscribe = (listener: any) => {
    this.listeners.add(listener);

    return () => {
      this.listeners.delete(listener);
    };
  };

  getSnapshot = () => {
    return this.cognitoId;
  };
}

const syncModule = new AuthSyncModule();

export const useCognitoId = () => {
  return useSyncExternalStore(syncModule.subscribe, syncModule.getSnapshot);
};
