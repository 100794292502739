import { ComponentProps } from 'react';

import {
  Box,
  HStack,
  IconButton as UclIconButton,
  Input as UclInput,
} from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';

export const Wrapper = HStack.withConfig({
  alignItems: 'center',
});

export const Input = UclInput.withConfig<{ $isHidden?: boolean }>(p => ({
  height: 12,
  width: 12,
  display: p.$isHidden ? 'none' : 'flex',
  margin: 0,
  borderWidth: theme.token('border-width-input'),
  borderRadius: theme.token('border-radius-input'),
  borderColor: 'transparent',
  backgroundColor: theme.token('background-input'),
  textAlign: 'center',
  font: theme.token('text-style-form-input'),
  color: theme.token('text-default'),

  _focus: {
    borderColor: theme.token('border-color-input'),
  },

  _disabled: {
    borderColor: 'transparent',
    color: theme.token('text-disabled'),
  },
}));

export const DisplayedValue = Box.withConfig<{
  $width?: string;
  $disabled?: boolean;
  $overrides?: ComponentProps<typeof Box>;
}>(p => ({
  height: 12,
  width: p.$width,
  minWidth: 12,
  maxWidth: 20,
  backgroundColor: theme.token('background-input'),
  marginX: 2,
  marginY: 0,
  alignItems: 'center',
  justifyContent: 'center',
  color: p.$disabled ? theme.token('text-disabled') : theme.token('text-default'),
  ...p.$overrides,
  _text: {
    ellipsizeMode: 'tail',
    numberOfLines: 1,
    marginX: 2,
  },
  focusable: true,
}));

export const IconButton = UclIconButton.withConfig<{
  disabled?: boolean;
  $overrides?: ComponentProps<typeof UclIconButton>;
}>(p => ({
  marginX: -3,
  _icon: {
    color: p.disabled ? theme.token('icon-disabled') : theme.token('icon-default'),
  },
  _pressed: {
    // Avoid overlapping with numeric input while pressed
    zIndex: 1,
  },
  ...p.$overrides,
}));
