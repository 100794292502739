import { IBackendCartEntries, ICartEntry } from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';
import { CartEntryType } from 'utils/cart/types';

// Not every cartEntry can be mapped. return null for an un-mappable entry
const cartEntryTypesMap = {
  [CartEntryType.combo]: MenuObjectTypes.COMBO,
  [CartEntryType.comboSlot]: MenuObjectTypes.COMBO_SLOT,
  [CartEntryType.item]: MenuObjectTypes.ITEM,
  [CartEntryType.itemOption]: MenuObjectTypes.ITEM_OPTION,
  [CartEntryType.itemOptionModifier]: MenuObjectTypes.ITEM_OPTION_MODIFIER,
  [CartEntryType.offerDiscount]: null,
  [CartEntryType.offerCombo]: null,
  [CartEntryType.offerItem]: null,
};

export const maybeMapCartEntryToMenuObjectIdentifier = (
  entry: ICartEntry | IBackendCartEntries
) => {
  const entryType = cartEntryTypesMap[entry.type];
  const sanityId = entry?.sanityId || ('_id' in entry ? entry._id : '');
  const isMappable = !!(entryType && sanityId);
  return isMappable ? `${entryType}-${sanityId}` : null;
};
