import { useFeatureLoyaltyClaimPointsContentQuery } from 'generated/sanity-graphql';

const FEATURE_LOYALTY_CLAIM_POINTS_SINGLETON_ID = 'claim-points-ui-singleton';

export const useLoyaltyClaimPoints = () => {
  const { data, loading } = useFeatureLoyaltyClaimPointsContentQuery({
    variables: { featureClaimPointsUIId: FEATURE_LOYALTY_CLAIM_POINTS_SINGLETON_ID },
    fetchPolicy: 'cache-and-network',
  });

  return {
    data: data?.ClaimPointsUI,
    loading,
  };
};
