import React from 'react';

import { Box, Text } from '@rbilabs/universal-components';

import Link from 'components/link';
import SanityBlockRenderer, {
  IBlockContentOverrides,
  ISerializers,
  SanityBlockContentLinkProps,
} from 'components/sanity-block-renderer';
import { useFeatureDisclaimers } from 'hooks/use-feature-disclaimers';
import { LoyaltyOffer } from 'state/loyalty/types';

export const isMoreInfoDisclaimerAvailable = (offer: LoyaltyOffer): boolean => {
  const moreInfoText = offer?.moreInfo?.localeRaw?.[0]?.children?.[0]?.text;
  return !!moreInfoText;
};

export const DisclaimerContainer = Box.withConfig({
  maxWidth: '576px',
  marginTop: {
    base: '12',
    md: '8',
  },
  marginBottom: {
    base: '12',
    md: '6',
  },
  textAlign: 'center',
  marginX: 'auto',
});

const DisclaimerText = Text.withConfig({
  color: Styles.color.grey.one,
  fontSize: 'xs',
  margin: 0,
  textAlign: 'center',
});

// TODO - RN: Use withConfig when ExternalLink is migrated to RN
const DisclaimerLink = Link.withConfig({
  isExternal: true,
  fontSize: 'xs',
  lineHeight: 16 * 1.33,
  color: Styles.color.grey.one,
  textDecoration: 'underline',
});

const TextBlock = SanityBlockRenderer.withConfig({
  textProps: {
    display: 'flex',
    textAlign: 'center',
  },
});

const disclaimerBlockContentOverrides: IBlockContentOverrides = {
  // @ts-expect-error TS(2339) FIXME: Property 'textProps' does not exist on type 'Props... Remove this comment to see the full error message
  normal: p => <DisclaimerText {...p.textProps}>{p.children}</DisclaimerText>,
};

const disclaimerBlockSerializers: ISerializers = {
  marks: {
    link: ({ children = [], mark = {} }: SanityBlockContentLinkProps) => {
      const [linkText] = children;
      const { href } = mark;
      return href && linkText ? <DisclaimerLink to={href}>{linkText}</DisclaimerLink> : null;
    },
  },
};

export const CustomDisclaimer = ({
  content,
  textProps,
}: {
  content: React.ComponentProps<typeof SanityBlockRenderer>['content'];
  textProps?: React.ComponentProps<typeof TextBlock>['textProps'];
}) => {
  return (
    <TextBlock
      blockContentOverrides={disclaimerBlockContentOverrides}
      serializers={disclaimerBlockSerializers}
      content={content}
      textProps={textProps}
    />
  );
};

interface IDisclaimerProps {
  offer: LoyaltyOffer | null;
}

export const OfferDisclaimer: React.FC<React.PropsWithChildren<IDisclaimerProps>> = ({ offer }) => {
  const { featureDisclaimers } = useFeatureDisclaimers();
  const { locale: generalOfferDisclaimer } = featureDisclaimers?.offers || {};

  if (!offer) {
    return null;
  }

  const isMoreInfoDisclaimer = isMoreInfoDisclaimerAvailable(offer);

  return (
    <DisclaimerContainer>
      {isMoreInfoDisclaimer ? (
        <CustomDisclaimer content={offer?.moreInfo?.localeRaw} />
      ) : (
        <DisclaimerText variant="copyOne">{generalOfferDisclaimer}</DisclaimerText>
      )}
    </DisclaimerContainer>
  );
};
