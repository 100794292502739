import { ReactNode, useCallback, useState } from 'react';

import { LayoutChangeEvent, useWindowDimensions } from 'react-native';

import { ActionsheetContent } from 'components/action-sheet';
import { FocusableContainer } from 'components/modal/focusable-container';
import { KeyboardAwareView } from 'components/ucl/keyboard-aware-view';
import { useScrollRestoration } from 'hooks/use-scroll-restoration';

import { useInputFocusFix } from './use-input-focus-fix';

const calculateScrollViewHeight = (height: number, windowHeight: number) => {
  const twentyPercentOfWindowHeight = (windowHeight * 20) / 100;
  return height >= windowHeight ? windowHeight - twentyPercentOfWindowHeight : height;
};

export const ModalAuthActionSheetContent: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { height: windowHeight } = useWindowDimensions();
  const [scrollViewHeight, setScrollViewHeight] = useState<number | undefined>(undefined);
  const scrollProps = useScrollRestoration('modal-auth');

  useInputFocusFix({
    setScrollViewHeight,
    scrollViewHeight,
    windowHeight,
  });

  const handleScrollViewLayout = useCallback(
    (event: LayoutChangeEvent) => {
      const { height } = event.nativeEvent.layout;
      const finalHeight = calculateScrollViewHeight(height, windowHeight);
      setScrollViewHeight(finalHeight);
    },
    [windowHeight]
  );

  return (
    <ActionsheetContent alignItems="stretch">
      <KeyboardAwareView
        onLayout={handleScrollViewLayout}
        style={{ maxHeight: scrollViewHeight }}
        keyboardShouldPersistTaps="handled"
        withScroll
        testID={'keyboard-aware-auth-modal'}
        {...scrollProps}
      >
        <FocusableContainer>{children}</FocusableContainer>
      </KeyboardAwareView>
    </ActionsheetContent>
  );
};
