import { EventName, addOnceEventListener } from 'utils/event-hub';

import { stopTrace } from './trace';
import { TraceName } from './types';

export const initializeNativePerformanceMonitors = () => {
  addOnceEventListener(EventName.JAVASCRIPT_START, () => {
    // This trace is started in native
    stopTrace(TraceName.JAVASCRIPT_START);
  });

  addOnceEventListener(EventName.REACT_START, () => {
    // This trace is started in native
    stopTrace(TraceName.REACT_START);
  });
};
