import { Box, HStack, Text } from '@rbilabs/universal-components';

import { AccordionActivatorButton } from 'components/accordion/styled';
import { theme } from 'styles/configure-theme';
import { primitive } from 'styles/constants/primitives';

export const SignupAccordionItem = Box;

export const SignupAccordionActivatorButton = AccordionActivatorButton.withConfig({
  padding: 0,
});

export const SignupAccordionListItemContainer = HStack.withConfig({
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginBottom: primitive.$spacing6,
});

export const SignupAccordionTitle = Text.withConfig({
  variant: 'copyTwo',
  color: theme.token('text-default'),
  alignSelf: 'flex-start',
  margin: 0,
  marginTop: '$1',
});

export const ContentContainer = Box;
