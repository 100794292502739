import {
  ICombo,
  IComboSlot,
  IComboSlotOption,
  IItem,
  IItemOption,
  IPicker,
  IPickerOption,
  ISanityCombo,
  ISanityComboSlot,
  ISanityComboSlotOption,
  ISanityItem,
  ISanityItemOption,
  ISanityPickerOption,
  IWithPricingProps,
  MenuObject,
  SanityMenuObject,
} from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';
import { DAY_PART_SELECTIONS, IDayPartBoundary, IValidDayPart } from 'state/menu/main-menu/types';
import {
  IAvailabilityObject,
  IMenuObjectWithDaypart,
  comboIsAvailable,
  comboSlotIsAvailable,
  comboSlotOptionIsAvailable,
  getMenuItemDayParts,
  isAvailableForActiveDayParts,
  itemIsAvailable,
  itemOptionIsAvailable,
  itemOptionModifierIsAvailable,
} from 'utils/availability';
import { ComboSlotUiPattern } from 'utils/cart';
import { modifiersForItem } from 'utils/menu/modifiers';

interface IUseFilterForAvailabilityProps extends IWithPricingProps {
  activeDayParts?: IDayPartBoundary[];
  dayParts?: ReadonlyArray<IValidDayPart>;
  storeHasBurgersForBreakfast?: boolean;
}

export const applyFiltersForAvailability = ({
  activeDayParts = [],
  dayParts = [],
  prices,
  vendor,
  storeHasBurgersForBreakfast,
}: IUseFilterForAvailabilityProps) => {
  const filterForAvailability = (
    menuData: SanityMenuObject[] | SanityMenuObject
  ): MenuObject | MenuObject[] | null => {
    if (!menuData) {
      return null;
    }
    const reducePickerChildren = (children: ISanityPickerOption[]): IPickerOption[] => {
      return children.reduce<IPickerOption[]>((availableOpts, option: ISanityPickerOption) => {
        if (!option.option) {
          return availableOpts;
        }

        if (
          option.option._type === MenuObjectTypes.COMBO &&
          comboIsAvailable(option.option, vendor, prices)
        ) {
          const remappedOption = {
            ...option.option,
            options: reduceComboSlots(option.option),
          } as ICombo;

          if (option?.option?.mainItem?.options) {
            const remappedItem = modifiersForItem(option.option.mainItem!);
            remappedOption.mainItem = {
              ...remappedItem,
              // @ts-expect-error TS(2345) FIXME: Argument of type '{ options: { options: { _key: st... Remove this comment to see the full error message
              options: reduceItemOptions(remappedItem),
            };
          }

          return availableOpts.concat({
            ...option,
            option: remappedOption,
          });
        }

        if (
          option.option._type === MenuObjectTypes.ITEM &&
          itemIsAvailable(option.option, vendor, prices)
        ) {
          const itemOptions: ISanityItemOption[] = option.option.options;

          const remappedItem: IItem = {
            ...option.option,
            // @ts-expect-error TS(2345) FIXME: Argument of type '{ options: { options: { _key: st... Remove this comment to see the full error message
            options: itemOptions ? reduceItemOptions(modifiersForItem(option.option)) : [],
          };

          return availableOpts.concat({
            ...option,
            option: remappedItem,
          });
        }

        return availableOpts;
      }, []);
    };

    const reduceComboSlots = (combo: ISanityCombo): IComboSlot[] => {
      const comboSlots = combo?.options || [];
      return comboSlots.reduce<IComboSlot[]>((availableOpts, comboSlot: ISanityComboSlot) => {
        if (comboSlotIsAvailable(comboSlot, vendor, prices)) {
          return availableOpts.concat({
            ...comboSlot,
            options: reduceItems(comboSlot),
          });
        }
        return availableOpts;
      }, []);
    };

    const reduceItemOptions = (item: IItem) => {
      return item.options.reduce<IItemOption[]>((availableOpts, option) => {
        if (itemOptionIsAvailable({ item, itemOption: option, vendor, prices })) {
          return availableOpts.concat({
            ...option,
            options: option.options.filter(itemOptionModifier =>
              itemOptionModifierIsAvailable({
                item,
                itemOption: option,
                itemOptionModifier,
                vendor,
                prices,
              })
            ),
          });
        }
        return availableOpts;
      }, []);
    };

    const reduceItems = (comboSlot: ISanityComboSlot): IComboSlotOption[] => {
      // If UI is collapsed we want to return all options so that proper nutrition can be displayed.
      if (comboSlot.uiPattern === ComboSlotUiPattern.COLLAPSED) {
        return comboSlot.options as IComboSlotOption[];
      }

      return comboSlot.options.reduce<IComboSlotOption[]>(
        (availableOpts, option: ISanityComboSlotOption) => {
          const { option: comboSlotOption } = option;

          // TODO: evaluate what happens if comboSlotOption is a picker
          if (comboSlotOptionIsAvailable(comboSlotOption as ISanityItem, vendor, prices)) {
            const filteredOption = filterForAvailability(comboSlotOption);

            if (!filteredOption) {
              return availableOpts;
            }

            return availableOpts.concat({
              ...option,
              option: filteredOption as IItem | IPicker,
            });
          }

          return availableOpts;
        },
        []
      );
    };

    // if an array filter each item in the array for availability
    if (Array.isArray(menuData)) {
      return menuData.map(filterForAvailability) as MenuObject[];
    }

    if (dayParts.length && activeDayParts.length) {
      const itemDayParts = getMenuItemDayParts(menuData as IAvailabilityObject);
      const isItemBurgersForBreakfast = itemDayParts.includes(
        DAY_PART_SELECTIONS.BURGERS_FOR_BREAKFAST.toLowerCase()
      );

      // Check if the store sells burgers for breakfast (B4B). If not, check for other active dayparts.
      if (!storeHasBurgersForBreakfast && isItemBurgersForBreakfast) {
        // Remove B4B daypart if the store doesn't sell B4B
        const filteredDayParts = itemDayParts.filter(
          dayPart =>
            dayPart.toLowerCase() !== DAY_PART_SELECTIONS.BURGERS_FOR_BREAKFAST.toLowerCase()
        );

        // Check if any of the remaining dayparts are active
        const hasActiveDayPart = filteredDayParts.some(dayPart =>
          activeDayParts.some(
            activeDayPart => dayPart.toLowerCase() === activeDayPart.key.toLowerCase()
          )
        );

        if (!hasActiveDayPart) {
          return null;
        }
      } else {
        if (
          !isAvailableForActiveDayParts({
            activeDayParts,
            menuData: menuData as IMenuObjectWithDaypart,
          })
        ) {
          return null;
        }
      }
    }

    switch (menuData._type) {
      case MenuObjectTypes.SECTION: {
        const filteredChildren = (filterForAvailability(menuData.options!) as MenuObject[]).filter(
          option => {
            if (!option) {
              return false;
            }
            return option._type === MenuObjectTypes.PICKER ? option?.options?.length > 0 : true;
          }
        );

        return { ...menuData, options: filteredChildren };
      }
      case MenuObjectTypes.PICKER: {
        if (menuData.pickerAspects) {
          const options = reducePickerChildren(menuData.options);

          return {
            ...menuData,
            options,
          };
        }
        return null;
      }
      case MenuObjectTypes.COMBO: {
        if (comboIsAvailable(menuData, vendor, prices)) {
          const mainItem = menuData.mainItem
            ? (filterForAvailability(menuData.mainItem) as IItem | null)
            : null;
          return {
            ...menuData,
            mainItem,
            options: reduceComboSlots(menuData),
          };
        }
        return null;
      }
      case MenuObjectTypes.ITEM:
        if (itemIsAvailable(menuData, vendor, prices)) {
          const itemWithOptionsRemapped = modifiersForItem(menuData);

          return {
            ...itemWithOptionsRemapped,
            // @ts-expect-error TS(2345) FIXME: Argument of type '{ options: { options: { _key: st... Remove this comment to see the full error message
            options: reduceItemOptions(itemWithOptionsRemapped),
          };
        }
        return null;

      default:
        return null;
    }
  };

  return filterForAvailability;
};
