import React from 'react';

import { Text } from '@rbilabs/universal-components';

import SanityBlockRenderer, { IBlockContentOverrides } from 'components/sanity-block-renderer';
import { isSanityLocaleBlockContent } from 'hooks/use-locale-smart-block-content/types';
import { primitive } from 'styles/constants/primitives';

import { OptInModalImage } from './components/initial-modal-image';
import {
  ActionWrapper,
  CreateLoyaltyUserButton,
  OptInDialogBody,
  OptInDialogBodyContent,
  OptInDialogBodyText,
  OptInDialogFooter,
  OptInDialogHeader,
  OptInDialogHeading,
} from './styled';
import type { IOptInInitContentProps } from './types';

const ParagraphOptOut = Text.withConfig({
  color: primitive.$white,
  textAlign: 'center',
  fontSize: 'sm',
  lineHeight: 15,
});
const blockTextContentOverrides: IBlockContentOverrides = {
  normal: props => <ParagraphOptOut {...props} />,
};

export const OptInInitContent = ({
  heading,
  description,
  image,
  altImage,
  buttonText,
  disclaimer,
  onActionPress,
}: IOptInInitContentProps) => {
  const disclaimerContent = isSanityLocaleBlockContent(disclaimer) ? disclaimer : null;

  return (
    <>
      <OptInModalImage image={image} alt={altImage} />

      <OptInDialogHeader>
        <OptInDialogHeading>{heading}</OptInDialogHeading>
      </OptInDialogHeader>

      <OptInDialogBodyContent>
        <OptInDialogBody>
          <OptInDialogBodyText>{description}</OptInDialogBodyText>
        </OptInDialogBody>

        <OptInDialogFooter>
          <ActionWrapper>
            <CreateLoyaltyUserButton testID="loyalty-opt-in-button" onPress={onActionPress}>
              {buttonText}
            </CreateLoyaltyUserButton>
          </ActionWrapper>

          {!!disclaimerContent && (
            <SanityBlockRenderer
              w="full"
              marginTop="$2"
              linkProps={{
                lineHeight: 0,
                color: primitive.$white,
              }}
              content={disclaimerContent.localeRaw}
              blockContentOverrides={blockTextContentOverrides}
            />
          )}
        </OptInDialogFooter>
      </OptInDialogBodyContent>
    </>
  );
};
