import { Svg, SvgPath, SvgProps, registerSVG } from '../../../components/svg';

export function BkAccount(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <SvgPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.039 13.6089C17.8742 13.6176 21.5996 15.8709 21.5996 19.9339C21.5996 20.3984 21.7465 21.8696 21.3853 21.9431C21.2033 21.9803 20.3118 21.9964 19.697 21.9995L19.4364 22C18.2643 21.9973 16.7266 21.9352 16.3723 21.9195C15.0463 21.8635 14.1086 21.8416 13.4141 21.8399C11.1818 21.8373 7.27712 22.0105 5.34665 21.9099C3.90863 21.8355 2.7925 21.9554 2.48723 21.5486C2.32541 21.3326 2.37964 19.8324 2.38052 19.4432C2.38664 15.0128 7.65937 13.6019 12.039 13.6089ZM17.4528 7.32448C15.7847 16.4993 1.45875 10.2128 8.49314 3.36294C8.87539 3.04717 9.33636 2.74277 9.8813 2.45499C12.9926 0.817541 18.0808 3.86852 17.4528 7.32448Z"
      />
    </Svg>
  );
}

registerSVG('BkAccount', BkAccount);
