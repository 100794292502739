import { useCallback, useEffect } from 'react';

import { useFeatureWalmartUiLazyQuery } from 'generated/sanity-graphql';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';

const FEATURE_LOYALTY_WALMART_SINGLETON_ID = 'walmart-singleton';

export const useLoyaltyWalmart = () => {
  const dispatch = useAppDispatch();
  const loyaltyWalmartUI = useAppSelector(selectors.loyalty.selectLoyaltyWalmartUI);
  const loyaltyWalmartUILoading = useAppSelector(selectors.loyalty.selectLoyaltyWalmartUILoading);

  const [queryFeatureWalmart] = useFeatureWalmartUiLazyQuery({
    variables: { featureWalmartUIId: FEATURE_LOYALTY_WALMART_SINGLETON_ID },
    fetchPolicy: 'cache-and-network',
  });

  const fetchWalmartUIData = useCallback(async () => {
    dispatch(actions.loyalty.setLoyaltyWalmartUILoading(true));

    const { data } = await queryFeatureWalmart();
    dispatch(actions.loyalty.setLoyaltyWalmartUI(data || null));

    dispatch(actions.loyalty.setLoyaltyWalmartUILoading(false));
  }, [dispatch, queryFeatureWalmart]);

  useEffect(() => {
    if (!loyaltyWalmartUI && !loyaltyWalmartUILoading) {
      fetchWalmartUIData();
    }
  }, [fetchWalmartUIData, loyaltyWalmartUI, loyaltyWalmartUILoading]);

  return {
    data: loyaltyWalmartUI?.Walmart,
    loading: loyaltyWalmartUILoading,
  };
};
