import * as React from 'react';

import ActionButton, { ActionButtonSizes, IActionButtonProps } from 'components/action-button';

import { Badge, FabContainer } from './styled';

export interface FabProps extends IActionButtonProps {
  caption?: string;
  badge?: string | number | undefined;
}

export const Fab: React.FC<React.PropsWithChildren<FabProps>> = ({
  size = ActionButtonSizes.LARGE,
  caption,
  badge,
  onPress,
  ...rest
}) => (
  <FabContainer>
    {badge && <Badge>{badge}</Badge>}
    <ActionButton
      keepIconSize
      size={size}
      shadow="7"
      paddingLeft="$6"
      paddingRight="$6"
      backgroundColor={Styles.color.primary}
      _text={{ fontSize: 'lg' }}
      _hover={{
        backgroundColor: Styles.color.primaryHover,
      }}
      onPress={onPress}
      {...rest}
    >
      {caption}
    </ActionButton>
  </FabContainer>
);
