import { Svg, SvgPath, SvgProps, SvgRect, registerSVG } from '../../../components/svg';

export function Visa(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 25 25">
      <SvgRect x="0.541016" y="4.33984" width="24" height="16" rx="2" fill="white" />
      <SvgRect
        x="0.5"
        y="4.5"
        width="23"
        height="15"
        rx="1.5"
        stroke="black"
        strokeOpacity="0.3"
        fill="white"
      />
      <SvgPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.50001 14.465H7.28845L6.37992 10.974C6.3368 10.8134 6.24524 10.6714 6.11055 10.6045C5.77443 10.4364 5.40405 10.3026 5 10.2351V10.1007H6.95174C7.22111 10.1007 7.42313 10.3026 7.4568 10.537L7.9282 13.0552L9.13917 10.1007H10.3171L8.50001 14.465ZM10.9904 14.4649H9.84619L10.7884 10.1006H11.9326L10.9904 14.4649ZM13.4123 11.3097C13.446 11.0746 13.648 10.9402 13.8837 10.9402C14.2541 10.9065 14.6576 10.974 14.9943 11.1415L15.1963 10.2019C14.8596 10.0675 14.4892 10 14.1531 10C13.0426 10 12.2345 10.6045 12.2345 11.4435C12.2345 12.0818 12.8069 12.4169 13.2109 12.6188C13.648 12.8201 13.8164 12.9545 13.7827 13.1558C13.7827 13.4578 13.446 13.5922 13.1099 13.5922C12.7058 13.5922 12.3018 13.4916 11.932 13.3234L11.73 14.2636C12.134 14.4312 12.5712 14.4987 12.9752 14.4987C14.2205 14.5319 14.9943 13.9279 14.9943 13.0214C14.9943 11.8799 13.4123 11.813 13.4123 11.3097ZM19.0001 14.4649L18.0915 10.1006H17.1157C16.9137 10.1006 16.7116 10.235 16.6443 10.4363L14.9619 14.4649H16.1398L16.3749 13.8272H17.8222L17.9569 14.4649H19.0001ZM17.2828 11.2759L17.619 12.9207H16.6768L17.2828 11.2759Z"
        fill="#172B85"
      />
    </Svg>
  );
}

registerSVG('Visa', Visa);
