import { useContext, useEffect, useMemo, useState } from 'react';

import { Box, Header, Pressable, ScrollView, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { LayoutContainer } from 'components/layout/styled';
import { useContentCardsNotificationsExperiment } from 'experiments/use-content-cards-notifications';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { BrazeContext } from 'state/braze';
import {
  getReadContentCardsFromLocalStorage,
  setReadContentCardsInLocalStorage,
} from 'state/braze/hooks/utils';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { portalToFooter } from 'state/mobile-footer';
import { portalToReplaceHeader } from 'state/mobile-header-nav';
import Braze, { ContentCard } from 'utils/braze';
import { routes } from 'utils/routing';

import {
  CardsContainer,
  CenteredLine,
  ContentCardContainer,
  StyledBackIcon,
  UnreadSeparator,
  UnreadSeparatorText,
} from './styled';

// TODO: BKPE-8662 - Create different Custom Content Cards for each type
const Card = ({ card, unread = false }: { card: ContentCard; unread?: boolean }) => {
  if (card.type === 'Classic' || card.type === 'Captioned') {
    return (
      <ContentCardContainer viewed={!unread}>
        <Text>type: {card.type}</Text>
        <Text>title: {card.title}</Text>
        <Text>description: {card.cardDescription}</Text>
        <Text>image: {card.image}</Text>
        <Text>clicked: {card.clicked ? 'yes' : 'no'}</Text>
        <Text>dismissed: {card.dismissed ? 'yes' : 'no'}</Text>
        <Text>viewed: {card.viewed ? 'yes' : 'no'}</Text>
      </ContentCardContainer>
    );
  } else if (card.type === 'ImageOnly') {
    return (
      <ContentCardContainer viewed={!unread}>
        <Text>type: {card.type}</Text>
        <Text>image: {card.image}</Text>
        <Text>clicked: {card.clicked ? 'yes' : 'no'}</Text>
        <Text>dismissed: {card.dismissed ? 'yes' : 'no'}</Text>
        <Text>viewed: {card.viewed ? 'yes' : 'no'}</Text>
      </ContentCardContainer>
    );
  }

  return null;
};

export const NotificationCenter = () => {
  const enableContentCards = useContentCardsNotificationsExperiment();
  const dispatch = useAppDispatch();
  const contentCards = useAppSelector(selectors.loyalty.selectContentCards);
  const [unreadContentCardsIds, setUnreadContentCardsIds] = useState<string[]>([]);
  const { logContentCardImpression } = useContext(BrazeContext);
  const { formatMessage } = useIntl();
  const { navigate, goBack } = useNavigation();

  useEffect(() => {
    if (contentCards.length === 0) {
      return;
    }

    const readCardsFromLocalStorage = getReadContentCardsFromLocalStorage();
    const unreadCardsIds = contentCards
      .filter(({ id, viewed }) => !viewed && !readCardsFromLocalStorage.includes(id))
      .map(({ id }) => id);

    if (unreadCardsIds.length > 0) {
      setUnreadContentCardsIds(prevState => {
        return [...new Set([...prevState, ...unreadCardsIds])];
      });
    }

    contentCards.forEach(({ id, viewed }) => {
      if (!viewed) {
        setReadContentCardsInLocalStorage([id]);
        dispatch(actions.loyalty.refreshUnreadContentCards());
        logContentCardImpression(id);
      }
    });
  }, [contentCards, dispatch, logContentCardImpression, unreadContentCardsIds.length]);

  const orderedContentCards = useMemo(
    () =>
      contentCards.reduce(
        (acc, cur) => {
          const key = unreadContentCardsIds.includes(cur.id) ? 'unread' : 'read';
          acc[key].push(cur);
          return acc;
        },
        { read: [] as ContentCard[], unread: [] as ContentCard[] }
      ),
    [contentCards, unreadContentCardsIds]
  );

  const AppHeader = portalToReplaceHeader(() => {
    return (
      <Box _ios={{ marginTop: '$12' }} _android={{ marginTop: '$3' }} marginTop={'$0'}>
        <Pressable onPress={goBack} padding="$4">
          <StyledBackIcon variant="back" />
        </Pressable>
        <Header alignSelf="center" variant="headerTwo" top="$4" position="absolute">
          {formatMessage({ id: 'news' })}
        </Header>
      </Box>
    );
  });

  const AppFooter = portalToFooter(() => null);

  if (!enableContentCards) {
    navigate(routes.base);
  }

  useEffect(() => {
    return () => Braze.requestContentCardsRefresh();
  }, []);

  return (
    <LayoutContainer isFullContainer={true}>
      <ScrollView width="full" height="full" margin="auto">
        <CardsContainer>
          {orderedContentCards.unread.map(card => (
            <Card key={card.id} unread card={card} />
          ))}
          <UnreadSeparator>
            <CenteredLine />
            <UnreadSeparatorText>
              {formatMessage({ id: 'contentCardsSeparatorLabel' })}
            </UnreadSeparatorText>
            <CenteredLine />
          </UnreadSeparator>
          {orderedContentCards.read.map(card => (
            <Card key={card.id} card={card} />
          ))}
        </CardsContainer>

        <AppHeader />
        <AppFooter />
      </ScrollView>
    </LayoutContainer>
  );
};
