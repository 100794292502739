import { useCallback, useEffect, useState } from 'react';

import {
  LoyaltyOstType,
  OrderSelectionStatus,
  useOrderSelectionByMultipleTypeQuery,
} from 'generated/graphql-gateway';

import {
  IUseShortCodePollStatus,
  IUseShortCodePollStatusParams,
  ShortCodePollingStatus,
} from './types';

export const SHORT_CODE_NOT_FOUND_ERROR_CODE = 'HashSetNotFoundError';

export const useShortCodePollStatus = ({
  loyaltyId,
  skipPolling,
  shortCode,
  pollInterval,
}: IUseShortCodePollStatusParams): IUseShortCodePollStatus => {
  const [pollingStatus, setPollingStatus] = useState<ShortCodePollingStatus>(
    ShortCodePollingStatus.None
  );

  const resetPollingStatus = useCallback(() => {
    setPollingStatus(ShortCodePollingStatus.None);
  }, []);

  // setting pending code state
  useEffect(() => {
    if (!skipPolling) {
      setPollingStatus(ShortCodePollingStatus.Pending);
    }
  }, [skipPolling]);

  const skip =
    skipPolling || !shortCode || !loyaltyId || pollingStatus === ShortCodePollingStatus.None;
  // short code polling
  const { error, startPolling, stopPolling } = useOrderSelectionByMultipleTypeQuery({
    variables: {
      input: [
        {
          key: shortCode!,
          ostType: LoyaltyOstType.OTP,
        },
        {
          key: loyaltyId!,
          ostType: LoyaltyOstType.LOYALTY_ID,
        },
      ],
    },
    fetchPolicy: 'no-cache',
    skip,
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      data?.loyaltyOrderSelectionByMultipleType?.forEach(sel => {
        if (sel?.status === OrderSelectionStatus.CLAIMED) {
          setPollingStatus(ShortCodePollingStatus.Confirmed);
        }
      });
    },
  });

  useEffect(() => {
    // skip is going to be true, if the polling status is 'pending' after 5 minutes
    // for example when the user is in a different screen
    if (skip || pollingStatus === ShortCodePollingStatus.Confirmed) {
      stopPolling();
    } else {
      startPolling(pollInterval);
    }
  }, [pollInterval, skip, pollingStatus, startPolling, stopPolling]);

  useEffect(() => {
    const isNotFoundError = error?.graphQLErrors?.some(
      err => err?.extensions?.code === SHORT_CODE_NOT_FOUND_ERROR_CODE
    );
    if (isNotFoundError) {
      setPollingStatus(ShortCodePollingStatus.NotFound);
    }
  }, [error]);

  return {
    pollingShortCodeStatus: pollingStatus,
    resetPollingStatus,
  };
};
