import { useEffect } from 'react';

import { useGeolocation } from 'state/geolocation';

export const useAppStateChange = () => {
  const { isPermissionGranted, loadCurrentPosition } = useGeolocation();

  useEffect(() => {
    const listener = () => {
      const isActive = document.visibilityState === 'visible';
      // Get current user position
      if (isActive && isPermissionGranted) {
        loadCurrentPosition();
      }
    };

    document.addEventListener('visibilitychange', listener);

    return () => document.removeEventListener('visibilitychange', listener, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPermissionGranted, loadCurrentPosition, document.visibilityState]);
};
