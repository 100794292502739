import { primitive } from 'styles/constants/primitives';

import { G, Mask, Path, Rect, Svg } from '../../../../ucl/components/svg/Svg';
export const CancelSearchIcon = () => (
  <Svg width="6" height="6" viewBox="0 0 24 24" fill="none">
    <Mask id="mask0_7330_1311" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <Rect width="24" height="24" fill="#D9D9D9" />
    </Mask>
    <G mask="url(#mask0_7330_1311)">
      <Path
        d="M7 17.7L8.4 19.125C8.5 19.225 8.61667 19.275 8.75 19.275C8.88333 19.275 9 19.225 9.1 19.125C9.2 19.025 9.25 18.9042 9.25 18.7625C9.25 18.6208 9.2 18.5 9.1 18.4L7.7 17L9.125 15.575C9.225 15.475 9.275 15.3583 9.275 15.225C9.275 15.0917 9.225 14.975 9.125 14.875C9.025 14.775 8.90833 14.725 8.775 14.725C8.64167 14.725 8.525 14.775 8.425 14.875L7 16.3L5.575 14.875C5.475 14.775 5.35833 14.725 5.225 14.725C5.09167 14.725 4.975 14.775 4.875 14.875C4.775 14.975 4.725 15.0917 4.725 15.225C4.725 15.3583 4.775 15.475 4.875 15.575L6.3 17L4.875 18.425C4.775 18.525 4.725 18.6417 4.725 18.775C4.725 18.9083 4.775 19.025 4.875 19.125C4.975 19.225 5.09167 19.275 5.225 19.275C5.35833 19.275 5.475 19.225 5.575 19.125L7 17.7ZM7 22C5.61667 22 4.4375 21.5125 3.4625 20.5375C2.4875 19.5625 2 18.3833 2 17C2 15.6167 2.4875 14.4375 3.4625 13.4625C4.4375 12.4875 5.61667 12 7 12C8.38333 12 9.5625 12.4875 10.5375 13.4625C11.5125 14.4375 12 15.6167 12 17C12 18.3833 11.5125 19.5625 10.5375 20.5375C9.5625 21.5125 8.38333 22 7 22ZM14.2 14.6C14 14.3833 13.7875 14.1625 13.5625 13.9375C13.3375 13.7125 13.1167 13.5 12.9 13.3C13.5333 12.9 14.0417 12.3667 14.425 11.7C14.8083 11.0333 15 10.3 15 9.5C15 8.25 14.5625 7.1875 13.6875 6.3125C12.8125 5.4375 11.75 5 10.5 5C9.25 5 8.1875 5.4375 7.3125 6.3125C6.4375 7.1875 6 8.25 6 9.5C6 9.6 6.00417 9.69583 6.0125 9.7875C6.02083 9.87917 6.03333 9.975 6.05 10.075C5.75 10.1083 5.42083 10.175 5.0625 10.275C4.70417 10.375 4.38333 10.4917 4.1 10.625C4.06667 10.4417 4.04167 10.2583 4.025 10.075C4.00833 9.89167 4 9.7 4 9.5C4 7.68333 4.62917 6.14583 5.8875 4.8875C7.14583 3.62917 8.68333 3 10.5 3C12.3167 3 13.8542 3.62917 15.1125 4.8875C16.3708 6.14583 17 7.68333 17 9.5C17 10.2167 16.8875 10.8958 16.6625 11.5375C16.4375 12.1792 16.125 12.7667 15.725 13.3L21.3 18.9C21.4833 19.0833 21.5792 19.3125 21.5875 19.5875C21.5958 19.8625 21.5 20.1 21.3 20.3C21.1167 20.4833 20.8833 20.575 20.6 20.575C20.3167 20.575 20.0833 20.4833 19.9 20.3L14.2 14.6Z"
        fill={primitive.bk.$mayoEggWhite}
      />
    </G>
  </Svg>
);
