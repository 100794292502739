import React, { VFC } from 'react';

import { Svg, SvgPath, addWithConfig } from '@rbilabs/universal-components';

import { ILoyaltyPointsIconProps } from './types';

// TODO: use bkCrown  Design: if this is commonly used, we need to decide on common variant names.
const LoyaltyPointsIcon: VFC<ILoyaltyPointsIconProps> = ({
  width = '32px',
  height = '32px',
  ...props
}) => (
  <Svg width={width} height={height} viewBox="0 0 32 32" {...props}>
    <SvgPath
      d="m26.997 9.952-2.038 1.408-2.037 1.409a1.495 1.495 0 0 1-1.948-.223l-1.815-1.978-1.815-1.977A1.813 1.813 0 0 0 16 8c-.492 0-.984.197-1.344.59l-1.815 1.978-1.815 1.978a1.495 1.495 0 0 1-1.947.223L7.04 11.36 5.004 9.952a1.47 1.47 0 0 0-1.606-.056c-.466.278-.787.8-.723 1.434l.59 5.865.591 5.864a1.489 1.489 0 0 0 1.485 1.34h21.317a1.497 1.497 0 0 0 1.486-1.34l.59-5.864.591-5.865a1.474 1.474 0 0 0-.723-1.434 1.47 1.47 0 0 0-1.605.056Z"
      fill={props.fill ?? '#FFAA00'}
    />
  </Svg>
);

export default addWithConfig(LoyaltyPointsIcon);
