import { shouldPolyfill } from '@formatjs/intl-locale/should-polyfill';

import logger from 'utils/logger';

export default async function polyfillIntlLocale() {
  // This platform already supports Intl.Locale
  if (shouldPolyfill()) {
    try {
      await import('@formatjs/intl-locale/polyfill');
    } catch (e) {
      // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
      logger.warn('Polyfill IntlLocale', e);
    }
  }
}
