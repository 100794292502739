import { IFee, IServerOrder } from '@rbi-ctg/menu';

import { FEES } from './constants';
import { IGetStandardizedName } from './types';

export const getStandardizedName = ({ name }: IGetStandardizedName) => FEES[name] ?? name;

export const getFeesByGroup = (fees: IFee[]) =>
  (fees || []).reduce((acc: { [key: string]: number }, val: IFee) => {
    const lowerCaseName = val.name.toLowerCase();
    if (!acc[lowerCaseName]) {
      acc[lowerCaseName] = 0;
    }

    acc[lowerCaseName] += val.totalCents;

    return acc;
  }, {});

enum DONATIONS {
  ROUND_UP = '',
}
// TODO this donation code will likely evolve as we implement. this was stolen from rGuest for the mparticle -> amplitude migration
export const getRoundUpDonations = (serverOrder: IServerOrder | null) =>
  serverOrder?.cart?.donations?.find(({ name }) => name === DONATIONS.ROUND_UP);
