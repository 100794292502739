import { Box, makeUclComponent } from '@rbilabs/universal-components';

import ActionButton from 'components/action-button';

export const DrawerContainer = Box.withConfig({
  paddingX: '$4',
  marginTop: '$9',
  width: 'full',
});

export const ButtonsContainer = Box.withConfig({
  paddingX: {
    md: '1/3',
  },
});

export const StyledActionButton = makeUclComponent(ActionButton).withConfig({
  marginBottom: '$4',
  fullWidth: true,
});
