import { isAfter, isBefore } from 'date-fns';
import { partition } from 'lodash-es';

import { IChannelExclusions, IMainMenuSectionView, IMainMenuViewOption } from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';
import { IPricingAndAvailabilityFragment } from 'generated/graphql-gateway';
import { MainMenuObject } from 'pages/menu/components/menu-tile-grid/menu-tile/types';
import { DAY_PART_SELECTIONS, IDayPartBoundary } from 'state/menu/main-menu/types';
import { SectionUiPattern } from 'state/menu/types';
import { ServiceMode } from 'state/service-mode/types';
import { isPickup } from 'utils/service-mode/service-mode';

import {
  FilteredMainMenuSections,
  IDaypartTransformerProps,
  IIsDayPartOutOfTimeIntervalProps,
} from './types';

export const separateBreakfastMenu = (menu: IMainMenuViewOption[]) => {
  const menuSections = menu.filter(
    item => item._type === MenuObjectTypes.SECTION
  ) as IMainMenuSectionView[];
  const [breakfastMenuData, menuData] = partition(menuSections, item =>
    item.daypart?.includes(DAY_PART_SELECTIONS.BREAKFAST_KEY)
  );

  return { menuData, breakfastMenuData };
};

export const daypartDaytimeTransformer = ({
  key,
  label,
  formattedDaytimeMessage,
}: IDaypartTransformerProps): string =>
  key === DAY_PART_SELECTIONS.BREAKFAST_KEY ? label : formattedDaytimeMessage;

export const excludeDisabledMenuSections = ({
  menuData,
  activeDayParts,
  showBurgersForBreakfast,
  serviceMode,
  getPricingAndAvailability,
}: {
  menuData: IMainMenuViewOption[] | undefined;
  activeDayParts: IDayPartBoundary[];
  showBurgersForBreakfast?: boolean;
  serviceMode: ServiceMode | null;
  getPricingAndAvailability: (id: string) => IPricingAndAvailabilityFragment | null | undefined;
}) => {
  const activeDayPartsSet = new Set(activeDayParts.map(dayPart => dayPart.key.toLowerCase()));
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  return (menuData ?? [])?.filter((menuOption: IMainMenuSectionView) => {
    if (menuOption.hiddenFromMainMenu) {
      return false;
    }

    if (menuOption.channelExclusions) {
      const shouldDisplayOption = filterWithChannelExclusions(
        menuOption.channelExclusions,
        serviceMode
      );

      // Only return when item should be hidden, otherwise continue with the rest of the checks
      if (!shouldDisplayOption) {
        return false;
      }
    }

    // Get price and availability before checking dayparts
    const priceAndAvailability = getPricingAndAvailability(menuOption._id);
    if (!priceAndAvailability?.isAvailable) {
      return false;
    }

    if (menuOption._type !== MenuObjectTypes.SECTION || !menuOption?.daypart?.length) {
      return true;
    }

    // Burgers for Breakfast
    if (
      menuOption.daypart.includes(DAY_PART_SELECTIONS.BURGERS_FOR_BREAKFAST) &&
      activeDayPartsSet.has(DAY_PART_SELECTIONS.BURGERS_FOR_BREAKFAST.toLowerCase())
    ) {
      // B4B should not show in static menu
      return showBurgersForBreakfast;
    }
    return menuOption.daypart.some(dayPart => activeDayPartsSet.has(dayPart.toLowerCase()));
  });
};

export const isDayPartOutOfTimeInterval = ({
  currentUserTime,
  interval,
}: IIsDayPartOutOfTimeIntervalProps) =>
  (interval?.startTime && isBefore(currentUserTime, interval.startTime)) ||
  (interval?.endTime && isAfter(currentUserTime, interval.endTime));

export const filterWithChannelExclusions = (
  exclusions: IChannelExclusions,
  serviceMode: ServiceMode | null
): boolean => {
  if (!exclusions) {
    return true;
  }

  if (exclusions.pickup) {
    return !isPickup(serviceMode);
  }

  if (exclusions.delivery) {
    return serviceMode !== ServiceMode.DELIVERY;
  }

  return true;
};

export const reduceMenuSectionOptions = ({
  menuSectionOptions,
  serviceMode,
  getPricingAndAvailability,
}: {
  menuSectionOptions: MainMenuObject[];
  serviceMode: ServiceMode | null;
  getPricingAndAvailability: (id: string) => IPricingAndAvailabilityFragment | null | undefined;
}): FilteredMainMenuSections => {
  return (menuSectionOptions ?? []).reduce<FilteredMainMenuSections>(
    (acc, option) => {
      // TODO: should we exclude options by type !== 'section'
      const excludedByChannelExclusions =
        !!option.channelExclusions &&
        filterWithChannelExclusions(option.channelExclusions, serviceMode || null);

      const isOptionAvailable = getPricingAndAvailability(option._id)?.isAvailable;

      if (excludedByChannelExclusions || !isOptionAvailable) {
        return acc;
      }

      const { inlineTileSections, standardSections } = acc;
      if (option.uiPattern === SectionUiPattern.INLINE_TILE) {
        inlineTileSections.push(option);
      } else {
        standardSections.push(option);
      }

      return {
        inlineTileSections,
        standardSections,
        totalSectionsLength: acc.totalSectionsLength + 1,
      };
    },
    { inlineTileSections: [], standardSections: [], totalSectionsLength: 0 }
  );
};
