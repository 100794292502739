import React from 'react';

import { ApolloProvider } from '@apollo/client';

import { IBaseProps } from '@rbi-ctg/frontend';

import { apolloClient } from './client';
import { useHeadersSync } from './hooks/use-headers-sync';

export const GraphQLProvider = ({ children }: IBaseProps) => {
  useHeadersSync();

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
