import { Box, Icon, Text } from '@rbilabs/universal-components';
import { StatusBar } from 'expo-status-bar';
import { useIntl } from 'react-intl';

import { WEB_APP_MAX_WIDTH_DESKTOP_LARGE } from 'utils/constants';

export const NoNetworkHeader = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <StatusBar backgroundColor={NO_NETWORK_BACKGROUND_COLOR} />
      <Box alignItems="center" backgroundColor={NO_NETWORK_BACKGROUND_COLOR}>
        <Box
          flexDirection="row"
          alignItems="center"
          maxWidth={WEB_APP_MAX_WIDTH_DESKTOP_LARGE}
          width="100%"
          marginLeft={{ base: 10, md: 0 }}
        >
          <Icon variant="warning" width="14px" marginRight={1} />
          <Text variant="copyTwo" lineHeight="xs">
            {formatMessage({ id: 'noInternetConnection' })}
          </Text>
        </Box>
      </Box>
    </>
  );
};

export const NO_NETWORK_BACKGROUND_COLOR = '#FBE8E7';
