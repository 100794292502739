import { Box, Pressable } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';

/**
 * Spreads children horizontally and centers them vertically
 */
export const SpreadContentContainer = Box.withConfig({
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'left',
  flexDirection: 'row',
  width: 'full',
});

/**
 * Adds space for an Accordion Item's content.
 */
export const AccordionContentWrapper = Box.withConfig({
  marginTop: '$8',
  marginRight: '$4',
  marginBottom: '$4',
  paddingX: '$8',
  width: 'full',
});

/**
 * Unstyled button for triggering Accordion dropdowns.
 */
export const AccordionActivatorButton = Pressable.withConfig({
  width: 'full',
  paddingY: '$4',
  paddingX: '$8',
  background: 'transparent',
});

export const AccordionWrapper = Box.withConfig({
  marginX: '$4',
  borderBottomWidth: '1',
  borderColor: theme.token('border-color-default'),
});
