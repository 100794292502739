import React from 'react';

import { Linking } from 'react-native';

import { useNavigation } from 'hooks/navigation/use-navigation';
import { useAuthContext } from 'state/auth';
import { isExternalLink } from 'utils/is-external-link';

import {
  IButtonPropsType,
  IFixedCallToActionType,
  IFixedCallToActionWidgetType,
} from './fixedCallToActionTypes';
import { FixedActionButton, FixedCallToAction } from './fixedCallToActionWidget.styled';

export default function FixedCallToActionWidget(props: IFixedCallToActionWidgetType) {
  const { navigate } = useNavigation();
  const { user } = useAuthContext();

  const navigateToUrl = (url: string) => {
    if (isExternalLink(url)) {
      Linking.openURL(url);
    } else {
      navigate(url);
    }
  };

  return props.fixedCallToActionContent ? (
    <FixedCallToAction>
      {props.fixedCallToActionContent.map((cta: IFixedCallToActionType, index: React.Key) => {
        const button: IButtonPropsType = user?.loyaltyId
          ? { url: cta.linkAuthenticated.locale, text: cta.buttonTextAuthenticated.locale }
          : { url: cta.linkUnauthenticated.locale, text: cta.buttonTextUnauthenticated.locale };

        return (
          <FixedActionButton key={index} onPress={() => navigateToUrl(button.url)}>
            {button.text}
          </FixedActionButton>
        );
      })}
    </FixedCallToAction>
  ) : null;
}
