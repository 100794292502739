import { LoyaltyOffer } from 'state/loyalty/types';
import { IDayPartBoundary, IValidDayPart } from 'state/menu/main-menu/types';

import { IMenuObjectWithDaypart } from './types';

const itemDayPartsContainActiveDayPart = (
  itemDayparts: string[],
  activeDayParts: IDayPartBoundary[]
) => activeDayParts.findIndex(({ key }) => itemDayparts.includes(key.toLowerCase())) !== -1;

/**
 * Determine whether a menu object can be ordered
 * during the given activeDayPart(s).
 */
export function isAvailableForActiveDayParts({
  activeDayParts,
  menuData,
}: {
  activeDayParts: IDayPartBoundary[];
  menuData: IMenuObjectWithDaypart;
}): boolean {
  if (!menuData) {
    return false;
  }

  const itemDayParts = getMenuItemDayParts(menuData);

  const isDayPartsQualify = (itemDayPartsToCheck: string[]) => {
    return (
      !itemDayPartsToCheck.length ||
      itemDayPartsContainActiveDayPart(itemDayPartsToCheck, activeDayParts)
    );
  };

  if (menuData._type === 'picker' && 'options' in menuData) {
    return (menuData.options ?? []).some(pickerOption => {
      const optionDayParts = pickerOption.option ? getMenuItemDayParts(pickerOption.option) : [];
      return isDayPartsQualify(optionDayParts);
    });
  }

  return isDayPartsQualify(itemDayParts);
}

export const getMenuItemDayParts = (menuData: IMenuObjectWithDaypart): string[] => {
  if (!menuData) {
    return [];
  }

  switch (menuData._type) {
    case 'item': {
      const dayParts: string[] = (menuData?.operationalItem?.daypart || []).map(
        daypart => daypart?.toLowerCase() ?? ''
      );
      return dayParts;
    }
    case 'combo': {
      const mainItem = menuData.mainItem;
      return mainItem ? getMenuItemDayParts(mainItem) : [];
    }
    case 'section': {
      if (menuData.daypart) {
        const dayParts: string[] = menuData.daypart.map(
          (daypart: string | null) => daypart?.toLowerCase() ?? ''
        );
        return dayParts;
      }

      return [];
    }
    default:
      return [];
  }
};

export const getNextMatchingDayPart = (
  lookupDayParts: LoyaltyOffer['daypart'],
  dayParts: readonly IValidDayPart[]
): IValidDayPart | undefined => {
  const lookupSet = new Set(lookupDayParts?.map(x => x?.toLowerCase()));
  return dayParts.find(dayPart => dayPart.key && lookupSet.has(dayPart.key.toLowerCase()));
};
