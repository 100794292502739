import { HStack, Text } from '@rbilabs/universal-components';

import { primitive } from 'styles/constants/primitives';

export const OrderEtaContainer = HStack.withConfig({
  alignItems: 'center',
  backgroundColor: primitive.$blackOpacity4,
  p: '$1',
  borderRadius: Styles.borderRadius,
  maxHeight: '$10',
  justifyContent: 'center',
});

export const OrderEtaDescription = Text.withConfig({
  ml: '$2',
});
