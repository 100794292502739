import { Pressable } from '@rbilabs/universal-components';

import { Header, MobileHeaderContainer } from 'components/app-header/styled';
import { theme } from 'styles/configure-theme';

export const SignUpHeaderContainer = MobileHeaderContainer.withConfig<{ reversed?: boolean }>({
  background: theme.token('background-default'),
  backgroundColor: theme.token('background-default'),
});

export const SignUpHeader = Header.withConfig({
  backgroundColor: theme.token('background-default'),
});

export const BackArrowContainer = Pressable.withConfig({});
