import * as React from 'react';

import { EmptyState } from 'components/features/components/empty-state';

import { LoyaltyLoader } from '../loyalty-loader';

import { useFeaturesLoyaltyNoReward } from './use-features-loyalty-no-reward';

const LoyaltyNoRewardPoints = () => {
  const { data, loading } = useFeaturesLoyaltyNoReward();
  if (loading) {
    return <LoyaltyLoader height={{ base: '$64', md: 'full' }} />;
  }
  if (!data) {
    return null;
  }
  return <EmptyState content={data} />;
};

export default LoyaltyNoRewardPoints;
