import { FC } from 'react';

import { Button, Icon, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { useNavigation } from 'hooks/navigation/use-navigation';
import { routes } from 'utils/routing';

export const RedeemOffersButton: FC = () => {
  const { navigate } = useNavigation();
  const { formatMessage } = useIntl();

  return (
    <Button
      variant="ghost"
      onPress={() => navigate(routes.loyaltyOfferList)}
      rightIcon={<Icon name="mi:navigate-next" color={Styles.color.primary} />}
      marginY="$4"
    >
      <Text
        style={{ fontFamily: 'Flame-Regular', fontWeight: 400, color: Styles.color.primary }}
        fontSize="md"
      >
        {formatMessage({ id: 'redeemOffers' })}
      </Text>
    </Button>
  );
};
