export enum FeatureHomePageComponentTypes {
  AppDownload = 'AppDownload',
  Alert = 'Alert',
  LockedOffers = 'LockedOffers',
  MarketingTileGroup = 'MarketingTileGroup',
  MarketingCardGroup = 'MarketingCardGroup',
  RecentItems = 'RecentItems',
  RewardsSection = 'RewardsSection',
  QuestsSection = 'QuestsSection',
  SimpleHomePage = 'SimpleHomePage',
}

export type FeatureHomePageComponents = {
  [key in FeatureHomePageComponentTypes]?: any;
};

export interface IRawFeatureHomePageComponent {
  _id: string;
  __typename: FeatureHomePageComponentTypes;
}
