import { useState } from 'react';

import { NutritionFilterModal } from './nutrition-filter-modal';
import { IUseNutritionFilterModal } from './types';

const useNutritionFilterModal = (): IUseNutritionFilterModal => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return {
    NutritionFilterModal,
    isModalOpen,
    openModal,
    closeModal,
  };
};

export default useNutritionFilterModal;
