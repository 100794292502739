import { DeliveryStatus, OrderStatus } from '../types';

export const OrderSuccessFailureStatuses = {
  success: {
    delivery: [
      DeliveryStatus.ORDER_CREATED,
      DeliveryStatus.DRIVER_ASSIGNED,
      DeliveryStatus.DRIVER_STARTING,
    ],
    pos: [OrderStatus.INSERT_SUCCESSFUL, OrderStatus.UPDATE_SUCCESSFUL],
  },
  inProgress: {
    pos: [
      OrderStatus.PRICE_REQUESTED,
      OrderStatus.PAYMENT_REQUESTED,
      OrderStatus.INSERT_REQUESTED,
      OrderStatus.UPDATE_REQUESTED,
      OrderStatus.PAYMENT_SUCCESSFUL,
    ],
  },
  failures: {
    delivery: [
      DeliveryStatus.QUOTE_ERROR,
      DeliveryStatus.ORDER_ERROR,
      DeliveryStatus.ORDER_TIMEOUT,
    ],
    pos: [OrderStatus.INSERT_ERROR, OrderStatus.PAYMENT_ERROR, OrderStatus.VALIDATION_ERROR],
  },
};

/*
 * Represents how much time we are going to wait until hide the confirmation button
 * and also to show or not the Curbside confirmation modal when tried to change the
 * store on a curren curbside order
 */
export const PRE_CURBSIDE_CONFIRM_ARRIVAL_TIMEOUT_IN_MINUTES = 60;
