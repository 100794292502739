import { LinkToType } from 'hooks/navigation/types';
import { UUID_REGEX_STRING } from 'utils/constants';
import { region } from 'utils/environment';
import { Region } from 'utils/environment/types';

import { localeRoutes } from './intl';

export const relativeRoutes = {
  address: 'address',
  addCard: 'add-card',
  addExtra: 'extras',
  addGiftCard: 'add-gift-card',
  favorites: 'favorites',
  noDeliveryStores: 'no-delivery-stores',
  deliveryStoresClosed: 'delivery-stores-closed',
  orderPayment: 'payment',
  storeConfirmation: 'store-confirmation',
  pickupMode: 'pickup-mode',
  recentItems: 'recent-items',
  serviceMode: 'service-mode',
  store: 'store',
  successGiftCard: 'success-gift-card',
  successTransferGiftCard: 'success-transfer-gift-card',
  transferBalance: 'transfer-balance',
  transferGiftCard: 'transfer-gift-card',
};

const claimPointsType = region === Region.CA ? 'Crowns' : 'points';

// TODO: Cleanup all routes that are irrelevant to BK
export const routes = {
  aboutApp: '/about-app',
  accountMain: '/account/main',
  accountRequestInfo: '/account/request-information',
  accountDelete: '/account/delete',
  addCard: `/account/payment/${relativeRoutes.addCard}`,
  addGiftCard: `/account/payment/${relativeRoutes.addGiftCard}`,
  address: `/store-locator/${relativeRoutes.address}`,
  authChallengeJwt: '/auth-challenge-jwt',
  base: '/',
  baseMain: 'home',
  browseMenu: '/browse-menu',
  bkRewards: '/bkrewards',
  cart: '/cart',
  cartMain: '/cart/main',
  cartAddCard: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.addCard}`,
  cartAddExtras: `/cart/${relativeRoutes.addExtra}`,
  cartPayment: `/cart/${relativeRoutes.orderPayment}`,
  cartStoreConfirmation: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.storeConfirmation}`,
  cardPaymentAddGiftCard: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.addGiftCard}`,
  cartPaymentSuccessGiftCard: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.successGiftCard}`,
  cartPaymentTransferGiftCard: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.transferGiftCard}`,
  cartPaymentTransferSuccessGiftCard: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.successTransferGiftCard}`,
  cartTransferBalance: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.transferBalance}`,
  campaigns: '/campaigns',
  claimPointsAccepted: `/claim-${claimPointsType}/accepted`,
  claimPointsBase: `/claim-${claimPointsType}`,
  claimPointsProcessing: `/claim-${claimPointsType}/claim`,
  claimPointsSurvey: `/claim-${claimPointsType}/survey`,
  communication: `/communication-preferences`,
  confirmJwt: '/confirm-jwt',
  confirmOtp: '/confirm-otp',
  delivery: '/delivery',
  deliveryStoresClosed: `/store-locator/${relativeRoutes.deliveryStoresClosed}`,
  diagnostics: '/diagnostics',
  drawer: 'Drawer',
  favoritesMain: `/menu/${relativeRoutes.favorites}/main`,
  favorites: `/menu/${relativeRoutes.favorites}`,
  favoriteEditor: `/menu/${relativeRoutes.favorites}/:favoriteId/edit`,
  accountInfo: '/account/info',
  languageSelector: '/language-selector',
  loading: '/loading',
  loyaltyDashboard: '/rewards/details',
  loyaltyHome: '/rewards',
  loyaltyRewardsDashboard: '/rewards/dashboard',
  loyaltyRewardsList: '/rewards/list',
  loyaltyOfferList: '/rewards/offers',
  loyaltyOfferGroup: '/rewards/offers-group',
  loyaltyChallengeDetails: '/challenge-details',
  menu: '/menu',
  menuEmpty: '/menu-empty',
  menuFavorites: '/menu/favorites',
  menuMain: '/menu/main',
  menuContent: '/menu-content',
  menuRecent: '/menu/recent-items',
  noDeliveryStores: `/store-locator/${relativeRoutes.noDeliveryStores}`,
  notFound: '/not-found',
  notFound404: '/not-found/404',
  news: '/news',
  nutritionExplorer: '/nutrition-explorer',
  offers: '/offers',
  offersList: 'OffersList',
  orderConfirmation: '/order-confirmation',
  orders: '/account/orders',
  ordersDetails: '/account/orders/details',
  partnerships: '/partnerships',
  payment: '/account/payment',
  accountPaymentSuccessGiftCard: `/account/${relativeRoutes.orderPayment}/${relativeRoutes.successGiftCard}`,
  accountPaymentTransferSuccessGiftCard: `/account/${relativeRoutes.orderPayment}/${relativeRoutes.successTransferGiftCard}`,
  pickupMode: `/store-locator/${relativeRoutes.pickupMode}`,
  productDetail: '/product-detail',
  redeem: '/redeem',
  rewards: '/rewards',
  rewardsEarn: '/rewards/earn',
  rewardsHistory: '/rewards/history',
  rewardsList: '/rewards/list',
  rewardsOnboarding: '/rewards-onboarding',
  royalPerks: '/royalperks',
  scanMain: '/scan-main',
  scaryPlaces: '/scaryplaces',
  serviceMode: `/store-locator/${relativeRoutes.serviceMode}`,
  signOut: '/signout',
  staticPage: '/static-page',
  store: `/store-locator/${relativeRoutes.store}`,
  support: '/support',
  supportCategories: '/support/categories',
  supportCategory: '/support/categories/:categoryId',
  supportOrders: '/support/orders',
  supportForm: '/support/form',
  supportPage: 'SupportPage',
  tabs: 'Tabs',
  transferGiftCard: `/account/${relativeRoutes.orderPayment}/${relativeRoutes.transferGiftCard}`,
  accountTransferBalance: `/account/${relativeRoutes.orderPayment}/${relativeRoutes.transferBalance}`,
  trending: '/trending',
  contactUs: 'contact-us',
  modalStaticPage: '/modal-static-page',
  questLibrary: '/quest-library',
  favoriteStoreSearch: '/favorite-store-search',
  walmart: '/walmartplus',
  walmartLocation: '/walmartLocation',
  walmartRedirect: '/walmart-redirect',

  //TODO: this route is a static page in sanity. Ensure it exists in data set

  // Default localizable routes
  account: localeRoutes.en.account,
  acceptGift: localeRoutes.en.acceptGift,
  sendGift: localeRoutes.en.sendGift,
  signUp: localeRoutes.en.signUp,
  signIn: localeRoutes.en.signIn,
  storeLocator: localeRoutes.en.storeLocator,
  hockeyChallenge: localeRoutes.en.hockeyChallenge,
  foundation: '/foundation',
  scan: localeRoutes.en.scan,
};

export const isSectionRoute = (pathname: string) => pathname.startsWith(`${routes.menu}/section-`);

export const isRecentItemsRoute = (pathname: string) =>
  pathname.startsWith(`${routes.menu}/${relativeRoutes.recentItems}`);

export const isFavoritesRoute = (pathname: string) => pathname.startsWith(routes.favorites);

export const isOfferItemRoute = (pathname: string) =>
  new RegExp(`^${routes.loyaltyOfferList}/${UUID_REGEX_STRING}`).test(pathname);

export const isItemPickerOrComboRoute = (pathname: string) =>
  new RegExp(`^${routes.menu}/(item|picker|combo)-`).test(pathname);

export function editFavorite({
  linkTo,
  favoriteId,
  popCurrent = false,
}: {
  linkTo: LinkToType;
  favoriteId?: string | null;
  popCurrent?: boolean;
}) {
  linkTo(`${routes.favorites}/${favoriteId}/edit`, { popCurrent });
}

export {
  localeRoutes,
  getAllLocalizedVersionsOfRoute,
  getInCodeLocalizedRouteForPath,
  getStaticPagesLocalizedRouteForPath,
} from './intl';
