import { IOptInModalFragment, IOptInModalLoadingFragment } from 'generated/sanity-graphql';

import type {
  IOptInErrorContentProps,
  IOptInInitContentProps,
  IOptInLoadingContentProps,
} from './types';

export const parseInitModalData = (
  optInModal: IOptInModalFragment
): Omit<IOptInInitContentProps, 'onActionPress'> => {
  return {
    heading: optInModal.title?.locale || '',
    description: optInModal.description?.locale || '',
    image: optInModal.image?.locale?.app,
    altImage: optInModal.image?.locale?.imageDescription || 'Image',
    buttonText: optInModal.buttonText?.locale || '',
    disclaimer: optInModal.blockText,
  };
};

export const parseLoadingModalData = (
  optInModalLoading: IOptInModalLoadingFragment
): IOptInLoadingContentProps => {
  return {
    heading: optInModalLoading?.title?.locale || '',
    description: optInModalLoading?.description?.locale || '',
  };
};

export const parseErrorModalData = (
  optInModalError: IOptInModalFragment
): Omit<IOptInErrorContentProps, 'onActionPress' | 'onDismiss' | 'allowDismiss'> => {
  return {
    heading: optInModalError.title?.locale || '',
    description: optInModalError.description?.locale || '',
    image: optInModalError.image?.locale?.app,
    altImage: optInModalError.image?.locale?.imageDescription || 'Image',
    buttonText: optInModalError.buttonText?.locale || '',
  };
};
