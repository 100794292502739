import {
  isPayPalSupported,
  isVenmoSupported,
} from 'components/payments/integrations/firstdata/braintree.config';
import { isCashAppSupported } from 'components/payments/integrations/firstdata/cashapp.config';
import {
  CASH_APP_PAYMENT_METHOD_PLACEHOLDER,
  PAYPAL_PAYMENT_METHOD_PLACEHOLDER,
  VENMO_PAYMENT_METHOD_PLACEHOLDER,
} from 'state/payment/constants';
import { IPaymentMethod } from 'state/payment/types';
import { isApplePay, isGooglePay } from 'utils/payment/native-payment';

import { isVenmoAvailable } from '../../../../../modules/expo-braintree';
import { IGetAvailablePaymentMethods, ISetupPaymentMethodState } from '../types';

import { getCheckoutPaymentMethodId } from './getCheckoutPaymentMethodId';
import { getPrepaidReloadPaymentMethodId } from './getPrepaidReloadPaymentMethodId';
import { getValidPaymentMethodId } from './getValidPaymentMethodId';
/**
 * Get a valid payment methods list, default, checkout and prepaid payment method ID
 */

const getAvailablePaymentMethods = ({
  accounts,
  applePayCardDetails,
  canUseApplePay,
  canUseGooglePay,
  googlePayCardDetails,
}: IGetAvailablePaymentMethods) => {
  const availablePaymentMethodsWithoutNative = accounts.filter(
    method => !isApplePay(method.fdAccountId) && !isGooglePay(method.fdAccountId)
  );

  // Put Apple/Google Pay on top of the list, after, the prepaid card and finally the CC
  const availablePaymentMethodsWithoutPrepaidOrCash = availablePaymentMethodsWithoutNative.filter(
    method => !method.prepaid && !method.cash
  );

  // Adding Apple Pay if available
  if (canUseApplePay) {
    availablePaymentMethodsWithoutPrepaidOrCash.unshift(applePayCardDetails);
  }
  // Adding Google Pay if available
  if (canUseGooglePay) {
    availablePaymentMethodsWithoutPrepaidOrCash.unshift(googlePayCardDetails);
  }

  return { availablePaymentMethodsWithoutPrepaidOrCash, availablePaymentMethodsWithoutNative };
};

export const getPaymentMethodsState = ({
  paymentMethodId,
  prepaidPaymentMethodId,
  accounts,
  canUseApplePay,
  enableCashPayment,
  enableCashApp,
  enablePayPal,
  enableVenmo,
  applePayCardDetails,
  canUseGooglePay,
  googlePayCardDetails,
  paymentMethodHasBeenInit,
  braintreeAuthorizationKey,
}: ISetupPaymentMethodState) => {
  const {
    availablePaymentMethodsWithoutPrepaidOrCash,
    availablePaymentMethodsWithoutNative,
  } = getAvailablePaymentMethods({
    accounts,
    applePayCardDetails,
    canUseApplePay,
    canUseGooglePay,
    googlePayCardDetails,
  });

  const isDefaultReloadMethodAGiftCard = (paymentMethod?: IPaymentMethod) =>
    !!paymentMethod?.prepaid;

  const isUsersPaymentMethodGiftCard = isDefaultReloadMethodAGiftCard(
    accounts.find(account => account.accountIdentifier === prepaidPaymentMethodId)
  );

  // Get the default prepaid reload card payment method
  const validDefaultReloadPaymentMethodId = getPrepaidReloadPaymentMethodId({
    paymentMethodHasBeenInit,
    accounts: [...availablePaymentMethodsWithoutNative],
    usersPaymentMethodId: prepaidPaymentMethodId,
    canUseApplePay,
    canUseGooglePay,
    applePayCardDetails,
    googlePayCardDetails,
    isUsersPaymentMethodGiftCard,
  });

  // Get the prepaid reload card payment method
  const validReloadPaymentMethodId = getPrepaidReloadPaymentMethodId({
    paymentMethodHasBeenInit,
    accounts: [...availablePaymentMethodsWithoutNative],
    usersPaymentMethodId: prepaidPaymentMethodId,
    canUseApplePay,
    canUseGooglePay,
    applePayCardDetails,
    googlePayCardDetails,
  });

  const prepaidCard = accounts.find(method => !!method.prepaid);
  const cash = accounts.find(method => !!method.cash);
  const cashapp = accounts.find(method => !!method.cashapp);
  const paypal = accounts.find(method => !!method.paypal);
  const venmo = accounts.find(method => !!method.venmo);

  const availablePaymentMethodList = availablePaymentMethodsWithoutPrepaidOrCash;

  if (prepaidCard) {
    availablePaymentMethodList.unshift(prepaidCard);
  }

  if (enableCashPayment && cash) {
    availablePaymentMethodList.push(cash);
  }

  if (enableCashApp && isCashAppSupported() && !cashapp) {
    availablePaymentMethodList.push(CASH_APP_PAYMENT_METHOD_PLACEHOLDER);
  }

  if (enablePayPal && isPayPalSupported() && !paypal) {
    availablePaymentMethodList.push(PAYPAL_PAYMENT_METHOD_PLACEHOLDER);
  }

  if (enableVenmo && isVenmoSupported() && isVenmoAvailable(braintreeAuthorizationKey) && !venmo) {
    availablePaymentMethodList.push(VENMO_PAYMENT_METHOD_PLACEHOLDER);
  }

  // Get the default payment method
  const validDefaultPaymentMethodId = getValidPaymentMethodId({
    canUseApplePay,
    canUseGooglePay,
    paymentMethodId,
    paymentMethods: availablePaymentMethodList,
    returnFirstValid: true,
  });

  // Get the checkout payment method
  const validCheckoutPaymentMethodId = getCheckoutPaymentMethodId({
    paymentMethodHasBeenInit,
    accounts: [...availablePaymentMethodsWithoutNative],
    usersPaymentMethodId: paymentMethodId,
    canUseApplePay,
    applePayCardDetails,
    canUseGooglePay,
    googlePayCardDetails,
  });

  return {
    availablePaymentMethodList,
    validDefaultPaymentMethodId,
    validCheckoutPaymentMethodId,
    validReloadPaymentMethodId,
    validDefaultReloadPaymentMethodId,
  };
};
