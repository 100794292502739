import { Header, HeaderProps, Text, TextProps } from '@rbilabs/universal-components';

import { HorizontalPosition, IColorable, IHorizontalPositionable } from './types';

const horizontalPositionToTextAlignMap: {
  [key: string]: 'left' | 'center' | 'right';
} = {
  [HorizontalPosition.LEFT]: 'left',
  [HorizontalPosition.CENTER]: 'center',
  [HorizontalPosition.RIGHT]: 'right',
};

type ColorableAndHorizontalPositionable = IColorable & IHorizontalPositionable;

const baseHeadlineStyles: HeaderProps = {
  fontSize: '5xl',
  lineHeight: 'xs',
};

export const Headline1 = Header.withConfig<ColorableAndHorizontalPositionable>(
  ({ color, horizontalPosition }) => {
    return {
      ...baseHeadlineStyles,
      accessibilityLevel: 1,
      fontSize: '8xl',
      textAlign: horizontalPositionToTextAlignMap[horizontalPosition],
      color,
    };
  }
);

export const Headline2 = Header.withConfig<ColorableAndHorizontalPositionable>(
  ({ color, horizontalPosition }) => ({
    ...baseHeadlineStyles,
    accessibilityLevel: 2,
    fontSize: '6xl',
    textAlign: horizontalPositionToTextAlignMap[horizontalPosition],
    color,
  })
);

export const Headline3 = Header.withConfig<ColorableAndHorizontalPositionable>(
  ({ color, horizontalPosition }) => ({
    accessibilityLevel: 3,
    ...baseHeadlineStyles,
    fontSize: '5xl',
    textAlign: horizontalPositionToTextAlignMap[horizontalPosition],
    color,
  })
);

export const Headline4 = Header.withConfig<ColorableAndHorizontalPositionable>(
  ({ color, horizontalPosition }) => ({
    ...baseHeadlineStyles,
    accessibilityLevel: 4,
    fontSize: '4xl',
    textAlign: horizontalPositionToTextAlignMap[horizontalPosition],
    color,
  })
);

export const Headline5 = Header.withConfig<ColorableAndHorizontalPositionable>(
  ({ color, horizontalPosition }) => ({
    ...baseHeadlineStyles,
    accessibilityLevel: 5,
    fontSize: '2xl',
    textAlign: horizontalPositionToTextAlignMap[horizontalPosition],
    color,
  })
);

export const Headline6 = Header.withConfig<ColorableAndHorizontalPositionable>(
  ({ color, horizontalPosition }) => ({
    ...baseHeadlineStyles,
    accessibilityLevel: 6,
    fontSize: 'xl',
    textAlign: horizontalPositionToTextAlignMap[horizontalPosition],
    color,
  })
);

const baseSubtitleStyles: TextProps = {
  lineHeight: 'sm',
};

export const Subtitle1 = Text.withConfig<ColorableAndHorizontalPositionable>(
  ({ color, horizontalPosition }) => ({
    ...baseSubtitleStyles,
    fontSize: 'lg',
    textAlign: horizontalPositionToTextAlignMap[horizontalPosition],
    color,
  })
);

export const Subtitle2 = Text.withConfig<ColorableAndHorizontalPositionable>(
  ({ color, horizontalPosition }) => ({
    ...baseSubtitleStyles,
    fontSize: 'sm',
    textAlign: horizontalPositionToTextAlignMap[horizontalPosition],
    color,
  })
);

const baseBodyStyles: TextProps = {
  lineHeight: 'md',
};

export const Normal = Text.withConfig<ColorableAndHorizontalPositionable>(
  ({ color, horizontalPosition, fontSize, lineHeight }) => ({
    fontSize: fontSize ? fontSize : 'sm',
    lineHeight: lineHeight ? lineHeight : 'lg',
    textAlign: horizontalPositionToTextAlignMap[horizontalPosition],
    color,
  })
);

export const Body1 = Text.withConfig<ColorableAndHorizontalPositionable>(
  ({ color, horizontalPosition }) => ({
    ...baseBodyStyles,
    fontSize: 'sm',
    lineHeight: 'lg',
    textAlign: horizontalPositionToTextAlignMap[horizontalPosition],
    color,
  })
);

export const Body2 = Text.withConfig<ColorableAndHorizontalPositionable>(
  ({ color, horizontalPosition }) => ({
    ...baseBodyStyles,
    fontSize: 'xs',
    textAlign: horizontalPositionToTextAlignMap[horizontalPosition],
    color,
  })
);

export const Caption = Text.withConfig<ColorableAndHorizontalPositionable>(
  ({ color, horizontalPosition }) => ({
    ...baseBodyStyles,
    fontSize: 'xs',
    textAlign: horizontalPositionToTextAlignMap[horizontalPosition],
    color,
  })
);
