import { Box, Header, HeaderProps, Text, TextProps } from '@rbilabs/universal-components';

import BlockRenderer from 'components/sanity-block-renderer';
import { theme } from 'styles/configure-theme';

const staticPageHeader2: { [key: string]: HeaderProps } = {
  'terms-conditions-rewards': {
    textAlign: 'left',
  },
};

const staticPageHeader3: { [key: string]: HeaderProps } = {
  'about-bk': {
    textAlign: 'left',
    fontFamily: Styles.fontFamily.body,
  },
  'terms-of-service': {
    textAlign: 'left',
    fontFamily: 'body',
  },
};

const staticPageParagraph: { [key: string]: TextProps } = {
  'about-bk': {
    marginTop: '$1',
  },
};

const HeaderCommon = {
  fontWeight: 'light',
  padding: 0,
  paddingTop: '$8',
  margin: 0,
  marginBottom: '$2',
};

export const UnknownWidget = Box;

export const Header1 = Header.withConfig({
  ...HeaderCommon,
  textAlign: 'center',
  variant: 'headerOne',
  fontSize: {
    base: '4xl',
    md: '6xl',
  },
  display: 'flex',
});

export const Header2 = Header.withConfig<{ pageSlug: string }>(p => ({
  ...HeaderCommon,
  textAlign: 'center',
  variant: 'headerTwo',
  fontSize: {
    base: '2xl',
    md: '4xl',
  },
  display: 'flex',
  ...(staticPageHeader2[p.pageSlug] ?? null),
}));

export const Header3 = Header.withConfig<{ pageSlug: string }>(p => ({
  ...HeaderCommon,
  textAlign: 'center',
  variant: 'headerThree',
  fontSize: {
    base: 'lg',
    md: '3xl',
  },
  display: 'flex',
  ...(staticPageHeader3[p.pageSlug] ?? null),
}));

export const Header4 = Header.withConfig({
  ...HeaderCommon,
  variant: 'headerFour',
  fontSize: 'lg',
  display: 'flex',
});

export const Header5 = Header.withConfig({
  ...HeaderCommon,
  variant: 'headerFour',
  fontSize: 'lg',
  display: 'flex',
});

export const Paragraph = Text.withConfig<{ pageSlug: string }>(p => ({
  variant: 'copyOne',
  fontSize: {
    base: 'md',
    md: 'xl',
  },
  lineHeight: 'lg',
  marginTop: '$8',
  _web: {
    display: 'block',
  },
  ...(staticPageParagraph[p.pageSlug] ?? null),
}));

export const BlockWidgetContainer = BlockRenderer.withConfig({
  marginBottom: '$6',
  marginX: 'auto',
  width: 'full',
});

export const ListItemNumber = Text;

export const ListItem = Text.withConfig({
  variant: 'copyOne',
  fontSize: {
    base: 'md',
    md: 'xl',
  },
  lineHeight: 'md',
  marginTop: '$2',
});

export const blockWidgetStrongProps = {
  lineHeight: 'lg',
};

export const blockWidgetLinkProps = {
  color: theme.token('text-link-hover'),
};
