import { View } from '@rbilabs/universal-components';

import { addWithConfig } from '../../utils';
import { Checkbox } from '../checkbox';
import { HStack } from '../h-stack';
import { Pressable } from '../pressable';
import { Text } from '../text';

import { ListItemCheckboxProps } from './types';

export const ListItemCheckbox = <ValueType extends string>({
  value,
  label,
  isChecked,
  onChange,
  disabled,
  isLastItem,
  ...props
}: ListItemCheckboxProps<ValueType>) => {
  const Component = disabled ? View : Pressable;

  return (
    // @ts-ignore to ignore invalid properties in order to allow accessibility on disabled items
    <Component
      accessible
      accessibilityRole="checkbox"
      accessibilityState={{ checked: isChecked }}
      accessibilityHint=""
      paddingBottom="0"
      paddingTop="0"
      width="full"
      bg="token.background-pattern"
      _pressed={{ bg: 'token.background-hover' }}
      _hover={{ bg: 'token.background-hover' }}
      onPress={() => onChange(value)}
      // ignore any touch events on actual checkbox component
      pointerEvents="box-only"
      isDisabled={disabled}
      {...props}
    >
      <HStack
        justifyContent="space-between"
        alignItems="center"
        mx="$4"
        py="$2"
        borderBottomWidth={isLastItem ? '0' : '1'}
        borderBottomColor="token.border-color-options"
      >
        <Text color={disabled ? 'token.text-disabled' : 'token.text-default'}>{label}</Text>
        <Checkbox
          value={value}
          isChecked={disabled || isChecked}
          mt="$2.5"
          isDisabled={disabled}
          isNullVariation={disabled}
        />
      </HStack>
    </Component>
  );
};

export default addWithConfig(ListItemCheckbox);
