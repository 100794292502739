import { flatMap, isEmpty, isUndefined } from 'lodash-es';
import { dollarsToCents } from 'utils';

import { ICartEntry, IDiscount, IOfferDiscount } from '@rbi-ctg/menu';
import { DiscountTypes, MenuObjectTypes, OfferDiscountTypes } from 'enums/menu';
import { IAppliedRewards } from 'state/loyalty/hooks/types';
import { LoyaltyOffer } from 'state/loyalty/types';
import { priceForCartEntry } from 'utils/menu/price';
import { PosVendors } from 'utils/vendor-config';

import { CartEntryType } from './types';

interface ICartEntryForPricing extends Pick<ICartEntry, 'price' | 'quantity' | 'cartId'> {}

export const getIndividualItemPrice = ({
  cartEntry,
}: {
  cartEntry: ICartEntryForPricing;
}): number => (cartEntry.price || 0) / cartEntry.quantity;

export const getItemLoyaltyRewardDiscount = ({
  cartEntry,
  appliedLoyaltyRewards,
}: {
  cartEntry: ICartEntryForPricing;
  appliedLoyaltyRewards: IAppliedRewards;
}): number => {
  const individualItemPrice = getIndividualItemPrice({ cartEntry });
  const timesRewardApplied = appliedLoyaltyRewards[cartEntry.cartId]?.timesApplied ?? 0;
  return individualItemPrice * timesRewardApplied;
};

export const getCartEntryBenefit = (
  cartEntries: ICartEntry[],
  getAvailableRewardFromCartEntry: (cartEntry: ICartEntry) => void,
  rewardBenefitId: string | undefined
) =>
  cartEntries?.find(entry => {
    const { _id } = entry ?? {};
    // in the case of pickers, the `_id` won't match `rewardBenefitId`
    // however we can derive the resolved item/combo using the `url` property on the cartEntry
    return _id === rewardBenefitId || getAvailableRewardFromCartEntry(entry);
  });

// Used only when cartV2 is enabled to get all the cart entries that have a reward benefit id instead
// of only the first one.
export const getCartEntriesBenefit = (
  cartEntries: ICartEntry[],
  getAvailableRewardFromCartEntry: (cartEntry: ICartEntry) => void,
  rewardBenefitId: string | undefined
): ICartEntry[] | undefined =>
  cartEntries?.filter(entry => {
    const { _id } = entry ?? {};
    // in the case of pickers, the `_id` won't match `rewardBenefitId`
    // however we can derive the resolved item/combo using the `url` property on the cartEntry
    return _id === rewardBenefitId || getAvailableRewardFromCartEntry(entry);
  });

export const getLoyaltyRewardsDiscount = ({
  cartEntries,
  loyaltyEnabled,
  appliedLoyaltyRewards,
}: {
  cartEntries: ICartEntryForPricing[];
  loyaltyEnabled: boolean;
  appliedLoyaltyRewards?: IAppliedRewards;
}): number =>
  !loyaltyEnabled || isUndefined(appliedLoyaltyRewards) || isEmpty(appliedLoyaltyRewards)
    ? 0
    : cartEntries.reduce(
        (totalRewardsDiscount: number, cartEntry: ICartEntryForPricing): number => {
          const discount: number = getItemLoyaltyRewardDiscount({
            cartEntry,
            appliedLoyaltyRewards,
          });
          return totalRewardsDiscount + discount;
        },
        0
      );

export const getLoyaltyOfferDiscount = ({
  loyaltyEnabled,
  appliedLoyaltyOfferDiscount,
}: {
  loyaltyEnabled: boolean;
  appliedLoyaltyOfferDiscount?: IOfferDiscount | null | undefined;
}) => {
  return loyaltyEnabled && isOfferDiscount(appliedLoyaltyOfferDiscount)
    ? appliedLoyaltyOfferDiscount
    : undefined;
};

export const computeTotalWithOfferDiscount = (
  totalCents: number,
  offerDiscount?: IOfferDiscount
) => {
  const { discountValue, discountType } = offerDiscount ?? {};

  // We can't apply a discount if we don't have a discountValue
  if (!discountValue || discountValue === 0) {
    return totalCents;
  }

  if (discountType === OfferDiscountTypes.AMOUNT) {
    const discountValueInCents = Math.abs(dollarsToCents(discountValue));
    // Negative values are allowed, but the consumer of this method should handle them properly
    return totalCents - discountValueInCents;
  }

  if (discountType === OfferDiscountTypes.PERCENTAGE) {
    const discountPercentage = discountValue / 100;
    // If the discount percentage is over 100% the order is free...
    if (discountPercentage >= 1) {
      return 0;
    }
    const discountValueInCents = Math.round(totalCents * discountPercentage);
    return totalCents - discountValueInCents;
  }
  // there is a discountType we are not accounting for... Consider a log here to track this?
  return totalCents;
};

export const computeCartTotal = (
  cartEntries: ICartEntry[],
  loyaltyInfo: {
    loyaltyEnabled: boolean;
    appliedLoyaltyRewards?: IAppliedRewards;
    appliedLoyaltyOfferDiscount?: IOfferDiscount | null | undefined;
  }
) => {
  const loyaltyRewardsDiscount = getLoyaltyRewardsDiscount({
    cartEntries,
    ...loyaltyInfo,
  });

  const totalWithRewardsApplied =
    Math.max(
      cartEntries.reduce(
        (totalPrice: number, entry: ICartEntry) => priceForCartEntry(entry) + totalPrice,
        0
      ),
      0
    ) - loyaltyRewardsDiscount;

  // When the offer has an offerDiscount calculate the discount off the entire order
  const offerDiscount = getLoyaltyOfferDiscount(loyaltyInfo);
  if (offerDiscount) {
    const donationItemInCart = cartEntries.find(item => item.isDonation);
    const donationPrice = donationItemInCart?.price;
    // It is then added to the subTotal once the discount is already applied to the other cart entries
    if (donationItemInCart && donationPrice) {
      // This is to exclude the donation item from the offer discount calculation
      return computeTotalWithOfferDiscount(totalWithRewardsApplied - donationPrice, offerDiscount);
    }
    return computeTotalWithOfferDiscount(totalWithRewardsApplied, offerDiscount);
  }
  return totalWithRewardsApplied;
};

export const computeOtherDiscountAmount = (allDiscounts?: IDiscount[]) => {
  if (!allDiscounts) {
    return 0;
  }
  return allDiscounts
    .filter(discount => discount.name !== DiscountTypes.REWARDS_DISCOUNTS)
    .reduce((acc, curr) => curr.value + acc, 0);
};

export const computeDeliveryFee = ({
  feeCents,
  feeDiscountCents,
  serviceFeeCents,
  smallCartFeeCents,
  geographicalFeeCents,
}: {
  feeCents: number | null;
  feeDiscountCents: number | null;
  serviceFeeCents: number | null;
  smallCartFeeCents: number | null;
  geographicalFeeCents: number | null;
}) => {
  // The value "feeCents" includes the additional fees "serviceFeeCents" and "smallCartFeeCents"
  return Math.max(
    (feeCents || 0) -
      (feeDiscountCents || 0) -
      (serviceFeeCents || 0) -
      (smallCartFeeCents || 0) -
      (geographicalFeeCents || 0),
    0
  );
};

export const isOfferDiscount = (option: any): option is IOfferDiscount =>
  option?._type === MenuObjectTypes.OFFER_DISCOUNT;

/**
 * Find first OfferDiscount benefit inside an Offers list, if any
 *
 * @param offers A LoyaltyOffer array
 */
export const getAppliedLoyaltyOfferDiscount = (
  offers?: LoyaltyOffer[]
): IOfferDiscount | undefined => {
  const benefits = flatMap(offers, offer => offer.incentives);
  const [firstOfferDiscount] = benefits.filter(isOfferDiscount) as IOfferDiscount[];
  return firstOfferDiscount;
};

/**
 * BOGO Offers are a particular kind of offer in which you have twice the same menu item
 * in one combo, these offers are being priced in a different way for some particular vendors.
 * @param entry Entry
 * @param vendor Vendor type
 * @returns boolean true if is bogo or false
 */
export const checkIsBogoOfferItem = (entry: ICartEntry, vendor: PosVendors | null) =>
  entry &&
  entry.type === CartEntryType.offerCombo &&
  entry.children?.length === 3 &&
  entry.children[1].name === entry.children[2].name;
