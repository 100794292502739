import { Icon, Header as UCHeader, makeUclComponent } from '@rbilabs/universal-components';

import ActionButton from 'components/action-button';
import Modal, { ModalContent as UCModalContent } from 'components/modal';
import { theme } from 'styles/configure-theme';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

export const ModalContent = UCModalContent.withConfig({
  alignSelf: 'center',
  mt: '$0',
  maxWidth: {
    base: 'full',
    md: '600px',
  },
});

export const Header = UCHeader.withConfig({
  mb: '$8',
  variant: 'headerOne',
  testID: 'modal-heading',
  textAlign: 'center',
});

export const StyledModal = makeUclComponent(Modal).withConfig({
  headerStyle: {
    _web: {
      height: '$13.5',
      shadow: '1',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
  },
});

export const StyledBackIcon = Icon.withConfig({
  color: theme.token('icon-header-contrast'),
  size: '5',
  ...hiddenAccessibilityPlatformProps,
});

export const StyledActionButton = makeUclComponent(ActionButton).withConfig({
  width: {
    base: 'full',
    md: '300px',
  },
  alignSelf: 'center',
  marginTop: '$4',
});
