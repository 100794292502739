import React, { useCallback, useState } from 'react';

import {
  ButtonProps,
  Text,
  TextProps,
  LinkProps as UCCLLinkProps,
  Link as UCLLink,
  addWithConfig,
} from '@rbilabs/universal-components';
import { GestureResponderEvent, Linking } from 'react-native';

import { getTextFromChildren } from 'components/action-button';
import { useNavigation } from 'hooks/navigation/use-navigation';
import useDebounceOnPressCallback from 'hooks/use-debounce-on-press-callback';
import { CustomEventNames, useMParticleContext } from 'state/mParticle';
import { isExternalLink } from 'utils/is-external-link';

export { useHref } from 'react-router-native';

const StyledInLineLink = Text.withConfig<{
  isUnderlined: boolean;
  isSelected: boolean;
  isDisabled: boolean;
}>(props => ({
  variant: props.variant ?? 'copyOne',
  color: props.color ?? (props.isSelected ? Styles.color.tertiary : Styles.color.black),
  textDecorationLine: props.isUnderlined ? 'underline' : 'none',
  _hover: {
    color: Styles.color.tertiary,
  },
  _web: {
    cursor: props.isDisabled ? 'text' : 'pointer',
  },
}));

const StyledLink = UCLLink.withConfig<{
  isUnderlined: boolean;
  isSelected: boolean;
  isDisabled: boolean;
}>(props => ({
  variant: 'copyOne',
  color: props.isSelected ? Styles.color.tertiary : Styles.color.black,
  _hover: {
    color: Styles.color.tertiary,
  },
  _web: {
    cursor: props.isDisabled ? 'text' : 'pointer',
  },
}));

type IButtonProps = ButtonProps;

interface ILinkSharedProps {
  // Route to navigate with or without state
  to?: string;
  href?: string;
  // Path to navigate using linking options
  linkPath?: string;
  // This will open in a new tab
  isExternal?: boolean;
  // Is the link active
  isSelected?: boolean;
  isUnderlined?: boolean;
  // React Router
  replace?: boolean;
  // React Router
  state?: any;
  eventName?: CustomEventNames;
  touchableOpacityEffect?: boolean;
  isDisabled?: boolean;
}

export interface IInLineLinkProps extends ILinkSharedProps, TextProps {
  // Is the link used inside of a text block
  isInline: true;
  // Native base had different types
  onPress?: ((event?: GestureResponderEvent) => void) | undefined;
  // TODO: RN WEB - implement hover
  _hover?: Omit<IButtonProps, '_hover'>;
}

export interface ILinkProps extends ILinkSharedProps, UCCLLinkProps {
  isInline?: boolean | undefined;
  // Native base had different types
  onPress?: ((event?: GestureResponderEvent) => any) | null | undefined;
}

const openExternalLink = (URL: string) => {
  Linking.openURL(URL).catch(err =>
    // eslint-disable-next-line no-console
    console.warn(`Link: there was an error opening the URL: ${URL}`, err)
  );
};

// TODO: RN - add web version of StyledInLineLink
function LinkBase(props: IInLineLinkProps | ILinkProps): React.ReactElement {
  const {
    to,
    linkPath,
    onPress: _onPress,
    children,
    replace,
    state,
    isUnderlined,
    isInline,
    isSelected,
    eventName,
    href,
    isDisabled,
    ...rest
  } = props;
  const { logNavigationClick } = useMParticleContext();
  const { navigate, linkTo } = useNavigation();
  const toIsExternal = isExternalLink(to);

  const onPress = useDebounceOnPressCallback(
    (event?: GestureResponderEvent) => {
      event?.preventDefault();
      if (isDisabled) {
        return;
      }

      if (eventName) {
        logNavigationClick(eventName);
      }

      if (_onPress) {
        _onPress(event);
      }

      if (linkPath) {
        const openLink = isExternalLink(linkPath) ? openExternalLink : linkTo;
        return openLink(linkPath);
      }

      if (to) {
        // This is an internal link
        // React Router Native
        if (toIsExternal) {
          openExternalLink(to);
          return;
        }

        navigate(to, { state, replace });
      }
    },
    [eventName, to, linkPath, _onPress, toIsExternal, state, replace, isDisabled]
  );

  const [opacity, setOpacity] = useState(rest.opacity);
  const origOnPressIn = (rest as TextProps)?.onPressIn;
  const origOnPressOut = (rest as TextProps)?.onPressOut;
  const touchableEffects = {
    opacity,
    onPressIn: useCallback(
      (e: any) => {
        if (isDisabled) {
          return;
        }
        setOpacity(0.3);
        origOnPressIn?.(e);
      },
      [isDisabled, origOnPressIn]
    ),
    onPressOut: useCallback(
      (e: any) => {
        if (isDisabled) {
          return;
        }
        setOpacity(rest.opacity);
        origOnPressOut?.(e);
      },
      [isDisabled, origOnPressOut, rest.opacity]
    ),
  };

  const propsToForward = {
    isUnderlined: Boolean(isUnderlined),
    isSelected: Boolean(isSelected),
    accessibilityRole: isDisabled ? 'none' : 'link',
    accessible: !isDisabled,
    onPress,
    href: href ?? to ?? linkPath,
    children,
    isDisabled,
    ...(rest as any),
    ...(props.touchableOpacityEffect ? touchableEffects : null),
  } as const;

  const ddActionName =
    props?.['dd-action-name'] || eventName || props?.testID || getTextFromChildren(children);

  if (isInline) {
    return <StyledInLineLink {...propsToForward} dd-action-name={ddActionName} />;
  }

  return <StyledLink {...propsToForward} dd-action-name={ddActionName} />;
}

const Link = addWithConfig(LinkBase);

const secondaryHoverColor = '#000'; // deviates from theme to increase color contrast

export const SecondaryLink = Link.withConfig<{ isSelected?: boolean }>(p => ({
  color: p.isSelected ? secondaryHoverColor : Styles.color.white,
}));

export default Link;
