import React from 'react';

import { Header, Text, Divider as UCLDivider } from '@rbilabs/universal-components';

import { ModalHeading } from 'components/modal';

import { ContentContainer } from './styled';
import { IStoreDiagnosticData } from './types';

const CodeWrapper = Text.withConfig({
  bg: Styles.color.codeBlockBackground,
  color: Styles.color.black,
  justifyContent: 'center',
  paddingY: '1',
  paddingX: {
    base: '2',
    md: '4',
  },
  fontSize: 'xl',
  variant: 'mono',
  textAlign: 'center',
  fontWeight: 'light',
});

const Divider = UCLDivider.withConfig({
  marginY: '6',
});

const StyledModalHeading = ModalHeading.withConfig({
  marginBottom: '6',
});

const StyledModalSubHeading = Header.withConfig({
  marginBottom: '6',
  textAlign: 'center',
  fontWeight: 'normal',
});

const BoldMonospace = Text.withConfig({
  fontWeight: 'bold',
  fontSize: 'xl',
});

const Paragraph = Text.withConfig({
  textAlign: 'center',
});

export const determineStoreInfoSubheader = (
  storeName: string | undefined,
  storeAddress: string | undefined
) => {
  if (!storeName) {
    return `(${storeAddress})`;
  }

  if (!storeAddress || storeName.toLowerCase() === storeAddress.toLowerCase()) {
    return `(${storeName})`;
  }

  return `(${storeName} - ${storeAddress})`;
};

const DiagnosticModalContents: React.FC<React.PropsWithChildren<{
  storeDiagnosticData: IStoreDiagnosticData | undefined;
}>> = ({ storeDiagnosticData }) => {
  if (!storeDiagnosticData) {
    return (
      <ContentContainer>
        <StyledModalHeading>No Diagnostic Data Available</StyledModalHeading>
        <p>Sorry, diagnostic data is not available at this time!</p>
      </ContentContainer>
    );
  }

  return (
    <ContentContainer>
      <StyledModalHeading>Store Diagnostics</StyledModalHeading>
      <StyledModalSubHeading>
        {determineStoreInfoSubheader(
          storeDiagnosticData.restaurantName,
          storeDiagnosticData.restaurantStreetAddress
        )}
      </StyledModalSubHeading>
      <Paragraph>
        This store's online ordering should be enabled if all three of these properties are true
        (these are the current values for this store):
      </Paragraph>

      <CodeWrapper>{`isRestaurantOpen: ${storeDiagnosticData.isRestaurantOpen}`}</CodeWrapper>
      <CodeWrapper>{`isMobileOrderingAvailable: ${storeDiagnosticData.isMobileOrderingAvailable}`}</CodeWrapper>
      <CodeWrapper>{`isRestaurantPosOnline: ${storeDiagnosticData.isRestaurantPosOnline}`}</CodeWrapper>

      <Paragraph>
        For Tim Hortons stores, the store must also have a country in its physical address to enable
        online ordering (here's the current value for this store):
      </Paragraph>

      <CodeWrapper>{`country: '${storeDiagnosticData.restaurantCountry}'`}</CodeWrapper>

      <Divider />
      <Paragraph>
        <BoldMonospace>isRestaurantOpen </BoldMonospace>
        is true if either of these store properties is true (here are the current values for this
        store):
      </Paragraph>
      <CodeWrapper>{`driveThruOpen: ${storeDiagnosticData.driveThruOpen}`}</CodeWrapper>
      <CodeWrapper>{`diningRoomOpen: ${storeDiagnosticData.diningRoomOpen}`}</CodeWrapper>

      <Divider />
      <Paragraph>
        <BoldMonospace>isMobileOrderingAvailable </BoldMonospace>
        is set to the value of the store's <BoldMonospace>hasMobileOrdering </BoldMonospace>
        property (here's the current value for this store):
      </Paragraph>

      <CodeWrapper>{`hasMobileOrdering: ${storeDiagnosticData.restaurantHasMobileOrdering}`}</CodeWrapper>

      <Divider />
      <Paragraph>
        <BoldMonospace>isRestaurantPosOnline </BoldMonospace>is determined by a store's heartbeat
        check.
      </Paragraph>
    </ContentContainer>
  );
};

export default DiagnosticModalContents;
