import { PermissionStatus } from 'expo-modules-core';

import { appTrackingTransparency } from 'state/app-tracking-transparency-permissions';
import { CustomEventNames, EventTypes, IMParticleCtx } from 'state/mParticle';
import { EventName, emitEvent } from 'utils/event-hub';
import logger from 'utils/logger';
import { enableLocation, enablePush, openPushSetting } from 'utils/permissions';
import { promptReview } from 'utils/review/prompt-review';

export enum OsPrompts {
  push = 'push',
  location = 'location',
  review = 'review',
  att = 'att',
  pushSetting = 'pushSetting',
}

// checkForOSPrompts checks a URL for a `/OS/*`
// return true if /OS/ link
export const checkForOSPrompts = (
  url: string,
  mparticleLogEvent?: IMParticleCtx['logEvent'],
  mparticleLogRBIEvent?: IMParticleCtx['logRBIEvent'],
  callback?: () => void
): boolean => {
  // If the link is /OS/* then prompt
  const parts = url.split('/');
  if (parts && parts.length === 3 && parts[1] === 'OS') {
    const routeTo = parts[2];

    switch (routeTo) {
      case OsPrompts.push:
        enablePush().then(response => {
          if (callback) {
            callback();
          }
          if (!mparticleLogRBIEvent || !response) {
            return;
          }

          mparticleLogRBIEvent({
            name: CustomEventNames.PUSH_NOTIFICATIONS_RESPONSE,
            type: EventTypes.Other,
            attributes: {
              response: response.status,
            },
          });
        });
        return true;
      case OsPrompts.location:
        emitEvent(EventName.LOCATION_PERMISSION_REQUEST_START);
        enableLocation()
          .catch(err => {
            logger.warn(`location: enableLocation error: ${err}`);
          })
          .finally(() => {
            emitEvent(EventName.LOCATION_END);
            if (callback) {
              callback();
            }
          });
        return true;
      case OsPrompts.review:
        promptReview().then(() => {
          if (callback) {
            callback();
          }
          if (mparticleLogRBIEvent) {
            mparticleLogRBIEvent({
              name: CustomEventNames.APP_REVIEW_POP_UP,
              type: EventTypes.Other,
              attributes: {},
            });
          }
        });
        return true;
      case OsPrompts.att:
        appTrackingTransparency()
          // @ts-expect-error TS(2345) FIXME: Argument of type '(status: PermissionStatus) => vo... Remove this comment to see the full error message
          .then((status: PermissionStatus) => {
            if (callback) {
              callback();
            }
            if (mparticleLogEvent) {
              mparticleLogEvent(
                CustomEventNames.APP_TRACKING_REQUEST_PERMISSION,
                EventTypes.Other,
                {
                  'App Tracking Response': status,
                }
              );
            }
          })
          .catch(() => {
            logger.warn(`Error in requesting user authorizaton for App Tracking Transparency`);
          });
        return true;
      case OsPrompts.pushSetting:
        openPushSetting();
        if (callback) {
          callback();
        }
        return true;
      default:
        logger.warn(`location: unknown OS/ link: ${url}`);
        return false;
    }
  }
  // No navigation is required so return undefined
  // return the URL
  return false;
};
