import { useIntl } from 'react-intl';

import { useLocale } from 'state/intl';

import { CaloriesSuffix, IUseFormatCalories } from './types';

export function useFormatCalories(): IUseFormatCalories {
  const { formatMessage } = useIntl();
  const { locale } = useLocale();

  return function formatCalories(calories: number | null, suffix: CaloriesSuffix = 'Cal'): string {
    const calsString = suffix !== 'none' ? formatMessage({ id: suffix.toLowerCase() }) : '';

    if ((!calories && calories !== 0) || isNaN(calories)) {
      return '';
    }

    const calorieDisplay = Math.floor(calories).toLocaleString(locale);

    return `${calorieDisplay} ${calsString}`.trim();
  };
}
