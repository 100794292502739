import { camelCase } from 'lodash-es';

import { Nullable } from '@rbi-ctg/frontend';
import { IRestaurant } from '@rbi-ctg/store';
import { ServiceMode } from 'state/service-mode/types';
import { PosVendors } from 'utils/vendor-config';

export const getPosVendorFromStore = (
  store: IRestaurant,
  serviceMode: Nullable<ServiceMode> = null
): PosVendors | null => {
  if (!store?.pos?.vendor) {
    return null;
  }

  const vendor = camelCase(store.pos.vendor);
  return (serviceMode === ServiceMode.DELIVERY ? vendor.concat('Delivery') : vendor) as PosVendors;
};
