import * as React from 'react';

import { BoxProps, makeUclComponent } from '@rbilabs/universal-components';

import { QuestBadgeIcon, QuestBadgeText, QuestBadgeWrapper } from './styled';

type QuestBadgeProps = BoxProps & { text?: string };

export const QuestBadge = makeUclComponent<QuestBadgeProps>(({ text, ...props }) => {
  if (!text) {
    return null;
  }

  return (
    <QuestBadgeWrapper {...props}>
      <QuestBadgeIcon />
      <QuestBadgeText variant="copyTwo">{text}</QuestBadgeText>
    </QuestBadgeWrapper>
  );
});
