import { Svg, SvgPath, SvgProps, registerSVG } from '../../../components/svg';

export function BkRewards(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <SvgPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5936 3.53903C19.4698 4.84906 19.9378 6.38943 19.9386 7.96544C19.9386 10.0794 19.0991 12.1068 17.6047 13.6019C16.1102 15.0971 14.0832 15.9375 11.9693 15.9386C10.3933 15.9386 8.85263 15.4713 7.54217 14.5958C6.23172 13.7202 5.21029 12.4759 4.607 11.0199C4.00371 9.56391 3.84566 7.96176 4.15285 6.41597C4.46003 4.87019 5.21865 3.45018 6.33279 2.33551C7.44693 1.22083 8.86654 0.461524 10.4122 0.153594C11.9578 -0.154336 13.5601 0.00295497 15.0163 0.605542C16.4726 1.20813 17.7175 2.22899 18.5936 3.53903ZM8.01185 6.93691C7.92418 6.5087 8.33934 6.16015 8.71442 6.34709L10.2507 7.11281C10.4847 7.22944 10.7643 7.13837 10.8955 6.90277L11.81 5.26058C12.0054 4.90959 12.4873 4.91406 12.6769 5.26862L13.5638 6.92726C13.6884 7.16042 13.9584 7.25825 14.1923 7.15502L15.8776 6.41114C16.2518 6.24601 16.6467 6.59489 16.5591 7.01312L15.8106 10.5856C15.76 10.8277 15.5568 11 15.3221 11H9.24748C9.0119 11 8.80823 10.8264 8.75842 10.5832L8.01185 6.93691ZM7.60734 16.1383C6.94863 15.772 6.33507 15.3299 5.7792 14.8209C5.58717 14.6481 5.39511 14.4715 5.22228 14.2833C4.12003 16.8987 3.11378 19.4988 2.14979 22.3255L2 22.8824L5.89823 20.9198C5.97032 20.8914 6.04852 20.8822 6.12524 20.893C6.20195 20.9038 6.27458 20.9342 6.33607 20.9813L8.64044 23.47C8.67672 23.4307 8.7077 23.3868 8.73261 23.3394C9.78304 20.7692 10.6023 18.9107 11.3935 17.1157L11.4671 16.9486C10.9156 16.9465 10.3649 16.9042 9.81949 16.8219C9.04768 16.7257 8.29889 16.4942 7.60734 16.1383ZM22.0365 22.3255C21.0802 19.5334 20.0855 16.9486 19.0024 14.3831L18.8603 14.4906C18.3729 14.8554 17.8595 15.184 17.3241 15.4738C15.892 16.2595 14.3146 16.7443 12.6885 16.8987C13.5872 18.8881 14.4705 20.8853 15.5343 23.4738C15.6073 23.6582 15.7801 24 15.7801 24L17.7581 21.1579C17.8552 21.0566 17.9825 20.9895 18.121 20.9666C18.2595 20.9438 18.4017 20.9664 18.5262 21.0312L22.1902 22.8824L22.0365 22.3255Z"
      />
    </Svg>
  );
}

registerSVG('BkRewards', BkRewards);
