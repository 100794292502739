import React from 'react';

import { Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { LinkNoDecoration } from 'components/app-header/styled';
import { useReversedUIContext } from 'state/reversed-ui';
import { theme } from 'styles/configure-theme';

export const HamburgerButton: React.FC<React.PropsWithChildren<{}>> = () => {
  const { reversedUI } = useReversedUIContext();
  const { formatMessage } = useIntl();

  const iconColor = reversedUI ? theme.token('icon-reversed') : theme.token('icon-header-contrast');

  return (
    <LinkNoDecoration
      testID="hamburger-menu-button"
      to={formatMessage({ id: 'routes.account' })}
      accessibilityLabel={formatMessage({ id: 'hamburgerMenuAriaLabel' })}
    >
      <Icon variant="menu" color={iconColor} aria-hidden size="$6" />
    </LinkNoDecoration>
  );
};
