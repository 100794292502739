import { LANGUAGES } from 'state/intl/types';
import { Region } from 'utils/environment/types';

export enum PROD_SUPPORTED_LANGUAGES {
  en = LANGUAGES.en,
  fr = LANGUAGES.fr,
}

export const PROD_DEFAULT_LANGUAGE = {
  [Region.US]: LANGUAGES.en,
  [Region.CA]: LANGUAGES.en,
};
