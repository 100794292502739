import { FC } from 'react';

import { HStack } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import Picture from 'components/picture';

import { WalmartLandingOffersProps } from '../walmart/types';
import { useLoyaltyWalmart } from '../walmart/use-loyalty-walmart';

import {
  Badges,
  Container,
  HeaderBkAndWalmart,
  HeaderReadySavings,
  ItemContent,
  LinkRoyalText,
  OfferBadge,
  OfferContainer,
  OfferItem,
  StyledPicture,
  Subtitle,
  Title,
} from './styled';

export const WalmartLandingOffers: FC<React.PropsWithChildren<WalmartLandingOffersProps>> = ({
  isAlreadyLinked,
}) => {
  const { data, loading } = useLoyaltyWalmart();
  const { formatMessage } = useIntl();

  if (loading) {
    return null;
  }

  const badge = data?.badge?.locale;

  return (
    <Container>
      {!isAlreadyLinked && (
        <HeaderBkAndWalmart>
          {formatMessage({ id: 'walmartLandingBkWalmartTogether' })}
        </HeaderBkAndWalmart>
      )}
      <HeaderReadySavings>
        {isAlreadyLinked
          ? formatMessage({ id: 'walmartLandingItsBetter' })
          : formatMessage({ id: 'walmartLandingReadyForMoreSavings' })}
      </HeaderReadySavings>
      <LinkRoyalText>
        {isAlreadyLinked
          ? formatMessage({ id: 'walmartLandingEnjoyYourExclusive' })
          : formatMessage({ id: 'walmartLandingLinkYourRoyal' })}
      </LinkRoyalText>
      <OfferContainer>
        <OfferItem>
          <HStack mr="$2">
            <StyledPicture source={require('./25Off.png')} alt={'25 Off'} />
            <ItemContent>
              <Title>{formatMessage({ id: 'walmartLanding25Off' })}</Title>
              <Subtitle>{formatMessage({ id: 'walmartLandingDailyOn' })}</Subtitle>
              <Badges>
                <Picture
                  image={badge}
                  alt={'Walmart'}
                  objectFitContain
                  lazy={false}
                  height="24px"
                />
                <OfferBadge>{formatMessage({ id: 'walmartLandingForDigital' })}</OfferBadge>
              </Badges>
            </ItemContent>
          </HStack>
        </OfferItem>
      </OfferContainer>

      <OfferContainer>
        <OfferItem>
          <HStack mr="$2">
            <StyledPicture source={require('./FreeWhooper.png')} alt="Free Whopper" />
            <ItemContent>
              <Title>{formatMessage({ id: 'walmartLandingFreeWhopper' })}</Title>
              <Subtitle>{formatMessage({ id: 'walmartLandingWithPurchase' })}</Subtitle>
              <Badges>
                <Picture
                  image={badge}
                  alt={'Walmart'}
                  objectFitContain
                  lazy={false}
                  height="24px"
                />
                <OfferBadge>{formatMessage({ id: 'walmartLandingStartingOn' })}</OfferBadge>
              </Badges>
            </ItemContent>
          </HStack>
        </OfferItem>
      </OfferContainer>
    </Container>
  );
};
