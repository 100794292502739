import { IPluWithQuantity } from '@rbi-ctg/menu';

import { PluQualifier } from './types';

// multi constant plus sometimes have qualifiers
// that express a quantity, i.e. NO or 1/2
// when an item option modifier has
// multi constant plus we should reduce the array
// of plus to a quantity to determine pricing
export function reduceMultiConstantPluQuantity(plus: IPluWithQuantity[]) {
  // if any NO plu is found this modifier
  // should not be considered "added"
  if (plus.find(({ qualifier }) => qualifier === PluQualifier.NO)) {
    return 0;
  }

  return plus.reduce((quantity, { qualifier, quantity: pluQuantity }) => {
    switch (qualifier) {
      case PluQualifier.HALF:
        return quantity + 0.5;
      case PluQualifier.ADD:
      default:
        return quantity + (pluQuantity || 0);
    }
  }, 0);
}
