import { Box, Header, Text } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';
import { primitive } from 'styles/constants/primitives';

export const TransformedHeadline = Header.withConfig({
  variant: 'headerTwo',
  textAlign: 'left',
  marginX: '0',
  marginTop: '0',
  marginBottom: '4',
});

export const SentEmailText = Text.withConfig({
  variant: 'copyOne',
  color: theme.token('text-default'),
  _web: {
    fontSize: 'sm',
  },
});

export const CodeSentText = Text.withConfig({
  marginY: '3',
  marginX: '0',
  variant: 'copyOne',
  textAlign: 'left',
});

export const ErrorMessage = Text.withConfig({
  color: primitive.$error,
  marginTop: '$2',
  marginX: '0',
  marginBottom: '6',
  variant: 'copyTwo',
  accessibilityRole: 'alert',
});

export const NotReceived = Box.withConfig({
  margin: '$0',
  textAlign: 'center',
  flexWrap: 'wrap',
  justifyContent: 'center',
  flexDirection: 'row',
  _web: {
    marginTop: '$2',
  },
});

export const CodeText = Text.withConfig({
  margin: '0',
  paddingTop: '0',
  paddingX: '1',
  paddingBottom: '1.25',
  variant: 'copyTwo',
  _web: {
    fontSize: 'sm',
  },
});

export const PressableTextWrapper = Text.withConfig({
  margin: '0',
  textDecorationLine: 'underline',
  variant: 'copyTwo',
  color: theme.token('text-default'),
  fontWeight: 'bold',
  _web: {
    fontSize: 'sm',
  },
});
