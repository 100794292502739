import React, { FC, PropsWithChildren } from 'react';

import { ScrollView } from '@rbilabs/universal-components';

import { useIsDesktopBreakpoint } from 'hooks/use-media-query';
import { staticLocation } from 'state/location';
import { useScrollContext } from 'state/scroll';

import { IContainerProps } from './types';

const defaultScrollViewProps = {};
const scrollViewPropsFullHeight = {
  contentContainerStyle: {
    flex: 1,
  },
};

export const ScrollContainer: FC<PropsWithChildren<IContainerProps>> = ({
  children,
  scrollRef,
}) => {
  const isDesktop = useIsDesktopBreakpoint();
  const { hasScrollFullHeight } = useScrollContext();

  // FIXME: BKPE-1489: issue with scroll views
  const pathname = staticLocation.current.pathname;

  const hasFullHeightContent =
    hasScrollFullHeight ||
    // FIXME: BKPE-1167: add all static pages with anchor links
    pathname.includes('/terms');

  const scrollViewProps = hasFullHeightContent ? scrollViewPropsFullHeight : defaultScrollViewProps;

  return (
    <ScrollView
      ref={scrollRef}
      showsVerticalScrollIndicator={isDesktop}
      nativeID="scroll-container"
      {...scrollViewProps}
    >
      {children}
    </ScrollView>
  );
};
