import { useCallback, useMemo } from 'react';

import { LoyaltyServiceMode } from 'generated/graphql-gateway';
import { useCmsConfigRewardsQuery, useLoyaltyRewardsQuery } from 'generated/sanity-graphql';
import { useLoyaltyEngineRewards } from 'hooks/use-loyalty-engine-rewards';
import { useAuthContext } from 'state/auth';
import { withSimpleCache } from 'state/graphql/with-simple-cache';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { ISanityRewardsMap, LoyaltyReward } from 'state/loyalty/types';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';

const useCachedLoyaltyRewardsQuery = withSimpleCache(useLoyaltyRewardsQuery);

export const useLoyaltyRewardsList = () => {
  const { serviceMode: ctxServiceMode } = useServiceModeContext();
  const serviceMode = LoyaltyServiceMode[ctxServiceMode || ''];
  const { store } = useStoreContext();
  const { isAuthenticated } = useAuthContext();
  const { loyaltyUser } = useLoyaltyUser();

  const {
    engineRewardsLoading,
    refetchEngineRewards,
    engineRewardIds,
    engineRewardsMap,
    personalizedRewardIds,
    personalizedRewardMap,
  } = useLoyaltyEngineRewards(isAuthenticated);

  // fetch all config rewards by ID
  const { data: configRewardsData, loading: configRewardsLoading } = useCmsConfigRewardsQuery({
    variables: {
      ids: personalizedRewardIds,
    },
    skip: !isAuthenticated || !personalizedRewardIds?.length,
  });

  const {
    data,
    loading: sanityRewardsLoading,
    refetch: refetchCmsRewards,
  } = useCachedLoyaltyRewardsQuery({
    skip: !engineRewardIds?.length,
  });

  const refetchRewards = useCallback(
    async (loyaltyId: string) => {
      await refetchEngineRewards({
        loyaltyId: loyaltyId || loyaltyUser?.id || '',
        where: {
          ignorePointBalance: true,
          serviceMode: serviceMode || undefined,
          storeId: store?.number,
        },
      });
      await refetchCmsRewards();
    },
    [loyaltyUser?.id, refetchCmsRewards, refetchEngineRewards, serviceMode, store?.number]
  );

  const rewardsByEngineId: LoyaltyReward[] | null = useMemo(() => {
    if (data?.allRewards) {
      const validRewards = data.allRewards.filter(
        reward => engineRewardsMap[reward?.loyaltyEngineId || '']
      );

      return [...validRewards, ...(configRewardsData?.allConfigRewards ?? [])];
    }
    return null;
  }, [data, engineRewardsMap, configRewardsData]);

  const sanityRewardsMap = useMemo(() => {
    if (!rewardsByEngineId) {
      return null;
    }

    const rewardsMap = rewardsByEngineId.reduce((acc: ISanityRewardsMap, reward) => {
      acc[reward._id] = reward;
      return acc;
    }, {});

    return Object.keys(personalizedRewardMap).reduce((acc: ISanityRewardsMap, id) => {
      const { sanityId } = personalizedRewardMap[id];
      if (sanityId) {
        rewardsMap[id] = acc[sanityId];
      }
      return rewardsMap;
    }, rewardsMap);
  }, [personalizedRewardMap, rewardsByEngineId]);

  const rewardsLoading = engineRewardsLoading || sanityRewardsLoading || configRewardsLoading;

  return {
    allRewards: data?.allRewards,
    rewards: rewardsByEngineId || null,
    loading: rewardsLoading,
    engineRewardsMap,
    sanityRewardsMap,
    refetchRewards,
  };
};
