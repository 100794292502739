export enum ShortCodePollingStatus {
  None = 'None',
  Pending = 'Pending',
  Processing = 'Processing',
  Confirmed = 'Confirmed',
  NotFound = 'NotFound',
}

export interface IUseShortCodePollStatusParams {
  loyaltyId?: string;
  shortCode?: string;
  skipPolling: boolean;
  pollInterval: number;
}

export interface IUseShortCodePollStatus {
  pollingShortCodeStatus: ShortCodePollingStatus;
  resetPollingStatus: () => void;
}
