import { format, isDate, isSameYear, parseISO } from 'date-fns';

const MOTH_DAY_FORMAT = 'MMM do';
const FULL_DATE_FORMAT = `${MOTH_DAY_FORMAT}, yyyy`;

const hasTimezone = (dateTime: string) => dateTime.includes('T');

const toNaiveStringDate = (dateTime: string) =>
  hasTimezone(dateTime) ? dateTime.split('T')[0] : dateTime;

function assertIsDate(date: Date, message?: string): asserts date is Date {
  if (!isDate(date)) {
    throw new Error(`${date} is not a date ${message}`);
  }
}

const parseQuestDate = (dateTime: string) => {
  const parsedDate = parseISO(toNaiveStringDate(dateTime));

  assertIsDate(parsedDate, `Error parsing quest date: ${dateTime}`);

  return parsedDate;
};

const formatQuestDateRange = (startDate: Date, endDate: Date): [string, string] => {
  const startDateFormat = isSameYear(startDate, endDate) ? MOTH_DAY_FORMAT : FULL_DATE_FORMAT;

  return [format(startDate, startDateFormat), format(endDate, FULL_DATE_FORMAT)];
};

export const formatQuestDates = (startDate: string, endDate: string): [string, string] => {
  const [parsedStartDate, parsedEndDate] = [startDate, endDate].map(parseQuestDate);

  return formatQuestDateRange(parsedStartDate, parsedEndDate);
};
