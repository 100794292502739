import { useCallback, useMemo, useState } from 'react';

export const useOnboardingNavigation = (numberOfScreens: number) => {
  const [currentScreenIndex, setCurrentScreenIndex] = useState(0);
  const [isFirstAppLoadOnboardingComplete, setIsFirstAllLoadOnboardingComplete] = useState(false);

  const isFirstScreen = useMemo(() => currentScreenIndex === 0, [currentScreenIndex]);
  const isLastScreen = useMemo(() => currentScreenIndex >= numberOfScreens - 1, [
    currentScreenIndex,
    numberOfScreens,
  ]);

  const navigateForward = useCallback(() => {
    if (isLastScreen) {
      setIsFirstAllLoadOnboardingComplete(true);
    } else {
      setCurrentScreenIndex(currentScreenIndex + 1);
    }
  }, [currentScreenIndex, isLastScreen]);

  const navigateBackward = useCallback(() => {
    setCurrentScreenIndex(currentScreenIndex - 1);
  }, [currentScreenIndex]);

  return {
    navigateForward,
    navigateBackward,
    currentScreenIndex,
    isFirstScreen,
    isLastScreen,
    isFirstAppLoadOnboardingComplete,
    setIsFirstAllLoadOnboardingComplete,
  };
};
