import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import { useLoyaltyContext } from 'state/loyalty';
import { useMParticleContext } from 'state/mParticle';
import { CustomEventNames } from 'state/mParticle/constants';
import { routes } from 'utils/routing';

import { useCmsQuestDetailLazyQuery } from './cms-hooks/use-cms-quest-detail';
import { isQuestCompleted } from './loyalty-quest/utils';
import { QuestCard } from './quest-card';
import { QuestDetailModal } from './quest-detail-modal';
import { QuestTerms } from './quest-drawer-content/quest-terms';
import type { IQuestCardWrapperProps } from './types';
import { useParseQuestCardData } from './use-parse-quest-card-data';
import { getProductRequirementCmsIds } from './utils';

export const QuestCardWrapper = ({
  cmsQuestCard,
  quest,
  cmsQuestCardUI,
}: IQuestCardWrapperProps) => {
  const { shouldQuestsModalBeVisible, setShouldQuestsModalBeVisible } = useLoyaltyContext();
  const { logQuestEvent } = useMParticleContext();
  const [showQuestTerms, setShowQuestTerms] = useState<boolean>(false);

  const questRequirementCmsIds = useMemo(() => getProductRequirementCmsIds(quest), [quest]);
  const [
    cmsQuestDetailQuery,
    {
      loadingCmsQuestDetail,
      cmsQuestDetail,
      refetch: refetchCmsQuestDetail,
      error: cmsQuestDetailError,
    },
  ] = useCmsQuestDetailLazyQuery({ cmsId: quest.cmsId, questRequirementCmsIds });
  const {
    image,
    imageDescription,
    title,
    description,
    buttonText,
    linkText,
    badgeText,
  } = useParseQuestCardData(quest, cmsQuestCard, cmsQuestCardUI, cmsQuestDetail);
  const { navigate } = useNavigation();

  const { pathname } = useRoute<{ pathname: string }>();
  const { formatMessage } = useIntl();
  const activateChallengeUrl = formatMessage({ id: 'routes.loyaltyActivateQuest' });
  const questDetailError = cmsQuestDetailError;
  const loadingQuestDetail = loadingCmsQuestDetail;

  const callToActionCard = useCallback(() => {
    logQuestEvent(
      {
        name: CustomEventNames.QUEST_DETAILS_VIEW,
        quest,
        cmsQuestCard,
      },
      { skipLoggingToBraze: true }
    );
    navigate(`${routes.loyaltyChallengeDetails}?previousPath=${pathname}`);
  }, [cmsQuestCard, logQuestEvent, navigate, pathname, quest]);

  const onPressLinkAndImageHandler = useCallback(() => {
    logQuestEvent(
      {
        name: CustomEventNames.QUEST_DETAILS_VIEW,
        quest,
        cmsQuestCard,
      },
      { skipLoggingToBraze: true }
    );

    navigate(`${routes.loyaltyChallengeDetails}?previousPath=${pathname}`);
    cmsQuestDetailQuery();
  }, [cmsQuestCard, cmsQuestDetailQuery, logQuestEvent, navigate, pathname, quest]);

  const onPressLinkHandler = useCallback(() => {
    setShouldQuestsModalBeVisible(true);
    logQuestEvent(
      {
        name: CustomEventNames.QUEST_DETAILS_VIEW,
        quest,
        cmsQuestCard,
      },
      { skipLoggingToBraze: true }
    );

    setShowQuestTerms(true);
  }, []);

  useEffect(() => {
    if (!loadingQuestDetail && pathname.includes(activateChallengeUrl) && quest.id) {
      // If quest exists open the drawer and activate challenge
      callToActionCard();
    }
  }, [activateChallengeUrl, callToActionCard, loadingQuestDetail, pathname, quest.id]);

  const onCloseDrawer = useCallback(() => {
    setShowQuestTerms(false);
  }, []);

  useEffect(() => {
    if (!cmsQuestDetail && !loadingCmsQuestDetail) {
      refetchCmsQuestDetail();
    }
  }, [cmsQuestDetail, loadingCmsQuestDetail, refetchCmsQuestDetail]);

  useEffect(() => {
    return () => {
      // Make sure we hide quest modal on unmount
      setShouldQuestsModalBeVisible(false);
      onCloseDrawer();
    };
  }, []);

  return (
    <>
      {!loadingCmsQuestDetail && (
        <Box alignItems="center" marginBottom="$1.5">
          <QuestCard
            image={image}
            altText={imageDescription}
            title={title}
            description={description}
            howToRedeem={cmsQuestDetail?.howToRedeem?.localeRaw}
            callToAction={callToActionCard}
            callToActionText={buttonText}
            linkText={linkText}
            badgeText={badgeText}
            onPressImage={onPressLinkAndImageHandler}
            onPressLink={onPressLinkHandler}
            isQuestCompleted={isQuestCompleted(quest.status)}
          />
        </Box>
      )}
      {shouldQuestsModalBeVisible && (
        <QuestDetailModal
          title={title}
          onDismiss={() => {
            setShouldQuestsModalBeVisible(false);
            onCloseDrawer();
          }}
          hideTitle={showQuestTerms || !!questDetailError}
        >
          {showQuestTerms && <QuestTerms cmsQuestTerms={cmsQuestCard.moreInfo} />}
        </QuestDetailModal>
      )}
    </>
  );
};
