import { Box, VStack } from '@rbilabs/universal-components';

import {
  OptInDialogBody as StyledOptInDialogBody,
  OptInDialogBodyText as StyledOptInDialogBodyText,
  OptInDialogContent as StyledOptInDialogContent,
  OptInDialogHeader as StyledOptInDialogHeader,
  OptInDialogHeading as StyledOptInDialogHeading,
} from './brand-styled/styled';

export * from './brand-styled/styled';

export const OptInDialogContent = StyledOptInDialogContent.withConfig({
  padding: 0,
});
export const OptInDialogBodyContent = VStack.withConfig({
  space: '$5',
  padding: '$4',
  paddingTop: 0,
});

export const ActionWrapper = Box.withConfig({
  alignItems: 'center',
  w: 'full',
});

export const OptInDialogHeader = StyledOptInDialogHeader.withConfig({
  alignItems: 'center',
});

export const OptInDialogHeading = StyledOptInDialogHeading.withConfig({
  variant: 'headerOne',
  margin: 0,
});
export const OptInDialogBody = StyledOptInDialogBody.withConfig({
  textAlign: 'center',
  padding: 0,
});
export const OptInDialogBodyText = StyledOptInDialogBodyText.withConfig({
  textAlign: 'center',
  fontSize: 'lg',
});
