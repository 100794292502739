import { usePropsResolution } from 'native-base';

import { addWithConfig } from '../../utils';
import { Box } from '../box';
import { HStack } from '../h-stack';
import { Header } from '../header';
import { Image } from '../image';
import { InlineAlert } from '../inline-alert';
import { Pressable } from '../pressable';
import { Text } from '../text';
import { VStack } from '../v-stack';

import type { VerticalCarouselItemProps } from './types';

export const VerticalCarouselItem = ({
  calories,
  cta,
  header,
  image,
  imageAlt,
  imageUri,
  onPress,
  price,
  rewardsBadge,
  unavailable,
  unavailableMessage,
  variant,
  disabled,
  ...props
}: VerticalCarouselItemProps) => {
  const resolvedProps = usePropsResolution('VerticalCarouselItem', { variant, ...props });
  const categoryVariant = variant === 'category';
  const itemFooter = () => {
    if (categoryVariant) {
      return null;
    }
    if (unavailable) {
      return (
        <InlineAlert
          borderBottomRadius="12"
          borderTopRadius="0"
          width={['150', '150', '182']}
          status="info"
          /* TODO: add localized version of message: "Not available at selected store" */
          message={unavailableMessage}
        />
      );
    }

    return (
      <VStack alignItems="center" px="$3" pb="$5">
        <HStack space="$2" mb="$2">
          {price && (
            <Text fontWeight={700} fontSize={[12, 14]}>
              {price}
            </Text>
          )}
          {calories && <Text fontSize={[12, 14]}>{calories}</Text>}
        </HStack>
        {rewardsBadge}
        {cta}
      </VStack>
    );
  };

  // In order to improve accessibility, the ‘disabled’ property is not being utilized on the button. Instead, the function is skipped
  const handleOnPress = () => {
    if (disabled || !onPress) {
      return;
    }
    onPress();
  };

  return (
    <Pressable
      {...resolvedProps}
      accessibilityRole="button"
      onPress={handleOnPress}
      flexGrow={1}
      mr="$4"
      _web={{
        // Given that the ‘disabled’ property is not utilized, we must explicitly define the cursor type to be displayed
        cursor: disabled ? 'not-allowed' : 'default',
      }}
    >
      {({ isHovered, isPressed, isFocused }) => {
        const pressed = ((isPressed && onPress && !unavailable) || isFocused) && !disabled;
        return (
          <Box
            rounded="12"
            justifyContent="space-between"
            flexGrow={1}
            bg={pressed ? 'token.background-focus' : 'transparent'}
          >
            <VStack px="$3" pt="$3">
              {image || (
                <Image
                  accessibilityIgnoresInvertColors
                  resizeMode="contain"
                  source={{
                    uri: imageUri,
                  }}
                  alt={imageAlt}
                  height="100"
                  width={['126', '126', '156']}
                />
              )}
              <Header
                variant="headerThree"
                mb={categoryVariant ? '$5' : '$2'}
                width={['126', '126', '156']}
                textAlign="center"
                color={isHovered ? 'token.text-active' : 'token.text-default'}
              >
                {header}
              </Header>
            </VStack>
            {itemFooter()}
          </Box>
        );
      }}
    </Pressable>
  );
};

export default addWithConfig(VerticalCarouselItem);
