import { createSelector } from '@reduxjs/toolkit';

import { LoyaltySecondaryIdentifierType } from 'generated/graphql-gateway';
import { RootState } from 'state/global-state';

// For more complex multiline selectors, export from this file
export const selectUser = ({ loyalty }: RootState) => loyalty.user.loyaltyUser;

export const selectLoyaltyLoading = ({ loyalty }: RootState) => loyalty.user.loyaltyLoading;

export const selectLoyaltyUser = createSelector(
  selectUser,
  selectLoyaltyLoading,
  (loyaltyUser, loyaltyLoading) => {
    return {
      loyaltyUser: loyaltyUser || null,
      loading: loyaltyLoading,
    };
  }
);

export const select12DigitSwipeCard = createSelector(
  ({ loyalty }: RootState) => loyalty.user?.loyaltyUser?.secondaryIdentifiers,
  secondaryIdentifiers =>
    secondaryIdentifiers?.find(
      identifier =>
        identifier?.identifierType === LoyaltySecondaryIdentifierType.LOYALTY12DIGITSWIPECARD
    )
);
