import { useMemo } from 'react';

import { useGetSortedQuestListUiQuery } from 'generated/sanity-graphql';

import { IUseCmsSortedQuestCardListQuery } from './types';
import { isCmsQuestCardList } from './utils';

export const useCmsSortedQuestCardListQuery: IUseCmsSortedQuestCardListQuery = () => {
  const { data, loading, refetch, error } = useGetSortedQuestListUiQuery({
    variables: {
      featureLoyaltyQuestsUIId: 'feature-loyalty-quests-ui-singleton',
    },
  });

  const { cmsQuests = null, cmsQuestCardUI = null } = useMemo(() => {
    if (!data?.LoyaltyQuestsUI) {
      return {};
    }

    const { sortedSystemwideQuests: sortedCmsQuests, ...cmsQuestCardUIData } = data.LoyaltyQuestsUI;

    return {
      cmsQuests: isCmsQuestCardList(sortedCmsQuests) ? sortedCmsQuests : null,
      cmsQuestCardUI: cmsQuestCardUIData,
    };
  }, [data]);

  return {
    loading,
    cmsQuests,
    cmsQuestCardUI,
    refetch,
    error,
  };
};
