import * as React from 'react';

import Svg, { Path } from 'react-native-svg';

import { primitive } from 'styles/constants/primitives';

const AppleIcon = () => (
  <Svg width={16} height={22} viewBox="0 0 16 22" fill="none">
    <Path
      d="M10.8889 4.0423C11.5672 3.15879 12.0275 1.9725 11.9061 0.760254C10.9131 0.81167 9.70143 1.44243 8.99991 2.32663C8.37002 3.08381 7.81252 4.31977 7.95786 5.4812C9.0725 5.58188 10.1861 4.90101 10.8889 4.0423Z"
      fill={primitive.bk.$bbqBrown}
    />
    <Path
      d="M11.8934 5.7079C10.2747 5.60749 8.89835 6.6646 8.12532 6.6646C7.35187 6.6646 6.16812 5.7585 4.8878 5.78292C3.22137 5.80841 1.67512 6.78958 0.829486 8.35011C-0.90985 11.4719 0.370476 16.1027 2.06189 18.6452C2.88328 19.9031 3.87319 21.2881 5.17755 21.2384C6.40995 21.188 6.893 20.4074 8.39104 20.4074C9.88798 20.4074 10.3231 21.2384 11.6277 21.2132C12.9805 21.188 13.8263 19.9547 14.6477 18.6956C15.59 17.2617 15.9757 15.8771 16 15.8011C15.9757 15.7759 13.3913 14.7435 13.3673 11.6476C13.3429 9.05537 15.3964 7.82236 15.493 7.7459C14.3334 5.95988 12.5215 5.7585 11.8934 5.7079Z"
      fill="black"
    />
  </Svg>
);
export default AppleIcon;
