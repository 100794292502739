import { Select as NbSelect } from 'native-base';

import { addWithConfig } from '../../utils';
import { Icon } from '../icon';

import type { _SelectComponentType } from './types';

const chevronDownIcon = <Icon color="black" variant="select" mr="4" />;
const selectedIcon = <Icon size={5} color="token.text-default" name="mi:check" />;

export const Select: _SelectComponentType = props => (
  <NbSelect
    dropdownIcon={chevronDownIcon}
    _selectedItem={{
      leftIcon: selectedIcon,
    }}
    {...props}
    // @TODO: Native-base's FlatList implementation is broken, so we have to use 'unoptimized' version
    // @ts-ignore optimized is a hidden prop, not part of type
    optimized={false}
  />
);
Select.Item = NbSelect.Item;

const SelectWithConfig = addWithConfig(Select);
SelectWithConfig.Item = addWithConfig(Select.Item);

export default SelectWithConfig;
