// @ts-expect-error TS(2307) FIXME: Cannot find module 'graphql/tsutils/Maybe' or its ... Remove this comment to see the full error message
import Maybe from 'graphql/tsutils/Maybe';

import { LaunchDarklyFlagsObject } from 'state/launchdarkly/flags';
import { relativeRoutes, routes } from 'utils/routing';

import { sanityAssets } from '../../sanityAssets';

import { ISanityNavigationTab, NavigationMobileTab } from './types';

export const checkFlagEnabled = (
  flags: LaunchDarklyFlagsObject,
  flagKey: Maybe<string>
): boolean => {
  return Boolean(flagKey && flags?.[flagKey]);
};

const alternateTabIsValid = (
  altMobileTab: ISanityNavigationTab['launchDarklyAlternateMobileTab']
) => {
  return Boolean(
    altMobileTab?.enabled &&
      altMobileTab?.iconPath &&
      altMobileTab?.iconActivePath &&
      altMobileTab?.link &&
      altMobileTab?.text
  );
};

interface IGenerateNavigationMobileTabs {
  LDFlags: LaunchDarklyFlagsObject;
  isLocationInRecentItemsRoute: boolean;
  language: string;
  sanityTabs: ISanityNavigationTab[];
  isDesktop?: boolean;
}

export const generateNavigationMobileTabs = ({
  LDFlags,
  isLocationInRecentItemsRoute = false,
  language,
  sanityTabs = [],
  isDesktop = false,
}: IGenerateNavigationMobileTabs): NavigationMobileTab[] => {
  const isRecentItemsLinkInSanityTabs = sanityTabs.some(tab =>
    [tab?.link?.[language], tab?.link?.en].includes(`${routes.menu}/${relativeRoutes.recentItems}`)
  );
  return sanityTabs.reduce<NavigationMobileTab[]>((tabs, sanityNavMobileTab) => {
    const hideNavLinkLdFlag = sanityNavMobileTab?.hideLinkBasedOnLdFlag?.launchDarklyFlag;
    const shouldHideNavLink =
      checkFlagEnabled(LDFlags, hideNavLinkLdFlag) ||
      (isDesktop && sanityNavMobileTab.hideOnDesktop);

    if (sanityNavMobileTab && !shouldHideNavLink) {
      const altSanityNavMobileTab = sanityNavMobileTab?.launchDarklyAlternateMobileTab;
      const LDFlagDependency = altSanityNavMobileTab?.launchDarklyFlagDependency?.launchDarklyFlag;
      const shouldShowAlternateLdNavLink =
        checkFlagEnabled(LDFlags, LDFlagDependency) && alternateTabIsValid(altSanityNavMobileTab);

      const alternateTabContents =
        shouldShowAlternateLdNavLink && altSanityNavMobileTab
          ? altSanityNavMobileTab
          : sanityNavMobileTab;

      const tabLabel = alternateTabContents.text?.[language] || alternateTabContents.text?.en || '';
      const tabLink =
        alternateTabContents?.link?.[language] || alternateTabContents?.link?.en || '';
      const forceExactMatch =
        tabLink === routes.menu && isLocationInRecentItemsRoute && isRecentItemsLinkInSanityTabs;

      const iconImageSource = sanityAssets.current[alternateTabContents?.iconPath];
      const iconActiveImageSource = sanityAssets.current[alternateTabContents?.iconActivePath];

      tabs.push({
        ...alternateTabContents,
        label: tabLabel,
        iconImageSource,
        iconActiveImageSource,
        selectedHrefs: [tabLink],
        forceExactMatch,
      });
    }
    return tabs;
  }, []);
};
