import React from 'react';

import ActionButton, { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import {
  HorizontalPosition,
  TypographyBlock,
} from 'components/features/components/typography-block';

import { ActionButtonsContainer, StyledPicture, TextContentContainer } from './styled';
import { IImageWithHeaderAndTextProps } from './types';

export const ImageWithHeaderAndText = ({
  image,
  imageDescription,
  textContent,
  buttonText = '',
  buttonUrl = '',
  secondaryButtonText = '',
  secondaryButtonUrl = '',
  onButtonPress,
  loading = false,
  pictureStyles,
}: IImageWithHeaderAndTextProps) => {
  const showButton = !!buttonText && !!buttonUrl;
  const showSecondaryButton = !!secondaryButtonText && !!secondaryButtonUrl;

  return (
    <>
      <StyledPicture alt={imageDescription} image={image} {...pictureStyles} />
      <TextContentContainer>
        <TypographyBlock content={textContent} horizontalPosition={HorizontalPosition.CENTER} />
      </TextContentContainer>
      <ActionButtonsContainer>
        {showButton && (
          <ActionButton
            onPress={() => onButtonPress(buttonUrl)}
            variant={ActionButtonVariants.PRIMARY}
            size={ActionButtonSizes.LARGE}
            fullWidth
            isLoading={loading}
          >
            {buttonText}
          </ActionButton>
        )}
        {showSecondaryButton && (
          <ActionButton
            onPress={() => onButtonPress(secondaryButtonUrl)}
            variant={ActionButtonVariants.TEXT_ONLY}
            size={ActionButtonSizes.LARGE}
            fullWidth
            isLoading={loading}
          >
            {secondaryButtonText}
          </ActionButton>
        )}
      </ActionButtonsContainer>
    </>
  );
};
