import { RetryConfig } from 'utils/network/utils';

const normalizeString = (str: string = '') => str.trim().toLowerCase();

export default normalizeString;

export const getCognitoRetryConfig = (operationName: string): RetryConfig => ({
  operationName,
  canRetry: error => error?.code === 'NetworkError',
  typeName: 'Cognito',
});
