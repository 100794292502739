import { maybeLocaleImages } from 'utils/graphql';
import { blockContentToPlainText } from 'utils/sanity';

import type { ICmsOffer, IEngineOffer, ILoyaltyOffer } from './types';
import { getOfferServiceMode } from './utils';

/**
 * Consolidate cms and the engine data to create a new entity.
 */
export const createLoyaltyOffer = (
  cmsOffer: ICmsOffer,
  engineOffer: IEngineOffer
): ILoyaltyOffer => {
  const serviceMode = getOfferServiceMode(engineOffer);

  return {
    ...cmsOffer,
    // parsed values
    loyaltyEngineId: cmsOffer.loyaltyEngineId || '',
    cmsId: cmsOffer._id,
    name: blockContentToPlainText(cmsOffer.name?.localeRaw),
    description: cmsOffer.description?.localeRaw ?? null,
    image: maybeLocaleImages(cmsOffer?.localizedImage),
    imageDescription: '',
    moreInfo: cmsOffer?.moreInfo?.localeRaw,
    // calculated data
    serviceMode,
    // engine data
    isStackable: engineOffer.isStackable || false,
    metadata: engineOffer.metadata,
    errors: engineOffer.errors,
  };
};
