import { useWindowDimensions } from 'react-native';

import { MarketingTileList } from 'components/features/components/marketing-tile-group/marketing-tile-list';
import { IEmptyStateFragment } from 'generated/sanity-graphql';
import { useIsMobileBreakpoint } from 'hooks/use-media-query';

import {
  Container,
  DescriptionTextBlock,
  HeadingText,
  ImageContainer,
  StyledLoyaltyLogo,
  SubtitleText,
  Terms,
  TitleText,
} from './styled';

export const EmptyStateContent: React.FC<{
  content: IEmptyStateFragment;
  shouldRenderSignUpButton?: boolean;
}> = ({ content, shouldRenderSignUpButton = false }) => {
  const { image, headerText, title, subtitle, description, marketingTileList, terms } = content;
  const mainImage = image?.locale?.app;
  const header = headerText?.locale;
  const titleText = title?.locale;
  const subtitleText = subtitle?.locale;
  const termsText = terms?.locale;
  const descriptionContent = description?.locale;
  const tiles = marketingTileList?.tiles || [];
  const { height: $windowHeight } = useWindowDimensions();
  const isMobile = useIsMobileBreakpoint();

  return (
    <Container showsVerticalScrollIndicator={false} hasSignUpButton={shouldRenderSignUpButton}>
      <StyledLoyaltyLogo
        pictureSize={{
          height: isMobile ? '$16' : '$32',
          width: '4/5',
        }}
      />
      {header && <HeadingText>{header}</HeadingText>}
      {mainImage && (
        <ImageContainer
          image={mainImage}
          alt={image?.locale?.imageDescription || 'Empty Image'}
          objectFitContain
          $windowHeight={$windowHeight}
        />
      )}
      {titleText && <TitleText>{titleText}</TitleText>}
      {subtitleText && <SubtitleText>{subtitleText}</SubtitleText>}
      {descriptionContent && <DescriptionTextBlock content={descriptionContent} />}
      <MarketingTileList tiles={tiles} />

      {termsText && <Terms content={termsText} />}
    </Container>
  );
};
