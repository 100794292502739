import React from 'react';

import { useWindowDimensions } from 'react-native';
import { TabView as RNTabView } from 'react-native-tab-view';

import { TabBar } from './tab-bar';
import { ITabViewProps } from './types';

const TabView: React.FC<ITabViewProps> = ({
  activeTabIndex,
  handleTabIndexChange,
  renderScene,
  tabs,
  keyboardDismissMode,
  color = 'primary',
  backgroundColor,
  animationEnabled = true,
  tabBarTextProps,
}) => {
  const layout = useWindowDimensions();

  return (
    <RNTabView
      animationEnabled={animationEnabled}
      swipeEnabled={tabs.every(tab => !tab.disabled)}
      navigationState={{ index: activeTabIndex, routes: tabs }}
      renderScene={renderScene}
      renderTabBar={tabBarProps => (
        <TabBar
          {...tabBarProps}
          color={color}
          backgroundColor={backgroundColor}
          tabBarTextProps={tabBarTextProps}
        />
      )}
      onIndexChange={handleTabIndexChange}
      initialLayout={{ width: layout.width }}
      keyboardDismissMode={keyboardDismissMode}
      sceneContainerStyle={{
        overflow: 'visible',
      }}
    />
  );
};

export default TabView;
