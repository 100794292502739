import { RootState } from 'state/global-state';

import { getAppliedPointsInCart } from './rewards.utils';

export const selectAppliedLoyaltyRewards = ({ loyalty }: RootState) => {
  return loyalty.rewards.appliedLoyaltyRewards;
};

export const selectAppliedLoyaltyRewardsArray = ({ loyalty }: RootState) => {
  return loyalty.rewards.appliedLoyaltyRewardsArray;
};

export const selectAvailableLoyaltyRewardsMap = ({ loyalty }: RootState) => {
  return loyalty.rewards.availableLoyaltyRewardsMap;
};

export const selectIsPricingRewardApplication = ({ loyalty }: RootState) => {
  return loyalty.rewards.isPricingRewardApplication;
};

export const selectStagedCartPoints = ({ loyalty }: RootState) => {
  return loyalty.rewards.stagedCartPoints;
};

export const selectTotalTimesRewardApplied = ({ loyalty }: RootState) => {
  return loyalty.rewards.totalTimesRewardApplied;
};

export const selectShouldRefetchRewards = ({ loyalty }: RootState) => {
  return loyalty.rewards.shouldRefetchRewards;
};

export const selectAppliedPoints = ({ loyalty }: RootState) =>
  getAppliedPointsInCart(loyalty.rewards);
