import { Box } from '@rbilabs/universal-components';

export const ContentContainer = Box.withConfig({
  display: 'flex',
  height: 'full',
});

export const NavigationContainer = Box.withConfig({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
});

export const Stepper = Box.withConfig({
  marginRight: '$6',
});
