import {
  createNativeBaseThemeComponents,
  createNativeBaseThemeTokens,
} from '../../utils/native-base';
import { ComponentVariants, ForcedNativeBaseThemeType } from '../../utils/native-base/types';
import { sharedNativeBaseThemeComponents } from '../shared';
import { SharedVariants } from '../shared/native-base-theme';

import { bkPrimitives } from './primitives';

const {
  breakpoints,
  space,
  sizes,
  shadows,
  opacity,
  letterSpacings,
  lineHeights,
  fontConfig,
  fontWeights,
  fontSizes,
  borderWidths,
  fonts,
  radii,
  colors,
} = bkPrimitives;

const tokens = createNativeBaseThemeTokens({
  breakpoints,
  space,
  sizes,
  shadows,
  opacity,
  letterSpacings,
  lineHeights,
  fontConfig,
  fontWeights,
  fontSizes,
  borderWidths,
  fonts,
  radii,
  colors,
});

type Variants = SharedVariants &
  ComponentVariants<{
    BrandLogo: {
      variants: {
        BkLogoPrimary: {};
        BkLogoRoyalPerks: {};
        BkLogoRoyalPerksFR: {};
        BkLogoSecondary: {};
        primary: {};
      };
    };
    IconButton: {
      variants: {
        ghost: {};
        input: {};
        outline: {};
        solid: {};
      };
    };
    Text: {
      variants: {
        copyOne: {};
        copyTwo: {};
        formUtility: {};
        headerFour: {};
        headerOne: {};
        headerThree: {};
        headerTwo: {};
        hero: {};
      };
    };
  }>;

const components = createNativeBaseThemeComponents(sharedNativeBaseThemeComponents, {
  Button: {
    defaultProps: {
      _text: {
        fontWeight: 400,
      },
    },
  },
  BrandLogo: {
    variants: {
      primary: {
        // @ts-ignore variant is not defined in a variant.
        variant: 'BkLogoPrimary',
      },
      BkLogoPrimary: {
        // @ts-ignore 56 not in primitives.
        height: '56px',
      },
      BkLogoRoyalPerks: {
        height: '16',
      },
      BkLogoRoyalPerksFR: {
        height: '16',
      },
      BkLogoSecondary: {
        height: '4',
      },
    },
  },
  Icon: {
    variants: {
      rewards: { name: 'custom:BkCrown' },
    },
  },
  Text: {
    baseStyle: {
      // @ts-ignore NB is not typing this correctly
      color: 'token.text-default',
      fontWeight: 400,
    },
    variants: {
      hero: {
        accessibilityRole: 'header',
        fontFamily: 'heading',
        fontSize: [32, 32, 32, 56],
        lineHeight: [36, 36, 36, 56],
        mb: 3,
      },
      headerOne: {
        accessibilityRole: 'header',
        fontFamily: 'heading',
        fontSize: [24, 24, 24, 30],
        lineHeight: [24, 24, 24, 32],
        mb: 3,
      },
      headerTwo: {
        accessibilityRole: 'header',
        // @ts-ignore NB is not typing this correctly
        accessibilityLevel: 2,
        fontFamily: 'heading',
        fontSize: [18, 18, 18, 24],
        lineHeight: [20, 20, 20, 28],
        mb: 3,
      },
      headerThree: {
        accessibilityRole: 'header',
        // @ts-ignore NB is not typing this correctly
        accessibilityLevel: 3,
        fontFamily: 'heading',
        fontSize: [16, 16, 16, 18],
        lineHeight: 20,
        mb: 3,
      },
      headerFour: {
        accessibilityRole: 'header',
        // @ts-ignore NB is not typing this correctly
        accessibilityLevel: 4,
        fontFamily: 'heading',
        fontSize: 13,
        lineHeight: 16,
        mb: 3,
      },
      copyOne: {
        fontFamily: 'body',
        fontSize: [15, 15, 15, 16],
        lineHeight: [20, 20, 20, 24],
      },
      copyTwo: {
        fontFamily: 'body',
        fontSize: [12, 12, 12, 13],
        lineHeight: [16, 16, 16, 20],
      },
      bodyOne: {
        fontFamily: 'body',
        fontSize: [13, 13, 13, 14],
        lineHeight: [16, 16, 16, 16],
      },
      formUtility: {
        fontFamily: 'body',
        fontSize: 11,
        lineHeight: 12,
      },
    },
  },
});

export const bkNativeBaseTheme = ({
  ...tokens,
  config: { initialColorMode: 'light' },
  components,
} as unknown) as ForcedNativeBaseThemeType<typeof tokens, Variants>;
