import logger from 'utils/logger';

import { Keys } from './constants';

/**
 * Function to set a value in local storage with expiration in minutes
 *
 * @param key name of the key to be stored in local storage
 * @param value data to be persisted
 * @param exp minutes this value will expire after being set
 */
export const setItemWithExpirationTime = <V = any>(
  key: Keys,
  value: V,
  exp: number,
  setItem: (key: Keys, value: V) => any
) => {
  const now = new Date();
  // Convert minutes to milliseconds
  const expirationTime = now.getTime() + exp * 60 * 1000;

  const data = {
    value,
    expirationTime,
  };

  setItem(key, JSON.stringify(data) as V);
};

/**
 * Function to get the value from local storage and check if it's expired
 *
 * @param key name of the key stored in Local Storage
 * @returns value stored
 */
export const getItemWithExpirationTime = <V = any>(
  key: Keys,
  getItem: (key: Keys) => V | null,
  removeItem: (key: Keys) => void
): V | null => {
  const storedItem = getItem(key);
  let data;
  if (storedItem && typeof storedItem === 'string') {
    try {
      data = JSON.parse(storedItem);
    } catch (error) {
      logger.error({
        error,
        message: `Error parsing local storage item with expiration time with key: ${key}`,
      });
      return null;
    }

    // Check if the item has expired
    const now = new Date().getTime();
    if (now < data?.expirationTime) {
      // Item is still valid, return the value
      return data.value;
    }
    // Item has expired, remove it from local storage
    removeItem(key);
  }
  // Key not found or item expired
  return null;
};
