import { IntlShape } from 'react-intl';

import { LoyaltyQuestIncentiveType, LoyaltyQuestStatus } from 'generated/graphql-gateway';

import type { ICmsQuestCardUI, ICmsQuestDetail } from '../cms-hooks/types';
import { IQuest } from '../loyalty-quest/types';

import { formatQuestDates } from './date-formatter';

export const getButtonText = ({
  questStatus,
  incentiveRedeemed,
  cmsQuestCardUI,
}: {
  questStatus: LoyaltyQuestStatus | undefined;
  incentiveRedeemed: boolean;
  cmsQuestCardUI: ICmsQuestCardUI | null | undefined;
}): string => {
  let buttonText: string = '';

  switch (questStatus) {
    case LoyaltyQuestStatus.NOT_ENROLLED: {
      buttonText = cmsQuestCardUI?.notEnrolledButton?.locale ?? '';
      break;
    }
    case LoyaltyQuestStatus.ACTIVATED: {
      buttonText = cmsQuestCardUI?.enrolledButton?.locale ?? '';
      break;
    }
    case LoyaltyQuestStatus.COMPLETED: {
      if (!incentiveRedeemed) {
        buttonText = cmsQuestCardUI?.completedButton?.locale ?? '';
      }
      break;
    }
    default: {
      break;
    }
  }

  return buttonText;
};

export const getRangeDateDescription = ({
  startDate,
  endDate,
  formatMessage,
}: {
  startDate: string;
  endDate: string;
  formatMessage: IntlShape['formatMessage'];
}): string => {
  const [formattedStartDate, formattedEndDate] = formatQuestDates(startDate, endDate);
  const description = `${formatMessage({ id: 'from' })} ${formattedStartDate} ${formatMessage({
    id: 'to',
  })} ${formattedEndDate}`;

  return description;
};

export const getRedeemedDescription = ({
  incentiveType,
  cmsQuestDetail,
}: {
  incentiveType: LoyaltyQuestIncentiveType | undefined;
  cmsQuestDetail: ICmsQuestDetail | null;
}): string => {
  return (
    (incentiveType === LoyaltyQuestIncentiveType.BONUS_POINTS
      ? cmsQuestDetail?.completedAndRedeemedBonusPointChallengeDescription?.localeRaw
      : cmsQuestDetail?.completedAndRedeemedDescription?.localeRaw) || ''
  );
};

export const formatCompletedChallengeMessage = ({
  description,
  quest,
}: {
  description: string | null | undefined;
  quest: IQuest | undefined;
}): string =>
  description?.toString().replace('{points}', quest?.bonusPoints?.toString() || '0') || '';
