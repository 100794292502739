import { primitive } from 'styles/constants/primitives';

import { Svg, SvgPath, SvgProps, registerSVG } from '../../../components/svg';
export function SodiumFlag(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 22 23" fill="none">
      <SvgPath
        d="M11.0308 20.1262C8.29438 20.1262 5.55795 20.1075 2.82195 20.1382C2.06891 20.1467 1.62247 19.4232 2.02171 18.7287C4.77359 13.9408 7.50649 9.1423 10.2456 4.34732C10.6439 3.65011 11.4226 3.64433 11.8183 4.33664C14.5648 9.14452 17.3114 13.9524 20.0571 18.7607C20.373 19.3138 20.167 19.9318 19.6102 20.0911C19.4929 20.1244 19.3641 20.1249 19.2401 20.1249C16.5037 20.1267 13.7668 20.1262 11.0308 20.1262ZM9.13036 10.433C9.1083 10.4566 9.09374 10.4655 9.08933 10.478C9.05492 10.583 9.01963 10.6875 8.99007 10.7939C8.70553 11.8185 8.40158 12.8485 8.40732 13.9186C8.41437 15.2027 8.50481 16.4858 8.56745 17.769C8.57142 17.8469 8.63186 17.9483 8.69671 17.9919C9.04433 18.2246 9.39857 18.4475 9.75723 18.6624C9.83928 18.7118 9.94736 18.7385 10.0435 18.7394C10.703 18.7461 11.363 18.7465 12.023 18.7394C12.1257 18.7381 12.24 18.706 12.3282 18.6535C12.675 18.4471 13.0182 18.2339 13.3517 18.0066C13.4267 17.9554 13.4959 17.842 13.503 17.7521C13.5533 17.1043 13.6014 16.4556 13.6221 15.8064C13.6459 15.0616 13.6799 14.3132 13.6398 13.5707C13.5842 12.5411 13.2952 11.5516 13.0125 10.5652C12.9992 10.5194 12.9697 10.4784 12.9454 10.4304C11.663 10.8664 10.3907 10.8642 9.12947 10.4335L9.13036 10.433ZM12.9136 9.39724C12.8523 9.09603 12.6631 8.89981 12.4195 8.77079C11.5073 8.28804 10.5826 8.28848 9.66723 8.761C9.41666 8.89047 9.21682 9.08802 9.15815 9.38834C9.94339 9.75674 11.97 9.76431 12.9136 9.39724ZM9.15241 9.73094C9.15241 9.83372 9.14271 9.96631 9.15771 10.0962C9.163 10.1434 9.22035 10.2079 9.26711 10.223C10.4454 10.5994 11.6259 10.5959 12.8051 10.2244C12.8501 10.2101 12.9123 10.159 12.9163 10.1198C12.9295 9.97832 12.9216 9.83461 12.9216 9.73049C12.2718 9.78967 11.6555 9.88978 11.0388 9.88933C10.4159 9.88933 9.79252 9.78922 9.15241 9.73094Z"
        fill={primitive.bk.$bbqBrown}
      />
      <SvgPath
        d="M12.3759 18.0045C12.43 15.8639 12.814 13.7238 12.1354 11.6249C11.9342 11.6089 11.9228 11.6969 11.9598 11.8559C12.1328 12.5953 12.3111 13.3378 12.2869 14.1035C12.2473 15.3487 12.1821 16.5931 12.1284 17.8379C12.1262 17.8868 12.1359 17.9361 12.1399 17.9863C12.1812 17.9943 12.209 18.0018 12.2376 18.0045C12.2662 18.0072 12.2957 18.0049 12.3754 18.0049L12.3759 18.0045ZM9.95185 11.6147C9.27638 13.73 9.65066 15.8661 9.71231 18.0151C9.82151 18.0014 9.88888 17.9929 9.95493 17.9845C9.91134 17.1154 9.87743 16.2694 9.82459 15.4242C9.7559 14.3233 9.78188 13.2308 10.0522 12.1547C10.0928 11.994 10.1245 11.8306 10.1628 11.6556C10.0756 11.6387 10.0135 11.6267 9.95185 11.6152V11.6147Z"
        fill={primitive.bk.$bbqBrown}
      />
      <SvgPath
        d="M10.7568 9.22514C10.9726 9.0515 11.1901 9.05683 11.3535 9.22914C11.1782 9.40545 10.9739 9.40811 10.7568 9.22514Z"
        fill={primitive.bk.$bbqBrown}
      />
      <SvgPath
        d="M10.9447 8.82662C10.7365 9.00515 10.5211 9.00071 10.3564 8.82662C10.5198 8.65564 10.7426 8.65209 10.9447 8.82662Z"
        fill={primitive.bk.$bbqBrown}
      />
      <SvgPath
        d="M11.7718 8.82331C11.6088 9.00317 11.3772 9.00583 11.1958 8.82997C11.3622 8.65188 11.5939 8.64922 11.7718 8.82331Z"
        fill={primitive.bk.$bbqBrown}
      />
      <SvgPath
        d="M9.8374 9.13561C9.98491 8.96374 10.2148 8.95486 10.4134 9.1143C10.2835 9.29904 10.0193 9.30881 9.8374 9.13561Z"
        fill={primitive.bk.$bbqBrown}
      />
      <SvgPath
        d="M12.2794 9.12837C12.0839 9.30246 11.8545 9.30068 11.6907 9.13459C11.8382 8.96538 12.0716 8.95872 12.2794 9.12837Z"
        fill={primitive.bk.$bbqBrown}
      />
      <SvgPath
        d="M10.993 21.5942C8.06489 21.5942 5.13708 21.5951 2.20897 21.5939C1.10942 21.5933 0.264081 20.9227 0.0417495 19.8651C-0.0674973 19.3456 0.0429307 18.8558 0.311323 18.3987C0.803524 17.5611 1.29572 16.7234 1.78763 15.8858C2.79565 14.1681 3.80279 12.4498 4.81111 10.7324C5.93044 8.82637 7.05067 6.92096 8.1703 5.01496C8.49007 4.47048 8.80422 3.9228 9.1299 3.38151C9.54946 2.68448 10.1748 2.3091 10.9998 2.30939C11.8177 2.30968 12.4504 2.67228 12.8608 3.36872C14.5701 6.26897 16.272 9.17357 17.9762 12.0764C19.1419 14.062 20.3061 16.0482 21.4733 18.0329C21.6853 18.3932 21.9014 18.7462 21.9735 19.1699C22.1619 20.2786 21.3239 21.4137 20.1883 21.566C20.0525 21.5843 19.914 21.5927 19.777 21.5927C16.8489 21.5945 13.9211 21.5942 10.993 21.5942ZM10.975 20.7554C13.9075 20.7554 16.8401 20.7565 19.7723 20.7522C19.9418 20.7519 20.1189 20.7313 20.279 20.6792C21.0009 20.4439 21.4013 19.5662 20.9327 18.7796C19.7891 16.86 18.6663 14.9284 17.5351 13.0018C16.1432 10.631 14.7514 8.26009 13.3595 5.88922C12.9488 5.18958 12.5443 4.48646 12.1247 3.79205C11.6057 2.9329 10.3898 2.9236 9.88636 3.77375C9.12399 5.06116 8.36901 6.35264 7.61137 7.64268C6.33997 9.80842 5.06917 11.9744 3.79807 14.1402C2.88098 15.7028 1.96331 17.2647 1.04534 18.827C0.906275 19.0638 0.832165 19.3174 0.864644 19.5909C0.947907 20.2972 1.4779 20.7548 2.20749 20.7548C5.12969 20.7554 8.05219 20.7554 10.975 20.7554Z"
        fill={primitive.bk.$bbqBrown}
      />
    </Svg>
  );
}

registerSVG('SodiumFlag', SodiumFlag);
