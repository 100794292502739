import { useCallback, useState } from 'react';

import { useToast } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';
import { GestureResponderEvent } from 'react-native';

import { LogUserErrorSeverity, logUserErrorMessage } from 'hooks/log-user-error-message';
import { HttpErrorCodes } from 'remote/constants';
import { useAuthContext } from 'state/auth';
import { getStoredOtpCredentials } from 'state/auth/hooks/use-account-authentication';

interface IUseSendNewOtpProps {
  setShowSendNewOtpForm: (showSendNewOtpForm: boolean) => void;
  setResendOrSendNewOtpError: (formError: string | null) => void;
  setOtpValidationError: (formError: string | null) => void;
  setOtpAuthError(error: null | string): void;
  setShowHasSentNewOtpMessage: (showHasSentNewOtpMessage: boolean) => void;
}

export const useSendNewOtp = ({
  setResendOrSendNewOtpError,
  setOtpValidationError,
  setOtpAuthError,
  setShowHasSentNewOtpMessage,
  setShowSendNewOtpForm,
}: IUseSendNewOtpProps) => {
  // use this `loadingState` rather than `loading` from `seAuthContext` so that I can fake the async request within storybook easily
  const [isLoading, setIsLoading] = useState(false);

  const { formatMessage } = useIntl();
  const { signIn } = useAuthContext();
  const { email, phoneNumber } = getStoredOtpCredentials() || {};
  const toast = useToast();

  const sendNewOtp = useCallback(
    async (ev: GestureResponderEvent) => {
      ev.preventDefault();
      setShowHasSentNewOtpMessage(false);
      setIsLoading(true);
      setOtpValidationError(null);
      setOtpAuthError(null);

      try {
        await signIn({
          email,
          phoneNumber,
          navigateOnSuccess: false,
        });

        setShowHasSentNewOtpMessage(true);
        setShowSendNewOtpForm(false);
        toast.show({
          text: formatMessage({ id: 'resendCodeSuccess' }),
          variant: 'positive',
        });
      } catch (error) {
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        switch (error?.graphQLErrors?.[0]?.extensions?.statusCode) {
          case HttpErrorCodes.TooManyRequests:
            setResendOrSendNewOtpError(
              logUserErrorMessage({
                message: `${formatMessage({ id: 'maxAttemptsReached' })} ${formatMessage({
                  id: 'pleaseTryAgainLater',
                })}`,
                severity: LogUserErrorSeverity.Irrecoverable,
              })
            );
            break;
          default:
            setResendOrSendNewOtpError(
              logUserErrorMessage({
                message: formatMessage({ id: 'authError' }),
                severity: LogUserErrorSeverity.Recoverable,
              })
            );
            break;
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      setShowHasSentNewOtpMessage,
      setOtpValidationError,
      setOtpAuthError,
      signIn,
      email,
      phoneNumber,
      setShowSendNewOtpForm,
      setResendOrSendNewOtpError,
      formatMessage,
    ]
  );

  return {
    sendNewOtp,
    isLoading,
  };
};
