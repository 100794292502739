import { ISignupErrors } from 'utils/form/types';

export const prepareErrorKeys = (errorsObj: ISignupErrors): string[] => {
  const accumulatorArray: string[] = [];

  // filter out obsolete form errors (whose value is undefined)
  // to avoid showing them in the accessibility errors alert
  const errorsArr = Object.entries(errorsObj).reduce((acc, [key, val]) => {
    if (val) {
      acc.push(key);
    }
    return acc;
  }, accumulatorArray);

  return errorsArr;
};
