import { IconButton } from '@rbilabs/universal-components';

export const Button = IconButton.withConfig({
  width: '$12',
  height: '$12',
  borderRadius: 'full',
  backgroundColor: 'white',
  alignItems: 'center',
  justifyContent: 'center',
});
