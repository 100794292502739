import { IQuestFragment } from 'generated/graphql-gateway';

import { IQuest, IQuestStep, QuestCmsType, QuestError, QuestValidationError } from './types';

export const validateQuest = (quest: IQuest): QuestError[] => {
  const errors: QuestError[] = [];

  if (!quest?.cmsId) {
    errors.push({
      error: QuestValidationError.INVALID_CMS_DOCUMENT,
      message: `No cms document [${quest.id}]`,
    });
  }

  return errors;
};

export const createQuest = ({
  id,
  type,
  incentiveType,
  endDate,
  startDate,
  sanityId,
  externalIdentifiers,
  status,
  steps,
  bonusPoints,
  lastCompletion,
}: IQuestFragment): IQuest => ({
  cmsId: sanityId || '',
  cmsType: (externalIdentifiers?.find(e => e?.value === sanityId)?.type as QuestCmsType) || '',
  type,
  startDate,
  endDate,
  id,
  incentiveType,
  lastCompletion: lastCompletion || null,
  status,
  steps: (steps as IQuestStep[]) || null,
  bonusPoints,
});
