import { useEffect, useState } from 'react';

import { useConfigValue } from 'hooks/configs/use-config-value';
import { withForterHeaders } from 'state/graphql/links/with-forter-headers';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

export const useForterScriptTag = () => {
  const enableForterFraudTracking = useFlag(LaunchDarklyFlag.ENABLE_FORTER_FRAUD);
  const [scriptHasMounted, setScriptHasMounted] = useState(false);
  const siteId = useConfigValue({ key: 'forter', defaultValue: {} }).siteId;

  useEffect(() => {
    if (enableForterFraudTracking && !scriptHasMounted) {
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      document.addEventListener('ftr:tokenReady', (event: CustomEvent) => {
        const token = event.detail;
        withForterHeaders.setForterToken(token);
      });
      const script = document.createElement('script');
      script.async = true;
      script.crossOrigin = 'anonymous';
      script.id = siteId;
      script.src = '/forter.js';
      document.body.appendChild(script);
      setScriptHasMounted(true);
    }
  }, [enableForterFraudTracking, scriptHasMounted, siteId]);
};
