import { Box, Header as UCLHeader, ZStack } from '@rbilabs/universal-components';

import Picture from 'components/picture';

import { headerWidgetTheme } from './theme';

export const StyledHeroHeading = UCLHeader.withConfig({
  variant: 'headerOne',
  paddingTop: '$5',
  paddingBottom: '$2',
  paddingX: '$6',
  textAlign: 'center',
  color: Styles.color.white,
  _web: {
    fontSize: {
      base: '5xl',
      md: '8xl',
    },
    lineHeight: {
      base: '2xs',
      md: '8xl',
    },
    paddingY: {
      base: '$5',
      md: '$12',
    },
  },
});

export const HeadingContainer = Box.withConfig({
  // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'Responsiv... Remove this comment to see the full error message
  flexDirection: headerWidgetTheme.headerFlexDirection,
});

export const Heading = UCLHeader.withConfig({
  variant: 'headerOne',
  letterSpacing: '-2px',
  fontWeight: 'black',
  lineHeight: '2xs',
  color: headerWidgetTheme.textColor,
  textAlign: 'center',
  margin: '$0',
  padding: '$0',
  fontSize: '5xl',
});

export const Tagline = UCLHeader.withConfig({
  variant: 'headerTwo',
  position: 'relative',
  fontWeight: 'normal',
  color: headerWidgetTheme.textColor,
  textAlign: 'center',
  margin: '$0',
  padding: '$0',
  fontSize: '3xl',
  lineHeight: 'xl',
});

export const Container = ZStack.withConfig({
  height: '440px',
});

export const Top = Box.withConfig({
  background: headerWidgetTheme.background,
  width: 'full',
  paddingTop: '$8',
  paddingBottom: '$32',
});

export const Bottom = Box.withConfig({
  width: 'full',
  marginTop: '$48',
});

export const ImageContainer = Box.withConfig({
  alignItems: 'center',
});

export const Image = Picture.withConfig({
  borderRadius: 'md',
  width: '90%',
});
