import React from 'react';

import { makeUclComponent } from '@rbilabs/universal-components';
import { Animated } from 'react-native';

import { IRotateAnimatedViewProps } from './types';
import useRotateAnimation from './use-rotate-animation';

function RotateAnimatedView({
  isRotated = false,
  children,
  fromDeg = 0,
  toDeg = 180,
  ...props
}: IRotateAnimatedViewProps) {
  const { rotateData } = useRotateAnimation({
    isRotated,
    fromDeg,
    toDeg,
  });

  return (
    <Animated.View
      {...props}
      style={[
        {
          transform: [{ rotate: rotateData }],
        },
      ]}
    >
      {children}
    </Animated.View>
  );
}

export default makeUclComponent(RotateAnimatedView);
