import { useCallback, useEffect } from 'react';

import { ApolloError } from '@apollo/client';

import { LoyaltyQuestType } from 'generated/graphql-gateway';
import {
  ILoyaltyQuestsUiCardFragment,
  useGetLoyaltyQuestConfigUiLazyQuery,
} from 'generated/sanity-graphql';
import { useLoyaltyContext } from 'state/loyalty';

import { useCmsSortedQuestCardListQuery } from '../cms-hooks/use-cms-sorted-quest-card-list';
import { QuestCmsType } from '../loyalty-quest/types';
import { CardListItem } from '../types';

type UseUserQuestResult = {
  isLoading: boolean;
  error: ApolloError | undefined;
  refetch: () => void;
  userQuest: CardListItem | null | undefined;
  cmsQuestCardUI: ILoyaltyQuestsUiCardFragment | null;
};

export function useUserQuest(): UseUserQuestResult {
  // Fetch the Quest stored in our Loyalty service for the current User
  const { userQuest, refetchQuests } = useLoyaltyContext();

  // Fetch UI info common for all quests and specific UI info for a GLOBAL quest
  // or a PERSONALIZED quest that's based on a GLOBAL quest
  const {
    loading: loadingQuestCardList,
    refetch: refetchQuestCardList,
    cmsQuestCardUI,
    cmsQuests: globalQuestsUI,
    error: errorQuestCardList,
  } = useCmsSortedQuestCardListQuery();

  // Fetch UI info for a PERSONALIZED quest that's based on a CONFIG quest
  const [
    getConfigQuestUI,
    {
      loading: loadingConfigQuestUI,
      error: errorConfigQuestUI,
      data: configQuestUI,
      refetch: refetchConfigQuestUI,
    },
  ] = useGetLoyaltyQuestConfigUiLazyQuery();

  let questCard: CardListItem | null | undefined;

  // If we have a GLOBAL quest or a PERSONALIZED quest that's based on a GLOBAL
  // quest, we need to find the UI info for it
  const questIsGlobalOrBasedOnGlobal =
    userQuest?.type === LoyaltyQuestType.GLOBAL ||
    (userQuest?.type === LoyaltyQuestType.PERSONALIZED &&
      userQuest?.cmsType === QuestCmsType.SYSTEMWIDE);

  if (questIsGlobalOrBasedOnGlobal && globalQuestsUI) {
    const cmsQuest = globalQuestsUI.find(cmsQuest => cmsQuest._id === userQuest.cmsId);
    if (cmsQuest) {
      questCard = {
        cmsQuestCard: cmsQuest,
        loyaltyQuest: userQuest,
      };
    }
  }

  // If we have a PERSONALIZED that's quest based on a CONFIG quest, we need to
  // find the UI info for it
  useEffect(() => {
    if (
      userQuest?.type === LoyaltyQuestType.PERSONALIZED &&
      userQuest?.cmsType === QuestCmsType.CONFIG
    ) {
      getConfigQuestUI({
        variables: {
          configId: userQuest.cmsId,
        },
      });
    }
  }, [userQuest, getConfigQuestUI]);

  if (userQuest && configQuestUI && configQuestUI?.ConfigQuest) {
    questCard = {
      cmsQuestCard: configQuestUI.ConfigQuest,
      loyaltyQuest: userQuest,
    };
  }

  const isLoading = loadingQuestCardList || loadingConfigQuestUI;

  const error = errorQuestCardList || errorConfigQuestUI;

  const refetch = useCallback(() => {
    refetchQuests();
    refetchQuestCardList();
    refetchConfigQuestUI();
  }, [refetchQuestCardList, refetchConfigQuestUI, refetchQuests]);

  return {
    isLoading,
    error,
    refetch,
    userQuest: questCard,
    cmsQuestCardUI: questCard ? cmsQuestCardUI : null,
  };
}
