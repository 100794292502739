import React, { useEffect } from 'react';

import {
  Actionsheet as UCLActionSheet,
  ActionsheetProps as UCLActionsheetProps,
  addWithConfig,
} from '@rbilabs/universal-components';

import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { isWeb } from 'utils/environment';

interface ActionsheetProps extends UCLActionsheetProps {
  onOpenEventMessage: string;
}

// This component is wrapper to register open Action sheet events in the app
export const ActionsheetWrapper = ({ onOpenEventMessage, ...props }: ActionsheetProps) => {
  const { logRBIEvent } = useMParticleContext();
  const hideDragIndicator = isWeb;

  useEffect(() => {
    if (props.isOpen) {
      logRBIEvent({
        name: CustomEventNames.ACTION_SHEET_VIEW,
        type: EventTypes.Other,
        attributes: {
          actionSheetHeader: onOpenEventMessage,
        },
      });
    }
  }, [props.isOpen, logRBIEvent, onOpenEventMessage]);

  return <UCLActionSheet {...props} hideDragIndicator={hideDragIndicator} />;
};

export const ActionsheetContent = addWithConfig(UCLActionSheet.Content).withConfig({
  // Accounts for extra space when drag indicator is hidden
  _web: {
    marginTop: '$7',
  },
});
