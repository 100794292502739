import { FirebasePerformanceTypes } from '@react-native-firebase/perf';

import { RbiOrderStatus } from 'generated/graphql-gateway';
import { startTrace } from 'utils/performance/trace-lite';

export const fetchWithTracing = (
  input: RequestInfo,
  init: RequestInit,
  operationName: string | null
) => {
  let trace: FirebasePerformanceTypes.Trace | null = null;

  if (operationName) {
    try {
      const customizer = operationNameSuffixCustomizer[operationName];
      if (customizer && typeof init.body === 'string') {
        const customizerResult = customizer(init.body as string);
        operationName = customizerResult ? `${operationName}_${customizerResult}` : operationName;
      }
    } catch (_err) {} // oh well

    trace = startTrace(`gql_op_${operationName}`);
  }

  return fetch(input, init).finally(() => trace?.stop());
};

// allow customizing the tracing output per body to differentiate
// useful if different operations have args that will drastically change perf of a call
const operationNameSuffixCustomizer = {
  GetRestaurants: (requestBody: string) => {
    if (requestBody.includes('"NEARBY"')) {
      return 'NEARBY';
    }

    if (requestBody.includes('"RECENT"')) {
      return 'RECENT';
    }

    if (requestBody.includes('"FAVORITE"')) {
      return 'FAVORITE';
    }

    return null;
  },
  GetUserOrders: (requestBody: string) =>
    Object.values(RbiOrderStatus)
      .filter(x => requestBody.includes(x))
      .join(','),
};
