import { FC } from 'react';

import { Header, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import Currency from 'components/currency';
import { usePaymentContext } from 'state/payment';

import { BalanceText, TextContainer, TransferBalanceContent } from './styled';

interface ITransferBalance {
  cardBalance: number;
  giftCardNumber: string;
}

export const TransferBalanceContainer: FC<React.PropsWithChildren<ITransferBalance>> = ({
  cardBalance,
  giftCardNumber,
}) => {
  const { formatMessage } = useIntl();
  const { getPrepaidPaymentMethod } = usePaymentContext();

  const currentBalance = getPrepaidPaymentMethod()?.prepaid?.currentBalance || 0;
  const transferCardBalance = cardBalance;
  const cardNumber = giftCardNumber;
  const totalAmount = currentBalance + transferCardBalance;
  const formatCardNumber = cardNumber?.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ');

  const isTransferCardBalanceValid = transferCardBalance && transferCardBalance > 0;

  return (
    isTransferCardBalanceValid && (
      <TransferBalanceContent>
        <Header variant="headerTwo">
          <Text fontSize="xl">{formatMessage({ id: 'transfer' })} </Text>
          <Currency textProps={{ fontSize: 'xl' }} amount={transferCardBalance} />?
        </Header>
        <TextContainer>
          <BalanceText>
            {formatMessage({ id: 'transferGiftCardYourCurrentGift' })}
            <Currency textProps={{ fontSize: 'md' }} amount={currentBalance} isBold />
          </BalanceText>
        </TextContainer>
        <TextContainer>
          <BalanceText>
            {formatMessage({ id: 'transferGiftCardTheCard' })}
            <BalanceText fontWeight="bold">{formatCardNumber}</BalanceText>
            <BalanceText>
              {formatMessage({ id: 'transferGiftCardHasABalance' })}
              <Currency textProps={{ fontSize: 'md' }} amount={transferCardBalance} isBold />.
            </BalanceText>
          </BalanceText>
        </TextContainer>
        <TextContainer>
          <BalanceText>
            {formatMessage({ id: 'transferGiftCardWouldYouLikeTo' })}
            <Currency textProps={{ fontSize: 'md' }} amount={transferCardBalance} isBold />
            {formatMessage({ id: 'transferGiftCardToYourCardFor' })}
            <Currency textProps={{ fontSize: 'md' }} amount={totalAmount} isBold />
            {formatMessage({ id: 'transferGiftCardNewBalanceQuestion' })}
          </BalanceText>
        </TextContainer>
      </TransferBalanceContent>
    )
  );
};
