import React, { useState } from 'react';

import { makeUclComponent } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { RotateAnimatedView } from 'components/ucl';

import { StyledButton, StyledIcon } from './styled';
import { CloseButtonProps } from './types';

const CloseButton = ({
  onClick,
  inverted = false,
  noBackground = true,
  _svg,
  icon = 'cancel',
  ...props
}: CloseButtonProps) => {
  const { formatMessage } = useIntl();
  const [isRotated, setIsRotated] = useState(false);
  return (
    <RotateAnimatedView isRotated={isRotated} toDeg={90}>
      <StyledButton
        variant={inverted ? 'ghost-reversed' : 'ghost'}
        onPress={onClick}
        onPressIn={() => setIsRotated(true)}
        onPressOut={() => setIsRotated(false)}
        onHoverIn={() => setIsRotated(true)}
        onHoverOut={() => setIsRotated(false)}
        noBackground={noBackground}
        testID="close-button"
        accessibilityLabel={formatMessage({ id: 'close' })}
        inverted={inverted}
        {...props}
        _pressed={{
          style: { transform: [{ scale: 0.9 }] },
        }}
      >
        <StyledIcon variant={icon} inverted={inverted} {..._svg} />
      </StyledButton>
    </RotateAnimatedView>
  );
};

export default makeUclComponent(CloseButton);
