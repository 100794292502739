import { ISection, MenuObject } from '@rbi-ctg/menu';
import {
  ICaloriesFragment,
  IEntity,
  IPricingAndAvailabilityFragment,
  IStoreMenuQuery,
} from 'generated/graphql-gateway';
import { IDayPart, IGetMenuSectionsQuery } from 'generated/sanity-graphql';

export enum DAY_PART_SELECTIONS {
  ALL_KEY = 'all',
  BREAKFAST_KEY = 'Breakfast',
  BURGERS_FOR_BREAKFAST = 'Burgers for Breakfast',
  MAIN_MENU_KEY = 'Main Menu',
}

export enum DAY_PART_IDS {
  ALL_KEY = '3',
  BREAKFAST_KEY = '16',
  BURGERS_FOR_BREAKFAST = '2',
  MAIN_MENU_KEY = '1',
}

export interface IGetDayPart {
  currentDayPart: DAY_PART_SELECTIONS | null;
  dayParts: IValidDayPart[];
}

export interface IUseDayPart {
  breakfastInterval: IDayPartBoundary | undefined;
  mainMenuInterval: IDayPartBoundary | undefined;
  setDayPart: (newDayPart: DAY_PART_SELECTIONS) => void;
  getDayPart: () => IGetDayPart;
}

export interface IDayPartBoundary {
  key: string;
  startTime: Date;
  endTime: Date;
  weekDays?: IDayPart['weekDays'];
}

export type IFeatureMenuDayPart = Omit<IDayPart, 'displayName'> & {
  displayName: { locale: string };
};

export interface IValidDayPart extends IFeatureMenuDayPart {
  endTime: string;
  key: DAY_PART_SELECTIONS;
  startTime: string;
}

interface IGetMainMenuParams {
  menuId: string;
  region: string;
  storeId: string | null;
  forceRefresh?: boolean;
}

export interface IGetStoreMenuParams {
  forceRefresh?: boolean;
  region: string;
  storeId: string;
}

export interface IMainMenuContext {
  getStoreMenu: (params: IGetStoreMenuParams) => Promise<void>;
  getMainMenu(params: IGetMainMenuParams): boolean;
  getPricingAndAvailability: (id: string) => IPricingAndAvailabilityFragment | null | undefined;
  getCalories: (item: MenuObject) => ICaloriesFragment['calories'] | null;
  getStoreMenuEntity: (id: string) => IEntity | null | undefined;
  data: IGetMenuSectionsQuery | undefined;
  loading: boolean;
  hasError: boolean;
  getSection: (id: string) => Partial<ISection> | null;
  dayParts: ReadonlyArray<IValidDayPart>;
  activeDayParts: IDayPartBoundary[];
  forceRefreshActiveDayParts: () => void;
  storeMenuLoading: boolean;
  storeMenuCalled: boolean;
  storeMenuData: IStoreMenuQuery | undefined;
  setIsOffer: (isOffer: boolean) => void;
  isOffer: boolean;
  isMenuLoading: boolean;
}
