import { ComponentProps, useCallback, useRef } from 'react';

import {
  NativeScrollEvent,
  NativeScrollPoint,
  NativeSyntheticEvent,
  ScrollView,
} from 'react-native';

export const useScrollRestoration = (restorationKey: string): ComponentProps<typeof ScrollView> => {
  const restorationMap = useRef<Record<string, NativeScrollPoint>>({});
  const scrollEventHandler = useCallback(
    (ev: NativeSyntheticEvent<NativeScrollEvent>) => {
      const { x, y } = ev.nativeEvent.contentOffset;
      restorationMap.current[restorationKey] = { x, y };
    },
    [restorationKey]
  );
  return {
    onScroll: scrollEventHandler,
    contentOffset: restorationMap.current[restorationKey],
    scrollEventThrottle: 0,
  };
};
