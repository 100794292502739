import { Box, Icon, makeUclComponent } from '@rbilabs/universal-components';

import ActionButton from 'components/action-button';
import { theme } from 'styles/configure-theme';
import { primitive } from 'styles/constants/primitives';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

export const HeaderContainer = Box.withConfig({
  position: 'relative',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '$14',
});

export const GiftCardContainer = Box.withConfig({
  width: 343,
  height: 190,
  flexDirection: 'row',
  alignItems: 'flex-start',
  alignSelf: 'center',
  paddingTop: '$4',
  paddingLeft: '$4',
  backgroundColor: primitive.bk.$meltyYellow,
  borderRadius: 10,
  marginY: '$4',
});

export const FieldsContainer = Box.withConfig({
  width: {
    base: 343,
    md: 528,
  },
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'center',
  padding: '$4',
  backgroundColor: primitive.$white,
  borderRadius: 10,
});

export const LogoAndNameContainer = Box.withConfig({
  flexDirection: 'column',
});

export const StyledBackIcon = Icon.withConfig({
  color: theme.token('icon-header-contrast'),
  size: '5',
  ...hiddenAccessibilityPlatformProps,
});

export const StyledActionButton = makeUclComponent(ActionButton).withConfig({
  width: {
    base: 'full',
    md: '300px',
  },
  alignSelf: 'center',
  marginTop: '$4',
});

export const BackgroundAndLogoContainer = Box.withConfig({
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const Divider = Box.withConfig({
  height: '$1',
  width: 'full',
});

export const DefaultContainer = Box.withConfig({
  width: 'full',
  flexDirection: 'row',
  justifyContent: 'space-between',
});
