import NetInfo, { NetInfoState, NetInfoStateType } from '@react-native-community/netinfo';
import { debounce, isEqual } from 'lodash-es';

import { getGraphqlGatewayApiUrl } from 'remote/constants';
import logger, { addLoggingContext } from 'utils/logger';

/** use this for full details and if you don't need to RENDER REACT differently based on its value.  */
export const staticConnectionStatus: { current: NetInfoState } = {
  current: {
    isConnected: true,
    type: NetInfoStateType.unknown,
    isInternetReachable: null,
    details: null,
  },
};

const REACHABILITY_SHORT_TIMEOUT = 2.5 * 1000;
const REACHABILITY_LONG_TIMEOUT = 60 * 1000;
const REACHABILITY_REQUEST_TIMEOUT = 5 * 1000;
// don't alert in UI immediately to avoid flake
export const UI_RESPONSE_TIME_TO_REACHABILITY_FAILED = REACHABILITY_SHORT_TIMEOUT * 4;

let isInternetReachable = true;
let pendingTimeout: NodeJS.Timeout | null = null;

function checkInternetReachable() {
  if (pendingTimeout) {
    // if this function is manually don't let multiple get going at once...
    clearTimeout(pendingTimeout);
  }

  const controller = new AbortController();
  const signal = controller.signal;

  setTimeout(() => {
    controller.abort();
  }, REACHABILITY_REQUEST_TIMEOUT);

  window
    .fetch(getGraphqlGatewayApiUrl() + '?query=%7B__typename%7D', { signal })
    .then(res => {
      if (!res.ok && isInternetReachable) {
        logger.info({
          message: `[BadNetwork] Network health check failed !res.ok`,
          isNetworkHealthCheckFailed: true,
          isBadNetworkDetected: true,
          notOkRes: true,
        });
        isInternetReachable = false;
        notifyNetworkStatusChanged();
      }

      if (res.ok && !isInternetReachable) {
        logger.info({
          message: `[BadNetwork] Network restored`,
          isNetworkRestored: true,
        });
        isInternetReachable = true;
        notifyNetworkStatusChanged();
      }
    })
    .catch(error => {
      if (isInternetReachable) {
        logger.info({
          message: `[BadNetwork] Network health check failed - catch`,
          error,
          isNetworkHealthCheckFailed: true,
          isBadNetworkDetected: true,
        });
        isInternetReachable = false;
        notifyNetworkStatusChanged();
      }
    })
    .finally(() => {
      pendingTimeout = setTimeout(
        checkInternetReachable,
        isInternetReachable ? REACHABILITY_LONG_TIMEOUT : REACHABILITY_SHORT_TIMEOUT
      );
    });
}

checkInternetReachable();

// we just use NetInfo lib for its type defs and connection information. its reachability doesn't work right.
NetInfo.configure({
  reachabilityShouldRun: () => false,
  shouldFetchWiFiSSID: false,
  useNativeReachability: false,
});

const listeners: ((info: NetInfoState) => void)[] = [];

/** use this instead of NetInfo.addEventListener as it doesn't seem to trigger all listeners reliably */
export const addNetworkStatusListener = (func: (info: NetInfoState) => void) => {
  listeners.push(func);
  return () => {
    listeners.splice(listeners.indexOf(func), 1);
  };
};

// we don't use NetInfo's isInternetReachable here because it doesn't work quite right but other info is useful
let partialNetInfoStatus: Omit<NetInfoState, 'isInternetReachable'> = {
  details: null,
  isConnected: true,
  type: NetInfoStateType.unknown,
};

// only 1 of these because it appears more than one doesn't always work (bug in lib?)
NetInfo.addEventListener(({ details, type, isConnected }) => {
  const newStatus = { details, type, isConnected };

  if (isEqual(newStatus, partialNetInfoStatus)) {
    // a "feature" of this lib is to spam same status although nothing has changed. lets not bother underlying stuff...
    return;
  }

  partialNetInfoStatus = newStatus;

  notifyNetworkStatusChanged();
});

function notifyNetworkStatusChanged() {
  const newStatus = {
    details: partialNetInfoStatus.details,
    type: partialNetInfoStatus.type,
    isConnected: partialNetInfoStatus.isConnected,
    isInternetReachable,
  } as NetInfoState;

  staticConnectionStatus.current = newStatus;
  addLoggingContext({ networkConnectionStatus: newStatus });

  listeners.forEach(listener => listener(newStatus));
}

/** call when API call fails for network reasons -
 * Why?
 * - THIS triggers a network reachability check to our RBI backend outside of normal reachabilityLongTimeout schedule
 * - Will show bad internet dialog if condition doesn't improve in reachabilityFinalCheckBeforeDialog*/
export const announceApiCallNetworkFailure = debounce(
  () => {
    NetInfo.refresh();
    checkInternetReachable();
  },
  REACHABILITY_SHORT_TIMEOUT,
  { leading: true }
);
