import { View } from '@rbilabs/universal-components';
import LottieView from 'lottie-react-native';

import { StaticImages, useStaticLottieUri } from 'components/picture/static-image';
import { isNative } from 'utils/environment';

import { useConfettiFriesAnimation } from './use-confetti-fries-animation';

const AnimationContainer = View.withConfig({
  width: 'full',
  height: '100%',
  position: 'absolute',
  // background: 'rgba(100,100,100,.2)',
  _web: {
    maxWidth: '600px',
    alignSelf: 'center',
  },
});

export const ConfettiFriesAnimation = () => {
  const { isAnimationComplete, setIsAnimationComplete } = useConfettiFriesAnimation();
  const uri = useStaticLottieUri(StaticImages.ConfettiFries);

  return (
    !isAnimationComplete &&
    uri && (
      <AnimationContainer>
        <LottieView
          autoPlay={true}
          loop={false}
          source={{ uri }}
          style={
            isNative
              ? {
                  position: 'absolute',
                  alignSelf: 'center',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  margin: -50,
                  pointerEvents: 'none',
                }
              : {}
          }
          webStyle={{}}
          onAnimationFinish={() => {
            // Add delay on Native to allow time for the animation to finish
            setTimeout(() => setIsAnimationComplete(true), isNative ? 2000 : 0);
          }}
        />
      </AnimationContainer>
    )
  );
};
