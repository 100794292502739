import { useCallback } from 'react';

import { MenuObjectTypes } from 'enums/menu';
import { useMainMenuContext } from 'state/menu/main-menu';
import {
  IPriceForComboSlotSelectionFn,
  IPriceForItemInComboSlotSelectionFn,
  IPriceForItemOptionModifierFn,
  IPricingFunctionFn,
  IUsePricingFunctionHookReturnType,
} from 'state/menu/types';
import { isCombo } from 'utils/menu/is-menu-type';

import { computeComboSlotsPrice, computeModifiersPrice, getMenuEntityKey } from './utils';

export const useStoreMenuPricing = (): IUsePricingFunctionHookReturnType => {
  const { getStoreMenuEntity, isMenuLoading, storeMenuData } = useMainMenuContext();
  const allPricingDependenciesAreSettled = !isMenuLoading && !!storeMenuData;

  const priceForItemInComboSlotSelection = useCallback<IPriceForItemInComboSlotSelectionFn>(
    ({ combo, selectedItem }) => {
      if (!combo || !selectedItem) {
        return 0;
      }

      const selectedItemId = getMenuEntityKey(selectedItem);
      const storeMenuEntity = getStoreMenuEntity(selectedItemId);

      const comboId = getMenuEntityKey(combo);

      const comboOverride = storeMenuEntity?.price?.overrides?.find(override =>
        override.key?.includes(comboId)
      );
      const { price } = comboOverride ?? {};
      return price ?? 0;
    },
    [getStoreMenuEntity]
  );

  // Keeping this indirection to be compliant with `usePricingFunction` interface
  const priceForComboSlotSelection = useCallback<IPriceForComboSlotSelectionFn>(
    ({ combo, comboSlot, selectedOption }) => {
      const selectedItem = selectedOption?.option;
      if (!selectedItem || selectedItem._type === MenuObjectTypes.PICKER) {
        return 0;
      }
      return priceForItemInComboSlotSelection({
        combo,
        comboSlot,
        selectedItem,
      });
    },
    [priceForItemInComboSlotSelection]
  );

  const priceForItemOptionModifier = useCallback<IPriceForItemOptionModifierFn>(
    ({ item, itemOption, modifier }) => {
      const itemId = getMenuEntityKey(item);
      const itemOptionKey = getMenuEntityKey(itemOption);

      if (isCombo(item) || !itemId || !itemOptionKey || !modifier) {
        return 0;
      }

      const { options: storeMenuOptions } = getStoreMenuEntity(itemId) || {};
      return computeModifiersPrice({
        storeMenuOptions,
        modifierSelections: [{ _key: itemOptionKey, modifier }],
      });
    },
    [getStoreMenuEntity]
  );

  /**
   * Returns the price of a menu object
   */
  const pricingFunction = useCallback<IPricingFunctionFn>(
    ({ item, quantity = 1, modifierSelections = [], comboSlotSelections = {} }) => {
      // Base Price
      const itemId = getMenuEntityKey(item as { _id: string });
      const storeMenuEntity = getStoreMenuEntity(itemId);
      const basePrice = storeMenuEntity?.price ? Number(storeMenuEntity.price.default) : 0;

      // ComboSlots Price
      let comboSlotsPrice = 0;
      if (isCombo(item)) {
        comboSlotsPrice += computeComboSlotsPrice({
          combo: item,
          modifierSelections,
          comboSlotSelections,
          getStoreMenuEntity,
          priceForItemInComboSlotSelection,
        });
      }

      // Modifiers Price
      let storeMenuOptions = storeMenuEntity?.options;
      if (isCombo(item)) {
        // The combos should compute modifiers on the mainItem instead
        const mainItemEntity = getStoreMenuEntity(item.mainItem?._id ?? '');
        storeMenuOptions = mainItemEntity?.options;
      }
      const modifiersPrice = computeModifiersPrice({ storeMenuOptions, modifierSelections });

      const totalPrice = basePrice + modifiersPrice + comboSlotsPrice;
      return totalPrice * quantity;
    },
    [getStoreMenuEntity, priceForItemInComboSlotSelection]
  );

  return {
    allPricingDependenciesAreSettled,
    priceForItemInComboSlotSelection,
    priceForComboSlotSelection,
    priceForItemOptionModifier,
    pricingFunction,
  };
};
