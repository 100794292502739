import React, { FC, useCallback } from 'react';

import { Image } from 'react-native';

import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';
import { useScrollContext } from 'state/scroll';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';
import { EventName, emitEvent } from 'utils/event-hub';
import { routes } from 'utils/routing';

import { NAV_BAR_ICON_SIZE } from './constants';
import { NavLink } from './helpers';
import { Badge, BadgeContainer, IconWrap, NavLinkText } from './styled';
import { NavigationMobileTab } from './types';

type ISanityNavMobileTabProps = {
  componentKey: string;
  tab: NavigationMobileTab;
  language: string;
  onClick?: (to: string, isActive: boolean) => void;
  isFirst: boolean;
  isLast: boolean;
};

export const SanityNavMobileTab: FC<React.PropsWithChildren<ISanityNavMobileTabProps>> = ({
  componentKey,
  tab,
  language,
  isFirst,
  isLast,
}) => {
  const { iconImageSource, iconActiveImageSource, link, text, forceExactMatch = false } = tab;
  const route = link?.[language] || link?.en || routes.base;
  const requiresExactMatch = forceExactMatch || route === routes.base || route === routes.account;

  const { scrollTo } = useScrollContext();
  const { inRestaurantRedemptionCart } = useInRestaurantRedemptionContext();
  const count = inRestaurantRedemptionCart.reduce(
    (acc, currentValue) => acc + currentValue.quantity,
    0
  );

  const onPress = useCallback(
    (clickRoute: string, isActive: boolean) => {
      emitEvent(EventName.BOTTOM_TAB_PRESSED, {
        route: clickRoute,
      });
      if (isActive) {
        // whenever a mobile nav button is pressed when the route already matches,
        // scroll to the top of the view
        scrollTo({ animated: false });
      }
    },
    [scrollTo]
  );

  const buttonIcon = useCallback(
    (isActive: boolean) => {
      const iconSource = isActive ? iconActiveImageSource : iconImageSource;
      return (
        <>
          {!!iconSource && (
            <IconWrap>
              {route === routes.redeem && count > 0 && (
                <BadgeContainer>
                  <Badge variant="number-solid">{count.toString()}</Badge>
                </BadgeContainer>
              )}
              <Image
                source={iconSource}
                resizeMode="contain"
                style={{
                  height: NAV_BAR_ICON_SIZE,
                  width: NAV_BAR_ICON_SIZE,
                }}
                {...hiddenAccessibilityPlatformProps}
              />
            </IconWrap>
          )}
          <NavLinkText variant="copyTwo" $isActive={isActive}>
            {text?.[language] || text?.en}
          </NavLinkText>
        </>
      );
    },
    [count, iconActiveImageSource, iconImageSource, language, text]
  );

  return (
    <NavLink
      to={route}
      requiresExactMatch={requiresExactMatch}
      testID={`nav-with-picture-icon-${(text?.en).split(' ').join('-')}`}
      // @ts-expect-error TS(2322) FIXME: Type '(clickRoute: string, isActive: boolean) => v... Remove this comment to see the full error message
      onClick={onPress}
      component={buttonIcon}
      navBarText={tab.label}
      componentKey={componentKey ?? ''}
      isFirst={isFirst}
      isLast={isLast}
    />
  );
};
