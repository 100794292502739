import React from 'react';

import { BkLogoRevamp } from 'components/logo/bk-logo-revamp';
import { RpFrLogoRevamp, RpLogoRevamp } from 'components/logo/rp-logo-revamp';
import { useAuthContext } from 'state/auth';
import useIntl from 'state/intl/hook';
import { LANGUAGES } from 'state/intl/types';

export const Logo: React.FC = props => {
  const { isAuthenticated } = useAuthContext();
  const { language } = useIntl();

  const RpLogo = language === LANGUAGES.en ? RpLogoRevamp : RpFrLogoRevamp;

  return isAuthenticated ? <RpLogo {...props} /> : <BkLogoRevamp {...props} />;
};
