import React, { ComponentProps, useState } from 'react';

import { AutocompletePrediction } from '@rbilabs/expo-maps';
import {
  Box,
  FormControl,
  Icon,
  IconProps,
  Input,
  InputProps,
} from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';
import {
  FlatList,
  Keyboard,
  NativeSyntheticEvent,
  StyleSheet,
  TextInputKeyPressEventData,
} from 'react-native';

import { useLogUserErrorMessage } from 'hooks/log-user-error-message';
import { styleToNumber } from 'utils/style/style-to-number';

import {
  Container,
  MainPredictionText,
  OptionsListWrapper,
  StyledAddressInput,
  StyledInputIcon,
  StyledItemText,
  StyledOptionsListItem,
} from './styles';
import { AutocompleteDropdownProps } from './types';

type ITextInputProps = ComponentProps<typeof Input> & {
  label: string;
  labelColor?: string;
  inputCustomProps?: InputProps;
  rightIconCustomProps?: IconProps;
};

const AutocompleteOptionsDropdown = React.forwardRef<
  HTMLInputElement,
  ITextInputProps & AutocompleteDropdownProps
>(
  (
    {
      testID,
      clearTextIconTitle,
      errorMessage,
      label,
      labelColor,
      noPredictionsFoundText,
      predictions,
      onInputChange,
      onSelection,
      value,
      onBlur,
      inputCustomProps,
      rightIconCustomProps,
    },
    ref
  ) => {
    const [highlightedItemId, setHighlightedItemId] = useState<string | null>(null);
    const [isItemSelected, setIsItemSelected] = useState(false);
    const [isInputFocus, setIsInputFocus] = useState(false);
    const { formatMessage } = useIntl();

    const handleKeyPress = (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      if (!predictions) {
        return;
      }

      const key = event.nativeEvent.key;
      const currentIndex = predictions?.findIndex(item => item.placeId === highlightedItemId);

      switch (key) {
        case 'ArrowUp':
          if (currentIndex > 0) {
            setHighlightedItemId(predictions[currentIndex - 1].placeId);
          }
          break;
        case 'ArrowDown':
          if (currentIndex < predictions?.length - 1) {
            setHighlightedItemId(predictions[currentIndex + 1].placeId);
          }
          break;
        case 'Enter':
          if (highlightedItemId) {
            handleOnSelectItem(predictions[currentIndex]);
          }
          break;
        case 'Escape':
          setIsInputFocus(false);
          setHighlightedItemId(null);
          Keyboard.dismiss();
          break;
      }
    };

    const handleOnSelectItem = (item: AutocompletePrediction) => {
      onSelection({
        address: item.description || '',
        placeId: item.placeId || '',
      });
      setIsItemSelected(true);
      Keyboard.dismiss();
    };

    const RenderListItems = ({ item }: { item: AutocompletePrediction }) => (
      <StyledOptionsListItem
        key={item.placeId}
        onPress={() => {
          handleOnSelectItem(item);
          setIsInputFocus(false);
        }}
        isHighlighted={highlightedItemId === item.placeId}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          testID="address-suggestion"
          alignItems="stretch"
          accessible
          accessibilityLabel={`${item.mainText} ${item.secondaryText}`}
          accessibilityRole="menuitem"
        >
          <MainPredictionText>{item.mainText}</MainPredictionText>
          <StyledItemText fontSize="xs" numberOfLines={1}>
            {item.secondaryText}
          </StyledItemText>
        </Box>
      </StyledOptionsListItem>
    );

    const InputRightIcon = () => {
      const accessibilityLabel = formatMessage({ id: 'searchLocation' });
      const accessibilityLabelClear = formatMessage({ id: 'clearText' });
      return value ? (
        <StyledInputIcon
          variant="cancel"
          title={clearTextIconTitle}
          // @ts-expect-error TS(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
          onPress={() => handleOnSelectItem({})}
          _web={{ cursor: 'pointer' }}
          focusable
          accessibilityLabel={accessibilityLabelClear}
          rightIconCustomProps={rightIconCustomProps}
        />
      ) : (
        <StyledInputIcon
          variant="search"
          accessibilityLabel={accessibilityLabel}
          rightIconCustomProps={rightIconCustomProps}
        />
      );
    };

    const handleInputChange = (inputValue: string) => {
      onInputChange(inputValue);
      setIsItemSelected(false);
    };

    const handleInputFocus = () => {
      setIsInputFocus(true);
    };

    useLogUserErrorMessage({ message: errorMessage });

    return (
      <Container>
        <FormControl isInvalid={!!errorMessage} margin={0}>
          <StyledAddressInput
            testID={testID}
            placeholder={label}
            placeholderTextColor={labelColor}
            value={value}
            InputRightElement={InputRightIcon()}
            onChangeText={handleInputChange}
            wrapperRef={ref}
            onFocus={handleInputFocus}
            onBlur={onBlur}
            inputCustomProps={inputCustomProps}
            onKeyPress={handleKeyPress} // Handle keyboard events
          />
          <FormControl.ErrorMessage leftIcon={<Icon variant="error" size="xs" />}>
            {errorMessage}
          </FormControl.ErrorMessage>
        </FormControl>
        {isInputFocus && (
          <OptionsListWrapper>
            {value &&
              value.length > 2 &&
              !isItemSelected &&
              (!predictions?.length ? (
                <StyledOptionsListItem disabled>
                  <StyledItemText fontSize="2xs">{noPredictionsFoundText}</StyledItemText>
                </StyledOptionsListItem>
              ) : (
                <FlatList
                  style={styles.flatList}
                  data={predictions}
                  renderItem={RenderListItems}
                  keyExtractor={(item: AutocompletePrediction) => item.placeId}
                  scrollEnabled={false}
                  keyboardShouldPersistTaps="always"
                />
              ))}
          </OptionsListWrapper>
        )}
      </Container>
    );
  }
);

const styles = StyleSheet.create({
  flatList: {
    borderBottomLeftRadius: styleToNumber(Styles.borderRadius),
    borderBottomRightRadius: styleToNumber(Styles.borderRadius),
    overflow: 'hidden',
  },
});

export default AutocompleteOptionsDropdown;

export * from './types';
