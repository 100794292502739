import { RunningExperiments, useExperiments } from 'state/experiments';

export const useLoyaltyOfferAndRewardDetailButtonCopyExperiment = (
  skipExposureLogging: boolean = false
) => {
  const { variant } = useExperiments();

  return variant(RunningExperiments.OfferAndRewardDetailButtonCopy, 'treatment', {
    skipExposureLogging,
  });
};
