import { IHistoricalOrder, IServerOrder } from '@rbi-ctg/menu';
import { computeDeliveryFee } from 'utils/cart/helper';

import { getOrderNumber as getOrderNumberWithPosFallback } from '../../pages/order-confirmation/util';

import { IUseDeliveryFees, IUserDeliveryFeesReturn } from './types';

export function getOrderNumber(
  serverOrder: IServerOrder | IHistoricalOrder,
  enableOrderNumberGeneration: boolean
): string | null {
  const orderNumber = getOrderNumberWithPosFallback(serverOrder);
  if (!enableOrderNumberGeneration) {
    return orderNumber;
  }

  return serverOrder?.orderNumberFormatted || orderNumber;
}

export const getDeliveryFees = ({ delivery }: IUseDeliveryFees): IUserDeliveryFeesReturn => {
  const baseDeliveryFeeCents = delivery?.feeCents || 0;
  const deliveryFeeDiscountCents = delivery?.feeDiscountCents || 0;
  const serviceFeeCents = delivery?.serviceFeeCents || 0;
  const smallCartFeeCents = delivery?.smallCartFeeCents || 0;
  const geographicalFeeCents = delivery?.geographicalFeeCents || 0;
  const tipCents = delivery?.tipCents || 0;

  const finalDeliveryFeeCents = computeDeliveryFee({
    feeCents: baseDeliveryFeeCents,
    feeDiscountCents: deliveryFeeDiscountCents,
    serviceFeeCents,
    smallCartFeeCents,
    geographicalFeeCents,
  });

  const isFreeDelivery = finalDeliveryFeeCents === 0;

  return {
    isFreeDelivery,
    feeCents: finalDeliveryFeeCents,
    deliveryFeeDiscountCents,
    geographicalFeeCents,
    serviceFeeCents,
    smallCartFeeCents,
    tipCents,
  };
};
