import { Box, Text } from '@rbilabs/universal-components';

import { primitive } from 'styles/constants/primitives';

export const TransferBalanceContent = Box.withConfig({
  width: {
    base: 343,
    md: 528,
  },
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'center',
  padding: '$4',
  backgroundColor: primitive.$white,
  borderRadius: 10,
  marginY: '$4',
});

export const TextContainer = Box.withConfig({
  flexDirection: 'row',
  mb: '$6',
});

export const BalanceText = Text.withConfig({
  fontSize: 'md',
});
