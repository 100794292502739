import { useCallback, useEffect, useMemo } from 'react';

import {
  GetStaticPageRoutesQuery,
  IGetStaticPageRoutesQuery,
  IStaticPageRoute,
} from 'remote/queries/static-page';
import { useLazySanityQuery } from 'state/graphql/hooks/use-sanity-query';
import { withLazySimpleCache } from 'state/graphql/with-simple-cache';
import { useLocale } from 'state/intl';

const useStaticPageRoutesLazyQuery = withLazySimpleCache<IGetStaticPageRoutesQuery, any>(opts =>
  useLazySanityQuery<IGetStaticPageRoutesQuery>(GetStaticPageRoutesQuery, opts, { isV2: true })
);

export const useStaticPageRoutes = () => {
  const [
    _loadRoutes,
    { data, error, loading, refetch, called: loadRoutesHasBeenCalled },
  ] = useStaticPageRoutesLazyQuery();

  const routes = useMemo<IStaticPageRoute[]>(
    () => (data && data.allStaticPage ? data.allStaticPage : []),
    [data]
  );

  const loadRoutes = useCallback(() => {
    // Don't refetch the static routes if _loadRoutes already has been called.
    if (!loadRoutesHasBeenCalled) {
      _loadRoutes({ allowFromCache: true });
    }
  }, [_loadRoutes, loadRoutesHasBeenCalled]);

  // Refetch static page routes when switching language
  const { language } = useLocale();
  useEffect(() => {
    refetch();
  }, [language, refetch]);

  return { loadRoutes, error, loading, refetch, routes, loadRoutesHasBeenCalled };
};
