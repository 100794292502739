import noop from 'utils/noop';

const crashlytics = () => {
  return {
    recordError: noop,
    sendUnsentReports: noop,
    setUserId: noop,
    setAttribute: noop,
    log: noop,
  };
};

export default crashlytics;
