import { MenuObjectTypes } from 'enums/menu';
import { LoyaltyServiceMode } from 'generated/graphql-gateway';

import { IncentiveSource } from '../types';

import type { ICmsConfigOffer, IEngineOffer, ILoyaltyOffer } from './types';
import { OffersServiceMode } from './types';

// these service modes are considered pickup
const PICKUP_SERVICE_MODES = [
  LoyaltyServiceMode.TAKEOUT,
  LoyaltyServiceMode.CURBSIDE,
  LoyaltyServiceMode.DRIVE_THRU,
];

/**
 * Simplify the service mode parsing grouping service modes.
 */
export const getOfferServiceMode = (engineOffer: IEngineOffer): OffersServiceMode | undefined => {
  let serviceMode = undefined;
  const offerServiceModes = engineOffer.serviceModes;

  if (offerServiceModes?.length) {
    const hasPickupService = offerServiceModes.some(
      entry => !!entry && PICKUP_SERVICE_MODES.includes(entry)
    );
    const hasDelivery = offerServiceModes.includes(LoyaltyServiceMode.DELIVERY);

    const isDeliveryOnly = offerServiceModes.length === 1 && hasDelivery;
    const isPickupOnly = !hasDelivery && hasPickupService;

    if (isDeliveryOnly) {
      serviceMode = OffersServiceMode.DELIVERY_ONLY;
    } else if (isPickupOnly) {
      serviceMode = OffersServiceMode.PICKUP_ONLY;
    }
  }

  // Return `undefined` if the offer accepts both service modes.
  return serviceMode;
};

export const isPromoCodeOffer = (offer: ILoyaltyOffer) =>
  offer.metadata?.some(data => data?.value === IncentiveSource.PROMOTION_CODE);
export const isOfferDeliveryOnly = (offer: ILoyaltyOffer) =>
  offer.serviceMode === OffersServiceMode.DELIVERY_ONLY;

export const isDiscountOffer = (offer: ILoyaltyOffer) =>
  offer?.incentives?.every(benefit => benefit?._type === MenuObjectTypes.OFFER_DISCOUNT);

export const isCmsConfigOffer = (offer: {
  readonly __typename?: string;
}): offer is ICmsConfigOffer => offer.__typename === 'ConfigOffer';
