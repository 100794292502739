import { useMemo } from 'react';

import { IHomePageConfigurationFragment } from 'generated/sanity-graphql';

import {
  FeatureHomePageComponentTypes,
  FeatureHomePageComponents,
  IRawFeatureHomePageComponent,
} from '../types';

import { useAlert } from './use-alert';
import { useAppDownload } from './use-app-download';
import { useMarketingCardGroups } from './use-marketing-card-groups';
import { useMarketingTileGroups } from './use-marketing-tile-groups';
import { useRewardsSection } from './use-rewards-section';
import { useSimpleHomePage } from './use-simple-home-page';

const ID_ONLY_COMPONENTS = [
  FeatureHomePageComponentTypes.LockedOffers,
  FeatureHomePageComponentTypes.RecentItems,
  FeatureHomePageComponentTypes.QuestsSection,
];

const MULTIPLE_INSTANCE_COMPONENTS = [
  FeatureHomePageComponentTypes.MarketingCardGroup,
  FeatureHomePageComponentTypes.MarketingTileGroup,
];

/**
 * @name formatIdOnlyComponents
 * @description
 *  At this point there are some components we only care about their _id,
 *    and we don't need to query for them because we already have the _id
 *    from the original homepage query.
 *  So, lets just format them to mirror the queried components
 */
const formatIdOnlyComponents = (featureHomePageComponentMap: any) => {
  return ID_ONLY_COMPONENTS.reduce((acc, compType) => {
    if (featureHomePageComponentMap[compType]) {
      acc[compType] = {
        _id: featureHomePageComponentMap[compType],
        __typename: compType,
      };
    }
    return acc;
  }, {});
};

const orderPopulatedFeatureHomePageComponents = (
  allPopulatedComponents: FeatureHomePageComponents,
  featureHomePage?: IHomePageConfigurationFragment
) => {
  return (featureHomePage?.components ?? []).reduce(
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    (acc: FeatureHomePageComponents[], rawComp: IRawFeatureHomePageComponent) => {
      if (rawComp?.__typename && allPopulatedComponents[rawComp?.__typename]) {
        const allowedMultipleInstances = MULTIPLE_INSTANCE_COMPONENTS.includes(rawComp.__typename);
        // These types are allowed multiple instances, so we need to find the right one
        if (allowedMultipleInstances) {
          const specificItem = allPopulatedComponents[rawComp.__typename].find(
            (popComp: any) => popComp._id === rawComp._id
          );
          if (specificItem) {
            acc.push(specificItem);
          }
        }
        // all others are limited to one instance so just push the one
        else {
          acc.push(allPopulatedComponents[rawComp?.__typename]);
        }
      }
      return acc;
    },
    [] as FeatureHomePageComponents[]
  );
};

export const useFeatureHomePageComponents = (featureHomePage?: IHomePageConfigurationFragment) => {
  /**
   * @name featureHomePageComponentMap
   * @description
   *  maps raw feature home page components to { type: id } structure
   *  to simplify querying for the different component types
   */
  // @ts-expect-error TS(2322) FIXME: Type '{ readonly __typename: "Alert"; readonly _id... Remove this comment to see the full error message
  const featureHomePageComponentMap: FeatureHomePageComponents = useMemo(
    () =>
      (featureHomePage?.components ?? [])
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        .reduce((acc: any, comp: IRawFeatureHomePageComponent) => {
          const allowedMultipleInstances = MULTIPLE_INSTANCE_COMPONENTS.includes(comp.__typename);
          // These types are allowed multiple instances
          // set up the component type as an array of ids
          if (allowedMultipleInstances) {
            if (!acc[comp.__typename]) {
              acc[comp.__typename] = [];
            }
            acc[comp.__typename].push(comp._id);
          }
          // all others are limited to one instance so just one id
          else {
            acc[comp.__typename] = comp._id;
          }
          return acc;
        }, {}),
    [featureHomePage?.components]
  );

  /**
   * ==================================
   *    INDIVIDUAL COMPONENT QUERIES
   * ==================================
   */

  const { simpleHomePage, simpleHomePageLoading } = useSimpleHomePage(
    featureHomePageComponentMap.SimpleHomePage
  );

  const { alert } = useAlert(featureHomePageComponentMap.Alert);

  const { marketingCardGroups } = useMarketingCardGroups(
    featureHomePageComponentMap.MarketingCardGroup
  );

  const { marketingTileGroups } = useMarketingTileGroups(
    featureHomePageComponentMap.MarketingTileGroup
  );

  const { rewardsSection } = useRewardsSection(featureHomePageComponentMap.RewardsSection);

  const { appDownload } = useAppDownload(featureHomePageComponentMap.AppDownload);

  const idOnlyComponents = useMemo(() => formatIdOnlyComponents(featureHomePageComponentMap), [
    featureHomePageComponentMap,
  ]);

  /**
   * ==================================
   *   ORDER POPULATED COMPONENT DATA
   * ==================================
   */
  const orderedPopulatedFeatureHomePageComponents = useMemo(() => {
    const allPopulatedComponents: FeatureHomePageComponents = {
      [FeatureHomePageComponentTypes.Alert]: alert,
      [FeatureHomePageComponentTypes.MarketingTileGroup]: marketingTileGroups,
      [FeatureHomePageComponentTypes.MarketingCardGroup]: marketingCardGroups,
      [FeatureHomePageComponentTypes.RewardsSection]: rewardsSection,
      [FeatureHomePageComponentTypes.AppDownload]: appDownload,
      [FeatureHomePageComponentTypes.SimpleHomePage]: simpleHomePage,
      ...idOnlyComponents,
    };
    return orderPopulatedFeatureHomePageComponents(allPopulatedComponents, featureHomePage);
  }, [
    alert,
    appDownload,
    featureHomePage,
    idOnlyComponents,
    marketingCardGroups,
    marketingTileGroups,
    rewardsSection,
    simpleHomePage,
  ]);

  /**
   * ==================================
   *            RETURN DATA
   * ==================================
   */
  return {
    featureHomePageComponentsLoading: simpleHomePageLoading,
    featureHomePageComponents: orderedPopulatedFeatureHomePageComponents,
  };
};
