import React, { useCallback, useReducer } from 'react';

import { useIntl } from 'react-intl';

import { useNavigation } from 'hooks/navigation/use-navigation';
import { useAuthContext } from 'state/auth';

import SuccessAnimation from './animations/success-animation';
import { useIsEnrollmentEnabled } from './hooks/use-is-enrollment-enabled';
import { LoyaltyOptInModal } from './loyalty-opt-in-modal';
import { OptInReducerModal, OptInReducerModalAction, OptInReducerState } from './types';

const optInReducer = (state: OptInReducerState, action: OptInReducerModalAction) => {
  switch (action.type) {
    case OptInReducerModal.DISPLAY_SUCCESS_ANIMATION: {
      return { ...state, displaySuccessAnimation: true };
    }
    case OptInReducerModal.COMPLETE_OPT_IN: {
      return { completedOptIn: true, displaySuccessAnimation: false };
    }
    default: {
      throw new Error('Unrecognized state transition');
    }
  }
};

/**
 * LoyaltyOptIn component handle the loyalty user creation and call manage the mutation call state.
 */
const LoyaltyOptIn = ({ cognitoId }: { cognitoId: string }) => {
  const { navigate } = useNavigation();
  const { formatMessage } = useIntl();
  const { user, refreshCurrentUser } = useAuthContext();
  const [{ displaySuccessAnimation, completedOptIn }, dispatch] = useReducer(optInReducer, {
    completedOptIn: false,
    displaySuccessAnimation: false,
  });

  const onLoyaltyUserCreated = useCallback(() => {
    // Reload user data since loyalty id should now be updated
    refreshCurrentUser();
    dispatch({ type: OptInReducerModal.DISPLAY_SUCCESS_ANIMATION });
  }, [refreshCurrentUser]);

  // once the success animation finish the flow ends
  const onSuccessAnimationFinish = useCallback(() => {
    dispatch({ type: OptInReducerModal.COMPLETE_OPT_IN });
    navigate(formatMessage({ id: 'routes.loyaltyDashboard' }));
  }, [formatMessage, navigate]);

  // if user was created successfully display animation
  if (displaySuccessAnimation) {
    return <SuccessAnimation onAnimationFinish={onSuccessAnimationFinish} />;
  }

  // once the user gets a loyalty ID we've to first display the success animation
  if (
    // user already have a loyalty ID or it was created on this flow
    user?.loyaltyId ||
    completedOptIn
  ) {
    return null;
  }

  return <LoyaltyOptInModal cognitoId={cognitoId} onLoyaltyUserCreated={onLoyaltyUserCreated} />;
};

/**
 * Wrap the LoyaltyOptIn Component to not create any check if the flow
 * doesn't apply for the user or is not available.
 */
export const LoyaltyOptInWrapper = () => {
  const { user } = useAuthContext();
  const isEnrollmentEnabled = useIsEnrollmentEnabled();

  const cognitoId = user?.cognitoId;

  // key to display a new modal instance if the user id changes
  return isEnrollmentEnabled && cognitoId ? (
    <LoyaltyOptIn key={cognitoId} cognitoId={cognitoId} />
  ) : null;
};

export default LoyaltyOptInWrapper;
