import React, { useCallback } from 'react';

import { useConfigValue } from 'hooks/configs/use-config-value';
import useEffectOnce from 'hooks/use-effect-once';
import { logger } from 'utils/logger';

import { IEncryptionConfig, ISafetechEncryption } from './types';

declare global {
  interface Window {
    ProtectPANandCVV: Function;
    ValidatePANChecksum: Function;
    PIE: {
      key_id: string;
      phase: string;
    };
  }
}

const loadScript = (url: string) => {
  return new Promise((resolve, reject) => {
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.onload = resolve;
    script.onerror = reject;
    head.appendChild(script);
  });
};

const SafetechEncryption: React.FC<ISafetechEncryption> = ({
  cardNumber,
  cvv,
  onResult,
  onError,
}) => {
  const config: IEncryptionConfig = useConfigValue({
    key: 'orbital',
    defaultValue: null,
    isRegionalized: true,
  });

  const encryptCard = useCallback(async () => {
    const { baseUrl, pieFormat, subscriberId, mode } = config;
    const embed = mode === 'eFPE';

    const getKeyScriptUrl: string = `${baseUrl}/pie/v1/${pieFormat}${subscriberId}/getkey.js`;
    const encryptionScriptUrl: string = `${baseUrl}/pie/v1/encryption.js`;

    // Load scripts
    await loadScript(getKeyScriptUrl);
    await loadScript(encryptionScriptUrl);

    const hasValidChecksum = await window.ValidatePANChecksum(cardNumber);
    if (!hasValidChecksum) {
      return onError({
        error: 'PAN has invalid checksum',
      });
    }
    const result = await window.ProtectPANandCVV(cardNumber, cvv, !embed);
    if (!result) {
      return onError({
        error: 'Encryption failed',
      });
    }

    const [cryptCard, cryptCvv, integrityCheck] = result;

    // Return encrypted card details and orbital configs
    onResult({
      cryptCard,
      cryptCvv,
      integrityCheck,
      pieFormat,
      subscriberId,
      mode,
      keyId: window.PIE.key_id,
      phase: parseInt(window.PIE.phase),
    });
  }, [cardNumber, config, cvv, onError, onResult]);

  useEffectOnce(() => {
    encryptCard();
  });

  if (!config || !config.baseUrl) {
    logger.error('Safetech encryption config not available');
    return null;
  }

  return null;
};

export default SafetechEncryption;
