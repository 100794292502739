import registerRootComponent from 'expo/build/launch/registerRootComponent';

// TODO figure out why this is needed for react native web to not fail in other files and fix it
import Cognito from 'utils/cognito/storage';
Cognito.getItem('FAKE');

// eslint-disable-next-line import/first
import { App } from './App';

registerRootComponent(App);
