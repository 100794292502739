import {
  Box,
  Icon,
  IconProps,
  Input,
  InputProps,
  Pressable,
  Text,
} from '@rbilabs/universal-components';
import { Platform } from 'react-native';

import { theme } from 'styles/configure-theme';

export const Container = Box.withConfig({
  _ios: {
    zIndex: Styles.zIndex.top,
  },
  _web: { zIndex: Styles.zIndex.top },
});

export const StyledAddressInput = Input.withConfig<{ inputCustomProps?: InputProps }>(
  ({ inputCustomProps }) =>
    inputCustomProps
      ? inputCustomProps
      : {
          fontSize: 'sm',
          boxSize: '$12',
          color: theme.token('text-default'),
          borderColor: theme.token('border-color-input-default'),
          borderWidth: 1,
        }
);

export const StyledInputIcon = Icon.withConfig<{ rightIconCustomProps?: IconProps }>(
  ({ rightIconCustomProps }) =>
    rightIconCustomProps
      ? rightIconCustomProps
      : {
          marginRight: '$4',
          color: theme.token('icon-form-input-default'),
        }
);

export const OptionsListWrapper = Box.withConfig({
  shadow: '2',
  _android: {
    style: {
      elevation: Styles.zIndex.top,
    },
  },
  width: 'full',
  marginTop: '$12',
  position: 'absolute',
  ...(Platform.OS === 'android' && { zIndex: Styles.zIndex.top }),
});

export const StyledOptionsListItem = Pressable.withConfig(
  ({ isHighlighted }: { isHighlighted?: boolean }) => ({
    paddingY: '$3',
    paddingX: '$4',
    borderWidth: 1,
    boxSize: '12',
    width: 'full',
    borderColor: theme.token('border-color-default'),
    backgroundColor: theme.token('background-input'),
    _pressed: {
      backgroundColor: theme.token('background-default'),
    },
    _hover: {
      backgroundColor: theme.token('background-default'),
    },
    _web: {
      cursor: 'pointer',
      paddingY: 0,
      justifyContent: 'center',
    },
    ...(isHighlighted && {
      backgroundColor: theme.token('background-default'),
    }),
  })
);

export const MainPredictionText = Text.withConfig({
  font: theme.token('text-style-form-options'),
  fontSize: 'xs',
  fontWeight: 'bold',
  paddingRight: '$2',
});
export const StyledItemText = Text.withConfig({
  flex: 1,
  font: theme.token('text-style-form-options'),
});
