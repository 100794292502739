import EventEmitter from 'eventemitter3';

import { EventName } from './types';

type EventListener<T> = (args: T) => void;

export * from './types';

// Emits an event and synchronously calls each event listener one by one
export const emitEvent = (eventName: EventName, args?: object) => {
  EventHub.getInstance().emitEvent(eventName, args);
};

// Add an event listener which will be called everytime the event is emitted
export const addEventListener = <T extends object>(
  eventName: EventName,
  listener: EventListener<T>
) => {
  EventHub.getInstance().addEventListener(eventName, listener);
};

// Remove an event listener
export const removeEventListener = <T extends object>(
  eventName: EventName,
  listener: EventListener<T>
) => {
  EventHub.getInstance().removeEventListener(eventName, listener);
};

// Remove all listeners bind to eventName
export const removeAllListeners = (eventName: EventName) => {
  EventHub.getInstance().removeAllListeners(eventName);
};

// This event listener is called only once per event
// i.e even if the event fires multiple time the listener will only be called on the first emission
export const addOnceEventListener = <T extends object>(
  eventName: EventName,
  listener: EventListener<T>
) => {
  EventHub.getInstance().addOnceEventListener(eventName, listener);
};

class EventHub {
  public emitEvent = (eventName: EventName, args?: object) => {
    this.eventEmitter.emit(eventName, args);
  };

  public addEventListener = <T extends object>(
    eventName: EventName,
    listener: EventListener<T>
  ) => {
    this.eventEmitter.addListener(eventName, listener);
  };

  public addOnceEventListener = <T extends object>(
    eventName: EventName,
    listener: EventListener<T>
  ) => {
    this.eventEmitter.once(eventName, listener);
  };

  public removeEventListener = <T extends object>(
    eventName: EventName,
    listener: EventListener<T>
  ) => {
    this.eventEmitter.removeListener(eventName, listener);
  };

  public removeAllListeners = (eventName: EventName) => {
    this.eventEmitter.removeAllListeners(eventName);
  };

  eventEmitter: EventEmitter;
  private constructor() {
    this.eventEmitter = new EventEmitter();
  }

  private static instance: EventHub;
  public static getInstance(): EventHub {
    if (!EventHub.instance) {
      EventHub.instance = new EventHub();
    }
    return EventHub.instance;
  }
}
