import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

export default function useCanUseApplePay() {
  const applePayPluginAvailable = useFlag(LaunchDarklyFlag.ENABLE_APPLE_PAY);
  const isApplePayWebEnabled = useFlag(LaunchDarklyFlag.ENABLE_APPLE_PAY_WEB);
  let canMakePayments = false;
  if (window.ApplePaySession) {
    try {
      canMakePayments = ApplePaySession.canMakePayments();
    } catch {
      canMakePayments = false;
    }
  }
  return canMakePayments && applePayPluginAvailable && isApplePayWebEnabled;
}
