import { createContext, useContext } from 'react';

const FormikFormControlNameContext = createContext('');
export const FormikFormControlNameContextProvider = FormikFormControlNameContext.Provider;

// This 'FormikFormControlState' is unfortunately duplicated context from native-base.
// Native base does not expose the form control state so we have to recreate it and expose it
// ourselves in the event that we need access to a wrapping form control's state outside
// of native-base components that are internally consuming native-base's form control context.
const FormikFormControlStateContext = createContext<{
  isDisabled?: boolean;
  isInvalid?: boolean;
}>({
  isInvalid: undefined,
  isDisabled: undefined,
});
export const FormikFormControlStateContextProvider = FormikFormControlStateContext.Provider;

export const useFormikFormControlState = () => useContext(FormikFormControlStateContext);

export const usePropsWithFormikFormControlName = <P extends { name?: string }>(props: P) => {
  const contextName = useContext(FormikFormControlNameContext);
  const name = props.name || contextName;
  return { ...props, name };
};
