import { Box, Header, Icon, IconButton, Pressable, Text } from '@rbilabs/universal-components';

import Picture from 'components/picture';
import SanityBlockRenderer from 'components/sanity-block-renderer';
import { primitive } from 'styles/constants/primitives';

const CARD_WIDTH = '152px';
const CARD_HEIGHT = '200px';
const IMAGE_WIDTH = '100px';
const IMAGE_HEIGHT = '100px';

export const RewardText = Header.withConfig({
  variant: 'headerThree',
  ellipsizeMode: 'tail',
  numberOfLines: 2,
  textAlign: 'center',
  paddingLeft: '$1',
  paddingRight: '$1',
  fontSize: 'xs',
  marginBottom: '$0',
  lineHeight: 'xs',
});

export const BackgroundImage = Picture.withConfig({
  width: '100%',
  height: '100%',
  position: 'absolute',
});

export const CardContainer = Box.withConfig({
  marginRight: '$2',
  overflow: 'hidden',
});

export const StyledClickableContainer = Pressable.withConfig<{ isLocked: boolean }>(p => ({
  width: CARD_WIDTH,
  height: CARD_HEIGHT,
  paddingTop: '$1',
  paddingX: '$3',
  paddingBottom: '$4',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: 'xl',
  background: p.isLocked ? Styles.color.grey.ten : primitive.$white,
}));

export const ImageWrapper = Box.withConfig({
  width: IMAGE_WIDTH,
  height: IMAGE_HEIGHT,
  justifyContent: 'center',
});

export const InfoContainer = Box.withConfig({
  fontFamily: 'Flame',
  fontSize: '$3.5',
  fontStyle: 'normal',
  fontWeight: '400',
  flexDirection: 'column',
  alignItems: 'center',
});

export const InlineAlertContainer = Box.withConfig({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: CARD_WIDTH,
  paddingX: '$4',
  paddingY: '$2',
  gap: '$2',
  position: 'absolute',
  bottom: '$0',
  background: Styles.color.grey.nine,
  borderBottomRadius: 'xl',
  fontStyle: 'normal',
});

export const InlineAlertSpacer = Box.withConfig({
  height: '$6',
});

export const InlineAlertText = Text.withConfig({
  fontSize: 'xs',
});

export const StyledRewardLock = Icon.withConfig({
  variant: 'locked',
  size: '$6',
  position: 'absolute',
  bottom: '$4',
  margin: '$2',
});

export const AlertIcon = Icon.withConfig({
  name: 'mi:info',
  size: '$4',
});

export const AddIconButton = IconButton.withConfig({
  padding: '$0',
  background: primitive.bk.$meltyYellow,
  borderColor: primitive.bk.$meltyYellow,
  width: '44px',
  height: '44px',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  bottom: '15px',
  _icon: {
    color: primitive.bk.$bbqBrown,
    size: '$5',
  },
});

export const IncentiveCardDescription = SanityBlockRenderer.withConfig({
  width: 'full',
  overflow: 'hidden',
  mb: '$2',
  textProps: {
    numberOfLines: 2,
    fontSize: 12,
    lineHeight: 16,
    ellipsizeMode: 'tail',
  },
});

export const PointsNeededText = Text.withConfig({
  fontSize: 'sm',
});

export const PointsNeededTextDetail = Text.withConfig({
  color: Styles.color.primary,
});
