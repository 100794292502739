import { Svg, SvgPath, SvgProps, SvgRect, registerSVG } from '../../../components/svg';

export function GiftCardNoBorder(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <SvgRect y="4" width="24" height="16" rx="2" fill="white" />

      <SvgPath
        d="M15.4364 10.11L14.7997 10.5501L14.163 10.9901C14.0667 11.0557 13.9543 11.0824 13.8459 11.0691C13.7376 11.057 13.6333 11.0062 13.5543 10.9206L12.9872 10.3026L12.42 9.6846C12.3076 9.56153 12.1538 9.5 12 9.5C11.8461 9.5 11.6923 9.56153 11.58 9.6846L11.0128 10.3026L10.4456 10.9206C10.3667 11.0062 10.2624 11.057 10.154 11.0691C10.0457 11.0811 9.93331 11.0557 9.83699 10.9901L9.20027 10.5501L8.56355 10.11C8.40036 9.99761 8.20773 10.0056 8.06193 10.0926C7.91613 10.1795 7.8158 10.3427 7.83587 10.5407L8.02046 12.3733L8.20506 14.2059C8.2171 14.3249 8.27328 14.4293 8.35621 14.5042C8.43915 14.5791 8.54884 14.6245 8.66922 14.6245H12H15.3307C15.4498 14.6245 15.5608 14.5791 15.6437 14.5042C15.7267 14.4293 15.7829 14.3249 15.7949 14.2059L15.9795 12.3733L16.1641 10.5407C16.1842 10.3427 16.0838 10.1795 15.938 10.0926C15.7909 10.0056 15.5996 9.99761 15.4364 10.11Z"
        fill="#FFAA00"
      />
    </Svg>
  );
}

registerSVG('GiftCardNoBorder', GiftCardNoBorder);
