import { IAuthCampaign } from '../components/auth-campaigns/hooks/use-auth-campaign';

export enum OverridableFields {
  PHONE = 'phoneNumber',
}

export type IOverrides = {
  [key in OverridableFields]: {
    required: boolean;
  };
};

export interface IStoreInfo {
  storeId: string;
  storeNumber: string;
  storeAddress: string;
}

export interface IFavoriteLocationField {
  isInvalid?: boolean;
  disabled?: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void | undefined;
}

export interface ISignup {
  skipRedirect?: boolean;
  authCampaignExtraFields?: IAuthCampaign['extraFields'];
  initialEmail?: string;
  shouldWaitLoginForVendor?: boolean;
}
