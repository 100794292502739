import { Box } from '@rbilabs/universal-components';

import { primitive } from 'styles/constants/primitives';

export const MobileWebNavigationBorder = Box.withConfig({
  width: 'full',
  zIndex: Styles.zIndex.overlay,
  background: Styles.color.white,
  style: {
    shadowRadius: 1,
    shadowOffset: {
      width: Styles.shadowOffsetX,
      height: -1,
    },
    shadowColor: primitive.$white,
  },
  _android: {
    style: {
      // this is a needed android propery in order to display the shadow.
      elevation: 10,
    },
  },
});
