import React, { FC, ReactNode, createContext, useContext, useState } from 'react';

// Define the context type for the ConfettiFriesAnimation
interface ConfettiFriesAnimationContextType {
  isAnimationComplete: boolean; // State to track if the animation is complete
  triggerAnimation: () => void; // Function to trigger the animation
  setIsAnimationComplete: (complete: boolean) => void; // Function to manually set the animation state
}

// Create the context
const ConfettiFriesAnimationContext = createContext<ConfettiFriesAnimationContextType | undefined>(
  undefined
);

// Props interface for the provider to accept children components
interface ConfettiFriesAnimationProviderProps {
  children: ReactNode;
}

// Provider component to hold the state and provide context values to its children
export const ConfettiFriesAnimationProvider: FC<ConfettiFriesAnimationProviderProps> = ({
  children,
}) => {
  const [isAnimationComplete, setIsAnimationComplete] = useState(true);

  // Function to trigger the animation
  const triggerAnimation = () => {
    setIsAnimationComplete(false);
  };

  return (
    // Provide the context values to the child components
    <ConfettiFriesAnimationContext.Provider
      value={{ isAnimationComplete, triggerAnimation, setIsAnimationComplete }}
    >
      {children}
    </ConfettiFriesAnimationContext.Provider>
  );
};

// Custom hook to use the ConfettiFriesAnimation context values
export const useConfettiFriesAnimation = (): ConfettiFriesAnimationContextType => {
  const context = useContext(ConfettiFriesAnimationContext);
  if (!context) {
    // Ensure the hook is used within the appropriate provider
    throw new Error(
      'useConfettiFriesAnimation must be used within a ConfettiFriesAnimationProvider'
    );
  }
  return context;
};
