import { Platform } from 'react-native';

import { ILocation } from '@rbi-ctg/frontend';

type NativeLocation = null | false | ILocation;

function isIOSWeb(navigatorPlatform: typeof navigator.platform): boolean {
  return (
    Platform.OS === 'web' &&
    (navigatorPlatform.includes('iPhone') ||
      navigatorPlatform.includes('iPad') ||
      navigatorPlatform.includes('iPod'))
  );
}

function joinCoordinates(location: NativeLocation): string {
  return location && location.lat ? `${location.lat},${location.lng}` : '';
}

export function nativeMapsHref(origin: NativeLocation, destination: false | ILocation = false) {
  const scheme = Platform.OS === 'ios' || isIOSWeb(navigator?.platform) ? 'maps' : 'https';

  return `${scheme}://maps.google.com/maps?saddr=${joinCoordinates(origin)}&daddr=${joinCoordinates(
    destination
  )}`;
}

declare interface IBillingAddress {
  city: string;
  street: string;
  country: string;
  postalCode: string;
  state: string;
}
export const buildFormattedBillingAddress = ({
  city,
  street,
  country,
  postalCode,
  state,
}: IBillingAddress) => {
  return `${street}, ${city}, ${state}, ${postalCode}, ${country}`;
};
