import { IProductHierarchy } from '@rbi-ctg/menu';
import { IRestaurant } from '@rbi-ctg/store';
import {
  ICartEntryFragment,
  LoyaltyPromotionType,
  LoyaltyQuestIncentiveType,
  ProviderType,
} from 'generated/graphql-gateway';
import { AllowedEvent } from 'state/mParticle/events/allowed-events';
import { OTPAuthDeliveryMethod } from 'utils/otp';

export interface ILogPageView {
  pathname: string;
  store: IRestaurant;
}

export interface ILogRBIEvent {
  (event: AllowedEvent): void;
}

export enum AuthenticationPath {
  CONTEXTUAL = 'Contextual',
  CONVENTIONAL = 'Conventional',
}

export interface ISignInEventOptions {
  phase: SignInPhases;
  success: boolean;
  otpMethod?: OTPAuthDeliveryMethod;
  authenticationMethod?: string;
  socialAuthenticationType?: ProviderType;
  authenticationPath?: AuthenticationPath;
}

export enum SignInPhases {
  START = 'start',
  COMPLETE = 'complete',
}

export interface ISignUpEventOptions {
  success: boolean;
}

export interface IGlobalAttributes {
  /** Current Screen the user is at (ie /cart/payment) */
  currentScreen: string;
  deviceTime: string;
  brand?: string;
  region?: string;
  env?: string;
  serviceMode?: string;
  pickupMode?: string;
  appBuild?: string;
  browserType?: string;
  browserVersion?: string;
  isMobileWeb?: string;
}

// booleans need to be stringified for mParticle
export interface IAmplitudeAttributes extends IUtmParamsAttributes {
  'Android Location Permissions'?: string;
  'App Tracking Status'?: string;
  'Date of Birth'?: string;
  'Email Opt In'?: string;
  'IOS Location Permissions'?: string;
  'Join Date'?: string;
  'Legacy User'?: string;
  'Location Services'?: string;
  'RBI Cognito ID'?: string;
  'Size Preference'?: string;
  'Snack Preference'?: string;
  'Time Preference'?: string;
  'Type Preference'?: string;
  $Age?: number;
  $City?: string;
  $Mobile?: string;
  $State?: string;
  $Zip?: string;
  Age?: number;
  brand?: string;
  env?: string;
  favoriteStores?: string;
  language?: string;
  Locale?: string;
  locationPermission?: string;
  marketingEmail?: string;
  marketingPush?: string;
  orderStatus?: string;
  region?: string;
  rewardsEmail?: string;
  rewardsPush?: string;
  Timezone?: string;
}

export interface IAmplitudeUserIdentity {
  email: string;
  customerid: string;
  other3?: string;
}

export enum HTTPCode {
  SUCCESS = 200,
  NO_HTTP_COVERAGE = -1,
  ACTIVE_IDENTITY_REQUEST = -2,
  ACTIVE_SESSION = -3,
  VALIDATION_ISSUE = -4,
  NATIVE_IDENTITY_REQUEST = -5,
  LOGGING_DISABLED_OR_MISSING_API_KEY = -6,
  TOO_MANY_REQUESTS = 42,
}

export type ExcludesNull = <T>(x: T | null) => x is T;

export interface IAmplitudeProduct {
  'Item Level': ProductItemType;
  cartId: string;
  comboChild: string;
  custom_attributes: { [key: string]: string | number | boolean };
  id: string;
  isDonation: string;
  isExtra: string;
  rewardItem?: string;
  L1?: string;
  L2?: string;
  L3?: string;
  L4?: string;
  L5?: string;
  L6?: string;
  name: string;
  price: number;
  quantity: number;
  sublevelItems: string;
  total_product_amount: number;
}

export enum ProductItemType {
  Parent = 'Parent',
  Child = 'Child',
}

export interface IAmplitudeProductDetails {
  name?: string;
  sanityId: string;
  quantity?: number;
  price?: number;
  bkpn?: string | null;
  productType: string;
  parentName?: string;
  parentSanityId?: string;
  foodType?: string;
  category?: string;
  segment?: string;
  platform?: string;
  menuItemName?: string;
  isExtra?: boolean | string;
}

export interface ICartEntryLoggable extends ICartEntryFragment {
  children?: ICartEntryLoggable[];
  productHierarchy?: IProductHierarchy | null;
  productNumber?: string;
  cartId?: string;
}
export interface IAmplitudePurchaseEventAttributes {
  brand: string;
  env: string;
  region: string;
  'Card Type': string;
  'Cart Data': string;
  'CC Token': string | null;
  'Coupon Applied': string;
  'Coupon ID': string;
  'Device Time': string;
  'Has Upsell': boolean;
  'Is Kiosk': boolean;
  'Is Loyalty': boolean;
  'Payment Type': string;
  'Pickup Mode': string;
  'Product Count': number;
  'Restaurant Address': string | null;
  'Restaurant City': string | null;
  'Restaurant Country': string | null;
  'Restaurant Drink Station Type': string | null;
  'Restaurant Drive Thru Lane Type': string | null;
  'Restaurant Franchise Group Id': number | null;
  'Restaurant Franchise Group Name': string | null;
  'Restaurant Front Counter Closed': boolean | null;
  'Restaurant Has Breakfast': boolean | null;
  'Restaurant Has Burgers For Breakfast': boolean | null;
  'Restaurant Has Curbside': boolean | null;
  'Restaurant Has Dine In': boolean | null;
  'Restaurant Has Drive Thru': boolean | null;
  'Restaurant Has Front Counter Closed': boolean | null;
  'Restaurant Has Home Delivery': boolean | null;
  'Restaurant Has Mobile Ordering': boolean | null;
  'Restaurant Has Parking': boolean | null;
  'Restaurant Has Playground': boolean | null;
  'Restaurant Has Take Out': boolean | null;
  'Restaurant Has Wifi': boolean | null;
  'Restaurant ID': string | null;
  'Restaurant Latitude': number | null;
  'Restaurant Longitude': number | null;
  'Restaurant Name': string | null;
  'Restaurant Number Drive Thru Windows': number;
  'Restaurant Number': string | null;
  'Restaurant Parking Type': string | null;
  'Restaurant Playground Type': string | null;
  'Restaurant POS': string | null;
  'Restaurant Postal Code': string | null;
  'Restaurant State/Province Name': string | null;
  'Restaurant Status': string | null;
  'Service Mode': string;
  'Source Page': string;
  'Tax Amount': number;
  'Timed Fire Minutes': string;
  'Total Amount': number;
  'Transaction Order Number ID': string;
  'Transaction POS': string;
  'Transaction RBI Cloud Order ID': string;
  'Upsell Total': string;
  'Value Threshold 10 Met'?: boolean;
  'Value Threshold 15 Met'?: boolean;
  'Value Threshold 20 Met'?: boolean;
  'Value Threshold 5 Met'?: boolean;
  baseDeliveryFeeAmount?: number;
  branch_service_mode: string;
  Currency: string;
  customer_event_alias: string;
  deliveryDiscountAmount?: number;
  deliveryFeeAmount?: number;
  deliveryGeographicalFeeAmount?: number;
  deliveryServiceFeeAmount?: number;
  deliverySmallCartFeeAmount?: number;
  deliverySurchargeFeeAmount?: number;
  quotedFeeAmount?: number;
  Rewards: string | null;
  roundUpAmount: number;
  totalDeliveryOrderFeeAmount?: number;
}

export interface IAmplitudeUniversalAttributes extends IUtmParamsAttributes {
  'Service Mode': string;
  'Pickup Mode': string;
  'Source Page': string;
  isSmallScreen: boolean;
  isLoyaltyUser: boolean;
  currentBuild: string;
}

export interface IAmplitudeSublevelItem {
  id: string;
  quantity: number;
}

// Track the modification of item, combo slot and picker aspect.
// These values are initially false and is set to true if the user
// changes these values away from the initial state.
export interface IAddToCartSelectionAttributes {
  pickerAspectSelection: boolean;
  comboSlotSelection: boolean;
  itemModified: boolean;
}

export interface IUtmParams {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

interface IUtmParamsAttributes {
  'UTM Source'?: string;
  'UTM Medium'?: string;
  'UTM Campaign'?: string;
  'UTM Term'?: string;
  'UTM Content'?: string;
  utmCampaign?: string;
  utmContent?: string;
  utmMedium?: string;
  utmSource?: string;
  utmTerm?: string;
}

/**
 * Comes from Amplitude
 * https://github.com/amplitude/Amplitude-TypeScript/blob/6262330785394f88ffbd1ed1aaa72f48e7d50f3e/packages/analytics-types/src/event.ts#LL37C1-L37C1
 */
export type ValidPropertyType =
  | number
  | string
  | boolean
  | Array<string | number>
  | {
      [key: string]: ValidPropertyType;
    };

export interface IAmplitudeLoyaltyDocument {
  name: string;
  sanityId: string;
  loyaltyEngineId: string;
  type: string;
  incentiveType: LoyaltyPromotionType | LoyaltyQuestIncentiveType;
  dayParts?: string[];
  engineId?: string; // same as loyaltyEngineId - kept for backwards compatibility
  incentiveSanityId?: string; // same as sanityId - kept for backwards compatibility
  questStartDate?: string;
  questEndDate?: string;
}
