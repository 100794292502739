import * as React from 'react';

import { Divider, Header, ScrollView, VStack } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import SanityBlockRenderer from 'components/sanity-block-renderer';

import { QuestIncentive } from './quest-incentive';
import { QuestSteps } from './quest-steps';
import { QuestTerms } from './quest-terms';
import { QuestStepsSectionWrapper } from './styled';
import { IQuestDetailContentViewProps } from './types';

export const QuestDrawerContent = ({
  cmsQuestDetail,
  cmsQuestDetailUI,
  onCloseDrawer,
}: IQuestDetailContentViewProps) => {
  const { formatMessage } = useIntl();

  const { howToRedeem } = cmsQuestDetail;
  const howToRedeemSanityContent = howToRedeem?.localeRaw;

  return (
    <ScrollView>
      <VStack space="$6">
        <QuestStepsSectionWrapper>
          <Header margin={0} variant="headerThree">
            {formatMessage({ id: 'howItWorks' })}
          </Header>
          <SanityBlockRenderer content={howToRedeemSanityContent} />
          <QuestSteps
            cmsQuestRequirementById={cmsQuestDetail.requirementByCmsId}
            cmsQuestDetail={cmsQuestDetail}
            cmsQuestDetailUI={cmsQuestDetailUI}
            onCloseDrawer={onCloseDrawer}
          />
        </QuestStepsSectionWrapper>
        <QuestIncentive
          cmsIncentive={cmsQuestDetail.incentive}
          cmsQuestDetail={cmsQuestDetail}
          onCloseDrawer={onCloseDrawer}
        />
        <Divider />
        <QuestTerms cmsQuestTerms={cmsQuestDetail.moreInfo} />
      </VStack>
    </ScrollView>
  );
};
