import React, { PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Experiment, Variant } from '@amplitude/experiment-react-native-client';

import { useApiKey } from 'hooks/configs/use-api-key';
import { useCognitoId } from 'state/auth/auth-sync-module';
import { EventTypes, useMParticleContext } from 'state/mParticle';
import { getDeviceId } from 'utils/device-id';
import { minDevLogLevel } from 'utils/environment';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import logger, { LogLevel } from 'utils/logger';
import noop from 'utils/noop';

import { RunningExperiments } from './running-experiments';
import { IExperimentsContext, VariantOptions } from './types';

export { RunningExperiments } from './running-experiments';

const Context = React.createContext<IExperimentsContext>({
  isLoading: false,
  getVariantValue: (_flag: string | RunningExperiments) => undefined,
  logExposure: (_flag: RunningExperiments) => void 0,
  variant: (_flag: RunningExperiments, _expectedVariant?: string, _options?: VariantOptions) =>
    false,
});

export const useExperiments = () => useContext(Context);

/** CAREFUL: only use for rare circumstances where its OK to read experiments outside of react
 * TODO: after amplitude refactor experiments will be available statically
 */
export const staticExperiments: { current: IExperimentsContext | null } = { current: null };

/*
 * The Experiments API logic follows a few basic stepstones to initialize:
 *
 * 1. We preload the variants in state with variants stored in local storage.
 * 2. We initialize the SDK with the apiKey from config
 * 3. We "fetch" the variants assigned to this user from amplitude. The fetch call is where amplitude does its complex logic to decide what experiments the user is assigned to.
 *    - We will re-run this any time the congitoId changes. In practice this should only happen when going from auth<->unauth
 * 4. We implement our own `variant` function so we can implement the `automaticExposureTracking` logic - but work around the bugs (or features?) that seem to not handle exposures while fetch calls are outbound
 */
const ExperimentsProviderWithCognitoId = React.memo<
  PropsWithChildren<{ apiKey: string; cognitoId: string | undefined }>
>(function ExperimentsProviderWithCognitoId({ apiKey, cognitoId, children }) {
  const flagExposuresHandled = useRef(new Map<string, boolean>());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [variants, setVariants] = useState<Record<string, Variant>>(
    LocalStorage.getItem(StorageKeys.AMPLITUDE_VARIANTS) ?? {}
  );

  const [experimentApi] = useState(() => {
    return Experiment.initialize(apiKey, {
      debug: __DEV__ && minDevLogLevel() <= LogLevel.debug,
      // since we cache our own flags in local storage, there are strange
      // race conditions with the automaticExposureTracking. So instead of trying
      // to fight that, i've copied their logic on how to ensure we only log exposure
      // for a variant once via the above flagExposuresHandled ref Map.
      automaticExposureTracking: false,
      userProvider: {
        getUser: async () => {
          // we have to lowercase the device id for amplitude to resolve users who do not have a
          // user id yet, but have events with this device id. Our mparticle setup currently has
          // a lambda that lowercases the device id, but this request goes straight to amplitude
          // and doesnt get that lambda call.
          const deviceId = await getDeviceId();

          return {
            device_id: deviceId.toLowerCase(),
          };
        },
      },
    });
  });

  // Whenever the user's cognitoId changes - we need to fetch variants
  // to allocate the user to any new experiments they may or may not be included in.
  useEffect(() => {
    setIsLoading(true);
    experimentApi
      .fetch({ user_id: cognitoId })
      .then(() => {
        const newVariants = experimentApi.all();
        setVariants(oldVariants => {
          // avoid massive re-renders if flags aren't effectively changing
          if (oldVariants && JSON.stringify(newVariants) === JSON.stringify(oldVariants)) {
            return oldVariants;
          }
          return newVariants;
        });
        LocalStorage.setItem(StorageKeys.AMPLITUDE_VARIANTS, newVariants);
      })
      // should we log this? not sure there is much actionable to do.
      // if users fail to get variants - they won't be exposed to any tests
      // and the control/treatment parties should have statistical parity in their
      // results for any given outtages.
      .catch(noop)
      .finally(() => setIsLoading(false));
  }, [experimentApi, cognitoId]);

  const { logEvent } = useMParticleContext();

  // Override the typical getVariant function to utilize our local cache approach.
  const value = useMemo(
    () => ({
      isLoading,

      getVariantValue: (flagKey: string | RunningExperiments) => {
        return variants[flagKey];
      },

      logExposure(flagKey: string | RunningExperiments) {
        const foundVariant = variants[flagKey];
        const flagAndValueKey = flagKey + foundVariant?.value;

        if (!flagExposuresHandled.current.has(flagAndValueKey) && foundVariant) {
          logEvent('$exposure', EventTypes.Other, {
            flag_key: flagKey,
            variant: foundVariant.value,
          });
          flagExposuresHandled.current.set(flagAndValueKey, true);
          logger.debug(
            `[Experiment] Manually logging exposure for "${flagKey}", variant value: "${foundVariant.value}"`
          );
        }
      },

      variant: (
        flagKey: string,
        expectedVariant: string,
        options = { skipExposureLogging: false }
      ) => {
        const foundVariant = variants[flagKey];
        const flagAndValueKey = flagKey + foundVariant?.value;

        // Logging an exposure must only happen when these are explicitly true.
        // 1. We haven't logged exposure before. Only log once per key/value.
        // 2. We have the variant assigned to the user.
        // 3. The caller is not explicitly skipping logging (this may happen when the caller needs to do something based on a flag that isn't explicitly tied to the experience they are testing)
        if (
          !flagExposuresHandled.current.has(flagAndValueKey) &&
          foundVariant &&
          options.skipExposureLogging !== true
        ) {
          logEvent('$exposure', EventTypes.Other, {
            flag_key: flagKey,
            variant: foundVariant.value,
          });
          logger.debug(
            `[Experiment] Automatically logging exposure for "${flagKey}", variant value: "${foundVariant.value}"`
          );
          flagExposuresHandled.current.set(flagAndValueKey, true);
        }

        return foundVariant?.value === expectedVariant;
      },
    }),

    [variants, isLoading, logEvent]
  );

  staticExperiments.current = value;

  return <Context.Provider value={value}>{children}</Context.Provider>;
});

export const ExperimentsProvider = React.memo(function WithCognitoId(props: PropsWithChildren<{}>) {
  const cognitoId = useCognitoId();
  const apiKey = useApiKey({ key: 'amplitudeExperiments' });

  if (!apiKey && __DEV__) {
    throw new Error(`amplitudeExperiments apiKey is missing, but is required for this runtime.
Please verify that the amplitudeExperiments apiKey is published in sanity for this brand/env, and rebuild the .sanity.json config file`);
  }

  if (!apiKey) {
    return <>{props.children}</>;
  }

  return (
    <ExperimentsProviderWithCognitoId
      cognitoId={cognitoId ?? undefined}
      apiKey={apiKey}
      {...props}
    />
  );
});

// export for testing purposes only
export const __TESTING_EXPORT__ = ExperimentsProviderWithCognitoId;
