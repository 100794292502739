import { fetchSanityConfig, writeToken } from './fetch-sanity-config';
import { fetchSanityNavbar, ISanityNavigationTab } from './fetch-sanity-navbar';
import { Io, Mode } from './types';

interface IConfigs {
  [configKey: string]: any;
}

interface IDataByRegion {
  [region: string]: IConfigs;
}

const CYAN = '\x1b[36m%s\x1b[0m'

const formatSharedConfigData = ({
  buildRegionConfig = {},
  env,
  region,
  sanityProjectId,
  mode
}: {
  buildRegionConfig: any;
  env: string;
  region: string;
  sanityProjectId: string;
  mode: Mode
}) => {
  const sharedConfigs: IConfigs = {
    brand: 'bk',
    env: writeToken(env),
    buildRegion: writeToken(region),
    sanityProjectId: writeToken(sanityProjectId),

    // these are fixed at build-time and shouldn't change
    ...mode === 'prebuild' ? {
      platform: writeToken(process.env.REACT_APP_RBI_PLATFORM || 'app'),
      graphqlEnv: writeToken(process.env.REACT_APP_RBI_GRAPHQL_ENV),
      graphqlGatewayEnv: writeToken(process.env.REACT_APP_RBI_GRAPHQL_GATEWAY_ENV),
      commitRef: writeToken(process.env.CIRCLE_SHA1 || process.env.COMMIT_REF),
      releaseTimestamp: writeToken(process.env.REACT_APP_RELEASE_TIMESTAMP),
      sanityEnv: writeToken(process.env.REACT_APP_RBI_SANITY_ENV),
      // TODO: RN - locale switching - should be regionalized, but breaks CI if not shared
      sanityDataset: writeToken(process.env.REACT_APP_SANITY_DATASET),
    } : null
  };
  /**
   * NOTE: the following config values could theoretically be changed in Sanity for different regions
   *  HOWEVER, these values must be the same for all supported regions in a single app because they
   *  are required at build time and cannot be changed during runtime, even if a user switches locales
   *  ex. google maps api key is required in app.config and at this time cannot/should not be changed
   *
   * If ever these values diverge in Sanity, we are forcing them to be the same here so as not to break the app
   * Defaulting to using the build region as the source of truth for shared values
   *
   * !!keys added here should then be removed in the removeForceSharedConfigValuesFromRegionData fn!!
   *
   * TODO: RN - locale switching -- add link to docs explaining this better
   */
  Object.keys(buildRegionConfig).forEach(configSection => {
    // NOTE: add any more config sections here
    switch (configSection) {
      case 'aws':
        sharedConfigs.aws = {
          gqlApiUrl: writeToken(buildRegionConfig.aws.gqlApiUrl),
          gqlGatewayApiUrl: writeToken(buildRegionConfig.aws.gqlGatewayApiUrl),
          region: writeToken(buildRegionConfig.aws.region),
          userPoolClientId: writeToken(buildRegionConfig.aws.userPoolClientId),
          userPoolId: writeToken(buildRegionConfig.aws.userPoolId),
        };
        break;
      case 'apple':
        sharedConfigs.apple = {
          merchantId: writeToken(buildRegionConfig.apple.merchantId),
          migrationMerchantId: writeToken(buildRegionConfig.apple.migrationMerchantId),
          prepaidMerchantId: writeToken(buildRegionConfig.apple.prepaidMerchantId),
          migrationPrepaidMerchantId: writeToken(
            buildRegionConfig.apple.migrationPrepaidMerchantId
          ),
          paymentsNetworks: writeToken(buildRegionConfig.apple.paymentsNetworks, []),
        };
        break;
      case 'apiKeys':
        sharedConfigs.apiKeys = {
          googleMaps: writeToken(buildRegionConfig.apiKeys.googleMaps),
          googleMapsWeb: writeToken(buildRegionConfig.apiKeys.googleMapsWeb),
          googleMapsIOS: writeToken(buildRegionConfig.apiKeys.googleMapsIOS),
          googleMapsAndroid: writeToken(buildRegionConfig.apiKeys.googleMapsAndroid),
        };
        break;
      default:
    }
  });
  return sharedConfigs;
};

// NOTE: deleted keys here should be the same as the keys added in the buildRegionConfig section of formatSharedConfigData
const removeForceSharedConfigValuesFromRegionData = (
  dataByRegion: IDataByRegion,
  supportedRegions: string[]
) => {
  return supportedRegions.reduce((updatedDataByRegion, region) => {
    const updatedDataForRegion = { ...dataByRegion[region] };
    delete updatedDataForRegion.aws;
    delete updatedDataForRegion.apple;
    delete updatedDataForRegion.apiKeys.googleMaps;
    delete updatedDataForRegion.apiKeys.googleMapsWeb;
    delete updatedDataForRegion.apiKeys.googleMapsIOS;
    delete updatedDataForRegion.apiKeys.googleMapsAndroid;
    updatedDataByRegion[region] = updatedDataForRegion;
    return updatedDataByRegion;
  }, {} as IDataByRegion);
};

const formatNavbarTabAssetsBySupportedRegion = (
  supportedDatasetRegions: string[],
  sanityNavbarsBySupportedRegion: { [key in string]: ISanityNavigationTab[] }
) => {
  // stringify the organization of each set of assets by their respective region
  const formattedNavbarTabAssetsByRegion = supportedDatasetRegions.reduce(
    (tabAssetsByRegionStr, supportedRegion) => {
      // create a stringified version of each asset object where
      // key: path to asset string, value: require actual asset
      const formattedNavbarTabAssetsForRegion = sanityNavbarsBySupportedRegion[supportedRegion]
        .reduce((entries, tab) => {
          tab.iconPath && entries.push(`  ['${tab.iconPath}']: require('./${tab.iconPath}')`);
          tab.iconActivePath &&
            entries.push(`  ['${tab.iconActivePath}']: require('./${tab.iconActivePath}')`);
          tab.launchDarklyAlternateMobileTab.iconPath &&
            entries.push(
              `  ['${tab.launchDarklyAlternateMobileTab.iconPath}']: require('./${tab.launchDarklyAlternateMobileTab.iconPath}')`
            );
          tab.launchDarklyAlternateMobileTab.iconActivePath &&
            entries.push(
              `  ['${tab.launchDarklyAlternateMobileTab.iconActivePath}']: require('./${tab.launchDarklyAlternateMobileTab.iconActivePath}')`
            );
          return entries;
        }, [] as string[])
        .join(',\n');
      tabAssetsByRegionStr += `
    ${supportedRegion}: {
      ${formattedNavbarTabAssetsForRegion}
    },
      `;
      return tabAssetsByRegionStr;
    },
    ''
  );

  return formattedNavbarTabAssetsByRegion;
};

/** this function is designed to get data at runtime in react native (for pre-prod puproses) as well as for prebuild and thus its a little funky */
export async function fetchSanityData(
  region: string,
  env: string,
  workingDirectory: string,
  supportedRegions: string[],
  mode: Mode,
  io: Io
) {
  const sanityProjectId = 'czqk28jt';

  const supportedDatasetRegions = supportedRegions.map(r => r.toUpperCase());

  const sanityConfigs = await Promise.all(
    supportedDatasetRegions.map(supportedRegion =>
      fetchSanityConfig(sanityProjectId, `${env}_bk_${supportedRegion}`.toLowerCase(), io)
    )
  );

  const sanityNavbars = mode === 'prebuild' ? await Promise.all(
    supportedDatasetRegions.map(supportedRegion =>
      fetchSanityNavbar({
        sanityProjectId,
        sanityDataset: `${env}_bk_${supportedRegion}`.toLowerCase(),
        workingDirectory,
        io
      })
    )
  ) : [];

  const { dataByRegion, sanityNavbarsBySupportedRegion } = supportedDatasetRegions.reduce(
    (acc, supportedRegion, i) => {
      const sanityConfigsForRegion = sanityConfigs[i];
      const sanityNavbarForRegion = sanityNavbars[i];
      acc.sanityNavbarsBySupportedRegion[supportedRegion] = sanityNavbarForRegion;
      acc.dataByRegion[supportedRegion] = {
        ...sanityConfigsForRegion,
        tabs: sanityNavbarForRegion,
        country: supportedRegion,
      };
      return acc;
    },
    {
      dataByRegion: {} as { [key in string]: any },
      sanityNavbarsBySupportedRegion: {} as {
        [key in string]: ISanityNavigationTab[];
      },
    }
  );

  // not all data is region-specific so let's get all shared config values together
  // buildRegionConfig is source of truth for values that COULD be configured differently between regions, but shouldn't be
  const sharedConfigData = formatSharedConfigData({
    env,
    region: region.toUpperCase(),
    buildRegionConfig: dataByRegion[region.toUpperCase()],
    sanityProjectId,
    mode
  });
  // removes forced shared config values from the region-specific configs to avoid in-app confusion
  const finalizedDataByRegion = removeForceSharedConfigValuesFromRegionData(
    dataByRegion,
    supportedDatasetRegions
  );

  const finalConfig = { ...sharedConfigData, ...finalizedDataByRegion }

  if (mode === 'runtime') {
    delete finalConfig.tabs // not changing assets at runtime and can't change tabs either
  }

  if (mode === 'prebuild') {
    // we aren't going to change assets at runtime (not sure if possible, and they don't really ever change, maybe shouldn't be in sanity anymore...)
    const assets = `
  /* eslint-disable */
export const sanityAssets = {
${formatNavbarTabAssetsBySupportedRegion(supportedDatasetRegions, sanityNavbarsBySupportedRegion)}
};
  `;

    await io.writeFile!(io.pathJoin!(workingDirectory, '/assets.js'), assets);
    await io.writeFile!(
      io.pathJoin!(workingDirectory, `/.sanity.json`),
      JSON.stringify(finalConfig, null, 2)
    );
  }

  console.log(CYAN, '[RN Sanity Prebuild] Finished Sanity Prebuild');
  return finalConfig;
}
