import { Svg, SvgImage, SvgProps, registerSVG } from '../../../components/svg';

export function GooglePayNoBorder(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 128 80">
      <SvgImage href={require('./GooglePayNoBorder.png')} />
    </Svg>
  );
}

registerSVG('GooglePayNoBorder', GooglePayNoBorder);
