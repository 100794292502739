import { ApolloLink, Operation } from '@apollo/client';
import { hasDirectives, removeDirectivesFromDocument } from '@apollo/client/utilities';

export const AUTH_REQUIRED_DIRECTIVE = 'authRequired';
export const AUTHORIZED_KEY = 'authorized';

/**
 * Accepts an Apollo Operation and will determine if it is auth protected by
 * checking if the `@authRequred` directive is present on the query document.
 *
 * @param {Operation} operation
 * The operation under question
 *
 * @returns {boolean}
 * Whether or not this operation is auth protected
 */
export const isRequestAuthProtected = (operation: Operation): boolean => {
  return hasDirectives([AUTH_REQUIRED_DIRECTIVE], operation.query);
};

/**
 * Accepts an Apollo Operation and will determine if the user is logged in when
 * the operation was invoked by checking if the `AUTHORIZED_KEY` exists on the
 * operation context.
 *
 * @param {Operation} operation
 * The operation under question
 *
 * @returns {boolean}
 * Whether or not the user is logged on at the time this operation is invoked
 */
export const isUserLoggedIn = (operation: Operation): boolean => {
  const context = operation.getContext();
  return context[AUTHORIZED_KEY];
};

/**
 * An Apollo Link used to strip the `@authRequired` directive from
 * query documents.
 */
export const stripAuthDirective = new ApolloLink((operation, forward) => {
  const strippedQuery = removeDirectivesFromDocument(
    [{ name: AUTH_REQUIRED_DIRECTIVE }],
    operation.query
  );

  if (strippedQuery) {
    operation.query = strippedQuery;
  }

  return forward(operation);
});
