import React from 'react';

import { Box, Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { theme } from 'styles/configure-theme';
import { primitive } from 'styles/constants/primitives';

export const StyledChooseLocation = Box.withConfig<{ reversedUI: boolean }>(p => ({
  flexDirection: 'row',
  justifySelf: 'flex-end',
  marginLeft: '2',
  fontSize: {
    base: 'sm',
    md: 'md',
  },
  _text: {
    color: p.reversedUI ? theme.token('text-reversed') : primitive.bk.$bbqBrown,
  },
  marginRight: {
    md: '4',
  },
}));

interface ChooseLocationType {
  reversedUI: boolean;
  testID?: string;
}

const ArrowRightIcon = Icon.withConfig({
  variant: 'back',
  size: {
    base: 'xs',
    md: 'sm',
  },
  mt: {
    base: '$1',
    md: '$0',
  },
  style: {
    transform: [{ rotate: '180deg' }],
  },
});

const ChooseLocation = ({ reversedUI, testID }: ChooseLocationType) => {
  const { formatMessage } = useIntl();
  const chooseYourLocation = formatMessage({ id: 'chooseYourLocation' });

  return (
    <StyledChooseLocation
      testID={testID}
      reversedUI={reversedUI}
      accessibilityLabel={chooseYourLocation}
      accessibilityHint={formatMessage({ id: 'clickToSelectLocation' })}
      paddingTop={'$1'}
    >
      {chooseYourLocation}
      <ArrowRightIcon />
    </StyledChooseLocation>
  );
};

export default ChooseLocation;
