import { separateBreakfastMenu } from 'pages/menu/main-menu-page/utils';
import { DAY_PART_SELECTIONS } from 'state/menu/main-menu/types';

import { IGetTabbedMenuData } from './types';

export function getTabbedMenuData({ dayPartMenu, mainMenuItems }: IGetTabbedMenuData) {
  if (dayPartMenu === DAY_PART_SELECTIONS.ALL_KEY || !dayPartMenu) {
    return mainMenuItems;
  }

  const { menuData, breakfastMenuData } = separateBreakfastMenu(mainMenuItems);

  const shouldRenderBreakfast =
    !!breakfastMenuData?.length && dayPartMenu === DAY_PART_SELECTIONS.BREAKFAST_KEY;
  return shouldRenderBreakfast ? breakfastMenuData : menuData;
}
