import { ICartEntry, IPrices } from '@rbi-ctg/menu';
import { IRestaurant } from '@rbi-ctg/store';
import { getPosVendorFromStore } from 'hooks/menu/get-pos-vendor-from-store';
import { ServiceMode } from 'state/service-mode';
import { CartEntryType } from 'utils/cart/types';
import { PosVendors } from 'utils/vendor-config';

import { itemIsAvailable, itemOptionModifierPluIsAvailable } from './menu-availability';

// This "availability" is actually very different from availability of IItem and friends
export const cartEntryIsAvailable = (
  cartEntry: ICartEntry,
  vendor: PosVendors | null,
  prices: IPrices,
  item?: ICartEntry
): boolean => {
  const isAvailable = (entry: ICartEntry) => {
    switch (entry.type) {
      case CartEntryType.combo:
        // we don't use comboIsAvailable here
        return itemIsAvailable(entry, vendor, prices);
      case CartEntryType.item:
        return itemIsAvailable(entry, vendor, prices);
      case CartEntryType.itemOptionModifier:
        // we only really need to know if the plu is available
        if (!item || !vendor) {
          return false;
        }
        return itemOptionModifierPluIsAvailable({
          item,
          itemOptionModifier: entry,
          vendor,
          prices,
        });
      case CartEntryType.comboSlot:
      case CartEntryType.itemOption:
        return true;
      case CartEntryType.offerCombo:
      case CartEntryType.offerItem:
        return false;
      default:
        return false; // this shouldn't happen
    }
  };

  // hacking in a way to remember what the associated IItem is at every step
  const i = cartEntry.type === CartEntryType.item ? cartEntry : item;

  return (
    isAvailable(cartEntry) &&
    (cartEntry.children || []).every(entry => cartEntryIsAvailable(entry, vendor, prices, i))
  );
};

export const getUnavailableCartEntries = (
  cartEntries: ICartEntry[],
  restaurant: IRestaurant,
  prices: IPrices,
  serviceMode?: ServiceMode
) => {
  const vendor = getPosVendorFromStore(restaurant, serviceMode);
  const unavailableEntries = cartEntries.filter(
    (cartEntry: ICartEntry) => !cartEntryIsAvailable(cartEntry, vendor, prices)
  );

  return unavailableEntries;
};
