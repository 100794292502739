import { IRestaurant } from '@rbi-ctg/store';

import { StorePermissions, curriedGetStoreStatus } from './types';

/**
 * Creates a function to get a store's status for store selection 1.0
 *
 * @param {ServiceMode} selectedServiceMode the default service mode to check against
 * @param {ServiceMode} serviceMode service mode to check
 * @param {ServiceModeStatus} serviceModeStatuses the service mode status of the store
 */
export const curriedGetStoreStatusV1: curriedGetStoreStatus = ({
  store,
  serviceModeStatuses,
  selectedServiceMode,
}) => (serviceMode = selectedServiceMode) => {
  const noStoreSelected =
    !store ||
    (store &&
      'selectedUnavailableStoreNumber' in store &&
      store.selectedUnavailableStoreNumber === null);

  if (noStoreSelected || !serviceMode) {
    return StorePermissions.NO_STORE_SELECTED;
  }

  const serviceModeStatus = serviceModeStatuses[serviceMode];
  const unavailableStoreSelected = store && !('hasMobileOrdering' in store);

  if (unavailableStoreSelected) {
    return StorePermissions.OPEN_AND_UNAVAILABLE;
  }

  return serviceModeStatus.available && !serviceModeStatus.disabled
    ? StorePermissions.OPEN_AND_AVAILABLE
    : StorePermissions.CLOSED;
};

export const initialStoreProxyState: IRestaurant = {
  _id: null,
  isNonTraditional: false,
  accessInstructions: '',
  chaseMerchantId: null,
  curbsideHours: null,
  deliveryHours: null,
  diningRoomHours: null,
  distanceInMiles: null,
  drinkStationType: null,
  driveThruHours: null,
  driveThruLaneType: null,
  email: null,
  environment: null,
  fastestServiceMode: null,
  franchiseGroupId: null,
  franchiseGroupName: null,
  frontCounterClosed: null,
  hasBreakfast: null,
  hasBurgersForBreakfast: null,
  hasCurbside: null,
  hasDelivery: null,
  hasDineIn: null,
  hasDriveThru: null,
  hasMobileOrdering: null,
  hasParking: null,
  hasPlayground: null,
  hasTakeOut: null,
  hasWifi: null,
  hasLoyalty: null,
  isDarkKitchen: null,
  isHalal: null,
  isOrderEtaEnabled: false,
  latitude: null,
  longitude: null,
  mobileOrderingStatus: null,
  name: null,
  number: null,
  orderEtaValue: null,
  parkingType: null,
  phoneNumber: null,
  physicalAddress: null,
  playgroundType: null,
  pos: null,
  posRestaurantId: null,
  restaurantImage: null,
  restaurantPosData: null,
  status: null,
  amenities: [],
  vatNumber: null,
  available: false,
  hasSelection: true,
  isAvailable: null,
  isFavorite: null,
  isRecent: null,
  selectedUnavailableStoreName: '',
  selectedUnavailableStoreNumber: undefined,
};
