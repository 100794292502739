import * as React from 'react';
import { PropsWithChildren, useContext, useMemo } from 'react';

import type { IQuest, IQuestContext } from './types';

const QuestContext = React.createContext<IQuestContext>({} as IQuestContext);

type IQuestProviderProps = PropsWithChildren<{
  quest: IQuest;
}>;

export const useQuestContext = () => useContext(QuestContext);

export const QuestProvider = ({ children, quest }: IQuestProviderProps) => {
  const questContextValue: IQuestContext = useMemo(() => ({ quest }), [quest]);

  return <QuestContext.Provider value={questContextValue}>{children}</QuestContext.Provider>;
};
