import { Svg, SvgImage, SvgProps, SvgRect, registerSVG } from '../../../components/svg';

export function PayPalLogo(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 200 53">
      <SvgRect width="200" height="53" fill="none" />
      <SvgImage href={require('./PayPalLogo.png')} />
    </Svg>
  );
}

registerSVG('PayPalLogo', PayPalLogo);
