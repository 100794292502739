import { FC, useEffect } from 'react';

import { LayoutContainer } from 'components/layout/styled';
import { Logo } from 'components/logo';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useAuthContext } from 'state/auth';
import { portalToFooter } from 'state/mobile-footer';
import { portalToReplaceHeader } from 'state/mobile-header-nav';
import { routes } from 'utils/routing';

import { useLoyaltyUserState } from '../../../../state/loyalty/hooks/use-loyalty-user-state';
import { isActiveWalmartUser } from '../../../../state/loyalty/hooks/utils/walmart';
import { StyledLoadingAnimation } from '../../loyalty-history/loyalty-history.styled';

import { StyledBox, StyledLogo } from './styled';

export const WalmartRedirect: FC<React.PropsWithChildren<{}>> = () => {
  const { isAuthenticated, loading } = useAuthContext();
  const { navigate } = useNavigation();
  const { loyaltyUser, loading: loyaltyLoading } = useLoyaltyUserState();

  useEffect(() => {
    const isAlreadyLinked = isActiveWalmartUser(loyaltyUser);
    if (!loading && !loyaltyLoading) {
      if (!isAuthenticated || !isAlreadyLinked) {
        navigate(`${routes.walmart}?type=distro`);
      } else {
        navigate(routes.loyaltyOfferList);
      }
    }
  }, [loyaltyUser]);

  const HiddenAppHeader = portalToReplaceHeader(() => null);
  const HiddenAppFooter = portalToFooter(() => null);
  return (
    <LayoutContainer isFullContainer={true}>
      <StyledBox>
        <StyledLogo>
          <Logo />
        </StyledLogo>
        <StyledLoadingAnimation />
      </StyledBox>
      <HiddenAppHeader />
      <HiddenAppFooter />
    </LayoutContainer>
  );
};
