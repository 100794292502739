import React, { useEffect } from 'react';

import { Box, Text } from '@rbilabs/universal-components';
import { FlatList } from 'react-native';

import { LayoutContainer } from 'components/layout/styled';
import { WebView } from 'components/web-view';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useIsMobileBreakpoint } from 'hooks/use-media-query';
import { IStaticPage } from 'remote/queries/static-page';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';
import { brand } from 'utils/environment';
import {
  AnchorComponentRefType,
  AnchorScrollContainerRefType,
  useAnchorContext,
} from 'utils/static-page/hooks/useAnchorContext';

import AccordionWidget from '../accordionWidget';
import AnchorLinksWidget from '../anchorLinksWidget';
import CallToActionWidget from '../callToActionWidget';
import FixedCallToActionWidget from '../fixedCallToActionWidget';
import HeaderWidget from '../headerWidget';
import ImageWidget from '../imageWidget';
import NutritionExplorerWidget from '../nutrition-explorer-widget';
import { PreviewWidget } from '../preview-widget';
import RewardsCarouselWidget from '../rewards-carousel-widget';
import theme from '../theme';
import VideoWidget from '../videoWidget';
import YouTubeWidget from '../youtubeWidget';

import serializers from './serializers';
import {
  BlockWidgetContainer,
  Header1,
  Header2,
  Header3,
  Header4,
  Header5,
  Paragraph,
  UnknownWidget,
  blockWidgetLinkProps,
  blockWidgetStrongProps,
} from './styled';
import { WidgetType } from './widgetEnums';

export const AnchorWidget = ({ id, ...rest }: { id: string }) => {
  const { registerTarget, formatLocaleAnchorName } = useAnchorContext();
  const formattedName = formatLocaleAnchorName(id);

  return (
    <Box
      ref={(ref: AnchorComponentRefType) => {
        if (ref) {
          registerTarget(formattedName, ref);
        }
      }}
      position={'relative'}
      {...hiddenAccessibilityPlatformProps}
      {...rest}
    />
  );
};

export const blockContentOverrides = (pageSlug: string) => ({
  h1: (props: any) => <Header1 {...props} pageSlug={pageSlug} />,
  h2: (props: any) => <Header2 {...props} pageSlug={pageSlug} />,
  h3: (props: any) => <Header3 {...props} pageSlug={pageSlug} />,
  h4: (props: any) => <Header4 {...props} pageSlug={pageSlug} />,
  h5: (props: any) => <Header5 {...props} pageSlug={pageSlug} />,
  normal: (props: any) => <Paragraph {...props} pageSlug={pageSlug} />,
});

const Row = Box.withConfig<{ blockTextInversion?: boolean }>(p => ({
  background: p.blockTextInversion
    ? brand() === 'th'
      ? Styles.color.black
      : theme.rowInversionBackgroundColor
    : theme.backgroundColor,
  color: p.blockTextInversion
    ? theme.rowInversionTextColor
    : brand() === 'th'
    ? Styles.color.secondary
    : Styles.color.black,
}));

export const StaticPageContainer: React.FC<React.PropsWithChildren<{
  data: any[];
  currentPage: IStaticPage;
  useBoxContainer: boolean;
  fixedHeightContent?: React.ReactNode;
  webViewLoading?: boolean;
}>> = ({ data, currentPage, useBoxContainer, fixedHeightContent, webViewLoading = false }) => {
  const { registerContainerRef } = useAnchorContext();
  const { navigate } = useNavigation();

  const isMobile = useIsMobileBreakpoint();
  const hasAnchorLinks = data.some(widget => widget._type === WidgetType.ANCHOR_LINKS);
  const fixedCallToActionWidget = data.find(
    (item: { _type: WidgetType }) => item._type === WidgetType.FIXED_CALL_TO_ACTION
  );

  useEffect(() => {
    if (currentPage?.redirectUrl?.locale) {
      navigate(currentPage.redirectUrl.locale);
    }
  }, [currentPage, navigate]);

  const renderItem = ({ item: widget, index }: { item: any; index: number }) => {
    const type = widget._type;
    const key = `widget${index}`;

    switch (type) {
      case WidgetType.WEB_VIEW:
        return <Row key={key}>{widget.renderWebView()}</Row>;
      case WidgetType.WEB_VIEW_WIDGET:
        return (
          <Row key={key}>
            <WebView {...widget} />
          </Row>
        );
      case WidgetType.REWARDS_CAROUSEL:
        return (
          <Row key={key}>
            <RewardsCarouselWidget {...widget} />
          </Row>
        );
      case WidgetType.HEADER:
        return (
          <Row key={key}>
            <HeaderWidget {...widget} />
          </Row>
        );
      case WidgetType.ANCHOR:
        return (
          <Row key={key} {...widget}>
            {/* @ts-expect-error TS(2322) FIXME: Type '{ className: string; id: any; }' is not assi... Remove this comment to see the full error message */}
            <AnchorWidget className="anchorWidget" id={widget.anchorName?.locale} />
          </Row>
        );
      case WidgetType.ANCHOR_LINKS:
        return (
          <Row key={key}>
            <AnchorLinksWidget widgets={currentPage.widgets} />
          </Row>
        );
      case WidgetType.LOCALE_BLOCK_TEXT:
        return (
          <Row key={key} {...widget}>
            <LayoutContainer>
              <BlockWidgetContainer
                blockContentOverrides={blockContentOverrides(currentPage.path.current)}
                strongProps={blockWidgetStrongProps}
                linkProps={blockWidgetLinkProps}
                // @ts-expect-error TS(2322) FIXME: Type '{ list: ({ type, children }: { type: string;... Remove this comment to see the full error message
                serializers={serializers}
                content={widget?.localeBlockTextContent?.localeRaw}
              />
            </LayoutContainer>
          </Row>
        );
      case WidgetType.IMAGE:
        return (
          <Row key={key}>
            <ImageWidget {...widget} />
          </Row>
        );
      case WidgetType.CALL_TO_ACTION:
        return (
          <Row key={key}>
            <CallToActionWidget {...widget} />
          </Row>
        );
      case WidgetType.ACCORDION:
        return (
          <Row key={key}>
            <LayoutContainer isFullContainer={isMobile}>
              <AccordionWidget {...widget} />
            </LayoutContainer>
          </Row>
        );
      case WidgetType.PREVIEW:
        return (
          <Row key={key}>
            <LayoutContainer>
              <PreviewWidget {...widget} />
            </LayoutContainer>
          </Row>
        );
      case WidgetType.NUTRITION_EXPLORER:
        return (
          <Row key={key}>
            <NutritionExplorerWidget {...widget} />
          </Row>
        );
      case WidgetType.VIDEO:
        return (
          <Row key={key}>
            <VideoWidget {...widget} />
          </Row>
        );
      case WidgetType.YOUTUBE:
        return (
          <Row key={key}>
            <YouTubeWidget {...widget} />
          </Row>
        );
      case WidgetType.MULTI_WIDGET:
        return (
          <Row key={key}>
            {/* TODO: RN - Delete this text block */}
            <Text>Unknown Widget (not implemented)</Text>
            <UnknownWidget> Placeholder: {type} </UnknownWidget>
          </Row>
        );
      default:
        return (
          <Row key={key}>
            {/* TODO: RN - Delete this text block */}
            <Text>Unkown Widget (not implemented)</Text>
            <UnknownWidget> Placeholder: {type} </UnknownWidget>
          </Row>
        );
    }
  };

  /**
   * When displaying static pages, we utilize either Box or FlatList components,
   * depending on the content. If a static page contains widgets,
   * we generally opt for the FlatList—except for certain widgets
   * like the Fixed Call To Action, which require a plain layout.
   * If no widgets are present, we use a Box component.
   */
  if (!useBoxContainer) {
    // TODO: BKPE-1167: consider replacing FlatList with ScrollView for static pages with anchor links.
    // FlatList only render visible elements, so Anchor widgets outside visible area are not registered on mount.
    // FIXME: This fix is only valid for existing static pages. This line doubles the default value of 21 so all anchor widgets are registered.
    const windowSize: number = hasAnchorLinks && isMobile ? 41 : 21;

    return (
      <FlatList
        overScrollMode={webViewLoading ? 'never' : 'auto'}
        showsVerticalScrollIndicator={!isMobile}
        ref={ref => {
          registerContainerRef(ref as AnchorScrollContainerRefType);
        }}
        data={data}
        renderItem={renderItem}
        windowSize={windowSize}
      />
    );
  }

  return (
    <Box height="full" width="full">
      {fixedHeightContent}
      {fixedCallToActionWidget && <FixedCallToActionWidget {...fixedCallToActionWidget} />}
    </Box>
  );
};
