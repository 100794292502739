import { Refinement } from '@rbi-ctg/frontend';
import { IPickerAspectUiPattern } from '@rbi-ctg/menu';
import {
  ICartEntry,
  ICombo,
  IComboSlot,
  IComboSlotSelection,
  IItem,
  IItemOption,
  IItemOptionModifier,
  IModifierSelection,
  IPrices,
  ISanityItemOption,
  IWithPricingProps,
} from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';
import {
  IGetComboPlusQuery,
  IGetItemPlusQuery,
  IGetPickerPlusQuery,
} from 'generated/sanity-graphql';
import { CartEntryType } from 'utils/cart/types';
import { IComboSlotSelectionsNoDataInSelections } from 'utils/menu/price';
import { IWithVendorConfig, PosVendors } from 'utils/vendor-config';

import { useMainMenuContext } from './main-menu';
import { useMenuOptionsContext } from './menu-options';

export type IMenuContext = ReturnType<typeof useMainMenuContext> &
  ReturnType<typeof useMenuOptionsContext> & {
    showBurgersForBreakfast: boolean;
  } & IUsePricingFunctionHookReturnType;

export type IMenuCombo = NonNullable<IGetComboPlusQuery['Combo']> & {
  _type: MenuObjectTypes.COMBO;
};
export type IMenuItem = NonNullable<IGetItemPlusQuery['Item']> & {
  _type: MenuObjectTypes.ITEM;
};
export type IMenuPicker = NonNullable<IGetPickerPlusQuery['Picker']> & {
  _type: MenuObjectTypes.PICKER;
};
export type IMenuObject = IMenuCombo | IMenuItem | IMenuPicker;

export type IsCartEntryType<MenuType extends { type: CartEntryType }> = Refinement<
  object,
  MenuType
>;

export interface IPriceForItemOptionModifierParams {
  item: ICombo | IItem | ICartEntry | null;
  itemOption: ISanityItemOption | IItemOption | null;
  modifier: (IItemOptionModifier & { quantity?: number }) | null;
  prices?: IPrices;
}
export type IPriceForItemOptionModifierFn = (args: IPriceForItemOptionModifierParams) => number;

export interface IPriceForComboSlotSelectionParams {
  combo?: ICombo;
  comboSlot: IComboSlot;
  selectedOption?: IComboSlotSelection['option'];
  prices?: IPrices;
  vendor?: PosVendors | null;
}
export type IPriceForComboSlotSelectionFn = (args: IPriceForComboSlotSelectionParams) => number;

export interface IPriceForItemInComboSlotSelectionParams {
  combo?: ICombo | ICartEntry;
  comboSlot?: IComboSlot | ICartEntry;
  selectedItem: IItem | ICartEntry;
  prices?: IPrices;
  vendor?: PosVendors | null;
}
export type IPriceForItemInComboSlotSelectionFn = (
  args: IPriceForItemInComboSlotSelectionParams
) => number;

export type IPricingFunctionParams = {
  item: IWithVendorConfig;
  quantity?: number;
  modifierSelections?: Array<IModifierSelection>;
  comboSlotSelections?: IComboSlotSelectionsNoDataInSelections;
  prices?: IPrices;
  vendor?: PosVendors | null;
  isBogoOffer?: boolean;
};

export type IPricingFunctionFn = (args: IPricingFunctionParams) => number;

interface IUsePricingFunctionHookParams extends IWithPricingProps {}
export interface IUsePricingFunctionHookReturnType {
  allPricingDependenciesAreSettled: boolean;
  priceForItemInComboSlotSelection: IPriceForItemInComboSlotSelectionFn;
  priceForComboSlotSelection: IPriceForComboSlotSelectionFn;
  priceForItemOptionModifier: IPriceForItemOptionModifierFn;
  pricingFunction: IPricingFunctionFn;
}
export type IUsePricingFunctionHook = (
  args: IUsePricingFunctionHookParams
) => IUsePricingFunctionHookReturnType;

export enum PickerAspectUiPattern {
  SELECTOR = 'selector',
  RADIO = 'radio',
}

export const isUiPatternRadio = (p: IPickerAspectUiPattern): boolean =>
  p.uiPattern === PickerAspectUiPattern.RADIO;

export enum SectionUiPattern {
  STANDARD = 'standard',
  STACKED = 'stacked',
  INLINE_TILE = 'inlineTile',
}
