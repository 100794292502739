import { Svg, SvgPath, SvgProps, SvgRect, registerSVG } from '../../../components/svg';

export function Mastercard(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <SvgRect y="4" width="24" height="16" rx="2" fill="white" />
      <SvgRect
        x="0.5"
        y="4.5"
        width="23"
        height="15"
        rx="1.5"
        stroke="black"
        strokeOpacity="0.3"
        fill="white"
      />
      <SvgPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8911 15.8663C11.0553 16.5317 9.97108 16.9333 8.78637 16.9333C6.14293 16.9333 4 14.9335 4 12.4667C4 9.99979 6.14293 8 8.78637 8C9.97108 8 11.0553 8.40167 11.8911 9.06705C12.7269 8.40167 13.8111 8 14.9958 8C17.6392 8 19.7821 9.99979 19.7821 12.4667C19.7821 14.9335 17.6392 16.9333 14.9958 16.9333C13.8111 16.9333 12.7269 16.5317 11.8911 15.8663Z"
        fill="#ED0006"
      />
      <SvgPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8911 15.8663C12.9202 15.047 13.5728 13.828 13.5728 12.4667C13.5728 11.1054 12.9202 9.88631 11.8911 9.06705C12.7269 8.40167 13.8111 8 14.9958 8C17.6392 8 19.7822 9.99979 19.7822 12.4667C19.7822 14.9335 17.6392 16.9333 14.9958 16.9333C13.8111 16.9333 12.7269 16.5317 11.8911 15.8663Z"
        fill="#F9A000"
      />
      <SvgPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8911 15.8662C12.9202 15.0469 13.5727 13.8279 13.5727 12.4666C13.5727 11.1053 12.9202 9.88629 11.8911 9.06702C10.862 9.88629 10.2095 11.1053 10.2095 12.4666C10.2095 13.8279 10.862 15.0469 11.8911 15.8662Z"
        fill="#FF5E00"
      />
    </Svg>
  );
}

registerSVG('Mastercard', Mastercard);
