import { useCallback } from 'react';

import { ApolloError } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';

import {
  IMergePrepaidInput,
  MergePrepaidDocument,
  UserAccountsDocument as UserAccountsQuery,
  useMergePrepaidMutation,
} from 'generated/graphql-gateway';
import { useLocale } from 'state/intl';
import logger from 'utils/logger';

type Variables = IMergePrepaidInput;

const MutationName = getOperationName(MergePrepaidDocument)!;

export const usePrepaidsMerge = () => {
  const { feCountryCode } = useLocale();

  const onError = useCallback((error: ApolloError) => {
    const { graphQLErrors: errors } = error;
    if (errors.length) {
      logger.error({
        errors,
        message: 'Error merging prepaid balances.',
        queryName: MutationName,
      });
    }
  }, []);

  const [mergePrepaidMutation, { data, loading }] = useMergePrepaidMutation({
    onError,
    refetchQueries: [
      // Note: Once UserAccountsQuery is deprecated (BKPE-2745), need to replace with StoredPaymentMethods query
      // { query: StoredPaymentMethodsDocument, variables: { feCountryCode } },
      { query: UserAccountsQuery, variables: { feCountryCode } },
    ],
  });

  const mergePrepaids = useCallback(
    ({ sourceCardNumber, sourcePin }: Omit<Variables, 'destinationFdAccountId'>) => {
      return mergePrepaidMutation({
        variables: {
          input: {
            sourceCardNumber: sourceCardNumber.replace(/\s/g, ''),
            sourcePin,
          },
        },
      });
    },
    [mergePrepaidMutation]
  );

  const destinationAccountBalance = data?.mergePrepaid.prepaidDetails.balance;

  return { ...data, destinationAccountBalance, mergePrepaids, loading };
};
