import React, { useEffect, useState } from 'react';

import AsyncStorage from '@react-native-async-storage/async-storage';
import { reloadAsync } from 'expo-updates';
import {
  ActivityIndicator,
  Alert,
  NativeModules,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import { SplashScreen } from 'components/splash-screen/splash-screen';
import NativeAuthStorage from 'utils/cognito/storage';
import { RBIEnv, env, mmkvStorage, region, setPickedEnvConfig } from 'utils/environment';
import { Region } from 'utils/environment/types';
import WebLocalStorage from 'utils/local-storage';

import { fetchSanityData } from '../../../sanity-prebuild-data/src/fetch-sanity-data';

const ENVIRONMENTS: RBIEnv[] = [RBIEnv.DEV, RBIEnv.QA, RBIEnv.STAGING, RBIEnv.PROD];

const REGIONS: Region[] = [Region.US, Region.CA];

export const EnvPicker = ({ isDiagnosticsScreen = false }) => {
  const initialEnv = env();
  const initialRegion = region;
  const [selectedEnv, setSelectedEnv] = useState(initialEnv);
  const [selectedRegion, setSelectedRegion] = useState(initialRegion);
  const [isLoadingEnv, setIsLoadingEnv] = useState(false);

  // for when used pre-boot
  useEffect(() => {
    SplashScreen.hide();
  }, []);

  return (
    <SafeAreaView style={{ flex: 1, margin: isDiagnosticsScreen ? 0 : 20 }}>
      <Text style={{ marginVertical: 10, fontWeight: '700' }}>Select an environment:</Text>
      {ENVIRONMENTS.map(envName => (
        <TouchableOpacity
          key={envName}
          onPress={() => {
            setSelectedEnv(envName);
          }}
          style={{ flexDirection: 'row', alignItems: 'center', marginTop: 5 }}
        >
          <View
            style={[
              { borderColor: selectedEnv === envName ? Styles.color.red : 'gray' },
              styles.radioOuter,
            ]}
          >
            {selectedEnv === envName && <View style={styles.radioInner} />}
          </View>
          <Text style={{ marginLeft: 8 }}>
            {envName}
            <Text style={{ fontWeight: '700' }}>{envName === initialEnv ? ' (current)' : ''}</Text>
          </Text>
        </TouchableOpacity>
      ))}

      <Text style={{ marginTop: 20, marginBottom: 10, fontWeight: '700' }}>Select a region:</Text>
      {REGIONS.map(region => (
        <TouchableOpacity
          key={region}
          onPress={() => {
            setSelectedRegion(region);
          }}
          style={{ flexDirection: 'row', alignItems: 'center', marginTop: 5 }}
        >
          <View
            style={[
              { borderColor: selectedRegion === region ? Styles.color.red : 'gray' },
              styles.radioOuter,
            ]}
          >
            {selectedRegion === region && <View style={styles.radioInner} />}
          </View>
          <Text style={{ marginLeft: 8 }}>
            {region}
            <Text style={{ fontWeight: '700' }}>
              {region === initialRegion ? ' (current)' : ''}
            </Text>
          </Text>
        </TouchableOpacity>
      ))}

      <TouchableOpacity
        disabled={isLoadingEnv}
        style={styles.continue}
        onPress={async () => {
          if (initialEnv !== selectedEnv || selectedRegion !== initialRegion) {
            confirm(
              `This will clear out all app storage including login and restart the app using "${selectedEnv}" "${selectedRegion}". Continue?`,
              async () => {
                setIsLoadingEnv(true);
                const newEnvData = await fetchSanityData(
                  selectedRegion,
                  selectedEnv,
                  '',
                  ['US', 'CA'],
                  'runtime',
                  {
                    fetch: (...args) => window.fetch(...args), // args spread to avoid `this` context issue on web
                  }
                );
                await clearAllLocalDataForEnvSwitch();
                setPickedEnvConfig(newEnvData);
                restartApp();
              }
            );
          } else {
            setPickedEnvConfig({ hasPicked: true });
            restartApp();
          }
        }}
      >
        {isLoadingEnv ? (
          <ActivityIndicator />
        ) : (
          <Text style={{ color: 'white', fontWeight: '700' }}>Continue</Text>
        )}
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          confirm(
            `This will clear out all app storage including login and restart the app using the default environment. Continue?`,
            async () => {
              await clearAllLocalDataForEnvSwitch();
              restartApp();
            }
          );
        }}
        disabled={isLoadingEnv}
        style={styles.resetAndRestart}
      >
        <Text style={{ color: Styles.color.red, fontWeight: '700' }}>Reset & Restart</Text>
      </TouchableOpacity>
      {!isDiagnosticsScreen && (
        <Text style={{ marginTop: 15 }}>
          The environment can be changed at any time on the diagnostics screen.
        </Text>
      )}
    </SafeAreaView>
  );
};

// different strategies because some only work in dev or NOT in dev...
const restartApp = () =>
  Platform.OS === 'web'
    ? window?.location && (window.location.href = '/')
    : __DEV__
    ? NativeModules.DevSettings.reload()
    : reloadAsync();

// because of the pre-boot usage of this we can't use UCL components
const confirm = (message: string, onConfirm: () => void) => {
  if (Platform.OS === 'web') {
    window.confirm(message) && onConfirm();
    return;
  }

  Alert.alert(
    'Confirmation',
    message,
    [
      {
        text: 'Cancel',
        style: 'cancel',
      },
      { text: 'OK', onPress: onConfirm },
    ],
    { cancelable: false }
  );
};

export const clearAllLocalDataForEnvSwitch = async () => {
  mmkvStorage?.clearAll();
  await AsyncStorage.clear().catch(() => {}); // will complain if already cleared
  NativeAuthStorage.clear();
  WebLocalStorage.clearAll();
  WebLocalStorage.setCurrentVersion();
};

const styles = StyleSheet.create({
  radioOuter: {
    height: 24,
    width: 24,
    borderRadius: 12,
    borderWidth: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioInner: {
    height: 12,
    width: 12,
    borderRadius: 6,
    backgroundColor: Styles.color.red,
  },
  continue: {
    marginTop: 15,
    padding: 10,
    backgroundColor: Styles.color.red,
    alignItems: 'center',
    borderRadius: 20,
  },
  resetAndRestart: {
    marginTop: 15,
    padding: 10,
    alignItems: 'center',
    borderRadius: 20,
    borderColor: Styles.color.red,
    borderWidth: 2,
  },
});
