import { useIntl } from 'react-intl';

import { IRestaurant } from '@rbi-ctg/store';
import { CoordinateTypes, useGeolocation } from 'state/geolocation';
import { useLocale } from 'state/intl';
import { readableDistanceFromStore } from 'utils/restaurant';

import { useDistance } from './use-distance';

interface UserDistanceTextOptions {
  restaurant: IRestaurant | null;
  shouldUseUserCoordinatesIfAvailable?: boolean;
}

interface UseDistanceTextReturn {
  distanceText: string;
  isLoading: boolean;
  canShowDistance: boolean;
}

export const useDistanceText = ({
  restaurant,
  shouldUseUserCoordinatesIfAvailable = false,
}: UserDistanceTextOptions): UseDistanceTextReturn => {
  const { formatMessage } = useIntl();
  const { region } = useLocale();

  const { loadingUserCoordinates, isPermissionGranted, coordinatesType } = useGeolocation();

  const distance = useDistance({ restaurant, shouldUseUserCoordinatesIfAvailable });

  const canShowDistance = coordinatesType === CoordinateTypes.USER && isPermissionGranted;
  const distanceText = distance ? readableDistanceFromStore(distance, region, formatMessage) : '';

  // isLoading currently only considers coordinates of type USER.
  // We are not yet storing in state when coordinates of type manual are being fetched from Google Place.
  // https://github.com/rbilabs/bk-us-app/blob/6205a42c478174e37c7cd85c3eb5c0a4c1babfda/src/state/geolocation/index.tsx#L390
  const isLoading = loadingUserCoordinates;

  return {
    distanceText,
    isLoading,
    canShowDistance,
  };
};
