import { Header } from '@rbilabs/universal-components';

import { InnerLayout } from 'components/layout/styled';
import BlockRenderer from 'components/sanity-block-renderer';

import { LoyaltyLoader } from '../loyalty-loader';

export const StyledBlockRenderer = BlockRenderer.withConfig({
  margin: 0,
  fontSize: 'xs',
});

export const LoyaltyIncentivesContainer = InnerLayout.withConfig({
  paddingY: '$0',
  margin: '$4',
});

export const OffersItemHeader = Header.withConfig({
  alignSelf: 'center',
  marginTop: '$4',
  variant: 'headerTwo',
});

export const StyledLoyaltyLoader = LoyaltyLoader;
