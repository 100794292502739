import type { ComponentType } from 'react';

import { usePropsResolution, useTheme } from 'native-base';

import { addWithConfig } from '../../utils';
import { svgLibrary } from '../svg';

import type { BrandLogoProps, _BrandLogoSvgs } from './types';

export const BrandLogo = ({ variant: initialVariant, ...props }: BrandLogoProps) => {
  const nativeBaseTheme = useTheme();

  /**
   * This allows us to swap out the variant to another. Enabling setting a common
   * theme variant like primary that can change to a brand specific variant.
   */
  const finalVariant: _BrandLogoSvgs =
    // @ts-ignore must be ignored because types are forced to avoid circular dependencies.
    nativeBaseTheme.components.BrandLogo.variants[initialVariant]?.variant || initialVariant;
  const resolvedProps = usePropsResolution('BrandLogo', {
    ...props,
    variant: finalVariant,
  });
  const isVariantInTheme: boolean = !!nativeBaseTheme.components.BrandLogo.variants[finalVariant];

  if (!isVariantInTheme) {
    throw new Error(`BrandLogo variant "${finalVariant}" not found in theme`);
  }

  const Logo: ComponentType<typeof resolvedProps> | undefined = svgLibrary[finalVariant];
  if (!Logo) {
    throw new Error(`BrandLogo SVG "${finalVariant}" not found in svgLibrary export`);
  }

  return <Logo {...resolvedProps} />;
};

export default addWithConfig(BrandLogo);
