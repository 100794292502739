import { useCallback } from 'react';

import { isNil } from 'lodash-es';

import { IConstantPlu } from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';
import {
  IPriceForComboSlotSelectionFn,
  IPriceForItemInComboSlotSelectionFn,
  IPriceForItemOptionModifierFn,
  IPricingFunctionFn,
  IUsePricingFunctionHook,
} from 'state/menu/types';
import { isCombo, isItem } from 'utils/menu';
import { getDefaultComboSlotOption } from 'utils/menu/get-default-combo-slot-option';
import { ModifierComponentStyle } from 'utils/menu/modifiers';
import {
  isOptionInComboSlot,
  priceForItemOptionModifierBooleanOrStepper,
  priceForItemOptionModifierSelection,
} from 'utils/menu/price';

import {
  priceComboSlotComposite,
  priceComboSlotDelta,
  priceForComboSelections,
  priceForItemModifierSelections,
  pricingFunctionNoSelections,
} from './utils';

export const usePricingFunction: IUsePricingFunctionHook = ({
  prices: storeContextPrices,
  vendor: storeContextVendor,
}) => {
  const priceForItemInComboSlotSelection = useCallback<IPriceForItemInComboSlotSelectionFn>(
    ({
      combo,
      comboSlot,
      selectedItem,
      prices = storeContextPrices,
      vendor = storeContextVendor,
    }) => {
      const compositePrice =
        combo &&
        priceComboSlotComposite({
          combo,
          selectedItem,
          prices,
          vendor,
        });
      if (!isNil(compositePrice)) {
        return compositePrice;
      }
      const defaultComboSlotOption = getDefaultComboSlotOption(comboSlot);
      return priceComboSlotDelta(defaultComboSlotOption, selectedItem, prices, vendor);
    },
    [storeContextPrices, storeContextVendor]
  );

  const priceForComboSlotSelection = useCallback<IPriceForComboSlotSelectionFn>(
    ({
      combo,
      comboSlot,
      selectedOption,
      prices = storeContextPrices,
      vendor = storeContextVendor,
    }) => {
      // return instantly if the selectedOption doesn't exist in the comboSlot... Returning zero. Its on the caller to identify what to do.
      if (!selectedOption || !isOptionInComboSlot(comboSlot, selectedOption)) {
        return 0;
      }
      // When upsellPricing is not supported for this comboSlotOption means its price is included in the combo
      if (!selectedOption.enableUpsellPricing) {
        return 0;
      }
      const selectedItem = selectedOption?.option;

      if (!selectedItem || selectedItem._type === MenuObjectTypes.PICKER) {
        return 0;
      }
      return priceForItemInComboSlotSelection({
        combo,
        comboSlot,
        selectedItem,
        prices,
        vendor,
      });
    },
    [priceForItemInComboSlotSelection, storeContextPrices, storeContextVendor]
  );

  const priceForItemOptionModifier = useCallback<IPriceForItemOptionModifierFn>(
    ({ item, itemOption, modifier, prices = storeContextPrices }) => {
      if (!item || !itemOption || !modifier || isCombo(item)) {
        return 0;
      }

      if (
        !itemOption.componentStyle ||
        itemOption.componentStyle === ModifierComponentStyle.Selector
      ) {
        return priceForItemOptionModifierSelection({
          item,
          itemOption,
          modifier,
          prices,
          vendor: storeContextVendor,
        });
      }

      return priceForItemOptionModifierBooleanOrStepper({
        item,
        itemOption,
        modifier,
        prices,
        vendor: storeContextVendor,
      });
    },
    [storeContextPrices, storeContextVendor]
  );

  /**
   * Returns the price of a menu object
   */
  const pricingFunction = useCallback<IPricingFunctionFn>(
    ({
      item,
      quantity = 1,
      modifierSelections = [],
      comboSlotSelections = {},
      prices = storeContextPrices,
      vendor = storeContextVendor,
      isBogoOffer = false,
    }) => {
      const itemQuantity = quantity || 1;
      const price = pricingFunctionNoSelections(item, prices, vendor, itemQuantity);

      if (isItem(item)) {
        return (
          price +
          priceForItemModifierSelections({
            item,
            quantity,
            modifierSelections,
            prices,
            priceForItemOptionModifier,
          })
        );
      }

      if (isCombo(item)) {
        return (
          price +
          priceForComboSelections({
            combo: item,
            quantity,
            modifierSelections,
            comboSlotSelections,
            prices,
            vendor,
            priceForComboSlotSelection,
            priceForItemOptionModifier,
          })
        );
      }

      // BOGO Offers checking
      if (isBogoOffer && vendor) {
        // Getting PLU from the Offer itself or from children (Carrols special case)
        const generalVendor = item?.vendorConfigs?.[vendor] as IConstantPlu;
        const constantPlu =
          generalVendor.constantPlu ||
          item?.children?.[1].children?.[0].vendorConfigs[vendor].constantPlu;

        return prices?.[constantPlu || 0] || 0;
      }

      return price;
    },
    [priceForComboSlotSelection, priceForItemOptionModifier, storeContextPrices, storeContextVendor]
  );

  const allPricingDependenciesAreSettled = !!storeContextPrices && !!storeContextVendor;

  return {
    allPricingDependenciesAreSettled,
    priceForItemInComboSlotSelection,
    priceForComboSlotSelection,
    priceForItemOptionModifier,
    pricingFunction,
  };
};
