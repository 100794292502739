import React, { FC } from 'react';

import { Box, HStack } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';

/** Basic inline wrapper to contain a list of items */
const Wrapper = HStack.withConfig({
  alignItems: 'center',
  bottom: '$2',
  justifyContent: 'center',
  position: 'absolute',
  width: 'full',
});

type ColorType = 'primary' | 'secondary' | 'inverted';

interface IDotProps {
  /** Changes the dot color to mark that it's active */
  isActive: boolean;
  /** Changes the dot color to mark that it's active */
  color: ColorType;
  isLast: boolean;
}

const colors = {
  primary: Styles.color.primary,
  secondary: Styles.color.secondary,
  inverted: Styles.color.white,
};

const getColor = (color: ColorType) => {
  return colors[color];
};

const DOT_SIZE = '$3';
/** Circles used to note an active or inactive state. */
const Dot = Box.withConfig<IDotProps>(p => ({
  width: p.isActive ? '$6' : DOT_SIZE,
  height: DOT_SIZE,
  borderRadius: 50,
  backgroundColor: p.isActive ? getColor(p.color) : theme.token('border-color-disabled'),
  marginRight: p.isLast ? '$0' : DOT_SIZE,
}));

interface IProgressDotsProps {
  /** How many dots shot up */
  dotCount: number;
  /** The color of the active dot
   *
   *  default 'primary'
   */
  color?: ColorType;
  /** in index of the currently active dot */
  activeDotIndex: number;
}

/**
 * Displays a dot(circle) based stepped progress indicator
 */
export const ProgressDots: FC<React.PropsWithChildren<IProgressDotsProps>> = ({
  dotCount = 4,
  activeDotIndex = 0,
  color = 'primary',
}) => {
  const dots = Array.from(Array(dotCount).keys());

  return (
    <Wrapper>
      {dots.map(dotIndex => (
        <Dot
          isActive={dotIndex === activeDotIndex}
          key={dotIndex}
          color={color}
          isLast={dotIndex + 1 === dotCount}
        />
      ))}
    </Wrapper>
  );
};
