import { differenceInMinutes } from 'date-fns';
import { keyBy } from 'lodash-es';

import {
  IBackendCartEntries,
  ICartEntry,
  IServerOrder,
  IUnavailableCartEntry,
} from '@rbi-ctg/menu';
import { IAppliedOffer } from 'generated/graphql-gateway';
import { CartEntryType } from 'utils/cart/types';

export const isOfferType = (type: CartEntryType) => /^offer/i.test(type);

export const replaceEntryArrayItem = (array: ICartEntry[], newEntry: ICartEntry) => {
  const index = array.findIndex(entry => String(entry.cartId) === String(newEntry.cartId));
  return index !== -1
    ? [...array.slice(0, index), newEntry, ...array.slice(index + 1)]
    : [...array, newEntry];
};

export const validateCartEntries = (
  cartEntries: ICartEntry[],
  appliedOffers: IAppliedOffer[],
  incentivesIds: Set<string>,
  validateCallback: (entry: ICartEntry) => void
) => {
  const appliedOffersByCartId = keyBy(appliedOffers, 'cartId');
  cartEntries.forEach(entry => {
    const { _id, type, cartId } = entry;
    const isOffer = incentivesIds.has(_id);

    if (!isOffer) {
      return;
    }

    if (
      (type !== CartEntryType.offerCombo && type !== CartEntryType.offerItem) ||
      !appliedOffersByCartId[cartId]
    ) {
      validateCallback(entry);
    }
  });
};

type EntryOrChildrenMatchId = (entry: ICartEntry) => boolean;

const cartEntryContainsUnavailableId = (id: string): EntryOrChildrenMatchId => {
  function entryOrChildrenMatchId(entry: ICartEntry) {
    return entry.cartId === id || (entry.children || []).some(entryOrChildrenMatchId);
  }
  return entryOrChildrenMatchId;
};

export const getUnavailableItemsFromServerOrder = ({
  cartEntries,
  serverOrder,
}: {
  serverOrder: IServerOrder | null;
  cartEntries: ICartEntry[];
}): ICartEntry[] => {
  if (!serverOrder || !serverOrder.cart || !serverOrder.cart.unavailableCartEntries) {
    return [];
  }

  const serverOrderUnavailableCartEntries: IUnavailableCartEntry[] =
    serverOrder.cart.unavailableCartEntries;
  const unavailableLineIds = serverOrderUnavailableCartEntries.map(
    (item: IUnavailableCartEntry) => item.lineId
  );

  // get all unavailable cart entries from cartEntries
  // if a child of the combo/item is unavailable, return the most parent cart entry
  return unavailableLineIds.reduce((acc: ICartEntry[], lineId: string) => {
    const filterCartEntries = cartEntryContainsUnavailableId(lineId);

    return acc.concat(cartEntries.filter(filterCartEntries));
  }, []);
};

export const cartEntriesHasAnOffer = (cartEntries: IBackendCartEntries[] = []) =>
  cartEntries.some((entry: IBackendCartEntries) => isOfferType(entry.type));

export const isOrderTimeInInterval = (
  currentOrderTime: string,
  timeoutInMinutes: number
): boolean =>
  Math.abs(differenceInMinutes(new Date(currentOrderTime), new Date())) < timeoutInMinutes;
