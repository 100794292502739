import de from 'date-fns/locale/de';
import enUS from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';

export const Locales = {
  de,
  en: enUS,
  fr,
  it,
};
