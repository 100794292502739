import { AccessibilityProps } from 'react-native';

type HiddenAccessibilityPlatformProps = {
  [key in '_ios' | '_web' | '_android']: AccessibilityProps;
};

/**
 * Uses the appropriate accessibility prop based on the current platform
 *
 * This hides the elements from the accessibility API and will be ignored from screen readers, etc.
 */
export const hiddenAccessibilityPlatformProps: HiddenAccessibilityPlatformProps = {
  _ios: {
    accessibilityElementsHidden: true,
  },
  _web: {
    accessibilityHidden: true,
  },
  _android: {
    importantForAccessibility: 'no-hide-descendants',
  },
};
