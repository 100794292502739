import { useIntl } from 'react-intl';

import { PROD_DEFAULT_REGION } from 'state/intl/constants';
import {
  ISOs,
  postalCodeStringLocaleKey,
  regionInputProperties,
  regionStringLocaleKey,
} from 'utils/form/constants';

const useCreditCardFormProps = (isoCountryCode: ISOs = ISOs[PROD_DEFAULT_REGION]) => {
  // construct postal code props based on users country
  const { formatMessage } = useIntl();
  const postalCodeInputProps = {
    label: formatMessage({ id: postalCodeStringLocaleKey[isoCountryCode] }),
  };
  const regionInputProps = {
    label: formatMessage({ id: regionStringLocaleKey[isoCountryCode] }),
    ...regionInputProperties[isoCountryCode],
  };

  return { postalCodeInputProps, regionInputProps };
};

export default useCreditCardFormProps;
