import { IPluWithQuantity, IPrices } from '@rbi-ctg/menu';

export const bestPlusForQuantity = (
  plus: IPluWithQuantity[],
  prices: IPrices,
  quantity: number
) => {
  // remove plus with no price and plus for quantities greater than the amount user is buying
  const relevantPlus = plus.filter(
    ({ plu, quantity: quantityForPlu }) => quantityForPlu <= quantity && plu in (prices ?? {})
  );
  // sort in descending order
  const sorted = relevantPlus.sort((left, right) => (left.quantity < right.quantity ? 1 : -1));
  // build a bucket of plus that sums to the user's selected quantity
  return sorted.reduce<IPluWithQuantity[]>((bucket, currentPlu) => {
    const remainingSpace = quantity - bucket.reduce((acc, { quantity: qty }) => acc + qty, 0);
    return bucket.concat(
      Array<IPluWithQuantity>(Math.floor(remainingSpace / currentPlu.quantity)).fill(currentPlu)
    );
  }, []);
};
