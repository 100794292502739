import { ReactNode } from 'react';

import { Box, Button, HStack, Header, Text, VStack } from '@rbilabs/universal-components';
import { GestureResponderEvent } from 'react-native';

import { BadgeButtonText, BadgeContainer } from './styled';

type WalmartDistributionOfferItemProps = {
  name: string;
  description: string;
  image: ReactNode;
  onConfirm: ((event: GestureResponderEvent) => void) | null | undefined;
  confirmButtonLabel: string;
};

export const WalmartDistributionOfferItem = ({
  name,
  description,
  image,
  confirmButtonLabel,
  onConfirm,
}: WalmartDistributionOfferItemProps) => (
  <Box bg="token.background-pattern" rounded={10} borderWidth={0} borderColor="transparent">
    <VStack p="$3" pt="$3" borderTopRadius={10} borderBottomRadius={10}>
      <HStack mr="$2">
        {image}
        <VStack flexShrink={1} ml="$3" alignItems="flex-start" justifyContent="center">
          <Header variant="headerThree" color="token.text-default" mb="$1">
            {name}
          </Header>
          {!!description && (
            <Text fontSize={12} lineHeight={16}>
              {description}
            </Text>
          )}

          <BadgeContainer>
            <Button
              size="sm"
              h="$7.5"
              px="$7"
              py="0"
              onPress={onConfirm}
              variant="outline"
              style={{ borderColor: Styles.color.primary }}
            >
              <BadgeButtonText>{confirmButtonLabel}</BadgeButtonText>
            </Button>
          </BadgeContainer>
        </VStack>
      </HStack>
    </VStack>
  </Box>
);
