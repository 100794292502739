import React, { createContext, useCallback, useContext, useRef, useState } from 'react';

import { ScrollView } from 'react-native';

import { IBaseProps } from '@rbi-ctg/frontend';
import { useMemoAll } from 'utils/use-memo-all';

type ScrollViewInstance = ScrollView | null;

export interface IScrollContext {
  scrollRef: React.MutableRefObject<ScrollViewInstance>;
  scrollTo: ScrollView['scrollTo'];
  hasScrollFullHeight: boolean;
  setHasScrollFullHeight: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ScrollContext = createContext<IScrollContext>({} as IScrollContext);
export const useScrollContext = () => useContext(ScrollContext);

export default ScrollContext.Consumer;

export const ScrollProvider = ({ children }: IBaseProps) => {
  const scrollRef = useRef<ScrollViewInstance>(null);
  const [hasScrollFullHeight, setHasScrollFullHeight] = useState(false);

  // If no coordinates given, default scroll to top
  const scrollTo = useCallback(({ x, y, animated } = { x: 0, y: 0, animated: true }) => {
    scrollRef.current?.scrollTo({ x, y, animated });
  }, []);

  const value = useMemoAll({
    scrollRef,
    scrollTo,
    hasScrollFullHeight,
    setHasScrollFullHeight,
  });

  return <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>;
};
