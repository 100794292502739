import { PROD_DEFAULT_LANGUAGE } from 'state/intl/constants';
import { loadRegion } from 'utils/intl/region';

import { getNavigatorLanguage } from './get-navigator-language';

export function inferLanguageFromNavigator(): string {
  const navigatorLanguage = getNavigatorLanguage();
  // If language not set return default language
  return navigatorLanguage.split(/.*-/)[0] || String(PROD_DEFAULT_LANGUAGE[loadRegion()]);
}
