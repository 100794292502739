// This was previously referenced on Storybook packages
// `module.hot` is not listed on @types/node/ts4.8/globals.d.ts
/// <reference types="webpack-env" />
/* eslint-disable import/no-import-module-exports */

import { updateId } from 'expo-updates';
import { LDContext } from 'launchdarkly-js-sdk-common';
import { isEqual, merge, omitBy } from 'lodash-es';
import { omit } from 'lodash-es';
import { Platform } from 'react-native';

import { appVersion, binaryRuntimeAppVersion } from 'utils/app-version-info';
import { getDeviceId } from 'utils/device-id';
import { getCurrentCommitHash, platform, sanityDataset } from 'utils/environment';
import { loadLanguage } from 'utils/intl/language';
import { loadRegion } from 'utils/intl/region';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { Keys } from 'utils/local-storage/constants';
import { TraceName } from 'utils/performance';
import { startTrace } from 'utils/performance/trace-lite';

import { configurePlatformClient, isLaunchDarklyReadyDeferred, ldClient } from './client';
import { AllLaunchDarklyFlags, LaunchDarklyFlagsObject } from './flags';

export type LDContextUpdates = Partial<LDContext>;

/**
 * this isn't guaranteed to be up-to-date or even populated, but will be as of last call or change listener response
 * for cases outside of react where you can't use the provider, using the provider is preferred
 * */
export const staticLDFlags: { current: LaunchDarklyFlagsObject; isLoadedFromServer: boolean } = {
  current: {},
  isLoadedFromServer: false,
};

/** exposed for private LD use, you shouldn't really be reading this and its not in react either for the same reason */
export const staticLDContextUserAttrs: { current: LDContext } = { current: {} };

// ensure values are populated after they are available in LocalStorage.getItem which is async :(
LocalStorage.sync().then(() => {
  staticLDFlags.current = LocalStorage.getItem(StorageKeys.LAUNCH_DARKLY_FLAGS) ?? {};
});

/** cross platform LD init */
export const initLaunchDarklyAndGetAllFlags = async (): Promise<Record<string, unknown>> => {
  const [, baseLDContext] = await Promise.all([
    LocalStorage.sync(), // LocalStorage.getItem technically async, this ensures current values
    createBaseLDContext(),
  ]);

  staticLDContextUserAttrs.current = merge(
    {},
    baseLDContext,
    omitSomeCachedAttributes(LocalStorage.getItem(Keys.LAUNCH_DARKLY_USER_ATTRIBUTES) as LDContext)
  ) as LDContext;

  const ldInitTrace = startTrace(TraceName.APP_START_INIT_LAUNCHDARKLY);

  // delegate to native/web files to configure their core client.
  await configurePlatformClient(staticLDContextUserAttrs.current);
  const allFlags = await getAllLDFlags();
  staticLDFlags.isLoadedFromServer = true;

  ldInitTrace?.stop();

  return allFlags;
};

/** will always update a user's attributes with LD even if they haven't effectively changed */
const updateUserWithAttributes = async (newLDContext: LDContext) => {
  // we don't want to persist values that are on the baseLDContext, only unique attributes of the user. it has caused bugs (like app version can change every boot!)
  const diffToStore = getDiffFromBaseLDContext(await createBaseLDContext(), newLDContext);
  LocalStorage.setItem(Keys.LAUNCH_DARKLY_USER_ATTRIBUTES, diffToStore);
  staticLDContextUserAttrs.current = newLDContext;

  // block LD call on it being initialized to avoid race condition related errors.
  await isLaunchDarklyReadyDeferred.promise;
  await ldClient.current.identify(newLDContext);
  const flags = await getAllLDFlags();

  return { newFlags: flags, userAttributes: newLDContext };
};

/** get all LD flags and handle persisting static state */
export const getAllLDFlags = async () => {
  // block LD call on it being initialized to avoid race condition related errors.
  await isLaunchDarklyReadyDeferred.promise;
  const allFlags = await ldClient.current.allFlags();
  staticLDFlags.current = allFlags as AllLaunchDarklyFlags;
  LocalStorage.setItem(StorageKeys.LAUNCH_DARKLY_FLAGS, allFlags);
  return allFlags;
};

/** conditionally update a user and get new flags if something actually changed */
export const updateLDUserCore = async (changes: LDContextUpdates) => {
  const normalized = normalizeUserAttributes(changes as any);
  const mergedAttributes = merge({}, staticLDContextUserAttrs.current, normalized) as LDContext;

  // Only update LD user if attributes are different via a deep comparison check
  if (!isEqual(staticLDContextUserAttrs.current, mergedAttributes)) {
    return updateUserWithAttributes(mergedAttributes);
  }

  return { newFlags: undefined, userAttributes: mergedAttributes };
};

export const clearLDUserCore = async () => {
  const baseContext = await createBaseLDContext();
  return updateUserWithAttributes(baseContext);
};

export const getDiffFromBaseLDContext = (baseLDContext: LDContext, currentUser: LDContext) => {
  return Object.fromEntries(
    Object.entries(currentUser).filter(([key, value]) => value !== baseLDContext[key])
  );
};

export const privateAttributes = [
  'email',
  'firstName',
  'lastName',
  'name',
  'dateOfBirth',
  'phoneNumber',
];

/**
 * we do not want to pull values from LocalStorage if they were set previously
 * omit some attributes that should never come from cache as they need to be fresh
 * */
export const omitSomeCachedAttributes = (cachedUserAttributes: LDContext) =>
  omitBy(
    omit(cachedUserAttributes, [
      // these cannot come from the cache because they can change every boot
      // and break the force update modal!
      'appFlowBuildId',
      'appShellVersion',
      'expoOtaUpdateId',
      'appVersion',
    ]),
    // also remove all custom.* attributes are they are legacy
    (val, key) => key.startsWith('custom')
  );

const normalizeUserAttributes = (userAttributeUpdates: LDContext): LDContext => {
  const trimStringKeys = <T extends {}>(attributes: T): T =>
    Object.entries(attributes).reduce((acc, [key, value]) => {
      if (typeof value === 'object') {
        return { ...acc, [key]: value && trimStringKeys(value) };
      }

      if (typeof value === 'string') {
        return { ...acc, [key]: value.trim() };
      }

      return { ...acc, [key]: value };
    }, {} as T);

  const emailAttributeLowerCase = ({ email, ...attributes }: LDContext): LDContext => ({
    ...attributes,
    ...(email ? { email: email.toLowerCase() } : null),
  });

  return emailAttributeLowerCase(trimStringKeys(userAttributeUpdates));
};

const createBaseLDContext = async (): Promise<LDContext> => {
  const appShellVersion = binaryRuntimeAppVersion;
  const region = loadRegion();
  const isWeb = Platform.OS === 'web';
  const anon: LDContext = {
    kind: 'user',
    anonymous: true,
    expoOtaUpdateId: updateId ?? '', // transition to this since it makes more sense
    appFlowBuildId: updateId ?? '',
    appShellVersion,
    appVersion: isWeb ? getCurrentCommitHash() : appVersion,
    country: region,
    device_id: (await getDeviceId()) || '',
    host: isWeb ? window.location.host : 'NATIVE_APP',
    language: loadLanguage(),
    mobileOS: Platform.OS.toLowerCase(),
    platform: platform(),
    sanityDataset: `${sanityDataset()}_${region.toLowerCase()}`,
    // this used to be hardcoded to IOS for mobile for some reason but now does ANDROID too
    userClient: isWeb ? window.navigator.userAgent || '' : Platform.OS.toUpperCase(),
  };
  return normalizeUserAttributes(anon);
};
