import { IFeatureMenuQuery } from 'generated/sanity-graphql';

import { useMenuExperimentVariantName } from './use-menu-experiment-variant-name';

interface IMenuVariant {
  variantName: string;
  menuVariantId: string;
}

/**
 * Returns the current variant menu under A/B test, if any.
 *
 * Based on the Menu A/B experiments setup on Amplitude, we could
 * get the variant menu that's under testing from Sanity. If no
 * variant menu matches the current experiment `variantName`,
 * it returns undefined.
 *
 * @param featureMenu The Sanity document with the menu config
 */
export const useMenuVariant = (
  featureMenu: IFeatureMenuQuery['FeatureMenu']
): IMenuVariant | undefined => {
  const variantName = useMenuExperimentVariantName();

  const sanityMenuVariants = featureMenu?.variants;
  if (!sanityMenuVariants?.length || !variantName) {
    return;
  }

  const foundSanityMenuVariant = sanityMenuVariants.find(
    menuVariant => menuVariant?.key?.current === variantName
  );

  const menuVariantId = foundSanityMenuVariant?.menuVariantReference?._id;
  if (!menuVariantId) {
    return;
  }

  return {
    variantName,
    menuVariantId,
  };
};
