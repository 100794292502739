import { Skeleton as NbSkeleton } from 'native-base';

import { addWithConfig } from '../../utils';

import type { _SkeletonComponentType } from './types';

export const Skeleton: _SkeletonComponentType = NbSkeleton;
Skeleton.Text = NbSkeleton.Text;

const SkeletonWithConfig = addWithConfig(Skeleton);
SkeletonWithConfig.Text = addWithConfig(Skeleton.Text);

export default SkeletonWithConfig;
