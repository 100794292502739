import { IVendorConfig } from '@rbi-ctg/menu';

import { IWithVendorConfig, PosVendors } from './types';

export const getVendorConfig = (
  item: IWithVendorConfig,
  vendor: PosVendors | null
): IVendorConfig | null | undefined => {
  if (!item || !item.vendorConfigs || !vendor) {
    return null;
  }

  const { vendorConfigs } = item;
  return vendorConfigs[vendor] as IVendorConfig | undefined;
};
