import { Svg, SvgPath, SvgProps, registerSVG } from '../../../components/svg';

export function CalendarToday(props: SvgProps) {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <SvgPath d="M7 10h5v5H7m12 4H5V8h14m0-5h-1V1h-2v2H8V1H6v2H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2z" />
    </Svg>
  );
}

registerSVG('CalendarToday', CalendarToday);
