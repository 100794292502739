import React from 'react';

import { Box, Icon, InlineAlert } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { ActionButtonVariants } from 'components/action-button';
import { CustomDisclaimer } from 'components/offer-disclaimer/offer-disclaimer';
import { useRoute } from 'hooks/navigation/use-route';
import { useRumPageView } from 'hooks/rum/use-rum-page-view';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyOfferDetail } from 'state/loyalty/offers/use-loyalty-offer-detail';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

import {
  ButtonsWrapper,
  StyledActionButton,
  StyledBackgroundImage,
  StyledDisclaimer,
  StyledImage,
  StyledOfferDescription,
  StyledOfferName,
} from './loyalty-offer-detail.styled';
import { StyledLoyaltyLoader } from './loyalty-offers.styled';
import { ILoyaltyOffersParams } from './types';

export const LoyaltyOfferDetail = () => {
  const { formatMessage } = useIntl();
  const { params } = useRoute<ILoyaltyOffersParams>();
  const enableRedeemInRestaurantPrimary = Boolean(
    useFlag(LaunchDarklyFlag.ENABLE_REDEEM_IN_RESTAURANT_PRIMARY)
  );
  const offerEngineId = (params?.offerEngineId || '').trim();
  const { offer, loading } = useLoyaltyOfferDetail({ offerEngineId });

  useRumPageView(`loyalty-offers-detail`, `Loyalty Offers Detail`);

  // TODO: handle the scenario `!loading && !offer` redirecting to the offer list
  if (loading || !offer) {
    return <StyledLoyaltyLoader />;
  }

  const { backgroundImage, description, image, name = '', errors, moreInfo } = offer ?? {};
  const [error] = errors ?? [];

  // TODO - in next PR
  const canAddToMobileOrder = true;
  const canAddToInRestaurantOrder = true;
  const disableMobileOrderButton = false;
  const disableInRestaurantOrderButton = false;
  const handleRedeemRewardPress = () => {};
  const handleInRestaurantRedemptionPress = () => {};
  const checkingAvailability = false;

  return (
    <>
      <Box alignItems="center" flexShrink={0}>
        {backgroundImage && <StyledBackgroundImage alt={name} image={backgroundImage} />}
        {image && <StyledImage alt={name} image={image} objectFitContain />}
      </Box>
      <Box alignItems="center" paddingX="$4">
        {name && <StyledOfferName>{name}</StyledOfferName>}
        {description && <StyledOfferDescription content={description} />}
      </Box>
      {/* TODO - show offer evaluation feedback message here */}
      {!!error && <InlineAlert marginTop="$6" status="info" message={'error message'} />}
      <ButtonsWrapper $error={!!error}>
        <Box flexShrink={0}>
          {canAddToMobileOrder && (
            <StyledActionButton
              disabled={disableMobileOrderButton}
              onPress={handleRedeemRewardPress}
              isLoading={checkingAvailability}
              leftIcon={
                <Icon variant="mobile" width="24px" {...hiddenAccessibilityPlatformProps} />
              }
            >
              {formatMessage({ id: 'addMobileOrder' })}
            </StyledActionButton>
          )}

          {canAddToInRestaurantOrder && (
            <StyledActionButton
              disabled={disableInRestaurantOrderButton}
              onPress={handleInRestaurantRedemptionPress}
              isLoading={checkingAvailability}
              marginTop="$4"
              leftIcon={<Icon variant="store" width="24px" {...hiddenAccessibilityPlatformProps} />}
              variant={
                enableRedeemInRestaurantPrimary
                  ? ActionButtonVariants.PRIMARY
                  : ActionButtonVariants.OUTLINE
              }
            >
              {formatMessage({ id: 'redeemInRestaurant' })}
            </StyledActionButton>
          )}
        </Box>
      </ButtonsWrapper>
      {moreInfo && (
        <StyledDisclaimer>
          <CustomDisclaimer content={moreInfo as any} />
        </StyledDisclaimer>
      )}
    </>
  );
};
