import { IAmplitudeAttributes } from 'state/amplitude/types';
import Braze from 'utils/braze';
import logger from 'utils/logger';

interface ISetBrazeUserAttributes extends IAmplitudeAttributes {
  'Date of Birth'?: string;
  Age?: number;
  $Age?: number;
  email?: string;
  phoneNumber?: string;
}

export const setBrazeUserAttributes = (brazeAttributes: ISetBrazeUserAttributes) => {
  for (const key in brazeAttributes) {
    const value = brazeAttributes[key as keyof ISetBrazeUserAttributes];

    if (typeof value !== 'string' && typeof value !== 'number') {
      continue;
    }

    try {
      switch (key) {
        case 'firstName':
          if (typeof value === 'string') {
            Braze.setFirstName(value);
          }
          break;
        case 'lastName':
          if (typeof value === 'string') {
            Braze.setLastName(value);
          }
          break;
        case '$City':
          if (typeof value === 'string') {
            Braze.setHomeCity(value);
          }
          break;
        case '$Mobile':
        case 'phoneNumber':
          if (typeof value === 'string') {
            Braze.setPhoneNumber(value);
          }
          break;
        // We cannot set complex data structures as user attributes in Braze.
        case 'favoriteStores':
          break;
        default:
          Braze.setCustomUserAttribute(key, value);
          break;
      }
    } catch (error) {
      logger.warn({ error, message: 'Error setting Braze User Attributes' });
    }
  }
};
