import { IPrices } from '@rbi-ctg/menu';
import { computeUpsellModifierPlu } from 'utils/vendor-config';
import { IWithVendorConfig, PosVendors } from 'utils/vendor-config/types';

interface IPriceComboSlotUpsellModifier {
  modifier: IWithVendorConfig;
  prices: IPrices;
  vendor: PosVendors | null;
}

export const priceComboSlotUpsellModifier = ({
  modifier,
  prices,
  vendor,
}: IPriceComboSlotUpsellModifier): number | undefined => {
  if (!prices || !vendor) {
    return;
  }
  const modifierPlu = computeUpsellModifierPlu({ modifier, vendor });
  return modifierPlu ? prices[modifierPlu] : undefined;
};
