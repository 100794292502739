import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { CognitoUser, CognitoUserSession, ISignUpResult } from 'amazon-cognito-identity-js';
import { isNil, omitBy } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import { Cognito } from 'utils/cognito';
import { withNetworkRetries } from 'utils/network/utils';

import { CapsuleChannels, CapsuleEvents } from '../../types/aws-amplify';

import normalizeString, { getCognitoRetryConfig } from './util';

interface ISignUp {
  username: string;
  name: string;
  phoneNumber: string;
  wantsPromotionalEmails: boolean;
  country: string;
  dob?: string;
}

interface IValidateLogin {
  username: string;
  code: string;
  sessionId: string;
}

interface ISignUpAttributes {
  nickname: string;
  name: string;
  locale: string;
  phone_number?: string;
  birthdate?: string;
}

interface IUpdateUserAttributes {
  name?: string;
  phoneNumber?: string | null;
  dob?: string;
}

////////////////////////////////////////////////////////////
// Sign In Moved to GQL queries
////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////
// Sign Out
////////////////////////////////////////////////////////////

export const signOut = async (isGlobalSignOut: boolean = false): Promise<void> => {
  await Auth.signOut({ global: isGlobalSignOut });

  // dispatch an event so those listening to the sign out can trigger their callbacks
  Hub.dispatch(CapsuleChannels.AUTH, {
    event: CapsuleEvents.SIGN_OUT,
  });
};

////////////////////////////////////////////////////////////
// Sign Up
////////////////////////////////////////////////////////////

export const signUp = ({
  username,
  wantsPromotionalEmails,
  name,
  phoneNumber,
  country,
  dob,
}: ISignUp): Promise<ISignUpResult> => {
  // TODO: customAttributes must be predefined using nickname as a hack
  const normalizedUsername = normalizeString(username);
  const password = uuidv4();
  const emailConsent = wantsPromotionalEmails ? 'true' : 'false';
  const attributes: ISignUpAttributes = {
    nickname: emailConsent,
    name,
    locale: country,
  };

  if (phoneNumber) {
    attributes.phone_number = phoneNumber;
  }

  if (dob) {
    attributes.birthdate = dob;
  }

  return withNetworkRetries(
    () =>
      Auth.signUp({
        username: normalizedUsername,
        password,
        attributes,
      }),
    getCognitoRetryConfig('Auth.signUp')
  );
};

////////////////////////////////////////////////////////////
// Confirm Login
////////////////////////////////////////////////////////////

export const validateLogin = async ({
  username,
  code,
  sessionId,
}: IValidateLogin): Promise<CognitoUserSession | null> => {
  const cognitoUser = getCognitoUserForUsername(username);
  (cognitoUser as any).Session = sessionId;

  return withNetworkRetries(
    () => Auth.sendCustomChallengeAnswer(cognitoUser, code),
    getCognitoRetryConfig('Auth.sendCustomChallengeAnswer')
  ).then((cognitoUser: CognitoUser) => cognitoUser.getSignInUserSession());
};

////////////////////////////////////////////////////////////
// Update User Attributes
////////////////////////////////////////////////////////////

export const updateUserAttributes = async ({ name, phoneNumber, dob }: IUpdateUserAttributes) => {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.updateUserAttributes(
    user,
    omitBy(
      {
        name,
        phone_number: phoneNumber,
        birthdate: dob,
      },
      isNil
    )
  );
};

////////////////////////////////////////////////////////////
// Helper
////////////////////////////////////////////////////////////

const getCognitoUserForUsername = (username: string): CognitoUser => {
  const normalizedUsername = normalizeString(username);
  return new CognitoUser({
    Username: normalizedUsername,
    Pool: Cognito.userPool(),
    Storage: Cognito.storage,
  });
};
