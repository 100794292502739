import React, { FC } from 'react';

import { useIntl } from 'react-intl';

import Picture from 'components/picture';
import { useFeatureLayout } from 'hooks/use-feature-layout';

const GiftCardImage: FC = () => {
  const { formatMessage } = useIntl();
  const { featureLayout } = useFeatureLayout();
  const giftCardImage = featureLayout?.giftCardImage?.locale;

  return giftCardImage ? (
    <Picture
      image={giftCardImage}
      alt={formatMessage({ id: 'giftCard' })}
      objectFitContain
      width="300px"
      height="162px"
    />
  ) : null;
};

export default React.memo(GiftCardImage);
