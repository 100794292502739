import React, { PropsWithChildren, createContext, useCallback, useContext, useMemo } from 'react';

import { useNavigation } from 'hooks/navigation/use-navigation';
import { checkForOSPrompts } from 'state/location/routes';
import { useMParticleContext } from 'state/mParticle';
import { addOrUpdateScreenState } from 'state/onboarding/utils';

import { useOnboardingContent, useOnboardingNavigation } from './hooks';
import { IFeatureOnboardingScreen, IOnboardingContext } from './types';

export const OnboardingContext = createContext<IOnboardingContext>({} as IOnboardingContext);

export const useOnboardingContext = () => useContext<IOnboardingContext>(OnboardingContext);

export const OnboardingProvider = ({ children }: PropsWithChildren<{}>) => {
  const { navigate } = useNavigation();
  const { logEvent, logRBIEvent } = useMParticleContext();
  const { firstAppLoadOnboarding, triggerRule } = useOnboardingContent();

  const numberOfScreens = useMemo(
    () => firstAppLoadOnboarding?.featureOnboardingScreens?.length || 0,
    [firstAppLoadOnboarding?.featureOnboardingScreens?.length]
  );

  const {
    navigateForward,
    navigateBackward,
    currentScreenIndex,
    isFirstScreen,
    isLastScreen,
    isFirstAppLoadOnboardingComplete,
    setIsFirstAllLoadOnboardingComplete,
  } = useOnboardingNavigation(numberOfScreens);

  const onDismissOnboardingModal = useCallback(() => {
    setIsFirstAllLoadOnboardingComplete(true);
  }, [setIsFirstAllLoadOnboardingComplete]);

  const onButtonPress = useCallback(
    (featureOnboardingScreen: IFeatureOnboardingScreen, route?: string, callback?: () => void) => {
      if (!route) {
        onDismissOnboardingModal();
        return;
      }
      addOrUpdateScreenState(triggerRule || '', featureOnboardingScreen, route);
      if (route === '/OS/continue') {
        navigateForward();
        return;
      }
      if (!route?.startsWith('/OS/')) {
        onDismissOnboardingModal();
        navigate(route);
        return;
      }
      checkForOSPrompts(route, logEvent, logRBIEvent, () => {
        if (callback) {
          callback();
        }
        navigateForward();
      });
    },
    [logEvent, logRBIEvent, navigate, navigateForward, onDismissOnboardingModal, triggerRule]
  );

  const value = useMemo(
    () => ({
      triggerRule,
      featureOnboardingId: firstAppLoadOnboarding?.id,
      featureOnboardingScreens: firstAppLoadOnboarding?.featureOnboardingScreens ?? [],
      onDismissOnboardingModal,
      onButtonPress,
      isFirstAppLoadOnboardingComplete,
      navigateForward,
      navigateBackward,
      currentScreenIndex,
      isFirstScreen,
      isLastScreen,
      numberOfScreens,
    }),
    [
      triggerRule,
      firstAppLoadOnboarding?.id,
      firstAppLoadOnboarding?.featureOnboardingScreens,
      onDismissOnboardingModal,
      onButtonPress,
      isFirstAppLoadOnboardingComplete,
      navigateForward,
      navigateBackward,
      currentScreenIndex,
      isFirstScreen,
      isLastScreen,
      numberOfScreens,
    ]
  );
  return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>;
};
