import { TextInput } from 'components/ucl/text-input';

export const StyledInput = TextInput.withConfig({
  height: '50px',
  width: 'full',
  margin: {
    base: '0 4px',
    md: '0 5px',
  },
  paddingX: 0,
  textAlign: 'center',
  background: '#ffffff',
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 4,
  letterSpacing: 4,
  fontSize: '2xl',
  fontWeight: 'bold',
  placeholderTextColor: 'transparent',
  _focus: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Styles.color.primary,
  },
  _disabled: {
    _web: {
      cursor: 'none',
    },
    background: Styles.color.grey.four,
  },
});
