import { Box, Button, Header } from '@rbilabs/universal-components';

import Dialog from 'components/dialog';
import Picture from 'components/picture';

export const StyledHeader = Header.withConfig({
  fontSize: '2xl',
  fontWeight: Styles.fontWeight.heavy,
  accessibilityLevel: 1,
  variant: 'headerOne',
  textAlign: 'center',
  fontFamily: Styles.fontFamily.body,
  marginBottom: '$3',
});

export const StyledBody = Box.withConfig({
  marginTop: '$0',
  marginBottom: '$3',
  _text: {
    textAlign: 'center',
    fontSize: 'sm',
  },
  _android: {
    marginBottom: '$4',
  },
});

export const StyledDialog = Dialog.withConfig({
  paddingTop: '$4',
  textAlign: 'left',
});

export const StyledPicture = Picture.withConfig({
  maxWidth: '85%',
  alignSelf: 'center',
  marginBottom: '$3',
});

export const StyledTextOnlyButton = Button.withConfig({
  variant: 'ghost',
  paddingX: '$12',
  paddingY: '$3',
  marginTop: '$1',
  _text: {
    textTransform: 'capitalize',
  },
});

export const StyledButton = Button.withConfig({
  _text: {
    textTransform: 'capitalize',
  },
});

export const StyledOutlineButton = Button.withConfig({
  variant: 'outline',
});
