import { ComponentProps, forwardRef } from 'react';

import { Button as NbButton } from 'native-base';

import { addWithConfig } from '../../utils';

import type { _ButtonComponentType } from './types';

export const Button = NbButton as _ButtonComponentType;

Button.Group = NbButton.Group;

export const withDataDogTracking = (component: _ButtonComponentType) => {
  const Component = component as any;

  return (forwardRef((props: ComponentProps<any>, ref: any) => (
    <Component
      {...props}
      dd-action-name={
        props?.['dd-action-name'] ||
        (typeof props?.children === 'string' && props.children) ||
        props?.testID
      }
      ref={ref}
    />
  )) as unknown) as _ButtonComponentType;
};

const ButtonWithTracking = withDataDogTracking(Button);
const ButtonWithConfigAndTracking = addWithConfig(ButtonWithTracking);
ButtonWithConfigAndTracking.Group = addWithConfig(Button.Group);

export default ButtonWithConfigAndTracking;
