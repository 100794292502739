import React, { useState } from 'react';

import { useIntl } from 'react-intl';

import { AutocompleteAddressInput } from 'components/autocomplete-address-input';

import { ISearchBarProps } from './types';

export const SearchBar = ({
  onAddressSelected,
  value,
  defaultValue,
  onInputValueChange,
}: ISearchBarProps) => {
  const { formatMessage } = useIntl();
  const [inputValue, setInputValue] = useState(defaultValue || value || '');

  return (
    <AutocompleteAddressInput
      required
      value={inputValue}
      // @ts-expect-error TS(2322) FIXME: Type 'Dispatch<SetStateAction<string>>' is not ass... Remove this comment to see the full error message
      onInputValueChange={setInputValue}
      onAddressSelected={(address, placeId) => {
        setInputValue(address);
        onAddressSelected(address, placeId);
      }}
      onBlur={() => {
        onInputValueChange(inputValue);
      }}
      label={formatMessage({ id: 'yourAddress' })}
      testID="storelocator-autocomplete"
    />
  );
};
