import { getVendorConfig } from './get-vendor-config';
import { IWithVendorConfig, PluTypes, PosVendors } from './types';

export const computeSimpleModifierPlu = ({
  modifier,
  vendor,
}: {
  modifier: IWithVendorConfig;
  vendor: PosVendors | null;
}): string | null => {
  const modifierVendorConfig = getVendorConfig(modifier, vendor);

  if (modifierVendorConfig && modifierVendorConfig.pluType === PluTypes.CONSTANT) {
    return modifierVendorConfig.constantPlu;
  }

  return null;
};
