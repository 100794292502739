import { IComboSlotOption, IComboSlotSelection } from '@rbi-ctg/menu';

export const getDefaultSelectionsForComboSlot = (
  comboSlotOptions: IComboSlotOption[] = []
): IComboSlotSelection[] => {
  const filteredOptsWithDefaultOrMin = comboSlotOptions.filter(
    option => option.defaultAmount || option.minAmount > 0
  );

  const nextAvailableItemInOpts = comboSlotOptions.filter(
    slotOption => slotOption.defaultAmount === 0
  );

  // This logic will use the default item if there is one (set in sanity),
  // and will use the next available item if there is not a default.
  // The quantity of 1 is added under 'selections' quantity
  let availableItems =
    filteredOptsWithDefaultOrMin.length > 0
      ? filteredOptsWithDefaultOrMin
      : [nextAvailableItemInOpts[0]];

  // If the item option is not include, do not select the next available item on default
  if (comboSlotOptions.length === 1 && comboSlotOptions[0].defaultAmount === 0) {
    availableItems = filteredOptsWithDefaultOrMin;
  }

  return availableItems.map(option => ({
    option,
    quantity:
      (option.defaultAmount || option.minAmount) > 0 ? option.defaultAmount || option.minAmount : 1,
  }));
};
