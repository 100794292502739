import type { SVGTypes } from '../../themes/types';
import { getObjectKeys } from '../../utils/js';
import { svgLibrary } from '../svg';

export type AddPrefix<TKey, TPrefix extends string> = TKey extends string
  // eslint-disable-next-line prettier/prettier
  ? `${TPrefix}${TKey}`
  : never;

/**
 * Adds a prefix to every string in an array
 */
const addPrefix = <T extends string, Prefix extends string>(list: T[], prefix: Prefix) =>
  list.map((item) => `${prefix}${item}`) as AddPrefix<T, Prefix>[];

/**
 * Prefixed list of SVG Icon Names
 */
export type svgIconNames = AddPrefix<keyof SVGTypes, 'custom:'>;

/**
 * Prefixed list of Material Icon Names
 */
const materialIconNames = addPrefix(
  [
    'accessible',
    'add',
    'account-circle',
    'bug-report',
    'camera-alt',
    'close',
    'content-copy',
    'shopping-bag',
    'keyboard-arrow-up',
    'check',
    'check-circle-outline',
    'payment',
    'delivery-dining',
    'drive-eta',
    'edit',
    'error-outline',
    'error',
    'expand-more',
    'expand-less',
    'favorite-outline',
    'favorite',
    'forward',
    'help-outline',
    'home',
    'info-outline',
    'info',
    'language',
    'laptop',
    'list',
    'location-on',
    'lock',
    'menu',
    'monitor',
    'more-horiz',
    'more-vert',
    'near-me',
    'navigate-next',
    'local-offer',
    'qr-code',
    'qr-code-scanner',
    'refresh',
    'remove',
    'remove-circle-outline',
    'restaurant',
    'keyboard-arrow-down',
    'search',
    'settings',
    'star-border',
    'star',
    'store',
    'phone',
    'access-time',
    'lock-open',
    'visibility',
    'visibility-off',
    'keyboard-voice',
    'warning',
    'wallet-giftcard',
  ],
  'mi:',
);

export function getIconNames() {
  const customKeys = addPrefix(getObjectKeys(svgLibrary as unknown as SVGTypes), 'custom:');
  return [...customKeys, ...materialIconNames];
}

export type IconName = ReturnType<typeof getIconNames>[number];

/**
 * Icons with the NativeBase icon are finicky.
 *
 * Icons included in this list render within the NativeBase Icon `as` prop.
 *
 * This fixes an issue where using the SVG as children breaks some SVG features.
 *
 * This can only be used with Icons that come with their own colors that can't be
 * overridden.
 */
export const svgAsExclusions: readonly svgIconNames[] = ['custom:Jcb'];
