import { Box, Text, makeUclComponent } from '@rbilabs/universal-components';

import LoadingAnimation from 'components/loading-animation';
import { LoyaltyNoRewardPoints } from 'pages/loyalty/loyalty-no-reward-points';

import LoyaltyClaimPointsLink from '../loyalty-claim-points/loyalty-claim-points-link';

const MAX_WIDTH_CONTAINER = '560px';

const Header = Text.withConfig({
  fontWeight: Styles.fontWeight.heavy,
});

export const RightHeader = Header.withConfig({
  flex: 1,
  textAlign: 'right',
});

export const LeftHeader = Header.withConfig({
  flexGrow: 1,
  textAlign: 'left',
});

export const CenterHeader = Header.withConfig({
  paddingRight: '$4',
  flexGrow: 1,
  textAlign: 'center',
});

export const StyledLoadingAnimation = makeUclComponent(LoadingAnimation).withConfig({
  marginTop: '$12',
});

export const LoyaltyContainer = Box.withConfig({
  flex: 1,
  backgroundColor: Styles.color.background,
  maxWidth: MAX_WIDTH_CONTAINER,
  width: {
    md: '4/5',
    base: 'full',
  },
  alignSelf: 'center',
  padding: {
    base: '$3',
  },
});

export const TableWrap = Box.withConfig<{ idx?: Number; bgColor?: String }>(props => ({
  position: 'relative',
  bgColor: props.bgColor || Styles.color.white,
  // opacity: 0,
  // TODO: RN - animation
  // animation: ${slideIn} 0.45s ${({ idx = 0 }) => 0.1 * idx}s forwards;
  borderRadius: Styles.borderRadius,
  borderWidth: '0px',
  borderStyle: 'solid',
  borderColor: Styles.color.grey.four,
  // maxWidth: '300px',
  width: 'full',
}));

export const TransactionDateAndTime = Box.withConfig({
  alignItems: 'flex-start',
});

export const TransactionDate = Text.withConfig({
  fontSize: 'md',
});

export const TransactionTime = Text.withConfig({
  fontSize: 'xs',
});

export const StyledLoyaltyClaimPointsLink = LoyaltyClaimPointsLink.withConfig({
  marginY: '$4',
  marginX: 'auto',
  paddingY: '$3',
  paddingX: '$5',
});

export const StyledLoyaltyNoRewardPoints = LoyaltyNoRewardPoints;
