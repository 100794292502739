import { SvgProps } from '@rbilabs/universal-components';

import { OrderLocatorTab } from 'state/launchdarkly/variations';

export enum EmptyStateCategories {
  NO_AUTH = 'noAuth',
  NO_LOCATION = 'noLocation',
  NO_STORES = 'noStores',
  ERROR = 'error',
  TEST = 'test',
}

export enum EmptyStateActions {
  LOGIN = 'login',
  SHARE_LOCATION = 'shareLocation',
}

export interface IEmptyStateContent {
  icon?: (props: SvgProps) => JSX.Element;
  titleId: string;
  bodyId: string;
  action?: EmptyStateActions;
}

interface IEmptyStateCategoryOptions {
  [key: string]: IEmptyStateContent;
}

export interface IEmptyStateTabOptions {
  [key: string]: IEmptyStateCategoryOptions;
}

export interface IStoreLocatorEmptyStatesProps {
  tab: OrderLocatorTab;
  locationPermissionsGranted: boolean;
  currentTabError: boolean;
  coordinatesAvailable?: boolean;
}
