import { Box, HStack, Pressable, View } from '@rbilabs/universal-components';

import { ActionButtonVariants } from 'components/action-button';
import Link from 'components/link';
import { theme } from 'styles/configure-theme';

const headerIconPressedBg = theme.token('background-icon-header-contrast');

const headerIconContainerStyles = {
  zIndex: Styles.zIndex.overlay,
  _pressed: {
    backgroundColor: headerIconPressedBg,
  },
  _focus: {
    backgroundColor: headerIconPressedBg,
  },
  _hover: {
    backgroundColor: headerIconPressedBg,
    color: 'inherit',
  },
};

// The absolute position pops it out of the flex flow
// and this allows our centered logo to be truly centered and not offset by the menu item
export const MenuButtonContainer = Box.withConfig({
  position: 'absolute',
  zIndex: 1,
  flexShrink: 1,
  left: 0,
});

export const MobileHeaderContainer = Box.withConfig<{
  background?: string;
  hideShadow?: boolean;
}>(p => ({
  safeAreaTop: true,
  background: p.background,
  zIndex: Styles.zIndex.overlay,
  _web: {
    shadow: p.hideShadow ? null : '1',
  },
}));

export const Header = HStack.withConfig({
  alignItems: 'center',
  color: theme.token('text-reversed'),
  height: Styles.layout.navHeight.mobile,
  justifyContent: 'space-between',
  textAlign: 'center',
  width: 'full',
});

export const LinkNoDecoration = Link.withConfig({
  ...headerIconContainerStyles,
});

export const HeaderIconContainer = Pressable.withConfig({
  ...headerIconContainerStyles,
  padding: 1,
  borderRadius: 'full',
});

export const StyledCenterSpacer = Box.withConfig({
  flexGrow: 1,
  alignItems: 'flex-end',
  textAlign: 'center',
});

export const StyledRightSpacer = Box.withConfig({
  flex: 1,
  alignItems: 'flex-end',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  _web: {
    gap: '$4',
  },
});

export const StyledLogo = View.withConfig({
  marginX: 'auto',
  justifyContent: 'center',
  accessibilityRole: 'link',
  accessibilityLabel: 'Burger King Logo. Navigate to Home',
});

export const TopServiceModeContainer = View.withConfig({
  shadow: '$1',
  zIndex: 1,
});

export default {
  buttonVariant: ActionButtonVariants.PRIMARY,
  linkVariant: ActionButtonVariants.INVERSE,
  linkVariantReversed: ActionButtonVariants.PRIMARY,
};

export const HeaderContainer = Box.withConfig<{
  reversed?: boolean;
}>(p => ({
  paddingY: 0,
  background: p.reversed
    ? theme.token('background-hero-reversed')
    : theme.token('background-hero-light'),
}));
