import { useCallback, useEffect, useRef } from 'react';

import { AppState, AppStateStatus, NativeEventSubscription } from 'react-native';

import { isNative } from 'utils/environment';

/**
 * This custom hook is designed to listen for changes in the application's state, such as
 * when it moves to the background and comes back to the foreground. It works for both
 * React Native (mobile) and web environments.
 */
export const useAppStateChangeListener = () => {
  const subscription = useRef<NativeEventSubscription | null>(null);
  const eventCallbackRef = useRef<Function | null>(null);

  // The app has returned from the background (mobile)
  const handleAppStateChange = useCallback((nextAppState: AppStateStatus) => {
    if (nextAppState === 'active' && eventCallbackRef.current) {
      eventCallbackRef.current();
    }
  }, []);

  // The tab has the focus again (web)
  const handleBrowserTabChange = useCallback(() => {
    if (document.visibilityState === 'visible' && eventCallbackRef.current) {
      eventCallbackRef.current();
    }
  }, []);

  /**
   * This function adds a listener to detect the moment the app returns from the background,
   * for example, on mobile, when a new app is opened on the device and then returns to the
   * BK app, or in the case of the web, when a new tab is opened and then the tab of our app
   * regains focus.
   */
  const handleAddListener = useCallback(
    (callback: Function) => {
      eventCallbackRef.current = callback;

      if (isNative) {
        subscription.current = AppState.addEventListener('change', handleAppStateChange);
      } else {
        document.addEventListener('visibilitychange', handleBrowserTabChange);
      }
    },
    [handleAppStateChange, handleBrowserTabChange]
  );

  const handleRemoveListener = useCallback(() => {
    if (isNative) {
      subscription.current?.remove();
      subscription.current = null;
    } else {
      document.removeEventListener('visibilitychange', handleBrowserTabChange);
    }
  }, [handleBrowserTabChange]);

  // Remove listeners when the component is unmounted
  useEffect(() => {
    return () => {
      handleRemoveListener();
    };
  }, [handleRemoveListener]);

  return {
    addAppStateChangeListeners: handleAddListener,
    removeAppStateChangeListeners: handleRemoveListener,
  };
};
