import {
  Box,
  HStack,
  Header,
  ScrollView,
  VStack,
  makeUclComponent,
} from '@rbilabs/universal-components';

import Modal from 'components/modal';
import { theme } from 'styles/configure-theme';

export const StyledModal = makeUclComponent(Modal).withConfig({
  headerStyle: {
    minHeight: '$13.5',
  },
});

export const Footer = Box.withConfig({
  flex: 1,
  paddingY: 4,
  paddingX: 4,
  _web: {
    shadow: '1',
  },
  borderTopWidth: 1,
  borderColor: theme.token('border-color-options'),
  alignItems: 'center',
});

export const HeaderContainer = HStack.withConfig({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  borderBottomWidth: 1,
  borderColor: theme.token('border-color-options'),
});

export const HeaderStyled = Header.withConfig({
  style: {
    marginBottom: 0,
  },
  textTransform: 'uppercase',
});

export const ItemRow = HStack.withConfig(({ isLastItem }: { isLastItem: boolean }) => ({
  borderBottomWidth: isLastItem ? 0 : 1,
  borderColor: theme.token('border-color-options'),
}));

export const AddExtrasContainer = ScrollView.withConfig({
  alignSelf: 'center',
  // TODO: BKPE-1177 Review modal container width
  width: {
    base: '100%',
    sm: '80%',
    md: '60%',
  },
});

export const AddExtrasDebugMenuContainer = VStack.withConfig({
  backgroundColor: theme.token('icon-button-primary'),
});
