import { fetchWithTracing } from './fetch-with-tracing';

export const operationNameTracingFetch = async (url: string, options?: RequestInit) => {
  const parsedUrl = new URL(url);
  const operationName = getOperationName(parsedUrl, options?.body as string);

  return fetchWithTracing(
    getUrlForOperationName(parsedUrl, operationName).href,
    options!,
    operationName
  );
};

// doing this to avoid a JSON.parse of potentially large body
// (or really complicated code to get it earlier in the ApolloLink pipeline)
const parseOperationNameRegex = /"operationName"\s*:\s*"(\S*?)"/;

const OPERATION_NAME = 'operationName';

// get the operation name for tracing purposes
export const getOperationName = (url: URL, jsonBody?: string) => {
  try {
    const existingOperationName = url?.searchParams?.get(OPERATION_NAME);
    if (existingOperationName) {
      return existingOperationName;
    }

    if (!jsonBody) {
      return null;
    }

    const operationName = parseOperationNameRegex.exec(jsonBody)?.[1];
    if (operationName) {
      return operationName;
    }
  } catch (_error) {} // oh well

  return null; // oh well
};

// add ?operationName=X to most GQL requests so we have better visibility on timings / error rates across our stack
export const getUrlForOperationName = (url: URL, operationName: string | null) => {
  if (operationName && !url?.searchParams?.get(OPERATION_NAME)) {
    url.searchParams.append(OPERATION_NAME, operationName);
  }

  return url;
};
