import { RetryLink } from '@apollo/client/link/retry';

import { Loggable } from '@rbi-ctg/frontend';
import { LaunchDarklyFlag } from 'state/launchdarkly';
import { staticLDFlags } from 'state/launchdarkly/launchdarkly-core';
import { announceApiCallNetworkFailure, staticConnectionStatus } from 'state/network/status';
import logger from 'utils/logger';
import { isBadNetworkError } from 'utils/network/utils';

import { getGqlQueryInfo } from '../utils';

import { isChaosModeEnabled } from './chaos-mode';

const DEFAULT_MAX_API_RETRIES = 4; // per prod logs, diminishing returns show >4 doesn't really help much
const MAX_RETRY_DELAY = 4000; // randomly try retries at delay up to this value to avoid overwhelming backends

const getMaxSanityApiRetries = () =>
  staticLDFlags.current[LaunchDarklyFlag.MAX_SANITY_API_RETRIES] || DEFAULT_MAX_API_RETRIES;

export const sanityBackendRetryLink = new RetryLink({
  attempts: (count, operation, fullError) => {
    try {
      if (isBadNetworkError(fullError)) {
        announceApiCallNetworkFailure();
      }

      const { operationType, operationName, isRetryAllowed } = getGqlQueryInfo(operation);

      const getLogCommon = (message: string, extraRetryInfo: object) => ({
        message,
        error: fullError,
        retryInfo: {
          operationName,
          operationType,
          isRetryLog: true,
          attemptNumber: count,
          backendType: 'sanity',
          ...extraRetryInfo,
        },
      });

      // retrying here is unlikely to fix us. retryWhenNetworkRestored will log this and try to replay once network comes back...
      if (staticConnectionStatus.current.isInternetReachable === false) {
        return false;
      }

      // TODO maybe include this code if we find similar findings to RBI backend below for !isBadNetworkError - lets find out with log data first.
      // if (!isBadNetworkError(fullError)) {
      //   // TODO probably remove this "noise" once we know there aren't any issues with this approach
      //   log({
      //     message: '[RetryLog] - Not retrying because this does not look like a bad network issue',
      //     isRetryLogExclusion: true,
      //     error,
      //     operationName: operation?.operationName,
      //     operationType,
      //     backendType,
      //   });
      //   return false;
      // }

      const maxRetries = getMaxRbiApiRetries();

      const isRetrying = isRetryAllowed && count <= getMaxSanityApiRetries();

      if (isRetrying) {
        (isRetrying ? logInfo : logError)(
          getLogCommon(
            isRetrying
              ? `[RetryLog] Retrying query ${operationName} attempt ${count}/${maxRetries}`
              : `[RetryLog] No Retry - Exceeded max retries for ${operationType} ${operationName} at attempt ${count}/${maxRetries}`,
            {
              isRetrying,
            }
          )
        );
        return true;
      }

      return isRetrying;
    } catch (error) {
      logger.info({ message: 'Unexpected error in sanity retry link, will not retry', error });
    }

    // something unexpected is happening - don't retry to be safe
    return false;
  },
  delay: { max: MAX_RETRY_DELAY },
});

const getMaxRbiApiRetries = () =>
  staticLDFlags.current[LaunchDarklyFlag.MAX_RBI_API_RETRIES] || DEFAULT_MAX_API_RETRIES;

export const rbiBackendRetryLink = new RetryLink({
  attempts: (count, operation, fullError) => {
    try {
      const { operationType, operationName, isRetryAllowed } = getGqlQueryInfo(operation);

      const getLogCommon = (message: string, extraRetryInfo: object) => ({
        message,
        error: fullError,
        retryInfo: {
          ...extraRetryInfo,
          operationName,
          attemptNumber: count,
          operationType,
          isRetryLog: true,
          backendType: 'rbi',
        },
      });

      // retrying here is unlikely to fix us. retryWhenNetworkRestored will log this and try to replay once network comes back...
      if (staticConnectionStatus.current.isInternetReachable === false) {
        return false;
      }

      // per log analysis -  500s, 400s rarely work regardless of retries and could hurt UX more than help it.
      if (!isBadNetworkError(fullError)) {
        // TODO probably remove this "noise" once we know there aren't any issues with this approach
        logInfo(
          getLogCommon(
            '[RetryLog] - Not retrying because this does not look like a bad network issue',
            {
              isRetryLogExclusion: true,
              isRetrying: false,
            }
          )
        );
        return false;
      }

      announceApiCallNetworkFailure();

      if (count > getMaxRbiApiRetries()) {
        const operationName = operation?.operationName;
        logError(
          getLogCommon(
            `[RetryLog] No Retry - Exceeded max retries for ${operationType} ${operationName} at attempt ${count}/${getMaxRbiApiRetries()}`,
            {
              isRetrying: false,
            }
          )
        );
        return false;
      }

      // only retry mutations in our allow list - cause like we don't want to make duplicate orders (etc)!
      if (operationType === 'mutation') {
        const operationName = operation?.operationName;
        (isRetryAllowed ? logInfo : logError)(
          getLogCommon(
            isRetryAllowed
              ? `[RetryLog] Retrying mutation ${operationName} attempt ${count}/${getMaxRbiApiRetries()}`
              : `[RetryLog] No Retry - Mutation ${operationName} not in allow list`,
            {
              isRetrying: isRetryAllowed,
            }
          )
        );
        return isRetryAllowed;
      }

      // queries don't mutate data so are safe to retry under all circumstances
      if (operationType === 'query') {
        const operationName = operation?.operationName;
        logInfo(
          getLogCommon(
            `[RetryLog] Retrying query ${operationName} attempt ${count}/${getMaxRbiApiRetries()}`,
            { isRetrying: true }
          )
        );

        return true;
      }
    } catch (error) {}

    // something unexpected is happening - don't retry to be safe
    return false;
  },
  delay: { max: MAX_RETRY_DELAY },
});

const logInfo = (logBody: Loggable | Array<Loggable>) => {
  // we want to see this in datadog in prod for now
  logger.info(logBody);
  if (!__DEV__ && isChaosModeEnabled) {
    // we want to see these in the console in prod mode if chaosmode is enabled to help understand whats going on
    // eslint-disable-next-line dot-notation
    global['console']['info'](logBody);
  }
};

const logError = (logBody: Loggable | Array<Loggable>) => {
  // we want to see this in datadog in prod for now
  logger.error(logBody);
  if (!__DEV__ && isChaosModeEnabled) {
    // we want to see these in the console in prod mode if chaosmode is enabled to help understand whats going on
    // eslint-disable-next-line dot-notation
    global['console']['error'](logBody);
  }
};
