import { IPoints } from '@rbi-ctg/frontend';
import { IsoCountryCode2Char } from 'utils/rbi-common';

type isoCountryCode2Char = IsoCountryCode2Char;

const mapDefaults: Record<isoCountryCode2Char | 'default', IPoints> = {
  /**
   * (CA)
   */
  [IsoCountryCode2Char.CA]: {
    lat: 49.76773,
    lng: -96.8097,
    zoom: 4,
  },
  /**
   * (US)
   */
  [IsoCountryCode2Char.US]: {
    lat: 39.8283459,
    lng: -98.5794797,
    zoom: 4,
  },
  default: {
    lat: 44.967243,
    lng: -103.771556,
    zoom: 4,
  },
};

export default mapDefaults;
