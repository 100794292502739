import { useCallback } from 'react';

import { useAuthContext } from 'state/auth';
import { RBIPlatform, platform } from 'utils/environment';
import logger from 'utils/logger';
import { decodeBase64String, parseStringifiedJSON } from 'utils/parse-string';

interface UseConfirmJwtReturn {
  handleConfirmJwt: (jwt: string, email: string) => Promise<void>;
}

export const useConfirmJwt = (): UseConfirmJwtReturn => {
  const { validateLogin } = useAuthContext();

  const confirmJwt = useCallback(
    async (username: string, jwt: string) => {
      try {
        await validateLogin({ username, jwt });
      } catch {
        // TODO: originally the user should be redirected to the home page.
      }
    },
    [validateLogin]
  );

  const handleConfirmJwt = useCallback(
    async (jwt: string, email: string) => {
      const jwtBody = jwt.split('.')[1];
      const currentPlatform = platform();
      const originalRequestPlatform: RBIPlatform | undefined = parseStringifiedJSON({
        // get jwt body here
        value: decodeBase64String({ value: jwtBody }) || '',
        defaultValue: {},
        onError: (error: Error) =>
          logger.warn({ error, message: 'Could not parse platform from auth jwt' }),
      }).platform;

      if (currentPlatform !== RBIPlatform.APP && originalRequestPlatform === RBIPlatform.APP) {
        return;
      }
      const username = decodeBase64String({ value: email }) || '';
      await confirmJwt(username, jwt);
    },
    [confirmJwt]
  );

  return {
    handleConfirmJwt,
  };
};
