
  /* eslint-disable */
export const sanityAssets = {

    US: {
        ['assets/images/sanity/7d482ef1b512df33dd3f38c0a605be28e1712ecf-16x18.png']: require('./assets/images/sanity/7d482ef1b512df33dd3f38c0a605be28e1712ecf-16x18.png'),
  ['assets/images/sanity/06a69e0c55e97f69bc726a42e3a944b5cf477ff8-16x18.png']: require('./assets/images/sanity/06a69e0c55e97f69bc726a42e3a944b5cf477ff8-16x18.png'),
  ['assets/images/sanity/4f5f9366a38571baec86febd55f0769bc348f9d7-22x22.png']: require('./assets/images/sanity/4f5f9366a38571baec86febd55f0769bc348f9d7-22x22.png'),
  ['assets/images/sanity/7f36745c29de4cbc382fa4a6687c094d90c9ebf9-22x22.png']: require('./assets/images/sanity/7f36745c29de4cbc382fa4a6687c094d90c9ebf9-22x22.png'),
  ['assets/images/sanity/31679639fd68b0a91b41a6f3078f8432cb2cf9f6-18x18.png']: require('./assets/images/sanity/31679639fd68b0a91b41a6f3078f8432cb2cf9f6-18x18.png'),
  ['assets/images/sanity/10c7bf80759531ba1a93dd527fe2c6b26c00a53d-18x18.png']: require('./assets/images/sanity/10c7bf80759531ba1a93dd527fe2c6b26c00a53d-18x18.png'),
  ['assets/images/sanity/9b95779aacae2e68e7776766f274463f70688499-20x20.png']: require('./assets/images/sanity/9b95779aacae2e68e7776766f274463f70688499-20x20.png'),
  ['assets/images/sanity/f7bdf8876045b7c6e9f89c9b5273ddd46e766a99-20x20.png']: require('./assets/images/sanity/f7bdf8876045b7c6e9f89c9b5273ddd46e766a99-20x20.png'),
  ['assets/images/sanity/daf5c681f2f8aafb1000074e9fa60c327055d59d-22x15.png']: require('./assets/images/sanity/daf5c681f2f8aafb1000074e9fa60c327055d59d-22x15.png'),
  ['assets/images/sanity/7ab073c738819404894e57116db68405f105b1ae-22x15.png']: require('./assets/images/sanity/7ab073c738819404894e57116db68405f105b1ae-22x15.png')
    },
      
    CA: {
        ['assets/images/sanity/7d482ef1b512df33dd3f38c0a605be28e1712ecf-16x18.png']: require('./assets/images/sanity/7d482ef1b512df33dd3f38c0a605be28e1712ecf-16x18.png'),
  ['assets/images/sanity/06a69e0c55e97f69bc726a42e3a944b5cf477ff8-16x18.png']: require('./assets/images/sanity/06a69e0c55e97f69bc726a42e3a944b5cf477ff8-16x18.png'),
  ['assets/images/sanity/4f5f9366a38571baec86febd55f0769bc348f9d7-22x22.png']: require('./assets/images/sanity/4f5f9366a38571baec86febd55f0769bc348f9d7-22x22.png'),
  ['assets/images/sanity/7f36745c29de4cbc382fa4a6687c094d90c9ebf9-22x22.png']: require('./assets/images/sanity/7f36745c29de4cbc382fa4a6687c094d90c9ebf9-22x22.png'),
  ['assets/images/sanity/31679639fd68b0a91b41a6f3078f8432cb2cf9f6-18x18.png']: require('./assets/images/sanity/31679639fd68b0a91b41a6f3078f8432cb2cf9f6-18x18.png'),
  ['assets/images/sanity/10c7bf80759531ba1a93dd527fe2c6b26c00a53d-18x18.png']: require('./assets/images/sanity/10c7bf80759531ba1a93dd527fe2c6b26c00a53d-18x18.png'),
  ['assets/images/sanity/9b95779aacae2e68e7776766f274463f70688499-20x20.png']: require('./assets/images/sanity/9b95779aacae2e68e7776766f274463f70688499-20x20.png'),
  ['assets/images/sanity/f7bdf8876045b7c6e9f89c9b5273ddd46e766a99-20x20.png']: require('./assets/images/sanity/f7bdf8876045b7c6e9f89c9b5273ddd46e766a99-20x20.png'),
  ['assets/images/sanity/daf5c681f2f8aafb1000074e9fa60c327055d59d-22x15.png']: require('./assets/images/sanity/daf5c681f2f8aafb1000074e9fa60c327055d59d-22x15.png'),
  ['assets/images/sanity/7ab073c738819404894e57116db68405f105b1ae-22x15.png']: require('./assets/images/sanity/7ab073c738819404894e57116db68405f105b1ae-22x15.png'),
  ['assets/images/sanity/daf5c681f2f8aafb1000074e9fa60c327055d59d-22x15.png']: require('./assets/images/sanity/daf5c681f2f8aafb1000074e9fa60c327055d59d-22x15.png'),
  ['assets/images/sanity/7ab073c738819404894e57116db68405f105b1ae-22x15.png']: require('./assets/images/sanity/7ab073c738819404894e57116db68405f105b1ae-22x15.png')
    },
      
};
  