import React from 'react';
import type { ComponentType } from 'react';

import { BoxProps } from '@rbilabs/universal-components';

import { useRoute } from 'hooks/navigation/use-route';
import { useMobileFooterComponent } from 'state/mobile-footer';
import { UUID_REGEX_STRING } from 'utils/constants';
import { routes } from 'utils/routing';

export type LayoutBottomContainerProps = BoxProps;

const LayoutBottomContainer: ComponentType<React.PropsWithChildren<LayoutBottomContainerProps>> = ({
  children,
  ...props
}) => {
  const { pathname } = useRoute();
  const footerComponent = useMobileFooterComponent();

  const mobileRoutesWithoutBottomNavbar = [
    routes.cart,
    routes.rewardsOnboarding,
    routes.signUp,
    routes.partnerships,
    routes.walmartLocation,
    routes.loyaltyChallengeDetails,
    new RegExp(`${routes.orderConfirmation}/${UUID_REGEX_STRING}`),
  ];

  if (footerComponent) {
    return footerComponent;
  } else if (mobileRoutesWithoutBottomNavbar.some(route => !!pathname.match(route))) {
    return null;
  }

  return <>{children}</>;
};

export default LayoutBottomContainer;
