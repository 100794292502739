import { IVendorConfig } from '@rbi-ctg/menu';

import { PluTypes } from './types';

export const getConstantPlu = (vendorConfig: IVendorConfig | null | undefined): string | null => {
  if (!vendorConfig || vendorConfig.pluType !== PluTypes.CONSTANT) {
    return null;
  }

  return vendorConfig.constantPlu;
};
