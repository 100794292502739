import { ICombo, IComboSlotOption, IModifierSelection, IPrices } from '@rbi-ctg/menu';
import { IPriceForComboSlotSelectionFn, IPriceForItemOptionModifierFn } from 'state/menu/types';
import { assignInclusionComboSlotSelections, isCombo, isItem } from 'utils/menu';
import { IComboSlotSelectionsNoDataInSelections } from 'utils/menu/price';
import { PosVendors } from 'utils/vendor-config';

import { priceForItemModifierSelections } from './price-for-item-modifier-selections';
import { pricingFunctionNoSelections } from './pricing-function-no-selections';

export const priceForComboSelections = ({
  combo,
  quantity = 1,
  modifierSelections = [],
  comboSlotSelections = {},
  prices,
  vendor,
  priceForItemOptionModifier,
  priceForComboSlotSelection,
}: {
  combo: ICombo | null;
  quantity: number;
  modifierSelections: Array<IModifierSelection>;
  comboSlotSelections: IComboSlotSelectionsNoDataInSelections;
  prices: IPrices;
  vendor: PosVendors | null;
  priceForItemOptionModifier: IPriceForItemOptionModifierFn;
  priceForComboSlotSelection: IPriceForComboSlotSelectionFn;
}): number => {
  if (!isCombo(combo)) {
    return 0;
  }

  const mainItemTotal: number = priceForItemModifierSelections({
    item: combo.mainItem,
    quantity: 1,
    modifierSelections: (modifierSelections || []).filter(({ comboSlotId }) => !comboSlotId),
    prices,
    priceForItemOptionModifier,
  });

  const comboSlotsTotal = (combo?.options || []).reduce<number>((comboSlotsSum, comboSlot) => {
    const { selections } = comboSlotSelections[comboSlot._id] || {};
    if (!selections) {
      return comboSlotsSum;
    }

    const assignedSelections = assignInclusionComboSlotSelections(selections, comboSlot.maxAmount);

    const selectionsTotal = assignedSelections.reduce<number>((selectionsSum, selection) => {
      const aLaCarteDeltaPrice = priceForComboSlotSelection({
        combo,
        comboSlot,
        selectedOption: selections.find(
          (sel: { option: IComboSlotOption }) =>
            sel.option.option._id === selection.option.option._id
        )?.option,
      });

      // Price for all selections not included in the combo (not part of the combo OR went over comboSlot max)
      const upsellPrice = selection.excludedAmount
        ? pricingFunctionNoSelections(
            selection.option.option,
            prices,
            vendor,
            selection.excludedAmount
          )
        : aLaCarteDeltaPrice;

      // Price for the selected modifiers
      const modifiersPrice = isItem(selection.option.option)
        ? priceForItemModifierSelections({
            item: selection.option.option,
            quantity: 1,
            modifierSelections: (modifierSelections || []).filter(
              ({ comboSlotId }) => comboSlotId === comboSlot._id
            ),
            prices,
            priceForItemOptionModifier,
          })
        : 0;

      return selectionsSum + upsellPrice + modifiersPrice * selection.quantity;
    }, 0);

    return comboSlotsSum + selectionsTotal;
  }, 0);

  return (mainItemTotal + comboSlotsTotal) * quantity;
};
