import { Box } from '@rbilabs/universal-components';

import Picture from 'components/picture';

//@TODO: GST-5568 - Modify StyledPicture and TextContentContainer to match with design
export const StyledPicture = Picture.withConfig({
  width: '100%',
  marginBottom: '$6',
});

export const TextContentContainer = Box.withConfig({
  textAlign: 'center',
  alignSelf: 'center',
  margin: '$4',
});

export const ActionButtonsContainer = Box.withConfig({
  marginX: '$12',
});
