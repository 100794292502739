import { useMemo } from 'react';

import { useLocale } from 'state/intl';
import { getApiKey } from 'utils/environment';

interface IUseApiKey {
  key: string;
  defaultValue?: string;
  isRegionalized?: boolean;
}

export const useApiKey = ({ key, defaultValue = '', isRegionalized = true }: IUseApiKey) => {
  const { region } = useLocale();

  const value = useMemo(
    () =>
      isRegionalized ? getApiKey({ key, region, defaultValue }) : getApiKey({ key, defaultValue }),
    [defaultValue, isRegionalized, key, region]
  );
  return value;
};
