import { useEffect, useMemo } from 'react';

import { QueryHookOptions } from '@apollo/client';
import { isNil } from 'lodash-es';

import { IServerOrder } from '@rbi-ctg/menu';
import {
  DeliveryStatus,
  IGetOrderQuery,
  IGetOrderQueryVariables,
  RbiOrderStatus,
  useGetOrderQuery,
} from 'generated/graphql-gateway';
import useEffectOnUnmount from 'hooks/use-effect-on-unmount';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { usePrevious } from 'hooks/use-previous';
import { OrderSuccessFailureStatuses } from 'state/order/constants';
import { orderPollFailure, orderPollSuccessful } from 'state/order/order-state-utils';
import noop from 'utils/noop';

import { handleOrderStatusChange } from './on-order-status-change';
import { IUseOrderStatusParams } from './types';

// default order status polling interval, in ms
const DEFAULT_POLL_INTERVAL = 1500;

export const useOrderStatus = ({
  failureStatuses = OrderSuccessFailureStatuses.failures,
  onSuccess = noop,
  orderStatusPollInterval = DEFAULT_POLL_INTERVAL,
  rbiOrderId,
  successStatuses = OrderSuccessFailureStatuses.success,
  // NOTE: currently this `skip` prop is being used as a means to "skip polling"
  // we need the query to run at least once in order to have `serverOrder`
  // defined for the remaining change status logic to work.
  // this value is only being used to evaluate whether we should start/stop polling
  // TODO: refactor all of this logic so that we don't need multiple instances of this hook
  skip,
  disableCache = false,
}: IUseOrderStatusParams) => {
  const rbiOrderIdIsNil = isNil(rbiOrderId);
  const queryParams: QueryHookOptions<IGetOrderQuery, IGetOrderQueryVariables> = {
    skip: rbiOrderIdIsNil,
  };

  if (rbiOrderId) {
    queryParams.variables = { id: rbiOrderId };
  }

  if (disableCache) {
    queryParams.fetchPolicy = 'no-cache';
  }

  const queryResult = useGetOrderQuery(queryParams);

  const { data, startPolling, stopPolling } = queryResult;

  const serverOrder = useMemo<IServerOrder | null>(
    // FIXME: Do not alias the order with IServerOrder, as this is prone to masking schema errors
    () => (data?.order ? ((data.order as unknown) as IServerOrder) : null),
    [data]
  );

  const orderStatus = serverOrder?.status ?? null;
  const orderDeliveryStatus = serverOrder?.delivery?.status ?? null;

  const prevOrderStatus = usePrevious<RbiOrderStatus | null>(orderStatus);
  const prevOrderDeliveryStatus = usePrevious<DeliveryStatus | null>(orderDeliveryStatus);

  const failure =
    serverOrder?.rbiOrderId === rbiOrderId &&
    orderPollFailure({
      deliveryFailureStatus: failureStatuses.delivery,
      order: serverOrder,
      orderFailureStatus: failureStatuses.pos,
    });

  const success =
    serverOrder?.rbiOrderId === rbiOrderId &&
    orderPollSuccessful({
      deliverySuccessStatus: successStatuses.delivery,
      order: serverOrder,
      orderSuccessStatus: successStatuses.pos,
    });

  // use pollInterval value to control whether we start/stop polling
  const pollInterval = skip || rbiOrderIdIsNil || success || failure ? 0 : orderStatusPollInterval;
  // stop polling if it's enabled and shouldn't be
  useEffect(() => {
    if (pollInterval > 0) {
      startPolling(pollInterval);
    } else {
      stopPolling();
    }
  }, [pollInterval, startPolling, stopPolling]);

  useEffectOnUpdates(() => {
    if (!serverOrder) {
      return;
    }

    handleOrderStatusChange({
      failure,
      onSuccess,
      orderStatus,
      orderDeliveryStatus,
      prevOrderStatus,
      prevOrderDeliveryStatus,
      serverOrder,
      success,
    });
  }, [
    failure,
    orderStatus,
    orderDeliveryStatus,
    prevOrderStatus,
    prevOrderDeliveryStatus,
    serverOrder,
    success,
  ]);

  useEffectOnUnmount(() => {
    stopPolling();
  });

  return {
    ...queryResult,
    failure,
    orderStatus,
    orderDeliveryStatus,
    prevOrderStatus,
    prevOrderDeliveryStatus,
    serverOrder,
    success,
  };
};
