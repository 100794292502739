import { initialLoyaltyState } from 'state/global-state/models/loyalty/loyalty.reducer';
import { initialOrderingState } from 'state/global-state/models/ordering/ordering.reducer';

export { store } from 'state/global-state/store';
export type { RootState, AppDispatch, ConfiguredStore } from 'state/global-state/store';

export { selectors } from 'state/global-state/selectors';
export { actions } from 'state/global-state/actions';
export { useAppSelector, useAppDispatch } from 'state/global-state/hooks';

export const initialGlobalState = {
  loyalty: initialLoyaltyState,
  ordering: initialOrderingState,
};
