import { ISanityBlockContent, ISanityTextNode } from '@rbi-ctg/menu';
import {
  IAppliedOffer,
  ILoyaltyEngineRewardsQuery,
  IOffersFragment,
  Maybe,
} from 'generated/graphql-gateway';
import {
  ICmsConfigRewardsQuery,
  IConfigOfferFragment,
  IFeatureLoyaltyOfferGroupsQuery,
  ILoyaltyRewardsByEngineIdQuery,
  IOfferGroupFragment,
  ISystemwideOffersFragment,
} from 'generated/sanity-graphql';
import { OffersServiceMode } from 'pages/loyalty/loyalty-offers/types';

export type SelectedRewardTierIdType = string | null | undefined;
export interface ILoyaltyCategoryOption {
  name: ISanityTextNode;
  description: ISanityBlockContent;
  points: number;
  _id: string;
}
export interface ILoyaltyCategory {
  _id: string;
  name: ISanityTextNode;
  description: ISanityBlockContent;
  options: Array<ILoyaltyCategoryOption>;
}

export interface ILoyaltyCategories {
  allLoyaltyConfigurations: {
    categories: ILoyaltyCategory;
  };
  errors?: Error[];
}

export interface ILoyaltyTier {
  name: ISanityTextNode;
  description: ISanityBlockContent;
  points: number;
  _id: string;
}

export interface ILoyaltyTiers {
  allLoyaltyConfigurations: {
    bankMyRewardTier: ILoyaltyTier;
  };
  errors?: Error[];
}

export type LoyaltyRewards =
  | ILoyaltyRewardsByEngineIdQuery['allRewards']
  | ICmsConfigRewardsQuery['allConfigRewards'];

export type LoyaltyReward = LoyaltyRewards[0];

export type LoyaltyEngineRewards = ILoyaltyEngineRewardsQuery['loyaltyRewardsV2'];

export type LoyaltyEngineReward = NonNullable<LoyaltyEngineRewards>[0];

export type LoyaltyOffer = (ISystemwideOffersFragment | IConfigOfferFragment) &
  Pick<IOffersFragment, 'isStackable' | 'metadata'> & {
    offerServiceMode?: OffersServiceMode;
    serviceModes?: any;
    errors?: any;
    inlineAlertMessage?: any;
  };

export type LoyaltyOfferGroup = IFeatureLoyaltyOfferGroupsQuery['allOfferGroups'] &
  IOfferGroupFragment;

export enum IncentiveSource {
  SUPPORT = 'SUPPORT',
  PROMOTION_CODE = 'PROMOTION_CODE',
}

export type LoyaltyAppliedOffer = IAppliedOffer & {
  isStackable?: Maybe<boolean>;
  cmsId?: Maybe<string>;
};

export function isReward(incentive: LoyaltyReward | LoyaltyOffer): incentive is LoyaltyReward {
  return incentive.__typename === 'Reward' || incentive.__typename === 'ConfigReward';
}

export function isLoyaltyOffer(
  incentive: LoyaltyReward | LoyaltyOffer | null
): incentive is LoyaltyOffer {
  return incentive?.__typename === 'SystemwideOffer' || incentive?.__typename === 'ConfigOffer';
}

// Offer Incentives must have an _id to be valid
export function isValidOfferIncentive(obj: any): obj is { _id: string } {
  return !!obj?._id;
}

export type IEngineRewardsMap = {
  [sanityId: string]: LoyaltyEngineReward;
};
export type IPersonalizedRewardMap = {
  [rewardId: string]: LoyaltyEngineReward;
};
export type ISanityRewardsMap = Record<LoyaltyReward['_id'], LoyaltyReward>;
