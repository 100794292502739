import { useCallback, useRef } from 'react';

export function useNavigateBack() {
  const backListeners = useRef<Array<() => void>>([]);

  const addNavigateBackListener = useCallback((listener: () => void): { remove(): void } => {
    backListeners.current = backListeners.current.concat(listener);

    return {
      remove() {
        backListeners.current = backListeners.current.filter(l => l !== listener);
      },
    };
  }, []);

  const back = useCallback(() => {
    backListeners.current.forEach(listener => listener());
    return window.history.back();
  }, []);

  return { addNavigateBackListener, back };
}
