import { Box, Icon, Text } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';
import { primitive } from 'styles/constants/primitives';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

export const CardsContainer = Box.withConfig({
  _web: {
    alignSelf: 'center',
    width: 'full',
    maxWidth: '912px',
    paddingX: '$4',
  },
});

export const StyledBackIcon = Icon.withConfig({
  color: theme.token('icon-header-contrast'),
  size: '5',
  ...hiddenAccessibilityPlatformProps,
});

export const UnreadSeparator = Box.withConfig({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingX: '$2',
});

export const CenteredLine = Box.withConfig({
  flexGrow: 1,
  borderTopWidth: 1,
  borderTopStyle: 'solid',
  borderColor: primitive.$blackOpacity50,
  marginX: '$2',
});

export const UnreadSeparatorText = Text.withConfig({
  color: primitive.$blackOpacity50,
});

// TODO: BKPE-8662 - this container is only for testing. It will be replaced by the ContetCard components
export const ContentCardContainer = Box.withConfig(({ viewed }: { viewed: boolean }) => ({
  borderWidth: '1px',
  borderColor: 'black',
  borderStyle: 'solid',
  margin: '10px',
  padding: '10px',
  background: viewed ? 'gray' : 'white',
}));
