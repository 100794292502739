import { IRestaurant } from '@rbi-ctg/store';
import { IStoreAddressInput } from 'generated/graphql-gateway';

export const buildStoreAddress = (store: IRestaurant): IStoreAddressInput => {
  const { address1, address2, city, country, postalCode, stateProvince } =
    store.physicalAddress ?? {};

  return {
    addressLine1: address1 ?? '',
    addressLine2: address2,
    city: city ?? '',
    country: country ?? '',
    zip: postalCode ?? '',
    state: stateProvince ?? '',
    phoneNumber: store.phoneNumber ?? '',
  };
};
