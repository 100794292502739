import store from 'store';

import { StatusType, dataDogLogger } from 'utils/datadog';
import logger, { LogLevel } from 'utils/logger';
import noop from 'utils/noop';

import { Keys, LOCAL_STORAGE_VERSION } from './constants';
import { getItemWithExpirationTime, setItemWithExpirationTime } from './shared';

export class WebLocalStorage {
  // increment this value any time the shape of
  // data stored in localStorage changes
  static readonly currentVersion = LOCAL_STORAGE_VERSION;

  static getAll = (): Promise<{ [key: string]: any }> => {
    return Promise.resolve(
      Object.values(Keys).reduce((acc, key) => ({ ...acc, [key]: store.get(key) }), {})
    );
  };

  static getItem = <V = any>(key: Keys): V | null => {
    return store.get(key) || null;
  };

  static setItem = <V = any>(key: Keys, value: V) => {
    try {
      store.set(key, value);
    } catch (error) {
      const message = 'Local Storage setItem Error';
      dataDogLogger({ message, status: LogLevel[StatusType.error] });
      logger.warn({ error, message });
    }
  };

  static setItemWithExpiration = <V = any>(key: Keys, value: V, exp: number) =>
    setItemWithExpirationTime(key, value, exp, this.setItem);

  static getItemWithExp = <V = any>(key: Keys): V | null =>
    getItemWithExpirationTime(key, this.getItem, this.removeItem);

  static isCurrentVersion = () =>
    Number(WebLocalStorage.getStoredVersion()) === LOCAL_STORAGE_VERSION;

  static clear = ({ excludeKeys = [] }: { excludeKeys?: Keys[] } = {}) => {
    const allExcludeKeys = [...excludeKeys, Keys.STORAGE_VERSION];

    Object.values(Keys).forEach(storageKey => {
      if (!allExcludeKeys.includes(storageKey)) {
        store.remove(storageKey);
      }
    });
  };

  static clearAll = () => {
    store.clearAll();
  };

  static removeItem = (storageKey: string) => {
    store.remove(storageKey);
  };

  static setCurrentVersion = () => store.set(Keys.STORAGE_VERSION, LOCAL_STORAGE_VERSION);

  private static getStoredVersion = () => store.get(Keys.STORAGE_VERSION);

  static sync(): Promise<void> {
    return Promise.resolve(noop());
  }
}
