import * as React from 'react';

import { Header } from '@rbilabs/universal-components';

import Modal, { ModalContent, ModalSize } from 'components/modal';
import { theme } from 'styles/configure-theme';

import { IQuestDetailModalProps } from './types';

export const QuestDetailModal = ({
  title,
  onDismiss,
  children,
  hideTitle = false,
}: IQuestDetailModalProps) => {
  return (
    <Modal
      size={ModalSize.FULLSCREEN}
      mParticleEventData={{
        modalAppearanceEventMessage: title,
      }}
      onDismiss={onDismiss}
      backgroundColor={theme.token('background-dialog')}
      header={
        hideTitle ? null : (
          <Header
            variant="headerTwo"
            alignSelf="center"
            fontSize="xl"
            paddingX="$4"
            marginY="$10"
            paddingY="$4"
          >
            {title}
          </Header>
        )
      }
    >
      <ModalContent
        alignSelf="center"
        maxWidth={{ base: 'full', md: '3/4' }}
        paddingY={hideTitle ? '$16' : '$0'}
      >
        {children}
      </ModalContent>
    </Modal>
  );
};
