import { v4 as uuidv4 } from 'uuid';

import { getFdProxyAndUrl } from 'remote/api/get-fd-proxy-and-url';
import { encryptCredit } from 'utils/encryption';
import { isLocalDev } from 'utils/environment';
import { IPaymentPayload } from 'utils/payment';

import { FirstDataError } from '../exceptions';

/**
 * The First Data endpoint doesn't support CORS for localhost,
 * so during local dev we hit a proxy on our dev server.
 * Stable environments (dev, staging, prod) can hit the API directly from the browser.
 */
export const getFirstDataBaseUrl = (fdUrl: string) => {
  const [proxy, firstData] = getFdProxyAndUrl(fdUrl);
  // NOTE: cypress-v2 test suite requirement
  // Use the same proxy as local development when Cypress is running.
  // Allows Cypress in CI to mimic the recorded request patterns.
  return isLocalDev || window.Cypress ? proxy : firstData;
};

// TODO: validate type
type IGetNonceAccountCredit = Pick<
  IPaymentPayload,
  'cardNumber' | 'expiryDate' | 'securityCode'
> & {
  billingAddress: IPaymentPayload['billingAddress'] | { postalCode: string };
};

interface IGetNonce {
  account: {
    // TODO: validate type
    credit: IGetNonceAccountCredit;
    type: string;
  };
  deviceInfo: {
    id: string;
    kind: string;
  };
  referenceToken: {
    tokenType: string;
  };
  fdCustomerId?: string;
}

export const getNonce = async (
  credit: IPaymentPayload,
  fdPublicKey: string,
  fdApiKey: string,
  fdAccessToken: string,
  fdCustomerId: string | null,
  fdUrl: string,
  onlySendPostalCode?: boolean,
  algorithm?: string
) => {
  const encryptedCredit = await encryptCredit({
    credit,
    fdPublicKey,
    algorithm,
  });
  // billing address sent to First Data may only require postal code
  const postalCode = credit.billingAddress.postalCode;
  const billingAddress = onlySendPostalCode ? { postalCode } : credit.billingAddress;

  const encryptedCreditWithCorrectBilling: IGetNonceAccountCredit = {
    ...encryptedCredit,
    billingAddress,
  };

  const headers = {
    Authorization: `Bearer ${fdAccessToken}`,
    'Content-Type': 'application/json',
    Timestamp: (new Date().getTime() as unknown) as string,
    'Client-Request-Id': uuidv4(),
    'Api-Key': fdApiKey,
  };

  const body: IGetNonce = {
    account: {
      credit: encryptedCreditWithCorrectBilling,
      type: 'CREDIT',
    },
    deviceInfo: {
      id: uuidv4(),
      kind: 'native',
    },
    referenceToken: {
      tokenType: 'CLAIM_CHECK_NONCE',
    },
  };

  if (fdCustomerId) {
    body.fdCustomerId = fdCustomerId;
  }

  const firstDataBaseUrl = getFirstDataBaseUrl(fdUrl);
  const response = await fetch(`${firstDataBaseUrl}/ucom/v1/account-tokens`, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  });

  if (response.ok) {
    return response;
  }

  throw new FirstDataError('Error getting nonce', new Error(response.statusText));
};
