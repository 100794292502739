import { Box, Header, makeUclComponent } from '@rbilabs/universal-components';

import ActionButton from 'components/action-button';
import { DisclaimerContainer } from 'components/offer-disclaimer/offer-disclaimer';
import Picture from 'components/picture';
import SanityBlockRenderer from 'components/sanity-block-renderer';

export const StyledBackgroundImage = Picture.withConfig({
  position: 'absolute',
  left: 0,
  top: 0,
  width: 'full',
  height: '100%',
});

export const StyledImage = Picture.withConfig({
  width: '80%',
  marginTop: '$4',
  zIndex: Styles.zIndex.normal,
  height: '$32',
});

export const StyledOfferName = Header.withConfig({
  textAlign: 'center',
  fontSize: '2xl',
  lineHeight: 'md',
  marginTop: '$4',
  maxWidth: '960px',
});

export const StyledOfferDescription = SanityBlockRenderer.withConfig({
  color: Styles.color.grey.one,
  textProps: {
    textAlign: 'center',
    fontSize: 'sm',
  },
});

export const ButtonsWrapper = Box.withConfig<{ $error?: boolean }>(props => ({
  marginTop: props.$error ? '0' : '$4',
  alignItems: 'center',
  zIndex: Styles.zIndex.top,
  paddingTop: {
    base: props.$error ? '$4' : '$8',
    md: '$8',
  },
  paddingX: '$4',
  paddingBottom: '$4',
  ...props,
}));

export const StyledActionButton = makeUclComponent(ActionButton);

export const StyledDisclaimer = DisclaimerContainer.withConfig({
  marginTop: '$4',
});
