import { useEffect } from 'react';

import { useRoute } from 'hooks/navigation/use-route';
import { usePrevious } from 'hooks/use-previous';

export const useEffectOnUrlChange = (callback: () => void) => {
  const { pathname } = useRoute();
  const previousPathname = usePrevious(pathname);

  useEffect(() => {
    if (previousPathname !== pathname) {
      callback();
    }
    // disabling the line to avoid unnecessary executions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, previousPathname]);
};
