import { PosVendors } from './types';

const vendorsWithMainItemPlu = new Set([
  PosVendors.NCR,
  PosVendors.NCR_DELIVERY,
  PosVendors.QDI,
  PosVendors.QDI_DELIVERY,
]);

export const shouldUseMainItemCompositePlu = (vendor: PosVendors | null) =>
  !!vendor && vendorsWithMainItemPlu.has(vendor);
