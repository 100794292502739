import React, { useMemo } from 'react';

import { Pressable, Text } from '@rbilabs/universal-components';

import { ClickableContainer } from 'components/clickable-container';

import { IFilter } from './types';

const Container = ClickableContainer.withConfig({
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  paddingTop: {
    base: '$4',
    md: '$2',
  },
  paddingX: {
    base: '$2',
    md: '$4',
  },
  minHeight: {
    base: 0,
    md: '$10',
  },
});

const Tag = Pressable.withConfig({
  paddingX: '$2',
  paddingY: '$1',
  marginRight: '$1',
  marginBottom: '$1',
  backgroundColor: '__legacyToken.background-chip',
  borderRadius: 'sm',
  _web: {
    cursor: 'pointer',
  },
});

const TagContent = Text.withConfig({
  variant: '__legacyToken.text-style-chip',
  fontWeight: 'bold',
  fontFamily: Styles.fontFamily.base,
  color: '__legacyToken.text-reversed',
  textTransform: 'uppercase',
  fontSize: 'xs',
});

interface ITags {
  activeModalFilters: IFilter[];
  openFilterModal: () => void;
}

export const Tags: React.FC<React.PropsWithChildren<ITags>> = ({
  activeModalFilters,
  openFilterModal,
}) => {
  const filterTags = useMemo(() => {
    return activeModalFilters.map(filter => filter.description.locale);
  }, [activeModalFilters]);

  if (!filterTags.length) {
    return null;
  }

  return (
    <Container testID="tags-container">
      {filterTags.map(tag => (
        <Tag key={tag} onPress={openFilterModal}>
          <TagContent>{tag}</TagContent>
        </Tag>
      ))}
    </Container>
  );
};
