import { PropsWithChildren } from 'react';

import { Header as UCHeader, makeUclComponent } from '@rbilabs/universal-components';

import ActionButton, { IActionButtonProps } from 'components/action-button';
import Modal, { ModalContent as UCModalContent } from 'components/modal';
import { isMobile } from 'utils/environment';

export const Header = UCHeader.withConfig({
  mb: '$8',
  variant: 'headerOne',
  testID: 'modal-heading',
  textAlign: 'center',
  _web: {
    marginTop: '$16',
  },
});

export const GiftCardModalContent = UCModalContent.withConfig({
  alignSelf: 'center',
  alignItems: 'center',
  width: 'full',
  mt: '$24',
  maxWidth: {
    md: '50%',
    lg: '30%',
  },
});

export const SubmitButton = ({ children, ...props }: PropsWithChildren<IActionButtonProps>) => (
  <ActionButton
    fullWidth
    _web={{
      width: isMobile() ? 'full' : '80%',
      marginTop: '$5',
    }}
    marginTop="$2"
    {...props}
  >
    {children}
  </ActionButton>
);

export const StyledModal = makeUclComponent(Modal).withConfig({
  headerStyle: {
    _web: {
      height: '$13.5',
      shadow: '1',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
  },
});
