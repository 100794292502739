import { ReactNode } from 'react';

export interface ITopServiceModeProps {
  heading: string;
  details: ReactNode;
  icon: string;
  rbiOrderId: string | null;
  variant?: TopServiceVariants;
  className?: string;
  isLoading?: boolean;
  openModal?: () => void;
}

export interface ITopServiceModeDetail extends ITopServiceModeProps {
  refetchUserOrder: () => void;
}

export enum TopServiceVariants {
  DEFAULT = 'DEFAULT',
  IN_PROGRESS = 'IN_PROGRESS',
  NO_STORE = 'NO_STORE',
}
