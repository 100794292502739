import { Box, Text, addWithConfig } from '@rbilabs/universal-components';

import ActionButton from 'components/action-button';
import BellIcon from 'components/icons/bell-icon';
import { primitive } from 'styles/constants/primitives';

export const StyledBellIcon = BellIcon.withConfig(() => ({
  size: '$5',
  position: 'relative',
}));

export const UnreadNotificationBadge = Box.withConfig(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  left: '-16px',
  top: '-18px',
  minWidth: '$4',
  height: '$4',
  background: primitive.bk.$fieryRed,
  borderRadius: 'full',
}));

export const BadgeText = Text.withConfig({
  color: 'white',
  fontSize: '2xs',
  lineHeight: '2xs',
  paddingX: '$1',
  height: '$2.5',
});

export const StyledNotificationButton = addWithConfig(ActionButton).withConfig({
  paddingLeft: '$3.5',
  paddingRight: 0,
  _web: {
    paddingRight: '$1.5',
  },
});
