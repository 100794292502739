import { useCallback } from 'react';

import { ApolloError } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';

import {
  IPrepaidByNumberQueryVariables,
  PrepaidByNumberDocument,
  usePrepaidByNumberLazyQuery,
} from 'generated/graphql-gateway';
import logger from 'utils/logger';

type Variables = IPrepaidByNumberQueryVariables;

const MutationName = getOperationName(PrepaidByNumberDocument)!;

export const usePrepaidByNumber = () => {
  const onError = useCallback((error: ApolloError) => {
    const { graphQLErrors: errors } = error;
    if (errors.length) {
      logger.error({
        errors,
        message: 'Error retrieving prepaid balance.',
        queryName: MutationName,
      });
    }
  }, []);

  const [prepaidByNumber, { loading }] = usePrepaidByNumberLazyQuery({
    onError,
  });
  const getPrepaidByNumber = useCallback(
    async ({ cardNumber }: Variables) => {
      try {
        const { data } = await prepaidByNumber({ variables: { cardNumber } });
        return { data: data?.prepaidByNumber };
      } catch (err) {
        return { errors: [err] };
      }
    },
    [prepaidByNumber]
  );

  return { getPrepaidByNumber, loading };
};
