import React, { FC } from 'react';

import { Icon } from '@rbilabs/universal-components';

import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

import {
  Header,
  HeaderLink,
  HeaderTextWrapper,
  IconWrapper,
  SectionContainer,
} from './responsive-carousel-wrapper.styled';
import { IResponsiveCarouselWrapperProps } from './types';

export const ResponsiveCarouselWrapper: FC<React.PropsWithChildren<
  IResponsiveCarouselWrapperProps
>> = ({ header, linkText, linkPath, linkAccessibilityLabel, children, ...rest }) => {
  return (
    <SectionContainer {...rest}>
      <HeaderTextWrapper>
        <Header accessible={true} accessibilityLabel={header}>
          {header}
        </Header>
        {linkPath && linkText && (
          <HeaderLink
            to={linkPath}
            accessibilityLabel={linkAccessibilityLabel}
            accessibilityRole="link"
          >
            {linkText}
            <IconWrapper>
              <Icon variant="forward" size="$5" {...hiddenAccessibilityPlatformProps} />
            </IconWrapper>
          </HeaderLink>
        )}
      </HeaderTextWrapper>
      {children}
    </SectionContainer>
  );
};
