import * as React from 'react';
import { FC } from 'react';

import { Box, FormControl } from '@rbilabs/universal-components';

import { useIdCounter } from 'hooks/use-id-counter';

import { InputMessage } from '../input-message';

import { IFieldSetProps } from './types';

/**
 * Groups several form fields and their labels together
 */
const FieldSet: FC<React.PropsWithChildren<IFieldSetProps>> = ({
  disabled,
  label,
  children,
  errorMessage,
  hintMessage,
  messageId: messageIdProp,
  testID,
  ...props
}) => {
  const defaultMessageId = useIdCounter('fieldset-message');
  const messageId = messageIdProp || defaultMessageId;

  return (
    <Box width="full" p="0" m="0" accessibilityLabel="group" {...props}>
      <FormControl.Label isDisabled={disabled}>{label}</FormControl.Label>
      {children}
      <InputMessage disabled={disabled} id={messageId} hasError={!!errorMessage} testID={testID}>
        {!errorMessage && hintMessage}
      </InputMessage>
    </Box>
  );
};

export default FieldSet;
