import { HeaderProps, TextProps } from '@rbilabs/universal-components';

import { ISanityTypographyBlock } from '@rbi-ctg/menu';

export enum HorizontalPosition {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export interface ITypographyBlockProps
  extends Omit<TextProps, 'variant'>,
    Omit<HeaderProps, 'variant'> {
  content: ISanityTypographyBlock[];
  color?: string;
  horizontalPosition?: HorizontalPosition;
}

export interface IColorable {
  color: string;
}

export interface IHorizontalPositionable {
  horizontalPosition: HorizontalPosition;
}
