import { Box, Header, Image, Text, VStack } from '@rbilabs/universal-components';

export const Container = Box.withConfig({
  justifyContent: 'center',
  alignSelf: 'center',
  width: {
    base: 'auto',
    md: 935,
  },
});

export const OfferContainer = Box.withConfig({
  background: 'token.background-pattern',
  rounded: 10,
  borderWidth: 0,
  borderColor: 'transparent',
  marginBottom: '$4',
  marginLeft: '$4',
  marginRight: '$4',
  padding: '$1',
});

export const OfferItem = VStack.withConfig({
  padding: '$1',
  paddingTop: '$3',
  borderTopRadius: 10,
  borderBottomRadius: 10,
  background: 'transparent',
});

export const HeaderBkAndWalmart = Header.withConfig({
  textAlign: 'center',
  fontSize: 'xl',
  marginTop: '$0',
});

export const HeaderReadySavings = Header.withConfig({
  textAlign: 'center',
  lineHeight: 'md',
  fontSize: '3xl',
  marginTop: '$0',
  marginX: '$4',
});

export const LinkRoyalText = Text.withConfig({
  margin: '$3',
  marginLeft: {
    base: '$4',
  },
  textAlign: {
    base: 'left',
    md: 'center',
  },
});

export const ItemContent = VStack.withConfig({
  flexShrink: 1,
  ml: '$3',
  alignItems: 'flex-start',
  justifyContent: 'center',
});

export const Title = Header.withConfig({
  variant: 'headerThree',
  color: 'token.text-default',
  ellipsizeMode: 'tail',
  numberOfLines: 2,
  mb: '$2',
});

export const Subtitle = Text.withConfig({
  fontSize: 'xs',
  lineHeight: 'sm',
  numberOfLines: 2,
  ellipsizeMode: 'tail',
});

export const Badges = Box.withConfig({ flexDirection: 'row', marginTop: '$2' });
export const OfferBadge = Box.withConfig({
  borderRadius: 4,
  width: 110,
  height: '$6',
  backgroundColor: 'trueGray.100',
  padding: '$1',
  marginLeft: '$2',
  _text: {
    fontSize: 'xs',
    lineHeight: 'sm',
  },
});

export const StyledPicture = Image.withConfig({
  width: '96px',
  height: '96px',
});
