import {
  IItem,
  IItemOption,
  IPrices,
  ISanityCombo,
  ISanityComboSlot,
  ISanityItem,
  IVendorConfig,
  IWithPricingProps,
} from '@rbi-ctg/menu';
import {
  IWithVendorConfig,
  PluTypes,
  PosVendors,
  computeCompositeModifierPlu,
  concatenateSizePlu,
  getQuantityBasedPlu,
  getVendorConfig,
} from 'utils/vendor-config';

import { IItemOptionModifierIsAvailable, IItemOptionModifierPluIsAvailable } from './types';

type AvailabilityFn<D> = (
  data: D,
  vendor: PosVendors | null,
  prices: IPrices,
  options?: IAvailabilityConfig
) => boolean;

interface IAvailabilityConfig {
  injectDefault?: boolean;
  isMainItem?: boolean;
  quantity?: number;
}

const getVendorConfigForData = (
  item: IWithVendorConfig,
  vendor: PosVendors | null
): IVendorConfig | null => {
  if (!item || !item.vendorConfigs) {
    return null;
  }

  const vendorConfig = getVendorConfig(item, vendor);

  if (!vendorConfig) {
    return null;
  }

  return vendorConfig;
};

export const itemIsAvailable: AvailabilityFn<IWithVendorConfig> = (
  item,
  vendor,
  prices,
  options: IAvailabilityConfig = {}
) => {
  const vendorConfig = getVendorConfigForData(item, vendor);

  if (!vendorConfig) {
    return false;
  }

  // In some cases the prices are not available,
  // this should not block the reorder process.
  if (prices) {
    const available = vendorConfigIsAvailable(vendorConfig, prices, options);
    if (!available) {
      return false;
    }
  }

  return true;
};

export const comboIsAvailable: AvailabilityFn<ISanityCombo> = (combo, vendor, prices) => {
  if (!itemIsAvailable(combo, vendor, prices)) {
    return false;
  }

  if (combo.mainItem && !itemIsAvailable(combo.mainItem, vendor, prices)) {
    return false;
  }

  const allComboSlotsAvailable = combo?.options?.every(comboSlot => {
    return comboSlotIsAvailable(comboSlot, vendor, prices);
  });

  if (!allComboSlotsAvailable) {
    return false;
  }

  return true;
};

export const comboSlotOptionIsAvailable: AvailabilityFn<ISanityItem> = (
  item,
  vendor,
  prices,
  options: IAvailabilityConfig = {}
) => {
  // Check the item is available, in all cases if the item is unavailable its always unavailable
  const itemVendorConfig = getVendorConfigForData(item, vendor);

  if (!itemVendorConfig) {
    return false;
  }

  // In some cases the prices are not available,
  // this should not block the reorder process.
  if (prices) {
    const itemAvailable = vendorConfigIsAvailable(itemVendorConfig, prices, options);
    if (!itemAvailable) {
      return false;
    }
  }

  // If all checks pass this comboSlotOption (item) is available for this combo
  return true;
};

export const comboSlotIsAvailable: AvailabilityFn<ISanityComboSlot> = (
  comboSlot,
  vendor,
  prices
) => {
  if (!itemIsAvailable(comboSlot, vendor, prices)) {
    return false;
  }

  const minRequirement = comboSlot.minAmount;

  const availableOptions = comboSlot.options.reduce(
    (acc, option) =>
      comboSlotOptionIsAvailable(option.option as ISanityItem, vendor, prices, {
        quantity: comboSlot.minAmount || 1,
      })
        ? acc + option.maxAmount
        : acc,
    0
  );

  if (availableOptions < minRequirement) {
    return false;
  }

  return true;
};

export const itemOptionModifierIsAvailable = ({
  item,
  itemOption,
  itemOptionModifier,
  prices,
  vendor,
}: IItemOptionModifierIsAvailable & { itemOption: IItemOption }): boolean => {
  const pluIsAvailableForItemOptionModifier = itemOptionModifierPluIsAvailable({
    item,
    itemOptionModifier,
    prices,
    vendor,
  });

  // for item option modifiers we should only care about plu availability when
  // "inject default selection" is true, OR the item is not the default.
  // if "inject default selection" is false, default modifiers are assumed to
  // be available if the item they belong to is available.
  if (itemOption.injectDefaultSelection) {
    return pluIsAvailableForItemOptionModifier;
  }

  return itemOptionModifier.default || pluIsAvailableForItemOptionModifier;
};

interface IItemOptionIsAvailable extends IWithPricingProps {
  item: IItem;
  itemOption: IItemOption;
}

export const itemOptionIsAvailable = ({
  item,
  itemOption,
  vendor,
  prices,
}: IItemOptionIsAvailable): boolean => {
  if (!itemOption || !itemOption.options || itemOption.options.length === 0) {
    return false;
  }

  const availableOptions: number = itemOption.options.filter(itemOptionModifier =>
    itemOptionModifierIsAvailable({
      item,
      itemOption,
      itemOptionModifier,
      prices,
      vendor,
    })
  ).length;

  return availableOptions > 0;
};

export const pluIsAvailable = (plu: string | null, prices: IPrices): boolean =>
  !!plu && !!prices && plu in prices && !isNaN(+prices[plu]);

export const vendorConfigIsAvailable = (
  vendorConfig: IVendorConfig,
  prices: IPrices,
  options: IAvailabilityConfig = {}
): boolean => {
  switch (vendorConfig.pluType) {
    case PluTypes.CONSTANT:
      return pluIsAvailable(vendorConfig.constantPlu, prices);
    case PluTypes.IGNORE:
      return true;
    case PluTypes.MULTI_CONSTANT:
      return (vendorConfig.multiConstantPlus || []).every(({ plu }) => pluIsAvailable(plu, prices));
    case PluTypes.PARENT_CHILD:
      return (
        pluIsAvailable(vendorConfig.parentChildPlu.plu, prices) &&
        pluIsAvailable(vendorConfig.parentChildPlu.childPlu, prices)
      );
    case PluTypes.QUANTITY:
      return pluIsAvailable(
        getQuantityBasedPlu(vendorConfig.quantityBasedPlu, options.quantity),
        prices
      );
    case PluTypes.SIZE_BASED:
      return pluIsAvailable(concatenateSizePlu(vendorConfig.sizeBasedPlu), prices);
    default:
      return false;
  }
};

export const itemOptionModifierPluIsAvailable = ({
  item,
  itemOptionModifier,
  vendor,
  prices,
}: IItemOptionModifierPluIsAvailable): boolean => {
  // If itemPlu-modifierPlu is available or modifierPlu is available the product is available
  const vendorConfig = getVendorConfig(itemOptionModifier, vendor);

  if (vendorConfig && vendorConfig.pluType === PluTypes.IGNORE) {
    return true;
  }

  const compositePlu = computeCompositeModifierPlu({
    item,
    modifier: itemOptionModifier,
    vendor,
  });

  return (
    pluIsAvailable(compositePlu, prices) || itemIsAvailable(itemOptionModifier, vendor, prices)
  );
};
