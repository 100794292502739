import { Box, Text } from '@rbilabs/universal-components';

import { primitive } from 'styles/constants/primitives';

export const AlertContainer = Box.withConfig({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  backgroundColor: primitive.$blackOpacity4,
  paddingLeft: '$4',
  paddingRight: '$4',
  paddingTop: '$2',
  paddingBottom: '$2',
  marginTop: '$2',
});

export const AlertText = Text.withConfig({
  variant: 'bodyOne',
  fontSize: 'xs',
  fontWeight: 'medium',
  color: primitive.bk.$bbqBrown,
});
