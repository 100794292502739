import { Box, HStack, Header, Pressable, Text } from '@rbilabs/universal-components';

export const Container = Box.withConfig({
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2',
  height: '16',
  backgroundColor: Styles.color.contrastBackground,
  shadow: '2',
  _web: {
    cursor: 'pointer',
  },
});

export const NotificationWrapper = HStack.withConfig({
  alignItems: 'center',
  maxWidth: Styles.layout.sectionMaxWidth,
  color: Styles.color.white,
  width: 'full',
});

export const IconContainer = Box.withConfig({
  marginLeft: '2',
});

export const DetailsContainer = Pressable.withConfig({
  flex: '1',
  marginLeft: '4',
});

export const Heading = Header.withConfig({
  variant: 'headerTwo',
  color: Styles.color.white,
  marginBottom: '0',
});

export const Details = Text.withConfig({
  color: Styles.color.white,
  fontSize: 'xs',
});

export const Close = Pressable.withConfig({
  marginRight: '$2',
});

export const ActionContainer = Box.withConfig({});
