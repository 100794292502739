import * as React from 'react';

import {
  Box,
  HStack,
  Pressable,
  Text,
  VStack,
  makeUclComponent,
} from '@rbilabs/universal-components';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

import Picture from 'components/picture';
import { useMenuCalories } from 'hooks/menu/use-menu-calories';
import { useUIContext } from 'state/ui';
import { theme } from 'styles/configure-theme';

import type { IPressableItemCardProps } from './types';

export const PressableItemCard = makeUclComponent(
  ({ image, altImage = '', name, price, calories, icon, ...props }: IPressableItemCardProps) => {
    const { disabled } = props;
    const { formatCurrencyForLocale } = useUIContext();
    const { formatCalories } = useMenuCalories();

    return (
      <Pressable flexDirection="row" padding="$2" justifyContent="space-between" {...props}>
        <HStack flex={1} space="$4" {...(disabled && { opacity: 0.5 })}>
          <Box w="$20" justifyContent="center">
            <Picture w="full" image={image} alt={altImage} />
          </Box>

          <VStack flex={1} space="$1" justifyContent="center">
            <Text variant="headerThree" margin="0">
              {name}
            </Text>

            <HStack space="$2">
              {isNumber(price) && <Text variant="copyTwo">{formatCurrencyForLocale(price)}</Text>}
              {isString(price) && <Text variant="copyTwo">{price}</Text>}
              {isNumber(calories) && (
                <Text variant="copyTwo" color={theme.token('text-disabled')}>
                  {formatCalories(calories)}
                </Text>
              )}
            </HStack>
          </VStack>
        </HStack>

        <Box marginLeft="$4" justifyContent="center" alignItems="flex-end">
          {icon}
        </Box>
      </Pressable>
    );
  }
);
