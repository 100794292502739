import LocalStorage from 'utils/cognito/storage.web';
import { StorageKeys } from 'utils/local-storage';
import logger from 'utils/logger';

const defaultChaosModeEnabledSettings = {
  failureRate: 0.2, // 20%
  addLatencyRate: 0.5, // 50%
  maxLatency: 6000,
};

type ChaosModeSettings = typeof defaultChaosModeEnabledSettings;

// prevent console log remover babel from removing this as its nice to have for testing
const logChaos = (message: string) => {
  // eslint-disable-next-line dot-notation
  global['console']['warn'](message);
};

const chaosModeSettings = (() => {
  const chaosModeSettingsString = LocalStorage.getItem(StorageKeys.DEBUG_CHAOS_MODE);
  if (!chaosModeSettingsString) {
    return null;
  }

  try {
    const parsed = JSON.parse(chaosModeSettingsString);
    if (parsed === true) {
      logger.warn('DEBUG_CHAOS_MODE enabled with default settings');
      return defaultChaosModeEnabledSettings;
    }
    logger.warn('DEBUG_CHAOS_MODE enabled with custom settings');
    return parsed as ChaosModeSettings;
  } catch (error) {
    logger.warn('error parsing DEBUG_CHAOS_MODE settings, not enabling');
  }

  return null;
})();

export const isChaosModeEnabled = !!chaosModeSettings;

if (chaosModeSettings) {
  const { failureRate, addLatencyRate, maxLatency } = chaosModeSettings;

  const oldFetch = global.fetch;

  const getIsRequiredDevUrl = (url: string) =>
    ['symbolicate', 'localhost'].some(x => url?.includes(x));

  global.fetch = async function () {
    const url = ((arguments[0] as string) || '').toString();
    const isRequiredDevUrl = getIsRequiredDevUrl(url);
    const shouldFail = Math.random() < failureRate && !isRequiredDevUrl;
    const shouldAddLatency = Math.random() < addLatencyRate && !isRequiredDevUrl;

    if (shouldFail) {
      const message = `[ChaosMode][FETCH][Fail] for: ${url}`;
      logChaos(message);
      return Promise.reject(new Error(message));
    }

    if (shouldAddLatency) {
      const latency = Math.floor(Math.random() * maxLatency);
      await new Promise(resolve => {
        logChaos(`[ChaosMode][FETCH][Delay] ${latency}ms for: ${url}`);
        setTimeout(resolve, latency);
      });
    }

    return oldFetch.apply(this, arguments as any);
  };

  var xhrProto = XMLHttpRequest.prototype,
    origOpen = xhrProto.open;

  xhrProto.open = function (method, url) {
    //@ts-ignore
    this._url = url;
    //@ts-ignore
    return origOpen.apply(this, arguments);
  };

  const OrigXHR = window.XMLHttpRequest;

  //@ts-ignore
  global.XMLHttpRequest = function () {
    const xhr = new OrigXHR();

    const origSend = xhr.send;
    xhr.send = function () {
      const { failureRate, addLatencyRate, maxLatency } = chaosModeSettings;
      const url = (xhr as any)._url;
      const isRequiredDevUrl = getIsRequiredDevUrl(url);
      const shouldFail = Math.random() < failureRate && !isRequiredDevUrl;
      const shouldAddLatency = Math.random() < addLatencyRate && !isRequiredDevUrl;

      if (shouldFail) {
        Object.defineProperty(xhr, 'readyState', {
          value: 4,
          writable: true,
        });
        Object.defineProperty(xhr, 'status', {
          value: 500,
          writable: true,
        });
        Object.defineProperty(xhr, 'statusText', {
          value: '500 Internal Server Error',
          writable: true,
        });

        logChaos(`[ChaosMode][XHR][Fail] for: ${url}`);

        setTimeout(() => {
          const evt =
            // @ts-ignore
            typeof Event !== 'undefined' ? new Event('error', {}) : ({ type: 'error' } as Event);
          xhr.dispatchEvent(evt);
          xhr.onreadystatechange?.(evt);
        }, 200);

        return;
      }

      if (shouldAddLatency) {
        const latency = Math.floor(Math.random() * maxLatency);
        const args = arguments;
        const thiz = this;
        logChaos(`[ChaosMode][XHR][Delay] ${latency}ms for: ${url}`);
        setTimeout(() => origSend.apply(thiz, args as any), latency);
        return;
      }

      origSend.apply(this, arguments as any);
    };

    return xhr;
  };
}
export default chaosModeSettings;
