/* eslint-disable react/destructuring-assignment */
import { Svg, SvgPath, SvgProps, SvgRect, registerSVG } from '../../../components/svg';

export function PaymentMethods(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 24 17">
      <SvgRect
        fill={props.fill}
        x="0.512695"
        y="3.16873"
        width="22.3885"
        height="9.66667"
        rx="1.5"
        stroke="#1F1F1F"
      />
      <SvgPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0126953 4.66873H23.4012V7.3354H0.0126953V4.66873Z"
        fill={props.fill}
      />
    </Svg>
  );
}

registerSVG('PaymentMethods', PaymentMethods);
