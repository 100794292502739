import {
  Svg,
  SvgDefs,
  SvgLinearGradient,
  SvgPath,
  SvgProps,
  SvgRect,
  SvgStop,
  registerSVG,
} from '../../../components/svg';

export function JcbNoBorder(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <SvgRect y="4" width="24" height="16" rx="2" fill="white" />
      <SvgPath
        d="M15.5432 12.2227C15.9225 12.2313 16.304 12.2051 16.6817 12.2363C17.0643 12.3112 17.1565 12.9188 16.8166 13.1178C16.5847 13.2489 16.3092 13.1667 16.0578 13.1898H15.5432V12.2227ZM16.9011 11.1281C16.9853 11.4401 16.6986 11.7203 16.4121 11.6773H15.5432C15.5493 11.3831 15.5313 11.0637 15.5521 10.7849C15.9001 10.7952 16.2514 10.7639 16.5975 10.8012C16.7462 10.8404 16.8706 10.9686 16.9011 11.1281ZM18.9921 6.50039C19.0083 7.09634 18.9945 7.72378 18.9991 8.33182C18.998 10.8039 19.0014 13.2759 18.9973 15.748C18.982 16.6745 18.1994 17.4795 17.3224 17.498C16.4446 17.5018 15.5667 17.4986 14.6887 17.4996V13.7624C15.6453 13.7572 16.6023 13.7729 17.5585 13.7546C18.0021 13.7253 18.4879 13.4183 18.5086 12.9062C18.5608 12.3919 18.0986 12.0361 17.6598 11.9799C17.4911 11.9753 17.496 11.9283 17.6598 11.9078C18.0783 11.8129 18.407 11.3585 18.2838 10.9033C18.1789 10.4246 17.6746 10.2394 17.2551 10.2403C16.3997 10.2342 15.5443 10.2394 14.6889 10.2377C14.6944 9.53996 14.6774 8.84151 14.6981 8.14433C14.7659 7.23459 15.5682 6.48435 16.4328 6.50063C17.286 6.50049 18.1391 6.50049 18.9921 6.50046V6.50039Z"
        fill="url(#paint0_linear_1731_18277)"
      />
      <SvgPath
        d="M5.02275 8.2386C5.0446 7.3136 5.83056 6.51518 6.7064 6.50166C7.58097 6.49883 8.45568 6.50125 9.33025 6.50044C9.32785 9.59527 9.33499 12.6904 9.32665 15.7851C9.29296 16.6989 8.51556 17.4821 7.64926 17.4982C6.77303 17.5016 5.89671 17.4987 5.02045 17.4996V13.6364C5.87159 13.8474 6.76414 13.9372 7.63245 13.7972C8.15148 13.7096 8.71936 13.4422 8.89509 12.8773C9.0244 12.3941 8.9516 11.8855 8.97085 11.3895V10.2377H7.46815C7.4614 10.9995 7.48198 11.7626 7.45728 12.5235C7.41677 12.9911 6.97531 13.2883 6.55489 13.2724C6.03349 13.2782 5.00026 12.876 5.00026 12.876C4.99766 11.4487 5.01538 9.66126 5.02275 8.23873V8.2386Z"
        fill="url(#paint1_linear_1731_18277)"
      />
      <SvgPath
        d="M9.8907 10.7022C9.8116 10.7198 9.8748 10.4195 9.85458 10.3056C9.85996 9.58544 9.84331 8.86445 9.86376 8.14476C9.93134 7.23117 10.7398 6.47906 11.608 6.50045H14.1646C14.1622 9.59528 14.1693 12.6905 14.161 15.7851C14.1273 16.6989 13.3498 17.482 12.4835 17.4983C11.6072 17.5017 10.7309 17.4988 9.85454 17.4997V13.267C10.453 13.7822 11.2664 13.8624 12.0121 13.8638C12.5742 13.8636 13.133 13.7726 13.6788 13.6366V12.8612C13.0637 13.1828 12.3405 13.3872 11.6586 13.2024C11.1829 13.0781 10.8376 12.5958 10.8453 12.0808C10.7902 11.5452 11.0894 10.9797 11.5911 10.8205C12.214 10.6159 12.8929 10.7724 13.4768 11.0383C13.6019 11.1071 13.7288 11.1923 13.6788 10.9729V10.3634C12.7023 10.1197 11.6631 10.03 10.682 10.2952C10.398 10.3792 10.1214 10.5067 9.8907 10.7023V10.7022Z"
        fill="url(#paint2_linear_1731_18277)"
      />
      <SvgDefs>
        <SvgLinearGradient
          id="paint0_linear_1731_18277"
          x1="14.6593"
          y1="11.7648"
          x2="18.9719"
          y2="11.7648"
          gradientUnits="userSpaceOnUse"
        >
          <SvgStop stopColor="#007B40" />
          <SvgStop offset="1" stopColor="#55B330" />
        </SvgLinearGradient>
        <SvgLinearGradient
          id="paint1_linear_1731_18277"
          x1="4.92875"
          y1="12.1054"
          x2="9.25959"
          y2="12.1054"
          gradientUnits="userSpaceOnUse"
        >
          <SvgStop stopColor="#1D2970" />
          <SvgStop offset="1" stopColor="#006DBA" />
        </SvgLinearGradient>
        <SvgLinearGradient
          id="paint2_linear_1731_18277"
          x1="9.84822"
          y1="11.8714"
          x2="14.1638"
          y2="11.8714"
          gradientUnits="userSpaceOnUse"
        >
          <SvgStop stopColor="#6E2B2F" />
          <SvgStop offset="1" stopColor="#E30138" />
        </SvgLinearGradient>
      </SvgDefs>
    </Svg>
  );
}

registerSVG('JcbNoBorder', JcbNoBorder);
