import { useMemo } from 'react';

import {
  LoyaltyServiceMode,
  useLoyaltyUnauthenticatedRewardsQuery,
  useLoyaltyUserRewardsQuery,
} from 'generated/graphql-gateway';
import { withSimpleCache } from 'state/graphql/with-simple-cache';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';

import { parseEngineRewards } from './utils';

const useCachedLoyaltyUserRewardsQuery = withSimpleCache(useLoyaltyUserRewardsQuery);
const useCachedLoyaltyUnauthenticatedRewardsQuery = withSimpleCache(
  useLoyaltyUnauthenticatedRewardsQuery
);

export const useLoyaltyEngineRewards = (isAuthenticated: boolean) => {
  const authenticatedData = useLoyaltyAuthenticatedEngineRewards(isAuthenticated);
  const unauthenticatedData = useLoyaltyUnauthenticatedEngineRewards(isAuthenticated);
  return isAuthenticated ? authenticatedData : unauthenticatedData;
};

const useLoyaltyAuthenticatedEngineRewards = (isAuthenticated: boolean) => {
  const { serviceMode: ctxServiceMode } = useServiceModeContext();
  const serviceMode = LoyaltyServiceMode[ctxServiceMode || ''];
  const { store } = useStoreContext();

  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { loyaltyUser } = useLoyaltyUser();

  const {
    data: engineResponse,
    loading: engineRewardsLoading,
    refetch: refetchEngineRewards,
  } = useCachedLoyaltyUserRewardsQuery({
    skip: !loyaltyEnabled || !loyaltyUser?.id || !isAuthenticated,
    variables: {
      loyaltyId: loyaltyUser?.id || '',
      where: {
        ignorePointBalance: true,
        serviceMode: serviceMode || undefined,
        storeId: store?.number,
      },
    },
  });

  const {
    engineRewardIds,
    engineRewardsMap,
    personalizedRewardIds,
    personalizedRewardMap,
  } = useMemo(() => parseEngineRewards(engineResponse?.loyaltyUserV2?.rewards), [engineResponse]);

  return {
    engineResponse,
    engineRewardsLoading,
    refetchEngineRewards,
    engineRewardIds,
    engineRewardsMap,
    personalizedRewardIds,
    personalizedRewardMap,
  };
};

const useLoyaltyUnauthenticatedEngineRewards = (isAuthenticated: boolean) => {
  const { serviceMode: ctxServiceMode } = useServiceModeContext();
  const serviceMode = LoyaltyServiceMode[ctxServiceMode || ''];
  const { store } = useStoreContext();

  const loyaltyEnabled = useIsLoyaltyEnabled();

  const {
    data: engineResponse,
    loading: engineRewardsLoading,
    refetch: refetchEngineRewards,
  } = useCachedLoyaltyUnauthenticatedRewardsQuery({
    skip: !loyaltyEnabled || isAuthenticated,
    variables: {
      where: {
        ignorePointBalance: true,
        serviceMode: serviceMode || undefined,
        storeId: store?.number,
      },
    },
  });

  const {
    engineRewardIds,
    engineRewardsMap,
    personalizedRewardIds,
    personalizedRewardMap,
  } = useMemo(() => parseEngineRewards(engineResponse?.loyaltyRewardsV2), [engineResponse]);

  return {
    engineResponse,
    engineRewardsLoading,
    refetchEngineRewards,
    engineRewardIds,
    engineRewardsMap,
    personalizedRewardIds,
    personalizedRewardMap,
  };
};
