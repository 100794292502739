import { theme as brandTheme } from 'components/layout/brand-theme';

import { DesignTokens, designTokens } from './constants/design-tokens';

export const theme = {
  ...brandTheme,
  token(tokenName: keyof DesignTokens) {
    return designTokens[tokenName];
  },
};
