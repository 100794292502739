import React, { useEffect, useRef } from 'react';

import { AlertDialog } from '@rbilabs/universal-components';
import { isString } from 'lodash-es';

import { rumWrapperStartView, rumWrapperStopView } from 'hooks/rum/datadog-rum-wrapper';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import logger from 'utils/logger';

import type { IAlertDialogEventLogger } from './types';

// A wrapper for the `AlertDialog` component to log the mParticle event.
export const AlertDialogEventLogger = ({
  /**
   * A small string sent to mParticle describing the purpose of the modal.
   */
  modalAppearanceEventMessage,
  /**
   * The string to sent to mParticle describing modal header/title.
   */
  modalAppearanceHeader,
  /**
   * The string to sent to mParticle describing modal message.
   */
  modalAppearanceMessage,
  /**
   * Additional Error details to be sent to mParticle.
   */
  errorEventMessage,
  isErrorModal,
  shouldLogToDdWhenIsErrorModal = true,
  ...props
}: IAlertDialogEventLogger) => {
  const { logRBIEvent } = useMParticleContext();

  const wasOpenRef = useRef(false);
  const { isOpen } = props;

  useEffect(() => {
    const rumKey = `dialog:${modalAppearanceEventMessage}`;

    if (isOpen) {
      wasOpenRef.current = true;
      const context = {
        ErrorMessage: errorEventMessage,
        ModalHeader: isString(modalAppearanceHeader) ? modalAppearanceHeader : '',
        ModalMessage: isString(modalAppearanceMessage) ? modalAppearanceMessage : '',
        isErrorModal,
      };

      logRBIEvent({
        name: CustomEventNames.MODAL_APPEARANCE,
        type: EventTypes.Other,
        attributes: {
          ...context,
          Message: modalAppearanceEventMessage,
          bodyMessage: context.ModalMessage,
          modalHeader: context.ModalHeader,
        },
      });

      // if not already logged log this error to datadog as well
      if (isErrorModal && shouldLogToDdWhenIsErrorModal) {
        logger.error({
          message: errorEventMessage || `${context.ModalHeader} - ${context.ModalMessage}`,
          modalHeader: context.ModalHeader,
          modalMessage: context.ModalMessage,
          isErrorModal: true,
        });
      }

      rumWrapperStartView(rumKey, rumKey, context);
    }

    if (!isOpen && wasOpenRef.current) {
      rumWrapperStopView(rumKey);
      wasOpenRef.current = false;
    }

    return () => {
      if (wasOpenRef.current) {
        rumWrapperStopView(rumKey); // for situations where component isn't using "isOpen" but just removed.
      }
    };
  }, [
    errorEventMessage,
    isErrorModal,
    isOpen,
    logRBIEvent,
    modalAppearanceEventMessage,
    modalAppearanceHeader,
    modalAppearanceMessage,
    shouldLogToDdWhenIsErrorModal,
  ]);

  return <AlertDialog {...props} />;
};
