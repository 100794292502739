import { statusHasChanged } from 'hooks/order-status/helper';

import { IOnOrderStatusChangeOptions } from './types';

export const handleOrderStatusChange = ({
  failure,
  onSuccess,
  orderStatus,
  orderDeliveryStatus,
  prevOrderStatus,
  prevOrderDeliveryStatus,
  serverOrder,
  success,
}: IOnOrderStatusChangeOptions) => {
  if (!serverOrder) {
    return;
  }

  const orderStatusHasChanged = statusHasChanged({
    orderStatus,
    orderDeliveryStatus,
    prevOrderStatus,
    prevOrderDeliveryStatus,
    serverOrder,
  });

  if ((!success && !failure) || !orderStatusHasChanged) {
    return;
  }

  if (success) {
    onSuccess(serverOrder);
  }
};
