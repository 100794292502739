import { primitive } from 'styles/constants/primitives';

import { Svg, SvgPath, SvgProps, registerSVG } from '../../../components/svg';
export function EmptyCreditCard(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <SvgPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5H22C22.5523 5 23 5.44771 23 6V7H1V6C1 5.44772 1.44772 5 2 5ZM1 11V18C1 18.5523 1.44772 19 2 19H22C22.5523 19 23 18.5523 23 18V11H1ZM0 6C0 4.89543 0.895431 4 2 4H22C23.1046 4 24 4.89543 24 6V18C24 19.1046 23.1046 20 22 20H2C0.89543 20 0 19.1046 0 18V6Z"
        fill={primitive.bk.$bbqBrown}
      />
    </Svg>
  );
}

registerSVG('EmptyCreditCard', EmptyCreditCard);
