import * as LDClient from 'launchdarkly-js-client-sdk';
import { LDContext } from 'launchdarkly-js-sdk-common';

import { Deferred } from 'utils/Deferred';
import { getApiKey } from 'utils/environment';
import { loadRegion } from 'utils/intl/region';

import { WAIT_FOR_LD_TIMEOUT_SECONDS } from './constants';
import { privateAttributes } from './launchdarkly-core';

// "external promise" allows us to block LD operations until LD is fully setup
export const isLaunchDarklyReadyDeferred = new Deferred();

// the raw LD client
export const ldClient: { current: LDClient.LDClient | null } = { current: null };

export const configurePlatformClient = async (ldUserAttributes: LDContext) => {
  ldClient.current = LDClient.initialize(
    getApiKey({ key: 'launchDarkly', region: loadRegion() }),
    ldUserAttributes,
    {
      privateAttributes,
      evaluationReasons: __DEV__, // debug rule info from LD API not necessary outside of dev
    }
  );

  await Promise.race([
    // use the same timeout interface as native
    new Promise((res, rej) =>
      setTimeout(
        () => rej(new Error(`LaunchDarkly init timed out after ${WAIT_FOR_LD_TIMEOUT_SECONDS}s`)),
        WAIT_FOR_LD_TIMEOUT_SECONDS * 1000
      )
    ),
    new Promise((resolve, reject) => {
      ldClient!.current!.on('initialized', () => resolve(null));
      ldClient!.current!.on('failed', () => reject(new Error('LaunchDarkly init failed!')));
      ldClient!.current!.on('error', error => reject(error));
    }),
  ]);

  isLaunchDarklyReadyDeferred.resolve();
};

export const addLDChangeListener = (callback: () => void) => {
  const changeCallback = async () => {
    callback();
  };
  isLaunchDarklyReadyDeferred.promise.then(() => ldClient!.current!.on('change', changeCallback));

  return () => {
    isLaunchDarklyReadyDeferred.promise.then(() =>
      ldClient!.current!.off('change', changeCallback)
    );
  };
};
