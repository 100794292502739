import {
  addNetworkStatusListener,
  announceApiCallNetworkFailure,
  staticConnectionStatus,
} from 'state/network/status';
import logger from 'utils/logger';
import { networkErrorRegexes } from 'utils/logger/middleware/error-categories';
import { getMessage } from 'utils/logger/middleware/shared';

export const isBadNetworkError = (error: any) => {
  try {
    const { message } = getMessage(error);
    return networkErrorRegexes.some(regex => regex.test(message));
  } catch (_err) {} //eat
  return false;
};

export type RetryConfig = {
  operationName: string;
  canRetry: (error: any) => boolean;
  typeName: string;
};

// FIFO limit on bad requests stored to avoid potential unknown/memory situations.
const MAX_BAD_REQUESTS_TO_RETRY = 25;

const retryWhenConnectedCalls: (() => void)[] = [];

/** replay calls when internet is restored to try to salvage the UI */
addNetworkStatusListener(info => {
  if (info.isInternetReachable && retryWhenConnectedCalls.length > 0) {
    while (retryWhenConnectedCalls.length > 0) {
      const retry = retryWhenConnectedCalls.pop();
      retry?.();
    }
  }
});

// 2 calls in < 250ms seem to internally get debounced by cognito so go over 250
const RETRY_AT_MS = [250, 1000, 2000, 3000];

/**
 * do retries on `canRetry` failures, also retry when network comes back if it was offline.
 * - WHY?  network errors are a given in a mobile app and are the source of lots of bad reviews
 * @param func the func to run
 * @param funcName  name for logging
 * @returns the result of the promise
 */
export const withNetworkRetries = <TResult>(func: () => Promise<TResult>, config: RetryConfig) => {
  let retryAttemptIndex = 0;
  let didRetryWhenNetworkConnected = false;
  const { operationName, canRetry, typeName } = config;

  const runFunc = () =>
    func().catch((error: any) => {
      if (canRetry(error)) {
        announceApiCallNetworkFailure();

        const getLogCommon = (message: string, extraRetryInfo: object = {}) => ({
          message,
          error,
          retryInfo: {
            ...extraRetryInfo,
            typeName,
            operationName,
            retryAttemptIndex,
            isCognitoNetworkError: true,
            didRetryWhenNetworkConnected,
          },
        });

        // attempt retries up to what is defined in RETRY_AT_MS
        if (RETRY_AT_MS[retryAttemptIndex]) {
          logger.info(
            getLogCommon(
              `[${typeName}NetworkError] ${operationName} failed, retrying ${
                retryAttemptIndex + 1
              }/${RETRY_AT_MS.length}`
            )
          );
          return new Promise((res, rej) => {
            setTimeout(() => {
              retryAttemptIndex++;
              runFunc().then(res).catch(rej);
            }, RETRY_AT_MS[retryAttemptIndex]);
          });
        }

        // we've exhausted retries above if network is offline we can retry again later
        if (staticConnectionStatus.current.isInternetReachable === false) {
          // we can maybe recover once network comes back suspend ths promise until network returns
          if (retryWhenConnectedCalls.length > MAX_BAD_REQUESTS_TO_RETRY) {
            retryWhenConnectedCalls.shift();
          }

          logger.info(
            getLogCommon(
              `[${typeName}RetryWhenNetworkRestored] - Adding ${operationName} to the queue`,
              {
                [`is${typeName}RetryWhenNetworkRestored`]: true,
                [`is${typeName}QueuingRetryWhenNetworkRestored`]: true,
                error,
              }
            )
          );

          return new Promise((res, rej) => {
            retryWhenConnectedCalls.push(() => {
              logger.info(
                getLogCommon(
                  `[${typeName}RetryWhenNetworkRestored] - Network is back! Retrying ${operationName} from queue.`,
                  {
                    [`is${typeName}RetryWhenNetworkRestored`]: true,
                    [`is${typeName}DoingRetryWhenNetworkRestored`]: true,
                  }
                )
              );
              if (!didRetryWhenNetworkConnected) {
                // let a full retry succession start over if necessary but only one time to avoid unexpected stack overflows
                didRetryWhenNetworkConnected = true;
                retryAttemptIndex = 0;
              }

              runFunc().then(res).catch(rej);
            });
          });
        }

        // exhausted retries
        logger.error(
          getLogCommon(
            `[${typeName}NetworkError] ${operationName} failed. No more retries ${RETRY_AT_MS.length}/${RETRY_AT_MS.length}`
          )
        );
      }
      // let all other errors pass though normally/or retry limit reached
      return Promise.reject(error);
    });

  return runFunc() as Promise<TResult>;
};
