import { MenuObjectTypes } from 'enums/menu';
import { isOfferDiscount } from 'utils/cart/helper';
import { isCombo, isPicker } from 'utils/menu/is-menu-type';

import { IParsedLoyaltyReward } from './hooks/types';
import { LoyaltyAppliedOffer, LoyaltyOffer } from './types';

export const isDiscountLoyaltyOffer = (offer: LoyaltyOffer) =>
  offer?.incentives?.every(benefit => benefit?._type === MenuObjectTypes.OFFER_DISCOUNT);

export const isDiscountOffer = (offer: LoyaltyOffer) => isDiscountLoyaltyOffer(offer);

export const getCanStackOfferCheck = <T>(
  getOffer: (element: T) => LoyaltyOffer | LoyaltyAppliedOffer | null
) => (offer: LoyaltyOffer, list: T[]): boolean => {
  return (
    offer.isStackable ||
    !Array.isArray(list) ||
    list.length === 0 ||
    list.every((element: T) => {
      const offerItem = getOffer(element);
      // the stackable offer check is for offers only
      return offerItem ? offerItem?.isStackable : true;
    })
  );
};

export const getCmsOffersMapByLoyaltyId = (
  offers: LoyaltyOffer[] | null
): { [key: string]: LoyaltyOffer } => {
  return (offers || []).reduce((acc, offer) => {
    if (offer?.loyaltyEngineId) {
      acc[offer.loyaltyEngineId] = offer;
    }

    return acc;
  }, {});
};

export const getCmsOffersMapByCmsId = (
  offers: LoyaltyOffer[] | null
): { [key: string]: LoyaltyOffer } => {
  return (offers || []).reduce((acc, offer) => {
    if (offer?._id) {
      acc[offer._id] = offer;
    }

    return acc;
  }, {});
};

export const incentiveIsOfferBenefit = (offer: LoyaltyOffer): boolean => {
  return !!offer.incentives?.every(incentive => {
    return (
      isOfferDiscount(incentive) ||
      ((isPicker(incentive) || isCombo(incentive)) && incentive.isOfferBenefit)
    );
  });
};

/**
 * Util fn to find a reward in array and calculate if has reached limit per order
 */
export const findRewardInAppliedRewards = (
  appliedLoyaltyRewardsArray: IParsedLoyaltyReward[],
  engineRewardId: string,
  limitPerOrder?: number | null
) => {
  const appliedReward = appliedLoyaltyRewardsArray.find(
    appliedReward => appliedReward.rewardId === engineRewardId
  );

  if (!appliedReward || !limitPerOrder) {
    return { appliedReward, limitPerOrderMet: false };
  }

  return { appliedReward, limitPerOrderMet: appliedReward.timesApplied >= limitPerOrder };
};

/**
 * Setting the offers, merging with existing ones and adding the new ones.
 * @param currentOffers, LoyaltyOffer[]
 * @param newOffers, LoyaltyOffer[]
 * @returns list of offers, LoyaltyOffer[]
 */
export const mergeLoyaltyOffers = (currentOffers: LoyaltyOffer[], newOffers: LoyaltyOffer[]) => {
  // Replacing existing offers with new ones (e.g. with different language)
  const mergedOffers = currentOffers.map(currentOffer => {
    const newOffer = newOffers?.find(item => item?._id === currentOffer._id);

    return newOffer ? newOffer : currentOffer;
  });

  // Adding new offers that previously doesn't exist
  newOffers?.forEach(newOffer => {
    if (!mergedOffers.some(currentOffer => currentOffer._id === newOffer._id)) {
      mergedOffers.push(newOffer);
    }
  });

  return mergedOffers;
};
