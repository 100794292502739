import * as Font from 'expo-font';

import logger from 'utils/logger';

export const initExpoFonts = async (cachedFonts?: Record<string, string>): Promise<void> => {
  // Fonts loading might throw a timeout exception on slow network connections.
  // Uncaught exceptions here will show a bummer page to the user at app initialization.
  return Font.loadAsync({
    ...(cachedFonts || {}),
  }).catch(error => {
    logger.warn({ error, message: 'Caught exception loading fonts at app initialization' });
  });
};
