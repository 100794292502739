import * as React from 'react';

import Svg, { Path } from 'react-native-svg';

const FacebookIcon = () => (
  <Svg width={17} height={16} viewBox="0 0 17 16" fill="none">
    <Path
      d="M16.5 8.00001C16.5 3.58172 12.9183 0 8.50001 0C4.08172 0 0.5 3.58172 0.5 8.00001C0.5 11.993 3.42548 15.3027 7.25 15.9028V10.3125H5.21875V8.00001H7.25V6.23751C7.25 4.2325 8.44434 3.125 10.2717 3.125C11.147 3.125 12.0625 3.28125 12.0625 3.28125V5.25H11.0537C10.0599 5.25 9.75001 5.86668 9.75001 6.49934V8.00001H11.9688L11.6141 10.3125H9.75V15.9028C13.5745 15.3027 16.5 11.993 16.5 8.00001Z"
      fill="#1877F2"
    />
    <Path
      d="M11.6141 10.3125L11.9688 8H9.74999V6.49932C9.74999 5.86667 10.0599 5.25 11.0537 5.25H12.0625V3.28125C12.0625 3.28125 11.147 3.125 10.2717 3.125C8.44434 3.125 7.25 4.23249 7.25 6.2375V8H5.21875V10.3125H7.25V15.9028C7.66351 15.9676 8.08144 16.0001 8.5 16C8.92525 16 9.3427 15.9667 9.74999 15.9028V10.3125H11.6141Z"
      fill="white"
    />
  </Svg>
);
export default FacebookIcon;
