import Svg, { Path } from 'react-native-svg';

import { primitive } from 'styles/constants/primitives';
export const EmailIcon = () => {
  return (
    <Svg width="23" height="24" viewBox="0 0 23 24" fill="none">
      <Path
        d="M4.01225 19.5106C3.49715 19.5106 3.05619 19.3272 2.68938 18.9604C2.32257 18.5936 2.13916 18.1526 2.13916 17.6375V6.39897C2.13916 5.88387 2.32257 5.44291 2.68938 5.0761C3.05619 4.70929 3.49715 4.52588 4.01225 4.52588H18.997C19.5121 4.52588 19.953 4.70929 20.3198 5.0761C20.6866 5.44291 20.87 5.88387 20.87 6.39897V17.6375C20.87 18.1526 20.6866 18.5936 20.3198 18.9604C19.953 19.3272 19.5121 19.5106 18.997 19.5106H4.01225ZM11.5046 12.7909C11.5826 12.7909 11.6646 12.7792 11.7504 12.7558C11.8363 12.7323 11.9182 12.6972 11.9963 12.6504L18.6223 8.50619C18.7472 8.42815 18.8409 8.33059 18.9033 8.21352C18.9657 8.09645 18.997 7.96768 18.997 7.8272C18.997 7.51502 18.8643 7.28088 18.5989 7.12479C18.3336 6.9687 18.0604 6.9765 17.7795 7.1482L11.5046 11.0817L5.22976 7.1482C4.94879 6.9765 4.67563 6.9726 4.41028 7.1365C4.14493 7.30039 4.01225 7.53063 4.01225 7.8272C4.01225 7.98329 4.04347 8.11987 4.1059 8.23694C4.16834 8.354 4.26199 8.44376 4.38687 8.50619L11.0129 12.6504C11.091 12.6972 11.1729 12.7323 11.2588 12.7558C11.3446 12.7792 11.4266 12.7909 11.5046 12.7909Z"
        fill={primitive.bk.$bbqBrown}
      />
    </Svg>
  );
};
