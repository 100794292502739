export enum MenuObjectTypes {
  COMBO = 'combo',
  COMBO_SLOT = 'comboSlot',
  ITEM = 'item',
  ITEM_OPTION = 'itemOption',
  ITEM_OPTION_MODIFIER = 'itemOptionModifier',
  PICKER = 'picker',
  PICKER_ASPECT = 'pickerAspect',
  PICKER_OPTION = 'pickerOption',
  SECTION = 'section',
  OFFER_DISCOUNT = 'offerDiscount',
}

export enum ComboUIPattern {
  series = 'series',
  parallel = 'parallel',
}

export enum AllergenIncludes {
  FreeFrom = 0,
  CookedInSameEquipment = 1,
  MayContain = 2,
  Contains = 3,
}

export enum DiscountTypes {
  ALL_DISCOUNTS = 'ALL_DISCOUNTS',
  REWARDS_DISCOUNTS = 'REWARDS_DISCOUNTS',
  COMBO_AND_OFFER_DISCOUNTS = 'COMBO_AND_OFFER_DISCOUNTS',
}

export enum ItemAvailabilityStatus {
  AVAILABLE = 'AVAILABLE',
  LIMITED_TIME_OFFER = 'LIMITED_TIME_OFFER',
  CART_EXTRA = 'CART_EXTRA',
  OUT_OF_DAYPART = 'OUT_OF_DAYPART',
  OUT_OF_MENU = 'OUT_OF_MENU',
  UNAVAILABLE = 'UNAVAILABLE',
  STORE_NOT_SELECTED = 'STORE_NOT_SELECTED',
  MAX_CART_QUANTITY_MET = 'MAX_CART_QUANTITY_MET',
}

export enum OfferDiscountTypes {
  AMOUNT = 'amount',
  PERCENTAGE = 'percentage',
}
