import { CartPaymentCardType } from 'generated/graphql-gateway';

import { IPaymentMethod } from './types';
// @TODO remove after merging cba-797

// In favor of RELOAD_AMOUNTS label/value pair
export const PREPAID_RELOAD_AMOUNTS = [500, 1000, 2500, 5000];

export const DEFAULT_RELOAD_AMOUNT = 500;

export const RELOAD_AMOUNTS = [
  {
    label: '$5',
    value: 500,
  },
  {
    label: '$10',
    value: 1000,
  },
  {
    label: '$25',
    value: 2500,
  },
  {
    label: '$50',
    value: 5000,
  },
];

export const CASH_ACCOUNT_IDENTIFIER = CartPaymentCardType.CASH;
export const CASH_APP_ACCOUNT_IDENTIFIER = CartPaymentCardType.CASHAPP;
export const PAYPAL_ACCOUNT_IDENTIFIER = CartPaymentCardType.PAYPAL;
export const VENMO_ACCOUNT_IDENTIFIER = CartPaymentCardType.VENMO;

export const CASH_APP_PAYMENT_METHOD_PLACEHOLDER: IPaymentMethod = {
  fdAccountId: CASH_APP_ACCOUNT_IDENTIFIER,
  accountIdentifier: CASH_APP_ACCOUNT_IDENTIFIER,
  orbitalIdentifier: null,
  credit: null,
  paypal: false,
  prepaid: null,
  venmo: false,
  cashapp: true,
};
export const PAYPAL_PAYMENT_METHOD_PLACEHOLDER: IPaymentMethod = {
  fdAccountId: PAYPAL_ACCOUNT_IDENTIFIER,
  accountIdentifier: PAYPAL_ACCOUNT_IDENTIFIER,
  orbitalIdentifier: null,
  credit: null,
  paypal: true,
  prepaid: null,
  venmo: false,
  cashapp: false,
};
export const VENMO_PAYMENT_METHOD_PLACEHOLDER: IPaymentMethod = {
  paypal: false,
  fdAccountId: VENMO_ACCOUNT_IDENTIFIER,
  accountIdentifier: VENMO_ACCOUNT_IDENTIFIER,
  orbitalIdentifier: null,
  credit: null,
  prepaid: null,
  venmo: true,
  cashapp: false,
};
