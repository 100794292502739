import { PROD_DEFAULT_REGION as DEFAULT_REGION } from 'state/intl/constants';
import { ISOs } from 'utils/form/constants';
import { ISignupForm } from 'utils/form/types';

const initialFormState = ({
  enableCustomBirthdayInput,
  regionIso,
  phoneNumber,
}: {
  enableCustomBirthdayInput: boolean;
  regionIso: ISOs;
  phoneNumber?: string;
}): ISignupForm => ({
  email: '',
  name: '',
  phoneNumber: phoneNumber || '',
  zipcode: '',
  dob: enableCustomBirthdayInput
    ? {
        day: '',
        month: '',
        year: '',
      }
    : '',
  country: regionIso || ISOs[DEFAULT_REGION],
  agreesToTermsOfService: false,
  wantsPromotionalEmails: false,
});

export default initialFormState;
