import { useCallback, useMemo } from 'react';

import { useIntl } from 'react-intl';

import { ISanityVendorConfigs } from '@rbi-ctg/menu';
import { ItemAvailabilityStatus } from 'enums/menu';
import { Maybe } from 'generated/sanity-graphql';
import usePosVendor from 'hooks/menu/use-pos-vendor';
import { selectors, useAppSelector } from 'state/global-state';
import { LoyaltyOffer, LoyaltyReward, isLoyaltyOffer } from 'state/loyalty/types';
import { isDiscountOffer } from 'state/loyalty/utils';
import { useMenuContext } from 'state/menu';
import { useMainMenuContext } from 'state/menu/main-menu';
import { useStoreContext } from 'state/store';
import { itemIsAvailable } from 'utils/availability';
import { PosVendors } from 'utils/vendor-config';

import {
  checkIncentiveAvailableForDayPart,
  makeIncentiveUnavailableMessage,
} from './incentive-details/utils';

const VENDORS_TO_IGNORE_AVAILABILITY_CHECK = [
  PosVendors.NCR,
  PosVendors.NCR_DELIVERY,
  PosVendors.QDI,
  PosVendors.QDI_DELIVERY,
];

const shouldSkipAvailabilityCheck = ({
  vendor,
  cmsOffer,
}: {
  vendor: PosVendors;
  cmsOffer: LoyaltyOffer;
}) => Boolean(VENDORS_TO_IGNORE_AVAILABILITY_CHECK.includes(vendor) && isDiscountOffer(cmsOffer));

type GetIncentiveUnavailableMessageInput = {
  incentive: LoyaltyReward | LoyaltyOffer;
  pointCost?: number;
  locked?: Maybe<boolean>;
};

export const useLoyaltyIncentivesAvailabilityUtils = () => {
  const {
    checkItemAvailability,
    showBurgersForBreakfast: burgersForBreakfastEnabled,
  } = useMenuContext();
  const { activeDayParts, dayParts } = useMainMenuContext();
  const { isStoreOpenAndAvailable, prices } = useStoreContext();
  const { vendor } = usePosVendor();
  const { formatMessage, formatTime } = useIntl();
  const stagedCartPoints = useAppSelector(selectors.loyalty.selectStagedCartPoints);

  const checkIncentiveAvailability = useCallback(
    async (itemId: string) => {
      // Check store availability for incentive
      if (isStoreOpenAndAvailable && itemId) {
        const { availabilityStatus } = await checkItemAvailability(itemId);

        return availabilityStatus === ItemAvailabilityStatus.AVAILABLE;
      }
      return false;
    },
    [checkItemAvailability, isStoreOpenAndAvailable]
  );

  const checkLoyaltyIncentiveAvailability = useCallback(
    (incentive: LoyaltyReward | LoyaltyOffer) => {
      let checkResult = true;

      if (!!incentive?.vendorConfigs && !!vendor && !!prices) {
        checkResult = itemIsAvailable(
          {
            ...incentive,
            name: undefined,
            vendorConfigs: incentive.vendorConfigs as ISanityVendorConfigs,
          },
          vendor,
          prices
        );
      }

      return checkResult;
    },
    [vendor, prices]
  );

  const checkLoyaltyOfferAvailability = useCallback(
    (offer: LoyaltyOffer) => {
      return !vendor || shouldSkipAvailabilityCheck({ vendor, cmsOffer: offer })
        ? true
        : checkLoyaltyIncentiveAvailability(offer);
    },
    [vendor, checkLoyaltyIncentiveAvailability]
  );

  const getIncentiveUnavailableMessage = useCallback(
    ({ incentive, pointCost, locked }: GetIncentiveUnavailableMessageInput) => {
      if (!incentive) {
        return;
      }
      const isIncentiveAvailable = isLoyaltyOffer(incentive)
        ? checkLoyaltyOfferAvailability(incentive)
        : checkLoyaltyIncentiveAvailability(incentive);

      const isAvailableForDayPart = checkIncentiveAvailableForDayPart({
        activeDayParts,
        burgersForBreakfastEnabled,
        incentive,
      });

      const incentiveUnavailableMessage = makeIncentiveUnavailableMessage({
        burgersForBreakfastEnabled,
        dayParts,
        formatMessage,
        formatTime,
        incentive,
        isAvailableForDayPart,
        isIncentiveAvailable,
        locked,
        pointCost,
        stagedCartPoints,
      });

      return incentiveUnavailableMessage;
    },
    [
      activeDayParts,
      burgersForBreakfastEnabled,
      checkLoyaltyIncentiveAvailability,
      checkLoyaltyOfferAvailability,
      dayParts,
      formatMessage,
      formatTime,
      stagedCartPoints,
    ]
  );

  // When dependencies have been loaded
  const ready = useMemo(() => !!activeDayParts.length, [activeDayParts]);

  return {
    checkIncentiveAvailability,
    checkLoyaltyOfferAvailability,
    getIncentiveUnavailableMessage,
    ready,
  };
};

export const useLoyaltyIncentivesAvailability = (
  incentive?: LoyaltyReward | LoyaltyOffer,
  pointCost?: number,
  locked?: Maybe<boolean>
) => {
  const {
    checkIncentiveAvailability,
    checkLoyaltyOfferAvailability,
    getIncentiveUnavailableMessage,
  } = useLoyaltyIncentivesAvailabilityUtils();

  const incentiveUnavailableMessage = useMemo(
    () => incentive && getIncentiveUnavailableMessage({ locked, pointCost, incentive }),
    [getIncentiveUnavailableMessage, locked, pointCost, incentive]
  );

  return {
    checkIncentiveAvailability,
    checkLoyaltyOfferAvailability,
    incentiveUnavailableMessage,
  };
};
