import { useEffect, useState } from 'react';

import { useHandledAccessibilityActions } from '../../hooks';
import { addWithConfig } from '../../utils';
import { HStack } from '../h-stack';
import { IconButton } from '../icon-button';
import { Input } from '../input';

import type { NumberInputProps, _NumberInputStepperButtonProps } from './types';
import { useBoundedNumberState, useMeasure0ChWidth } from './utils';

const NumberStepperButton = ({
  isDisabled,
  testID,
  iconName,
  onPress,
}: _NumberInputStepperButtonProps) => (
  <IconButton
    testID={testID}
    isDisabled={isDisabled}
    variant="outline"
    // This button is redundant a11y-wise since we registered an accessibility action with
    // the same purpose
    accessible={false}
    iconName={iconName}
    height="8"
    width="8"
    padding="0"
    justifyContent="center"
    alignItems="center"
    onPress={onPress}
  />
);

// We could add to props but we don't have a use case for it currently and having a non-integer step
// will require different, more complicated logic which is unneeded right now;
const STEP = 1;

// The focus of this implementation has been native accessibility. We may need to create a web
// implementation with keyboard accessibility in mind
// eslint-disable-next-line complexity
export const NumberInput = ({
  defaultValue = 0,
  value,
  onChangeValue,
  min = 0,
  max = 9999,
  isDisabled,
  isInvalid,
  testID = 'number-input',
  inputWidth = 'dynamic',
  ...rest
}: NumberInputProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputStr, setInputStr] = useState(`${value ?? defaultValue}`);
  const [numberValue, setNumberValueWithinBoundaries] = useBoundedNumberState({
    value,
    onChangeValue,
    min,
    max,
    defaultValue,
  });
  const [chWidth, chMeasurer] = useMeasure0ChWidth();
  const isDecrementDisabled = isDisabled || (min != null && numberValue - STEP < min);
  const isIncrementDisabled = isDisabled || (max != null && numberValue + STEP > max);

  useEffect(() => {
    if (!isEditing) {
      setInputStr(`${numberValue}`);
    }
  }, [isEditing, numberValue]);

  const handleIncrement = () => {
    if (!isIncrementDisabled) {
      setNumberValueWithinBoundaries(numberValue + STEP);
    }
  };
  const handleDecrement = () => {
    if (!isDecrementDisabled) {
      setNumberValueWithinBoundaries(numberValue - STEP);
    }
  };

  const parseInputStrToNumber = (str: string) => {
    let nextNumber = numberValue;

    const startsWithNegativeSign = str.trim().startsWith('-');
    const onlyDigits = str.replace(/[^\d]/g, '').trim() || '0';

    try {
      nextNumber = Number.parseInt(onlyDigits, 10);
      if (startsWithNegativeSign) {
        nextNumber = -nextNumber;
      }
    } catch (err) {
      // Do nothing
    }

    return nextNumber;
  };

  const [accessibilityActions, handleAccessibilityActions] = useHandledAccessibilityActions([
    {
      name: 'increment',
      label: 'increment',
      handler: handleIncrement,
    },
    {
      name: 'decrement',
      label: 'decrement',
      handler: handleDecrement,
    },
  ]);

  return (
    <HStack
      alignItems="center"
      space="2"
      testID={testID}
      accessible
      accessibilityLabel="Quantity"
      accessibilityHint=""
      accessibilityRole="spinbutton"
      accessibilityValue={{ text: `${numberValue}` }}
      accessibilityState={{ disabled: isDisabled }}
      accessibilityActions={accessibilityActions}
      onAccessibilityAction={handleAccessibilityActions}
      {...rest}
    >
      {chMeasurer}
      <NumberStepperButton
        testID={`${testID}-decrement-button`}
        isDisabled={isDecrementDisabled}
        iconName="remove"
        onPress={() => {
          handleDecrement();
        }}
      />
      <Input
        variant="outline"
        testID={`${testID}-input`}
        isDisabled={isDisabled}
        isInvalid={isInvalid}
        editable={!isDisabled}
        keyboardType="number-pad"
        returnKeyType="done"
        onChangeText={nextValue => setInputStr(nextValue)}
        value={inputStr}
        onFocus={() => {
          setIsEditing(true);
        }}
        onBlur={() => {
          setIsEditing(false);
          setNumberValueWithinBoundaries(parseInputStrToNumber(inputStr));
        }}
        minWidth="12"
        width={inputWidth === 'dynamic' ? `${chWidth * inputStr.length + 24}px` : inputWidth}
        paddingLeft="2"
        paddingRight="2"
        textAlign="center"
        {...(!isEditing && {
          borderColor: isInvalid ? 'token.border-color-input' : 'transparent',
          backgroundColor: 'transparent',
        })}
      />
      <NumberStepperButton
        testID={`${testID}-increment-button`}
        isDisabled={isIncrementDisabled}
        iconName="add"
        onPress={() => {
          handleIncrement();
        }}
      />
    </HStack>
  );
};

export default addWithConfig(NumberInput);
