import { Svg, SvgPath, SvgProps, registerSVG } from '../../../components/svg';

export function MapOutline(props: SvgProps) {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <SvgPath d="M20.5 3h-.2L15 5.1 9 3 3.4 4.9Q3 5 3 5.4v15.1l.5.5h.2L9 18.9l6 2.1 5.6-1.9q.4 0 .4-.5V3.5zM10 5.5l4 1.4v11.6l-4-1.4zm-5 1 3-1V17l-3 1.2zm14 11-3 1V7l3-1.2z" />
    </Svg>
  );
}

registerSVG('MapOutline', MapOutline);
