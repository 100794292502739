import React from 'react';

import { Center, Skeleton, VStack } from '@rbilabs/universal-components';

import { iconSize } from './helpers';

const NavItem = Center.withConfig({
  minHeight: '50px',
  paddingX: '0',
  paddingY: '2',
});

const NavOption = ({ textWidth = '48px', ...props }: { textWidth?: string }) => (
  <NavItem {...props}>
    <VStack alignItems="center" space={1}>
      <Skeleton key={1} h={`${iconSize}px`} w={`${iconSize}px`} />
      <Skeleton.Text key={2} lines={1} width={textWidth} />
    </VStack>
  </NavItem>
);

export const MobileWebNavigationBarSkeleton = React.memo(() => {
  return (
    <>
      <NavOption />
      <NavOption />
      <NavOption />
      <NavOption />
      <NavOption />
    </>
  );
});
