import React, { FC } from 'react';

import { useAuthContext } from 'state/auth';

import { EmptyStateContent } from './empty-state-content';
import { ButtonWrapper, StyledActionButton } from './styled';
import { IEmptyStateProps } from './types';

export const EmptyState: FC<React.PropsWithChildren<IEmptyStateProps>> = ({ content }) => {
  const { buttonAction } = content;
  const { isAuthenticated } = useAuthContext();
  const shouldRenderSignUpButton = Boolean(!isAuthenticated && buttonAction?.actionText);

  return (
    <>
      <EmptyStateContent content={content} shouldRenderSignUpButton={shouldRenderSignUpButton} />
      {shouldRenderSignUpButton && (
        <ButtonWrapper>
          <StyledActionButton to={buttonAction?.actionUrl?.locale || ''} fullWidth>
            {buttonAction?.actionText?.locale}
          </StyledActionButton>
        </ButtonWrapper>
      )}
    </>
  );
};
