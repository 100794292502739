export const messageIdMappingForDialogs = {
  paymentNotSupported: {
    title: 'paymentNotSupportedHeading',
    body: 'paymentNotSupported',
    message: 'paymentNotSupported',
  },
  paymentDeclined: {
    title: 'paymentDeclinedHeading',
    body: 'paymentDeclined',
    message: 'paymentDeclined',
  },
  paymentDetailsError: {
    title: 'paymentDetailsErrorHeading',
    body: 'paymentDetailsError',
    message: 'paymentDetailsError',
  },
  validationError: {
    title: 'validationErrorHeading',
    body: 'validationError',
    message: 'validationError',
  },
  duplicateSupportFormSubmission: {
    title: 'duplicateSupportFormSubmission',
    body: 'duplicateSupportFormSubmission',
    message: 'duplicateSupportFormSubmission',
  },
  coolingPeriodError: {
    title: 'errorDialogCooldownTitle',
    body: 'errorDialogCooldownMessage',
    message: 'errorDialogCooldownMessage',
  },
  giftCardUnavailableAtLocation: {
    title: 'giftCardUnavailableAtLocationErrorHeading',
    body: 'giftCardUnavailableAtLocationError',
    message: 'giftCardUnavailableAtLocationError',
  },
};

export const messageIdForPspRefusedErrors = {
  Code105: {
    title: 'pspPaymentDeclinedTitle',
    message: 'pspPaymentDeclinedMessage',
  },
  Code106: {
    title: 'pspPaymentDeclinedTransactionForterTitle',
    message: 'pspPaymentDeclinedTransactionForterMessage',
  },
  Code107: {
    title: 'pspPaymentStoreErrorTitle',
    message: 'pspPaymentStoreErrorMessage',
  },
  Code205: {
    title: 'pspPaymentIncorrectInformationTitle',
    message: 'pspPaymentIncorrectInformationMessage',
  },
  Code305: {
    title: 'pspPaymentIncorrectInformationTitle',
    message: 'pspPaymentIncorrectInformationMessage',
  },
  Code405: {
    title: 'pspPaymentInsufficientFundsTitle',
    message: 'pspPaymentInsufficientFundsMessage',
  },
  Code505: {
    title: 'pspPaymentInvalidCardTitle',
    message: 'pspPaymentInvalidCardMessage',
  },
  Code605: {
    title: 'pspPaymentDeclinedTransactionTitle',
    message: 'pspPaymentDeclinedTransactionMessage',
  },
  Code705: {
    title: 'pspPaymentExpiredCardTitle',
    message: 'pspPaymentExpiredCardMessage',
  },
  Code805: {
    title: 'pspPaymentUnableToProcessTitle',
    message: 'pspPaymentUnableToProcessMessage',
  },
  Code905: {
    title: 'pspPaymentRestrictedCardTitle',
    message: 'pspPaymentRestrictedCardMessage',
  },
};
