import {
  PROD_DEFAULT_LANGUAGE,
  PROD_SUPPORTED_LANGUAGES as SUPPORTED_LANGUAGES,
} from 'state/intl/constants';
import { LANGUAGES } from 'state/intl/types';
import { loadRegion } from 'utils/intl/region';

export function findSupportedLanguage(requestedLanguage: string): LANGUAGES {
  return SUPPORTED_LANGUAGES[requestedLanguage] || PROD_DEFAULT_LANGUAGE[loadRegion()];
}
