import * as React from 'react';

import { Svg, SvgPath, SvgProps, registerSVG } from '../../../components/svg';
export function Remove(props: SvgProps) {
  return (
    <Svg {...props} width={30} height={30} viewBox="0 0 30 30" fill="none">
      <SvgPath
        d="M10 24C9.45 24 8.97917 23.8042 8.5875 23.4125C8.19583 23.0208 8 22.55 8 22V9H7V7H12V6H18V7H23V9H22V22C22 22.55 21.8042 23.0208 21.4125 23.4125C21.0208 23.8042 20.55 24 20 24H10ZM20 9H10V22H20V9ZM12 20H14V11H12V20ZM16 20H18V11H16V20Z"
        fill="#502314"
      />
    </Svg>
  );
}

registerSVG('Remove', Remove);
