import React from 'react';

import { useIntl } from 'react-intl';

import useAuthFlow from 'state/auth/hooks/use-auth-flow';

import { GetStartedText } from './sign-up-headline.styled';

interface ISignUpHeadlineTextProps {
  rewardsLink: React.ReactNode;
}

const SignUpHeadlineText: React.FC<ISignUpHeadlineTextProps> = ({ rewardsLink }) => {
  const { formatMessage } = useIntl();
  const { showOtpForm } = useAuthFlow();

  let signUpLabel;
  if (showOtpForm) {
    signUpLabel = formatMessage({ id: 'otpFormHeadline' });
  } else {
    signUpLabel = formatMessage({ id: 'signUpOrSignIn' });
  }

  return <GetStartedText testID="sign-up-label">{signUpLabel}</GetStartedText>;
};

export default SignUpHeadlineText;
