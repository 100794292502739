import { Box } from '@rbilabs/universal-components';

export const LoyaltyLoaderContainer = Box.withConfig({
  height: '100%',
  // FIXME: BKPE-1489: issue with scroll views
  minHeight: '300px',
  position: 'absolute',
  alignSelf: 'center',
  justifyContent: 'center',
});
