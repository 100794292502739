import React, { useEffect, useMemo } from 'react';

import { Box, Header } from '@rbilabs/universal-components';
import { FlatList } from 'react-native';

import Dialog from 'components/dialog';
import AnchorLinksWidget from 'pages/static/anchorLinksWidget';
import ImageWidget from 'pages/static/imageWidget';
import serializers from 'pages/static/render-current-static-page/serializers';
import {
  AnchorWidget,
  blockContentOverrides,
} from 'pages/static/render-current-static-page/static-page-container';
import {
  BlockWidgetContainer,
  blockWidgetLinkProps,
  blockWidgetStrongProps,
} from 'pages/static/render-current-static-page/styled';
import { WidgetType } from 'pages/static/render-current-static-page/widgetEnums';
import { LoadingIndicator } from 'pages/store-locator/components';
import { IStaticPageRoute } from 'remote/queries/static-page';
import { useLocale } from 'state/intl';
import { PROD_DEFAULT_LANGUAGE, PROD_SUPPORTED_LANGUAGES } from 'state/intl/constants';
import { usePageManagerContext } from 'state/static-page-manager';
import { useUIContext } from 'state/ui';
import {
  AnchorContextProvider,
  AnchorScrollContainerRefType,
  useAnchorContext,
} from 'utils/static-page/hooks/useAnchorContext';

/**
 * This component is a simplified version of a static page presented in a dialog.
 * It has been streamlined by removing some elements to make it simpler.
 * For the time being, it will be used exclusively in the authentication flow for the links on the signup form.
 * However, if we wish to use this component in other contexts and with different pages in the future,
 * we would need to add back those removed elements.
 *
 */
export const DialogStaticPage = () => {
  const { language: currentLanguage, region, setCurrentLocale } = useLocale();
  const { dialogStaticPageState, setDialogStaticPageState } = useUIContext();

  const {
    currentPage,
    retrieveStaticPageById,
    routes: pageManagerRoutes,
    routesLoading,
    currentPageLoading,
  } = usePageManagerContext();

  const targetPage = useMemo(() => {
    let result: undefined | { route: IStaticPageRoute; language: string };
    pageManagerRoutes.forEach(route => {
      if (routesLoading) {
        result = undefined;
      }

      const staticPath = (dialogStaticPageState.page || '').replace(/^\/|\/$/g, '');

      const staticLocalizedPageLanguage = Object.keys(PROD_SUPPORTED_LANGUAGES).find(lang => {
        const localePath = route?.localePath;
        return localePath && lang in localePath && localePath[lang]?.current === staticPath;
      });

      if (staticLocalizedPageLanguage) {
        result = { route, language: staticLocalizedPageLanguage };
      }

      if (route.path.current === staticPath) {
        result = { route, language: PROD_DEFAULT_LANGUAGE[region] };
      }
    });

    return result;
  }, [pageManagerRoutes, routesLoading, dialogStaticPageState.page, region]);

  useEffect(() => {
    if (!targetPage) {
      return;
    }
    if (targetPage.language !== currentLanguage) {
      setCurrentLocale(targetPage.language, region);
    }

    retrieveStaticPageById(targetPage.route._id);
  }, [targetPage, currentLanguage, retrieveStaticPageById, region, setCurrentLocale]);

  const BodyComponent = () => {
    const { registerContainerRef } = useAnchorContext();

    if (!currentPage || currentPageLoading) {
      return <LoadingIndicator />;
    }

    const currentWidgets = currentPage.widgets ? [...currentPage.widgets] : [];
    const listData = [...currentWidgets];

    const renderItem = ({ item: widget, index }: { item: any; index: number }) => {
      const type = widget._type;
      const key = `widget${index}`;

      switch (type) {
        case WidgetType.ANCHOR:
          return (
            <Box key={key} {...widget}>
              {/* @ts-expect-error TS(2322) FIXME: Type '{ className: string; id: any; }' is not assi... Remove this comment to see the full error message */}
              <AnchorWidget className="anchorWidget" id={widget.anchorName?.locale} />
            </Box>
          );
        case WidgetType.ANCHOR_LINKS:
          return (
            <Box key={key}>
              <AnchorLinksWidget widgets={currentPage.widgets} />
            </Box>
          );
        case WidgetType.IMAGE:
          return (
            <Box key={key}>
              <ImageWidget {...widget} />
            </Box>
          );
        case WidgetType.LOCALE_BLOCK_TEXT:
          return (
            <Box key={key} {...widget}>
              <BlockWidgetContainer
                blockContentOverrides={blockContentOverrides(currentPage.path.current)}
                strongProps={blockWidgetStrongProps}
                linkProps={blockWidgetLinkProps}
                // @ts-expect-error TS(2322) FIXME: Type '{ list: ({ type, children }: { type: string;... Remove this comment to see the full error message
                serializers={serializers}
                content={widget?.localeBlockTextContent?.localeRaw}
              />
            </Box>
          );
        default:
          return null;
      }
    };

    return (
      <FlatList
        overScrollMode="auto"
        data={listData}
        ref={ref => {
          return registerContainerRef(ref as AnchorScrollContainerRefType);
        }}
        renderItem={renderItem}
      />
    );
  };

  const handleOnDismiss = () =>
    setDialogStaticPageState({ ...dialogStaticPageState, isOpen: false });

  return (
    <AnchorContextProvider>
      <Dialog
        showDialog={dialogStaticPageState.isOpen}
        modalAppearanceEventMessage={currentPage?.title}
        headingComponent={<Header textAlign="center">{currentPage?.title}</Header>}
        bodyComponent={<BodyComponent />}
        accessibilityLabel={currentPage?.title}
        showCloseButton
        closeButtonLabelId="okay"
        onDismiss={handleOnDismiss}
      />
    </AnchorContextProvider>
  );
};
