import { ICombo } from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';
import { IMainMenuContext } from 'state/menu/main-menu/types';
import { IPricingFunctionParams, IUsePricingFunctionHookReturnType } from 'state/menu/types';

import { computeModifiersPrice } from './compute-modifiers-price';
import { getMenuEntityKey } from './get-menu-entity-key';

type IComputeComboSlotsPriceParams = {
  combo: ICombo;
  comboSlotSelections?: IPricingFunctionParams['comboSlotSelections'];
  modifierSelections?: IPricingFunctionParams['modifierSelections'];
  getStoreMenuEntity: IMainMenuContext['getStoreMenuEntity'];
  priceForItemInComboSlotSelection: IUsePricingFunctionHookReturnType['priceForItemInComboSlotSelection'];
};

export const computeComboSlotsPrice = ({
  combo,
  comboSlotSelections = {},
  modifierSelections = [],
  getStoreMenuEntity,
  priceForItemInComboSlotSelection,
}: IComputeComboSlotsPriceParams): number => {
  let comboSlotsPrice = 0;

  for (const [comboSlotId, comboSlotSelection] of Object.entries(comboSlotSelections)) {
    for (const selection of comboSlotSelection.selections ?? []) {
      // Compute comboSlot upsell price
      const selectedItem = selection?.option?.option;
      if (selectedItem && selectedItem._type !== MenuObjectTypes.PICKER) {
        comboSlotsPrice += priceForItemInComboSlotSelection({ combo, selectedItem });
      }

      // Compute comboSlot modifiers (if any)
      if (!selectedItem?.options?.length) {
        continue;
      }
      const selectedItemId = getMenuEntityKey(selectedItem);
      const { options: storeMenuOptions } = getStoreMenuEntity(selectedItemId) || {};
      const comboSlotModifierPrice = computeModifiersPrice({
        storeMenuOptions,
        modifierSelections: modifierSelections.filter(x => x.comboSlotId === comboSlotId),
      });
      comboSlotsPrice += comboSlotModifierPrice;
    }
  }

  return comboSlotsPrice;
};
