import * as React from 'react';
import { FC } from 'react';

import Picture from 'components/picture';
import { IImageFragment } from 'generated/sanity-graphql';

import { OfferGroupItem } from './offer-group-item';

export interface ILoyaltyOfferGroupItem {
  handlePress: () => void;
  groupId: string;
  name: string;
  description: string;
  image: IImageFragment | null;
  imageDescription: string;
}

export const LoyaltyOfferGroupItem: FC<ILoyaltyOfferGroupItem> = ({
  handlePress,
  name,
  description,
  groupId,
  image,
  imageDescription,
}) => {
  return (
    <OfferGroupItem
      name={name}
      description={description}
      groupId={groupId}
      image={
        <Picture image={image} alt={imageDescription} height="97px" width="97px" objectFitContain />
      }
      onPress={handlePress}
    />
  );
};
