import { ISanityCombo, IWithPricingProps } from '@rbi-ctg/menu';
import { ItemAvailabilityStatus, MenuObjectTypes } from 'enums/menu';
import { DAY_PART_SELECTIONS, IDayPartBoundary } from 'state/menu/main-menu/types';
import { findBurgersForBreakfastDayPart } from 'utils/daypart';

import { getMenuItemDayParts, isAvailableForActiveDayParts } from './daypart-availability';
import { comboIsAvailable, itemIsAvailable } from './menu-availability';
import { IAvailabilityObject } from './types';

// NOTE: This will only check the main items it is not recursive. Do not use this in the menu. This is used for recent-items. This should not be used to filter items in the menu.
export const getAvailabilityStatus = ({
  data,
  activeDayParts,
  isExtra,
  prices,
  vendor,
  storeHasBurgersForBreakfast,
}: {
  data: IAvailabilityObject | null;
  activeDayParts: IDayPartBoundary[];
  isExtra: boolean;
  storeHasBurgersForBreakfast?: boolean;
} & IWithPricingProps) => {
  // check if there was actual data in sanity
  if (!data) {
    return ItemAvailabilityStatus.OUT_OF_MENU;
  }

  // check if its a cart extra - extras can only be added from the cart currently
  if (isExtra) {
    return ItemAvailabilityStatus.CART_EXTRA;
  }

  const burgersForBreakfastDayPart = findBurgersForBreakfastDayPart(activeDayParts);
  const itemDayParts = getMenuItemDayParts(data);
  const itemIsBurgerForBreakfast = itemDayParts.includes(
    DAY_PART_SELECTIONS.BURGERS_FOR_BREAKFAST.toLowerCase()
  );

  // Burgers for Breakfast
  if (!storeHasBurgersForBreakfast && itemIsBurgerForBreakfast && !!burgersForBreakfastDayPart) {
    return ItemAvailabilityStatus.OUT_OF_DAYPART;
  }

  if (
    burgersForBreakfastDayPart &&
    itemIsBurgerForBreakfast &&
    !isAvailableForActiveDayParts({
      activeDayParts: [burgersForBreakfastDayPart],
      menuData: data,
    })
  ) {
    return ItemAvailabilityStatus.OUT_OF_DAYPART;
  }

  // check its available for current dayparts
  if (activeDayParts.length && !isAvailableForActiveDayParts({ activeDayParts, menuData: data })) {
    return ItemAvailabilityStatus.OUT_OF_DAYPART;
  }

  // check if we have some prices or the vendor from the store
  if (!prices || !vendor) {
    return ItemAvailabilityStatus.STORE_NOT_SELECTED;
  }

  // check prices exist
  if (data._type === MenuObjectTypes.COMBO) {
    // FIXME: unsafe cast - change comboIsAvailable signature to use generated types
    return comboIsAvailable(data as ISanityCombo, vendor, prices)
      ? ItemAvailabilityStatus.AVAILABLE
      : ItemAvailabilityStatus.UNAVAILABLE;
  }

  // check if item is picker and has options
  if (data._type === MenuObjectTypes.PICKER) {
    return data.options?.length
      ? ItemAvailabilityStatus.AVAILABLE
      : ItemAvailabilityStatus.UNAVAILABLE;
  }

  return itemIsAvailable(data, vendor, prices)
    ? ItemAvailabilityStatus.AVAILABLE
    : ItemAvailabilityStatus.UNAVAILABLE;
};
