import { Center, Header, Icon, Text } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';

export const ModalBody = Center.withConfig({
  width: 'full',
  paddingY: '$10',
});

export const Heading = Header.withConfig({
  marginY: '$2',
  textAlign: 'center',
  fontSize: '3xl',
  lineHeight: 'lg',
  variant: 'headerTwo',
});

export const Info = Text.withConfig({
  marginY: '$0',
  marginRight: '$0',
  marginLeft: '$4',
  variant: 'copyOne',
});

export const SuccessIcon = Icon.withConfig({
  width: 'full',
  height: 'full',
  variant: 'bkCrown',
  size: '$12',
  color: theme.token('crown-color'),
});
