import React, { createContext, useContext } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';

import useNetwork from './hook';

export interface NetworkCtx {
  isNetworkConnected: boolean;
  isInternetReachable: boolean;
  sanityEndpoints: {
    graphql: string;
    groq: string;
    graphqlV2: string;
  };
}

export const NetworkContext = createContext<NetworkCtx>({} as NetworkCtx);
export const useNetworkContext = () => useContext(NetworkContext);

export function NetworkProvider({ children }: IBaseProps) {
  const value = useNetwork();

  return <NetworkContext.Provider value={value}>{children}</NetworkContext.Provider>;
}

export default NetworkContext.Consumer;
