import { differenceInMinutes } from 'date-fns';

import LocalStorage, { StorageKeys } from 'utils/local-storage';

export const preloadedOrder = () => {
  const order = LocalStorage.getItem(StorageKeys.ORDER);
  if (order) {
    const { curbsidePickupOrderTimePlaced } = order;
    // We timeout saved curbside order after 1 hour if user has not submitted the order to prevent outdated order
    if (
      !curbsidePickupOrderTimePlaced ||
      differenceInMinutes(new Date(), new Date(curbsidePickupOrderTimePlaced)) > 60
    ) {
      return {
        ...order,
        curbsidePickupOrderTimePlaced: '',
        curbsidePickupOrderId: '',
      };
    }
    return order;
  }
  return {};
};
