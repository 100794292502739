import { useMemo } from 'react';

import { useLocale } from 'state/intl';
import { getConfigValue } from 'utils/environment';

interface IUseConfigValueProps {
  key: string;
  defaultValue?: any;
  isRegionalized?: boolean;
}

export const useConfigValue = ({
  key,
  defaultValue = '',
  isRegionalized = true,
}: IUseConfigValueProps) => {
  const { region } = useLocale();

  const value = useMemo(() => {
    if (isRegionalized) {
      return getConfigValue({ key, region, defaultValue });
    }
    return getConfigValue({ key, defaultValue });
  }, [defaultValue, isRegionalized, key, region]);

  return value;
};
