import { DeliveryStatus } from 'state/order/types';

export const STATUS_PERCENTS = {
  [DeliveryStatus.QUOTE_SUCCESSFUL]: 10,
  [DeliveryStatus.ORDER_CREATED]: 33,
  [DeliveryStatus.DRIVER_ASSIGNED]: 33,
  [DeliveryStatus.DRIVER_UNASSIGNED]: 33,
  [DeliveryStatus.DRIVER_STARTING]: 33,
  [DeliveryStatus.DRIVER_AT_STORE]: 50,
  [DeliveryStatus.ORDER_PICKED_UP]: 66.7,
  [DeliveryStatus.DRIVER_AT_CUSTOMER]: 83.3,
  [DeliveryStatus.ORDER_DROPPED_OFF]: 100,
  [DeliveryStatus.ORDER_ABANDONED]: 100,
};

export const STATUS_MESSAGES = {
  [DeliveryStatus.QUOTE_SUCCESSFUL]: 'deliveryStatusQuoteSuccessful',
  [DeliveryStatus.QUOTE_ERROR]: 'deliveryStatusQuoteError',
  [DeliveryStatus.QUOTE_REQUESTED]: 'deliveryStatusQuoteRequested',
  [DeliveryStatus.QUOTE_UNAVAILABLE]: 'deliveryStatusQuoteUnavailable',
  [DeliveryStatus.ORDER_CREATED]: 'deliveryStatusOrderCreated',
  [DeliveryStatus.DRIVER_ASSIGNED]: 'deliveryStatusDriverAssigned',
  [DeliveryStatus.DRIVER_UNASSIGNED]: 'deliveryStatusDriverUnassigned',
  [DeliveryStatus.DRIVER_STARTING]: 'deliveryStatusDriverStarting',
  [DeliveryStatus.DRIVER_AT_STORE]: 'deliveryStatusDriverAtStore',
  [DeliveryStatus.ORDER_PICKED_UP]: 'deliveryStatusOrderPickedUp',
  [DeliveryStatus.DRIVER_AT_CUSTOMER]: 'deliveryStatusDriverAtCustomer',
  [DeliveryStatus.ORDER_DROPPED_OFF]: 'deliveryStatusOrderDroppedOff',
  [DeliveryStatus.ORDER_ABANDONED]: 'deliveryStatusOrderAbandoned',
  [DeliveryStatus.ORDER_CANCELLED]: 'deliveryStatusOrderCancelled',
  [DeliveryStatus.ORDER_ERROR]: 'deliveryStatusOrderError',
  [DeliveryStatus.ORDER_TIMEOUT]: 'deliveryStatusOrderTimeout',
};

export const getDeliveryHeading = ({ deliveryStatus }: { deliveryStatus: DeliveryStatus }) => {
  const deliveryProgress = STATUS_PERCENTS[deliveryStatus];
  if (deliveryProgress <= STATUS_PERCENTS[DeliveryStatus.ORDER_CREATED]) {
    return 'deliveryOrderPlaced';
  }
  if (deliveryProgress < STATUS_PERCENTS[DeliveryStatus.ORDER_PICKED_UP]) {
    return 'deliveryOrderBeingPrepared';
  }
  if (deliveryProgress < STATUS_PERCENTS[DeliveryStatus.ORDER_DROPPED_OFF]) {
    return 'deliveryDriverEnroute';
  }
  return 'deliveryDelivered';
};
