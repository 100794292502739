import { AlertDialog, Header, Text, addWithConfig } from '@rbilabs/universal-components';

import ActionButton from 'components/action-button';
import { CloseButton } from 'components/dialog';

export const OptInDialogContent = addWithConfig(AlertDialog.Content);
export const OptInDialogHeader = addWithConfig(AlertDialog.Header);
export const OptInDialogHeading = Header.withConfig({
  w: 'full',
  marginTop: '$4',
  textAlign: 'center',
});
export const OptInDialogBody = addWithConfig(AlertDialog.Body);
export const OptInDialogFooter = addWithConfig(AlertDialog.Footer);
export const OptInDialogBodyText = Text;

export const CreateLoyaltyUserButton = addWithConfig(ActionButton).withConfig({
  fullWidth: true,
});

export const CloseOptInModalButton = CloseButton;
