import { Platform as RBIPlatform } from 'generated/graphql-gateway';

export enum RBIEnv {
  DEV = 'dev',
  PROD = 'prod',
  QA = 'qa',
  SANDBOX = 'sandbox',
  STAGING = 'staging',
  TEST = 'test',
}

export enum Region {
  US = 'US',
  CA = 'CA',
}

export enum GraphQLEnv {
  DEV = 'dev',
  PROD = 'prod',
  QA = 'qa',
  SANDBOX = 'sandbox',
  STAGING = 'staging',
  TEST = 'test',
  LOCAL = 'local',
}

export { RBIPlatform as Platform, RBIPlatform };

// Only use for logging universal attributes in mParticle
export enum RBIExpandedPlatform {
  IOS = 'iOS',
  ANDROID = 'Android',
  WEB = 'Web',
}

export enum RBIBrand {
  BK = 'bk',
  PLK = 'plk',
  TH = 'th',
  FHS = 'fhs',
}

export enum RBIFullBrandName {
  Popeyes = 'Popeyes',
  TimHortons = 'Tim Hortons',
  BurgerKing = 'Burger King',
  FirehouseSubs = 'Firehouse Subs',
}

export enum RBIAppUriScheme {
  Popeyes = 'popeyes://',
  TimHortons = 'timhortons://',
  BurgerKing = 'burgerking://',
  FirehouseSubs = 'firehousesubs://',
}
