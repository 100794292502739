import { Auth } from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

import AuthStorage from 'utils/cognito/storage';
import { EventName, emitEvent } from 'utils/event-hub';
import { StorageKeys } from 'utils/local-storage';
import logger from 'utils/logger';
import { withNetworkRetries } from 'utils/network/utils';

import { getCognitoRetryConfig } from './util';

let pendingCurrentSessionCall: Promise<CognitoUserSession> | null = null;

export const getCurrentSessionWrapper = () => {
  // de-dupe calls to cognito as it does not dedupe internally for errors
  if (pendingCurrentSessionCall) {
    return pendingCurrentSessionCall;
  }

  const result = withNetworkRetries(
    () => Auth.currentSession(),
    getCognitoRetryConfig('Auth.currentSession')
  ).finally(() => {
    pendingCurrentSessionCall = null;
  });

  pendingCurrentSessionCall = result;

  return result;
};

// return the current user session
export const getCurrentSession = (): Promise<CognitoUserSession | null> =>
  getCurrentSessionWrapper().catch(() => {
    // if cognito failed to get a valid session
    // clear local storage to prevent a user with
    // no session from being "logged in" in app state
    // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    AuthStorage.setItem(StorageKeys.USER_AUTH_TOKEN, null);
    return null;
  });

let pendingForceRefreshCall: Promise<CognitoUserSession> | null = null;

export const forceRefreshGetCurrentSession = () => {
  if (pendingForceRefreshCall) {
    return pendingForceRefreshCall;
  }

  const result = withNetworkRetries(
    () => Auth.currentAuthenticatedUser({ bypassCache: true }).then(() => Auth.currentSession()),
    getCognitoRetryConfig('forceRefreshGetCurrentSession')
  )
    .then(user => {
      const token = user.getIdToken();
      const jwt = token.getJwtToken();
      logger.info({
        message: 'forceRefreshGetCurrentSession succeeded',
        isForceRefreshUserSession: true,

        // TODO could remove these fields later after we gather data on this situation
        jwtLength: jwt.length,
        jwtExp: token.getExpiration(),
        jwtIat: token.getIssuedAt(),
      });
      // let react know about new user session
      emitEvent(EventName.SET_CURRENT_USER, user);
      return user;
    })
    .catch(error => {
      // soft log the user out setting them to null in memory, there is no recovery here and the UI can get into a very funky state otherwise
      // "soft" don't clear everything and behaves similar to cognito refresh token timeout (currently 1 year)
      logger.error({
        error,
        message: 'forceRefreshGetCurrentSession failed, soft clearing user',
        isForceRefreshUserSession: true,
      });
      emitEvent(EventName.SET_CURRENT_USER); // sets user to null
      return Promise.reject(error);
    })
    .finally(() => {
      pendingForceRefreshCall = null;
    });

  pendingForceRefreshCall = result;

  return result;
};
