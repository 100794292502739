import { getConfigValue } from 'utils/environment/config';
import { Region } from 'utils/environment/types';

export const PROD_SUPPORTED_REGIONS =
  {
    US: {
      US: Region.US,
    },
    CA: {
      CA: Region.CA,
    },
  }[getConfigValue({ key: 'buildRegion' }).toUpperCase()] || {};

// TODO: RN - locale switching -- should pull from specific region
export const PROD_DEFAULT_REGION = getConfigValue({ key: 'buildRegion' }).toUpperCase();
