import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { isNumber } from 'lodash-es';
import { dollarsToCents } from 'utils';

import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

/**
 * @name computeTipAmountCents
 * @returns {number} representing the tip amount in cents for
 *    either the base dollar amount OR the percentage amount
 */

export const TipAmounts = {
  PERCENT_AMOUNTS: [15, 18, 20],
  DOLLAR_AMOUNTS: [2, 3, 4],
  PERCENT_DEFAULT: 18,
  DOLLAR_DEFAULT: 3,
  STYLE_THRESHOLD: 1500,
  MAX_AMOUNT: 5000,
};

export const computeTipAmountCents = (baseAmount: number, percentAmount?: number) => {
  if (percentAmount) {
    return Math.round((percentAmount * baseAmount) / 100);
  }
  return dollarsToCents(baseAmount);
};

export interface IUpdateTipAmountProps {
  subTotalCents: number;
  otherDiscountAmount: number;
  newTipOption?: string;
  newTipAmount?: number;
}

export interface ITipSelection {
  percentAmount: number;
  dollarAmount: number;
  otherAmount: number;
  isOtherSelected: boolean;
}

export interface IUseCartTip {
  tipSelection: ITipSelection;
  setTipSelection: Dispatch<SetStateAction<ITipSelection>>;
  tipAmount: number;
  setTipAmount: Dispatch<SetStateAction<number>>;
  updateTipAmount: (tipDetails: IUpdateTipAmountProps) => void;
  shouldShowTipPercentage: (subTotalCents: number) => boolean;
}

export const defaultTipSelection: ITipSelection = {
  percentAmount: TipAmounts.PERCENT_DEFAULT,
  dollarAmount: TipAmounts.DOLLAR_DEFAULT,
  otherAmount: 0,
  isOtherSelected: false,
};

export const useCartTip = (): IUseCartTip => {
  const [tipAmount, setTipAmount] = useState(0);
  const [tipSelection, setTipSelection] = useState<ITipSelection>(defaultTipSelection);

  const tipPercentThresholdCents = useFlag(LaunchDarklyFlag.TIP_PERCENT_THRESHOLD_CENTS) || 0;

  const shouldShowTipPercentage = useCallback(
    (subTotalCents: number) => {
      return (
        tipPercentThresholdCents <= 0 ||
        subTotalCents > tipPercentThresholdCents ||
        TipAmounts.DOLLAR_DEFAULT > subTotalCents / 100
      );
    },
    [tipPercentThresholdCents]
  );

  const updateTipAmount = useCallback(
    (tipDetails: IUpdateTipAmountProps) => {
      setTipAmount(() => {
        /** driver tip should be based on PRE-discounted subtotal */
        const baseAmountToTip = tipDetails.subTotalCents + tipDetails.otherDiscountAmount;
        const isShowingPercentage = shouldShowTipPercentage(baseAmountToTip);
        if (tipDetails.newTipOption === 'dollar' && isNumber(tipDetails.newTipAmount)) {
          return computeTipAmountCents(tipDetails.newTipAmount);
        }
        if (tipDetails.newTipOption === 'percent' && tipDetails.newTipAmount) {
          return computeTipAmountCents(baseAmountToTip, tipDetails.newTipAmount);
        }
        if (tipSelection.isOtherSelected) {
          return computeTipAmountCents(tipSelection.otherAmount);
        }
        if (isShowingPercentage) {
          return computeTipAmountCents(baseAmountToTip, tipSelection.percentAmount);
        }
        return computeTipAmountCents(tipSelection.dollarAmount);
      });
    },
    [
      shouldShowTipPercentage,
      tipSelection.dollarAmount,
      tipSelection.isOtherSelected,
      tipSelection.otherAmount,
      tipSelection.percentAmount,
    ]
  );

  return {
    tipAmount,
    setTipAmount,
    tipSelection,
    setTipSelection,
    updateTipAmount,
    shouldShowTipPercentage,
  };
};
