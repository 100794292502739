import { useCallback } from 'react';

import { DeliveryStatus, usePriceOrderMutation } from 'generated/graphql-gateway';
import usePosVendor from 'hooks/menu/use-pos-vendor';
import { useOrderStatus } from 'hooks/order-status';
import { useCart } from 'hooks/use-cart';
import useIsSignUpAfterCart from 'hooks/use-is-sign-up-after-cart';
import { UserDetails } from 'state/auth/hooks/use-current-user';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { OrderStatus, useOrderContext } from 'state/order';
import { buildPriceDeliveryInput, buildPriceOrderInput } from 'utils/cart';
import { computeOtherDiscountAmount } from 'utils/cart/helper';
import { isDelivery } from 'utils/service-mode';
import { PerformanceMarks, setMark } from 'utils/timing';

import { IPriceOrderParams, IUsePriceOrderParams } from './types';

// default order status polling interval, in ms
const DEFAULT_POLL_INTERVAL = 1000;

export const usePriceOrder = ({
  cartEntries,
  orderStatusPollInterval = DEFAULT_POLL_INTERVAL,
  updateTipAmount,
}: IUsePriceOrderParams) => {
  const { calculateCartTotalWithDiscount } = useCart();
  const { quoteId } = useOrderContext();
  const isSignUpAfterCart = useIsSignUpAfterCart();
  const [fireMutation, mutationResult] = usePriceOrderMutation({
    context: { shouldNotBatch: true },
  });
  const enablePaymentErrorContinue = useFlag(LaunchDarklyFlag.ENABLE_PAYMENT_ERROR_CONTINUE);
  const hideTipAmountCheckout = useFlag(LaunchDarklyFlag.HIDE_TIP_AMOUNT_CHECKOUT);

  const rbiOrderId = mutationResult.data ? mutationResult.data.priceOrder.rbiOrderId : null;

  const successStatuses = enablePaymentErrorContinue
    ? [OrderStatus.PRICE_SUCCESSFUL, OrderStatus.PAYMENT_ERROR, OrderStatus.VALIDATION_ERROR]
    : [OrderStatus.PRICE_SUCCESSFUL, OrderStatus.VALIDATION_ERROR];

  const { isCartTotalNegative = false } = calculateCartTotalWithDiscount?.() || {};

  const queryResult = useOrderStatus({
    failureStatuses: {
      delivery: [DeliveryStatus.QUOTE_ERROR, DeliveryStatus.QUOTE_UNAVAILABLE],
      pos: OrderStatus.PRICE_ERROR,
    },
    onSuccess(rbiOrder) {
      if (isDelivery(rbiOrder.cart.serviceMode)) {
        const otherDiscountAmount = computeOtherDiscountAmount(rbiOrder.cart.discounts);
        if (updateTipAmount) {
          updateTipAmount({
            subTotalCents: hideTipAmountCheckout ? 0 : rbiOrder.cart.subTotalCents,
            otherDiscountAmount,
          });
        }
      }
      setMark(PerformanceMarks.PRICE_END);
    },
    orderStatusPollInterval,
    rbiOrderId,
    successStatuses: {
      delivery: DeliveryStatus.QUOTE_SUCCESSFUL,
      pos: successStatuses,
    },
    skip: isCartTotalNegative,
  });

  const { orderStatus, serverOrder } = queryResult;
  const { vendor } = usePosVendor();

  const priceOrder = useCallback(
    (options: Omit<IPriceOrderParams, 'cartEntries' | 'redeemReward'>, user: UserDetails) => {
      if (!cartEntries.length || isSignUpAfterCart || isCartTotalNegative) {
        return;
      }

      const input = buildPriceOrderInput({ cartEntries, vendor, options });

      if (!input) {
        return;
      }

      const delivery = buildPriceDeliveryInput(options, user, quoteId);

      setMark(PerformanceMarks.PRICE_START);

      return fireMutation({
        variables: {
          delivery,
          input,
        },
      });
    },
    [cartEntries, fireMutation, isSignUpAfterCart, isCartTotalNegative]
  );

  return {
    loading: mutationResult.loading || queryResult.loading,
    order: queryResult,
    orderStatus,
    priceOrder,
    priceOrderFailure: queryResult.failure,
    priceOrderSuccess: queryResult.success,
    priceResult: mutationResult,
    rbiOrderId,
    serverOrder,
  };
};
