import { Svg, SvgPath, SvgProps, registerSVG } from '../../../components/svg';

export function Mobile(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <SvgPath
        d="M17 1.01L7 1C5.9 1 5 1.9 5 3V21C5 22.1 5.9 23 7 23H17C18.1 23 19 22.1 19 21V3C19 1.9 18.1
      1.01 17 1.01ZM17 19H7V5H17V19Z"
      />
    </Svg>
  );
}

registerSVG('Mobile', Mobile);
