function sanitizeUrlForId(url: string): string {
  // Remove unsafe characters and replace them with a hyphen
  let sanitized = url.replace(/[^a-zA-Z0-9-_.]/g, '-');

  // Ensure the ID starts with a letter (prepend 'id-' if it doesn't)
  if (!/^[a-zA-Z]/.test(sanitized)) {
    sanitized = 'id-' + sanitized;
  }

  // Limit the length to 50 characters
  return sanitized.substring(0, 50);
}

export const loadScript = (url: string) => {
  return new Promise<void>((resolve, reject) => {
    const id = sanitizeUrlForId(url);
    const exists = document.getElementById(id);
    if (exists) {
      resolve();
      return;
    }

    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.id = id;
    script.onload = () => resolve();
    script.onerror = () => reject();
    head.appendChild(script);
  });
};
