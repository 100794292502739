import { useCallback, useMemo } from 'react';

import { isNil } from 'lodash-es';

import { IRestaurant } from '@rbi-ctg/store';
import { useConfigValue } from 'hooks/configs/use-config-value';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useServiceModeContext } from 'state/service-mode';
import { ServiceMode, ServiceModeStatus } from 'state/service-mode/types';
import {
  isMobileOrderingAvailable,
  isRestaurantOpen,
  useGetRestaurantAvailabilityFn,
  useGetRestaurantFn,
} from 'utils/restaurant';
import { PICKUP_SERVICE_MODES } from 'utils/service-mode';

import { IS_AVAILABLE_CURBSIDE_SERVICE_MODE } from './constants';

export const useServiceModeStatusGenerator = () => {
  const isCurbsideEnabled = useFlag(LaunchDarklyFlag.ENABLE_CURBSIDE);
  const isDeliveryEnabled = useFlag(LaunchDarklyFlag.ENABLE_DELIVERY);
  // Move these up in order to check for null (meaning LD service is down) or undefined
  // FYI - use ENABLE_* flags to avoid this necessity as null values will correctly disable the option
  const driveThruDisabled = useFlag(LaunchDarklyFlag.DISABLE_DRIVE_THRU);
  const dineInDisabled = useFlag(LaunchDarklyFlag.DISABLE_DINE_IN);
  const takeOutDisabled = useFlag(LaunchDarklyFlag.DISABLE_TAKE_OUT);

  // ENABLE_DELIVERY_CHECKOUT_OUTSIDE_OPENING_HOURS
  const enableDeliveryOutsideOpeningHours = useFlag(
    LaunchDarklyFlag.ENABLE_DELIVERY_CHECKOUT_OUTSIDE_OPENING_HOURS
  );

  const generateServiceModeStatusForStore = useCallback(
    (store: IRestaurant) => {
      const serviceModeStatus: ServiceModeStatus = {
        CURBSIDE: {
          capable: !!store.hasCurbside,
          available: false,
          disabled: !isCurbsideEnabled,
          open: false,
        },
        DELIVERY: {
          capable: !!store.hasDelivery,
          available: false,
          disabled: !isDeliveryEnabled,
          open: false,
        },
        DRIVE_THRU: {
          capable: !!store.hasDriveThru,
          available: false,
          disabled: isNil(driveThruDisabled) || driveThruDisabled,
          open: false,
        },
        EAT_IN: {
          capable: !!store.hasDineIn,
          available: false,
          disabled: isNil(dineInDisabled) || dineInDisabled,
          open: false,
        },
        TAKEOUT: {
          capable: !!store.hasTakeOut,
          available: false,
          disabled: isNil(takeOutDisabled) || takeOutDisabled,
          open: false,
        },
      };

      const curbsideOpen = isRestaurantOpen(store.curbsideHours);
      const deliveryOpen =
        isRestaurantOpen(store.deliveryHours) || enableDeliveryOutsideOpeningHours;
      const diningRoomOpen = isRestaurantOpen(store.diningRoomHours);
      const driveThruOpen = isRestaurantOpen(store.driveThruHours);

      /** Need to know if service mode is open for store selection 2.0 */
      serviceModeStatus.DELIVERY.open = deliveryOpen;
      serviceModeStatus.EAT_IN.open = diningRoomOpen;
      serviceModeStatus.TAKEOUT.open = diningRoomOpen;
      serviceModeStatus.CURBSIDE.open = curbsideOpen;
      serviceModeStatus.DRIVE_THRU.open = driveThruOpen;

      serviceModeStatus.DELIVERY.available = serviceModeStatus.DELIVERY.capable && deliveryOpen;
      serviceModeStatus.EAT_IN.available = serviceModeStatus.EAT_IN.capable && diningRoomOpen;
      serviceModeStatus.CURBSIDE.available =
        IS_AVAILABLE_CURBSIDE_SERVICE_MODE && serviceModeStatus.CURBSIDE.capable && curbsideOpen;
      serviceModeStatus.TAKEOUT.available = serviceModeStatus.TAKEOUT.capable && diningRoomOpen;
      serviceModeStatus.DRIVE_THRU.available =
        serviceModeStatus.DRIVE_THRU.capable && driveThruOpen;

      return serviceModeStatus;
    },
    [
      isCurbsideEnabled,
      isDeliveryEnabled,
      driveThruDisabled,
      dineInDisabled,
      takeOutDisabled,
      enableDeliveryOutsideOpeningHours,
    ]
  );

  return {
    generateServiceModeStatusForStore,
  };
};

export const isAvailableAndNotDisabled = ({
  available,
  disabled,
}: ServiceModeStatus[keyof ServiceModeStatus]) => available && !disabled;

export const useServiceModeStatus = (store: IRestaurant) => {
  const restaurantsConfig = useConfigValue({ key: 'restaurants', defaultValue: {} });
  const validMobileOrderingEnvs = useMemo(() => restaurantsConfig.validMobileOrderingEnvs ?? [], [
    restaurantsConfig.validMobileOrderingEnvs,
  ]);
  const { serviceMode } = useServiceModeContext();

  const enableOrdering = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const isAlphaBetaStoreOrderingEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_ALPHA_BETA_STORE_ORDERING
  );

  const hasAvailableProperty = useGetRestaurantAvailabilityFn();
  const getRestaurant = useGetRestaurantFn();
  const { generateServiceModeStatusForStore } = useServiceModeStatusGenerator();

  const serviceModeStatus = useMemo(() => generateServiceModeStatusForStore(store), [
    generateServiceModeStatusForStore,
    store,
  ]);

  const allServiceModesUnavailable = Object.values(serviceModeStatus).every(
    ({ available, disabled }) => !available || disabled
  );

  const availablePickupServiceModes = useMemo(
    () => PICKUP_SERVICE_MODES.filter(value => isAvailableAndNotDisabled(serviceModeStatus[value])),
    [serviceModeStatus]
  );

  const isRestaurantAvailable = useCallback(
    async (restaurantData: IRestaurant) => {
      const mobileOrderingEnabled = isMobileOrderingAvailable(
        restaurantData,
        isAlphaBetaStoreOrderingEnabled,
        validMobileOrderingEnvs
      );
      const { number: storeId } = restaurantData;

      if (!storeId) {
        return false;
      }

      const rbiRestaurant = await getRestaurant(storeId);

      const isRestaurantPosAvailable = Boolean(rbiRestaurant?.available);
      const serviceModeStatusForStoreToCheck = generateServiceModeStatusForStore(restaurantData);
      const open =
        !serviceMode ||
        serviceMode === ServiceMode.DELIVERY ||
        (serviceModeStatusForStoreToCheck[serviceMode].available &&
          !serviceModeStatusForStoreToCheck[serviceMode].disabled);
      return open && isRestaurantPosAvailable && mobileOrderingEnabled;
    },
    [generateServiceModeStatusForStore, serviceMode, isAlphaBetaStoreOrderingEnabled] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /** Determines whether a restaurant can be selected */
  const restaurantCanBeSelected = useMemo(() => {
    if (!enableOrdering) {
      return false;
    }

    // Store selection 1.0: store can only be selected if there is an available service mode
    return (
      hasAvailableProperty(store) &&
      isMobileOrderingAvailable(store, isAlphaBetaStoreOrderingEnabled, validMobileOrderingEnvs) &&
      Boolean(availablePickupServiceModes.length)
    );
  }, [
    store,
    enableOrdering,
    hasAvailableProperty,
    isAlphaBetaStoreOrderingEnabled,
    validMobileOrderingEnvs,
    availablePickupServiceModes.length,
  ]);

  return {
    allServiceModesUnavailable,
    availablePickupServiceModes,
    isRestaurantAvailable,
    restaurantCanBeSelected,
    serviceModeStatus,
  };
};
