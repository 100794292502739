import React from 'react';

import useProviders from '../../providers';

export default function ReduceProviders({ children }: React.PropsWithChildren) {
  const providers = useProviders();

  return (
    <>
      {providers.reduceRight((acc, Comp) => {
        return <Comp>{acc}</Comp>;
      }, children)}
    </>
  );
}
