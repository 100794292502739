import { Svg, SvgPath, SvgProps, SvgRect, registerSVG } from '../../../components/svg';

export function PayPal(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <SvgRect y="4" width="24" height="16" rx="2" fill="white" />
      <SvgRect
        x="0.5"
        y="4.5"
        width="23"
        height="15"
        rx="1.5"
        stroke="black"
        strokeOpacity="0.3"
        fill="white"
      />
      <SvgPath
        d="M10.4077 16.6609L10.5811 15.5601L10.195 15.5512H8.35156L9.63266 7.42816C9.63664 7.40364 9.64956 7.38078 9.66845 7.36454C9.68734 7.3483 9.71153 7.33936 9.73671 7.33936H12.845C13.8769 7.33936 14.589 7.55409 14.9608 7.97792C15.1351 8.17674 15.2462 8.38451 15.2998 8.61316C15.3562 8.85308 15.3572 9.13972 15.3022 9.48932L15.2982 9.51483V9.73884L15.4725 9.83759C15.6193 9.91547 15.7359 10.0046 15.8254 10.1067C15.9745 10.2767 16.0709 10.4927 16.1117 10.7489C16.1538 11.0123 16.1399 11.3258 16.0709 11.6807C15.9914 12.089 15.8628 12.4445 15.6892 12.7355C15.5295 13.0036 15.326 13.2259 15.0844 13.3982C14.8538 13.5619 14.5798 13.6862 14.2699 13.7657C13.9697 13.8439 13.6274 13.8834 13.2519 13.8834H13.01C12.8371 13.8834 12.669 13.9457 12.5372 14.0573C12.4049 14.1713 12.3175 14.3271 12.2906 14.4974L12.2724 14.5965L11.9662 16.5367L11.9523 16.6079C11.9486 16.6305 11.9423 16.6417 11.9331 16.6493C11.9248 16.6563 11.9129 16.6609 11.9013 16.6609H10.4077Z"
        fill="#253B80"
      />
      <SvgPath
        d="M15.6377 9.54077C15.6284 9.60009 15.6178 9.66073 15.6058 9.72303C15.1959 11.8276 13.7936 12.5546 12.0025 12.5546H11.0905C10.8715 12.5546 10.6869 12.7137 10.6528 12.9297L10.1859 15.8909L10.0537 16.7303C10.0315 16.8721 10.1408 17 10.284 17H11.9014C12.0929 17 12.2556 16.8609 12.2858 16.672L12.3017 16.5898L12.6062 14.6572L12.6258 14.5512C12.6556 14.3616 12.8186 14.2224 13.0102 14.2224H13.2521C14.8192 14.2224 16.0459 13.5862 16.4045 11.7451C16.5542 10.976 16.4767 10.3338 16.0804 9.88209C15.9604 9.74589 15.8116 9.63289 15.6377 9.54077Z"
        fill="#179BD7"
      />
      <SvgPath
        d="M15.2089 9.36991C15.1463 9.35168 15.0817 9.33511 15.0154 9.3202C14.9488 9.30562 14.8805 9.2927 14.8103 9.28143C14.5644 9.24167 14.295 9.22278 14.0063 9.22278H11.5701C11.5101 9.22278 11.4531 9.23636 11.4021 9.26089C11.2897 9.3149 11.2062 9.42127 11.186 9.5515L10.6677 12.8341L10.6528 12.9299C10.687 12.7138 10.8715 12.5548 11.0906 12.5548H12.0025C13.7936 12.5548 15.196 11.8274 15.6059 9.72316C15.6182 9.66086 15.6284 9.60021 15.6377 9.5409C15.534 9.48589 15.4217 9.43884 15.3007 9.39874C15.2709 9.3888 15.2401 9.37919 15.2089 9.36991Z"
        fill="#222D65"
      />
      <SvgPath
        d="M11.1858 9.55126C11.2061 9.42103 11.2896 9.31466 11.4019 9.26097C11.4533 9.23645 11.5099 9.22286 11.5699 9.22286H14.0062C14.2948 9.22286 14.5642 9.24175 14.8101 9.28152C14.8803 9.29278 14.9486 9.30571 15.0152 9.32029C15.0815 9.3352 15.1461 9.35177 15.2087 9.37C15.2399 9.37927 15.2707 9.38888 15.3009 9.39849C15.4218 9.43859 15.5341 9.48598 15.6379 9.54065C15.7598 8.76292 15.6369 8.23338 15.2164 7.75388C14.7528 7.226 13.916 7 12.8454 7H9.73707C9.51836 7 9.33179 7.15906 9.29799 7.37545L8.00331 15.582C7.97779 15.7443 8.10305 15.8908 8.26675 15.8908H10.1857L10.6676 12.8339L11.1858 9.55126Z"
        fill="#253B80"
      />
    </Svg>
  );
}

registerSVG('PayPal', PayPal);
