import React, { useState } from 'react';

import { MapWrapper } from './map.styled';
import { IStoreLocatorMapProps } from './types';

/*
 * NOTE: We want to only render the map once
 * So we "hide" / `display: none` the map container when not on the map view
 */
export const StoreLocatorMap = ({ currentViewIsMap, map }: IStoreLocatorMapProps) => {
  // We need to track when we have shown the map already so we don't load it again
  const [hasBeenMounted, setHasBeenMounted] = useState(currentViewIsMap);
  if (!hasBeenMounted && !currentViewIsMap) {
    return null;
  }
  return (
    <MapWrapper
      ref={() => setHasBeenMounted(true)}
      hide={!currentViewIsMap}
      testID="store-locator-map"
    >
      {map}
    </MapWrapper>
  );
};
