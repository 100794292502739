import { useCallback, useMemo } from 'react';

import { useRoute } from 'hooks/navigation/use-route';
import { useIsMobileBreakpoint } from 'hooks/use-media-query';
import { RunningExperiments, useExperiments } from 'state/experiments';
import { routes } from 'utils/routing';

interface IUseMenuTopServiceMode {
  evaluateMenuTopServiceModeExperiment: () => boolean;
  isTreatmentEnabled: boolean;
}
export enum MenuTopServiceModeExperimentVariants {
  CONTROL = 'control',
  TREATMENT = 'treatment',
}

export const useMenuTopServiceMode = (): IUseMenuTopServiceMode => {
  const menuTopServiceModeExperiment = RunningExperiments.MenuTopServiceMode;

  const isMobile = useIsMobileBreakpoint();
  const { pathname } = useRoute();
  const { variant, getVariantValue } = useExperiments();

  const isBaseRoute = pathname === routes.base;
  const isMenuPage = pathname.includes(routes.menu);

  const { value: experimentValue } = useMemo(() => {
    return getVariantValue(menuTopServiceModeExperiment) || {};
  }, [getVariantValue, menuTopServiceModeExperiment]);

  const isControl = isBaseRoute && experimentValue === MenuTopServiceModeExperimentVariants.CONTROL;
  const isTreatment =
    isMenuPage && experimentValue === MenuTopServiceModeExperimentVariants.TREATMENT;

  const evaluateMenuTopServiceModeExperiment = useCallback(() => {
    if (!isMobile) {
      return false;
    }

    if (!experimentValue && isBaseRoute) {
      return true;
    }

    if (isControl || isTreatment) {
      return variant(menuTopServiceModeExperiment, experimentValue);
    }

    return false;
  }, [
    experimentValue,
    isBaseRoute,
    isControl,
    isMobile,
    isTreatment,
    menuTopServiceModeExperiment,
    variant,
  ]);

  return { evaluateMenuTopServiceModeExperiment, isTreatmentEnabled: isTreatment };
};
