import { memo } from 'react';

import { Skeleton } from '../skeleton';
import { VStack, VStackProps } from '../v-stack';

export const CarouselSkeletonItem = memo((vStackProps: VStackProps) => (
  <VStack
    borderWidth="1"
    space={4}
    overflow="hidden"
    rounded="lg"
    borderColor="coolGray.200"
    background="token.background-pattern"
    mr="$4"
    {...vStackProps}
  >
    <Skeleton h="$24" />
    <Skeleton.Text px="$4" h="$20" />
  </VStack>
));
