import { OperationStatus } from '../../../../../generated/graphql-gateway';

export enum ShortCodePaymentResultPollingStatus {
  None = 'None',
  Pending = 'Pending',
  Processing = 'Processing',
  Completed = 'Completed',
  NotFound = 'NotFound',
}

export interface IUseShortCodePaymentResultPollStatusParams {
  loyaltyId?: string;
  shortCode?: string;
  skipPolling: boolean;
  pollInterval: number;
  currentDateTime: Date;
}
export interface IUseShortCodePaymentResultPollStatus {
  pollingShortCodePaymentResultStatus: ShortCodePaymentResultPollingStatus;
  resetPollingPaymentResultStatus: () => void;
  paymentResult: OperationStatus;
}
