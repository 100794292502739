import { ReactNode } from 'react';

import {
  Badge,
  Box,
  Button,
  Divider,
  HStack,
  Header,
  Text,
  VStack,
} from '@rbilabs/universal-components';
import { GestureResponderEvent } from 'react-native';

import { primitive } from 'styles/constants/primitives';

import { BadgeContainer } from './styled';

type IWalmartOfferItem = {
  name: string;
  description: string;
  descriptionL2?: string;
  image: ReactNode;
  activateButtonLabel: string;
  onPartnershipActivate: ((event: GestureResponderEvent) => void) | null | undefined;
  isLoadingUser: boolean;
  isWalmartUser?: boolean;
  linkedButtonLabel: string;
  showUnlinkButton: boolean;
  unlinkButtonLabel: string;
  onUnlinkUser: () => void;
  isUnlinkingUser: boolean;
};

export const WalmartOfferItem = ({
  name,
  description,
  descriptionL2,
  image,
  activateButtonLabel,
  onPartnershipActivate,
  isLoadingUser,
  isWalmartUser,
  linkedButtonLabel,
  showUnlinkButton,
  unlinkButtonLabel,
  onUnlinkUser,
  isUnlinkingUser,
}: IWalmartOfferItem) => (
  <Box bg="token.background-pattern" rounded={10} borderWidth={0} borderColor={'transparent'}>
    <VStack p="$3" pt="$3" borderTopRadius={10} borderBottomRadius={10}>
      <HStack mr="$2">
        {image}
        <VStack flexShrink={1} ml="$3" alignItems="flex-start" justifyContent="center">
          <Header
            variant="headerThree"
            color={'token.text-default'}
            ellipsizeMode="tail"
            numberOfLines={2}
            mb="$1"
          >
            {name}
          </Header>
          {!!description && (
            <Text fontSize={12} lineHeight={16}>
              {description}
            </Text>
          )}

          {!!descriptionL2 && (
            <Text fontSize={12} lineHeight={16} marginTop="$4">
              {descriptionL2}
            </Text>
          )}

          <BadgeContainer>
            {!isLoadingUser && (
              <>
                {!isWalmartUser && (
                  <Button
                    size="sm"
                    h="$7.5"
                    px="$7"
                    py="0"
                    onPress={onPartnershipActivate}
                    variant="outline"
                  >
                    {activateButtonLabel}
                  </Button>
                )}
                {isWalmartUser && (
                  <Badge variant="default-subtle" backgroundColor="#D1FAE5" iconName="checkFill">
                    {linkedButtonLabel}
                  </Badge>
                )}
                {isWalmartUser && showUnlinkButton && (
                  <>
                    <Divider
                      orientation="vertical"
                      height="16px"
                      width="1px"
                      mx="$2"
                      color={primitive.$blackOpacity10}
                    />
                    <Button
                      size="sm"
                      h="$7.5"
                      px="$3"
                      py="0"
                      onPress={onUnlinkUser}
                      variant="ghost"
                      _text={{
                        color: primitive.bk.$fieryRed,
                      }}
                      isLoading={isUnlinkingUser}
                    >
                      {unlinkButtonLabel}
                    </Button>
                  </>
                )}
              </>
            )}
          </BadgeContainer>
        </VStack>
      </HStack>
    </VStack>
  </Box>
);
