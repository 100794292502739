import { FC, PropsWithChildren } from 'react';

import Animated, { Easing, ZoomIn } from 'react-native-reanimated';

interface ZoomInViewProps {
  animate?: boolean;
  duration?: number;
}

export const ZoomInView: FC<PropsWithChildren<ZoomInViewProps>> = ({
  children,
  animate = true,
  duration = 300,
}) => {
  const animationConfig = animate ? ZoomIn.duration(duration).easing(Easing.ease) : undefined;
  return <Animated.View entering={animationConfig}>{children}</Animated.View>;
};
