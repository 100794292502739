import { onError } from '@apollo/client/link/error';

import logger from 'utils/logger';

import { REQUEST_CANCELLED_KEY } from './cancel-request-link';

export const withErrorLogger = onError(({ operation, graphQLErrors }) => {
  if (graphQLErrors && graphQLErrors.length > 0) {
    const loggableErrors = graphQLErrors.filter(
      error => !error.extensions?.[REQUEST_CANCELLED_KEY]
    );

    if (!loggableErrors || loggableErrors.length === 0) {
      return;
    }

    for (const error of loggableErrors) {
      error.message = `GraphQL error calling ${operation.operationName}: ${error.message}`;
      logger.error({
        error,
        operationName: operation.operationName,
      });
    }
  }
});
