import { FirebasePerformanceTypes } from '@react-native-firebase/perf';

import AuthStorage from 'utils/cognito/storage';
import { getConfigValue, isWeb } from 'utils/environment';
import { StorageKeys } from 'utils/local-storage';

import { perf } from './firebase-perf-wrapper';

type KeyValuePair = { [key: string]: string };

const MAX_TRACE_COUNT = 250;

const traces = new Map<string, FirebasePerformanceTypes.Trace>();

const ALLOW_DEV_ONLY_DEBUG_TRACES = !getConfigValue({ key: 'disableDevTraceLogging' }) && __DEV__;

/**
 * start a trace with a string name
 * be mindful of a few things:
 * - it wont throw errors or log them
 * - if you use the same name twice you better keep the returned trace reference
 * @param traceName the name of the trace as it will appear in the firebase performance tab
 * @param additionalMetadata additional metadata that will show up per trace instance, (up to 4, rest discarded!)
 * @returns the trace that can be used for stopping it (or use stopTrace if you just have the name)
 */
export const startTrace = (traceName: string, additionalMetadata?: KeyValuePair) => {
  try {
    const trace = perf().newTrace(traceName);
    if (ALLOW_DEV_ONLY_DEBUG_TRACES) {
      (trace as any).startTime = new Date().getTime();
    }

    const metadata = {
      cognitoId: AuthStorage.getItem(StorageKeys.USER_AUTH_TOKEN),
      ...additionalMetadata,
    };
    // filter for rules of attributes and non empty
    Object.entries(metadata)
      .filter(([, v]) => !!v)
      .slice(0, 5) // max of 5 attributes on a trace
      .forEach(([k, v]) => trace.putAttribute(k, v!));

    // prune traces on `stop` and preserve `this` context with bind
    const origStop = trace.stop.bind(trace);
    trace.stop = async () => {
      traces.delete(traceName);

      if (ALLOW_DEV_ONLY_DEBUG_TRACES) {
        DEV_ONLY_colorLog(traceName, trace);
      }

      return origStop().catch(() => null); // oh well
    };

    // prevent a memory leak situation that likely can't happen but...
    if (traces.size > MAX_TRACE_COUNT) {
      const oldestKey = traces.keys().next().value;
      traces.delete(oldestKey);
    }

    traces.set(traceName, trace);
    trace.start().catch(() => {});
    return trace;
  } catch (_err) {}
  return null;
};

// TODO add stopTrace if/when we need it but start returning the stop works OK

const devOnlyDupeTracker: string[] = [];
// in __DEV__ only:
// - log colors on a gradient based on seconds 0 green -> 1 yellow -> 2 red
// - log duplicate (within X seconds) traces
function DEV_ONLY_colorLog(traceName: string, trace: FirebasePerformanceTypes.Trace) {
  const timeSeconds = (new Date().getTime() - (trace as any).startTime) / 1000;
  devOnlyDupeTracker.push(traceName);
  let dupeAlert = '';
  const arrayCount = devOnlyDupeTracker.filter(x => x === traceName).length;
  if (arrayCount > 1) {
    dupeAlert = ` \x1b[37m (x${arrayCount} traces recently!) \x1b[0m`;
  }
  setTimeout(() => {
    // remove the traceName from the dupe tracking array after the timeout
    devOnlyDupeTracker.splice(devOnlyDupeTracker.indexOf('traceName'), 1);
  }, 5000);

  const clampedValue = Math.max(0, Math.min(2, timeSeconds));
  const message = `TRACE END - ${timeSeconds.toFixed(2)}s - ${traceName}${dupeAlert} `;

  if (isWeb) {
    let style = '';
    if (clampedValue <= 1) {
      const red = Math.round((clampedValue / 1) * 255);
      style = `color: rgb(${red}, 255, 0);`;
    } else {
      const green = 255 - Math.round(((clampedValue - 1) / 1) * 255);
      style = `color: rgb(255, ${green}, 0);`;
    }
    // eslint-disable-next-line no-console
    console.log(`%c${message}`, style);
  } else {
    // mobile terminal
    let colorCode: number;
    if (clampedValue <= 1) {
      const redScaled = Math.round((clampedValue / 1) * 5);
      colorCode = 16 + 36 * redScaled + 6 * 5;
    } else {
      const greenScaled = 5 - Math.round(((clampedValue - 1) / 1) * 5);
      colorCode = 16 + 36 * 5 + 6 * greenScaled;
    }
    // eslint-disable-next-line no-console
    console.log(`\x1b[38;5;${colorCode}m${message}\x1b[0m`);
  }
}
