import React from 'react';

import { Helmet as RNHelmet } from 'react-helmet-async';

import { fullBrandName } from 'utils/environment';

export const Helmet = ({ title }: { title: string }): RNHelmet => {
  const defaultTitle = fullBrandName();
  const titleTemplate = `%s - ${fullBrandName()}`;
  return <RNHelmet titleTemplate={titleTemplate} defaultTitle={defaultTitle} title={title} />;
};
