import { FormikTouched } from 'formik';

import { ISignupErrors } from 'utils/form/types';

import { prepareErrorKeys } from './prepare-error-keys';

export const generateDisplayedErrors = (
  errorObj: ISignupErrors,
  touchedObj: FormikTouched<any>
): string[] => {
  const displayedErrors = {};

  // filter out displayed form errors on the screen
  // to avoid showing from in the accessibility errors alert
  prepareErrorKeys(errorObj).forEach(errorKey => {
    if (touchedObj[errorKey]) {
      displayedErrors[errorKey] = errorObj[errorKey];
    }
  });

  return prepareErrorKeys(displayedErrors);
};
