import { IFeatureAccountFormQuery, useFeatureAccountFormQuery } from 'generated/sanity-graphql';

interface IUseFeatureAccountFormValue {
  featureAccountFormLoading: boolean;
  featureAccountForm: IFeatureAccountFormQuery['FeatureAccountForm'];
}

const FEATURE_ACCOUNT_FORM_SINGLETON_ID = "'feature-account-form-singleton'";

export const useFeatureAccountForm = (): IUseFeatureAccountFormValue => {
  const { data, loading } = useFeatureAccountFormQuery({
    variables: { featureAccountFormId: FEATURE_ACCOUNT_FORM_SINGLETON_ID },
  });

  const featureAccountFormLoading = loading;
  const featureAccountForm = data?.FeatureAccountForm ?? null;

  return {
    featureAccountForm,
    featureAccountFormLoading,
  };
};
