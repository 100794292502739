import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { StyledModal } from 'components/add-extras-modal/styled';
import { BackArrowContainer } from 'components/app-header/signup-mobile-header/signup-mobile-header.styled';
import { LayoutContainer } from 'components/layout/styled';
import { ModalSize } from 'components/modal';
import { CurrentSelectedMethodType } from 'components/payment-method/types';
import { useLoyaltyUpdateIdAndPayMutation } from 'generated/graphql-gateway';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import useErrorModal from 'hooks/use-error-modal';
import { AddGiftCardInput } from 'pages/cart/payment/add-gift-card-input';
import { useLocationContext } from 'state/location';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { usePaymentContext } from 'state/payment';
import { useUIContext } from 'state/ui';
import logger from 'utils/logger';
import noop from 'utils/noop';
import { relativeRoutes } from 'utils/routing';

import GiftCardImage from '../gift-card-image';
import { GiftCardModalContent, Header, SubmitButton } from '../styled';
import { useCardNumber } from '../use-giftcard-number';

const GIFT_CARD_NUMBER_TRANSLATION_ID = 'giftCardNumber';

export const AddGiftCardModal: React.FC<React.PropsWithChildren<{
  onCardAdded: (fdAccountId?: string | null) => void;
  handleSelect: (fdAccountId?: string | null) => void;
}>> = ({ onCardAdded = noop, handleSelect = noop }) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    defaultPaymentMethodId,
    getPrepaidPaymentMethod,
    mergePrepaidCardBalances,
  } = usePaymentContext();
  const { formatMessage } = useIntl();
  const { giftCardNumber, errors, isValidGiftCardNumber, handleCardNumberChange } = useCardNumber();
  const { goBack, navigate } = useNavigation();
  const { pathname } = useRoute();
  const { formatCurrencyForUser } = useUIContext();
  const { addPaymentCallbackUrl = '', setAddPaymentCallbackUrl } = useLocationContext();
  const { loyaltyUser } = useLoyaltyUser();
  const [loyaltyQuickPayMutation] = useLoyaltyUpdateIdAndPayMutation();

  const handleCloseModal = () => {
    handleSelect(defaultPaymentMethodId || CurrentSelectedMethodType.ADD_NEW_CARD);
    setAddPaymentCallbackUrl('');
    goBack();
  };

  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Vaulting Gift Card',
    onCancel: handleCloseModal,
  });

  const addNewCard = useCallback(
    async (event: any) => {
      event.preventDefault();

      setIsLoading(true);

      if (getPrepaidPaymentMethod()) {
        return openErrorDialog({ message: formatMessage({ id: 'giftCardExists' }) });
      }

      try {
        const { prepaidDetails, storedPaymentMethodId } = await mergePrepaidCardBalances({
          // Source pin is not required, BK crown cards don't have it
          sourceCardNumber: giftCardNumber,
        });
        loyaltyQuickPayMutation({
          variables: {
            idAndPayInput: { storedPaymentMethodId, enabled: true },
            loyaltyId: loyaltyUser?.id,
          },
        });
        onCardAdded(storedPaymentMethodId);

        const successRoute = pathname.replace(
          relativeRoutes.addGiftCard,
          relativeRoutes.successGiftCard
        );
        const formatCurrency = formatCurrencyForUser(prepaidDetails.balance);
        const navigationRoute = addPaymentCallbackUrl ? addPaymentCallbackUrl : successRoute;
        // Remove the source screen from stack and then navigate to success modal
        navigate(navigationRoute, {
          replace: true,
          state: {
            currentBalance: formatCurrency,
          },
        });
      } catch (error) {
        // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Error | ... Remove this comment to see the full error message
        openErrorDialog({ message: formatMessage({ id: 'giftCardAddingError' }), error });
        logger.error({ error, message: 'Error adding gift card' });
      } finally {
        setIsLoading(false);
      }
    },
    [
      getPrepaidPaymentMethod,
      mergePrepaidCardBalances,
      openErrorDialog,
      formatMessage,
      giftCardNumber,
      onCardAdded,
      pathname,
      formatCurrencyForUser,
      navigate,
    ]
  );

  const submitButtonText = `${formatMessage({ id: 'save' })} & ${formatMessage({
    id: 'continue',
  })}`;

  useEffect(() => {
    // clearing payment callback url on unmount
    return () => {
      setAddPaymentCallbackUrl('');
    };
  }, []);

  return (
    <LayoutContainer>
      <BackArrowContainer />
      <StyledModal
        mParticleEventData={{
          modalAppearanceEventMessage: 'Add gift card',
        }}
        size={ModalSize.FULLSCREEN}
        onDismiss={handleCloseModal}
      >
        <GiftCardModalContent testID="add-giftcard-form">
          <Header>{formatMessage({ id: 'addNewPaymentMethodGiftCard' })}</Header>
          <Box mb="$8">
            <GiftCardImage />
          </Box>
          <AddGiftCardInput
            onChange={handleCardNumberChange}
            errorMessage={errors?.giftCardNumber}
            label={formatMessage({ id: GIFT_CARD_NUMBER_TRANSLATION_ID })}
            testID="add-giftcard-input"
          />
          <SubmitButton
            testID="save-and-continue"
            isLoading={isLoading}
            disabled={!isValidGiftCardNumber}
            onPress={addNewCard}
          >
            {submitButtonText}
          </SubmitButton>
        </GiftCardModalContent>

        <ErrorDialog />
      </StyledModal>
    </LayoutContainer>
  );
};
