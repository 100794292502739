import { Svg, SvgPath, SvgProps, registerSVG } from '../../../components/svg';

export function Error(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <SvgPath
        fill="#502314"
        d="M2.725 21a.973.973 0 0 1-.85-.5 1.098 1.098 0 0 1-.138-.488.898.898 0 0 1 .138-.512l9.25-16c.1-.167.23-.292.387-.375.159-.083.321-.125.488-.125.167 0 .33.042.488.125a.983.983 0 0 1 .387.375l9.25 16c.1.167.146.337.137.512a1.099 1.099 0 0 1-.137.488.974.974 0 0 1-.85.5H2.725ZM12 18c.283 0 .52-.096.713-.288A.968.968 0 0 0 13 17a.968.968 0 0 0-.287-.712A.968.968 0 0 0 12 16a.967.967 0 0 0-.713.288A.968.968 0 0 0 11 17c0 .283.096.52.287.712.192.192.43.288.713.288Zm0-3c.283 0 .52-.096.713-.287A.968.968 0 0 0 13 14v-3a.968.968 0 0 0-.287-.713A.968.968 0 0 0 12 10a.967.967 0 0 0-.713.287A.968.968 0 0 0 11 11v3c0 .283.096.52.287.713.192.191.43.287.713.287Z"
      />
    </Svg>
  );
}

registerSVG('Error', Error);
