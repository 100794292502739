import { Svg, SvgPath, SvgProps, registerSVG } from '../../../components/svg';

export function Back(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <SvgPath d="M17 20.23L15.1954 22L5 12L15.1954 2L17 3.77L8.60918 12L17 20.23Z" />
    </Svg>
  );
}

registerSVG('Back', Back);
