import { useEffect } from 'react';

const usePreventBodyScroll = (disableBodyScroll: boolean | undefined = true) => {
  useEffect(() => {
    if (disableBodyScroll) {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    }

    return () => {
      if (disableBodyScroll) {
        document.documentElement.style.overflow = 'auto';
        document.body.style.overflow = 'auto';
      }
    };
  }, [disableBodyScroll]);
};

export default usePreventBodyScroll;
