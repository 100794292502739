import logger from 'utils/logger';
import noop from 'utils/noop';

import { BranchEventNames } from '../branch-event-names';

/**
 * Logs an event to Branch.
 * Note, the react-native implementation of this method is async, but the web version is synchronous.
 *
 * https://help.branch.io/developers-hub/docs/tracking-commerce-content-lifecycle-and-custom-events
 */
export const logBranchEvent = (
  eventName: typeof BranchEventNames[keyof typeof BranchEventNames],
  customAttributes?: Record<string, any>
) => {
  try {
    /** Web does not require a different payload for custom attributes like react native */
    window.branch?.logEvent(eventName, customAttributes);
    Promise.resolve(); // in react native, this is a promise.
  } catch (error) {
    logger.error({ error, eventName, message: `Error logging event to Branch` });
  }
};

/**
 * Sets the identity of a user in Branch. Do not use PII here.
 *
 * https://help.branch.io/developers-hub/docs/web-advanced-features#track-users
 */
export const setBranchIdentity = (userId: string) => {
  try {
    window?.branch?.setIdentity(userId);
  } catch (error) {
    logger.error({ error, message: `Error setting identity with Branch` });
  }
};

/**
 * Logs out of Branch - de-references the identity from setBranchIdentity.
 *
 * https://help.branch.io/developers-hub/docs/web-advanced-features#track-users
 */
export const logoutBranch = () => {
  try {
    window?.branch?.logout();
  } catch (error) {
    logger.error({ error, message: `Error logging out of Branch` });
  }
};

/**
 * Only relevant for iOS
 */
export const handleBranchATTAuthorizationStatus = (status: PermissionStatus) => noop;
