import React from 'react';

import { Text } from '@rbilabs/universal-components';

import Link from 'components/link';

export const TermsLink = Link.withConfig({
  isInline: true,
  isUnderlined: true,
});

export const StyledText = Text.withConfig({
  variant: 'body',
  marginX: '$2',
  _web: {
    display: 'inline-block',
    marginRight: 0,
  },
});

export const OptInLabel: React.FC<React.PropsWithChildren<{ testID?: string }>> = ({
  testID,
  children,
}) => (
  <StyledText focusable testID={testID}>
    {children}
  </StyledText>
);
