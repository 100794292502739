import React from 'react';

import { LayoutContainer } from 'components/layout/styled';
import Picture from 'components/picture';
import { useFeatureLayout } from 'hooks/use-feature-layout';

import {
  Bottom,
  Container,
  Heading,
  HeadingContainer,
  Image,
  ImageContainer,
  StyledHeroHeading,
  Tagline,
  Top,
} from './headerWidget.styled';
import { IHeaderWidget } from './types';

const Header = ({ taglineContent, headerImage, headingContent }: IHeaderWidget) => {
  const { featureLayout } = useFeatureLayout();
  const { locale: defaultHeroImage } = featureLayout?.defaultHeroImage || {};

  // @ts-expect-error TS(2339) FIXME: Property 'locale' does not exist on type 'ILocaleS... Remove this comment to see the full error message
  return taglineContent?.locale && headerImage ? (
    <LayoutContainer>
      <Container>
        <Top>
          <HeadingContainer>
            {/* @ts-expect-error TS(2339) FIXME: Property 'locale' does not exist on type 'ILocaleS... Remove this comment to see the full error message */}
            <Heading>{headingContent?.locale}</Heading>
            {/* @ts-expect-error TS(2339) FIXME: Property 'locale' does not exist on type 'ILocaleS... Remove this comment to see the full error message */}
            {taglineContent && <Tagline>{taglineContent?.locale}</Tagline>}
          </HeadingContainer>
        </Top>
        <Bottom>
          <ImageContainer>
            {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
            <Image image={headerImage?.locale} />
          </ImageContainer>
        </Bottom>
      </Container>
    </LayoutContainer>
  ) : (
    <Picture image={defaultHeroImage} alt="" sizedByChildren>
      {/* @ts-expect-error TS(2339) FIXME: Property 'locale' does not exist on type 'ILocaleS... Remove this comment to see the full error message */}
      <StyledHeroHeading accessibilityLevel={1}>{headingContent?.locale}</StyledHeroHeading>
    </Picture>
  );
};

export default Header;
