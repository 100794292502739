import React, { FC } from 'react';

import { Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { TextInput, TextInputMask } from 'components/ucl/text-input';
import { PaymentFieldVariations } from 'state/launchdarkly/variations';
import { CardType } from 'state/payment/types';
import { primitive } from 'styles/constants/primitives';
import { creditCardNumberMask, expiryMask } from 'utils/masks';
import { IPaymentErrors, IPaymentState } from 'utils/payment';

import {
  CardNumberWrapper,
  StyledCvvWrapper,
  StyledExpiryCVVWrapper,
  StyledExpiryWrapper,
} from './styled';

interface IFirstDataCreditCardFormInputs {
  onChange: (name: string, value: string) => void;
  errors: IPaymentErrors;
  paymentValues: IPaymentState;
  supportedCardTypes?: CardType[];
  paymentFieldVariations: PaymentFieldVariations;
  validateCreditCardForm?: () => boolean;
  didBlur: Record<string, boolean>;
  validateOnBlur: (fieldName: string) => void;
  isCheckoutRedesign?: boolean;
}

const CreditCardIcon: FC<React.PropsWithChildren<{ cardType: string | null }>> = ({ cardType }) =>
  (cardType &&
    {
      AMEX: <Icon variant="amex" />,
      DISCOVER: <Icon variant="discover" />,
      DINERS_CLUB: <Icon variant="diners" />,
      JCB: <Icon variant="jcb" />,
      MASTERCARD: <Icon variant="mastercard" />,
      VISA: <Icon variant="visa" />,
    }[cardType]) || <Icon variant="whiteEmptyCreditCard" size="sm" />;

const FirstDataCreditCardInputsRedesign: FC<React.PropsWithChildren<
  IFirstDataCreditCardFormInputs
>> = ({ errors, onChange, paymentValues, paymentFieldVariations, validateOnBlur, didBlur }) => {
  const { formatMessage } = useIntl();
  const endIcon = paymentValues.isCardNumberValid ? (
    <CreditCardIcon cardType={paymentValues.cardType} />
  ) : (
    <Icon variant="whiteEmptyCreditCard" size="sm" />
  );

  return (
    <>
      {paymentFieldVariations.cardNumber && (
        <CardNumberWrapper marginBottom="0">
          <TextInputMask
            aria-label={formatMessage({ id: 'creditCardNumber' })}
            onChangeText={value => onChange('cardNumber', value)}
            label={formatMessage({ id: 'creditCardNumber' })}
            value={paymentValues.cardNumber}
            required
            testID="cardNumber"
            placeholder="0000 0000 0000 0000"
            borderColor={primitive.$blackOpacity30}
            placeholderTextColor={
              !didBlur.cardNumber && !errors.didAttemptSubmit
                ? primitive.$blackOpacity50
                : primitive.$error
            }
            onBlur={() => validateOnBlur('cardNumber')}
            errorMessage={
              !didBlur.cardNumber && !errors.didAttemptSubmit
                ? ''
                : errors.cardType || errors.cardNumber
            }
            autoComplete="cc-number"
            keyboardType="numeric"
            // @ts-expect-error TS(2322) FIXME: Type '(cardNumber: string) => any[]' is not assign... Remove this comment to see the full error message
            mask={creditCardNumberMask}
            autoFocus
            endIcon={endIcon}
          />
        </CardNumberWrapper>
      )}
      <StyledExpiryCVVWrapper marginBottom="0">
        {paymentFieldVariations.expiration && (
          <StyledExpiryWrapper marginBottom="0">
            <TextInputMask
              borderColor={primitive.$blackOpacity30}
              autoComplete="cc-exp"
              onBlur={() => validateOnBlur('expiry')}
              errorMessage={!didBlur.expiry && !errors.didAttemptSubmit ? '' : errors.expiry}
              placeholder="MM / YY"
              placeholderTextColor={
                !didBlur.expiry && !errors.didAttemptSubmit
                  ? primitive.$blackOpacity30
                  : primitive.$error
              }
              keyboardType="numeric"
              label="Expiration Date"
              mask={expiryMask}
              onChangeText={(value: string) => {
                onChange('expiry', value);
              }}
              required
              testID="expiry"
              value={paymentValues.expiry || ''}
            />
          </StyledExpiryWrapper>
        )}
        {paymentFieldVariations.cvv && (
          <StyledCvvWrapper marginBottom="0">
            <TextInput
              borderColor={primitive.$blackOpacity30}
              aria-label={formatMessage({ id: 'cvv' })}
              onChangeText={value => onChange('cvv', value)}
              label={formatMessage({ id: 'cvv' })}
              value={paymentValues.cvv || ''}
              required
              testID="cvv"
              placeholder="123"
              onBlur={() => validateOnBlur('cvv')}
              placeholderTextColor={
                !didBlur.cvv && !errors.didAttemptSubmit
                  ? primitive.$blackOpacity30
                  : primitive.$error
              }
              errorMessage={!didBlur.cvv && !errors.didAttemptSubmit ? '' : errors.cvv}
              autoComplete="cc-csc"
              keyboardType="numeric"
              // TODO - RN input should support pattern
              // pattern="[0-9]*"
              maxLength={4}
              type="password"
            />
          </StyledCvvWrapper>
        )}
      </StyledExpiryCVVWrapper>
    </>
  );
};

export default FirstDataCreditCardInputsRedesign;
