import * as React from 'react';

import Svg, { Path } from 'react-native-svg';

const CircularWarningIcon = () => (
  <Svg width={40} height={40} viewBox="0 0 24 24" fill="none">
    <Path
      d="M12 2C6.48003 2 2.00003 6.48 2.00003 12C2.00003 17.52 6.48003 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z"
      fill="#502314"
    />
  </Svg>
);

export { CircularWarningIcon };
