import { useCallback } from 'react';

import { ItemAvailabilityStatus } from 'enums/menu';
import { useEnableCartV2Experiment } from 'experiments/use-enable-cart-v2-experiment';
import useFeatureMenuAddOn from 'hooks/use-feature-menu-add-on';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMapToFrontendCartEntry } from 'state/order/hooks/use-map-to-frontend-cart-entry';
import { routes } from 'utils/routing';

/**
 *
 * Returns a function to reorder a previous order.
 */
export const useHandleReorder = ({
  addToCart,
  navigate,
  linkTo,
  setPendingReorder,
  setReordering,
  storeHasSelection,
  // TODO: BKPE-1956 - unavailableCartEntries
  setUnavailableCartEntries,
  setReorderedOrderId,
  cartEntries,
}) => {
  const mapBackendToFrontend = useMapToFrontendCartEntry();
  const enableAddExtras = useFlag(LaunchDarklyFlag.ENABLE_ADD_EXTRAS);
  const { sections: addOnSections } = useFeatureMenuAddOn();
  const hasAddons = addOnSections.length > 0;
  const enableCartV2 = useEnableCartV2Experiment();

  return useCallback(
    async (order, url = routes.orders) => {
      if (!order || !storeHasSelection) {
        // if there's no order or no order or store selection, navigate to where was initially intended
        return navigate(url);
      }
      setReordering(true);
      setReorderedOrderId(order.rbiOrderId);
      const { reorderCartEntries } = order.cart;

      const unavailableEntries = [];
      let containsExtras = false;

      const processEntry = async entry => {
        // Map backend entry to frontend
        const { cartEntry: frontendEntry, status } = await mapBackendToFrontend(
          enableCartV2 ? { ...entry, quantity: 1 } : entry,
          cartEntries
        );

        // If this is an extra we want to avoid adding it to the cart or unavailable items.
        if (status === ItemAvailabilityStatus.CART_EXTRA) {
          containsExtras = true;
          return;
        }

        // Check if the item is available
        if (status === ItemAvailabilityStatus.AVAILABLE) {
          addToCart(frontendEntry);
        } else {
          unavailableEntries.push(frontendEntry);
        }
      };

      for (const entry of reorderCartEntries) {
        if (enableCartV2) {
          // Cart v2 process each item in the order individually.
          // ie: If the order has 3 items of the same item, we want to add 3 items to the cart and not 1 with quantity 3
          for (let i = 0; i < entry.quantity; i++) {
            await processEntry(entry);
          }
        } else {
          await processEntry(entry);
        }
      }

      setReordering(false);
      setReorderedOrderId(null);
      setPendingReorder(null);

      const hasUnavailableEntries = unavailableEntries.length > 0;

      // this is needed to prioritize the unavailable item modal
      if (hasUnavailableEntries) {
        setUnavailableCartEntries(unavailableEntries);
        navigate(url);
      } else if (enableAddExtras && hasAddons && containsExtras) {
        // If the order had extras go to the extras modal in the cart
        linkTo(routes.cartAddExtras);
      } else {
        navigate(routes.cart);
      }
    },
    [
      addToCart,
      cartEntries,
      enableAddExtras,
      hasAddons,
      linkTo,
      mapBackendToFrontend,
      navigate,
      setPendingReorder,
      setReorderedOrderId,
      setReordering,
      setUnavailableCartEntries,
      storeHasSelection,
      enableCartV2,
    ]
  );
};
