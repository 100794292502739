import { useEffect, useMemo, useRef, useState } from 'react';

import { NetInfoState } from '@react-native-community/netinfo';

import { useConfigValue } from 'hooks/configs/use-config-value';
import { QUERY_TYPE } from 'remote/constants';
import {
  getSanityGraphqlQueryUrl,
  getSanityGraphqlQueryUrlV2,
  getSanityGroqQueryUrl,
} from 'remote/index';
import { useLocale } from 'state/intl';
import { sanityDataset as defaultSanityDataset } from 'utils/environment';
import { FetchConfig } from 'utils/network';
import { useMemoAll } from 'utils/use-memo-all';

import {
  UI_RESPONSE_TIME_TO_REACHABILITY_FAILED,
  addNetworkStatusListener,
  staticConnectionStatus,
} from './status';
import { appStateInfo } from './util';

export interface QueryConfig<TResult, TVars> extends Omit<FetchConfig<TResult, TVars>, 'language'> {
  queryType: QUERY_TYPE;
}

export type Query = <TResult, TVars>(o: QueryConfig<TResult, TVars>) => Promise<TResult>;

export default function useNetwork() {
  const { region } = useLocale();
  const sanityProjectId = useConfigValue({ key: 'sanityProjectId', isRegionalized: false });

  const [isInternetReachable, setIsInternetReachable] = useState(true);
  const [isNetworkConnected, setIsNetworkConnected] = useState(true);

  const notifyTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    function statusListener(info: NetInfoState) {
      if (info.isInternetReachable) {
        setIsInternetReachable(true);
      }

      if (info.isConnected) {
        setIsNetworkConnected(true);
      }

      if (info.isInternetReachable && notifyTimeoutRef.current) {
        clearTimeout(notifyTimeoutRef.current);
        notifyTimeoutRef.current = null;
      }

      // delay letting UI know about a failed health check giving it a chance to recover
      // to reduce potential spam of messages / alerts
      if (!info.isInternetReachable && !notifyTimeoutRef.current) {
        notifyTimeoutRef.current = setTimeout(() => {
          if (!appStateInfo.wasRecentlyBackgrounded) {
            setIsInternetReachable(false);
          }
          notifyTimeoutRef.current = null;
        }, UI_RESPONSE_TIME_TO_REACHABILITY_FAILED);
      }

      // we have flake around app / site backgrounded and this messaging
      // this errors on the side of NOT displaying a no network message in a recently backgrounded situation.
      // realistically if there is no network isInternetReachable check will fail quickly if this doesn't fire
      if (!info.isConnected && !appStateInfo.wasRecentlyBackgrounded) {
        setIsNetworkConnected(false);
      }
    }

    statusListener(staticConnectionStatus.current);

    const cleanUp = addNetworkStatusListener(statusListener);

    return () => {
      notifyTimeoutRef.current && clearTimeout(notifyTimeoutRef.current);
      cleanUp();
    };
  }, []);

  const sanityEndpoints = useMemo(() => {
    // use CDN by default
    const useCdn = true;

    // TODO: RN - locale switching -- shouldn't this just pull from config?
    const sanityDataset = region
      ? `${defaultSanityDataset()}_${region.toLowerCase()}`
      : defaultSanityDataset().toLowerCase();

    const groq = getSanityGroqQueryUrl({
      sanityDataset,
      useCdn,
      sanityProjectId,
    });
    const graphql = getSanityGraphqlQueryUrl({
      sanityDataset,
      useCdn,
      sanityProjectId,
    });
    const graphqlV2 = getSanityGraphqlQueryUrlV2({
      sanityDataset,
      useCdn,
      sanityProjectId,
    });

    return {
      graphql,
      groq,
      graphqlV2,
    };
  }, [region, sanityProjectId]);

  const value = useMemoAll({
    sanityEndpoints,
    isInternetReachable,
    isNetworkConnected,
  });

  return value;
}
