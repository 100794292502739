export default {
  expandedAccordionActiveAccentColor: Styles.color.primary,
  expandedAccordionBackground: undefined,
  expandedAccordionFontWeight: 'normal',
  accordionHoverBackground: undefined,
  rowInversionBackgroundColor: Styles.color.primary,
  rowInversionTextColor: Styles.color.white,
  backgroundColor: Styles.color.white,
  imageWidgetCaptionAlign: 'left',
};
