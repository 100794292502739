import { SORTED_PROD_SUPPORTED_LOCALES } from 'state/intl/constants';
import { LANGUAGES } from 'state/intl/types';
import { Region } from 'utils/environment/types';
import { loadLanguage } from 'utils/intl/language';
import { inferLanguageFromUrlParams } from 'utils/intl/language/infer-language-from-url-params';
import { loadRegion } from 'utils/intl/region';
import { inferRegionFromUrlParams } from 'utils/intl/region/infer-region-from-url-params';
import LocalStorage, { StorageKeys } from 'utils/local-storage';

import { getLanguageAndRegionFromLocaleString } from './get-language-and-region-from-locale-string';

export { getLanguageAndRegionFromLocaleString };

export function inferSupportedLocale(newLanguage: LANGUAGES, newRegion: Region): string {
  const lowerCaseRegion = (newRegion || '').toLowerCase();
  const regionSupportedLocales = SORTED_PROD_SUPPORTED_LOCALES.filter(locale =>
    locale.toLowerCase().includes(lowerCaseRegion)
  );

  // Find the first item on the list with the langauge that we care about
  // fallback to the default if that language isn't supported
  return (
    regionSupportedLocales.find(sortedLocale => sortedLocale.startsWith(`${newLanguage}`)) ||
    regionSupportedLocales[0]
  );
}

export function hydrateInitialLocale(): string {
  const newLocale = inferSupportedLocale(loadLanguage(), loadRegion());

  const [newLocaleLanguage, newLocaleRegion] = getLanguageAndRegionFromLocaleString(newLocale);
  const currentLanguage = LocalStorage.getItem(StorageKeys.LANGUAGE);
  const currentRegion = LocalStorage.getItem(StorageKeys.REGION);

  if (currentLanguage !== newLocaleLanguage || inferLanguageFromUrlParams()) {
    LocalStorage.setItem(StorageKeys.LANGUAGE, newLocaleLanguage);
  }

  if (currentRegion !== newLocaleRegion || inferRegionFromUrlParams()) {
    LocalStorage.setItem(StorageKeys.REGION, newLocaleRegion);
  }

  return newLocale;
}
