import { useCallback } from 'react';

import {
  FilterRestaurantType,
  IRestaurantsInput,
  ServiceMode,
  useGetNearbyRestaurantsLazyQuery,
  useGetRestaurantsLazyQuery,
} from 'generated/graphql-gateway';
import { withLazySimpleCache } from 'state/graphql/with-simple-cache';

interface IFetchRestaurantsOptions {
  allowFromCache: boolean;
}

const useCachedGetRestaurantsLazyQuery = withLazySimpleCache(useGetRestaurantsLazyQuery);

const useCachedGetNearbyRestaurantsLazyQuery = withLazySimpleCache(
  useGetNearbyRestaurantsLazyQuery
);

export const useRestaurants = (filter: FilterRestaurantType) => {
  const [fetchRestaurants, { data, loading, error }] = useCachedGetRestaurantsLazyQuery();

  const fetch = useCallback(
    (
      input: IRestaurantsInput = {},
      options: IFetchRestaurantsOptions = { allowFromCache: true }
    ) => {
      fetchRestaurants({
        allowFromCache: options.allowFromCache,
        variables: { input: { filter, ...input } },
      });
    },
    [fetchRestaurants, filter]
  );

  return { fetch, data, loading, error };
};

export const useNearbyRestaurants = () => {
  const [
    fetchNearbyRestaurants,
    { data, loading, error },
  ] = useCachedGetNearbyRestaurantsLazyQuery();

  const fetch = useCallback(
    (input: IRestaurantsInput = {}, options: IFetchRestaurantsOptions = { allowFromCache: true }) =>
      fetchNearbyRestaurants({
        allowFromCache: options.allowFromCache,
        variables: {
          input: {
            pagination: {
              after: input.after,
              before: input.before,
              first: input.first,
              last: input.last,
            },
            radiusStrictMode: !!input.radiusStrictMode,
            serviceModes: input?.serviceModes?.map(x => x as ServiceMode) ?? undefined,
            coordinates: {
              searchRadius: input?.coordinates?.searchRadius ?? undefined,
              userLat: input.coordinates?.userLat,
              userLng: input.coordinates?.userLng,
            },
          },
        },
      }).then(x => x.data?.restaurantsV2?.nearby?.nodes),
    [fetchNearbyRestaurants]
  );

  return {
    fetch,
    stores: data?.restaurantsV2?.nearby?.nodes,
    loading,
    error,
  };
};
