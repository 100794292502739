import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';

import { Box } from '@rbilabs/universal-components';
import { Keyboard, useWindowDimensions } from 'react-native';

import { APP_MINIMUM_HEIGHT } from 'utils/constants';

export const Wrapper: FC<PropsWithChildren> = ({ children }) => {
  const { height: windowHeight } = useWindowDimensions();
  const [wrapperHeight, setWrapperHeight] = useState(Math.max(windowHeight, APP_MINIMUM_HEIGHT));
  const inputFocusedRef = useRef(false);

  /**
   * At the moment, there is not an stable API to detect keyboard visibility on web app using mobile devices.
   * Detecting if the keyboard is open on a mobile device using JavaScript can be a bit tricky
   * Keyboard visibility detection is possible by listening to focus and blur events on the document.
   * TODO: try to use the experimental VirtualKeyboard_API once it's ready to use to make this code clear and standarized
   * https://developer.mozilla.org/en-US/docs/Web/API/VirtualKeyboard_API
   */
  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
      inputFocusedRef.current = true;
    });

    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
      inputFocusedRef.current = false;
    });

    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, []);

  useEffect(() => {
    // If the input is focused, do not update the height to prevent reported issues with the keyboard on mobile devices
    // https://rbictg.atlassian.net/browse/BKPE-3622
    if (!inputFocusedRef.current) {
      setWrapperHeight(Math.max(windowHeight, APP_MINIMUM_HEIGHT));
    }
  }, [windowHeight]);

  return (
    <Box backgroundColor={Styles.color.background} height={wrapperHeight}>
      {children}
    </Box>
  );
};
