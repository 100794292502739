import { FC } from 'react';

import { portalToReplaceHeader } from 'state/mobile-header-nav';

import { LocatorHeaderBase } from './header.base';
import { ILocatorHeaderProps } from './types';

export const LocatorHeader: FC<ILocatorHeaderProps> = portalToReplaceHeader(props => {
  return <LocatorHeaderBase {...props} />;
});
