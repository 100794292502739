import React, { useCallback } from 'react';

import { VStack } from '@rbilabs/universal-components';

import { ISanityVendorConfigs, PickerSelection } from '@rbi-ctg/menu';
import { computeProductCalories } from 'hooks/menu/utils';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useMenuContext } from 'state/menu';
import { routes } from 'utils/routing';

import { useQuestContext } from '../../loyalty-quest';
import { canActivateQuest, isStepCompleted } from '../../loyalty-quest/utils';

import { CompletedStepIcon, PressableItem, UncompletedStepIcon } from './styled';
import { IQuestStepsProps } from './types';

export const QuestSteps = ({
  cmsQuestRequirementById,
  cmsQuestDetail,
  cmsQuestDetailUI,
  onCloseDrawer,
}: IQuestStepsProps) => {
  const { quest } = useQuestContext();
  const { pricingFunction } = useMenuContext();
  const { linkTo } = useNavigation();
  const onPress = useCallback(
    (link?: string, type?: string, id?: string) => () => {
      const path = type && id ? `${routes.menu}/${type}-${id}` : routes.menu;
      onCloseDrawer();

      if (link) {
        linkTo(link);
      } else {
        linkTo(path);
      }
    },
    [linkTo, onCloseDrawer]
  );

  const cmsSteps = cmsQuestDetail?.questRules?.steps || [];

  const { steps, status: questStatus } = quest;

  if (!quest || !steps) {
    return null;
  }

  const isQuestNotEnrolled = canActivateQuest(questStatus);

  return (
    <VStack space="$4">
      {steps.map((step, stepIdx) => {
        const cmsStep = cmsSteps[stepIdx];

        const { cartRequirements, status } = step;
        const isCompletedStep = isStepCompleted(status);
        const isDisabled = isQuestNotEnrolled || isCompletedStep;
        const hasSingleCartRequirement =
          cartRequirements && cartRequirements.length === 1 && cartRequirements[0].length === 1;
        let IconCmp;

        if (isCompletedStep) {
          IconCmp = CompletedStepIcon;
        } else if (!isQuestNotEnrolled) {
          IconCmp = UncompletedStepIcon;
        }
        let itemName,
          itemImage,
          itemLink = null;

        if (cmsStep?.title && cmsStep?.image && cmsStep?.link) {
          itemName = cmsStep?.title?.localeRaw;
          itemImage = cmsStep?.image?.locale;
          itemLink = cmsStep?.link?.localeRaw;
        }

        if (hasSingleCartRequirement) {
          const { id: requirementId } = cartRequirements[0][0];
          const cmsRequirement = cmsQuestRequirementById[requirementId];

          return (
            <PressableItem
              key={step.id}
              onPress={onPress(itemLink || '', cmsRequirement._type, cmsRequirement._id)}
              image={itemImage ? itemImage : cmsRequirement.image}
              altImage={cmsRequirement.imageDescription?.locale ?? ''}
              name={itemName ? itemName : cmsRequirement?.name?.locale ?? ''}
              price={
                pricingFunction({
                  item: {
                    _type: cmsRequirement._type,
                    vendorConfigs: (cmsRequirement.vendorConfigs as ISanityVendorConfigs) || null,
                  },
                }) || undefined
              }
              // using `as` statement because the function uses internal types
              calories={
                itemName ? undefined : computeProductCalories(cmsRequirement as PickerSelection)
              }
              icon={IconCmp && <IconCmp size="5" />}
              disabled={isDisabled}
            />
          );
        }

        return (
          <PressableItem
            key={step.id}
            onPress={onPress(itemLink || '')}
            image={itemImage ? itemImage : cmsQuestDetailUI.genericStepImage?.locale}
            name={itemName ? itemName : cmsQuestDetailUI.genericStepText?.locale ?? ''}
            icon={IconCmp && <IconCmp size="5" />}
            disabled={isDisabled}
          />
        );
      })}
    </VStack>
  );
};
