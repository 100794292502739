import * as React from 'react';

import { primitive } from 'styles/constants/primitives';

import {
  Svg,
  SvgClipPath,
  SvgDefs,
  SvgG,
  SvgPath,
  SvgProps,
  SvgRect,
  registerSVG,
} from '../../../components/svg';
export function DuplicateItem(props: SvgProps) {
  return (
    <Svg {...props} width={30} height={30} viewBox="0 0 30 30" fill="none">
      <SvgG clipPath="url(#clip0_12982_589)">
        <SvgPath
          d="M13 11L11 11L11 14L8 14L8 16L11 16L11 19L13 19L13 16L16 16L16 14L13 14L13 11ZM21 6.5C22.8167 7.13333 24.2708 8.22917 25.3625 9.7875C26.4542 11.3458 27 13.0833 27 15C27 16.9167 26.4542 18.6542 25.3625 20.2125C24.2708 21.7708 22.8167 22.8667 21 23.5L21 21.3C22.2333 20.7167 23.2083 19.8583 23.925 18.725C24.6417 17.5917 25 16.35 25 15C25 13.65 24.6417 12.4083 23.925 11.275C23.2083 10.1417 22.2333 9.28333 21 8.7L21 6.5ZM12 6C13.25 6 14.4208 6.2375 15.5125 6.7125C16.6042 7.1875 17.5542 7.82917 18.3625 8.6375C19.1708 9.44583 19.8125 10.3958 20.2875 11.4875C20.7625 12.5792 21 13.75 21 15C21 16.25 20.7625 17.4208 20.2875 18.5125C19.8125 19.6042 19.1708 20.5542 18.3625 21.3625C17.5542 22.1708 16.6042 22.8125 15.5125 23.2875C14.4208 23.7625 13.25 24 12 24C10.75 24 9.57917 23.7625 8.4875 23.2875C7.39583 22.8125 6.44583 22.1708 5.6375 21.3625C4.82917 20.5542 4.1875 19.6042 3.7125 18.5125C3.2375 17.4208 3 16.25 3 15C3 13.75 3.2375 12.5792 3.7125 11.4875C4.1875 10.3958 4.82917 9.44583 5.6375 8.6375C6.44583 7.82916 7.39583 7.1875 8.4875 6.7125C9.57917 6.2375 10.75 6 12 6ZM12 8C10.05 8 8.39584 8.67917 7.0375 10.0375C5.67917 11.3958 5 13.05 5 15C5 16.95 5.67917 18.6042 7.0375 19.9625C8.39583 21.3208 10.05 22 12 22C13.95 22 15.6042 21.3208 16.9625 19.9625C18.3208 18.6042 19 16.95 19 15C19 13.05 18.3208 11.3958 16.9625 10.0375C15.6042 8.67917 13.95 8 12 8Z"
          fill={props.fill ?? primitive.bk.$bbqBrown}
        />
      </SvgG>
      <SvgDefs>
        <SvgClipPath id="clip0_12982_589">
          <SvgRect width={24} height={24} fill="white" transform="translate(3 3)" />
        </SvgClipPath>
      </SvgDefs>
    </Svg>
  );
}

registerSVG('DuplicateItem', DuplicateItem);
