import { regionSpecificLocalStorageKeys } from 'state/intl/constants';
import LocalStorage, { StorageKeys } from 'utils/local-storage';

export const clearRegionSpecificStorage = () => {
  const nonRegionSpecificStorageKeys = Object.values(StorageKeys).filter(
    key => !regionSpecificLocalStorageKeys.includes(key as StorageKeys)
  ) as StorageKeys[];
  // Wipe out region specific keys
  LocalStorage.clear({ excludeKeys: nonRegionSpecificStorageKeys });
};
