export function getNavigatorLanguage(): string {
  return (
    (window.navigator &&
      ((window.navigator.languages && window.navigator.languages[0]) ||
        window.navigator.language ||
        // @ts-expect-error TS(2339) FIXME: Property 'userLanguage' does not exist on type 'Na... Remove this comment to see the full error message
        window.navigator.userLanguage)) ||
    ''
  );
}
