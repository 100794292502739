import { useMemo } from 'react';

import {
  useLoyaltyPersonalizeOffersQuery,
  usePlaceholderFieldsQuery,
} from 'generated/sanity-graphql';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import type { ICmsOffer, IEngineOffer } from '../types';

import type { PersonalizedEngineOffer } from './types';
import { useApplyOfferPlaceholders } from './use-apply-offer-placeholders';
import { createOfferPlaceHolderFieldsMap, isPersonalizedEngineOffer } from './utils';

/**
 * Fetch personalize data and create the personalized offer objects applied the templates.
 */
export const useLoyaltyPersonalizedOffer = ({
  engineOffer,
}: {
  engineOffer: IEngineOffer | null;
}) => {
  const { applyOfferPlaceholders } = useApplyOfferPlaceholders();

  const personalizedOffersTemplateEnabled = Boolean(
    useFlag(LaunchDarklyFlag.ENABLE_PERSONALIZED_OFFERS_TEMPLATE)
  );

  const personalizedEngineOffer: PersonalizedEngineOffer | null = useMemo(
    () =>
      personalizedOffersTemplateEnabled && engineOffer && isPersonalizedEngineOffer(engineOffer)
        ? engineOffer
        : null,
    [engineOffer, personalizedOffersTemplateEnabled]
  );

  // get all engine config and template ids
  const { configId, templateId } = personalizedEngineOffer || {};

  const { data, loading } = useLoyaltyPersonalizeOffersQuery({
    variables: {
      configEngineIds: [configId!],
      templateEngineIds: templateId ? [templateId] : [],
    },
    skip: !configId,
  });

  const {
    data: placeholderFieldsData,
    loading: loadingPlaceholderFields,
  } = usePlaceholderFieldsQuery({
    skip: !configId,
  });

  const configOffer = data?.allConfigOffers?.[0];
  const offerTemplate = data?.allOfferTemplates?.[0];
  const placeholderFields = placeholderFieldsData?.allPlaceholderFields;

  const personalizedOffer: ICmsOffer | null = useMemo(() => {
    // if the are not config offers means the user doesn't have personalized offers or the query is still on flight
    if (!configOffer || !engineOffer) {
      return null;
    }
    const placeholderFieldsMap = createOfferPlaceHolderFieldsMap(placeholderFields || []);

    return applyOfferPlaceholders({
      configOffer,
      engineOffer,
      offerTemplate: offerTemplate ?? undefined,
      placeholderFieldsMap,
    });
  }, [applyOfferPlaceholders, configOffer, engineOffer, offerTemplate, placeholderFields]);

  return { personalizedOffer, loading: loading || loadingPlaceholderFields };
};
