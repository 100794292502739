import { EventName, addOnceEventListener } from 'utils/event-hub';

import { startTrace, stopTrace } from '../trace';
import { TraceName } from '../types';

// Captures a trace from JavascriptStart to the time it takes until the first Hero is visible
// Note: this end to end trace will *include* any appflow downloads
// Adds a custom attribute based on whether appflow was initiated or not during the course of this trace
export const initializeFirstHeroVisibleMonitor = () => {
  const traceName = TraceName.JAVASCRIPT_START_TO_FIRST_MAIN_HERO_IMAGE_VISIBLE;

  addOnceEventListener(EventName.JAVASCRIPT_START, () => {
    startTrace(traceName);
  });

  addOnceEventListener(EventName.MAIN_HERO_IMAGE_LOADED, async () => {
    stopTrace(traceName);
  });
};
