import { useRef } from 'react';

import { Animated, Easing } from 'react-native';

import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { isNative } from 'utils/environment';

export default function useFadeInAnimation({ isVisible }: { isVisible: boolean }) {
  const fadeAnimation = useRef(new Animated.Value(isVisible ? 1 : 0));

  useEffectOnUpdates(() => {
    const fadeInValues = {
      from: 0,
      to: 1,
    };
    const fadeOutValues = {
      from: 1,
      to: 0,
    };
    const { from, to } = isVisible ? fadeInValues : fadeOutValues;
    fadeAnimation.current.setValue(from);

    Animated.timing(fadeAnimation.current, {
      toValue: to,
      useNativeDriver: isNative,
      easing: Easing.linear,
    }).start();
  }, [isVisible]);

  return fadeAnimation.current;
}
