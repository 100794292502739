import { useCallback, useRef } from 'react';

import { ApolloError } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { useIntl } from 'react-intl';

import {
  IPrepaidsReloadInput,
  PrepaidsReloadDocument,
  UserAccountsDocument as UserAccountsQuery,
  usePrepaidsReloadMutation,
} from 'generated/graphql-gateway';
import { ModalCb } from 'hooks/use-error-modal';
import { useLocale } from 'state/intl';
import {
  GraphQLErrorCodes,
  IErrorDialog,
  getAppleOrGooglePayError,
  getErrorDialogForGraphQLCode,
  parseGraphQLErrorCodes,
} from 'utils/errors';
import logger from 'utils/logger';

const MutationName: string = getOperationName(PrepaidsReloadDocument)!;

export type IPrepaidsReload = IPrepaidsReloadInput & { prepaidBalance: number };

interface IUsePrepaidsReloadProps {
  openErrorDialog: ModalCb;
}

export const usePrepaidsReload = ({ openErrorDialog }: IUsePrepaidsReloadProps) => {
  const lastFdAccountId = useRef<string | null | undefined>(null);
  const lastPrepaidFdAccountId = useRef<string | null>(null);
  const lastPrepaidBalance = useRef<number | null>(null);
  const lastFundValue = useRef<number | null>(null);
  const { feCountryCode } = useLocale();
  const { formatMessage } = useIntl();

  const onError = useCallback(
    (error: ApolloError) => {
      let dialog: IErrorDialog = { message: formatMessage({ id: 'reloadCrownCardCardError' }) };
      if (lastPrepaidBalance.current! + lastFundValue.current! > 10000) {
        dialog.message = formatMessage({ id: 'exceedsBalanceLimit' });
      }
      const [errorCode] = parseGraphQLErrorCodes(error);
      if (errorCode) {
        const maybeMoreAuthoritativeDialog = getErrorDialogForGraphQLCode(errorCode, formatMessage);
        if (maybeMoreAuthoritativeDialog) {
          dialog = maybeMoreAuthoritativeDialog;
        }
        const applePayOrGooglePayError = getAppleOrGooglePayError(lastFdAccountId.current || '');

        const isPaymentError =
          errorCode.errorCode === GraphQLErrorCodes.PAYMENTS_DECLINED_ERROR ||
          errorCode.errorCode === GraphQLErrorCodes.PAYMENTS_ERROR;

        if (isPaymentError && applePayOrGooglePayError) {
          dialog.message = formatMessage({ id: applePayOrGooglePayError });
        }
      }

      openErrorDialog({
        ...dialog,
        modalAppearanceEventMessage: 'Error: Reload Prepaid Card Failure',
        error,
      });
      const { graphQLErrors: errors } = error;
      if (errors.length) {
        logger.error({
          fdAccountId: lastFdAccountId.current,
          prepaidFdAccountId: lastPrepaidFdAccountId.current,
          errors,
          message: 'error reloading prepaid card',
          MutationName,
        });
      }
    },
    [formatMessage, openErrorDialog]
  );

  const [fireMutation, { loading }] = usePrepaidsReloadMutation({
    awaitRefetchQueries: true,
    onError,
    refetchQueries: [{ query: UserAccountsQuery, variables: { feCountryCode } }],
  });

  const reloadPrepaidsCardMutation = useCallback(
    async ({
      countryCode,
      fdAccountId,
      fundValue,
      prepaidFdAccountId,
      applePayDetails,
      googlePayDetails,
      prepaidBalance,
    }: any) => {
      lastFdAccountId.current = fdAccountId;
      lastPrepaidFdAccountId.current = prepaidFdAccountId;
      lastPrepaidBalance.current = +prepaidBalance;
      lastFundValue.current = +fundValue;

      return fireMutation({
        variables: {
          input: {
            countryCode,
            fdAccountId,
            fundValue,
            prepaidFdAccountId,
            applePayDetails,
            googlePayDetails,
          },
        },
      });
    },
    [fireMutation]
  );

  return {
    reloadPrepaidsCardMutation,
    loading,
  };
};
