/**
 * mParticle Provider RN Implementation
 * We will uncomment logic as we continue to implement various portions and swap in RN functionality.
 */

//
// TODO refactor this to NOT be a provider and be static and other cleanup
//
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { DestinationPlugin, PluginType, ReactNativeOptions } from '@amplitude/analytics-types';
import { Platform } from 'react-native';

import { useApiKey } from 'hooks/configs/use-api-key';
import { setBrazeUserAttributes } from 'state/braze/set-braze-user-attribute';
import { Header, setHeaders } from 'state/graphql/headers';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { AllowedEvent } from 'state/mParticle/events/allowed-events';
import { normalizeBooleans, sanitizeValues } from 'state/mParticle/utils';
import { appVersion } from 'utils/app-version-info';
import { getName } from 'utils/attributes/index';
import AuthStorage from 'utils/cognito/storage';
import crashlytics from 'utils/crashlytics';
import { addContext as addLoggerContext } from 'utils/datadog';
import { getDeviceId } from 'utils/device-id';
import { brand, env, isNativeIOS } from 'utils/environment';
import { getNativeLocationPermissions } from 'utils/geolocation';
import { initGTM, sendGoogleTagManagerEvent } from 'utils/google-tag-manager';
import { StorageKeys } from 'utils/local-storage';
import logger from 'utils/logger';
import noop from 'utils/noop';
import { TraceName, startTrace, stopTrace } from 'utils/performance';

import {
  Revenue,
  add,
  getSessionId,
  init,
  revenue,
  setDeviceId as setAmplitudeDeviceId,
  setSessionId as setAmplitudeSessionId,
  setOptOut,
  setUserId,
  track,
} from './amplitude-package';
import { IAmplitudeProductDetails, ValidPropertyType } from './types';
import { extractAmplitudeUserAttributes, getAge, setAmplitudeUserAttributes } from './utils';

export const APPFLOW_DISPLAYED_BLOCKING_UI = 'Displayed Update UI';

const staticSessionId = { current: 'UN-INITIALIZED' };

interface ILogAmplitudeCustomEventProps {
  name: AllowedEvent['name'];
  attributes: Record<string, string | IAmplitudeProductDetails>;
}
interface IAmplitudeContext {
  logAmplitudeRevenueEvent: (props: {
    totalAmount: number;
    eventProperties: Record<string, string | number | boolean>;
  }) => void;
  initialize: () => void;
  updateUserLocationPermissionStatus: () => Promise<void>;
  updateAmplitudeUserId: (userId?: string | null) => void;
  logAmplitudeCustomEvent: (event: ILogAmplitudeCustomEventProps) => void;
  sessionId: string;
  updateAmplitudeUserAttributes: (userAttributes: Record<string, any>) => void;
}

const AmplitudeContext = React.createContext<IAmplitudeContext>({
  initialize: noop,
  updateUserLocationPermissionStatus: () => Promise.resolve(),
  logAmplitudeCustomEvent: () => noop,
  logAmplitudeRevenueEvent: () => noop,
  updateAmplitudeUserId: () => noop,
  sessionId: 'fake-id',
  updateAmplitudeUserAttributes: noop,
});

export const useAmplitudeContext = () => useContext<IAmplitudeContext>(AmplitudeContext);

/**
 * - Sets the session ID.
 * - Sets the user ID.
 * - Sets the device ID.
 * - Exposes logging and initialize methods.
 */
export function AmplitudeProvider(props: { children: ReactNode }) {
  const [sessionId, setSessionId] = useState('');
  const { region } = useLocale();
  const enableOptionalAmplitudeTracking = useFlag(
    LaunchDarklyFlag.ENABLE_OPTIONAL_AMPLITUDE_TRACKING
  );
  const amplitudeKey = useApiKey({ key: 'amplitude' });

  /**
   * We never log PII to Amplitude.
   * Though you may pass any object to this method,
   * `extractAmplitudeUserAttributes` will only add the attributes we want.
   */
  const updateAmplitudeUserAttributes = useCallback(
    async (userAttributes: Record<string, any> = {}) => {
      const cognitoId = AuthStorage.getItem(StorageKeys.USER_AUTH_TOKEN);
      if (!cognitoId) {
        return;
      }
      const globalUserAttributes = {
        brand: brand().toUpperCase(),
        region,
        env: env() as string,
      };

      const sanitizedAttributes = sanitizeValues({ ...userAttributes, ...globalUserAttributes });
      const normalizedAttributes = normalizeBooleans(sanitizedAttributes);

      const amplitudeUserAttributes = extractAmplitudeUserAttributes(normalizedAttributes);
      setAmplitudeUserAttributes(amplitudeUserAttributes);

      // set Braze user attributes
      // in addition to Amplitudes' attributes, we want email, phone number, and age/dob (PII).
      const age = getAge(userAttributes.dob);

      const { firstName, lastName } = getName(
        { name: userAttributes.name },
        { firstName: '', lastName: '' }
      );

      setBrazeUserAttributes({
        ...amplitudeUserAttributes,
        ...(userAttributes.dob && { 'Date of Birth': userAttributes.dob }),
        ...(age && { $Age: age, Age: age }),
        ...(firstName && { firstName }),
        ...(lastName && { lastName }),
        ...(userAttributes.email && { email: userAttributes.email }),
        ...(userAttributes.phoneNumber && { phoneNumber: userAttributes.phoneNumber }),
      });
    },
    [region]
  );

  const addGTMPlugin = async () => {
    if (Platform.OS === 'web') {
      try {
        const gtmPlugin: DestinationPlugin = {
          name: 'google-tag-manager',
          type: PluginType.DESTINATION,
          setup: initGTM,
          execute: sendGoogleTagManagerEvent,
        };
        await add(gtmPlugin).promise;
      } catch (error) {
        logger.error({ error, message: 'Failed to add Google Tag Manager plugin to Amplitude' });
      }
    }
  };

  /**
   * Initialize the Amplitude client.
   * If a user is already logged in, we will initialize with the user's cognito ID.
   */
  const initialize = useCallback(() => {
    const cognitoId = AuthStorage.getItem(StorageKeys.USER_AUTH_TOKEN);

    const options: ReactNativeOptions = {
      trackingSessionEvents: true,
      appVersion,
      defaultTracking: { sessions: true },
      trackingOptions: {
        appSetId: true,
        idfv: true,
      },
    };
    try {
      addGTMPlugin();
      startTrace(TraceName.APP_START_INIT_AMPLITUDE);

      if (cognitoId) {
        init(amplitudeKey, cognitoId, options);

        // If we turn on optional Amplitude tracking
        // users will be able to opt out of Amplitude tracking.
        // If we decide that we do not want to disable events,
        // when we disable the flag (making Amplitude tracking not-optional),
        // we need to change setOptOut to false in order to send events to Amplitude.
        // This would guarantee any users who previously opted out, will be opted back in.
        // We should always faithfully record in local storage + Amplitude what the user wants us to do.
        // If we have good reason to not respect their preferences - that is where we should override their preferences.
        // REVISIT
        if (!enableOptionalAmplitudeTracking) {
          setOptOut(false);
        }

        // This will automatically add global user attributes.
        updateAmplitudeUserAttributes();
      } else {
        init(amplitudeKey, undefined, options);
      }
    } catch (error) {
      logger.error({ error, message: 'Error initializing Amplitude SDK' });
    } finally {
      stopTrace(TraceName.APP_START_INIT_AMPLITUDE);
    }
  }, [amplitudeKey, enableOptionalAmplitudeTracking, updateAmplitudeUserAttributes]);

  /**
   * @deprecated
   * Logs a custom event to Amplitude.
   * Please use the logRBIEvent method from useCRMEventsContext instead of this.
   * Note - this is used inside of the logRBIEvent in CRMEventsContext.
   */
  const logAmplitudeCustomEvent = useCallback((event: ILogAmplitudeCustomEventProps) => {
    try {
      track(event.name, event.attributes);
    } catch (err) {
      logger.error('Error logging event to Amplitude');
    }
  }, []);

  const logAmplitudeRevenueEvent = useCallback(
    ({
      totalAmount,
      eventProperties,
    }: {
      totalAmount: number;
      eventProperties: {
        [key: string]: ValidPropertyType;
      };
    }) => {
      const revenueEvent = new Revenue();
      revenueEvent.setRevenue(totalAmount).setEventProperties(eventProperties);
      revenue(revenueEvent);
    },
    []
  );

  /**
   * Sets the session ID in State.
   */
  const configureSessionId = useCallback(() => {
    // TODO re-think this function. having iOS throw an error ~100% of the time doesn't make sense.
    const MISSING_SESSION_ERROR_MESSAGE = 'No Amplitude session ID available';
    try {
      const amplitudeSessionId = getSessionId()?.toString();
      if (!amplitudeSessionId) {
        throw new Error(MISSING_SESSION_ERROR_MESSAGE);
      }
      setSessionId(amplitudeSessionId);
      addLoggerContext('session', amplitudeSessionId);
      crashlytics().setAttribute('amplitudeSession', amplitudeSessionId);
      setHeaders(Header.SESSION_ID, amplitudeSessionId);
      staticSessionId.current = amplitudeSessionId;
    } catch (error) {
      /**
       * This happens when the web does not have a sessionId yet for iOS to use.
       * We won't bother making it an error since there is nothing for us to do.
       */

      /**
       * Setting a custom session ID. Note, the session ID does note have to be unique across users.
       * https://www.docs.developers.amplitude.com/data/sdks/typescript-react-native/#custom-user-id
       * https://help.amplitude.com/hc/en-us/articles/115002323627-Track-sessions-in-Amplitude#:~:text=The%20session%20ID%20does%20not,you%20use%20to%20group%20sessions.
       */
      const newSessionID = Date.now();
      setAmplitudeSessionId(newSessionID);
      setSessionId(String(newSessionID));
      addLoggerContext('session', String(newSessionID));
      crashlytics().setAttribute('amplitudeSession', String(newSessionID));
      setHeaders(Header.SESSION_ID, String(newSessionID));
      staticSessionId.current = String(newSessionID);

      let errorMessage = MISSING_SESSION_ERROR_MESSAGE;
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      // this happens 100% of the time on IOS so it doesn't need to log at a high level
      if (errorMessage.includes(MISSING_SESSION_ERROR_MESSAGE)) {
        logger.debug(errorMessage);
        return;
      }
      logger.error({
        message: `Failed to get Amplitude SessionID: ${String(error)}`,
      });
    }
  }, []);

  const updateDeviceId = async () => {
    /**
     * We are using a custom util that is a promise, but we could potentially also use Amplitude's method
     * (also called getDeviceId).
     */
    const id = await getDeviceId();
    if (id) {
      setAmplitudeDeviceId(id?.toLocaleLowerCase()); // toLower keeps this consistent with how it was done from mparticle for continuity of tracking
    }
  };

  const updateAmplitudeUserId = useCallback<IAmplitudeContext['updateAmplitudeUserId']>(userId => {
    if (userId) {
      setUserId(userId);
    } else {
      setUserId(undefined);
    }
  }, []);

  const updateUserLocationPermissionStatus = useCallback(async () => {
    const status = await getNativeLocationPermissions();
    if (!status) {
      return;
    }
    if (isNativeIOS()) {
      updateAmplitudeUserAttributes({ 'IOS Location Permissions': status });
    } else {
      updateAmplitudeUserAttributes({ 'Android Location Permissions': status });
    }
  }, [updateAmplitudeUserAttributes]);

  useEffect(() => {
    initialize();
    configureSessionId();
    updateDeviceId();
  }, []);

  const amplitudeContext = useMemo<IAmplitudeContext>(
    () => ({
      initialize,
      logAmplitudeCustomEvent,
      logAmplitudeRevenueEvent,
      sessionId,
      updateUserLocationPermissionStatus,
      updateAmplitudeUserAttributes,
      updateAmplitudeUserId,
    }),
    [
      initialize,
      updateUserLocationPermissionStatus,
      logAmplitudeCustomEvent,
      logAmplitudeRevenueEvent,
      sessionId,
      updateAmplitudeUserAttributes,
      updateAmplitudeUserId,
    ]
  );

  return (
    <AmplitudeContext.Provider value={amplitudeContext}>{props.children}</AmplitudeContext.Provider>
  );
}

export default AmplitudeContext.Consumer;
