import { ICombo, IItem, IPicker, IPickerOption } from '@rbi-ctg/menu';

//resolves picker selections to a single option
export function computeSelectedOption<
  Item extends ICombo | IItem | IPicker | { _type: string; options: IPickerOption }
>(pickerSelections: Record<string, string>, item: Item) {
  if (item._type === 'combo' || item._type === 'item') {
    return item;
  }
  const { options } = item;
  const pickerValues = Object.values(pickerSelections);
  if (!pickerSelections || pickerValues.length < 1) {
    return options && options[0] ? (options[0].option as IPickerOption['option']) : false;
  }

  const resolution = (options as IPickerOption[]).find(itemOpt => {
    return itemOpt?.pickerItemMappings?.every(mapping => {
      return pickerSelections[mapping?.pickerAspect?._id!] === mapping?.pickerAspectValueIdentifier;
    });
  });

  return resolution?.option || false;
}
