import React, { useCallback, useRef } from 'react';

import { Box, BoxProps } from '@rbilabs/universal-components';
import { noop } from 'lodash-es';

import { useRoute } from 'hooks/navigation/use-route';
import { useCart } from 'hooks/use-cart';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { useIsMobileBreakpoint } from 'hooks/use-media-query';
import { useEnableMapListExperiment } from 'pages/store-locator/use-enable-map-list-experiment';
import { useAuthContext } from 'state/auth';
import useAuthFlow from 'state/auth/hooks/use-auth-flow';
import { useLocale } from 'state/intl';
import { useMatch } from 'state/location';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';
import { isWeb } from 'utils/environment';
import { isItemPickerOrComboRoute, isSectionRoute, routes } from 'utils/routing';

import CheckoutButtonLink from './checkout-button-link';
import { MobileWebNavigationBarSkeleton } from './mobile-web-navigation-bar.skeleton';
import { MobileWebNavigationBorder } from './mobile-web-navigation-border';
import { SanityNavMobileTab } from './sanity-nav-mobile-tab';
import { useSanityNavigationTabs } from './use-sanity-navigation-tabs';

const mobileMediaQuery = { mediaquery: 'mobile' };

// The types for react native do not support `dataSet` yet, but
// it is the required way to pass data-* attributes to the node.
// And we are using this mechanism to ensure these nodes are rendered
// during the pre-render, but only visible for the correct screen size.
const onlyRenderInMobile = () =>
  ({
    dataSet: mobileMediaQuery,
  } as any);

const boxViewProps: BoxProps = {
  flexDirection: 'row',
  justifyContent: {
    base: 'space-evenly',
    md: 'space-between',
  },
  _web: {
    width: 'full',
    marginX: 'auto',
  },
  backgroundColor: '__legacyToken.bottom-nav-bar-background-color',
};

interface IMobileCheckoutButtonProps {
  checkoutRoute: string;
}

const MobileCheckoutButton: React.FC<React.PropsWithChildren<IMobileCheckoutButtonProps>> = ({
  checkoutRoute,
}) => {
  // TODO: ReactNavigation Replace the use of this useMatch hook
  const baseRouteMatch = useMatch(`${routes.base}*`);
  const storeLocatorMatch = useMatch(`${routes.storeLocator}/*`);
  const menuMatch = useMatch(`${routes.menu}/*`);
  const cartMatch = useMatch(`${routes.cart}/*`);
  const orderConfirmationMatch = useMatch(`${routes.orderConfirmation}/*`);
  const { cartEntries, calculateCartTotal } = useCart();
  const { isAuthenticated } = useAuthContext();
  const cartButton = useRef<HTMLAnchorElement>(null);
  const { logEvent } = useMParticleContext();
  const logMobileCheckoutEvent = useCallback(() => {
    logEvent(CustomEventNames.CHECKOUT_MOBILE, EventTypes.Navigation);
  }, [logEvent]);
  if (!baseRouteMatch) {
    return null;
  }
  // We don't want to show the checkout cart on the store-locator page
  if (storeLocatorMatch) {
    return null;
  }

  if (menuMatch) {
    const menuObjectId = menuMatch.params['*'];
    if (menuObjectId && !menuObjectId!.startsWith('section')) {
      return null;
    }
  }

  if ((cartEntries.length && !cartMatch) || orderConfirmationMatch) {
    return (
      <CheckoutButtonLink
        amount={calculateCartTotal()}
        to={checkoutRoute}
        ref={cartButton}
        logMobileCheckoutEvent={isAuthenticated ? logMobileCheckoutEvent : noop}
      />
    );
  }
  return null;
};

const MobileWebNavigationBar: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { cartEntries } = useCart();
  const { serviceMode } = useOrderContext();
  const { isStoreOpenAndAvailable } = useStoreContext();
  const { language } = useLocale();
  const cartButton = useRef<HTMLAnchorElement>(null);
  const { pathname } = useRoute();
  const enableMapViewExperiment = useEnableMapListExperiment();

  const isMobile = useIsMobileBreakpoint();
  const hideForMobileMenuSectionRoute = isSectionRoute(pathname) && isMobile;
  const hideNavLinks =
    (isWeb && isItemPickerOrComboRoute(pathname)) ||
    hideForMobileMenuSectionRoute ||
    (enableMapViewExperiment && pathname.includes(routes.storeLocator));

  const { isInAuthFlow } = useAuthFlow();

  const [navLinks] = useSanityNavigationTabs();

  useEffectOnUpdates(() => {
    const timeoutRef = setTimeout(() => {
      cartButton.current?.focus();
    }, 100);
    return () => clearTimeout(timeoutRef);
  }, [cartEntries.length]);

  // TODO: @glengal checkoutRoute is not used. Expected? this is potentially a bug
  let checkoutRoute = routes.cart;
  if (!serviceMode) {
    checkoutRoute = routes.serviceMode;
  } else if (!isStoreOpenAndAvailable) {
    checkoutRoute = routes.storeLocator;
  }

  // not showing mobile navigation bar on sign up flow
  if (isInAuthFlow) {
    return null;
  }

  return (
    <MobileWebNavigationBorder {...onlyRenderInMobile()}>
      {!hideNavLinks && (
        <Box safeAreaBottom {...boxViewProps} testID="link-container">
          {navLinks?.length ? (
            navLinks.map((item, idx, arr) => (
              <SanityNavMobileTab
                key={item.selectedHrefs[0]}
                componentKey={item.selectedHrefs[0]}
                tab={item}
                language={language}
                isFirst={idx === 0}
                isLast={idx === arr.length - 1}
              />
            ))
          ) : (
            <MobileWebNavigationBarSkeleton />
          )}
          {isStoreOpenAndAvailable ? <MobileCheckoutButton checkoutRoute={checkoutRoute} /> : null}
        </Box>
      )}
    </MobileWebNavigationBorder>
  );
};

export default MobileWebNavigationBar;
