import { configureStore } from '@reduxjs/toolkit';

import { loyaltyReducer, orderingReducer } from './models';

const isReduxDebuggerEnabled = __DEV__;

export const store = configureStore({
  reducer: {
    loyalty: loyaltyReducer,
    ordering: orderingReducer,
  },
  middleware: getDefaultMiddleware => {
    const middleware = getDefaultMiddleware({ immutableCheck: false, serializableCheck: false });

    return middleware;
  },
  devTools: isReduxDebuggerEnabled,
});

export type ConfiguredStore = typeof store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
