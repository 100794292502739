import { useMemo } from 'react';

import { useRoute } from 'hooks/navigation/use-route';
import { IAuthRouteParams, ModalAuthScreen } from 'state/auth/types';
import { routes } from 'utils/routing';

import { useAuthContext } from '..';

type IUseAuthFlow = () => {
  isInAuthFlow: boolean;
  activeRouteIsSignIn: boolean;
  showOtpForm: boolean;
  attemptedSignUpWithExistingEmail: boolean;
  hasSignedOut: boolean;
  errorMessage: string | undefined;
  triggerSignInAccessibility: boolean;
  email: string | undefined;
};

const useAuthFlow: IUseAuthFlow = () => {
  const { params, pathname } = useRoute<IAuthRouteParams>();
  const { modalAuthState } = useAuthContext();

  // @ts-ignore
  const staticPageParams: { staticPagePath?: string } = params?.params || params || {};
  const isModalStaticPageRoute =
    pathname.startsWith(routes.staticPage) ||
    pathname.startsWith(routes.modalStaticPage) ||
    !!staticPageParams.staticPagePath;

  const isSignUp = pathname === routes.signUp || modalAuthState.screen === ModalAuthScreen.SIGN_UP;

  const isSignIn =
    pathname === routes.signIn ||
    Boolean(params?.activeRouteIsSignIn) ||
    modalAuthState.screen === ModalAuthScreen.SIGN_IN;

  const showOtpForm =
    pathname === routes.confirmOtp ||
    Boolean(params?.showOtpForm) ||
    modalAuthState.screen === ModalAuthScreen.OTP;

  const attemptedSignUpWithExistingEmail = Boolean(params?.attemptedSignUpWithExistingEmail);
  const hasSignedOut = Boolean(params?.hasSignedOut);
  const errorMessage = params?.errorMessage;
  const triggerSignInAccessibility = Boolean(params?.triggerSignInAccessibility);
  const email = params?.email;

  return useMemo(
    () => ({
      isInAuthFlow: isSignUp || isSignIn || showOtpForm || isModalStaticPageRoute,
      activeRouteIsSignIn: isSignIn,
      showOtpForm,
      attemptedSignUpWithExistingEmail,
      hasSignedOut,
      errorMessage,
      triggerSignInAccessibility,
      email,
    }),
    [
      isSignUp,
      isSignIn,
      isModalStaticPageRoute,
      showOtpForm,
      attemptedSignUpWithExistingEmail,
      hasSignedOut,
      errorMessage,
      triggerSignInAccessibility,
      email,
    ]
  );
};

export default useAuthFlow;
