import { ISanityImage, ISanityTypographyBlock } from '@rbi-ctg/menu';
import { IImageFragment, IOnboardingScreenFragment } from 'generated/sanity-graphql';

export interface IOnboardingContext {
  featureOnboardingScreens: IFeatureOnboardingScreen[];
  onDismissOnboardingModal: (value?: string) => void;
  onButtonPress: (
    featureOnboardingScreen: IFeatureOnboardingScreen,
    url?: string | undefined,
    callback?: () => void
  ) => void;
  isFirstAppLoadOnboardingComplete: boolean;
  navigateForward: () => void;
  navigateBackward: () => void;
  currentScreenIndex: number;
  isFirstScreen: boolean;
  isLastScreen: boolean;
  numberOfScreens: number;
  triggerRule: string | undefined;
}

export interface IFeatureOnboardingScreenContent {
  id?: string;
  image: ISanityImage | IImageFragment | null;
  textContent: ISanityTypographyBlock[];
  buttonText: string;
  buttonUrl: string;
  secondaryButtonText?: string;
  secondaryButtonUrl?: string;
  imageDescription: string;
}

export type IFeatureOnboardingScreen = IFeatureOnboardingScreenContent | null | undefined;

export type IOnboardingScreen =
  | ({ readonly __typename?: 'ImageWithHeaderAndText' | undefined } & IOnboardingScreenFragment)
  | null;

export enum Channel {
  WEB = 'web',
  IOS = 'ios',
  ANDROID = 'android',
}

export enum TriggerRule {
  ON_FIRST_APP_LOAD_NEW_USER = 'firstAppLoadNewUser',
  ON_FIRST_APP_LOAD_EXISTING_USER = 'firstAppLoadExistingUser',
}

export interface OnboardingScreen {
  id: string;
  completed: boolean;
}

export interface OnboardingDoc {
  id: string;
  screens: OnboardingScreen[];
}

export interface OnboardingStorage {
  loyaltyEnrolled: boolean;
  onboardingDocs: OnboardingDoc[];
}

export type Onboardings =
  | {
      id: string;
      featureOnboardingChannel: string;
      featureOnboardingTriggerRule: string;
      featureOnboardingScreens: IFeatureOnboardingScreenContent[] | undefined;
    }[]
  | undefined;
