import { HStack, Pressable, Text } from '@rbilabs/universal-components';

import { primitive } from 'styles/constants/primitives';

export const Close = Pressable.withConfig({
  marginRight: '$2',
});

export const Container = HStack.withConfig({
  alignItems: 'center',
  paddingY: '$2',
});

export const ServiceModeMessage = Text.withConfig({
  color: primitive.$white,
  fontFamily: 'heading',
  marginLeft: '$1',
});

export const Details = Text.withConfig({
  color: primitive.$white,
  fontFamily: 'body',
  fontSize: 'md',
});

export const Cta = Pressable.withConfig({
  flexDir: 'row',
  flex: 1,
  justifyContent: 'flex-end',
  alignContent: 'center',
  alignItems: 'center',
});
