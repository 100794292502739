import { IHistoricalOrder, IServerOrder } from '@rbi-ctg/menu';
import { PosVendor } from 'generated/graphql-gateway';

export const getOrderNumber = (serverOrder: IServerOrder | IHistoricalOrder): string | null => {
  // QDI / NCR does not display the order number on the receipt.
  // To avoid confusion from the customer, we will omit the line
  //
  // This vendor check can be removed once the backend has been updated
  // to return `undefined` for NCR orders
  const vendorsWithoutOrderNumber = [PosVendor.NCR, PosVendor.QDI];

  const orderNumber = serverOrder.cart.ticketNumber;
  return !vendorsWithoutOrderNumber.includes(serverOrder.cart.posVendor) && orderNumber
    ? orderNumber
    : null;
};
