import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash-es';

import { useLoyaltyRewardsList } from 'hooks/use-loyalty-rewards-list';
import { usePrevious } from 'hooks/use-previous';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { updateAppliedRewardsInStorage } from 'state/global-state/models/loyalty/rewards/rewards.utils';
import { IUseLoyaltyRewards, UseLoyaltyRewards } from 'state/loyalty/hooks/types';

export const useLoyaltyRewards: UseLoyaltyRewards = (loyaltyUser): IUseLoyaltyRewards => {
  const { refetchRewards, engineRewardsMap } = useLoyaltyRewardsList();

  const [shouldApplyStorageRewards, setShouldApplyStorageRewards] = useState(false);
  const dispatch = useAppDispatch();
  const availableLoyaltyRewardsMap = useAppSelector(
    selectors.loyalty.selectAvailableLoyaltyRewardsMap
  );

  const loyaltyUserId = loyaltyUser?.id;
  const previousLoyaltyUserId = usePrevious(loyaltyUserId);

  useEffect(() => {
    // loyalty user id will populate upon logging in / initial page load
    if (!previousLoyaltyUserId && loyaltyUserId) {
      setShouldApplyStorageRewards(true);
    }
    // occurs when a user logs out
    else if (previousLoyaltyUserId && !loyaltyUserId) {
      updateAppliedRewardsInStorage({});
    }
  }, [previousLoyaltyUserId, loyaltyUserId]);

  useEffect(() => {
    if (!isEmpty(availableLoyaltyRewardsMap) && shouldApplyStorageRewards) {
      dispatch(actions.loyalty.rehydrateAppliedReward());
      setShouldApplyStorageRewards(false);
    }
  }, [availableLoyaltyRewardsMap, dispatch, shouldApplyStorageRewards]);

  return { refetchRewards, engineRewardsMap };
};
