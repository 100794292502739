import { Svg, SvgPath, SvgProps, registerSVG } from '../../../components/svg';

export function CashApp(props: SvgProps) {
  return (
    <Svg fill="none" {...props} viewBox="0 0 275 194">
      <SvgPath fill="#00D64F" d="M0 0h275v194H0z" />
      <SvgPath
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M141.6 65.5c9.2 0 18 3.7 23.7 8.8a3.6 3.6 0 0 0 5 0l6.9-7a3.6 3.6 0 0 0-.2-5.2c-4.6-4-10.8-7.9-18.4-10.3l2.2-10.3a3.6 3.6 0 0 0-3.5-4.3H144a3.6 3.6 0 0 0-3.5 2.8l-2 9.1c-17.5 1-32.4 9.7-32.4 27.7 0 15.6 12.3 22.3 25.3 27 12.4 4.6 18.9 6.3 18.9 12.8 0 6.7-6.5 10.6-16.1 10.6a35 35 0 0 1-25-9.9 3.6 3.6 0 0 0-5 0l-7.5 7.3a3.6 3.6 0 0 0 0 5.1c5.8 5.6 13 9.7 21.4 12l-2 9.4a3.6 3.6 0 0 0 3.4 4.3l13.3.1c1.7 0 3.2-1.1 3.5-2.8l2-9.1c21-1.3 34-12.8 34-29.6 0-15.4-13-22-28.5-27.2-9-3.3-16.7-5.5-16.7-12.2 0-6.5 7.3-9 14.5-9"
        fill="#fff"
      />
    </Svg>
  );
}
registerSVG('CashApp', CashApp);
