import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

const useIsSignUpAfterCart = (): boolean => {
  const { isAuthenticated } = useAuthContext();
  const enableSignupAfterCart = useFlag(LaunchDarklyFlag.ENABLE_MOVE_SIGNUP_AFTER_CART);
  return !isAuthenticated && enableSignupAfterCart;
};

export default useIsSignUpAfterCart;
