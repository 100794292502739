import React, { FC, useCallback } from 'react';

import { Box, Icon, IconButton } from '@rbilabs/universal-components';

import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';
import { theme } from 'styles/configure-theme';

const ClearStoreSMButtonWrapper = Box.withConfig({
  justifyContent: 'center',
  minWidth: '$10',
});

export const ClearStoreSmButton: FC<React.PropsWithChildren<unknown>> = () => {
  const renderButton = useFlag(LaunchDarklyFlag.ENABLE_LOCATOR_CLEAR_STORE_AND_SM_BUTTON);
  const { clearServerOrder } = useOrderContext();
  const { resetStore } = useStoreContext();

  const handleClick = useCallback(() => {
    clearServerOrder();
    resetStore();
  }, [clearServerOrder, resetStore]);

  return (
    <ClearStoreSMButtonWrapper>
      {renderButton ? (
        <IconButton
          variant="ghost-reversed"
          onPress={handleClick}
          testID="clear-store-sm-button"
          icon={<Icon variant="bkCrown" color={theme.token('text-button-reversed')} aria-hidden />}
        />
      ) : null}
    </ClearStoreSMButtonWrapper>
  );
};
