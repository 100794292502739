import { Spinner as NbSpinner } from 'native-base';

import { addWithConfig } from '../../utils';

import type { SpinnerProps } from './types';

export const Spinner = ({ reversed, ...props }: SpinnerProps) => (
  <NbSpinner
    color={reversed ? 'token.background-loader-indicator-reversed' : 'token.icon-active'}
    {...props}
  />
);

export default addWithConfig(Spinner);
