import * as React from 'react';
import { FC, useCallback, useState } from 'react';

import { Box, Header, Icon, Text } from '@rbilabs/universal-components';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { GeolocationDeniedModal } from 'components/geolocation-denied-modal';
import { LayoutContainer } from 'components/layout/styled';
import { StaticImage, StaticImages } from 'components/picture/static-image';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useGeolocation } from 'state/geolocation';
import { portalToFooter } from 'state/mobile-footer';
import { portalToReplaceHeader } from 'state/mobile-header-nav';
import { primitive } from 'styles/constants/primitives';
import { isNative } from 'utils/environment';
import { routes } from 'utils/routing';

import { useLoyaltyWalmart } from '../walmart/use-loyalty-walmart';

import {
  GeneralBottomContainer,
  GeneralTopContainer,
  InnerBottomContainer,
  InnerGrayContainer,
  InnerWhiteContainer,
  LeftButtonOne,
  LeftButtonThree,
  LeftButtonTwo,
  RightButton,
  WalmartLogoContainer,
} from './styled';

export const WalmartLocation: FC<React.PropsWithChildren<{}>> = () => {
  const { navigate } = useNavigation();
  const { isPermissionPrompt, isPermissionDenied, setPermissionChecking } = useGeolocation();
  const [geolocationDeniedModalOpen, setGeolocationDeniedModalOpen] = useState(false);
  const dismissModal = () => setGeolocationDeniedModalOpen(false);
  const { data, loading } = useLoyaltyWalmart();

  const handleGeolocationPrompt = useCallback(() => {
    if (isPermissionDenied) {
      setGeolocationDeniedModalOpen(true);
    } else if (isPermissionPrompt) {
      setPermissionChecking();
    }
    navigate(`${routes.loyaltyOfferList}?success=true`);
  }, [isPermissionDenied, isPermissionPrompt, navigate, setPermissionChecking]);

  const HiddenAppHeader = portalToReplaceHeader(() => null);

  const HiddenAppFooter = portalToFooter(() => null);

  if (loading) {
    return null;
  }

  return (
    <LayoutContainer isFullContainer={true} minHeight="100vh" backgroundColor="white">
      <HiddenAppHeader />
      <HiddenAppFooter />
      <Box
        height={{ md: '450px', base: isNative ? '470px' : '435px' }}
        background={primitive.$white}
      >
        <GeneralTopContainer isNative={isNative}>
          <Box>
            <LeftButtonOne />
            <LeftButtonTwo />
            <LeftButtonThree />
          </Box>
          <InnerWhiteContainer>
            <InnerGrayContainer>
              <StaticImage
                borderTopLeftRadius={30}
                borderTopRightRadius={30}
                height={385}
                width="full"
                source={StaticImages.WalmartLocation}
                alt={'BK Image'}
              ></StaticImage>
              <WalmartLogoContainer>
                <Icon variant="walmartLogo" aria-hidden size={100} />
              </WalmartLogoContainer>
            </InnerGrayContainer>
          </InnerWhiteContainer>
          <RightButton />
        </GeneralTopContainer>
      </Box>
      <Box height={{ base: isNative ? '405px' : 'auto' }} background={primitive.$white}>
        <GeneralBottomContainer isNative={isNative}>
          <InnerBottomContainer>
            <Header textAlign="center">{data?.location?.title}</Header>
            <Text textAlign="center" mb="$8">
              {data?.location?.description}
            </Text>
            <ActionButton
              width="$90"
              onPress={handleGeolocationPrompt}
              _text={{ fontSize: 'md' }}
              mb="$4"
            >
              {data?.location?.shareLocationButton?.buttonText?.locale}
            </ActionButton>
            <ActionButton
              variant={ActionButtonVariants.TEXT_ONLY}
              onPress={() => navigate(`${routes.loyaltyOfferList}?success=true`)}
            >
              {data?.location?.notShareLocationButton?.buttonText?.locale}
            </ActionButton>
          </InnerBottomContainer>
        </GeneralBottomContainer>
      </Box>
      <GeolocationDeniedModal isOpen={geolocationDeniedModalOpen} dismissModal={dismissModal} />
    </LayoutContainer>
  );
};
