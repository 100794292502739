import { Box } from '@rbilabs/universal-components';

export const LogosContainer = Box.withConfig({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '$4',
  marginBottom: '$8',
});

export const DividerLogos = Box.withConfig({
  height: '$10',
  width: '$2',
  borderStyle: 'solid',
  borderLeftWidth: '2',
  borderColor: '#c5bcb0',
  marginLeft: '$4',
  marginRight: '$3',
});

export const HeaderContainer = Box.withConfig({
  overflow: 'hidden',
  alignSelf: 'center',
  height: {
    base: '200px',
    sm: '350px',
    md: '456px',
  },
  width: {
    base: 'full',
    md: '912px',
  },
  borderTopRadius: {
    base: 0,
    md: 10,
  },
  borderBottomRadius: {
    base: 0,
    md: 10,
  },
});
