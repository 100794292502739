/**
 * a promise that can be externally resolved outside of a callback function.
 * useful for very specific (uncommon) situations where you cannot wrap async code in a promise.
 * a normal Promise should be attempted and used 99% of the time.
 * */
export class Deferred {
  public reject!: (reason?: any) => void;
  public resolve!: (value?: any) => void;
  public promise = new Promise((resolve, reject) => {
    this.reject = reject;
    this.resolve = resolve;
  });
}
