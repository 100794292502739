import { IOnOrderStatusChangeOptions, OrderStatusesAndPrevious } from 'hooks/order-status/types';
import { isDelivery } from 'utils/service-mode';

export const statusHasChanged = ({
  orderStatus,
  orderDeliveryStatus,
  prevOrderDeliveryStatus,
  prevOrderStatus,
  serverOrder,
}: Pick<IOnOrderStatusChangeOptions, OrderStatusesAndPrevious>) => {
  const isDeliveryOrder = isDelivery(serverOrder && serverOrder.cart.serviceMode);
  const posStatusHasChanged = orderStatus !== prevOrderStatus;
  const deliveryStatusHasChanged = orderDeliveryStatus !== prevOrderDeliveryStatus;

  return isDeliveryOrder ? posStatusHasChanged || deliveryStatusHasChanged : posStatusHasChanged;
};
