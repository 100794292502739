import * as React from 'react';

import { HStack, Header } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import TrophyIcon from 'components/icons/trophy';

import { getQuestText } from '../custom-text';

export const QuestLibraryHeader = () => {
  const { formatMessage } = useIntl();

  return (
    <HStack>
      <TrophyIcon mt={{ base: '$1.5', md: '$2' }} />
      <Header ml={{ base: '$2', md: '$3' }}>{getQuestText(formatMessage)}</Header>
    </HStack>
  );
};
