import { PROD_DEFAULT_REGION as DEFAULT_REGION } from 'state/intl/constants';
import { DOMAINS } from 'state/intl/types';
import { isNative, isRunningLocally } from 'utils/environment';
import { Region } from 'utils/environment/types';
import { getNavigatorLanguage } from 'utils/intl/language/get-navigator-language';
import LocalStorage, { StorageKeys } from 'utils/local-storage';

import { findSupportedRegion } from './find-supported-region';
import { inferRegionFromUrlParams } from './infer-region-from-url-params';

export { findSupportedRegion };

function inferRegionFromNavigator(): string {
  const navigatorLanguage = getNavigatorLanguage() || '';

  // If the language isn't set return default region
  return navigatorLanguage.split(/.*-/)[1] || String(DEFAULT_REGION);
}

// TODO: Make more robust by using a mapping library (no pun intended)
function inferRegionFromTLD(): string {
  const supportedDomainsRegex = new RegExp(
    ([] as string[])
      .concat(...Object.values(DOMAINS))
      .join('|')
      .replace(/\./g, '\\.')
  );
  const tldMatches = window.location.host.match(supportedDomainsRegex);
  const matchedTld = tldMatches && tldMatches.length && tldMatches[0];

  switch (matchedTld as DOMAINS) {
    case DOMAINS.com:
      return Region.US;
    default:
      return String(DEFAULT_REGION);
  }
}

export function loadRegion(): Region {
  const inferredUrlParamRegion = inferRegionFromUrlParams();

  if (isRunningLocally()) {
    // If there is no region inferred by URL params, set it to the selected region or to the default region
    return (inferredUrlParamRegion || DEFAULT_REGION) as Region;
  }
  const inferredDomainRegion = inferRegionFromTLD();

  const inferredNavigatorRegion = inferRegionFromNavigator();
  // TODO: get the Region form native code.
  // Ideally we would retrieve the user's defined region from native code.
  // As a temporary solution we are checking the users language e.g. en-US or en-CA
  const inferredDomainOrNavigatorRegion = isNative ? inferredNavigatorRegion : inferredDomainRegion;

  const region =
    inferredUrlParamRegion ||
    LocalStorage.getItem<Region>(StorageKeys.REGION) ||
    inferredDomainOrNavigatorRegion;

  return findSupportedRegion(region.toString());
}
