import { IRestaurant } from '@rbi-ctg/store';
import { CustomEventNames, EventTypes } from 'state/mParticle';
import { IMParticleCtx } from 'state/mParticle/types';
import { appendFieldsFromObject } from 'utils/index';

/**
 * mParticle events that need to be added to multiple places in the code can be added here once and reused in effort
 * to reduce duplication for the same event
 */

export const logAddRecentItemToCartEvent = (
  productName: string,
  store: IRestaurant,
  logRBIEvent: IMParticleCtx['logRBIEvent']
): void => {
  const storeAddressFields = ['address1', 'address2'];
  const storeAddress = appendFieldsFromObject(
    storeAddressFields,
    store?.physicalAddress ?? {},
    ', '
  );
  logRBIEvent({
    name: CustomEventNames.ADD_RECENT_ITEM_TO_CART,
    type: EventTypes.Other,
    attributes: {
      productName,
      restaurantId: store._id ?? '',
      restaurantAddress: storeAddress,
      restaurantZip: store.physicalAddress?.postalCode ?? '',
      restaurantCity: store.physicalAddress?.city ?? '',
      restaurantState: store.physicalAddress?.stateProvince ?? '',
      restaurantCountry: store.physicalAddress?.country ?? '',
    },
  });
};
