import { IValidationConfig } from './types';

// Default config... if your brand needs to be different create an override with a brand extension
export const validationConfig: IValidationConfig = {
  dob: {
    required: false,
  },
  phoneNumber: {
    required: false,
  },
  zipcode: {
    required: false,
  },
};
