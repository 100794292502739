import React from 'react';

import { useIntl } from 'react-intl';

import { OptInLabel, TermsLink } from '../styled';

import { RewardsPolicyTermsLink } from './rewards-policy-terms-link';

export const PromotionalEmailsLabel = () => {
  const { formatMessage } = useIntl();

  return (
    <OptInLabel testID={`opt-in-label-acceptEmailsAndSpecialOffers`}>
      {formatMessage({ id: 'acceptEmailsAndSpecialOffers' })}
    </OptInLabel>
  );
};

export const TermsOfServiceLabel = () => {
  const { formatMessage } = useIntl();

  const privacyPolicyLink = (
    <TermsLink to={formatMessage({ id: 'routes.privacyPolicy' })}>
      {formatMessage({ id: 'privacyPolicy' })}
    </TermsLink>
  );

  const rewardsPolicyLink = <RewardsPolicyTermsLink />;

  const termsOfServiceLink = (
    <TermsLink to={formatMessage({ id: 'routes.termsOfService' })}>
      {formatMessage({ id: 'termsOfService' })}
    </TermsLink>
  );

  return (
    <OptInLabel>
      {formatMessage(
        { id: 'acceptTermsAndPrivacyIntro' },
        { termsOfServiceLink, privacyPolicyLink, rewardsPolicyLink }
      )}
    </OptInLabel>
  );
};
