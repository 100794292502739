import {
  IBackendCartEntries,
  ICartEntry,
  IComboSlot,
  IComboSlotOption,
  IComboSlotSelectionsWithData,
  IItem,
  IItemOption,
  IItemOptionModifier,
  IModifierSelection,
  IOffer,
  ISanityItemOption,
  IWithPricingProps,
} from '@rbi-ctg/menu';
import { IPricingFunctionFn } from 'state/menu/types';

import {
  IWithVendorConfig,
  PluTypes,
  getVendorConfig,
  reduceMultiConstantPluQuantity,
} from '../vendor-config';

import { isDefaultOrLess } from './modifiers';
import { computePluForItemOptionModifier } from './plu';
import brandedPriceForCartEntry from './price-for-cart-entry';

type IComboSlotSelectionsNoData = Omit<IComboSlotSelectionsWithData, 'data'>;

export interface IComboSlotSelectionsNoDataInSelections {
  [comboSlotId: string]: IComboSlotSelectionsNoData;
}

export interface IComputePriceOptions {
  comboSlotSelections: IComboSlotSelectionsNoDataInSelections;
  item: IWithVendorConfig;
  modifierSelections: IModifierSelection[];
  pickerSelections: {
    [identifier: string]: string;
  };
  pricingFunction: IPricingFunctionFn;
  quantity: number;
  isOffer: boolean;
  selectedOffer: IOffer | null;
}

interface IPriceItemOptionModifierPlu extends IWithPricingProps {
  item: IBackendCartEntries | ICartEntry | IItem;
  modifier: IBackendCartEntries | ICartEntry | (IItemOptionModifier & { quantity?: number });
}

interface IPriceItemOptionModifier extends IWithPricingProps {
  item: IItem | ICartEntry;
  itemOption: ISanityItemOption | IItemOption;
  modifier: IItemOptionModifier & { quantity?: number };
}

export function priceForItemOptionModifierPlu({
  item,
  modifier,
  prices,
  vendor,
}: IPriceItemOptionModifierPlu): number {
  const plu = computePluForItemOptionModifier({
    item,
    modifier,
    vendor,
    prices,
  });

  // if plu could not be computed, we cannot get
  //  a price for this modifier
  if (!plu) {
    return 0;
  }

  const price = prices && plu in prices ? prices[plu] : 0;

  const modifierVendorConfig = getVendorConfig(modifier, vendor);

  if (!modifierVendorConfig) {
    return 0;
  }

  // if modifier is a multi-constant plu, we need to use
  // the quantity indicated by the plu to determine price
  if (modifierVendorConfig.pluType === PluTypes.MULTI_CONSTANT) {
    const quantity = reduceMultiConstantPluQuantity(modifierVendorConfig.multiConstantPlus || []);

    return price * quantity;
  }

  return price * (modifier.quantity || 1);
}

export function priceForItemOptionModifierBooleanOrStepper({
  item,
  itemOption,
  modifier,
  prices,
  vendor,
}: IPriceItemOptionModifier): number {
  // we do not charge the user for modifiers that are
  // default or any modifier with a smaller or equal
  // multiplier to the default
  const defaultOrLess = isDefaultOrLess(itemOption, modifier);

  if (defaultOrLess && !itemOption.upsellModifier) {
    return 0;
  }

  return priceForItemOptionModifierPlu({
    item,
    modifier,
    prices,
    vendor,
  });
}

export function priceForItemOptionModifierSelection({
  item,
  itemOption,
  modifier,
  prices,
  vendor,
}: IPriceItemOptionModifier): number {
  if (itemOption.allowMultipleSelections) {
    // @todo implement pricing for allowMultipleSelections
    return 0;
  }

  if (modifier.default && !itemOption.upsellModifier) {
    return 0;
  }

  return priceForItemOptionModifierPlu({
    item,
    modifier,
    prices,
    vendor,
  });
}

export const isOptionInComboSlot = (
  comboSlot: IComboSlot,
  comboSlotOption: IComboSlotOption | undefined
) => {
  if (!comboSlotOption?._key) {
    return false;
  }
  return !!comboSlot.options.find(slotOption => slotOption._key === comboSlotOption._key);
};

export const priceForCartEntry = brandedPriceForCartEntry;
