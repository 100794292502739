import { parse } from 'date-fns';
import { IntlShape } from 'react-intl';

export const timeToString = (time: string, formatTime: IntlShape['formatTime']) => {
  try {
    return formatTime(parse(time, 'HH:mm', new Date()));
  } catch {
    return time;
  }
};
