import { useCallback, useRef } from 'react';

import { ApolloClient, DocumentNode, QueryOptions, TypedDocumentNode } from '@apollo/client';
import {
  LazyQueryHookOptions,
  LazyQueryResultTuple,
  QueryHookOptions,
  QueryResult,
} from '@apollo/client/react/types/types';

type SimpleCacheQueryResult<TData, TVariables> = Pick<
  QueryResult<TData, TVariables>,
  'loading' | 'data' | 'previousData' | 'refetch' | 'error' | 'called'
>;

/** @allowFromCache - true: if the query result is stored in the cache (by input variables) return it - false: always fetch fresh  */
type SimpleLazyQueryHookOptions<TData, TVariables> = Omit<
  Partial<LazyQueryHookOptions<TData, TVariables>>,
  'fetchPolicy'
> & { allowFromCache: boolean };

type SimpleLazyQueryResultTuple<TData, TVariables> = [
  (
    options: SimpleLazyQueryHookOptions<TData, TVariables>
  ) => Promise<SimpleCacheQueryResult<TData, TVariables>>,
  SimpleCacheQueryResult<TData, TVariables>
];

type Result = { data: any; error: any };

// map[hookFunction][GQL variables stringified] = { data, error }
const hookFunctionToArgsToResultMap = new Map<Function, Map<string, Result>>();

// allow some strong typing for the baseOptions
function getIsLazyQueryAssertion(
  baseOptions: any,
  isLazyQuery: boolean
): baseOptions is SimpleLazyQueryHookOptions<any, any> {
  return isLazyQuery;
}

// allow both useQuery/useLazyQuery to use nearly same code at the expense of some minimal type safety
const useSimpleCacheBase = (
  func: any,
  baseOptions:
    | QueryHookOptions<any, any>
    | SimpleLazyQueryHookOptions<any, any> = {} as SimpleLazyQueryHookOptions<any, any>,
  isLazyQuery: boolean
): SimpleCacheQueryResult<any, any> => {
  const isLazyQueryAssertion = getIsLazyQueryAssertion(baseOptions, isLazyQuery);
  const fetchTimeVariablesRef = useRef<object>();

  const getVariablesString = () =>
    JSON.stringify({
      ...baseOptions.variables,
      ...fetchTimeVariablesRef.current,
    });

  const getCacheEntry = () => {
    const functionInMap = hookFunctionToArgsToResultMap.get(func);
    return functionInMap?.get(getVariablesString());
  };

  // create cache entry if it doesn't exist for use later
  const assertCacheEntry: () => Result = () => {
    if (!hookFunctionToArgsToResultMap.has(func)) {
      hookFunctionToArgsToResultMap.set(func, new Map());
    }

    const functionMap = hookFunctionToArgsToResultMap.get(func);
    const variablesString = getVariablesString();

    if (!functionMap!.has(variablesString)) {
      functionMap!.set(variablesString, {} as Result);
    }

    const result = functionMap!.get(variablesString);

    return result as Result;
  };

  const renderCacheEntry = getCacheEntry();

  const finalOptions = {
    ...baseOptions,
    // this prevents apollo from using it's slow / complicated cache which is the whole point of this wrapper
    fetchPolicy: 'no-cache',
    skip: (!isLazyQueryAssertion && baseOptions?.skip) || !!renderCacheEntry,
    onCompleted: (data: any) => {
      const cache = assertCacheEntry();
      cache.data = data;
      baseOptions.onCompleted?.(data);
    },
    onError: (error: any) => {
      const cache = assertCacheEntry();
      cache.error = error;
      baseOptions.onError?.(error);
    },
  };

  const funcResult = func(finalOptions);

  const finalResult = isLazyQueryAssertion ? funcResult[1] : funcResult;
  const fetch = isLazyQueryAssertion ? funcResult[0] : finalResult.refetch;

  const wrappedFetch = useCallback(
    (fetchOptions: SimpleLazyQueryHookOptions<any, any> = {} as any) => {
      fetchTimeVariablesRef.current = fetchOptions?.variables;
      const cacheEntry = getCacheEntry();

      // serve from cache on lazy queries if we have it and its allowed
      if (isLazyQueryAssertion && fetchOptions?.allowFromCache && cacheEntry?.data) {
        finalOptions.onCompleted?.(cacheEntry?.data);
        fetchOptions.onCompleted?.(cacheEntry.data);
        return Promise.resolve(cacheEntry.data);
      }

      // apollo's lazyQueries unexpectedly don't call base queryOptions onCompleted/onError if fetch has it specified. normalize this confusing behavior.
      if (isLazyQueryAssertion) {
        const origOnCompleted = fetchOptions.onCompleted;
        fetchOptions.onCompleted = data => {
          origOnCompleted?.(data);
          finalOptions?.onCompleted(data);
        };
        const origOnError = fetchOptions.onError;
        fetchOptions.onError = data => {
          origOnError?.(data);
          finalOptions?.onError(data);
        };
      }
      return fetch(fetchOptions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch]
  );

  return {
    error: finalResult.error || renderCacheEntry?.error,
    refetch: wrappedFetch,
    loading: finalResult.loading,
    data: finalResult.data || renderCacheEntry?.data,
    previousData: finalResult.loading ? renderCacheEntry : null,
    called: finalResult.called || !!renderCacheEntry,
  };
};

/**
 * Wrap useQuery with a simple cache that doesn't have performance issues like apollo's does
 * @param useQueryHook - the original useQuery from apollo
 * @returns useQuery wrapped with simple cache
 */
export const withSimpleCache = <TData, TVariables>(
  useQueryHook: (baseOptions: QueryHookOptions<TData, TVariables>) => QueryResult<TData, TVariables>
): ((
  baseOptions?: Omit<QueryHookOptions<TData, TVariables>, 'fetchPolicy'>
) => SimpleCacheQueryResult<TData, TVariables>) => baseOptions => {
  return useSimpleCacheBase(useQueryHook, baseOptions, false);
};

/**
 * Wrap useLazyQuery with a simple cache that doesn't have performance issues like apollo's does
 * @param useLazyQueryHook - the original useLazyQuery from apollo
 * @returns useLazyQuery wrapped with simple cache
 */
export const withLazySimpleCache = <TData, TVariables>(
  useLazyQueryHook: (
    baseOptions?: Omit<LazyQueryHookOptions<TData, TVariables>, 'fetchPolicy'>
  ) => LazyQueryResultTuple<TData, TVariables>
): ((
  baseOptions?: Omit<LazyQueryHookOptions<TData, TVariables>, 'fetchPolicy'>
) => SimpleLazyQueryResultTuple<TData, TVariables>) => baseOptions => {
  const result = useSimpleCacheBase(useLazyQueryHook, baseOptions, true);
  return [result.refetch as any, result];
};

// map[GQL AST][GQL variables stringified] = { data, error }
const astToArgsToResultMap = new Map<
  DocumentNode | TypedDocumentNode<any, any>,
  Map<string, any>
>();

/**
 * make a GQL network query using simple cache if the data is already cached instead of apollo's cache which has performance issues
 * @param client - apollo client
 * @param baseOptions - client options
 * @returns promise of data via a network call or from the cache
 */
export const simpleCacheQuery = async <TVariables, TData = any>(
  client: ApolloClient<any>,
  baseOptions: Omit<QueryOptions<TVariables, TData>, 'fetchPolicy'>
): Promise<TData> => {
  const keyInMap = astToArgsToResultMap.get(baseOptions.query);
  const variablesString = JSON.stringify(baseOptions.variables);
  const cachedResult = keyInMap?.get(variablesString);

  if (cachedResult) {
    return Promise.resolve(cachedResult);
  }

  const result = await client.query({ ...baseOptions, fetchPolicy: 'no-cache' });

  keyInMap?.set(variablesString, result.data);

  return result.data;
};
