import React, { useState } from 'react';

import { Box, Link, Stack } from '@rbilabs/universal-components';
import { filter } from 'lodash-es';

import { useAnchorContext } from 'utils/static-page/hooks/useAnchorContext';

const AnchorLinks = Stack.withConfig({
  flexWrap: {
    base: 'nowrap',
    md: 'wrap',
  },
  alignItems: 'center',
  direction: { base: 'column', md: 'row' },
  justifyContent: 'center',
  space: '$4',
  width: {
    base: '90%',
    md: '100%',
  },
  margin: 'auto',
  paddingY: '$5',
  paddingX: {
    md: '$24',
  },
  borderTopWidth: '2',
  borderBottomWidth: '2',
  borderColor: Styles.color.grey.five,
});

const AnchorLinkItem = Link.withConfig({
  isUnderlined: false,
  padding: { base: '$0', md: '$1' },
});

const AnchorLinksWidget = props => {
  const { scrollToTarget, formatLocaleAnchorName } = useAnchorContext();
  const [currentPressedLink, setcurrentPressedLink] = useState();

  return (
    <Box marginY="$4">
      <AnchorLinks>
        {filter(props.widgets, ['_type', 'anchorWidget']).map(anchor => {
          const anchorName = anchor.anchorName?.locale;

          return (
            <AnchorLinkItem
              // href={`#${formatLocaleAnchorName(anchor.anchorName?.locale)}`} TODO: RN - ensure web/browser anchor navigation works
              key={anchor._key}
              isUnderlined={currentPressedLink === anchor.anchorName?.locale}
              onPress={() => {
                // TODO: RN move to a better place in the navigation/route handling logic? href/router currently creates exception when pressed
                setcurrentPressedLink(anchorName);
                scrollToTarget(formatLocaleAnchorName(anchorName));
              }}
            >
              {anchor.anchorName?.locale}
            </AnchorLinkItem>
          );
        })}
      </AnchorLinks>
    </Box>
  );
};

export default AnchorLinksWidget;
