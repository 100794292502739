import React from 'react';

import {
  Pressable,
  PressableProps,
  Text,
  TextProps,
  makeUclComponent,
} from '@rbilabs/universal-components';

const TextContainer = Text.withConfig({
  fontFamily: 'heading',
});

type Props = PressableProps & {
  _text?: TextProps;
};

export default makeUclComponent(function UnstyledButton({ children, _text, ...rest }: Props) {
  return (
    <Pressable {...rest}>
      {typeof children === 'string' ? (
        <TextContainer {...(_text || {})}>{children}</TextContainer>
      ) : (
        children
      )}
    </Pressable>
  );
});
