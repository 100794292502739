import { IFeatureLoyaltyUiQuery, useFeatureLoyaltyUiQuery } from 'generated/sanity-graphql';

interface IUseFeatureLoyaltyUi {
  featureLoyaltyUiLoading: boolean;
  featureLoyaltyUi: IFeatureLoyaltyUiQuery['LoyaltyUI'];
}

export const FEATURE_LOYALTY_UI_SINGLETON = 'feature-loyalty-ui-singleton';

export const useFeatureLoyaltyUi = (): IUseFeatureLoyaltyUi => {
  const { data, loading } = useFeatureLoyaltyUiQuery({
    variables: { featureLoyaltyUIId: FEATURE_LOYALTY_UI_SINGLETON },
  });

  const featureLoyaltyUi = data?.LoyaltyUI ?? null;

  return { featureLoyaltyUiLoading: loading, featureLoyaltyUi };
};
