import { Operation, Reference } from '@apollo/client';
import { CanReadFunction, ToReferenceFunction } from '@apollo/client/cache/core/types/common';

export const readCmsOffers = ({
  toReference,
  canRead,
  offerEngineIds,
  typename,
}: {
  toReference: ToReferenceFunction;
  canRead: CanReadFunction;
  offerEngineIds: string[] | undefined;
  typename: 'OfferTemplate' | 'ConfigOffer';
}) => {
  // checks the references of offer details with the ids passed to the query
  const refs = offerEngineIds?.map((id: string | undefined) =>
    toReference({ __typename: typename, loyaltyEngineId: id })
  );

  // if every reference exists, returns the data from the cache
  if (refs?.every((ref: Reference | undefined) => canRead(ref))) {
    return refs;
  }

  // if the reference does not exist, it executes the query over the network
  return undefined;
};

// Only put mutations in this list you are sure are safe to retry aka NOT "CreateOrder" etc.
const MUTATION_RETRY_ALLOW_LIST = ['PriceOrder', 'UpdateMe', 'CreateOTP', 'ValidateOTP'];

export const getGqlQueryInfo = (operation: Operation) => {
  const operationType = (operation?.query?.definitions?.[0] as any)?.operation as
    | 'mutation'
    | 'query';
  const operationName = operation?.operationName;

  const isRetryAllowed =
    operationType === 'query' ? true : MUTATION_RETRY_ALLOW_LIST.includes(operationName);

  return { operationType, operationName, isRetryAllowed };
};
