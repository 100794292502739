import * as React from 'react';
import { FC } from 'react';

interface IShortCodeOverrideModalProps {
  onConfirm: () => void;
  onDismiss?: () => void;
  onCancel?: () => void;
}

const ShortCodeOverrideModal: FC<React.PropsWithChildren<IShortCodeOverrideModalProps>> = () => {
  return null;
};

export default ShortCodeOverrideModal;
