import React, { useCallback, useEffect, useState } from 'react';

import { Box, FlatList, Pressable, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';
import { LayoutChangeEvent } from 'react-native';

import Picture from 'components/picture';
import { MenuObjectTypes } from 'enums/menu';
import { useIsMobileBreakpoint } from 'hooks/use-media-query';
import { theme } from 'styles/configure-theme';

import { DropdownArrow, defaultOrientation, defaultSize } from './dropdown-arrow';
import ProductCard from './product-card';
import { IAccordion, IProduct } from './types';

const Container = Box.withConfig<{ hasBorderTop: boolean }>(p => ({
  borderTopWidth: p.hasBorderTop ? '1' : null,
  borderTopColor: theme.token('border-color-default'),
}));

const Header = Pressable.withConfig({
  flexDirection: 'row',
  alignItems: 'center',
  margin: 0,
  paddingY: '$3',
  width: 'full',
});

const TextContainer = Box.withConfig({
  _text: {
    textAlign: 'left',
  },

  paddingLeft: {
    base: '$2',
    md: '$3',
  },
});

const DisabledText = Text.withConfig<{ disabled: boolean }>(p => ({
  color: p.disabled ? theme.token('text-disabled') : null,
}));

const TitleContainer = Box.withConfig({
  flexDirection: 'row',
  alignItems: 'center',
  flex: 1,
});

const TitleText = DisabledText.withConfig({
  variant: 'headerTwo',
  margin: 0,
});

const CountText = DisabledText.withConfig({
  variant: 'headerTwo',
  marginLeft: '$2',
  flexShrink: 1,
  margin: 0,
});

const Title: React.FC<React.PropsWithChildren<{
  title: string;
  count: number;
  disabled: boolean;
}>> = ({ title, count, disabled }) => (
  <TitleContainer>
    <TitleText disabled={disabled}>{title}</TitleText>
    <CountText disabled={disabled}>{`(${count})`}</CountText>
  </TitleContainer>
);

const Caption = DisabledText.withConfig({
  margin: 0,
  variant: 'copyTwo',
});

const PreviewPicture = Picture.withConfig<{
  disabled: boolean;
}>(p => ({
  flexShrink: 0,
  opacity: p.disabled ? '0.7' : '1',

  width: {
    base: '$3',
    md: '$16',
  },

  height: {
    base: '$2.5',
    md: '$16',
  },

  marginLeft: {
    base: '$1',
    md: '$3',
  },
}));

export const Accordion: React.FC<React.PropsWithChildren<IAccordion>> = ({
  parentMenuObject,
  products,
  startsOpen,
  hasBorderTop = true,
}) => {
  const { formatMessage } = useIntl();
  const isMobile = useIsMobileBreakpoint();
  const [itemWidth, setItemWidth] = useState<number>();
  const NUM_COLUMNS = isMobile ? 1 : 3;
  const ITEM_MARGIN = 2;
  const disabled = products.length === 0;
  const [open, setOpen] = useState(startsOpen);

  const onLayout = React.useCallback(
    (e: LayoutChangeEvent) => {
      const { width } = e.nativeEvent.layout;
      const itemSize = width / NUM_COLUMNS - ITEM_MARGIN * NUM_COLUMNS;
      setItemWidth(itemSize - ITEM_MARGIN);
    },
    [NUM_COLUMNS]
  );

  const onPressHeader = useCallback(() => {
    if (!disabled) {
      setOpen(isOpen => !isOpen);
    }
  }, [disabled]);

  useEffect(() => {
    if (disabled) {
      setOpen(false);
    }
  }, [disabled]);

  const image =
    parentMenuObject._type === MenuObjectTypes.SECTION
      ? parentMenuObject.carouselImage
      : parentMenuObject.image;

  const renderItem = React.useCallback(
    ({ item }: { item: IProduct }) => <ProductCard product={item} size={itemWidth} />,
    [itemWidth]
  );

  // Avoid rendering the list if the itemWidth is not set
  const shouldDisplay = open && typeof itemWidth === 'number';

  return (
    <Container hasBorderTop={hasBorderTop} onLayout={onLayout}>
      <Header
        onPress={onPressHeader}
        aria-expanded={open}
        disabled={disabled}
        focusable
        accessibilityRole="button"
      >
        <DropdownArrow
          open={open}
          disabled={disabled}
          size={defaultSize}
          orientation={defaultOrientation}
        />
        <PreviewPicture image={image} alt="" objectFitContain disabled={disabled} />
        <TextContainer>
          <Title
            disabled={disabled}
            title={parentMenuObject.name?.locale}
            count={products.length}
          />
          {disabled && (
            <Caption disabled={disabled}>{formatMessage({ id: 'noItemsFitCriteria' })}</Caption>
          )}
        </TextContainer>
      </Header>
      {shouldDisplay ? (
        <FlatList
          data={products}
          keyExtractor={(product: IProduct) => product._id}
          numColumns={NUM_COLUMNS}
          contentContainerStyle={{
            justifyContent: 'flex-start',
          }}
          renderItem={renderItem}
          maxToRenderPerBatch={NUM_COLUMNS}
        />
      ) : null}
    </Container>
  );
};
