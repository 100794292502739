import { forwardRef } from 'react';

import { Input as NbInput } from 'native-base';

import { addWithConfig } from '../../utils';

import type { InputProps } from './types';

export const Input = forwardRef((props: InputProps, ref) => (
  <NbInput {...props} size="lg" ref={ref} />
));

export default addWithConfig(Input);
