import useEffectOnce from 'hooks/use-effect-once';
import useInterval from 'hooks/use-interval';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';
import LocalStorage, { StorageKeys } from 'utils/local-storage';

const MINUTE_TO_MS = 60 * 1000;
const HOUR_TO_MS = 60 * MINUTE_TO_MS;

const handleResetStoreTimeout = (
  resetLastTimeStoreUpdated: (currentTime: number) => void,
  logCartStoreAndTimeout: (resetStoreTimeout: number, timeSinceLastVisit: number) => void,
  resetStoreTimeout: number,
  clearCartStoreServiceModeTimeout: () => void
) => {
  /**
   * If the reset store timeout is falsy or undefined,
   * then don't set the timer
   */
  const isDisabled = !resetStoreTimeout;

  if (isDisabled) {
    return;
  }

  const elapsedTime = resetStoreTimeout * HOUR_TO_MS;

  const lastTimeSaved = LocalStorage.getItem(StorageKeys.LAST_TIME_STORE_UPDATED);
  const currentTime = Date.now();
  const timeSinceLastVisit = currentTime - lastTimeSaved;

  const isVisitWithinElapsedTime = timeSinceLastVisit < elapsedTime;

  if (!lastTimeSaved || isVisitWithinElapsedTime) {
    resetLastTimeStoreUpdated(currentTime);
    return;
  }
  if (!isVisitWithinElapsedTime) {
    logCartStoreAndTimeout(resetStoreTimeout, timeSinceLastVisit / HOUR_TO_MS);
    clearCartStoreServiceModeTimeout();
  }
};

export const useResetStoreResetCartResetServiceModeTimeout = () => {
  const resetStoreTimeout: number | undefined = useFlag(
    LaunchDarklyFlag.RESET_CART_RESET_STORE_TIMEOUT
  );

  const { logCartStoreAndTimeout, clearCartStoreServiceModeTimeout } = useOrderContext();
  const { resetLastTimeStoreUpdated } = useStoreContext();

  useEffectOnce(() => {
    handleResetStoreTimeout(
      resetLastTimeStoreUpdated,
      logCartStoreAndTimeout,
      resetStoreTimeout,
      clearCartStoreServiceModeTimeout
    );
  });

  // check every minute
  const RESET_STORE_INTERVAL_CHECK = MINUTE_TO_MS;

  useInterval(
    () => {
      handleResetStoreTimeout(
        resetLastTimeStoreUpdated,
        logCartStoreAndTimeout,
        resetStoreTimeout,
        clearCartStoreServiceModeTimeout
      );
    },
    RESET_STORE_INTERVAL_CHECK,
    [
      resetLastTimeStoreUpdated,
      logCartStoreAndTimeout,
      resetStoreTimeout,
      clearCartStoreServiceModeTimeout,
    ]
  );
};
