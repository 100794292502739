import { Icon } from '@rbilabs/universal-components';

import { PressableItemCard } from 'components/quests/components/pressable-item-card';
import { theme } from 'styles/configure-theme';
import { primitive } from 'styles/constants/primitives';

export const PressableItem = PressableItemCard.withConfig(p => ({
  borderRadius: 'lg',
  overflow: 'visible',
  minHeight: '72px',
  ...(!p.disabled && {
    background: primitive.$white,
    shadow: '1',
  }),
}));

export const CompletedStepIcon = Icon.withConfig({
  variant: 'checkFill',
  color: theme.token('background-contrast-loyalty-reversed'),
});

export const UncompletedStepIcon = Icon.withConfig({
  variant: 'forward',
});
