import { IImageFragment } from 'generated/sanity-graphql';

export interface ILoyaltyOptInModalProps {
  cognitoId: string;
  onLoyaltyUserCreated: () => void;
}

export interface IOptInErrorContentProps {
  heading: string | undefined;
  description: string | undefined;
  image?: IImageFragment | null;
  altImage: string;
  buttonText: string | undefined;
  onActionPress: () => void;
  onDismiss: () => void;
  allowDismiss?: boolean;
}

export interface IOptInLoadingContentProps {
  heading: string;
  description: string;
}

export interface IOptInInitContentProps {
  heading: string | undefined;
  description: string | undefined;
  image?: IImageFragment | null;
  altImage: string;
  buttonText: string | undefined;
  disclaimer: any;
  onActionPress: () => void;
}

export interface OptInReducerState {
  completedOptIn: boolean;
  displaySuccessAnimation: boolean;
}

export enum OptInReducerModal {
  DISPLAY_SUCCESS_ANIMATION,
  COMPLETE_OPT_IN,
}

export type OptInReducerModalAction =
  | { type: OptInReducerModal.DISPLAY_SUCCESS_ANIMATION }
  | { type: OptInReducerModal.COMPLETE_OPT_IN };
