import { useCallback, useMemo } from 'react';

import { IItem, ISanityTextNode } from '@rbi-ctg/menu';
import { useFeatureMenu } from 'hooks/use-feature-menu';
import { useMainMenuContext } from 'state/menu/main-menu';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { isAvailableForActiveDayParts, itemIsAvailable } from 'utils/availability';

import { getPosVendorFromStore } from './menu/get-pos-vendor-from-store';

export interface IAddOnSectionOption {
  _key: string;
  maxAmount: number;
  option: IItem;
}
export interface IAddOnSection {
  _id: string;
  maxAmount: number;
  name: ISanityTextNode;
  options: IAddOnSectionOption[];
}

const useFeatureMenuAddOn = () => {
  const { serviceMode } = useServiceModeContext();
  const { store, prices } = useStoreContext();
  const { featureMenu, featureMenuLoading } = useFeatureMenu();
  const { activeDayParts } = useMainMenuContext();

  const filterAvailableOptions = useCallback(
    (options: IAddOnSectionOption[]) => {
      const vendor = getPosVendorFromStore(store, serviceMode);
      return options.filter(option => {
        const isAvailableDayPart = activeDayParts.length
          ? isAvailableForActiveDayParts({ activeDayParts, menuData: option.option })
          : true;
        const isPriceAvailable = itemIsAvailable(option.option, vendor, prices);
        return isPriceAvailable && isAvailableDayPart;
      });
    },
    [prices, serviceMode, store, activeDayParts]
  );

  const sections: IAddOnSection[] = useMemo(() => {
    if (!featureMenu?.cartAddOnSections) {
      return [];
    }

    return (featureMenu.cartAddOnSections as IAddOnSection[]).reduce(
      (acc: IAddOnSection[], section: IAddOnSection) => {
        const availableOptions = filterAvailableOptions(section?.options ?? []);
        // Returning section only if it has available options
        return availableOptions.length ? [...acc, { ...section, options: availableOptions }] : acc;
      },
      []
    );
  }, [featureMenu, filterAvailableOptions]);

  return {
    sections,
    loading: featureMenuLoading,
  };
};

export default useFeatureMenuAddOn;
