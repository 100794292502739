import * as React from 'react';

import { HStack, Text } from '@rbilabs/universal-components';
import isNumber from 'lodash/isNumber';

import { ISanityImage } from '@rbi-ctg/menu';
import Picture from 'components/picture';
import { useQuestContext } from 'components/quests/loyalty-quest';
import { isIncentiveRedeemed, isQuestCompleted } from 'components/quests/loyalty-quest/utils';
import { formatCompletedChallengeMessage } from 'components/quests/use-parse-quest-card-data/utils';
import { IImageFragment } from 'generated/sanity-graphql';

import { QuestPointsPicture } from './quest-points-picture';

export const QuestPointsIncentive = ({
  description,
  image,
  challengeCompletedMessage,
}: {
  description: string;
  image?: ISanityImage | IImageFragment | null;
  challengeCompletedMessage?: string | null | undefined;
}) => {
  const { quest } = useQuestContext();
  const completedMessage = formatCompletedChallengeMessage({
    description: challengeCompletedMessage,
    quest,
  });

  return (
    <HStack space="$4">
      {!image && isNumber(quest.bonusPoints) && (
        <QuestPointsPicture bonusPoints={quest.bonusPoints} />
      )}
      {image && <Picture width="$16" height="$16" image={image} alt={description} />}
      <Text flexShrink={1} color="white">
        {isQuestCompleted(quest.status) && isIncentiveRedeemed(quest.lastCompletion)
          ? completedMessage
          : description}
      </Text>
    </HStack>
  );
};
