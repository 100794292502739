import React from 'react';

import { Svg, SvgPath, SvgProps } from '@rbilabs/universal-components';

export const ResetLocationIcon: React.FC<React.PropsWithChildren<SvgProps>> = props => (
  <Svg width={23} height={23} viewBox="0 0 23 23" fill="none">
    <SvgPath
      d="M.368 11.627c.398.399.797.524 1.583.524l8.625.046c.08 0 .147 0 .182.045.034.034.045.103.045.171l.034 8.636c.012.786.137 1.185.536 1.584.536.546 1.288.455 1.846-.091.296-.297.535-.786.752-1.242l8.716-18.798c.455-.957.398-1.663-.068-2.13-.457-.456-1.163-.513-2.12-.058L1.7 9.03c-.456.216-.945.456-1.242.752-.546.558-.637 1.299-.09 1.845"
      fill="#502314"
    />
  </Svg>
);
