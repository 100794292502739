import { useCallback, useEffect } from 'react';

import { IRestaurant } from '@rbi-ctg/store';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import { useServiceModeStatus } from 'hooks/use-service-mode-status';
import { getRestaurantsByStoreNumber } from 'remote/api/restaurants';
import { useNetworkContext } from 'state/network';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { addLoggingContext } from 'utils/logger';
import { mergeRestaurantData, useGetRestaurantFn } from 'utils/restaurant';
import { routes } from 'utils/routing';

import { IStoreContext } from './types';
import { usePosDataQuery } from './use-pos-data-query';

export const resetLastTimeStoreUpdated: IStoreContext['resetLastTimeStoreUpdated'] = currentTime =>
  LocalStorage.setItem(StorageKeys.LAST_TIME_STORE_UPDATED, currentTime || Date.now());

export const useStoreUtils = ({
  resetStore,
  store,
}: {
  resetStore: IStoreContext['resetStore'];
  store: IRestaurant;
}) => {
  const { navigate } = useNavigation();
  const { pathname } = useRoute();
  const { sanityEndpoints } = useNetworkContext();
  const getRestaurant = useGetRestaurantFn();

  const { data, isLoading: isPosDataLoading, refetch: refetchPosData } = usePosDataQuery({
    storeNumber: store.number ?? null,
  });

  const prices = store?._id && data?.posData && !isPosDataLoading ? data.posData : null;

  const storeEmail = store.email?.[0] ?? '';

  const { serviceModeStatus, isRestaurantAvailable } = useServiceModeStatus(store);

  const onConfirmLocateRestaurants = useCallback(() => {
    resetStore();

    if (pathname !== routes.storeLocator) {
      navigate(routes.storeLocator);
    }
  }, [pathname, navigate, resetStore]);

  const fetchStore = useCallback(
    async (storeId: string) => {
      const rbiRestaurant = await getRestaurant(storeId);

      if (!rbiRestaurant) {
        return;
      }

      const isRestaurantPosAvailable = rbiRestaurant?.available;

      if (!isRestaurantPosAvailable) {
        onConfirmLocateRestaurants();
        return;
      }

      const [sanityStore] = await getRestaurantsByStoreNumber(sanityEndpoints.groq, {
        storeNumber: storeId,
      });

      return mergeRestaurantData({ rbiRestaurant: rbiRestaurant as IRestaurant, sanityStore });
    },
    [onConfirmLocateRestaurants, sanityEndpoints.groq] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    const storeId = store.number ?? 'No store selected';

    addLoggingContext({
      storeId,
    });

    if (!store._id) {
      return;
    }
  }, [store]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    email: storeEmail,
    fetchStore,
    isRestaurantAvailable,
    prices,
    isPosDataLoading,
    refetchPosData,
    resetLastTimeStoreUpdated,
    serviceModeStatus,
  };
};
