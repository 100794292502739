import { useCallback } from 'react';

import { ISanityLocaleBlockContent, ISanityTextNode } from '@rbi-ctg/menu';
import { useMenuVariant } from 'experiments/menu-variant/use-menu-variant';
import { useFeatureMenu } from 'hooks/use-feature-menu';

import { IContentOptionsProperties, IMenuObjectWithContentOptions } from './types';

export * from './types';

/**
 * Returns the contentOptions overrides of a menu object, if any.
 *
 * If no contentOption matches the current Variant Menu key, the default properties are used.
 *
 * @param menuObject Section/Combo/Picker/Item
 * @param menuVariantReferenceId the target menu variant sanity id
 * @returns An object with all properties with contentOptions override (if applicable)
 */
function getVariantContentOptions({
  menuObject,
  menuVariantReferenceId,
}: {
  menuObject: IMenuObjectWithContentOptions;
  menuVariantReferenceId: string;
}): IContentOptionsProperties {
  const {
    contentOptions,
    description: defaultDescription,
    image: defaultImage,
    name: defaultName,
  } = menuObject;

  const contentOptionOverride = (contentOptions || []).find(
    contentOption => contentOption?.menuVariantReference?._id === menuVariantReferenceId
  );

  // @TODO: BKPE-2200 force casting types to avoid TS errors, we need to stop using menu.d.ts
  return {
    description:
      (contentOptionOverride?.description as ISanityLocaleBlockContent) || defaultDescription,
    image: contentOptionOverride?.image || defaultImage,
    name: (contentOptionOverride?.name as ISanityTextNode) || defaultName,
  };
}

export const useGetVariantContentOptions = () => {
  const { featureMenu } = useFeatureMenu();
  const menuVariant = useMenuVariant(featureMenu);
  const menuVariantReferenceId = menuVariant?.menuVariantId ?? '';
  return useCallback(
    (menuObject: IMenuObjectWithContentOptions) =>
      getVariantContentOptions({
        menuObject,
        menuVariantReferenceId,
      }),
    [menuVariantReferenceId]
  );
};
