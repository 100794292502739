import { Dispatch, SetStateAction } from 'react';

import { Nullable } from '@rbi-ctg/frontend';
import { ICartEntry, IPrices } from '@rbi-ctg/menu';
import { IRestaurant } from '@rbi-ctg/store';
import { IPhysicalAddress } from 'generated/graphql-gateway';
import { IUseDialogProps } from 'hooks/use-dialog-modal';
import { ServiceMode, ServiceModeStatus } from 'state/service-mode/types';
import { OrderingUnavailable } from 'state/store';

import { FetchPosDataFn } from './use-pos-data-query';

/**
 * The possible states of a restaurant based on:
 * 1. Availability (whether the restaurant and the restaurants service mode is configured correctly)
 * 2. The hours of the store
 */
export enum StorePermissions {
  OPEN_AND_AVAILABLE = 'openAndAvailable',
  OPEN_AND_UNAVAILABLE = 'openAndUnavailable',
  CLOSED = 'closed',
  NO_STORE_SELECTED = 'noStoreSelected',
}

export type SelectedUnavailableStore = {
  fastestServiceMode?: ServiceMode;
  hasSelection: false;
  physicalAddress: IPhysicalAddress;
  selectedUnavailableStoreName: string;
  chaseMerchantId: '';
  // Allows us to show/query data for a store that is unavailable
  selectedUnavailableStoreNumber: null | string;
};

export interface ISelectStoreOptions {
  sanityStore: IRestaurant;
  hasCartItems: boolean;
  callback: () => void;
  selectedOffer: any;
  requestedServiceMode?: ServiceMode;
  unavailableCartEntries?: ICartEntry[];
  skipRedirection?: boolean;
  skipChangeStoreMessage?: boolean;
}
interface IStoreStatusFlags {
  isStoreOpenAndAvailable: boolean;
  isStoreOpenAndUnavailable: boolean;
  isStoreClosed: boolean;
  noStoreSelected: boolean;
}

export interface OnConfirmStoreChangeParams {
  callback: () => void;
  newStore: IRestaurant;
  unavailableCartEntries?: ICartEntry[];
  message?: string;
}

export interface IStoreContext extends IStoreStatusFlags {
  email: string;
  isRestaurantAvailable(restaurant: IRestaurant): Promise<boolean>;
  openOrderingUnavailableDialog(): void;
  // FIXME: these 3 lines should be removed when we make the refactor for the store locator
  orderingUnavailableOpen: OrderingUnavailable;
  onConfirmLocateRestaurants(): void;
  onDismissStoreUnavailable(): void;
  prices: IPrices;
  isPosDataLoading: boolean;
  refetchPosData: FetchPosDataFn;
  resetStore(): void;
  selectStore(options: ISelectStoreOptions): Promise<void>;
  fetchStore: (storeId: string) => Promise<void>;
  setStore: Dispatch<SetStateAction<IRestaurant>>;
  selectUnavailableStore(store: IRestaurant): void;
  resetLastTimeStoreUpdated(currentTime?: number): void;
  serviceModeStatus: ServiceModeStatus;
  getStoreStatusFlags: (
    store: IRestaurant | null,
    serviceMode?: Nullable<ServiceMode>
  ) => IStoreStatusFlags;
  store: IRestaurant;
  onConfirmStoreChange: IUseStore['onConfirmStoreChange'];
  onConfirmStoreChangeParams: OnConfirmStoreChangeParams | null;
  clearOnConfirmStoreChangeParams: () => void;
  isSelectingStore: boolean;
  StoreChangeDialog: React.FC<IUseDialogProps>;
}

export interface IPreloadedStoreState {
  prices: IPrices;
  store: IRestaurant | null;
}

export type IUseStore = {
  onConfirmStoreChange(args: OnConfirmStoreChangeParams | undefined | null): void;
  storeProxy: IRestaurant;
  store: IRestaurant | null;
  setStore: Dispatch<SetStateAction<IRestaurant | null>>;
} & Pick<IStoreContext, 'resetStore' | 'selectUnavailableStore'>;

export type curriedGetStoreStatus = (args: {
  store: IRestaurant;
  serviceModeStatuses: ServiceModeStatus;
  selectedServiceMode?: Nullable<ServiceMode>;
}) => (serviceMode?: Nullable<ServiceMode>) => StorePermissions;
