import { FC, PropsWithChildren } from 'react';

import { useIntl } from 'react-intl';

import {
  BenefitText,
  Container,
  ContentText,
  InfoBadge,
  InfoContainer,
  InfoText,
  SectionTitle,
  StyledImage,
  Title,
} from './styled';

const walmartMembershipImg = require('./walmart-membership-image.png');

type WalmartMembershipProps = {
  title?: string;
};

export const WalmartMembership: FC<PropsWithChildren<WalmartMembershipProps>> = ({ title }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <SectionTitle>{title || formatMessage({ id: 'dontHaveWalmartAccount' })}</SectionTitle>
      <Container>
        <StyledImage source={walmartMembershipImg} />
        <Title>{formatMessage({ id: 'royalPerksMembersEnjoy' })}</Title>
        <BenefitText>{formatMessage({ id: 'walmartPlusBenefit' })}</BenefitText>
        <ContentText>{formatMessage({ id: 'walmartMembershipSignUp' })}</ContentText>
        <InfoContainer>
          <InfoBadge>
            <InfoText>{formatMessage({ id: 'walmartMembershipDeal' })}</InfoText>
          </InfoBadge>
        </InfoContainer>
      </Container>
    </>
  );
};
