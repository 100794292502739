import { HStack, Header, VStack } from '@rbilabs/universal-components';

import Picture from 'components/picture';

export const ListContainer = VStack.withConfig(props => ({
  space: '$4',
  ...props,
}));

export const StyledTileContainer = HStack.withConfig({
  flexDirection: 'row',
  alignItems: 'center',
  minHeight: { base: '80px', md: '112px' },
  padding: '$4',
  borderRadius: 'lg',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
});

export const TileImage = Picture.withConfig({
  maxWidth: { base: '40px', md: '60px' },
  maxHeight: { base: '40px', md: '60px' },
  marginRight: {
    base: 0,
    md: '$6',
  },
});

export const TileTitle = Header.withConfig({
  variant: 'headerThree',
  color: Styles.color.black,
  margin: 0,
});
