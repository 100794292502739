import { LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';

import { createUseLazyQueryHook, createUseQueryHook } from './create-query-hooks';

export const useSanityQuery = createUseQueryHook();
export const useLazySanityQuery = createUseLazyQueryHook();

// for apollo codegen compatibility
export { useSanityQuery as useQuery, useLazySanityQuery as useLazyQuery };
export type { LazyQueryHookOptions, QueryHookOptions };
