import React from 'react';

import { Box, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { IBaseProps } from '@rbi-ctg/frontend';

import { AllergenGroup } from './types';
import { allergenIncludesToIntlId } from './utils';

const AllergensText = Text.withConfig({
  variant: 'copyTwo',
  fontWeight: 'bold',
  fontSize: 'sm',
});

export interface IAllergensProps extends IBaseProps {
  allergenGroups: AllergenGroup[];
}

export const Allergens: React.FC<React.PropsWithChildren<IAllergensProps>> = ({
  allergenGroups,
}) => {
  const { formatMessage } = useIntl();
  if (!allergenGroups.length) {
    return null;
  }

  return (
    <Box testID="allergens">
      {allergenGroups.map(group => {
        const id = allergenIncludesToIntlId(group.allergenIncludes);
        if (!id) {
          return null;
        }
        const allNames = group.allergenNames.join(', ');
        // TODO: RN - Nested text is not overriding the fontWeight
        return (
          <AllergensText key={id}>
            {formatMessage(
              { id },
              {
                allergens: (
                  <Text fontWeight="normal" textTransform="capitalize">
                    {allNames}
                  </Text>
                ),
              }
            )}
          </AllergensText>
        );
      })}
    </Box>
  );
};
