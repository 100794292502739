import { StoreLocatorLoader } from 'components/store-locator-empty-states/look-animation';
import { BurgerInHandSvg } from 'components/svgs/burger-in-hand';
import { FriesSvg } from 'components/svgs/fries';
import { SadSvg } from 'components/svgs/sad';
import { SpillSvg } from 'components/svgs/spill';
import { OrderLocatorTab } from 'state/launchdarkly/variations';

import {
  EmptyStateActions,
  EmptyStateCategories,
  IEmptyStateContent,
  IEmptyStateTabOptions,
} from './types';

export const DEFAULT_CONTENT: IEmptyStateContent = {
  icon: FriesSvg,
  titleId: 'storeLocatorEmptyStateDefaultTitle',
  bodyId: 'storeLocatorEmptyStateDefaultBody',
};

export const EMPTY_STATES_CONTENT: IEmptyStateTabOptions = {
  [OrderLocatorTab.NEARBY]: {
    [EmptyStateCategories.NO_STORES]: {
      icon: SadSvg,
      titleId: 'storeLocatorEmptyStateNearbyNoStoresTitle',
      bodyId: 'storeLocatorEmptyStateNearbyNoStoresBody',
    },
    [EmptyStateCategories.NO_LOCATION]: {
      icon: StoreLocatorLoader,
      titleId: 'storeLocatorEmptyStateNearbyNoLocationTitle',
      bodyId: 'storeLocatorEmptyStateNearbyNoLocationBody',
      action: EmptyStateActions.SHARE_LOCATION,
    },
    [EmptyStateCategories.ERROR]: {
      icon: SpillSvg,
      titleId: 'storeLocatorEmptyStateNearbyErrorTitle',
      bodyId: 'storeLocatorEmptyStateNearbyErrorBody',
    },
  },
  [OrderLocatorTab.FAVORITE]: {
    [EmptyStateCategories.NO_AUTH]: {
      icon: BurgerInHandSvg,
      titleId: 'storeLocatorEmptyStateFavoritesNoAuthTitle',
      bodyId: 'storeLocatorEmptyStateFavoritesNoAuthBody',
      action: EmptyStateActions.LOGIN,
    },
    [EmptyStateCategories.NO_STORES]: {
      icon: BurgerInHandSvg,
      titleId: 'storeLocatorEmptyStateFavoritesNoStoresTitle',
      bodyId: 'storeLocatorEmptyStateFavoritesNoStoresBody',
    },
    [EmptyStateCategories.ERROR]: {
      icon: SpillSvg,
      titleId: 'storeLocatorEmptyStateFavoritesErrorTitle',
      bodyId: 'storeLocatorEmptyStateFavoritesErrorBody',
    },
  },
  [OrderLocatorTab.RECENT]: {
    [EmptyStateCategories.NO_AUTH]: {
      icon: FriesSvg,
      titleId: 'storeLocatorEmptyStateRecentNoAuthTitle',
      bodyId: 'storeLocatorEmptyStateRecentNoAuthBody',
      action: EmptyStateActions.LOGIN,
    },
    [EmptyStateCategories.NO_STORES]: {
      icon: FriesSvg,
      titleId: 'storeLocatorEmptyStateRecentNoStoresTitle',
      bodyId: 'storeLocatorEmptyStateRecentNoStoresBody',
    },
    [EmptyStateCategories.ERROR]: {
      icon: SpillSvg,
      titleId: 'storeLocatorEmptyStateRecentErrorTitle',
      bodyId: 'storeLocatorEmptyStateRecentErrorBody',
    },
  },
};
