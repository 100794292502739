import { useCallback, useEffect, useMemo, useState } from 'react';

import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  OrderLocatorTab,
  OrderLocatorTabVariations,
  defaultOrderLocatorTabVariation,
} from 'state/launchdarkly/variations';
import { CustomEventNames, useMParticleContext } from 'state/mParticle';

import { IStoreLocatorTabs, IStoreLocatorTabsProps, LocatorTabIds } from '../types';

/**
 * @name orderTabsForUnauthenticatedUser
 * @description we always want to show the NEARBY tab first
 *  for unauthed users while still preserving the custom order
 *  and visibility based on LD.
 */
const orderTabsForUnauthenticatedUser = (ldTabsOrder: OrderLocatorTab[]) => [
  OrderLocatorTab.NEARBY,
  ...ldTabsOrder.filter(tab => tab !== OrderLocatorTab.NEARBY),
];

export default function useStoreLocatorTabs({
  errorNearby,
  errorFavs,
  errorRecent,
}: IStoreLocatorTabsProps): IStoreLocatorTabs {
  const { isAuthenticated } = useAuthContext();
  const { logNavigationClick } = useMParticleContext();
  const locatorTabsOrder =
    useFlag<OrderLocatorTabVariations>(LaunchDarklyFlag.ORDER_LOCATOR_TABS) ||
    defaultOrderLocatorTabVariation;
  const [currentTabIndex, setTabIndex] = useState(0);
  const [currentTabKey, setTabKey] = useState(OrderLocatorTab.NEARBY);
  // Track whether or not the current tab is in an error state
  const [currentTabHasError, setCurrentTabHasError] = useState(false);

  // TODO: potentially also track tab empty state status here?
  const ldTabData: OrderLocatorTab[] = useMemo(() => Object.values(locatorTabsOrder), [
    locatorTabsOrder,
  ]);

  const locatorTabsEnabledAndOrdered = isAuthenticated
    ? ldTabData
    : orderTabsForUnauthenticatedUser(ldTabData);

  /**
   * @name locatorTabIds
   *  maps all possible tabs with their index in the final order
   *  any non-visible tabs have a value of -1, but are necessary
   *  to have in the map for type safety and ease of use by consumers
   */
  const locatorTabIds = Object.keys(OrderLocatorTab).reduce((acc, cur) => {
    const tabIdx = locatorTabsEnabledAndOrdered.indexOf(OrderLocatorTab[cur]);
    acc[OrderLocatorTab[cur]] = tabIdx;
    return acc;
  }, {} as LocatorTabIds);

  const currentTab = locatorTabsEnabledAndOrdered[currentTabIndex];

  const handleTabChange = useCallback(
    (newValue: number) => {
      const newTabName = locatorTabsEnabledAndOrdered[newValue];
      logNavigationClick(CustomEventNames.BUTTON_CLICK_STORE_LOCATOR_TAB, {
        Tab: newTabName,
      });

      setTabIndex(newValue);
      setTabKey(newTabName);
    },
    [locatorTabsEnabledAndOrdered, logNavigationClick]
  );

  // update whether or not the current tab has an error
  // check any time the tab itself or any of the tab error states changes
  useEffect(() => {
    let tabHasErr = false;
    switch (currentTab) {
      case OrderLocatorTab.NEARBY:
        if (errorNearby) {
          tabHasErr = true;
        }
        break;
      case OrderLocatorTab.FAVORITE:
        if (errorFavs) {
          tabHasErr = true;
        }
        break;
      case OrderLocatorTab.RECENT:
        if (errorRecent) {
          tabHasErr = true;
        }
        break;
      default:
    }
    // TODO: should we only update if value has actually changed?
    setCurrentTabHasError(tabHasErr);
  }, [currentTab, errorFavs, errorNearby, errorRecent]);

  return {
    currentTab,
    currentTabHasError,
    currentTabIndex,
    currentTabKey,
    handleTabChange,
    locatorTabIds,
    locatorTabs: locatorTabsEnabledAndOrdered,
  };
}
