import React, { FC, useEffect } from 'react';

import { Icon } from '@rbilabs/universal-components';

import { accordionItemTestIds } from 'components/accordion/constants';
import { IUseAccordionItemProps } from 'components/accordion/types';
import { useAccordionItem } from 'components/accordion/use-accordion-item';

import {
  ContentContainer,
  SignupAccordionActivatorButton,
  SignupAccordionItem,
  SignupAccordionListItemContainer,
  SignupAccordionTitle,
} from './signup-accordion.styled';

const {
  accordionActivatorButtonTestId,
  accordionItemContentTestId,
  accordionItemTestId,
  accordionItemTitleTestId,
} = accordionItemTestIds;

export interface ISignupAccordionProps {
  title: string;
  isOpen?: boolean;
  disabled?: boolean;
  id: string;
}

export const SignupAccordion: FC<React.PropsWithChildren<
  IUseAccordionItemProps & ISignupAccordionProps
>> = ({ title, onInteract, isOpen, children, disabled = false, id, ...props }) => {
  const { handleToggle, isActive } = useAccordionItem({ onInteract });

  useEffect(() => {
    handleToggle(isOpen);
  }, [handleToggle, isOpen]);

  if (!children) {
    return null;
  }

  return (
    <SignupAccordionItem {...props} testID={accordionItemTestId}>
      <SignupAccordionActivatorButton
        onPress={() => handleToggle()}
        disabled={disabled}
        testID={accordionActivatorButtonTestId}
        aria-expanded={isActive}
        aria-controls={`${id}-section`}
      >
        <SignupAccordionListItemContainer>
          <SignupAccordionTitle testID={accordionItemTitleTestId}>{title}</SignupAccordionTitle>
          <Icon variant={isActive ? 'collapse' : 'select'} alignSelf={'flex-end'} />
        </SignupAccordionListItemContainer>
      </SignupAccordionActivatorButton>
      {isActive && (
        <ContentContainer testID={accordionItemContentTestId} aria-labelledby={id}>
          {children}
        </ContentContainer>
      )}
    </SignupAccordionItem>
  );
};
