import { useCreateLoyaltyUserMutation } from 'generated/graphql-gateway';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import logger from 'utils/logger';

import type { IUseCreateLoyaltyUserParams } from './types';

/**
 * Create the loyalty user and log the proper events depending on the request result
 */
export const useCreateLoyaltyUser = ({
  onCompleted,
  onError,
  cognitoId,
}: IUseCreateLoyaltyUserParams) => {
  const { logEvent } = useMParticleContext();

  const logMpErrorEvent = () => {
    logEvent(CustomEventNames.LOYALTY_ACKNOWLEDGEMENT, EventTypes.Other, {
      'Is Successful': false,
    });
  };

  const logMpSuccessEvent = () => {
    logEvent(CustomEventNames.LOYALTY_ACKNOWLEDGEMENT, EventTypes.Other, {
      'Is Successful': true,
    });
  };

  return useCreateLoyaltyUserMutation({
    variables: {
      cognitoId,
    },
    onCompleted: ({ createLoyaltyUser: { loyaltyId } }) => {
      if (!loyaltyId) {
        logger.error('Success response without loyalty id');
        // if the query was completed but no loyalty id returned back from the BE
        // means that the loyalty user was not created correctly
        logMpErrorEvent();
        return;
      }

      logMpSuccessEvent();

      onCompleted?.();
    },
    onError: error => {
      logMpErrorEvent();
      logger.error(`Error enrolling user: ${error.message}`);

      onError?.(error);
    },
  });
};
