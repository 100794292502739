import { differenceInYears, parse } from 'date-fns';
import { isNil } from 'lodash-es';

import { NonNullableObject } from '@rbi-ctg/frontend';

import { Identify, identify } from './amplitude-package';
import { IAmplitudeAttributes } from './types';

export function sanitizeValues<M extends object = IAmplitudeAttributes>(
  attributes: M
): NonNullableObject<Partial<M>> {
  return Object.entries(attributes).reduce((memo, [key, value]) => {
    const attrIsEmptyString = typeof value === 'string' && value.length === 0;
    if (!isNil(value) && !attrIsEmptyString) {
      memo[key] = value;
    }
    return memo;
  }, {} as NonNullableObject<Partial<M>>) as NonNullableObject<Partial<M>>;
}

export const getAge = (dob: string): number | null => {
  if (dob) {
    return differenceInYears(new Date(), parse(dob, 'yyyy-MM-dd', new Date()));
  }
  return null;
};

/**
 *
 * @param userAttributes object with any values (we'll only take the ones we care about)
 * @returns
 */
export function extractAmplitudeUserAttributes(
  userAttributes: Record<string, string | any>
): IAmplitudeAttributes {
  return {
    // CRM-Cleanup there are many duplicate user attributes that we can remove.
    // We need them for now to acheive parity with what we already have on our CRM suite.
    ...(userAttributes.adsPersonalizationOptOut && {
      adsPersonalizationOptOut: userAttributes.adsPersonalizationOptOut,
    }),
    ...(userAttributes.brand && { brand: userAttributes.brand }),
    ...(userAttributes.customerid && {
      'RBI Cognito ID': userAttributes.customerid,
      userId: userAttributes.customerid,
      customerid: userAttributes.customerid,
      customerId: userAttributes.customerid,
    }),
    ...(userAttributes.env && { env: userAttributes.env }),
    ...(userAttributes.favoriteStores && { favoriteStores: userAttributes.favoriteStores }),
    ...(userAttributes.promotionalEmails && { 'Email Opt In': userAttributes.promotionalEmails }),
    ...(userAttributes.region && { region: userAttributes.region }),
    ...(userAttributes.zipcode && { $Zip: userAttributes.zipcode, Zip: userAttributes.zipcode }),
    ...(userAttributes['Join Date'] && { 'Join Date': userAttributes['Join Date'] }),
    ...(userAttributes.language && { language: userAttributes.language }),
    ...(userAttributes.Locale && { Locale: userAttributes.Locale }),
    ...(userAttributes['Legacy User'] && { 'Legacy User': userAttributes['Legacy User'] }),
    ...(userAttributes['Location Services'] && {
      'Location Services': userAttributes['Location Services'],
    }),
    ...(userAttributes.marketingEmail && { marketingEmail: userAttributes.marketingEmail }),
    ...(userAttributes.marketingPush && { marketingPush: userAttributes.marketingPush }),
    ...(userAttributes.orderStatus && { orderStatus: userAttributes.orderStatus }),
    ...(userAttributes.rewardsEmail && { rewardsEmail: userAttributes.rewardsEmail }),
    ...(userAttributes.rewardsPush && { rewardsPush: userAttributes.rewardsPush }),
    ...(userAttributes.Timezone && { Timezone: userAttributes.Timezone }),
    ...(userAttributes['Type Preference'] && {
      'Type Preference': userAttributes['Type Preference'],
    }),
    ...(userAttributes['Snack Preference'] && {
      'Snack Preference': userAttributes['Snack Preference'],
    }),
    ...(userAttributes['Size Preference'] && {
      'Size Preference': userAttributes['Size Preference'],
    }),
    ...(userAttributes['Time Preference'] && {
      'Time Preference': userAttributes['Time Preference'],
    }),
    ...(userAttributes['IOS Location Permissions'] && {
      'IOS Location Permissions': userAttributes['IOS Location Permissions'],
      locationPermission: userAttributes['IOS Location Permissions'],
    }),
    ...(userAttributes['Android Location Permissions'] && {
      'Android Location Permissions': userAttributes['Android Location Permissions'],
      locationPermission: userAttributes['Android Location Permissions'],
    }),
    ...(userAttributes['UTM Source'] && {
      'UTM Source': userAttributes['UTM Source'],
      utmSource: userAttributes['UTM Source'],
    }),
    ...(userAttributes['UTM Medium'] && {
      'UTM Medium': userAttributes['UTM Medium'],
      utmMedium: userAttributes['UTM Medium'],
    }),
    ...(userAttributes['UTM Campaign'] && {
      'UTM Campaign': userAttributes['UTM Campaign'],
      utmCampaign: userAttributes['UTM Campaign'],
    }),
    ...(userAttributes['UTM Term'] && {
      'UTM Term': userAttributes['UTM Term'],
      utmTerm: userAttributes['UTM Term'],
    }),
    ...(userAttributes['UTM Content'] && {
      'UTM Content': userAttributes['UTM Content'],
      utmContent: userAttributes['UTM Content'],
    }),
  };
}

export const setAmplitudeUserAttributes = (userAttributes: IAmplitudeAttributes) => {
  const identifyObj = new Identify();
  for (const userAttributeKey of Object.keys(userAttributes)) {
    identifyObj.set(userAttributeKey, userAttributes[userAttributeKey]);
  }
  identify(identifyObj);
};
