import * as React from 'react';

import { HStack, Skeleton, VStack } from '@rbilabs/universal-components';

export const QuestCardSkeleton = () => {
  return (
    <VStack space="$4">
      <Skeleton height="$48" borderTopRadius="xl" />
      <Skeleton.Text lines={3} />

      <HStack justifyContent="space-between" alignItems="flex-end">
        <Skeleton rounded="full" h="$8" w="$24" />
        <Skeleton rounded="full" h="$4" w="$16" />
      </HStack>
    </VStack>
  );
};
