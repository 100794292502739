import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { isEqual } from 'lodash-es';

import { IRestaurant } from '@rbi-ctg/store';
import { IPhysicalAddress } from 'generated/graphql-gateway';
import { useLDContext } from 'state/launchdarkly';
import { useServiceModeContext } from 'state/service-mode';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { initStore } from 'utils/preload-store';
import { remapStore } from 'utils/restaurant';

import { IPreloadedStoreState, IStoreContext, IUseStore } from './types';
import { resetLastTimeStoreUpdated } from './use-store-utils';
import { initialStoreProxyState } from './utils';

export const preloadedStoreInfo = (): IPreloadedStoreState =>
  LocalStorage.getItem(StorageKeys.STORE) || ({} as IPreloadedStoreState);

export const initialStoreState: Partial<IRestaurant> = {
  hasSelection: false,
  selectedUnavailableStoreName: '',
  physicalAddress: {} as IPhysicalAddress,
  chaseMerchantId: '',
  selectedUnavailableStoreNumber: null,
  isAvailable: false,
};

export const createStoreProxyFromStore = (store: IRestaurant | null) => {
  const storeHasPhysicalAddress =
    store && store.physicalAddress && !!Object.keys(store.physicalAddress).length;

  let storeProxy: IRestaurant = { ...initialStoreProxyState };

  if (store === null) {
    return storeProxy;
  }

  if ('hasSelection' in store) {
    const { hasSelection, ...storeDetails } = store;

    if (hasSelection) {
      storeProxy = { ...storeProxy, ...storeDetails };
    } else {
      // unavailable stores in store selection 1.0 only have 3 properties that we care about
      storeProxy.name = store.hasSelection
        ? store.name
        : store.selectedUnavailableStoreName || null;
      storeProxy.number = store.hasSelection
        ? store.number
        : store.selectedUnavailableStoreNumber ?? null;
      storeProxy.physicalAddress = storeHasPhysicalAddress ? store.physicalAddress : null;
    }
  } else {
    // does not have 'hasSelection' property
    storeProxy = store;
  }

  return storeProxy;
};

export const useStore = (): IUseStore => {
  const { updateUserStore } = useLDContext();
  const { clearOrderBay, serviceMode } = useServiceModeContext();
  const preloaded = useMemo(() => preloadedStoreInfo(), []);
  const userSelection = useRef(false);

  /** Explicit state of provider */
  const [_storeState, _setStoreState] = useState<IRestaurant | null>(
    initStore(preloaded, serviceMode)
  );
  const storeState = useMemo(() => remapStore(_storeState), [_storeState]);
  const setStoreState = useCallback<Dispatch<SetStateAction<IRestaurant | null>>>(
    newStore =>
      _setStoreState(s => {
        const value = typeof newStore === 'function' ? newStore(s) : newStore;

        // Store restaurant in local storage
        LocalStorage.setItem(StorageKeys.STORE, {
          store: value,
        });

        if (!value) {
          return {
            ...initialStoreState,
            selectedUnavailableStoreName: initialStoreState.selectedUnavailableStoreName ?? '',
            isAvailable: !!initialStoreState.isAvailable,
            isFavorite: !!initialStoreState.isFavorite,
            isRecent: !!initialStoreState.isRecent,
            hasSelection: !!initialStoreState.hasSelection,
            available: !!initialStoreState.available,
          } as IRestaurant;
        }

        if (isEqual(value, s)) {
          return s;
        }

        return value;
      }),
    []
  );

  /** Change store back to initial state (excluding prefetch) */
  const resetStoreState = useCallback<IStoreContext['resetStore']>(() => {
    setStoreState(null);
  }, [setStoreState]);

  /**
   * Only used in store selection 1.0
   * Selects an unavailable store
   */
  const selectUnavailableStore = useCallback(
    ({
      name,
      number,
      physicalAddress,
    }: {
      name?: string | null;
      number?: string | null;
      physicalAddress?: IPhysicalAddress | null;
    }) => {
      setStoreState({
        ...initialStoreState,
        isAvailable: !!initialStoreState.isAvailable,
        isFavorite: !!initialStoreState.isFavorite,
        isRecent: !!initialStoreState.isRecent,
        hasSelection: !!initialStoreState.hasSelection,
        available: !!initialStoreState.available,
        selectedUnavailableStoreName: name ?? initialStoreState.selectedUnavailableStoreName ?? '',
        physicalAddress: physicalAddress ?? initialStoreState.physicalAddress,
        selectedUnavailableStoreNumber: number ?? initialStoreState.selectedUnavailableStoreNumber,
      });
    },
    [setStoreState]
  );

  const onConfirmStoreChange: IUseStore['onConfirmStoreChange'] = useCallback(
    async args => {
      if (!args) {
        return;
      }

      const { newStore, callback } = args;
      const store: IRestaurant = { ...newStore, hasSelection: true };

      // TODO: BKPE-6812 ORDER_BAY_PILOT. Remove or consolidate this logic once the pilot is done
      clearOrderBay();

      setStoreState(store);

      resetLastTimeStoreUpdated();
      if (typeof callback === 'function') {
        await callback();
      }
      userSelection.current = false;
    },
    [clearOrderBay, setStoreState]
  );

  useEffect(() => {
    if (!userSelection.current) {
      // Update the user store in LD
      updateUserStore(storeState);
    }
  }, [storeState, updateUserStore]);

  /** An object similar to the {@link:IStore} type but all properties can be null */
  const storeProxy: IRestaurant = useMemo(() => createStoreProxyFromStore(storeState), [
    storeState,
  ]);

  return useMemo(
    () => ({
      resetStore: resetStoreState,
      setStore: setStoreState,
      store: storeState,
      selectUnavailableStore,
      onConfirmStoreChange,
      storeProxy,
    }),
    [
      onConfirmStoreChange,
      resetStoreState,
      selectUnavailableStore,
      setStoreState,
      storeProxy,
      storeState,
    ]
  );
};
