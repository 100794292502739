/**
 TODO / NOTE - this isn't using mparticle anymore it needs to be renamed and refactored to be static
 */
import React, { ReactNode, useCallback, useContext, useRef, useState } from 'react';

import { isUndefined, omitBy } from 'lodash-es';

import { ICartEntry, IServerOrder } from '@rbi-ctg/menu';
import { IUserOffersFeedbackEntry } from '@rbi-ctg/offers';
import { IRestaurant } from '@rbi-ctg/store';
import { ProviderType } from 'generated/graphql-gateway';
import { useRoute } from 'hooks/navigation/use-route';
import { useIsMobileBreakpoint } from 'hooks/use-media-query';
import { getRoundUpDonations } from 'pages/cart/your-cart/totals/utils';
import { IStaticPageRoute } from 'remote/queries/static-page';
import { useAmplitudeContext } from 'state/amplitude';
import {
  AuthenticationPath,
  ExcludesNull,
  IAddToCartSelectionAttributes,
  IAmplitudeProduct,
  IAmplitudePurchaseEventAttributes,
  IAmplitudeUniversalAttributes,
  ILogPageView,
  ISignInEventOptions,
} from 'state/amplitude/types';
import { BranchEventNames } from 'state/branch/branch-event-names';
import { logBranchEvent, setBranchIdentity } from 'state/branch/utils';
import { logBrazeCommerceEvent, logBrazeCustomEvent } from 'state/braze/log-braze-event';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { ServiceMode } from 'state/order';
import { preloadedStoreInfo } from 'state/store/hooks';
import { appVersion } from 'utils/app-version-info';
import AuthStorage from 'utils/cognito/storage';
import LocalStorage from 'utils/cognito/storage.web';
import { appStartId, brand, env } from 'utils/environment';
import { centsToDollars } from 'utils/index';
import { StorageKeys } from 'utils/local-storage';
import { Keys } from 'utils/local-storage/constants';
import logger from 'utils/logger';
import { convertQueryStringToObject } from 'utils/navigation';
import noop from 'utils/noop';
import { getInCodeLocalizedRouteForPath, routes } from 'utils/routing';
import { useMemoAll } from 'utils/use-memo-all';

import { CustomEventNames, EventTypes, SignInPhases } from './constants';
import { AllowedEvent } from './events/allowed-events';
import {
  IAutoSignInEventOptions,
  ILogButtonClickEvent,
  ILogEvent,
  ILogLoyaltyEvent,
  ILogQuestEvent,
  ILogRBIEvent,
  ILogRBIProductsEvent,
  IMParticleUniversalAttributes,
  IUtmParams,
} from './types';
import {
  booleanToString,
  convertToSingleCommerceProduct,
  createCRMProducts,
  createProduct,
  expandProductAttributes,
  getCartDataItems,
  getIsMobileWeb,
  getLoyaltyDocumentForQuest,
  getProductsDetails,
  isCommerceEvent,
  isExpandableEvent,
  normalizeBooleans,
  reformatAttributesForSingularProduct,
  sanitizeValues,
  serializeNumberOfDriveThruWindows,
  serializePaymentType,
  serializePickupMode,
  serializeServiceMode,
  toAttributesWithValidLengthValues,
  transformLoyaltyDocument,
} from './utils';

export const APPFLOW_DISPLAYED_BLOCKING_UI = 'Displayed Update UI';

export { CustomEventNames, EventTypes, ClickEventComponentNames } from './constants';

export interface IMParticleCtx {
  autoSignInEvent: ({ success, message, phase }: IAutoSignInEventOptions) => void;
  logButtonClick: (
    event: ILogButtonClickEvent,
    logOptions?: { logDuplicateClickEvent?: boolean }
  ) => void;
  logPurchase: (
    cartEntries: ICartEntry[],
    store: IRestaurant,
    serviceMode: ServiceMode,
    serverOrder: IServerOrder,
    attrs?: Record<string, any>
  ) => void;
  logLoyaltyEvent: (event: ILogLoyaltyEvent, logOptions?: { skipLoggingToBraze?: boolean }) => void;
  logQuestEvent: (event: ILogQuestEvent, logOptions?: { skipLoggingToBraze?: boolean }) => void;
  logRBIProductsDetailsEvent: (
    event: ILogRBIProductsEvent,
    logOptions?: { skipLoggingToBraze?: boolean }
  ) => void;
  logAddOrRemoveFromCart: ({
    action,
    cartEntry,
    previousCartEntries,
    selectionAttrs,
    isReward,
  }: {
    action: 'add' | 'remove';
    cartEntry: ICartEntry;
    previousCartEntries: ICartEntry[];
    selectionAttrs?: IAddToCartSelectionAttributes | undefined;
    isReward?: boolean;
  }) => void;
  logCheckoutEvent: (serviceMode: ServiceMode, cartEntries: ICartEntry[]) => void;
  logoutCRM: () => void;
  loginCRM: ({
    customerId,
    ...userAttributes
  }: {
    customerId?: string | undefined;
    [x: string]: any;
  }) => void;
  logRBIEvent: (event: AllowedEvent, logOptions?: { skipLoggingToBraze?: boolean }) => void;
  logEvent: ILogEvent;
  logNavigationClick: (
    eventName: CustomEventNames,
    attributes?: object,
    customFlags?: object
  ) => void;
  logAddPaymentMethodClick: () => void;
  signUpEvent: ({
    success,
    message,
    authenticationMethod,
    socialAuthenticationType,
    authenticationPath,
  }: {
    success: boolean;
    message?: string;
    authenticationMethod?: string;
    socialAuthenticationType?: ProviderType;
    authenticationPath?: AuthenticationPath;
  }) => void;
  signOutEvent: (success: boolean, message?: string) => void;
  setUTMParamsFromUrl: (url: string) => void;
  logCommercePageView: (
    menuData: { id: string; name: string; menuType: string },
    attrs?: Record<string, string>
  ) => void;
  updateStaticRoutes: (newStaticRoutes: IStaticPageRoute[]) => void;
  updateUniversalAttributes: (_universalAttributes: Partial<IAmplitudeUniversalAttributes>) => void;
  updateUserAttributes: (userAttributes: Record<string, any>) => void;
  updateUserLocationPermissionStatus: () => Promise<void>;
  signInEvent: (options: ISignInEventOptions) => void;
  logPageView: (pathname: string, store: IRestaurant) => void;
}

export const MParticleContext = React.createContext<IMParticleCtx>({
  autoSignInEvent: noop,
  logButtonClick: noop,
  logPurchase: noop,
  logLoyaltyEvent: noop,
  logQuestEvent: noop,
  logAddOrRemoveFromCart: noop,
  logCheckoutEvent: noop,
  loginCRM: noop,
  logoutCRM: noop,
  logRBIEvent: noop,
  logRBIProductsDetailsEvent: noop,
  logEvent: noop,
  logNavigationClick: noop,
  logAddPaymentMethodClick: noop,
  logPageView: noop,
  signInEvent: noop,
  signOutEvent: noop,
  updateStaticRoutes: noop,
  logCommercePageView: noop,
  signUpEvent: noop,
  updateUniversalAttributes: noop,
  updateUserAttributes: noop,
  updateUserLocationPermissionStatus: () => Promise.resolve(),
  setUTMParamsFromUrl: noop,
});

// TODO rename and make static (not a provider) in a future PR
export const useMParticleContext = () => useContext<IMParticleCtx>(MParticleContext);

// TODO rename and make static (not a provider) in a future PR
export function MParticleProvider(props: { children: ReactNode }) {
  const { locale, language, region } = useLocale();
  const enableSignUpInBE = useFlag(LaunchDarklyFlag.ENABLE_COGNITO_SIGNUP_IN_BE);
  const [isNewSignUpEvent, setIsNewSignupEvent] = useState(false);
  const { pathname } = useRoute();
  const isSmallScreen = useIsMobileBreakpoint();
  const {
    logAmplitudeCustomEvent,
    updateAmplitudeUserId,
    logAmplitudeRevenueEvent,
    updateAmplitudeUserAttributes,
    updateUserLocationPermissionStatus,
  } = useAmplitudeContext();

  const staticRoutes = useRef<string[]>([]);
  const logPageViewParameters = useRef<ILogPageView>();

  /**
   * @returns current pathname from react native navigation, or the pathname if react native navigation is not enabled
   */
  const getSourcePage = useCallback(() => {
    return pathname;
  }, [pathname]);

  /**
   * store universal attributes for mParticle event/page views without re-rendering for changes in values
   */
  const universalAttributes = useRef<IMParticleUniversalAttributes>({
    'Service Mode': '',
    'Pickup Mode': '',
    'Source Page': getSourcePage(),
    isLoyaltyUser: !!LocalStorage.getItem(Keys.USER)?.loyaltyId,
    isSmallScreen,
    currentBuild: appVersion,
  });

  const deviceTime = useCallback(() => new Date().toTimeString().split(' ')[0], []);

  /**
   * Updates only the state of the universal Attributes
   */
  const updateUniversalAttributes = useCallback(
    (newAttributes: Partial<IMParticleUniversalAttributes>) => {
      universalAttributes.current = {
        ...universalAttributes.current,
        ...newAttributes,
        'Source Page': getSourcePage(),
      };
    },
    [getSourcePage]
  );

  const { referrerLocation } = useLocationContext();
  /**
   * Logs a Custom Event
   */
  const logRBIEvent: ILogRBIEvent = useCallback(
    (event: AllowedEvent, logOptions?: { skipLoggingToBraze?: boolean }) => {
      let universalAttrs = sanitizeValues(universalAttributes.current);
      universalAttrs = normalizeBooleans(universalAttrs);
      const { store } = preloadedStoreInfo();

      // TODO fix `any` here
      const globalAttributes: any = {
        brand: brand().toUpperCase(),
        region,
        env: env() as string,
        currentScreen: getSourcePage(),
        previousScreen: referrerLocation,
        deviceTime: deviceTime(),
        serviceMode: universalAttrs['Service Mode'],
        pickupMode: universalAttrs['Pickup Mode'],
        appBuild: universalAttrs.currentBuild,
        isMobileWeb: getIsMobileWeb(),
        appStartId,
        'UTM Source': universalAttrs['UTM Source'],
        utmSource: universalAttrs['UTM Source'],
        'UTM Medium': universalAttrs['UTM Medium'],
        utmMedium: universalAttrs['UTM Medium'],
        'UTM Campaign': universalAttrs['UTM Campaign'],
        utmCampaign: universalAttrs['UTM Campaign'],
        'UTM Term': universalAttrs['UTM Term'],
        utmTerm: universalAttrs['UTM Term'],
        'UTM Content': universalAttrs['UTM Content'],
        utmContent: universalAttrs['UTM Content'],
        selectedStoreId: store?.number,
        posVendor: store?.pos?.vendor,
      };

      const definedAttributes = {
        ...omitBy(globalAttributes, isUndefined),
        ...omitBy(event.globalAttributes, isUndefined),
        ...omitBy(event.attributes, isUndefined),
      };

      const attributes = toAttributesWithValidLengthValues(definedAttributes);

      // Log to Braze
      if (!logOptions?.skipLoggingToBraze) {
        if (isCommerceEvent(event.name)) {
          //@ts-ignore
          const products = attributes?.products ?? [];
          logBrazeCommerceEvent(event.name as CustomEventNames, products, attributes);
        } else {
          logBrazeCustomEvent(event.name, attributes);
        }
      }

      // Expand the products if necessary for Amplitude
      //@ts-ignore
      if (isExpandableEvent(event.name)) {
        const products = attributes?.products ?? [];
        const expandedProducts = expandProductAttributes(products);
        logAmplitudeCustomEvent({
          name: event.name,
          attributes: { ...attributes, ...expandedProducts },
        });
      } else {
        logAmplitudeCustomEvent({ name: event.name, attributes });
      }
    },
    [deviceTime, getSourcePage, logAmplitudeCustomEvent, region]
  );

  const logEvent: ILogEvent = useCallback(
    (
      eventName: CustomEventNames | string,
      eventType: EventTypes,
      attributes?: object,
      customFlags?: object
    ) => {
      logRBIEvent({
        name: eventName as any,
        type: eventType as any,
        attributes,
        customFlags: customFlags as any,
      });
    },
    [logRBIEvent]
  );

  const logRBIProductsDetailsEvent = useCallback<IMParticleCtx['logRBIProductsDetailsEvent']>(
    (
      { name, products, price = 0, attributes = {} },
      logOptions?: { skipLoggingToBraze?: boolean }
    ) => {
      const productsDetails = getProductsDetails(products, price);
      logRBIEvent(
        {
          name,
          type: EventTypes.Other,
          attributes: {
            productsDetails,
            ...attributes,
          },
        },
        logOptions
      );
    },
    [logRBIEvent]
  );

  const logNavigationClick = useCallback(
    (eventName: CustomEventNames, attrs = {}, customFlags = {}) => {
      logger.debug(`[mParticle][logNavigationClick]: ${eventName}`);
      logEvent(
        CustomEventNames.BUTTON_CLICK,
        EventTypes.Navigation,
        {
          Name: eventName,
          ...attrs,
        },
        customFlags
      );
    },
    [logEvent]
  );

  const logAddPaymentMethodClick = useCallback(() => {
    logNavigationClick(CustomEventNames.BUTTON_CLICK_ADD_PAYMENT_METHOD);
  }, [logNavigationClick]);

  const trackWhenStaticRoutesAvailable = ({ pathname, store }: ILogPageView) => {
    logPageViewParameters.current = {
      pathname,
      store,
    };
  };

  /**
   * Logs a Page view event
   */
  const logPageView = useCallback(
    (pathname: string, store: IRestaurant) => {
      const isBaseRoute = routes.base === pathname;
      // Checking if path is local path
      const isLocalRoute =
        isBaseRoute ||
        Object.values(routes).some(route => {
          const localizedRoute = getInCodeLocalizedRouteForPath(route, locale, region) || route;
          return route !== '/' && pathname.startsWith(localizedRoute);
        });
      // If staticRoutes have not loaded yet
      // Store them for later when they are available
      if (!isLocalRoute && !staticRoutes.current.length) {
        return trackWhenStaticRoutesAvailable({ pathname, store });
      }
      logRBIEvent({
        name: CustomEventNames.PAGE_VIEW,
        type: EventTypes.Other,
        attributes: {
          path: pathname,
          restaurantId: store?.number ?? '',
          restaurantAddress: store?.physicalAddress?.address1 ?? '',
          restaurantZip: store?.physicalAddress?.postalCode ?? '',
          restaurantCity: store?.physicalAddress?.city ?? '',
          restaurantState: store?.physicalAddress?.stateProvince ?? '',
          restaurantCountry: store?.physicalAddress?.country ?? '',
          'Google.Page': '',
          'Google.DocumentReferrer': '',
          pathname,
          sanityId: '',
          referrer: '',
        },
      });
    },
    [locale, region, logRBIEvent]
  );

  /**
   * Logs a Sign In Event
   */
  const signInEvent = useCallback(
    ({
      phase,
      success,
      otpMethod,
      authenticationMethod,
      socialAuthenticationType,
      authenticationPath,
    }: ISignInEventOptions) => {
      const response = success ? 'Successful' : 'Failure';
      const data = {
        Response: response,
        'Response Description': response,
        'Authentication Method': authenticationMethod,
        'Authentication Path': authenticationPath,
        'Social Authentication Type': socialAuthenticationType,
      };

      const event =
        phase === SignInPhases.START
          ? CustomEventNames.OTP_SIGN_IN_START
          : CustomEventNames.OTP_SIGN_IN_VALIDATION;
      data['LaunchDarkly Flag Value'] = otpMethod;

      if (phase === SignInPhases.COMPLETE) {
        const cognitoId = AuthStorage.getItem(StorageKeys.USER_AUTH_TOKEN);
        if (cognitoId) {
          updateAmplitudeUserId(cognitoId);
          setBranchIdentity(cognitoId);
        }
      }

      if (success && phase === SignInPhases.COMPLETE) {
        logBranchEvent(BranchEventNames.LOGIN);
      }

      logEvent(event, EventTypes.Other, data);

      if (event === CustomEventNames.OTP_SIGN_IN_START) {
        logRBIEvent({
          name: CustomEventNames.SIGN_IN_OTP_ATTEMPT,
          type: EventTypes.Other,
          attributes: {
            signInType: 'Email',
            response,
          },
        });
      }
    },
    [logEvent, logRBIEvent, updateAmplitudeUserId]
  );

  const logCommercePageView = useCallback(
    (menuData: { id: string; name: string; menuType: string }, attrs = {}) => {
      try {
        const { name, id, menuType } = menuData;
        const product: Pick<
          IAmplitudeProduct,
          'id' | 'quantity' | 'name' | 'price' | 'total_product_amount'
        > = {
          id,
          name,
          quantity: 1,
          price: 0,
          total_product_amount: 0,
        };
        const singleProduct = convertToSingleCommerceProduct(product);
        const viewDetailAttributes = { menuType, ...attrs };
        const viewDetailItemAttributes = { menuType, products: [], ...singleProduct, ...attrs };

        logRBIEvent({
          //@ts-ignore
          name: 'eCommerce - ViewDetail',
          type: EventTypes.Other,
          attributes: { ...viewDetailAttributes, products: [product] },
        });

        logRBIEvent({
          //@ts-ignore
          name: 'eCommerce - ViewDetail - Item',
          type: EventTypes.Other,
          attributes: {
            ...viewDetailItemAttributes,
          },
        });
      } catch (error) {
        logger.error(`Error logging CRM eCommerce - View Detail event`);
      }
    },
    [logRBIEvent]
  );

  const signUpEvent = useCallback<IMParticleCtx['signUpEvent']>(
    ({ success, message, authenticationMethod, socialAuthenticationType, authenticationPath }) => {
      try {
        const data = {
          Response: success ? 'Successful' : 'Failure',
          'Response Description': success ? 'Successful' : message,
          Type: enableSignUpInBE ? 'Backend' : 'Cognito',
          'Authentication Method': authenticationMethod,
          'Authentication Path': authenticationPath,
          'Social Authentication Type': socialAuthenticationType,
        };

        if (success) {
          logBranchEvent(BranchEventNames.COMPLETE_REGISTRATION);
          setIsNewSignupEvent(true);
          logRBIEvent({
            name: CustomEventNames.SIGN_UP_SUCCESSFUL,
            type: EventTypes.Other,
            attributes: {
              signUpType: 'Email',
            },
          });
          const cognitoId = AuthStorage.getItem(StorageKeys.USER_AUTH_TOKEN);
          if (cognitoId) {
            updateAmplitudeUserId(cognitoId);
            setBranchIdentity(cognitoId);
          }
        }

        logEvent(CustomEventNames.SIGN_UP, EventTypes.Other, data);
      } catch (error) {
        logger.error(`Error logging CRM Sign Up Event: ${String(error)}`);
      }
    },
    [enableSignUpInBE, logEvent, logRBIEvent, updateAmplitudeUserId]
  );

  const logCheckoutEvent = useCallback(
    (serviceMode: ServiceMode, cartEntries: ICartEntry[]) => {
      try {
        const products = cartEntries.map(createProduct).filter((Boolean as any) as ExcludesNull);
        const pickUpMode = serializePickupMode(serviceMode);
        const customAttributes = {
          'Pickup Mode': pickUpMode,
          'Cart Data': getCartDataItems(cartEntries),
          'Total Amount': products.reduce((acc, curr) => acc + curr.total_product_amount, 0),
          'Product Count': products.length,
        };

        logRBIEvent({
          //@ts-ignore
          name: 'eCommerce - Checkout',
          type: EventTypes.Other,
          attributes: { ...customAttributes, products },
        });

        for (const product of products) {
          const singleProduct = convertToSingleCommerceProduct(product);
          logRBIEvent({
            //@ts-ignore
            name: 'eCommerce - Checkout - Item',
            type: EventTypes.Other,
            attributes: { ...customAttributes, ...(singleProduct as any) },
          });
        }
      } catch (error) {
        logger.error('Error logging CRM eCommerce - Checkout event');
      }
    },
    [logRBIEvent]
  );

  const loginCRM = useCallback(
    ({ customerId = '', ...userAttributes }) => {
      const normalizedAttrs = normalizeBooleans(userAttributes);
      updateUniversalAttributes(normalizedAttrs);

      const cognitoId = customerId || AuthStorage.getItem(StorageKeys.USER_AUTH_TOKEN);
      if (cognitoId) {
        updateAmplitudeUserId(cognitoId);
        setBranchIdentity(cognitoId);
      }

      // fire log sign up flow successful after successful login
      if (isNewSignUpEvent) {
        logRBIEvent({
          name: CustomEventNames.SIGN_UP,
          type: EventTypes.Other,
          attributes: {
            signUpType: 'Email',
          },
          // they want this event but typescript complaining due to external libs idea of what types should exist.
          // this all needs a drastic refactor and will do it soon after we get off mparticle.
        } as any);

        logRBIEvent({
          name: CustomEventNames.SIGN_UP_FLOW_SUCCESSFUL,
          type: EventTypes.Other,
          attributes: {
            signUpType: 'Email',
          },
        });
      }
    },
    [isNewSignUpEvent, logRBIEvent, updateAmplitudeUserId, updateUniversalAttributes]
  );

  const logoutCRM = useCallback(() => {
    updateAmplitudeUserId(undefined);
  }, [updateAmplitudeUserId]);

  const logAddOrRemoveFromCart = useCallback<IMParticleCtx['logAddOrRemoveFromCart']>(
    ({ action, cartEntry, previousCartEntries, selectionAttrs, isReward = false }) => {
      const product = createProduct(cartEntry);
      if (!product) {
        return;
      }
      if (action === 'add') {
        logBranchEvent(BranchEventNames.ADD_TO_CART);
      }
      const cartId = 'lineId' in cartEntry ? cartEntry.lineId : cartEntry.cartId;
      const attributes = {
        isDonation: cartEntry.isDonation ?? false,
        isExtra: cartEntry.isExtra ?? false,
        cartId: cartId || cartEntry._id,
        'Is Kiosk': booleanToString(false),
        'Device Time': deviceTime(),
        'Source Page': getSourcePage(),
        'Is Update': booleanToString(false),
        'Cart Data': getCartDataItems(previousCartEntries),
        'Picker Aspect Selection': booleanToString(!!selectionAttrs?.pickerAspectSelection),
        'Combo Slot Selection': booleanToString(!!selectionAttrs?.comboSlotSelection),
        'Item Modified': booleanToString(!!selectionAttrs?.itemModified),
        'Total Amount': product.total_product_amount,
        'Pickup Mode': universalAttributes.current?.['Pickup Mode'] ?? '',
      };

      if (action === 'add') {
        const addedAttributes = {
          wasModified: !!(
            selectionAttrs?.comboSlotSelection || selectionAttrs?.pickerAspectSelection
          ),
          wasCustomized: !!selectionAttrs?.itemModified,
        };
        logRBIProductsDetailsEvent({
          name: CustomEventNames.E_COMMERCE_ADD_TO_CART,
          products: [cartEntry],
          attributes: { ...addedAttributes, ...attributes, products: [product] },
        });
      } else {
        let itemType = 'product';
        if (isReward) {
          itemType = 'reward';
        } else if (cartEntry.type.includes('Offer')) {
          itemType = 'offer';
        }
        const removedAttributes = {
          itemType,
        };
        logRBIProductsDetailsEvent({
          name: CustomEventNames.E_COMMERCE_REMOVE_FROM_CART,
          products: [cartEntry],
          attributes: { ...removedAttributes, ...attributes, products: [product] },
        });
      }

      const singleProduct = convertToSingleCommerceProduct(product);
      logRBIEvent({
        //@ts-ignore
        name:
          action === 'add' ? 'eCommerce - AddToCart - Item' : 'eCommerce - RemoveFromCart - Item',
        type: EventTypes.Other,
        attributes: { products: [], ...attributes, ...singleProduct },
      });
    },
    [deviceTime, getSourcePage, logRBIEvent, logRBIProductsDetailsEvent]
  );

  const updateStaticRoutes = useCallback(
    (newStaticRoutes: IStaticPageRoute[]) => {
      staticRoutes.current = newStaticRoutes.reduce((acc: string[], route) => {
        const staticPath = route?.localePath?.[language]?.current || route?.path?.current;
        if (staticPath) {
          acc.push(`/${staticPath}`);
        }
        return acc;
      }, []);

      if (staticRoutes.current.length && logPageViewParameters.current) {
        const { store, pathname } = logPageViewParameters.current;
        logRBIEvent({
          name: CustomEventNames.PAGE_VIEW,
          type: EventTypes.Other,
          attributes: {
            path: pathname,
            restaurantId: store?.number ?? '',
            restaurantAddress: store?.physicalAddress?.address1 ?? '',
            restaurantZip: store?.physicalAddress?.postalCode ?? '',
            restaurantCity: store?.physicalAddress?.city ?? '',
            restaurantState: store?.physicalAddress?.stateProvince ?? '',
            restaurantCountry: store?.physicalAddress?.country ?? '',
            'Google.Page': '',
            'Google.DocumentReferrer': '',
            pathname,
            sanityId: '',
            referrer: '',
          },
        });

        logPageViewParameters.current = undefined;
      }
    },
    [language, logRBIEvent]
  );

  const logPurchase = useCallback<IMParticleCtx['logPurchase']>(
    (cartEntries, store, serviceMode, serverOrder, attrs = {}) => {
      const couponIDs = (serverOrder.cart.offersFeedback || []).map(
        (feedbackEntry: IUserOffersFeedbackEntry) => {
          return feedbackEntry.couponId;
        }
      );

      const upsells = cartEntries.filter(entry => entry.isUpsell);
      const hasUpsell = !!upsells.length;
      const upsellTotal = centsToDollars(
        upsells.reduce((total, entry) => total + (entry.price || 0), 0)
      );

      const couponIDString = couponIDs.join();
      const serializedServiceMode = serializeServiceMode(serviceMode);

      const rewardAttributes = serverOrder.cart.rewardsApplied?.map(reward => ({
        'Reward ID': reward.rewardId,
        'Reward Quantity': reward.timesApplied,
      }));

      const transactionAttributes = {
        revenue: centsToDollars(serverOrder.cart.subTotalCents),
        tax: centsToDollars(serverOrder.cart.taxCents),
      };

      const roundUpDonation = getRoundUpDonations(serverOrder);
      const products = createCRMProducts({ serverOrder, cartEntries });

      // Some of these are duplicates from transactionAttributes,
      // but BI wants to have them under specific property names.
      const additionalAttrs: IAmplitudePurchaseEventAttributes = {
        brand: brand().toUpperCase(),
        region,
        env: env() as string,
        'Pickup Mode': serializePickupMode(serviceMode),
        'Service Mode': serializedServiceMode,
        branch_service_mode: serializedServiceMode,
        customer_event_alias: serializedServiceMode,
        'CC Token': serverOrder?.cart?.payment?.panToken ?? null,
        'Coupon ID': couponIDString,
        'Coupon Applied': booleanToString(couponIDs.length > 0),
        Currency: attrs.currencyCode,
        'Tax Amount': transactionAttributes.tax,
        'Total Amount': transactionAttributes.revenue,
        'Transaction Order Number ID': serverOrder?.posOrderId ?? '',
        'Transaction POS': serverOrder?.cart?.posVendor ?? null,
        'Transaction RBI Cloud Order ID': serverOrder?.rbiOrderId ?? null,
        'Timed Fire Minutes': attrs.fireOrderInMinutes,
        'Restaurant ID': store.number ?? null,
        'Restaurant Name': store.name ?? null,
        'Restaurant Number': store.number ?? null,
        'Restaurant Address': store.physicalAddress?.address1 ?? null,
        'Restaurant City': store.physicalAddress?.city ?? null,
        'Restaurant State/Province Name': store.physicalAddress?.stateProvince ?? null,
        'Restaurant Postal Code': store.physicalAddress?.postalCode ?? null,
        'Restaurant Country': store.physicalAddress?.country ?? null,
        'Restaurant Latitude': store.latitude ?? null,
        'Restaurant Longitude': store.longitude ?? null,
        'Restaurant Status': store.status ?? null,
        'Restaurant Drink Station Type': store.drinkStationType ?? null,
        'Restaurant Drive Thru Lane Type': store.driveThruLaneType ?? null,
        'Restaurant Franchise Group Id': store.franchiseGroupId ?? null,
        'Restaurant Franchise Group Name': store.franchiseGroupName ?? null,
        'Restaurant Front Counter Closed': store.frontCounterClosed ?? null,
        'Restaurant Has Breakfast': store.hasBreakfast ?? null,
        'Restaurant Has Burgers For Breakfast': store.hasBurgersForBreakfast ?? null,
        'Restaurant Has Curbside': store.hasCurbside ?? null,
        'Restaurant Has Front Counter Closed': store.frontCounterClosed ?? null,
        'Restaurant Has Dine In': store.hasDineIn ?? null,
        'Restaurant Has Drive Thru': store.hasDriveThru ?? null,
        'Restaurant Has Home Delivery': store.hasDelivery ?? null,
        'Restaurant Has Mobile Ordering': store.hasMobileOrdering ?? null,
        'Restaurant Has Parking': store.hasParking ?? null,
        'Restaurant Has Playground': store.hasPlayground ?? null,
        'Restaurant Has Take Out': store.hasTakeOut ?? null,
        'Restaurant Has Wifi': store.hasWifi ?? null,
        'Restaurant Number Drive Thru Windows': serializeNumberOfDriveThruWindows(
          store.driveThruLaneType ?? null
        ),
        'Restaurant Parking Type': store.parkingType ?? null,
        'Restaurant Playground Type': store.playgroundType ?? null,
        'Restaurant POS': store.pos?.vendor ?? null,
        'Is Kiosk': false,
        'Card Type': serverOrder.cart.payment?.cardType || '',
        'Payment Type': serializePaymentType(serverOrder.cart.payment?.paymentType),
        'Has Upsell': hasUpsell,
        'Upsell Total': String(upsellTotal),
        'Device Time': deviceTime(),
        'Source Page': getSourcePage(),
        'Cart Data': getCartDataItems(cartEntries),
        Rewards: rewardAttributes ? JSON.stringify(rewardAttributes) : null,
        'Is Loyalty': !!serverOrder.loyaltyTransaction,
        roundUpAmount: roundUpDonation?.totalCents ?? 0,
        'Product Count': products.length,
      };
      // Delivery Fees
      if (serializeServiceMode(serviceMode) === 'Delivery') {
        additionalAttrs.deliveryFeeAmount = centsToDollars(attrs.deliveryFeeCents);
        additionalAttrs.deliveryDiscountAmount = centsToDollars(attrs.deliveryFeeDiscountCents);
        additionalAttrs.deliveryGeographicalFeeAmount = centsToDollars(
          attrs.deliveryGeographicalFeeCents
        );
        additionalAttrs.deliveryServiceFeeAmount = centsToDollars(attrs.deliveryServiceFeeCents);
        additionalAttrs.deliverySmallCartFeeAmount = centsToDollars(
          attrs.deliverySmallCartFeeCents
        );
        additionalAttrs.totalDeliveryOrderFeeAmount = centsToDollars(
          attrs.totalDeliveryOrderFeesCents
        );
        additionalAttrs.deliverySurchargeFeeAmount = centsToDollars(
          attrs.deliverySurchargeFeeCents
        );
        additionalAttrs.quotedFeeAmount = centsToDollars(attrs.quotedFeeCents);
        additionalAttrs.baseDeliveryFeeAmount = centsToDollars(attrs.baseDeliveryFeeCents);
      }

      if (transactionAttributes.revenue >= 20) {
        additionalAttrs['Value Threshold 20 Met'] = true;
      }

      if (transactionAttributes.revenue >= 15) {
        additionalAttrs['Value Threshold 15 Met'] = true;
      }

      if (transactionAttributes.revenue >= 10) {
        additionalAttrs['Value Threshold 10 Met'] = true;
      }
      if (transactionAttributes.revenue >= 5) {
        additionalAttrs['Value Threshold 5 Met'] = true;
      }

      const normalizedTransactionAttrs = normalizeBooleans(transactionAttributes);
      const sanitizedAttrs = sanitizeValues(additionalAttrs);
      const normalizedAttrs = normalizeBooleans(sanitizedAttrs);

      try {
        logBranchEvent(BranchEventNames.PURCHASE, {
          revenue: transactionAttributes.revenue,
          tax: transactionAttributes.tax,
          transaction_id: serverOrder.rbiOrderId,
          currency: attrs?.currencyCode || 'USD',
        });

        logRBIProductsDetailsEvent({
          name: CustomEventNames.E_COMMERCE_PURCHASE,
          products: cartEntries,
          attributes: {
            ...normalizedAttrs,
            ...normalizedTransactionAttrs,
            products,
          },
        });

        for (const product of products) {
          const reformattedProduct = reformatAttributesForSingularProduct(product);
          logRBIEvent({
            //@ts-ignore
            name: CustomEventNames.E_COMMERCE_PURCHASE_ITEM,
            type: EventTypes.Other,
            attributes: {
              products: [],
              ...normalizedAttrs,
              ...normalizedTransactionAttrs,
              ...reformattedProduct,
            },
          });
        }

        logAmplitudeRevenueEvent({
          totalAmount: transactionAttributes.revenue,
          eventProperties: { ...normalizedAttrs },
        });
      } catch (error) {
        logger.error({ error, message: 'Error logging eCommerce - Purchase event' });
      }

      try {
        // Also send a custom event for the Purchase allowing the brand to
        // create custom queries in mParticle
        logEvent(CustomEventNames.PURCHASE, EventTypes.Transaction, normalizedAttrs);
      } catch (error) {
        logger.error({ error, message: 'mParticle > purchase custom event error' });
      }

      // log rbi purchase events
      if (serializedServiceMode === 'Pickup' || serializedServiceMode === 'Delivery') {
        const eventName =
          serializedServiceMode === 'Pickup'
            ? CustomEventNames.PURCHASE_PICK_UP
            : CustomEventNames.PURCHASE_DELIVERY;
        try {
          logRBIEvent({
            name: eventName,
            type: EventTypes.Other,
          });
        } catch (error) {
          logger.error({
            error,
            message: `mParticle > ${serializedServiceMode} custom event error`,
          });
        }

        try {
          logBranchEvent(eventName);
        } catch (error) {
          logger.error({
            error,
            message: `Branch > ${serializedServiceMode} custom event error`,
          });
        }
      }
    },
    [
      deviceTime,
      getSourcePage,
      logAmplitudeRevenueEvent,
      logEvent,
      logRBIEvent,
      region,
      logRBIProductsDetailsEvent,
    ]
  );

  /**
   * Logs Loyalty Events, using the standard shape for logging loyalty document attributes.
   */
  const logLoyaltyEvent = useCallback<IMParticleCtx['logLoyaltyEvent']>(
    ({ name, incentives, attributes = {} }, logOptions?: { skipLoggingToBraze?: boolean }) => {
      const loyaltyDocuments = incentives.map(item => transformLoyaltyDocument(item));
      logRBIEvent(
        {
          name,
          type: EventTypes.Other,
          attributes: {
            ...(loyaltyDocuments.length > 1
              ? { incentives: loyaltyDocuments }
              : loyaltyDocuments[0]),
            ...attributes,
          },
        },
        logOptions
      );
    },
    [logRBIEvent]
  );

  /**
   * Logs Quest related Events.
   */
  const logQuestEvent = useCallback<IMParticleCtx['logQuestEvent']>(
    (
      { name, quest, cmsQuestCard, attributes = {} },
      logOptions?: { skipLoggingToBraze?: boolean }
    ) => {
      const loyaltyDocument = getLoyaltyDocumentForQuest(quest, cmsQuestCard);
      logRBIEvent(
        {
          name,
          type: EventTypes.Other,
          attributes: {
            questId: quest.id,
            ...loyaltyDocument,
            ...attributes,
          },
        },
        logOptions
      );
    },
    [logRBIEvent]
  );

  /**
   * Handles logging for Button Click event and Click event
   * @callback logButtonClick
   * @param {Object} eventInfo.attributes - Attributes of the click event.
   * @param {boolean} eventOptions.logDuplicateClickEvent - Whether to log duplicate click events.
   * By doing this, we will continue to send the CLICK event to Amplitude to avoid breaking any
   * chart that might be using it.
   */
  const logButtonClick = useCallback<IMParticleCtx['logButtonClick']>(
    ({ attributes }, { logDuplicateClickEvent } = {}) => {
      const {
        text,
        name,
        component,
        componentId,
        position,
        headerText,
        ...restAttributes
      } = attributes;

      if (logDuplicateClickEvent) {
        // log "Click Event"
        logRBIEvent({
          name: CustomEventNames.CLICK_EVENT,
          type: EventTypes.Other,
          attributes: {
            text: text || name,
            component,
            ...(componentId && { componentId }),
            ...(position && { position }),
            ...(headerText && { headerText }),
          },
        });
      }

      // log "Button Click" event
      logRBIEvent({
        name: CustomEventNames.BUTTON_CLICK,
        type: EventTypes.Other,
        attributes: {
          name,
          component,
          ...restAttributes,
        },
      });
    },
    [logRBIEvent]
  );

  const autoSignInEvent = useCallback(
    ({
      success,
      message,
      phase,
      authenticationMethod,
      socialAuthenticationType,
      authenticationPath,
    }: IAutoSignInEventOptions) => {
      logEvent(
        phase === SignInPhases.COMPLETE
          ? CustomEventNames.AUTO_SIGN_IN_COMPLETE
          : CustomEventNames.AUTO_SIGN_IN_START,
        EventTypes.Other,
        {
          Response: success ? 'Successful' : 'Failure',
          'Response Description': success ? 'Successful' : message,
          'Authentication Method': authenticationMethod,
          'Social Authentication Type': socialAuthenticationType,
          'Authentication Path': authenticationPath,
        }
      );
    },
    [logEvent]
  );

  const signOutEvent = useCallback(
    (success: boolean, message?: string) => {
      setIsNewSignupEvent(false);
      logEvent(CustomEventNames.SIGN_OUT, EventTypes.Other, {
        Response: success ? 'Successful' : 'Failure',
        'Response Description': success ? 'Successful' : message,
      });
    },
    [logEvent]
  );

  const updateUserAttributes = useCallback<IMParticleCtx['updateUserAttributes']>(
    attrs => {
      updateAmplitudeUserAttributes(attrs);
    },
    [updateAmplitudeUserAttributes]
  );

  /**
   * Updates UTM Attributes in the universal attributes.
   * Used For attribution tracking.
   */
  const setUTMParamsFromUrl = useCallback(
    (url: string) => {
      const {
        utm_source = '',
        utm_campaign = '',
        utm_medium = '',
        utm_content = '',
        utm_term = '',
      } = convertQueryStringToObject<IUtmParams>(url);

      if (utm_source || utm_campaign || utm_medium || utm_content || utm_term) {
        const attributes = {
          'UTM Source': utm_source,
          'UTM Medium': utm_medium,
          'UTM Campaign': utm_campaign,
          'UTM Term': utm_term,
          'UTM Content': utm_content,
        };
        updateUniversalAttributes(attributes);
        updateUserAttributes(attributes);
      }
    },
    [updateUniversalAttributes, updateUserAttributes]
  );

  const crmEventsContext = useMemoAll<IMParticleCtx>({
    autoSignInEvent,
    logAddOrRemoveFromCart,
    logAddPaymentMethodClick,
    logCheckoutEvent,
    logCommercePageView,
    logEvent,
    loginCRM,
    logLoyaltyEvent,
    logQuestEvent,
    logButtonClick,
    logNavigationClick,
    logoutCRM,
    logPageView,
    logPurchase,
    logRBIEvent,
    logRBIProductsDetailsEvent,
    setUTMParamsFromUrl,
    signInEvent,
    signUpEvent,
    signOutEvent,
    updateStaticRoutes,
    updateUniversalAttributes,
    updateUserAttributes,
    updateUserLocationPermissionStatus,
  });

  return (
    <MParticleContext.Provider value={crmEventsContext}>{props.children}</MParticleContext.Provider>
  );
}

export default MParticleContext.Consumer;
