import { Button, Icon } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';

export const StyledButton = Button.withConfig<{ noBackground?: boolean; inverted?: boolean }>(
  p => ({
    background: p.noBackground
      ? 'transparent'
      : p.inverted
      ? Styles.color.black
      : Styles.color.white,
    borderRadius: 'full',
    height: '$9',
    width: '$9',
  })
);

export const StyledIcon = Icon.withConfig<{ inverted?: boolean }>(p => ({
  color: p.inverted ? theme.token('text-button-primary') : Styles.color.black,
  ...p,
}));
