interface IGetSanityUrl {
  sanityDataset: string;
  sanityProjectId: string;
  useCdn?: boolean;
}

// TODO: RN - locale switching -- should pull from specific region
const getSanityBaseUrl = (useCdn: boolean = true, sanityProjectId: string = '') =>
  `https://${sanityProjectId}.api${useCdn ? 'cdn' : ''}.sanity.io/v1`;

export const getSanityGroqQueryUrl = ({ sanityDataset, useCdn, sanityProjectId }: IGetSanityUrl) =>
  `${getSanityBaseUrl(useCdn, sanityProjectId)}/data/query/${sanityDataset}`;

export const getSanityGraphqlQueryUrl = ({
  sanityDataset,
  useCdn,
  sanityProjectId,
}: IGetSanityUrl) =>
  `${getSanityBaseUrl(useCdn, sanityProjectId)}/graphql/${sanityDataset}/default`;

export const getSanityGraphqlQueryUrlV2 = ({
  sanityDataset,
  useCdn,
  sanityProjectId,
}: IGetSanityUrl) => `${getSanityBaseUrl(useCdn, sanityProjectId)}/graphql/${sanityDataset}/gen2`;
