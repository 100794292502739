import React from 'react';

import { useIntl } from 'react-intl';

import { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { selectors, useAppSelector } from 'state/global-state';
import { routes } from 'utils/routing';

import {
  BadgeText,
  StyledBellIcon,
  StyledNotificationButton,
  UnreadNotificationBadge,
} from './styled';

const MAX_NOTIFICATIONS_IN_BADGE = 9;

export const NotificationsButton = () => {
  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();
  const unreadContentCards = useAppSelector(selectors.loyalty.selectUnreadContentCards);

  const handleOnPress = () => {
    navigate(routes.news);
  };

  return (
    <StyledNotificationButton
      variant={ActionButtonVariants.TEXT_ONLY}
      size={ActionButtonSizes.SMALL}
      testID="app-header-notification-cta"
      onPress={handleOnPress}
      startIcon={<StyledBellIcon />}
      accessibilityLabel={formatMessage({ id: 'news' })}
    >
      {unreadContentCards > 0 && (
        <UnreadNotificationBadge>
          <BadgeText>
            {unreadContentCards > MAX_NOTIFICATIONS_IN_BADGE
              ? `${MAX_NOTIFICATIONS_IN_BADGE}+`
              : unreadContentCards}
          </BadgeText>
        </UnreadNotificationBadge>
      )}
    </StyledNotificationButton>
  );
};
