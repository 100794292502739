import { ICartEntryType } from './types';
import type { ICartEntry, ICartEntryOffer, ICartEntryReward } from './types';

type IGetItemIdGetterMap = { [key in ICartEntryType]: (ce: ICartEntry) => string };

const itemIdGetterMap: IGetItemIdGetterMap = {
  // @ts-expect-error TS(2418) FIXME: Type of computed property's value is '(ce: ICartEn... Remove this comment to see the full error message
  [ICartEntryType.REWARD]: (ce: ICartEntryReward) => ce.details.reward._id,
  // @ts-expect-error TS(2418) FIXME: Type of computed property's value is '(ce: ICartEn... Remove this comment to see the full error message
  [ICartEntryType.OFFER]: (ce: ICartEntryOffer) => ce.details.offer._id,
};

const getCartEntryItemId = (cartEntry: ICartEntry) => itemIdGetterMap[cartEntry.type](cartEntry);

export const isCartEntryEqual = (cartEntry: ICartEntry, cartEntryToCompare: ICartEntry) =>
  cartEntry.referenceId === cartEntryToCompare.referenceId ||
  (cartEntry.type === cartEntryToCompare.type &&
    getCartEntryItemId(cartEntry) === getCartEntryItemId(cartEntryToCompare));
