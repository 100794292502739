import { useCallback } from 'react';

import { useLocalStorageState } from 'hooks/use-local-storage-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { StorageKeys } from 'utils/local-storage';

export type IOrderBayValue = string | null;
export interface IOrderBayState {
  clearOrderBay: () => void;
  isOrderBayEnabled: boolean;
  orderBayValue: IOrderBayValue;
  setOrderBay: (value: IOrderBayValue) => void;
}

export const useOrderBay = (): IOrderBayState => {
  const isOrderBayEnabled = useFlag(LaunchDarklyFlag.ENABLE_ORDER_BAY);

  const [orderBayValue, _setOrderBay, _clearOrderBay] = useLocalStorageState<IOrderBayValue>({
    key: StorageKeys.ORDER_BAY,
    defaultReturnValue: null,
    updateLocalStorageOnMount: currentValue => ({
      shouldUpdate: true,
      newState: isOrderBayEnabled ? currentValue : null,
    }),
  });

  const setOrderBay = useCallback(
    (value: IOrderBayValue) => {
      return _setOrderBay((prevValue: IOrderBayValue) => {
        if (prevValue === value) {
          return prevValue;
        }

        return isOrderBayEnabled ? value : null;
      });
    },
    [_setOrderBay, isOrderBayEnabled]
  );

  const clearOrderBay = useCallback(() => {
    return _setOrderBay(() => {
      _clearOrderBay();
      return null;
    });
  }, [_clearOrderBay, _setOrderBay]);

  return {
    clearOrderBay,
    isOrderBayEnabled,
    orderBayValue,
    setOrderBay,
  };
};
