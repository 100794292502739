import { useRoute } from 'hooks/navigation/use-route';
import { useLDContext } from 'state/launchdarkly';
import { usePageManagerContext } from 'state/static-page-manager';

export const useIsEnrollmentEnabled = () => {
  const { ldUserIsAuthenticated } = useLDContext();
  const { routes: staticPageRoutes } = usePageManagerContext();
  const { pathname } = useRoute();

  // Check if page is static to avoid showing loyalty opt in.
  const isStaticPage = staticPageRoutes.some(route => `/${route.path.current}` === pathname);

  return !isStaticPage && ldUserIsAuthenticated;
};
