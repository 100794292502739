import { Box, Icon, Text, makeUclComponent } from '@rbilabs/universal-components';

import ActionButton, { ActionButtonVariants } from 'components/action-button';

export const ErrorIcon = Icon.withConfig({
  name: 'mi:error',
  size: '$9',
});

export const DialogIconContainer = Box.withConfig({
  alignItems: 'center',
});

export const DialogTitle = Text.withConfig({
  variant: 'headerTwo',
  fontSize: '2xl',
  textAlign: 'center',
  marginTop: '$3',
});

export const DialogContent = Text.withConfig({
  variant: 'copyOne',
  fontSize: 'md',
  textAlign: 'center',
  marginY: '$3',
});

export const ConfirmButton = makeUclComponent(ActionButton).withConfig({
  fullWidth: true,
  variant: ActionButtonVariants.PRIMARY,
  marginBottom: '$3',
});

export const CloseButton = makeUclComponent(ActionButton).withConfig({
  fullWidth: true,
  variant: ActionButtonVariants.TEXT_ONLY,
});

export const ModalActionsContainer = Box.withConfig({
  width: '100%',
});
