import { CartEntryType } from 'utils/cart';

export const mapEntryTypeToOfferType = (entryType: CartEntryType) => {
  let offerType;

  switch (entryType) {
    case CartEntryType.combo:
    case CartEntryType.offerCombo:
      offerType = CartEntryType.offerCombo;
      break;
    case CartEntryType.item:
    case CartEntryType.offerItem:
    default:
      offerType = CartEntryType.offerItem;
      break;
  }

  return offerType;
};
