import { ProviderType } from 'generated/graphql-gateway';

export class JwtValidationError extends Error {
  constructor() {
    super('JWT validation unsuccessful');
  }
}

export class UserNotFoundError extends Error {
  constructor() {
    super('user not found');
  }
}

export class OtpValidationError extends Error {
  constructor(message: string) {
    super(`OTP validation unsuccessful: ${message}`);
  }
}
export const USER_DECLINED_EMAIL_ACCESS_PERMISSION_ERROR_MESSAGE =
  'User declined email access permission';

export const ACCESS_TOKEN_NOT_RETRIEVED_ERROR_MESSAGE = 'Could not retrieve access token';

export class SocialLoginError extends Error {
  providerType: ProviderType;

  constructor(message: string, providerType: ProviderType) {
    super(message);

    this.providerType = providerType;
  }
}
