interface IStylesForMaxLinesOfText {
  maxLines: number;
  lineHeight: number;
  fontSizeInRem: number;
  overridingStyles?: any;
  useFullHeight?: boolean;
}

export const stylesForMaxLinesOfText = ({
  maxLines,
  lineHeight,
  fontSizeInRem,
  overridingStyles,
  useFullHeight,
}: IStylesForMaxLinesOfText) => {
  const calculatedHeightRems = maxLines * fontSizeInRem * lineHeight;
  return `
    /* clamp the text at <maxLines> lines */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${maxLines};

    line-height: ${lineHeight};
    font-size: ${fontSizeInRem}rem;
    /* The max height is constrained to the per-line height (font size * line height)
     * multiplied by the max number of lines. End result is measured in rems.
     */
    max-height: calc(${calculatedHeightRems}rem + 2px);
    height: ${useFullHeight ? `calc(${calculatedHeightRems}rem + 2px)` : 'auto'};

    ${overridingStyles || null}
  `;
};
