import { useCallback, useMemo } from 'react';

import { useToast } from '@rbilabs/universal-components';
import { isEqual } from 'lodash-es';
import { useIntl } from 'react-intl';

import { ICartEntry, ICombo } from '@rbi-ctg/menu';
import type { IAddToCartSelectionAttributes } from 'state/amplitude/types';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { removeAppliedOffersInStorage } from 'state/global-state/models/loyalty/offers/offers.utils';
import { removeAppliedRewardsInStorage } from 'state/global-state/models/loyalty/rewards/rewards.utils';
import { useGetAvailableRewards } from 'state/loyalty/hooks/use-get-available-rewards';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { useMenuContext } from 'state/menu';
import { CartEntryType } from 'utils/cart';
import { checkIsBogoOfferItem, computeCartTotal } from 'utils/cart/helper';

import usePosVendor from './menu/use-pos-vendor';

interface IUseCart {
  cartEntries: ICartEntry[];
  calculateCartTotal: () => number;
  calculateCartTotalWithDiscount: (
    paramCartEntries?: ICartEntry[]
  ) => { cartTotal: number; isCartTotalNegative: boolean };
  addItemToCart: (
    cartEntry: ICartEntry,
    selectionAttrs?: IAddToCartSelectionAttributes | undefined,
    showMessage?: boolean
  ) => void;
  emptyCart: () => void;
  isCartEmpty: boolean;
  removeAllFromCart: (cartEntries: ICartEntry[]) => void;
  removeFromCart: (objectWithCartId: { cartId: string }) => void;
  repriceCartEntries: (
    entries: ICartEntry[],
    parentEntry?: ICartEntry,
    mainCombo?: ICombo
  ) => ICartEntry[];
  updateCartEntry: (newCartEntry: ICartEntry, cartId: string) => void;
  updateQuantity: (cartId: string, quantity: number) => void;
  numberOfItemsInCart: number;
}

/**
 * Expose the cart state and the utility methods to mutate it.
 *
 * When an action to mutate the cart is triggered, it manages the Redux cart actions, logs the events,
 * coordinates with other parts of the code, and dispatches actions on related slices.
 */
export const useCart = (): IUseCart => {
  const dispatch = useAppDispatch();
  const cartEntries = useAppSelector(selectors.ordering.selectCartEntries);
  const { loyaltyUser } = useLoyaltyUser();
  const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);
  const discountAppliedCmsOffers = useAppSelector(selectors.loyalty.selectDiscountAppliedCmsOffer);
  const { vendor } = usePosVendor();

  const toast = useToast();
  const { formatMessage } = useIntl();

  const { logAddOrRemoveFromCart, logEvent } = useMParticleContext();
  const {
    pricingFunction,
    priceForItemInComboSlotSelection,
    priceForItemOptionModifier,
  } = useMenuContext();

  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { getAvailableRewardFromCartEntry } = useGetAvailableRewards();

  // Adds a single cartEntry to the cart
  const addItemToCart = useCallback<IUseCart['addItemToCart']>(
    (cartEntry, selectionAttrs, showMessage = true) => {
      logAddOrRemoveFromCart({
        action: 'add',
        cartEntry,
        previousCartEntries: cartEntries,
        selectionAttrs,
      });

      dispatch(actions.ordering.addCartEntry(cartEntry));

      if (cartEntry.isDonation) {
        return;
      }

      if (showMessage) {
        toast.show({
          text: formatMessage({ id: 'addToCartSuccess' }, { itemName: cartEntry.name }),
          variant: 'positive',
        });
      }
    },
    [cartEntries, dispatch, formatMessage, logAddOrRemoveFromCart, toast]
  );

  const updateCartEntry = useCallback(
    (newCartEntry: ICartEntry, cartId: string) => {
      const originalEntry = cartEntries.find(entry => entry.cartId === cartId);
      if (!originalEntry) {
        return;
      }

      dispatch(actions.ordering.updateCartEntry({ cartEntry: newCartEntry, cartId }));

      // TODO: analyze moving this to extraReducers on loyalty slice
      // If the original entry has a reward applied but the new entry is a different item,
      // then remove the reward if it is not a picker selection.
      const isPickerSelection = Object.keys(originalEntry.pickerSelections).length > 0;
      const appliedReward = appliedLoyaltyRewards[originalEntry?.cartId];
      if (
        !isPickerSelection &&
        appliedReward &&
        appliedReward.rewardBenefitId !== newCartEntry._id
      ) {
        dispatch(
          actions.loyalty.removeAppliedReward({
            rewardBenefitId: appliedReward.rewardBenefitId,
            cartId: originalEntry.cartId,
          })
        );
      }

      logAddOrRemoveFromCart({
        action: 'remove',
        cartEntry: originalEntry,
        previousCartEntries: [originalEntry],
        isReward: !!appliedReward,
      });
      logAddOrRemoveFromCart({
        action: 'add',
        cartEntry: newCartEntry,
        previousCartEntries: [originalEntry],
      });
      toast.show({
        text: formatMessage({ id: 'updateCartSuccess' }, { itemName: newCartEntry.name }),
        variant: 'positive',
      });
    },
    [appliedLoyaltyRewards, cartEntries, dispatch, formatMessage, logAddOrRemoveFromCart, toast]
  );

  const logCartEntryRemovedFromCart = useCallback(
    (cartEntry: ICartEntry) => {
      const appliedReward = appliedLoyaltyRewards[cartEntry?.cartId];
      logAddOrRemoveFromCart({
        action: 'remove',
        cartEntry,
        previousCartEntries: cartEntries,
        isReward: !!appliedReward,
      });
      if (cartEntry.isUpsell) {
        logEvent(CustomEventNames.UPSELL_REMOVED, EventTypes.Other, {
          Id: cartEntry._id,
          Name: cartEntry.name,
          Price: cartEntry.price && cartEntry.price / 100,
        });
      }
    },
    [cartEntries, logEvent, logAddOrRemoveFromCart]
  );

  const removeRewardIfNeeded = useCallback(
    (cartEntry: ICartEntry) => {
      const { cartId } = cartEntry;
      const cartEntryReward = getAvailableRewardFromCartEntry(cartEntry);

      const isRewardApplied = !!appliedLoyaltyRewards?.[cartId]?.timesApplied;
      if (loyaltyEnabled && isRewardApplied && cartEntryReward) {
        dispatch(
          actions.loyalty.removeAppliedReward({
            rewardBenefitId: cartEntryReward.rewardBenefitId,
            cartId,
          })
        );
      }
    },
    [appliedLoyaltyRewards, getAvailableRewardFromCartEntry, loyaltyEnabled, dispatch]
  );

  // Removes a single cartEntry using its cartId
  const removeFromCart = useCallback<IUseCart['removeFromCart']>(
    ({ cartId }: { cartId: string }) => {
      const cartEntryToRemove = cartEntries.find((entry: ICartEntry) => entry.cartId === cartId);

      if (!cartEntryToRemove) {
        return;
      }
      dispatch(actions.ordering.removeCartEntries(new Set([cartId])));

      // removes applied rewards associated to cart entry on removal
      removeRewardIfNeeded(cartEntryToRemove);
      dispatch(actions.loyalty.removeAppliedOfferByCartEntry(cartEntryToRemove));
      logCartEntryRemovedFromCart(cartEntryToRemove);
    },
    [cartEntries, dispatch, logCartEntryRemovedFromCart, removeRewardIfNeeded]
  );

  // Removes all provided cartEntries from the cart using their cartIds
  const removeAllFromCart = useCallback<IUseCart['removeAllFromCart']>(
    (cartEntriesToRemove = []) => {
      const cartEntryIdsToRemove = new Set(
        cartEntriesToRemove.map((entry: ICartEntry) => entry.cartId)
      );
      dispatch(actions.ordering.removeCartEntries(cartEntryIdsToRemove));

      cartEntriesToRemove.forEach(cartEntry => {
        removeRewardIfNeeded(cartEntry);
        dispatch(actions.loyalty.removeAppliedOfferByCartEntry(cartEntry));
        logCartEntryRemovedFromCart(cartEntry);
      });
    },
    [dispatch, logCartEntryRemovedFromCart, removeRewardIfNeeded]
  );

  const updateQuantity = useCallback<IUseCart['updateQuantity']>(
    (cartId: string, quantity: number) => {
      const cartEntryToUpdate = cartEntries.find(cartEntry => cartEntry.cartId === cartId);
      if (!cartEntryToUpdate) {
        return;
      }

      if (quantity < 1) {
        return removeFromCart({ cartId });
      }
      const price = pricingFunction({ item: cartEntryToUpdate, quantity });
      dispatch(actions.ordering.updateQuantityAndPrice({ cartId, quantity, price }));
    },
    [cartEntries, dispatch, pricingFunction, removeFromCart]
  );

  // When repricing cart entry, we need a record of parent and main combo in order to price combo item
  const repriceCartEntriesHelper = useCallback(
    (
      entries: ICartEntry[],
      parentEntry?: ICartEntry,
      mainCombo?: ICartEntry | ICombo
    ): ICartEntry[] =>
      entries.map((entry: ICartEntry) => {
        const hasPrice = entry.price !== undefined;
        const isItem = entry.type === CartEntryType.item;
        const isCombo = entry.type === CartEntryType.combo;
        const isComboSlot = parentEntry?.type === CartEntryType.comboSlot;

        if (isItem) {
          // reprices an item and its grandchild itemOptionModifiers in one go.

          let itemPrice = 0;
          if (isComboSlot) {
            itemPrice = entry.enableUpsellPricing
              ? priceForItemInComboSlotSelection({
                  combo: mainCombo,
                  comboSlot: parentEntry,
                  selectedItem: entry,
                })
              : 0;
          } else {
            itemPrice = pricingFunction({ item: entry, quantity: entry.quantity });
          }

          return {
            ...entry,
            // prices are not defined on main items in combos, we need to preserve this
            ...(hasPrice && { price: itemPrice }),
            children: (entry.children || []).map(
              (itemOption: any): ICartEntry => ({
                ...itemOption,
                // reprice the item modifiers
                children: (itemOption.children || []).map((modifier: any) => {
                  const modifierPrice = priceForItemOptionModifier({
                    item: entry,
                    itemOption,
                    modifier,
                  });
                  return {
                    ...modifier,
                    price: modifierPrice,
                  };
                }),
              })
            ),
          };
        }
        // Checking for BOGO Offers price
        const isBogoOffer = checkIsBogoOfferItem(entry, vendor);

        const price = pricingFunction({
          item: entry,
          quantity: entry.quantity,
          vendor,
          isBogoOffer,
        });
        return {
          ...entry,
          ...((isCombo || isBogoOffer) && hasPrice && { price }),
          children: repriceCartEntriesHelper(entry.children, entry, isCombo ? entry : mainCombo),
        };
      }),
    [vendor, pricingFunction, priceForItemInComboSlotSelection, priceForItemOptionModifier]
  );

  const repriceCartEntries = useCallback(
    (entries: ICartEntry[], parentEntry?: ICartEntry, mainCombo?: ICombo) => {
      const newEntries = repriceCartEntriesHelper(entries, parentEntry, mainCombo);
      // If newEntries is structurally equal to entries, then return original object
      // to avoid unnecessary re-renders. This is a fix to priceOrder mutation being called twice.
      return isEqual(entries, newEntries) ? entries : newEntries;
    },
    [repriceCartEntriesHelper]
  );

  const emptyCart = useCallback(() => {
    dispatch(actions.ordering.resetCart());

    removeAppliedOffersInStorage();
    removeAppliedRewardsInStorage();

    // TODO: evaluate moving this to `extraReducers` on loyalty slices
    if (loyaltyEnabled) {
      dispatch(actions.loyalty.resetAppliedOffers());
      dispatch(
        actions.loyalty.resetLoyaltyRewardsState({
          points: loyaltyUser?.points ?? 0,
          shouldResetAvailableRewardsMap: false,
        })
      );
    }
  }, [loyaltyEnabled, loyaltyUser, dispatch]);

  const numberOfItemsInCart = useMemo(
    () => cartEntries.reduce((total, cartEntry) => total + (cartEntry.quantity ?? 1), 0),
    [cartEntries]
  );

  const isCartEmpty = !cartEntries?.length;

  const calculateCartTotalWithDiscount = useCallback(
    (paramCartEntries?: ICartEntry[]) => {
      const total = computeCartTotal(paramCartEntries || cartEntries, {
        loyaltyEnabled,
        appliedLoyaltyRewards,
        appliedLoyaltyOfferDiscount: discountAppliedCmsOffers?.incentives?.[0] as any,
      });
      return {
        cartTotal: total,
        isCartTotalNegative: total < 0,
      };
    },
    [appliedLoyaltyRewards, cartEntries, loyaltyEnabled, discountAppliedCmsOffers]
  );

  const calculateCartTotal = useCallback(() => {
    const { cartTotal, isCartTotalNegative } = calculateCartTotalWithDiscount(cartEntries);
    return isCartTotalNegative ? 0 : cartTotal;
  }, [calculateCartTotalWithDiscount, cartEntries]);

  return {
    addItemToCart,
    cartEntries,
    calculateCartTotal,
    calculateCartTotalWithDiscount,
    emptyCart,
    isCartEmpty,
    removeAllFromCart,
    repriceCartEntries,
    removeFromCart,
    updateCartEntry,
    updateQuantity,
    numberOfItemsInCart,
  };
};
