import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'state/global-state';

export const selectLoyaltyWalmartUI = ({ loyalty }: RootState) => loyalty.walmart.loyaltyWalmartUI;

export const selectLoyaltyWalmartUILoading = ({ loyalty }: RootState) =>
  loyalty.walmart.loyaltyWalmartUILoading;

export const selectLoyaltyWalmart = createSelector(
  selectLoyaltyWalmartUI,
  selectLoyaltyWalmartUILoading,
  (loyaltyWalmartUI, loyaltyWalmartUILoading) => {
    return {
      loyaltyWalmartUI: loyaltyWalmartUI || null,
      loyaltyWalmartUILoading,
    };
  }
);
