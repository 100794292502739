import { useIntl } from 'react-intl';

import { IRestaurant } from '@rbi-ctg/store';
import { IOperatingHours } from 'generated/graphql-gateway';
import { useConfigValue } from 'hooks/configs/use-config-value';
import { useServiceModeStatus } from 'hooks/use-service-mode-status';
import { useEnableMapListExperiment } from 'pages/store-locator/use-enable-map-list-experiment';
import { useCartContext } from 'state/cart';
import { ServiceMode } from 'state/service-mode';
import {
  isOpenIfNotWithinMinutesOfCloseTodayAndNot24hours,
  readableCloseHourToday,
  readableOpenHourToday,
} from 'utils/restaurant';
import { TWELVE_HOUR_TIME_PARSE_FORMAT } from 'utils/restaurant/constants';

const getOperatingHours = (restaurant: IRestaurant) => (serviceMode: ServiceMode) => {
  switch (serviceMode) {
    case ServiceMode.DRIVE_THRU:
      return restaurant.driveThruHours;
    case ServiceMode.CURBSIDE:
      return restaurant.curbsideHours;
    default:
      return restaurant.diningRoomHours;
  }
};

const isWithinOneHourOfClose = (hours: IOperatingHours) => {
  return !isOpenIfNotWithinMinutesOfCloseTodayAndNot24hours(hours, new Date(Date.now()), 60);
};
interface IOpenClosedText {
  isOpen: boolean;
  isWithinOneHourOfClose: boolean;
  text: string;
}

export const useOpenClosedText = (restaurant: IRestaurant): IOpenClosedText => {
  const timeFormatConfig = useConfigValue({
    key: 'timeFormat',
    defaultValue: TWELVE_HOUR_TIME_PARSE_FORMAT,
  });
  const { availablePickupServiceModes } = useServiceModeStatus(restaurant);
  const { formatMessage } = useIntl();
  const enableMapListExperiment = useEnableMapListExperiment();
  const { serviceMode } = useCartContext();

  // Get the open hours for the pickup service mode that is open and not disabled.
  const [operatingHours] = availablePickupServiceModes.map(getOperatingHours(restaurant));

  if (enableMapListExperiment && !operatingHours) {
    return {
      isOpen: false,
      isWithinOneHourOfClose: false,
      text: `${formatMessage({ id: 'closed' })} - ${formatMessage(
        { id: 'opensAt' },
        {
          time: readableOpenHourToday(
            getOperatingHours(restaurant)(serviceMode),
            timeFormatConfig,
            formatMessage({ id: 'notApplicable' })
          ),
        }
      )}`,
    };
  }

  if (!operatingHours) {
    return { isOpen: false, isWithinOneHourOfClose: false, text: formatMessage({ id: 'closed' }) };
  }

  if (isWithinOneHourOfClose(operatingHours)) {
    return {
      isOpen: true,
      isWithinOneHourOfClose: true,
      text: formatMessage(
        { id: 'closesAt' },
        { time: readableCloseHourToday(operatingHours, timeFormatConfig) }
      ),
    };
  }

  return { isOpen: true, isWithinOneHourOfClose: false, text: formatMessage({ id: 'open' }) };
};
