import { Region } from 'utils/environment/types';

export enum LANGUAGES {
  /** English */
  en = 'en',
  /** French */
  fr = 'fr',
}

export enum DOMAINS {
  com = '.com',
  ca = '.ca',
}

export enum MONEY_FORMAT {
  en_us = '$US',
  en_ca = '$CA',
  fr_ca = 'CA$',
}

export enum CURRENCY_CODE_FORMAT {
  en_us = 'USD',
  en_ca = 'CAD',
  fr_ca = 'CAD',
}

export enum LOCALES {
  en_ca = 'en-CA',
  en_us = 'en-US',
  fr_ca = 'fr-CA',
}

export type LocaleData = {
  code: LOCALES;
  region: Region;
  language: LANGUAGES;
  moneyFormat?: MONEY_FORMAT;
  currencyCodeFormat?: CURRENCY_CODE_FORMAT;
};

export const CURRENCY_PREFIX = {
  [LOCALES.en_us]: '$',
  [LOCALES.en_ca]: '$',
  [LOCALES.fr_ca]: '$',
};

export type LanguageAndRegionFromLocaleString = [LANGUAGES, Region];
