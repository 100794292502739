import React from 'react';

import { Box } from '@rbilabs/universal-components';

import { isOverlay } from './constants';
import { IModalScreenProps } from './types';

import WebModal from '.';

// TODO: ReactNavigation once migration finished, each screen using this component should
// have a refactor in order to move away its content and logic from Modal component.
// Should replace regular ```import Modal from components/modal``` on migrated screens.

/**
 * A modal adapter for different navigators (react-router / react navigation).
 * Allows to declare Modal's props (header/body/footer) once and it will wrap it accordingly.
 *
 * For ReactRouter it will return a full modal implementation.
 *
 * For ReactNavigation it will only return the modal content. We'll still need
 * to declare the Modal route separately.
 */
const Modal: React.FC<React.PropsWithChildren<IModalScreenProps>> = ({
  children,
  showFixedHeader,
  ...modalProps
}) => {
  // in web and legacy isOverlay must be true in order to show UCL Modal
  // isOverlay as false is for screen modals for non floating content
  return isOverlay ? (
    <WebModal {...modalProps}>{children}</WebModal>
  ) : (
    <Box height="full">
      {showFixedHeader && modalProps.header}
      {children}
      {modalProps.footer}
    </Box>
  );
};

export default Modal;
