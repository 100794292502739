import * as React from 'react';

import { Svg, SvgPath, SvgProps, addWithConfig } from '@rbilabs/universal-components';

import { primitive } from 'styles/constants/primitives';

const BellIcon = ({ fill = primitive.bk.$bbqBrown, ...props }: SvgProps) => (
  <Svg width="16" height="20px" viewBox="0 0 16 20" fill="none" {...props}>
    <SvgPath
      d="M0.000244141 17V15H2.00024V8C2.00024 6.61667 2.41691 5.3875 3.25024 4.3125C4.08358 3.2375 5.16691 2.53333 6.50024 2.2V1.5C6.50024 1.08333 6.64608 0.729167 6.93774 0.4375C7.22941 0.145833 7.58358 0 8.00024 0C8.41691 0 8.77108 0.145833 9.06274 0.4375C9.35441 0.729167 9.50024 1.08333 9.50024 1.5V2.2C10.8336 2.53333 11.9169 3.2375 12.7502 4.3125C13.5836 5.3875 14.0002 6.61667 14.0002 8V15H16.0002V17H0.000244141ZM8.00024 20C7.45024 20 6.97941 19.8042 6.58774 19.4125C6.19608 19.0208 6.00024 18.55 6.00024 18H10.0002C10.0002 18.55 9.80441 19.0208 9.41274 19.4125C9.02108 19.8042 8.55024 20 8.00024 20Z"
      fill={fill}
    />
  </Svg>
);

export default addWithConfig(BellIcon);
