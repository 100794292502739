import { useCallback, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import { ILocation } from '@rbi-ctg/frontend';
import { IRestaurant } from '@rbi-ctg/store';
import { FilterRestaurantType, OperationalStatus } from 'generated/graphql-gateway';
import { useFavoriteStores } from 'hooks/favorite-stores';
import useDialogModal from 'hooks/use-dialog-modal';
import useErrorModal from 'hooks/use-error-modal';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { isValidPosition } from 'utils/geolocation';
import DEFAULT_SEARCH_RADIUS_M from 'utils/restaurant/default-search-radius-meters';
import { useSortRestaurants } from 'utils/restaurant/sort-restaurants';

import {
  StoreAction,
  searchFavRequested,
  searchFavsError,
  searchFavsSuccess,
  searchNearbyError,
  searchNearbyRequested,
  searchNearbySuccess,
  searchRecentError,
  searchRecentRequested,
  searchRecentSuccess,
} from '../ducks/stores';

import { useNearbyRestaurants, useRestaurants } from './helpers/use-restaurants';
import { ISearchNearbyRestaurants, SearchParams } from './types';

interface ISearchStoresProps {
  storeLocatorDispatch: StoreAction;
}

export const useSearchRestaurants = ({ storeLocatorDispatch }: ISearchStoresProps) => {
  const { isAuthenticated } = useAuthContext();
  const [activeStoreId, setActiveStoreId] = useState('');
  const defaultDiagonal =
    useFlag(LaunchDarklyFlag.RADIUS_FOR_STORE_LOCATOR) || DEFAULT_SEARCH_RADIUS_M;
  const sortRestaurantsByAvailability = useSortRestaurants();

  const { validFavStores } = useFavoriteStores();
  const { formatMessage } = useIntl();
  const [Dialog, openDialog] = useDialogModal({
    modalAppearanceEventMessage: 'Notify user to enable location services',
  });

  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Fetching Restaurants Error',
  });

  // @todo: refactor use-store-locator logic reducer to handle this
  // without the need for this helper function that repeats these 3 calls/effects
  const { fetch: fetchNearbyRestaurants, ...nearbyRestaurants } = useNearbyRestaurants();

  const { fetch: fetchRecentRestaurants, ...recentRestaurants } = useRestaurants(
    FilterRestaurantType.RECENT
  );
  const { fetch: fetchFavoriteRestaurants, ...favoriteRestaurants } = useRestaurants(
    FilterRestaurantType.FAVORITE
  );

  const restaurantsSearch = useCallback(
    (location: ILocation | null, searchParams: SearchParams, fetchFn: Function) => {
      const isValid =
        searchParams.filter === FilterRestaurantType.NEARBY
          ? isValidPosition({ lat: location?.lat, lng: location?.lng })
          : isAuthenticated;

      if (!isValid) {
        searchParams.invalidSearchCallback();
        return;
      }
      const fetchOptions = {
        allowFromCache: false,
      };
      if (location) {
        fetchFn(
          {
            coordinates: {
              // To get the best results from our cache implementation,
              // we should decrease the level of accuracy (currently 7 decimal places = 1/2 inch)
              // to 3 decimal places = 111 meters/football field
              userLat: Number(location.lat.toFixed(3)),
              userLng: Number(location.lng.toFixed(3)),
              searchRadius: searchParams.coordinates?.searchRadius,
            },
            first: searchParams.first,
            status: searchParams.status,
          },
          fetchOptions
        );
      } else {
        fetchFn({}, fetchOptions);
      }
    },
    [isAuthenticated]
  );

  const searchRecentRestaurants = useCallback(
    (location: ILocation | null) => {
      restaurantsSearch(
        location,
        {
          invalidSearchCallback: () => storeLocatorDispatch(searchRecentSuccess([])),
          filter: FilterRestaurantType.RECENT,
        },
        fetchRecentRestaurants
      );
    },
    [fetchRecentRestaurants, restaurantsSearch, storeLocatorDispatch]
  );

  const searchFavRestaurants = useCallback(
    (location: ILocation | null) => {
      restaurantsSearch(
        location,
        {
          invalidSearchCallback: () => storeLocatorDispatch(searchFavsSuccess([])),
          filter: FilterRestaurantType.FAVORITE,
        },
        fetchFavoriteRestaurants
      );
    },
    // Using `validFavStores` to recreate this callback. When this callback
    // is recreated we trigger a search which at the time of this writing
    // looked like in `use-store-locator`:
    // useEffect(() => {
    //   searchFavRestaurants(activeCoordinates);
    // }, [activeCoordinates, searchFavRestaurants]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [restaurantsSearch, fetchFavoriteRestaurants, storeLocatorDispatch, validFavStores]
  );

  const searchNearbyRestaurants = useCallback(
    ({ location }: ISearchNearbyRestaurants) => {
      restaurantsSearch(
        location,
        {
          invalidSearchCallback: () =>
            openDialog({
              message: formatMessage({ id: 'messageAllowLocation' }),
              title: formatMessage({ id: 'titleAllowLocation' }),
            }),
          filter: FilterRestaurantType.NEARBY,
          coordinates: {
            searchRadius: defaultDiagonal,
          },
          first: 20,
          status: OperationalStatus.OPEN,
        },
        fetchNearbyRestaurants
      );
    },
    [defaultDiagonal, fetchNearbyRestaurants, formatMessage, openDialog, restaurantsSearch]
  );

  /**
   * Manages the retrieval and processing of restaurant data (nearby, recent, and favorite)
   * using separate `useEffect` hooks for each data type.
   */

  // --- Nearby Restaurants ---
  useEffect(() => {
    if (nearbyRestaurants.error) {
      storeLocatorDispatch(searchNearbyError(nearbyRestaurants.error));
      openErrorDialog({
        message: formatMessage({ id: 'errorRetrievingRestaurants' }),
        error: nearbyRestaurants.error,
      });
      return;
    }

    if (nearbyRestaurants.loading) {
      storeLocatorDispatch(searchNearbyRequested());
      return;
    }

    const stores = (nearbyRestaurants.stores ?? []) as IRestaurant[];
    const sortedStores = sortRestaurantsByAvailability(stores);
    storeLocatorDispatch(searchNearbySuccess(sortedStores));
  }, [
    formatMessage,
    nearbyRestaurants.stores,
    nearbyRestaurants.error,
    nearbyRestaurants.loading,
    openErrorDialog,
    storeLocatorDispatch,
    sortRestaurantsByAvailability,
  ]);

  // --- Recent Restaurants ---
  useEffect(() => {
    if (recentRestaurants.error) {
      storeLocatorDispatch(searchRecentError(recentRestaurants.error));
      return;
    }

    if (recentRestaurants.loading) {
      storeLocatorDispatch(searchRecentRequested());
      return;
    }
    const stores = (recentRestaurants.data?.restaurants?.nodes ?? []) as IRestaurant[];
    const sortedStores = sortRestaurantsByAvailability(stores);
    storeLocatorDispatch(searchRecentSuccess(sortedStores));
  }, [
    formatMessage,
    openErrorDialog,
    recentRestaurants.data,
    recentRestaurants.error,
    recentRestaurants.loading,
    sortRestaurantsByAvailability,
    storeLocatorDispatch,
  ]);

  // --- Favorite Restaurants ---
  useEffect(() => {
    if (favoriteRestaurants.error) {
      storeLocatorDispatch(searchFavsError(favoriteRestaurants.error));
      return;
    }

    if (favoriteRestaurants.loading) {
      storeLocatorDispatch(searchFavRequested());
      return;
    }
    const stores = (favoriteRestaurants.data?.restaurants?.nodes ?? []) as IRestaurant[];
    const sortedStores = sortRestaurantsByAvailability(stores);
    storeLocatorDispatch(searchFavsSuccess(sortedStores));
  }, [
    favoriteRestaurants.data,
    favoriteRestaurants.error,
    favoriteRestaurants.loading,
    formatMessage,
    openErrorDialog,
    storeLocatorDispatch,
    sortRestaurantsByAvailability,
  ]);

  return {
    activeStoreId,
    ErrorDialog,
    Dialog,
    searchNearbyRestaurants,
    setActiveStoreId,
    searchFavRestaurants,
    searchRecentRestaurants,
  };
};
