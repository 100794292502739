import React, { FC, useState } from 'react';

import { Box, Checkbox, Divider, Switch, Text } from '@rbilabs/universal-components';
import { IntlFormatters, useIntl } from 'react-intl';

import { CountrySelect } from 'components/country-select';
import { TextInput } from 'components/ucl/text-input';
import { VisuallyHidden } from 'components/ucl/visually-hidden';
import { usePaymentCheckoutRedesignExperiment } from 'experiments/use-payment-checkout-redesign-experiment';
import { useValidateCCNumberInClientExperiment } from 'experiments/use-validate-cc-number-in-client';
import useCreditCardFormProps from 'hooks/form/use-credit-card-form-props';
import { useIsMobileBreakpoint } from 'hooks/use-media-query';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  PaymentFieldVariations,
  defaultPaymentFieldVariation,
} from 'state/launchdarkly/variations';
import { CardType } from 'state/payment/types';
import { primitive } from 'styles/constants/primitives';
import { isNative } from 'utils/environment';
import noop from 'utils/noop';
import { IPaymentErrors, IPaymentState, excludeNumeric } from 'utils/payment';

import { SAVE_CARD_DISCLAIMER } from './constants';
import FirstDataCreditCardInputsRedesign from './first-data-credit-card-form-inputs/first-data-credit-card-form-inputs-redesign';
import FirstDataCreditCardInputs from './first-data-credit-card-form-inputs/first-data-credit-card-inputs';
import {
  DefaultContainer,
  FormContainer,
  StyledBillingAptWrapper,
  StyledBillingCityWrapper,
  StyledBillingStateWrapper,
  StyledBillingZipWrapper,
  StyledCountrySelectWrapper,
  StyledNameOnCardWrapper,
  StyledSaveCardContainer,
  StyledStreetAddressWrapper,
} from './styled';

interface GetErrorMessageOptions {
  errors: IPaymentErrors;
  formatMessage: IntlFormatters['formatMessage'];
}

export const getErrorMessage = ({
  errors,
  formatMessage,
}: GetErrorMessageOptions): string | null => {
  const errorKeys = Object.keys(errors);
  const numberOfErrors = errorKeys.filter(error => errors[error]).length;

  if (!numberOfErrors) {
    return null;
  }

  const id = numberOfErrors === 1 ? 'thereIsAnErrorInThisForm' : 'thereAreErrorsInThisForm';
  return formatMessage({ id }, { numberOfErrors });
};

interface ICreditCardFormInputs {
  errors: IPaymentErrors;
  isDelivery?: boolean;
  onChange: (name: string, value: string) => void;
  paymentValues: IPaymentState;
  showSaveCard?: boolean;
  withBorder?: boolean;
  withPadding?: boolean;
  ariaLabel?: string;
  supportedCardTypes?: CardType[];
  validateCreditCardForm?: () => boolean;
  isCheckoutRedesign?: boolean;
  isDefaultPayment?: boolean;
  setIsDefaultPayment?: (value: boolean) => void;
}

const CreditCardFormInputs: FC<React.PropsWithChildren<ICreditCardFormInputs>> = ({
  errors,
  isDelivery = false,
  onChange,
  paymentValues,
  showSaveCard = true,
  withBorder = false,
  withPadding = true,
  ariaLabel,
  supportedCardTypes = [],
  validateCreditCardForm,
  isDefaultPayment = false,
  setIsDefaultPayment = noop,
}) => {
  const paymentFieldVariations =
    useFlag<PaymentFieldVariations>(LaunchDarklyFlag.PAYMENT_FIELD_VARIATIONS) ||
    defaultPaymentFieldVariation;
  const isPaymentRedesignEnabled = usePaymentCheckoutRedesignExperiment();

  const { feCountryCode } = useLocale();
  // If the country field is hidden from the form, use the user's current locale
  const country = paymentFieldVariations.country ? paymentValues.billingCountry : feCountryCode;

  // retrieve additional credit card form props
  const { postalCodeInputProps, regionInputProps } = useCreditCardFormProps(country ?? undefined);
  const { formatMessage } = useIntl();
  const errorMessage = getErrorMessage({ errors, formatMessage });
  const isMobileWeb = useIsMobileBreakpoint();

  // NativeBase is having a weird behavior with the Checkbox
  // The onChange handler passed to it is always stale, unless we force a re-render by changing its key
  const checkboxKey = React.useMemo(() => JSON.stringify(paymentValues), [paymentValues]);

  const [didBlur, setDidBlur] = useState({
    cardNumber: !!paymentValues.cardNumber,
    expiry: !!paymentValues.expiry,
    cvv: !!paymentValues.cvv,
    billingZip: !!paymentValues.billingZip,
  });

  const validateCCExperiment = useValidateCCNumberInClientExperiment();

  const validateOnBlur = validateCCExperiment
    ? (fieldName: string) => {
        setDidBlur({ ...didBlur, [fieldName]: true } as any);
        validateCreditCardForm?.();
      }
    : noop;

  return (
    <>
      {!!errorMessage && <VisuallyHidden accessibilityLabel={errorMessage} role="alert" />}
      <FormContainer
        withPadding={withPadding}
        withBorder={withBorder}
        accessibilityLabel={ariaLabel}
        marginTop="$6"
        isPaymentRedesignEnabled={isPaymentRedesignEnabled}
      >
        {isPaymentRedesignEnabled && (
          <FirstDataCreditCardInputsRedesign
            didBlur={didBlur}
            validateOnBlur={validateOnBlur}
            onChange={onChange}
            supportedCardTypes={supportedCardTypes}
            paymentValues={paymentValues}
            validateCreditCardForm={validateCreditCardForm}
            errors={errors}
            paymentFieldVariations={paymentFieldVariations}
          />
        )}

        {paymentFieldVariations.name && (
          <StyledNameOnCardWrapper {...(isPaymentRedesignEnabled ? { marginBottom: '0' } : null)}>
            <TextInput
              borderColor={primitive.$blackOpacity30}
              accessibilityLabel={formatMessage({ id: 'nameOnCard' })}
              onChangeText={(value: string) => onChange('nameOnCard', value)}
              label={formatMessage({ id: 'nameOnCard' })}
              value={paymentValues.nameOnCard}
              placeholder={isPaymentRedesignEnabled ? 'John Smith' : ''}
              placeholderTextColor={
                errors.nameOnCard ? primitive.$error : primitive.$blackOpacity50
              }
              required
              testID="nameOnCard"
              errorMessage={errors.nameOnCard}
              autoComplete="name"
            />
          </StyledNameOnCardWrapper>
        )}

        {!isPaymentRedesignEnabled && (
          <FirstDataCreditCardInputs
            didBlur={didBlur}
            validateOnBlur={validateOnBlur}
            onChange={onChange}
            supportedCardTypes={supportedCardTypes}
            paymentValues={paymentValues}
            validateCreditCardForm={validateCreditCardForm}
            errors={errors}
            paymentFieldVariations={paymentFieldVariations}
          />
        )}

        {isDelivery && (
          <Checkbox
            key={checkboxKey}
            onChange={value => onChange('billingAddressSameAsDelivery', value ? 'true' : 'false')}
            value={String(paymentValues.billingAddressSameAsDelivery)}
            isChecked={paymentValues.billingAddressSameAsDelivery}
            testID="billing-same-as-delivery"
          >
            {formatMessage({ id: 'billingAddressIsSameAsDeliveryAddress' })}
          </Checkbox>
        )}
        {isPaymentRedesignEnabled && paymentFieldVariations.zip && (
          <StyledBillingZipWrapper marginBottom="0">
            {/* Additional props are spread: label, maxLength, pattern */}
            <TextInput
              borderColor={primitive.$blackOpacity30}
              accessibilityLabel={formatMessage({ id: 'zipCode' })}
              onChangeText={value => onChange('billingZip', value)}
              value={paymentValues.billingZip}
              required
              testID="billingZip"
              onBlur={() => validateOnBlur('billingZip')}
              {...(isPaymentRedesignEnabled
                ? {
                    placeholder: formatMessage({ id: 'zipCode' }),
                    placeholderTextColor: primitive.$blackOpacity30,
                  }
                : null)}
              errorMessage={
                validateCCExperiment && !didBlur.billingZip && !errors.didAttemptSubmit
                  ? ''
                  : errors.billingZip
              }
              placeholderTextColor={
                !didBlur.billingZip && !errors.didAttemptSubmit
                  ? primitive.$blackOpacity50
                  : primitive.$error
              }
              autoComplete="postal-code"
              maxLength={10}
              isDisabled={isDelivery && paymentValues.billingAddressSameAsDelivery}
              {...postalCodeInputProps}
            />
          </StyledBillingZipWrapper>
        )}
        {isPaymentRedesignEnabled && (
          <>
            <Divider mb="$6" backgroundColor="blackOpacity.30" />
            <DefaultContainer>
              <Text fontSize="md">{formatMessage({ id: 'setAsDefaultPayment' })}</Text>
              <Switch
                value={isDefaultPayment}
                onToggle={() => {
                  setIsDefaultPayment(!isDefaultPayment);
                }}
                offTrackColor={primitive.$blackOpacity50}
                onTrackColor={primitive.bk.$bbqBrown}
              />
            </DefaultContainer>
          </>
        )}
      </FormContainer>
      {!isPaymentRedesignEnabled && (
        <FormContainer withPadding={withPadding} withBorder={false}>
          {paymentFieldVariations.addressLine1 && (
            <StyledStreetAddressWrapper>
              <TextInput
                accessibilityLabel={formatMessage({ id: 'streetAddress' })}
                onChangeText={value => onChange('billingStreetAddress', value)}
                label={formatMessage({ id: 'streetAddress' })}
                value={paymentValues.billingStreetAddress || ''}
                required
                testID="billingStreetAddress"
                errorMessage={errors.billingStreetAddress}
                autoComplete="street-address"
                isDisabled={isDelivery && paymentValues.billingAddressSameAsDelivery}
              />
            </StyledStreetAddressWrapper>
          )}
          {paymentFieldVariations.addressLine2 && (
            <StyledBillingAptWrapper>
              <TextInput
                accessibilityLabel={formatMessage({ id: 'apartment' })}
                onChangeText={value => onChange('billingApt', value)}
                label={formatMessage({ id: 'aptOrSuite' })}
                value={paymentValues.billingApt || ''}
                testID="billingApt"
                errorMessage={errors.billingApt}
                autoComplete="street-address"
                isDisabled={isDelivery && paymentValues.billingAddressSameAsDelivery}
              />
            </StyledBillingAptWrapper>
          )}
          {paymentFieldVariations.city && (
            <StyledBillingCityWrapper>
              <TextInput
                accessibilityLabel={formatMessage({ id: 'city' })}
                label={formatMessage({ id: 'city' })}
                onChangeText={value => onChange('billingCity', value)}
                value={paymentValues.billingCity || ''}
                required
                testID="billingCity"
                errorMessage={errors.billingCity}
                autoComplete="postal-address-locality"
                isDisabled={isDelivery && paymentValues.billingAddressSameAsDelivery}
              />
            </StyledBillingCityWrapper>
          )}
          {paymentFieldVariations.state && (
            <StyledBillingStateWrapper>
              <TextInput
                accessibilityLabel={formatMessage({ id: 'state' })}
                onChangeText={value => onChange('billingState', value)}
                value={excludeNumeric(paymentValues.billingState ?? '')}
                required
                testID="billingState"
                errorMessage={errors.billingState}
                autoComplete="postal-address-region"
                isDisabled={isDelivery && paymentValues.billingAddressSameAsDelivery}
                {...regionInputProps}
              />
            </StyledBillingStateWrapper>
          )}
          {paymentFieldVariations.zip && (
            <StyledBillingZipWrapper>
              {/* Additional props are spread: label, maxLength, pattern */}
              <TextInput
                accessibilityLabel={formatMessage({ id: 'zipCode' })}
                onChangeText={value => onChange('billingZip', value)}
                value={paymentValues.billingZip}
                required
                testID="billingZip"
                onBlur={() => validateOnBlur('billingZip')}
                errorMessage={
                  validateCCExperiment && !didBlur.billingZip && !errors.didAttemptSubmit
                    ? ''
                    : errors.billingZip
                }
                autoComplete="postal-code"
                maxLength={10}
                isDisabled={isDelivery && paymentValues.billingAddressSameAsDelivery}
                {...postalCodeInputProps}
              />
            </StyledBillingZipWrapper>
          )}
          {paymentFieldVariations.country && (
            <StyledCountrySelectWrapper>
              <CountrySelect
                label={formatMessage({ id: 'country' })}
                name="billingCountry"
                testID="billingCountry"
                required
                value={paymentValues.billingCountry ?? ''}
                onChange={value => onChange('billingCountry', value)}
                disabled={!!(isDelivery && paymentValues.billingCountry)}
              />
            </StyledCountrySelectWrapper>
          )}
        </FormContainer>
      )}
      {showSaveCard && (
        <StyledSaveCardContainer {...(isPaymentRedesignEnabled && { paddingLeft: '$2' })}>
          <Checkbox
            testID="saveCard"
            key={checkboxKey}
            value={String(paymentValues.saveCard)}
            isChecked={paymentValues.saveCard}
            onChange={value => onChange('saveCard', value ? 'true' : 'false')}
            accessibilityLabel={formatMessage({ id: SAVE_CARD_DISCLAIMER })}
            _text={{ fontSize: 'sm' }}
            justifyContent="flex-start"
            marginTop={{ md: '$3', base: 0 }}
          >
            <Box
              {...(!isNative && isMobileWeb ? { flex: 1 } : null)}
              marginTop={{ base: '$3', md: 0 }}
              paddingTop={{ base: '$3', md: 0 }}
              paddingLeft="$2"
            >
              {isPaymentRedesignEnabled
                ? formatMessage({ id: 'saveCardDisclaimerRedesign' })
                : formatMessage({ id: SAVE_CARD_DISCLAIMER })}
            </Box>
          </Checkbox>
        </StyledSaveCardContainer>
      )}
    </>
  );
};

export default CreditCardFormInputs;
