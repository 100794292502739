import { extendTheme } from '@rbilabs/universal-components/themes';
import { bkTheme } from '@rbilabs/universal-components/themes/bk';

import { designTokens } from '../constants/design-tokens';

// Overriding theme for some own BK-US styling
export const theme = extendTheme(bkTheme, {
  nativeBaseTheme: {
    components: {
      // @ts-ignore Variants should be modified in UCL
      Checkbox: {
        baseStyle: {
          _hover: {
            _interactionBox: {
              bg: 'blackOpacity.5',
            },
            borderColor: 'token.border-color-hover',
          },
        },
      },
    },
    colors: {
      // @ts-expect-error TS(2322) FIXME: Type '{ __legacyToken: DesignTokens; }' is not ass... Remove this comment to see the full error message
      __legacyToken: designTokens,
    },
  },
});
