import { NbModal, makeUclComponent } from '@rbilabs/universal-components';

import { primitive } from 'styles/constants/primitives';

const ModalHeading = makeUclComponent(NbModal.Header).withConfig({
  _text: {
    textAlign: 'center',
    fontFamily: 'heading',
    accessibilityRole: 'header',
    // @ts-expect-error TS(2322) FIXME: Type '{ textAlign: "center"; fontFamily: string; a... Remove this comment to see the full error message
    accessibilityLevel: '2',
    fontWeight: 'normal',
    fontSize: '4xl',
    color: primitive.bk.$bbqBrown,
    letterSpacing: '-0.42px',
    maxWidth: {
      md: '500px',
    },
  },
});

export default ModalHeading;
