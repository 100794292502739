import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

import { ICmsQuestCard } from './types';

const SYSTEMWIDE_QUEST_TYPENAME: ICmsQuestCard['__typename'] = 'SystemwideQuest';

// type guards
export const isCmsQuestCard = (param: unknown): param is ICmsQuestCard =>
  isObject(param) && (param as any)?.__typename === SYSTEMWIDE_QUEST_TYPENAME;

export const isCmsQuestCardList = (param: unknown): param is ICmsQuestCard[] =>
  isArray(param) && param.every(isCmsQuestCard);
