import React, { useCallback, useRef } from 'react';

import { Box, HStack, makeUclComponent } from '@rbilabs/universal-components';
import { NativeSyntheticEvent, TextInputChangeEventData } from 'react-native';

// TODO: RN - import it from utils/internals
import { useIdCounter } from 'hooks/use-id-counter';
import { combineAttributeStrings } from 'utils/index';

import { FieldSet } from '../field-set';
import { TextInput } from '../text-input';

import { DateInputName, FieldsAutocomplete, IDateInputProps } from './types';

const getDateInputLabel = ({ label = '', required = false }) => {
  if (label.endsWith('*')) {
    return label;
  }

  return combineAttributeStrings(label, required ? '*' : undefined);
};

const AUTOCOMPLETE_MAP: {
  [k in NonNullable<IDateInputProps['autoComplete']>]: FieldsAutocomplete;
} = {
  bday: { year: 'birthdate-year', month: 'birthdate-month', day: 'birthdate-day' },
};

/**
 * Allows entry of a date via text inputs for year, month, day
 */
const DateInput: React.FC<React.PropsWithChildren<IDateInputProps>> = ({
  day,
  disabled,
  hintMessage,
  errorMessage,
  month,
  onBlur,
  onChange,
  required,
  autoComplete: autoCompleteProp,
  testID,
  year,
  label,
  labelYear,
  labelMonth,
  labelDay,
  labelYearFormat,
  labelMonthFormat,
  labelDayFormat,
  ...props
}) => {
  const messageId = useIdCounter('message');
  const autoComplete: FieldsAutocomplete = AUTOCOMPLETE_MAP[autoCompleteProp!] || {};

  const isFocusedStates = useRef<{ [key in DateInputName]: boolean }>({
    year: false,
    month: false,
    day: false,
  });

  function handleBlur(inputName: DateInputName) {
    isFocusedStates.current[inputName] = false;

    // Doing a timeout the `handleFocus` will be triggered in between the above set false, and the below check. This is necessary due to handleBlur is called before handleFocus.
    setTimeout(() => {
      if (
        isFocusedStates.current.year ||
        isFocusedStates.current.month ||
        isFocusedStates.current.day
      ) {
        return;
      }
      onBlur?.(inputName);
    }, 1);
  }

  function handleFocus(inputName: DateInputName) {
    isFocusedStates.current[inputName] = true;
  }

  const handleOnChange = useCallback(
    (inputName: DateInputName, event: NativeSyntheticEvent<TextInputChangeEventData>) => {
      const inputText = event.nativeEvent.text;
      // Prevent non-numeric values from being entered
      if (!inputText.match(/^\d*$/)) {
        event.preventDefault();
        event.stopPropagation();
        return;
      }
      if (onChange) {
        onChange(inputName, inputText);
      }
    },
    [onChange]
  );

  return (
    <FieldSet
      disabled={disabled}
      label={getDateInputLabel({ label, required })}
      messageId={messageId}
      errorMessage={errorMessage}
      hintMessage={hintMessage}
      testID={testID ? `${testID}-message` : undefined}
      {...props}
    >
      <HStack space="2" minWidth="260px" height={'$13.5'}>
        <Box flex="1">
          <TextInput
            type="text"
            placeholder={labelYear}
            value={year}
            onChange={(event: NativeSyntheticEvent<TextInputChangeEventData>) =>
              handleOnChange('year', event)
            }
            maxLength={4}
            keyboardType="numeric"
            autoComplete={autoComplete.year}
            accessibilityLabel={`${label} ${labelYear}`}
            hintMessage={labelYearFormat}
            testID={testID ? `${testID}-year` : undefined}
            isDisabled={disabled}
            onBlur={() => handleBlur('year')}
            onFocus={() => handleFocus('year')}
            isInvalid={!!errorMessage}
          />
        </Box>
        <Box flex="1">
          <TextInput
            type="text"
            maxLength={2}
            keyboardType="numeric"
            autoComplete={autoComplete.month}
            placeholder={labelMonth}
            accessibilityLabel={`${label} ${labelMonth}`}
            value={month}
            onChange={(event: NativeSyntheticEvent<TextInputChangeEventData>) =>
              handleOnChange('month', event)
            }
            hintMessage={labelMonthFormat}
            testID={testID ? `${testID}-month` : undefined}
            isDisabled={disabled}
            onBlur={() => handleBlur('month')}
            onFocus={() => handleFocus('month')}
            isInvalid={!!errorMessage}
          />
        </Box>

        <Box flex="1">
          <TextInput
            type="text"
            maxLength={2}
            keyboardType="numeric"
            autoComplete={autoComplete.day}
            placeholder={labelDay}
            accessibilityLabel={`${label} ${labelDay}`}
            value={day}
            onChange={(event: NativeSyntheticEvent<TextInputChangeEventData>) =>
              handleOnChange('day', event)
            }
            hintMessage={labelDayFormat}
            testID={testID ? `${testID}-day` : undefined}
            isDisabled={disabled}
            onBlur={() => handleBlur('day')}
            onFocus={() => handleFocus('day')}
            isInvalid={!!errorMessage}
          />
        </Box>
      </HStack>
    </FieldSet>
  );
};

export default makeUclComponent(DateInput);
