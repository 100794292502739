import React from 'react';

import { Box } from '@rbilabs/universal-components';

import UnstyledButton from 'components/unstyled-button';

const Container = Box.withConfig({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  padding: 0,

  paddingTop: {
    base: '$6',
    md: 0,
  },

  paddingRight: {
    md: 0,
  },

  paddingBottom: {
    md: '$4',
  },

  paddingLeft: {
    md: '$1',
  },
  marginY: {
    base: '$0',
    md: '$2',
  },
});

const MoreOptionsButton = UnstyledButton.withConfig({
  _text: {
    color: '__legacyToken.text-button-secondary',
    variant: '__legacyToken.text-style-button-small',
    textTransform: Styles.textTransform.headlines,
  },
});

interface IMoreOptions {
  openFilterModal: () => void;
  buttonText: string;
}
export const MoreOptions: React.FC<React.PropsWithChildren<IMoreOptions>> = ({
  openFilterModal,
  buttonText,
}) => {
  return (
    <Container>
      <MoreOptionsButton onPress={openFilterModal} accessibilityRole="button">
        {buttonText}
      </MoreOptionsButton>
    </Container>
  );
};
