import React from 'react';

import { HStack, Icon, Text, VStack } from '@rbilabs/universal-components';

import { useEnableMapListExperiment } from 'pages/store-locator/use-enable-map-list-experiment';
import { primitive } from 'styles/constants/primitives';

interface StoreCardMessagesProps {
  errorMessage?: string;
  accessInstructions?: string;
}

export const StoreCardMessages: React.FC<StoreCardMessagesProps> = ({
  errorMessage,
  accessInstructions,
}) => {
  const enableMapListExperiment = useEnableMapListExperiment();
  if (!errorMessage && !accessInstructions) {
    return null;
  }

  return (
    <VStack marginTop={enableMapListExperiment ? undefined : '$1.5'}>
      {accessInstructions && (
        <Text variant="copyTwo" color={primitive.$blackOpacity50}>
          {accessInstructions}
        </Text>
      )}
      {errorMessage && (
        <HStack
          focusable
          backgroundColor={'#FFCFAD'}
          padding={'$1'}
          borderRadius={4}
          width={{ base: '240px', lg: '260px' }}
          mt={'$2'}
        >
          <Icon size="xs" marginRight="$1" marginTop={{ base: '$0', md: '$0.5' }} variant="info" />
          <Text variant="copyTwo">{errorMessage}</Text>
        </HStack>
      )}
    </VStack>
  );
};
