import React from 'react';

import { usePropsWithFormikFormControlName } from '@rbilabs/universal-components';
import { useField } from 'formik';

import { CountrySelect } from 'components/country-select';
import { ICountrySelectProps } from 'components/country-select/types';

export const FormikCountrySelect: React.FC<React.PropsWithChildren<
  ICountrySelectProps
>> = props => {
  const { name, ...rest } = usePropsWithFormikFormControlName(props);
  const [field, meta] = useField(name);

  return (
    <CountrySelect
      {...field}
      errorMessage={meta.touched && meta.error ? meta.error : undefined}
      {...rest}
    />
  );
};
