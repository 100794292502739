import { Box } from '@rbilabs/universal-components';
import { useWindowDimensions } from 'react-native';
import { bkPrimitives } from 'ucl/themes/bk/primitives';

import { useIsDesktopLargeBreakpoint } from 'hooks/use-media-query';
import {
  WEB_APP_MAX_WIDTH,
  WEB_APP_MAX_WIDTH_DESKTOP_LARGE,
  WEB_APP_MODAL_CONTENT_MAX_WIDTH,
} from 'utils/constants';

export const LayoutContainer = Box.withConfig(
  ({
    isModal,
    isFullContainer = false,
    backgroundColor,
  }: {
    isModal?: boolean;
    isFullContainer?: boolean;
    backgroundColor?: keyof typeof bkPrimitives.colors;
  }) => {
    const dimension = useWindowDimensions();
    const isDesktop = useIsDesktopLargeBreakpoint();
    const maxWidth = isDesktop
      ? isModal
        ? WEB_APP_MODAL_CONTENT_MAX_WIDTH
        : WEB_APP_MAX_WIDTH_DESKTOP_LARGE
      : WEB_APP_MAX_WIDTH;

    const totalPaddingX = (dimension.width - maxWidth) / 2;
    let paddingX = totalPaddingX > 0 ? totalPaddingX : '$4';
    if (isFullContainer) {
      paddingX = 0;
    }

    return {
      _web: {
        flex: 1,
      },
      paddingX,
      flexGrow: 1,
      backgroundColor,
    };
  }
);

export const InnerLayout = Box.withConfig({
  _web: {
    flex: 1,
  },
  paddingX: {
    md: '$16',
    lg: '$32',
    base: '0',
  },
  paddingY: '$4',
  flexGrow: 1,
});
