import { useEffect } from 'react';

import { useRoute } from 'hooks/navigation/use-route';
import { usePrevious } from 'hooks/use-previous';
import { BranchEventNames } from 'state/branch/branch-event-names';
import { logBranchEvent } from 'state/branch/utils';
import { useMParticleContext } from 'state/mParticle';
import { useStoreContext } from 'state/store';

import { logFirebasePageView } from './log-firebase-page-view';

// Only used for react-router and WEB
export default function useLogPageView() {
  const { pathname } = useRoute();
  const previousPathname = usePrevious(pathname);
  const { logPageView } = useMParticleContext();
  const { store } = useStoreContext();

  useEffect(() => {
    if (previousPathname !== pathname) {
      logPageView(pathname, store);
      logFirebasePageView(pathname);
      logBranchEvent(BranchEventNames.PAGE_VIEW);
    }
  }, [logPageView, pathname, store, previousPathname]);
}
