import { LOCALE_SELECTED_QUERY_PARAM, SORTED_PROD_SUPPORTED_LOCALES } from 'state/intl/constants';

export function inferHasShownLocaleSelectorFromUrlParams(): boolean {
  return Boolean(new URL(window.location.href).searchParams.get(LOCALE_SELECTED_QUERY_PARAM));
}

export function inferHasShownLocaleSelector() {
  const hasSelectedLocale = inferHasShownLocaleSelectorFromUrlParams();
  const hasSingleSupportedLocale = SORTED_PROD_SUPPORTED_LOCALES.length < 2;

  return hasSelectedLocale || hasSingleSupportedLocale;
}
