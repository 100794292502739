import { Box, Radio, withConfig } from '@rbilabs/universal-components';

export const LanguageSelectionContainer = withConfig(Radio.Group, {
  marginTop: 0,
  marginX: 'auto',
  marginBottom: '5',
});

export const Disclaimer = Box.withConfig({
  marginBottom: '6',
  fontSize: 'sm',
  textAlign: 'center',
});

export const ModalAdditionalContentWrapper = Box.withConfig({
  paddingX: '5',
});

export const LanguageSelectionCell = Box.withConfig({
  paddingX: '6',
  paddingY: '3',
});

export const ActionsWrapper = Box.withConfig({
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'center',
  marginBottom: '8',
});
