import { useCallback } from 'react';

import { useRoute } from 'hooks/navigation/use-route';
import { appendObjectToQueryString } from 'hooks/navigation/utils';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';

const reloadParam = 'error-reload';

interface IErrorReloadParams {
  [reloadParam]: string;
}

export function useReload() {
  const { logEvent } = useMParticleContext();
  const { params } = useRoute<IErrorReloadParams>();

  return useCallback(() => {
    logEvent(CustomEventNames.APP_ERROR_RELOAD, EventTypes.Other);

    // Send the user to the home page to avoid a reload loop
    if (params[reloadParam]) {
      window.location.href = '/';
      return;
    }

    params[reloadParam] = '1';
    // This will fire a reload
    window.location.href = appendObjectToQueryString('/', params);
  }, [logEvent, params]);
}
