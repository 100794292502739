import { useState } from 'react';

import { Text, View } from 'react-native';

import { useControllableState } from '../../hooks';

export function useMeasure0ChWidth() {
  const [width, setWidth] = useState(0);
  const measurer = width ? null : (
    <View
      style={{ position: 'absolute', opacity: 0 }}
      onLayout={event => {
        setWidth(event.nativeEvent.layout.width);
      }}
    >
      <Text style={{ fontVariant: ['tabular-nums'] }}>0</Text>
    </View>
  );

  return [width, measurer] as const;
}

type useBoundedNumberStateParams = {
  defaultValue: number;
  max: number;
  min: number;
  onChangeValue?: (nextValue: number) => void;
  value?: number;
};

export function useBoundedNumberState({
  value,
  onChangeValue,
  defaultValue,
  min,
  max,
}: useBoundedNumberStateParams) {
  const [numberValue, setNumberValue] = useControllableState({
    defaultValue,
    value,
    handleValueChange: onChangeValue,
  });

  const setNumberValueWithinBoundaries = (number: number) => {
    setNumberValue(Math.min(Math.max(number, min), max));
  };

  return [numberValue, setNumberValueWithinBoundaries] as const;
}
