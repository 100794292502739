import { combineReducers } from '@reduxjs/toolkit';

import { contentCardsSlice } from './content-cards/content-cards.slice';
import { initialState as initialOffersState, offersSlice } from './offers/offers.slice';
import { initialState as initialRewardsState, rewardsSlice } from './rewards/rewards.slice';
import { initialState as initialUserState, userSlice } from './user/user.slice';
import { loyaltyWalmartSlice } from './walmart/walmart.slice';

export const initialLoyaltyState = {
  offers: initialOffersState,
  rewards: initialRewardsState,
  user: initialUserState,
};

export const loyaltyReducer = combineReducers({
  offers: offersSlice.reducer,
  rewards: rewardsSlice.reducer,
  user: userSlice.reducer,
  walmart: loyaltyWalmartSlice.reducer,
  contentCards: contentCardsSlice.reducer,
});
