import React from 'react';

import { makeUclComponent } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import Picture from 'components/picture';
import { useFeatureLoyaltyUi } from 'hooks/use-feature-loyalty-ui';

import { DefaultLoyaltyLogo, PictureContainer } from './styled';
import { ILoyaltyRewardsLogo } from './types';

const LoyaltyRewardsLogo: React.FC<React.PropsWithChildren<ILoyaltyRewardsLogo>> = ({
  pictureSize,
  ...props
}) => {
  const { featureLoyaltyUiLoading, featureLoyaltyUi } = useFeatureLoyaltyUi();
  const { formatMessage } = useIntl();
  const rewardLogo = props.inverted
    ? featureLoyaltyUi?.rewardsLogoInverted?.locale
    : featureLoyaltyUi?.rewardsLogo?.locale;

  if (!rewardLogo && featureLoyaltyUiLoading) {
    return null;
  }

  // If custom logo hasn't been uploaded in Sanity, use default one
  if (!featureLoyaltyUi) {
    return <DefaultLoyaltyLogo variant="BkLogoRoyalPerks" />;
  }

  return (
    <PictureContainer testID="bk-rewards-logo" {...props}>
      <Picture
        image={rewardLogo}
        alt={formatMessage({ id: 'royalPerks' })}
        objectFitContain
        lazy={false}
        lqOff
        {...pictureSize}
      />
    </PictureContainer>
  );
};

export default makeUclComponent(LoyaltyRewardsLogo);
