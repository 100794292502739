import { updateId } from 'expo-updates';
import { Platform } from 'react-native';

import { appVersion, binaryRuntimeAppVersionWithBuildNumber } from 'utils/app-version-info';
import { getCurrentCommitHash } from 'utils/environment';

import { withHeaders } from './with-headers';

const getApolloVersion = (): string => {
  const bin = binaryRuntimeAppVersionWithBuildNumber ?? 'no-bin';
  const rv = appVersion ?? 'no-rv';
  const uid = (updateId ?? 'no-uid').slice(-12); // get last 12 of UUID
  const hash = getCurrentCommitHash().substring(0, 7); // get first 7
  return `${bin}-${rv}-${uid}-${hash}`;
};

const VERSION = getApolloVersion();
const NAME = `wl-rn-${Platform.OS}`; // TODO: frontend forks should replace "wl" with their own name.

export const withClientInfo = withHeaders(() => {
  return {
    'apollographql-client-name': NAME,
    'apollographql-client-version': VERSION,
  };
});
