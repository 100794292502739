import { Svg, SvgPath, SvgProps, registerSVG } from '../../../components/svg';

export function Applepay(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 165 105">
      <SvgPath d="M150.698 0H14.823c-.566 0-1.133 0-1.698.003-.477.004-.953.009-1.43.022-1.039.028-2.087.09-3.113.274a10.51 10.51 0 0 0-2.958.975 9.932 9.932 0 0 0-4.35 4.35 10.463 10.463 0 0 0-.975 2.96C.113 9.611.052 10.658.024 11.696c-.013.477-.019.953-.022 1.43C0 13.69 0 14.256 0 14.823v76.318c0 .567 0 1.132.002 1.699.003.476.009.953.022 1.43.028 1.036.09 2.084.275 3.11a10.46 10.46 0 0 0 .974 2.96 9.897 9.897 0 0 0 1.83 2.52 9.874 9.874 0 0 0 2.52 1.83c.947.483 1.917.79 2.96.977 1.025.183 2.073.245 3.112.273.477.011.953.018 1.43.02.565.004 1.132.004 1.698.004h135.875c.565 0 1.132 0 1.697-.004a79.71 79.71 0 0 0 1.431-.02c1.037-.028 2.085-.09 3.113-.273a10.478 10.478 0 0 0 2.958-.977 9.955 9.955 0 0 0 4.35-4.35c.483-.947.789-1.917.974-2.96.186-1.026.246-2.074.274-3.11.013-.477.02-.954.022-1.43.004-.567.004-1.132.004-1.699V14.824c0-.567 0-1.133-.004-1.699a63.067 63.067 0 0 0-.022-1.429c-.028-1.038-.088-2.085-.274-3.112a10.4 10.4 0 0 0-.974-2.96 9.941 9.941 0 0 0-4.35-4.35A10.52 10.52 0 0 0 156.939.3c-1.028-.185-2.076-.246-3.113-.274a71.413 71.413 0 0 0-1.431-.022C151.83 0 151.263 0 150.698 0z" />
      <SvgPath
        fill="#FFF"
        d="m150.698 3.532 1.672.003c.452.003.905.008 1.36.02.792.022 1.719.065 2.583.22.75.135 1.38.34 1.984.648a6.392 6.392 0 0 1 2.804 2.807c.306.6.51 1.226.645 1.983.154.854.197 1.783.218 2.58.013.45.019.9.02 1.36.005.557.005 1.113.005 1.671v76.318c0 .558 0 1.114-.004 1.682-.002.45-.008.9-.02 1.35-.022.796-.065 1.725-.221 2.589a6.855 6.855 0 0 1-.645 1.975 6.397 6.397 0 0 1-2.808 2.807c-.6.306-1.228.512-1.971.645-.882.157-1.847.2-2.574.22-.457.01-.912.017-1.379.019-.555.004-1.113.004-1.669.004H14.801c-.55 0-1.1 0-1.66-.004a75.086 75.086 0 0 1-1.35-.018c-.744-.02-1.71-.064-2.584-.22a6.938 6.938 0 0 1-1.986-.65 6.337 6.337 0 0 1-1.622-1.18 6.355 6.355 0 0 1-1.178-1.623 6.935 6.935 0 0 1-.647-1.985c-.155-.863-.198-1.788-.22-2.578a66.017 66.017 0 0 1-.02-1.355l-.002-1.327V14.474l.002-1.325c.003-.453.008-.905.02-1.357.022-.792.065-1.717.222-2.587a6.924 6.924 0 0 1 .646-1.981c.304-.598.7-1.144 1.18-1.622a6.385 6.385 0 0 1 1.624-1.18 6.96 6.96 0 0 1 1.98-.647c.865-.155 1.792-.198 2.586-.22.452-.012.905-.017 1.354-.02l1.677-.003h135.875"
      />
      <SvgPath d="M45.186 35.64c1.417-1.772 2.38-4.152 2.126-6.585-2.075.104-4.607 1.37-6.073 3.143-1.316 1.52-2.48 4-2.177 6.33 2.33.202 4.656-1.165 6.124-2.887M47.285 38.983c-3.382-.202-6.258 1.919-7.873 1.919-1.616 0-4.09-1.818-6.764-1.769-3.482.051-6.713 2.02-8.48 5.15-3.634 6.264-.959 15.556 2.575 20.657 1.717 2.524 3.785 5.303 6.51 5.203 2.575-.101 3.584-1.668 6.714-1.668 3.128 0 4.037 1.668 6.763 1.617 2.827-.05 4.594-2.525 6.31-5.051 1.969-2.877 2.775-5.655 2.825-5.808-.05-.05-5.45-2.122-5.5-8.333-.051-5.201 4.24-7.675 4.441-7.828-2.423-3.584-6.209-3.988-7.52-4.09M76.734 31.944c7.35 0 12.47 5.067 12.47 12.444 0 7.404-5.225 12.497-12.654 12.497h-8.14v12.943h-5.88V31.944h14.204zM68.41 51.949h6.747c5.12 0 8.034-2.756 8.034-7.534 0-4.778-2.914-7.509-8.008-7.509h-6.773V51.95zM90.74 61.979c0-4.831 3.702-7.798 10.266-8.166l7.56-.446v-2.126c0-3.072-2.074-4.91-5.539-4.91-3.282 0-5.33 1.575-5.828 4.043h-5.356c.315-4.988 4.568-8.664 11.394-8.664 6.695 0 10.974 3.545 10.974 9.084v19.034h-5.435v-4.542h-.13c-1.602 3.072-5.094 5.015-8.717 5.015-5.408 0-9.189-3.36-9.189-8.322zm17.826-2.494v-2.18l-6.8.42c-3.386.237-5.303 1.733-5.303 4.096 0 2.415 1.996 3.99 5.041 3.99 3.964 0 7.062-2.73 7.062-6.326zM119.342 79.989v-4.595c.419.105 1.364.105 1.837.105 2.625 0 4.043-1.102 4.909-3.938 0-.052.5-1.68.5-1.706l-9.977-27.646h6.143l6.984 22.474h.104l6.985-22.474h5.985l-10.344 29.063c-2.362 6.695-5.093 8.848-10.816 8.848-.473 0-1.891-.053-2.31-.131z" />
    </Svg>
  );
}

registerSVG('Applepay', Applepay);
