import { useEffect, useRef } from 'react';

import { FirebasePerformanceTypes } from '@react-native-firebase/perf';

import { useRoute } from 'hooks/navigation/use-route';
import { startTrace } from 'utils/performance/trace-lite';

interface UseTraceLoadingOptions {
  /** name the human readable name of the thing we are tracking, current route name is auto appended */
  name: string | undefined;
  /** logIfLoadingLongerThanMs - does not support changes after initial hook call */
  isLoading?: boolean | null;
  /** appended to name */
  type?: string;
}
/**
 * trace loading of an element to datadog. some built in smarts to de-uuid, de numbers things to make groupings work better
 */
export default function useTraceLoading({ name, isLoading, type = 'btn' }: UseTraceLoadingOptions) {
  const traceRef = useRef<FirebasePerformanceTypes.Trace | null>(null);
  const { pathname } = useRoute();

  useEffect(() => {
    const nameTrimmed = name?.trim();
    const traceName = `loading_"${pathname}"_${type}_"${nameTrimmed}"`
      .replace(uuidRegex, '{UUID}')
      .replace(moneyRegex, '{MONEY}')
      .replace(numberRegex, '{#}');

    if (!name) {
      return; // avoid invalid names
    }

    if (isLoading && !traceRef.current) {
      traceRef.current = startTrace(traceName);
    } else {
      traceRef.current?.stop();
      traceRef.current = null;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  // stop a trace when component unmounts if that were to happen before isLoading=false
  useEffect(() => {
    return () => {
      traceRef.current?.stop();
    };
  }, []);
}

const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g;
const moneyRegex = /\d+\.\d?\d?/g;
const numberRegex = /\d+/g;
