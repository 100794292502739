import * as React from 'react';
import { memo } from 'react';

import { Box, HStack, Header, Pressable, Text } from '@rbilabs/universal-components';

import Picture from 'components/picture';
import SanityBlockRenderer from 'components/sanity-block-renderer';
import { theme } from 'styles/configure-theme';

import { QuestBadge } from './quest-badge';
import { QuestCallToAction, QuestCardContainer, StyledLink } from './styled';
import { IQuestCardProps } from './types';

export const QuestCard = memo(
  ({
    image,
    altText,
    title,
    callToAction,
    callToActionText,
    onPressImage,
    onPressLink,
    linkText,
    description,
    badgeText,
    howToRedeem,
    isQuestCompleted,
  }: IQuestCardProps) => {
    return (
      <QuestCardContainer>
        {image && (
          <Pressable onPress={onPressImage}>
            <Picture
              width="full"
              borderTopRadius="xl"
              image={image}
              flexDirection="column"
              backgroundColor={theme.token('background-menu-customization-image')}
              alt={altText || 'quest image'}
            />
            <QuestBadge text={badgeText} />
          </Pressable>
        )}
        <Box padding="$4">
          {!image && <QuestBadge mb="$1" text={badgeText} />}
          <Header variant="headerTwo">{title}</Header>
          {!isQuestCompleted && howToRedeem ? (
            <SanityBlockRenderer marginBottom="$4" content={howToRedeem} />
          ) : (
            <Text marginBottom="$4">{description}</Text>
          )}
          <HStack
            // row reverse to display the link aligned to the right
            {...(Boolean(linkText) && { direction: 'row-reverse' })}
            justifyContent="space-between"
            alignItems="flex-end"
          >
            {Boolean(linkText) && <StyledLink onPress={onPressLink}>{linkText}</StyledLink>}
            {Boolean(callToActionText) && (
              <QuestCallToAction
                _text={{ variant: 'copyTwo', fontSize: 'sm' }}
                onPress={callToAction}
              >
                {callToActionText}
              </QuestCallToAction>
            )}
          </HStack>
        </Box>
      </QuestCardContainer>
    );
  }
);
