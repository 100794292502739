import React from 'react';

import { Box } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import { ActionButtonVariants } from 'components/action-button/types';
import Picture from 'components/picture';

import {
  ActionWrapper,
  CloseOptInModalButton,
  OptInDialogBody,
  OptInDialogBodyContent,
  OptInDialogBodyText,
  OptInDialogFooter,
  OptInDialogHeader,
  OptInDialogHeading,
} from './styled';
import type { IOptInErrorContentProps } from './types';

export const OptInErrorContent = ({
  heading,
  description,
  image,
  altImage,
  buttonText,
  onActionPress,
  onDismiss,
  allowDismiss = false,
}: IOptInErrorContentProps) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Box alignItems="center">
        <Picture width="130px" image={image} alt={altImage} objectFitContain />
      </Box>

      <OptInDialogHeader>
        <OptInDialogHeading>{heading}</OptInDialogHeading>
      </OptInDialogHeader>

      <OptInDialogBodyContent>
        <OptInDialogBody>
          <OptInDialogBodyText>{description}</OptInDialogBodyText>
        </OptInDialogBody>

        <OptInDialogFooter>
          <ActionWrapper>
            <ActionButton
              backgroundColor="white"
              fullWidth
              onPress={onActionPress}
              variant={ActionButtonVariants.OUTLINE}
            >
              {buttonText}
            </ActionButton>
          </ActionWrapper>

          {allowDismiss && (
            <CloseOptInModalButton mt={0} onPress={onDismiss}>
              {formatMessage({ id: 'close' })}
            </CloseOptInModalButton>
          )}
        </OptInDialogFooter>
      </OptInDialogBodyContent>
    </>
  );
};
