import React from 'react';

function createGlobalStyle(..._str: any) {
  // TODO: Implement me
  return () => null;
}

/**
 * In order to center popups, Braze is using both `margin: auto;` and
 * 50% possitioning and transforms. This results in a visual conflict
 * that causes the modal to be off center. Margin auto is adding margins
 * when they're not needed.
 *
 * TODO: Braze agreed to fix this in Q1 2020. This hack should be unnecessary at some point.
 */
const BrazeCssBugOverrideStyles = createGlobalStyle`
.ab-centering-div .ab-centered, .ab-centering-div.ab-centering-div .ab-modal, .ab-iam-root.v3 .ab-centered, .ab-iam-root.v3.ab-centering-div .ab-modal {
  margin: 0 !important;
  position: relative !important;
}
`;

export const BrazeCssBugOverride = () => <BrazeCssBugOverrideStyles />;
