export enum ZIndex {
  BELOW = 100,
  NORMAL = 200,
  TOP = 300,
  OVERLAY = 400,
  MAX = 999900,
}

export enum LayoutWidth {
  SMALL = 600,
  REGULAR = 962,
}

export enum NAV_HEIGHT {
  MOBILE = 56,
}
