import { CartPaymentCardType, LoyaltyPaymentMethod } from 'generated/graphql-gateway';
import { CardTypes } from 'state/payment/types';

const fallbackPaymentMethodCardTypes: Record<string, CardTypes> = {
  mc: CardTypes.MASTERCARD,
  visa: CardTypes.VISA,
  amex: CardTypes.AMEX,
  discover: CardTypes.DISCOVER,
  jcb: CardTypes.JCB,
  diners: CardTypes.DINERS_CLUB,
};

const isValidCardType = (cardType: string | undefined | null): cardType is CardTypes => {
  return !!cardType && Object.keys(CardTypes).includes(cardType.toUpperCase());
};

/**
 * We are attempting to map our payment processor cardTypes to our FE CardType's.
 *
 * NOTE: This is intended to map the returned cardType for credit payment methods from the userAccounts query.
 */
export const mapPaymentMethodCardTypes = (
  paymentMethodCardType: string | undefined | null
): CardTypes | null => {
  if (!paymentMethodCardType) {
    return null;
  }

  const cardTypeUpperCased = paymentMethodCardType.toUpperCase();

  // check for all original card types, mostly first data values
  return isValidCardType(cardTypeUpperCased)
    ? CardTypes[cardTypeUpperCased]
    : // check for payment processor card types
      // NOTE: in the future we may need to expand this
      fallbackPaymentMethodCardTypes[paymentMethodCardType] ?? null;
};

/**
 * Map CartPaymentCardType to LoyaltyPaymentMethod.
 */
export const mapCardTypeToLoyalty = (
  cardType: CartPaymentCardType | null
): LoyaltyPaymentMethod | null => {
  if (!cardType) {
    return null;
  }

  switch (cardType) {
    case CartPaymentCardType.DINERS_CLUB:
      return LoyaltyPaymentMethod.DINERSCLUB;
    case CartPaymentCardType.DINERS:
      return null;
    default:
      return LoyaltyPaymentMethod[cardType];
  }
};
