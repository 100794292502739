const findInnerHeadingElement = (element: HTMLElement) => {
  const headingTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const;
  for (const tag of headingTags) {
    const header = element.getElementsByTagName(tag).item(0);
    if (header) {
      return header;
    }
  }
  return undefined;
};

/**
 * Finds the text of the first header element in the provided HTMLElement
 */
export const findInnerHeaderText = (element?: HTMLElement) => {
  // Use #modal-heading if we can find it
  const idHeader = element?.querySelector<HTMLElement>('#modal-heading');
  if (idHeader) {
    return idHeader.innerText;
  }

  const headingEle = element && findInnerHeadingElement(element);
  if (headingEle) {
    return headingEle.innerText;
  }

  return undefined;
};
