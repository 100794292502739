import { LoyaltyOfferType } from 'generated/graphql-gateway';
import { IPlaceholderFieldFragment } from 'generated/sanity-graphql';
import type { PlaceholderFieldsMap } from 'hooks/use-locale-smart-block-content/types';

import type { ICmsConfigOffer, ICmsTemplateOffer, IEngineOffer } from '../types';

import type { PersonalizedEngineOffer } from './types';

export const isPersonalizedEngineOffer = (
  engineOffer: IEngineOffer
): engineOffer is PersonalizedEngineOffer =>
  engineOffer.type === LoyaltyOfferType.PERSONALIZED && !!engineOffer.configId;

/**
 * Collect all engine and template ids from a list of engine offers.
 */
export const getConfigAndTemplateIds = (
  engineOffers: PersonalizedEngineOffer[]
): { configEngineIds: string[]; templateEngineIds: string[] } => {
  const configOffersIds: Set<string> = new Set();
  const templatesIds: Set<string> = new Set();

  engineOffers.forEach(({ configId, templateId }) => {
    configOffersIds.add(configId);

    if (templateId) {
      templatesIds.add(templateId);
    }
  });

  return {
    configEngineIds: Array.from(configOffersIds),
    templateEngineIds: Array.from(templatesIds),
  };
};

/**
 * Create the placeholder field map by `_id`.
 * This function is needed to avoid type issues.
 */
export const createOfferPlaceHolderFieldsMap = (
  placeholderFields: readonly IPlaceholderFieldFragment[]
): PlaceholderFieldsMap => {
  return placeholderFields.reduce((acc, placeholderField) => {
    acc[placeholderField._id] = placeholderField;
    return acc;
  }, {});
};

export const createCmsPersonalizedMapByEngineId = <T extends ICmsConfigOffer | ICmsTemplateOffer>(
  personalizedCmsOffers: readonly T[] | undefined
): Record<string, T> | undefined =>
  personalizedCmsOffers?.reduce((accum, offer) => {
    if (offer?.loyaltyEngineId) {
      accum[offer.loyaltyEngineId] = offer;
    }
    return accum;
  }, {});
