import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';

export default function useStaticIdentifier() {
  const { loyaltyUser } = useLoyaltyUser();
  const isStaticIdentifierEnabled = Boolean(
    useFlag(LaunchDarklyFlag.ENABLE_STATIC_IN_STORE_IDENTIFICATION)
  );
  const loyaltyUserId = loyaltyUser?.id;

  // The static identifier is the loyalty id but this can be changed depending on biz requirements
  const staticIdentifier = isStaticIdentifierEnabled && loyaltyUserId ? loyaltyUserId : null;
  return { staticIdentifier, isStaticIdentifierEnabled };
}
