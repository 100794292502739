import { Box, HStack, Header, ScrollView, makeUclComponent } from '@rbilabs/universal-components';

import ActionButton from 'components/action-button';
import Picture from 'components/picture';
import SanityBlockRenderer from 'components/sanity-block-renderer';
import LoyaltyRewardsLogo from 'pages/loyalty/loyalty-rewards-logo';

export const Container = ScrollView.withConfig<{ hasSignUpButton?: boolean }>(
  ({ hasSignUpButton = false }) => ({
    width: 'full',
    marginBottom: hasSignUpButton ? '$20' : '$0',
  })
);

export const HeadingText = Header.withConfig({
  accessibilityLevel: 1,
  margin: 0,
  color: Styles.color.secondary,
  fontSize: '5xl',
  lineHeight: '2xs',
  textAlign: 'center',
  textTransform: Styles.textTransform.headlines,
});

export const LogoContainer = Picture;

export const ImageContainer = Picture.withConfig<{ $windowHeight?: number }>(
  ({ $windowHeight }) => ({
    width: 'full',
    maxWidth: '80%',
    height: $windowHeight ? { base: 0.2 * $windowHeight, sm: 0.28 * $windowHeight } : 'auto',
    marginY: '$1',
    marginX: 'auto',
  })
);

export const TitleText = Header.withConfig<{ $fontColor?: string }>({
  variant: 'headerTwo',
  accessibilityLevel: 2,
  textTransform: Styles.textTransform.headlines,
  margin: 0,
  textAlign: 'center',
});

export const SubtitleText = Header.withConfig({
  variant: 'headerFour',
  accessibilityLevel: 4,
  width: '95%',
  margin: 0,
  marginTop: '$4',
  marginBottom: '$8',
  fontSize: 'md',
  fontFamily: 'body',
  textAlign: 'center',
});

export const DescriptionTextBlock = SanityBlockRenderer.withConfig({
  textProps: {
    marginX: '$4',
    marginY: '$4',
    fontSize: 'md',
    fontFamily: 'body',
    display: 'flex',
    justifyContent: 'center',
  },
  linkProps: {
    top: 0.5,
    underline: true,
  },
});

export const ButtonWrapper = Box.withConfig({
  marginTop: '$2',
  marginX: 'auto',
  alignItems: 'center',
  flex: 1,
  width: 'full',
  position: 'absolute',
  bottom: 0,
  height: '$20',
  paddingX: '$4',
  justifyContent: 'center',
  borderTopColor: 'token.border-color-default',
  borderBottomColor: 'token.border-color-default',
  background: { base: 'token.background-default', sm: 'transparent' },
  shadow: { base: '2', sm: 0 },
  borderTopWidth: { base: '2', sm: 0 },
  borderBottomWidth: { base: '2', sm: 0 },
});

export const DesktopBar = HStack.withConfig({
  width: '100%',
  height: '75px',
  position: 'absolute',
  bottom: 0,
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingY: '$1',
  paddingX: '$4',
  backgroundColor: Styles.color.white,
});

export const FullPageImage = Picture.withConfig({
  height: '100%',
  width: '100%',
});

export const StyledLoyaltyLogo = LoyaltyRewardsLogo.withConfig({
  alignItems: 'center',
  marginTop: {
    base: '$8',
    lg: '$16',
  },
});

export const StyledActionButton = makeUclComponent(ActionButton).withConfig({
  paddingY: '$3',
  paddingX: '$12',
  _text: {
    fontSize: 'lg',
  },
  _web: {
    maxWidth: '480px',
  },
});

export const Terms = SanityBlockRenderer.withConfig({
  _web: {
    paddingY: '20px',
  },
  textProps: {
    textAlign: 'center',
    marginY: '$4',
    marginX: 'auto',
    fontSize: 'sm',
  },
});
