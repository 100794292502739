import { ICartEntry, IComboSlot, IComboSlotOption } from '@rbi-ctg/menu';

import { getDefaultSelectionsForComboSlot } from './get-default-selections-for-combo-slot';

export const getDefaultComboSlotOption = (
  comboSlot: ICartEntry | IComboSlot | undefined
): IComboSlotOption | undefined => {
  if (!comboSlot) {
    return;
  }
  if ('defaultComboSlotOption' in comboSlot) {
    return comboSlot.defaultComboSlotOption;
  }
  if (!('options' in comboSlot) || !comboSlot.options.length) {
    return;
  }
  const firstDefaultSelection = getDefaultSelectionsForComboSlot(comboSlot.options)[0];
  return firstDefaultSelection?.option;
};
