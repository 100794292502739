import { IntlShape } from 'react-intl';

import {
  IIncentiveEvaluationResult,
  IncentiveEvaluationErrorCodes,
} from 'state/loyalty/hooks/types';
import { LoyaltyOffer, LoyaltyReward, isLoyaltyOffer, isReward } from 'state/loyalty/types';
import { IValidDayPart } from 'state/menu/main-menu/types';
import {
  IMenuObjectWithDaypart,
  getMenuItemDayParts,
  getNextMatchingDayPart,
  timeToString,
} from 'utils/availability';

export const buildOutOfDayPartEvaluationResult = (
  incentive: LoyaltyOffer | LoyaltyReward,
  dayParts: readonly IValidDayPart[]
): IIncentiveEvaluationResult => ({
  code: IncentiveEvaluationErrorCodes.OUT_OF_DAY_PART,
  ruleId: '',
  message: '',
  currentValue: null,
  targetValue: {
    incentive,
    dayParts,
  },
});

export const buildVendorEvaluationResult = (): IIncentiveEvaluationResult => ({
  code: IncentiveEvaluationErrorCodes.NOT_AVAILABLE_IN_STORE,
  ruleId: '',
  message: '',
  currentValue: null,
  targetValue: null,
});

export const getIncentiveDayParts = (
  incentive: LoyaltyOffer | LoyaltyReward
): LoyaltyOffer['daypart'] => {
  let dayParts = [];
  if (isLoyaltyOffer(incentive) && incentive.daypart) {
    dayParts = [...incentive.daypart];
  } else if (isReward(incentive) && incentive.incentives?.length) {
    dayParts.push(...getMenuItemDayParts(incentive.incentives[0] as IMenuObjectWithDaypart));
  }
  return dayParts;
};

export const makeIncentiveOutOfDayPartMessage = ({
  incentive,
  dayParts,
  formatters,
}: {
  incentive: LoyaltyOffer | LoyaltyReward;
  dayParts: readonly IValidDayPart[];
  formatters: IntlShape;
}) => {
  let message: string;
  const { formatMessage, formatTime } = formatters;

  const incentiveDayParts = getIncentiveDayParts(incentive);
  const nextActiveDayPart = getNextMatchingDayPart(incentiveDayParts, dayParts);
  if (nextActiveDayPart) {
    message = formatMessage(
      { id: 'incentiveFeedbackOutOfDayPartTime' },
      { time: timeToString(nextActiveDayPart.startTime, formatTime) }
    );
  } else {
    message = formatMessage({ id: 'incentiveFeedbackOutOfDayPart' });
  }

  return message;
};
