import { LOCALES } from 'state/intl/types';
import logger from 'utils/logger';

const localeImport = {
  en_us: () => import(`@formatjs/intl-listformat/locale-data/en-US`),
};

export const polyfillListFormatLocale = async (locale: LOCALES) => {
  try {
    if (locale in LOCALES) {
      await localeImport[locale]();
    } else {
      await import('@formatjs/intl-listformat/locale-data/en-US');
    }
  } catch (e) {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logger.warn(e);
  }
};
