import * as React from 'react';
import { FC } from 'react';

import { Box, BoxProps, makeUclComponent } from '@rbilabs/universal-components';

interface IProgressMeter {
  percentage: number;
  _progress?: BoxProps;
}

const Wrapper = Box.withConfig({
  width: '100%',
  height: '8px',
  borderRadius: '10px',
  borderWidth: '1px',
  borderColor: Styles.color.grey.four,
  background: Styles.color.grey.four,
});

const Progress = Box.withConfig<{ percentage: number }>(p => ({
  borderRadius: '10px',
  height: '100%',
  width: `${p.percentage}%`,
  background: p.background ?? Styles.color.primary,
  // TODO: RN - Add animation
}));

const ProgressMeterComponent: FC<React.PropsWithChildren<IProgressMeter>> = ({
  percentage,
  _progress,
  ...props
}) => {
  return (
    <Wrapper {...props}>
      <Progress percentage={percentage} {..._progress} />
    </Wrapper>
  );
};

export const ProgressMeter = makeUclComponent(ProgressMeterComponent);
