import React, { PropsWithChildren } from 'react';

import { PermissionStatus } from 'expo-modules-core';

import { AttPermissionContext } from './common';

// this doesn't do anything on web its for ios only
export const AttPermissionProvider = ({ children }: PropsWithChildren<{}>) => {
  return (
    <AttPermissionContext.Provider
      value={{
        isAttPermissionKnown: true,
        isLoadingAttPermission: false,
        trackingPermissionGranted: true,
        requestAttPermission: () =>
          Promise.resolve({
            canAskAgain: true,
            expires: Number.POSITIVE_INFINITY,
            granted: true,
            status: PermissionStatus.GRANTED,
          }),
      }}
    >
      {children}
    </AttPermissionContext.Provider>
  );
};

export const appTrackingTransparency = async (): Promise<PermissionStatus | undefined> => {
  return Promise.resolve(PermissionStatus.GRANTED);
};
