import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import { ActionsheetContent, ActionsheetWrapper } from 'components/action-sheet';

import { ContentHeader, ContentText, ContentWrapper } from './styled';

interface WalmartWelcomeProps {
  isOpen?: boolean;
  handleOnClose?: () => void;
  handleOnConfirm: () => void;
}

export const WalmartWelcome = ({
  isOpen = false,
  handleOnClose,
  handleOnConfirm,
}: WalmartWelcomeProps) => {
  const { formatMessage } = useIntl();

  return (
    <ActionsheetWrapper
      isOpen={isOpen}
      onOpenEventMessage="Walmart Welcome modal"
      onClose={handleOnClose}
    >
      <ActionsheetContent>
        <ContentWrapper>
          <ContentHeader>{formatMessage({ id: 'welcome' })}</ContentHeader>
          <ContentText>{formatMessage({ id: 'walmartWelcomeLinkWithWalmartPlus' })}</ContentText>
          <ActionButton fullWidth onPress={handleOnConfirm} mb="$4">
            {formatMessage({ id: 'walmartConnectWithWalmartPlus' })}
          </ActionButton>
        </ContentWrapper>
      </ActionsheetContent>
    </ActionsheetWrapper>
  );
};
