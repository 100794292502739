import { Operation, createHttpLink, from, split } from '@apollo/client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import * as Crypto from 'expo-crypto';
import { isNil } from 'lodash-es';

import { getGraphqlGatewayApiUrl } from 'remote/constants';

import {
  AUTH_REQUIRED_DIRECTIVE,
  isRequestAuthProtected,
  isUserLoggedIn,
  stripAuthDirective,
} from './auth-required-directive';
import { cancelRequestLink } from './cancel-request-link';
import { operationNameTracingFetch } from './operation-name-tracer';
import { rbiBackendRetryLink, sanityBackendRetryLink } from './request-retry-links';
import { refreshAndRetryBadAuthLink } from './retry-bad-auth';
import { retryWhenNetworkRestored } from './retry-when-network-restored';
import { sanityFetch } from './sanity-fetch';
import { removeNestedTypenameFromMenuQueriesLink } from './strip-typename';
import { isCacheRequest, stripUseCacheDirective } from './strip-useCache-directive';
import { withAuthHeaders } from './with-auth-headers';
import { withClientInfo } from './with-client-info-headers';
import { withForterHeaders } from './with-forter-headers';
import { withLocalizedQueries } from './with-localized-queries';
import { withDateTimeHeaders } from './with-user-datetime-headers';

const isSanityRequest = (context?: Record<string, any>) => !isNil(context && context.uri);
const operationIsSanityRequest = (operation: Operation) => isSanityRequest(operation.getContext());
const operationIsCacheRequest = (operation: Operation) => isCacheRequest(operation.query);

const fetchOptions = {
  referrerPolicy: 'no-referrer',
};

const sanityHttpLink = from([
  withLocalizedQueries(),
  retryWhenNetworkRestored,
  sanityBackendRetryLink,
  // sanity requests have the uri set in context
  from([
    removeNestedTypenameFromMenuQueriesLink,
    createHttpLink({ credentials: 'omit', fetch: sanityFetch, fetchOptions }),
  ]),
]);

const withRBIGraphQLLinks = from([
  withAuthHeaders,
  refreshAndRetryBadAuthLink,
  withDateTimeHeaders,
  withForterHeaders,
  withClientInfo,
]);

const gatewayHttpLink = createHttpLink({
  uri: getGraphqlGatewayApiUrl(),
  credentials: 'omit',
  fetch: operationNameTracingFetch,
});

const sha256 = async (buffer: string) =>
  await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA256, buffer);

const gatewaySplit = split(
  operationIsCacheRequest,
  from([
    stripUseCacheDirective,
    createPersistedQueryLink({ sha256, useGETForHashedQueries: true }),
    retryWhenNetworkRestored,
    rbiBackendRetryLink,
    gatewayHttpLink,
  ]),
  from([retryWhenNetworkRestored, rbiBackendRetryLink, gatewayHttpLink])
);

/**
 * The lambdaHttpLink composes the link chain for requests going out to our
 * lambda backend. It can be difficult to visualize the chain with all the
 * splits, so here's a diagram:
 *
 *                         ...withRBIGraphQLLinks
 *                      is auth required on this request?
 *                         /                 \
 *                       yes                  no
 *                      /                      \
 *                 is user logged in?       gateway split
 *                  /           \
 *                yes           no
 *               /                \
 *          gateway split     invalid request link
 *
 * we split out the gatewaySplit into a variable so that it can be re-used,
 * because there are now two different places where we might want to use it.
 * after composing the "base" links (`withRBIGraphQLLinks`), we first ask if
 * we're dealing with a query that required auth. if not, we send the request
 * down the pre-existing chain, which just has the gateway split as the next
 * step. if the query does require auth, we send it to this new step, which
 * then asks if the user is logged in. if not, we cancel the request and
 * return an error. if they are logged in, then we treat it as a "normal"
 * request and send it down the rest of the link chain, where the first
 * step is the gateway split.
 */
const lambdaHttpLink = from([
  withRBIGraphQLLinks,
  split(
    isRequestAuthProtected,
    split(
      isUserLoggedIn,
      from([stripAuthDirective, gatewaySplit]),
      cancelRequestLink(
        `@${AUTH_REQUIRED_DIRECTIVE} directive present on query but user is not authorized.`
      )
    ),
    gatewaySplit
  ),
]);

export const httpLink = split(operationIsSanityRequest, sanityHttpLink, lambdaHttpLink);
