import { IVendorConfig } from '@rbi-ctg/menu';

import { PluTypes } from './types';

export const getParentChildPlu = (
  vendorConfig: IVendorConfig | null | undefined
): string | null => {
  if (!vendorConfig || vendorConfig.pluType !== PluTypes.PARENT_CHILD) {
    return null;
  }

  const { plu, childPlu } = vendorConfig.parentChildPlu || {};
  return plu && childPlu ? `${plu}-${childPlu}` : null;
};
