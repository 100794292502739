import { IPicker } from '@rbi-ctg/menu';

export function defaultPickerAspect<Item extends IPicker>(item: Item) {
  if (!item.options || !item.options.length || !item.pickerAspects) {
    return {};
  }

  const resolvedPickerDefaults: Record<string, string> = {};

  item.pickerAspects.forEach(aspect => {
    let resolvedDefault: string | undefined;
    // flatten the current values for comparison
    const currentResolvedValues: string[] = Object.values(resolvedPickerDefaults);
    // if the item has pickerDefaults use them
    if (item.pickerDefaults) {
      // find the default pickerAspectOption
      const defaultValue = item.pickerDefaults.find(
        pickerDefault => pickerDefault.pickerAspect._id === aspect._id
      );
      // if a defaultValue was found and can resolve to a pickerOption
      if (
        defaultValue &&
        pickerAspectOptHasResolution(
          defaultValue.pickerAspectValueIdentifier,
          currentResolvedValues,
          item.options
        )
      ) {
        resolvedDefault = defaultValue.pickerAspectValueIdentifier;
      } else {
        resolvedDefault = (
          aspect.pickerAspectOptions.find(option =>
            pickerAspectOptHasResolution(option.identifier, currentResolvedValues, item.options)
          ) || {}
        ).identifier;
      }

      resolvedPickerDefaults[aspect._id] = resolvedDefault!;
      // else use the first option that lead to a resolved pickerOption
    } else {
      resolvedDefault = (
        aspect.pickerAspectOptions.find(option =>
          pickerAspectOptHasResolution(option.identifier, currentResolvedValues, item.options)
        ) || {}
      ).identifier;
    }

    resolvedPickerDefaults[aspect._id] = resolvedDefault!;
  });

  return resolvedPickerDefaults;
}

export function pickerAspectOptHasResolution(
  selectionCandidate: string,
  previousSelections: string[],
  possibleResolutions: IPicker['options']
) {
  const candidateWithPreviousSelections = [selectionCandidate, ...previousSelections];
  return possibleResolutions.some(option => {
    const optMappings = option.pickerItemMappings.map(opt => opt.pickerAspectValueIdentifier);
    return candidateWithPreviousSelections.every(value => optMappings.includes(value));
  });
}
