import React from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import { MenuPricingProvider, useMenuPricingContext } from 'state/menu/menu-pricing';
import { IMenuContext } from 'state/menu/types';
import { useStoreContext } from 'state/store';

import { MainMenuProvider, useMainMenuContext } from './main-menu';
import { MenuOptionsProvider, useMenuOptionsContext } from './menu-options';

export const MenuContext = React.createContext<IMenuContext>({} as IMenuContext);

export const useMenuContext = (): IMenuContext => {
  const mainMenuCtx = useMainMenuContext();
  const menuOptionsCtx = useMenuOptionsContext();
  const menuPricingCtx = useMenuPricingContext();

  const { isStoreOpenAndAvailable, store } = useStoreContext();
  const showBurgersForBreakfast = isStoreOpenAndAvailable && store?.hasBurgersForBreakfast === true;

  return {
    ...mainMenuCtx,
    ...menuOptionsCtx,
    ...menuPricingCtx,
    showBurgersForBreakfast,
  };
};

export const MenuProvider = ({ children }: IBaseProps) => {
  return (
    <MainMenuProvider>
      <MenuOptionsProvider>
        <MenuPricingProvider>{children}</MenuPricingProvider>
      </MenuOptionsProvider>
    </MainMenuProvider>
  );
};
