import { primitive } from 'styles/constants/primitives';

import { Svg, SvgPath, SvgProps, SvgRect, registerSVG } from '../../../components/svg';

export function BkCrownPass(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <SvgRect y="4" width="24" height="16" rx="2" fill={primitive.bk.$fieryRed} />
      <SvgRect
        x="0.5"
        y="4.5"
        width="23"
        height="15"
        rx="1.5"
        stroke="black"
        strokeOpacity="0.1"
        fill={primitive.bk.$fieryRed}
      />
      <SvgPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7072 14.9604C15.8547 14.9604 15.9207 15.0398 15.9207 15.1784C15.9207 15.5044 15.655 16.187 14.9401 16.5457C14.2921 16.8708 13.2623 17 11.9608 17C10.6594 17 9.62964 16.8708 8.9816 16.5457C8.26671 16.187 8.00098 15.5044 8.00098 15.1784C8.00098 15.0398 8.06703 14.9604 8.21448 14.9604H15.7072Z"
        fill={primitive.bk.$mayoEggWhite}
      />
      <SvgPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4895 9.7434H8.20749C8.06417 9.7434 8 9.65849 8 9.50556C8 8.59964 9.18811 7 11.8485 7C14.4841 7 15.697 8.59964 15.697 9.50556C15.697 9.65849 15.6328 9.7434 15.4895 9.7434Z"
        fill={primitive.bk.$mayoEggWhite}
      />
      <SvgPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9008 12.1568C14.816 12.0917 14.7313 12.0267 14.7313 11.9616C14.7313 11.8803 14.7808 11.8466 14.8669 11.7502C15.3387 11.2214 15.547 10.8629 15.6392 10.4636C15.6699 10.3309 15.5761 10.2026 15.4345 10.2026H12.5778C12.578 10.2125 12.5782 10.2224 12.5782 10.2322C12.5782 10.6226 12.3578 11.2784 11.6289 11.2784H11.4085C11.1204 11.2784 10.9847 11.1157 10.9847 10.9043V10.2026H8.21394C8.09637 10.2026 8.00098 10.2941 8.00098 10.407V14.2971C8.00098 14.41 8.09637 14.5014 8.21394 14.5014H10.9847V13.6045C10.9847 13.3931 11.1204 13.2305 11.4085 13.2305H11.7137C12.5274 13.2305 12.7647 13.751 12.7647 14.2228C12.7647 14.3102 12.7545 14.4055 12.7312 14.5014H15.7965C15.912 14.5014 16.0044 14.4095 16.0001 14.2988L16 14.2974C15.9459 12.9442 15.0977 12.3143 14.9008 12.1568Z"
        fill={primitive.bk.$mayoEggWhite}
      />
    </Svg>
  );
}

registerSVG('BkCrownPass', BkCrownPass);
