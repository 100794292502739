import React from 'react';

import { View } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { routes } from 'utils/routing';

import SignUpHeadlineText from './sign-up-headline-text';
import {
  LogoLink,
  RewardsLinkStyled,
  SignUpHeadlineContainer,
  StyledBKLogo,
  StyledRewardsLogo,
} from './sign-up-headline.styled';

const SignUpHeadline = ({ disableLink = false }: { disableLink?: boolean }) => {
  const { formatMessage } = useIntl();
  const loyaltyEnabled = useIsLoyaltyEnabled();

  const title = formatMessage({ id: 'bk_logo_title' });
  const logoLinkPath = disableLink ? null : { to: routes.base };

  const rewardsLink = (
    <RewardsLinkStyled
      {...(!disableLink && { linkPath: formatMessage({ id: 'routes.bkRewards' }) })}
    >
      {formatMessage({ id: 'royalPerks' })}
    </RewardsLinkStyled>
  );

  return (
    <SignUpHeadlineContainer>
      {loyaltyEnabled ? (
        <>
          <LogoLink {...logoLinkPath}>
            <StyledRewardsLogo testID="sign-up-loyalty-logo" />
          </LogoLink>
          <View focusable>
            <SignUpHeadlineText rewardsLink={rewardsLink} />
          </View>
        </>
      ) : (
        <LogoLink {...logoLinkPath}>
          <StyledBKLogo variant="BkLogoPrimary" title={title} testID="brand-logo" />
        </LogoLink>
      )}
    </SignUpHeadlineContainer>
  );
};

export default SignUpHeadline;
