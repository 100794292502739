import React, { createContext, useContext, useEffect, useState } from 'react';

import { IBaseProps, SetState } from '@rbi-ctg/frontend';
import { useAuthContext } from 'state/auth';
import { useMatch } from 'state/location';
import noop from 'utils/noop';
import { routes } from 'utils/routing';
import { useMemoAll } from 'utils/use-memo-all';

export interface IReversedUIContext {
  reversedUI: boolean;
  setReversedUI: SetState<boolean>;
}

export const ReversedUIContext = createContext<IReversedUIContext>({
  reversedUI: false,
  setReversedUI: noop,
});

export const ReversedUIProvider = ({ children }: IBaseProps) => {
  const { isAuthenticated } = useAuthContext();
  const isHome = useMatch(routes.base);

  const [reversedUI, setReversedUI] = useState(false);

  useEffect(() => {
    if (!isHome || !isAuthenticated) {
      setReversedUI(false);
    }
  }, [isAuthenticated, isHome]);

  const value = useMemoAll({
    reversedUI,
    setReversedUI,
  });

  return <ReversedUIContext.Provider value={value}>{children}</ReversedUIContext.Provider>;
};

export const useReversedUIContext = () => useContext(ReversedUIContext);
