import type { ISanityBlockContent } from '@rbi-ctg/menu';
import type { IOffersFragment } from 'generated/graphql-gateway';
import type {
  IConfigOfferFragment,
  ILoyaltyOfferTemplateFragment,
  IMenuImageFragment,
  ISystemwideOffersFragment,
} from 'generated/sanity-graphql';

export enum OffersServiceMode {
  PICKUP_ONLY = 'pickUpOnly',
  DELIVERY_ONLY = 'deliveryOnly',
}

export type ICmsConfigOffer = IConfigOfferFragment;
export type ICmsTemplateOffer = ILoyaltyOfferTemplateFragment;
// this union works because these types have the same fields
export type ICmsOffer = ISystemwideOffersFragment | IConfigOfferFragment;
export type IEngineOffer = IOffersFragment;

/**
 * Interface to contain all the information to work with an offer in presentational components or business logic.
 */
export interface ILoyaltyOffer
  extends Omit<
    ICmsOffer,
    '__typename' | 'name' | 'description' | 'image' | 'imageDescription' | 'moreInfo'
  > {
  // parsed values
  loyaltyEngineId: string;
  cmsId: string;
  name: string;
  description: ISanityBlockContent[] | null;
  image: IMenuImageFragment | null;
  imageDescription: string;
  moreInfo: string;

  // calculated values
  serviceMode: OffersServiceMode | undefined;
  // engine values
  isStackable: boolean;
  metadata: IEngineOffer['metadata'];
  errors: IEngineOffer['errors'];
}
