import { noop } from 'lodash-es';

// firebase perf doesn't work in web but this wrapper keeps API compatibility the same for what we are using
const perf = () => ({
  newTrace: () => new MockTrace(),
});
export { perf };

class MockTrace {
  start = function () {
    return Promise.resolve();
  };
  stop = function () {
    return Promise.resolve();
  };
  putAttribute = noop;
}
