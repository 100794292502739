import { useState } from 'react';

import { IRestaurant } from '@rbi-ctg/store';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

interface UseNonTraditionalStoreUIProps {
  restaurant?: IRestaurant | null;
}

interface UseNonTraditionalStoreUIReturn {
  isNonTraditionalStore: boolean;
  accessInstructions: string;
  enableNonTraditionalStoreUI: boolean;
  isDialogDrawerOpen: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
}

export const useNonTraditionalStoreUI = ({
  restaurant,
}: UseNonTraditionalStoreUIProps): UseNonTraditionalStoreUIReturn | null => {
  const enableNonTraditionalStoreUI = useFlag(LaunchDarklyFlag.ENABLE_NON_TRADITIONAL_STORE_UI);
  const [isDialogDrawerOpen, setIsDialogDrawerOpen] = useState(false);

  if (!restaurant || !enableNonTraditionalStoreUI) {
    return null;
  }

  const { accessInstructions = '', isNonTraditional = false } = restaurant;

  return {
    isNonTraditionalStore: !!isNonTraditional,
    accessInstructions: accessInstructions || '',
    enableNonTraditionalStoreUI,
    isDialogDrawerOpen,
    openDrawer: () => setIsDialogDrawerOpen(true),
    closeDrawer: () => setIsDialogDrawerOpen(false),
  };
};
