import { useSimpleHomePageQuery } from 'generated/sanity-graphql';

export const useSimpleHomePage = (id?: string) => {
  const simpleHomePageId = id ?? '';
  const { data, loading } = useSimpleHomePageQuery({
    variables: {
      simpleHomePageId,
    },
    skip: !simpleHomePageId,
  });

  const simpleHomePage = data?.SimpleHomePage;

  return {
    simpleHomePage,
    simpleHomePageLoading: loading,
  };
};
