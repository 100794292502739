import React from 'react';

import { View } from '@rbilabs/universal-components';
import { AccessibilityRole } from 'react-native';

export const VisuallyHidden: React.FC<React.PropsWithChildren<{
  accessibilityLabel: string;
  role?: AccessibilityRole;
  testID?: string;
}>> = ({ accessibilityLabel, role, testID, children }) => (
  <View
    accessibilityRole={role}
    accessibilityLabel={accessibilityLabel}
    testID={testID}
    style={{ display: 'none' }}
  >
    {children}
  </View>
);
