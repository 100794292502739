import React, { useState } from 'react';

import { Box, HStack, Header, Icon, Pressable, Text } from '@rbilabs/universal-components';

import { RotateAnimatedView } from 'components/ucl';
import { IAccordionEntry, IAccordionWidget, ILocaleString, Maybe } from 'generated/sanity-graphql';

import theme from './theme';

const AccordionWrapper = Box.withConfig({
  paddingY: {
    base: '$6',
    md: '$10',
  },
});

const AccordionTitle = Header.withConfig({
  variant: 'headerTwo',
  fontFamily: 'body',
  fontSize: '2xl',
  lineHeight: 'md',
  paddingY: '$4',
  paddingX: '$4',
});

const AccordionItem = Pressable.withConfig<{ isExpanded: boolean; isLastChild: boolean }>(
  ({ isExpanded, isLastChild }) => ({
    paddingX: {
      base: '$4',
      md: '$0',
    },
    backgroundColor: isExpanded ? theme.expandedAccordionBackground : undefined,
    _hover: {
      backgroundColor: theme.accordionHoverBackground,
    },
    _web: {
      cursor: 'pointer',
    },
    borderStyle: 'solid',
    borderBottomWidth: isLastChild ? 0 : 1,
    borderBottomColor: Styles.color.grey.four,
  })
);

const ArrowDown = Icon.withConfig<{ isExpanded?: boolean }>(({ isExpanded }) => ({
  size: '$6',
  variant: 'add',
  color: isExpanded ? theme.expandedAccordionActiveAccentColor : Styles.color.black,
}));

const CollapsedContentHeader = Header.withConfig<{ isExpanded?: boolean }>(({ isExpanded }) => ({
  variant: 'headerFour',
  fontFamily: 'body',
  fontSize: 'lg',
  lineHeight: 'md',
  flex: 1,
  color: isExpanded ? theme.expandedAccordionActiveAccentColor : undefined,
  fontWeight: isExpanded ? theme.expandedAccordionFontWeight : 'normal',
  paddingX: {
    base: '$0',
    md: '$4',
  },
  marginY: {
    base: '$0',
    md: '$4',
  },
}));

const ExpandedContent = Text.withConfig<{ isExpanded: boolean }>(({ isExpanded }) => ({
  color: Styles.color.grey.two,
  marginBottom: isExpanded ? '$5' : undefined,
  opacity: isExpanded ? 1 : 0,
  height: isExpanded ? 'auto' : 1,
  paddingX: {
    base: '$0',
    md: '$4',
  },
  // TODO: RN - Migrate animation.
  // initial={{
  //   height: '1px',
  //   opacity: 0,
  // }}
  // animate={{
  //   height: 'auto',
  //   opacity: 1,
  //   transition: {
  //     duration: 333,
  //     easing: 'easeInOut',
  //   },
  // }}
}));

type StringType<T> = {
  [K in keyof T]: T[K] extends Maybe<ILocaleString> ? { locale: string } : T[K];
};

export type IAccordionWidgetProps = StringType<
  Required<Pick<IAccordionWidget, 'accordionTitle'>>
> & {
  accordionContent: Array<
    StringType<Pick<IAccordionEntry, 'collapsedContent' | 'expandedContent'>>
  >;
};

const AccordionWidget: React.FC<React.PropsWithChildren<IAccordionWidgetProps>> = ({
  accordionTitle,
  accordionContent,
}) => {
  const [expandedIdx, setExpandedIdx] = useState(-1);

  return (
    <AccordionWrapper>
      {!!accordionTitle?.locale?.trim() && (
        <AccordionTitle>{accordionTitle?.locale}</AccordionTitle>
      )}
      {accordionContent.map(({ collapsedContent, expandedContent }, idx, contentArray) => {
        const isExpanded = expandedIdx === idx;
        const isLastChild = contentArray.length - 1 === idx;
        return (
          <AccordionItem
            key={`accordionItem${idx}`}
            isExpanded={isExpanded}
            isLastChild={isLastChild}
            onPress={() => {
              setExpandedIdx(isExpanded ? -1 : idx);
            }}
          >
            <HStack marginY="$4" alignItems="center" justifyContent="center">
              <CollapsedContentHeader
                nativeID={`accordionButton${idx}`}
                isExpanded={isExpanded}
                accessibilityState={{ expanded: isExpanded }}
                // aria-controls={`accordionContent${idx}`}
              >
                {/* @ts-expect-error TS(2339) FIXME: Property 'locale' does not exist on type 'ILocaleS... Remove this comment to see the full error message */}
                {collapsedContent?.locale}
              </CollapsedContentHeader>

              <RotateAnimatedView isRotated={isExpanded} toDeg={45}>
                <ArrowDown isExpanded={isExpanded} />
              </RotateAnimatedView>
            </HStack>

            <ExpandedContent
              nativeID={`accordionContent${idx}`}
              isExpanded={isExpanded}
              // TODO: RN - migrate aria-labeledby to improve accesibility
              // aria-labelledby={`accordionButton${idx}`}
              // role="region"
              // pose={isExpanded ? 'open' : 'closed'}
            >
              {/* @ts-expect-error TS(2339) FIXME: Property 'locale' does not exist on type 'ILocaleS... Remove this comment to see the full error message */}
              {expandedContent?.locale}
            </ExpandedContent>
          </AccordionItem>
        );
      })}
    </AccordionWrapper>
  );
};

export default AccordionWidget;
