import { useRewardsSectionQuery } from 'generated/sanity-graphql';
import { withSimpleCache } from 'state/graphql/with-simple-cache';

const cachedRewardsSectionQuery = withSimpleCache(useRewardsSectionQuery);

export const useRewardsSection = (id?: string) => {
  const rewardsSectionId = id ?? '';

  const { data, loading } = cachedRewardsSectionQuery({
    variables: {
      rewardsSectionId,
    },
    skip: !rewardsSectionId,
  });

  const rewardsSection = data?.RewardsSection;

  return {
    rewardsSection,
    rewardsSectionLoading: loading,
  };
};
