import { OrderLocatorTab } from 'state/launchdarkly/variations';

export enum StoreLocatorViews {
  LIST_VIEW = 'listView',
  MAP_VIEW = 'mapView',
}

export type LocatorTabIds = {
  [key in OrderLocatorTab]: number;
};

export interface IStoreLocatorTabsProps {
  errorNearby: null | Error;
  errorRecent: null | Error;
  errorFavs: null | Error;
}

export interface IStoreLocatorTabs {
  currentTab: OrderLocatorTab;
  currentTabHasError: boolean;
  currentTabIndex: number;
  currentTabKey: string;
  handleTabChange: (value: number) => void;
  locatorTabIds: LocatorTabIds;
  locatorTabs: OrderLocatorTab[];
}
