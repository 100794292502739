import React, { useCallback } from 'react';

import { Box, Header, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { LayoutContainer } from 'components/layout/styled';
import { ResponsiveCarousel } from 'components/responsive-carousel';
import { ILocaleImageFragment, ILocaleStringFragment } from 'generated/sanity-graphql';
import { primitive } from 'styles/constants/primitives';

import { Image } from './styled';

export type IReward = {
  image: ILocaleImageFragment;
  name: ILocaleStringFragment;
};

export type RewardCarouselCategory = {
  label: ILocaleStringFragment;
  rewards: Array<IReward>;
};

export type IRewardsCarouselProps = {
  rewardsCarouselTitle: {
    locale: string;
  };
  rewardsCarouselDescription: {
    locale: string;
  };
  rewardCarouselCategories: Array<RewardCarouselCategory>;
};

const styles: { background: string; color: string }[] = [
  {
    background: 'transparent',
    color: Styles.color.black,
  },
  {
    background: Styles.color.orange,
    color: Styles.color.black,
  },
  {
    background: Styles.color.tertiary,
    color: Styles.color.white,
  },
  {
    background: Styles.color.red,
    color: Styles.color.white,
  },
  {
    background: primitive.bk.$meltyYellow,
    color: Styles.color.black,
  },
];

const itemNameLocaleKeyExtractor = ({ name }: IReward, index: number) =>
  name.locale || index.toString();

const RewardsCarouselWidget: React.FC<React.PropsWithChildren<IRewardsCarouselProps>> = ({
  rewardsCarouselTitle,
  rewardsCarouselDescription,
  rewardCarouselCategories,
}) => {
  const { formatMessage } = useIntl();

  const RewardsCategories = ({
    rewardCategory,
    categoryIndex,
  }: {
    rewardCategory: RewardCarouselCategory;
    categoryIndex: number;
  }) => (
    <Box alignItems={'center'} margin={'$0'} marginTop={'$10'}>
      <Header marginBottom={'$0'}>
        {rewardCategory.label.locale} {formatMessage({ id: 'crowns' })}
      </Header>
      <ResponsiveCarousel
        alignWithParent
        data={rewardCategory.rewards}
        itemWidth={260}
        centerCards
        bgColor={Styles.color.teal}
        keyExtractor={itemNameLocaleKeyExtractor}
        renderItem={useCallback(
          ({ item }: { item: IReward }) => {
            const styleIndex = categoryIndex > 5 ? 0 : categoryIndex;
            const itemNameColor = styles[styleIndex].color ?? 'black';
            const carouselBackgroundColor = styles[styleIndex].background ?? 'transparent';

            return (
              <Box
                backgroundColor={carouselBackgroundColor}
                justifyContent={'center'}
                alignItems="center"
                paddingX={'$2'}
                paddingY={'$2'}
                margin={'$0'}
              >
                <Image
                  lockedAspectRatio
                  objectFitContain
                  alt={item.name.locale ?? ''}
                  image={item.image.locale}
                />
                <Text color={itemNameColor}>{item.name.locale}</Text>
              </Box>
            );
          },
          [categoryIndex]
        )}
      />
    </Box>
  );

  return (
    <LayoutContainer>
      <Header>{rewardsCarouselTitle.locale}</Header>
      <Text>{rewardsCarouselDescription.locale}</Text>
      {rewardCarouselCategories.map((rewardCategory, i) => (
        <RewardsCategories key={i} categoryIndex={i} rewardCategory={rewardCategory} />
      ))}
    </LayoutContainer>
  );
};

export default RewardsCarouselWidget;
