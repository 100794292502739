import { useMemo } from 'react';

import { useIntl } from 'react-intl';

import { IRestaurant } from '@rbi-ctg/store';
import { useServiceModeStatus } from 'hooks/use-service-mode-status';
import { useGetRestaurantAvailabilityFn, useIsMobileOrderingAvailable } from 'utils/restaurant';
import { PICKUP_SERVICE_MODES } from 'utils/service-mode';

interface UseErrorMessageOptions {
  restaurant: IRestaurant;
}

export const useMessage = ({ restaurant }: UseErrorMessageOptions) => {
  const { formatMessage } = useIntl();
  const { serviceModeStatus } = useServiceModeStatus(restaurant);
  const getRestaurantAvailability = useGetRestaurantAvailabilityFn();
  const isMobileOrderingAvailable = useIsMobileOrderingAvailable(restaurant);

  const isRestaurantAvailable = getRestaurantAvailability(restaurant);
  const isRestaurantOpen = PICKUP_SERVICE_MODES.some(
    serviceMode => serviceModeStatus[serviceMode].available
  );

  return useMemo(() => {
    if (restaurant.hasMobileOrdering && !isRestaurantOpen) {
      return formatMessage({ id: 'locationClosed' });
    }

    if (!restaurant.hasMobileOrdering) {
      return formatMessage({ id: 'storeDoesNotHaveMobileOrdering' });
    }

    if (!isMobileOrderingAvailable || !isRestaurantAvailable) {
      return formatMessage({ id: 'mobileOrderingUnavailable' });
    }

    return;
  }, [
    formatMessage,
    isMobileOrderingAvailable,
    isRestaurantAvailable,
    isRestaurantOpen,
    restaurant.hasMobileOrdering,
  ]);
};
