import * as Application from 'expo-application';

import { getConfigValue } from 'utils/environment/config';

import versions from '../../../versions';

export const webVersion = versions
  ? `${versions.APP_VERSION_MAJOR}.${versions.APP_VERSION_MINOR}.${versions.APP_VERSION_PATCH}`
  : `UNKNOWN`;

/** will report the binary runtime version and NOT any subsequent OTAs
 * ex 7.1.2 on mobile / web */
export const binaryRuntimeAppVersion = Application.nativeApplicationVersion || webVersion;

/** ex:
 * - mobile: 7.1.2 (2)
 * - web: 7.1.2
 * */
export const binaryRuntimeAppVersionWithBuildNumber = Application.nativeApplicationVersion
  ? `${Application.nativeApplicationVersion} (${Application.nativeBuildVersion})`
  : webVersion;

/** will always report was in versions.js when built (either binary release or subsequent OTA)
 * EX: 7.1.2
 */
export const appVersion = getConfigValue({ key: 'version' }) || webVersion;
