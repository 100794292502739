import { useCallback } from 'react';

import type { ILocaleSmartBlockContent } from 'hooks/use-locale-smart-block-content/types';
import { useLocaleSmartBlockContent } from 'hooks/use-locale-smart-block-content/use-locale-smart-block-content';
import logger from 'utils/logger';

import type { ICmsOffer } from '../types';

import type { ApplyOfferPlaceholders } from './types';

/**
 * Apply the template's placeholders to a config offer, using the interpolation contract.
 */
export const useApplyOfferPlaceholders = () => {
  const { transformSmartBlockContent } = useLocaleSmartBlockContent();

  const applyOfferPlaceholders = useCallback<ApplyOfferPlaceholders>(
    ({ configOffer, engineOffer, offerTemplate, placeholderFieldsMap }) => {
      const { localizedImage, backgroundImage: templateBackgroundImage } = offerTemplate || {};
      const hasTemplateLocalizedImage = Boolean(localizedImage?.locale?.app);
      let personalizedCmsConfigOffer: ICmsOffer | null = null;

      const transformField = (blockContent: ILocaleSmartBlockContent) =>
        transformSmartBlockContent({
          blockContent,
          // this object should respect the interpolation contract
          interpolationSourceObject: {
            engineOffer,
            template: offerTemplate,
            config: configOffer,
          },
          placeholderFieldsMap,
        });

      try {
        personalizedCmsConfigOffer = {
          ...configOffer,
          // adding the engine id because the config offer has an internal engine document reference
          loyaltyEngineId: engineOffer.id,
          // Override offer config data with personalization from template
          ...(offerTemplate?.name && { name: transformField(offerTemplate.name) }),
          ...(offerTemplate?.description && {
            description: transformField(offerTemplate.description),
          }),
          ...(hasTemplateLocalizedImage && { localizedImage }),
          ...(templateBackgroundImage && { backgroundImage: templateBackgroundImage }),
        };
      } catch (error) {
        const message = error instanceof Error && error?.message ? `Error: ${error.message}` : '';
        logger.error(`Failed processing personalized offer: ${engineOffer.id} - ${message}`);
      }

      return personalizedCmsConfigOffer;
    },
    [transformSmartBlockContent]
  );

  return { applyOfferPlaceholders };
};
