import { Box, Text, Badge as UCLBadge, View } from '@rbilabs/universal-components';

import Link from 'components/link';
import { theme } from 'styles/configure-theme';
import { primitive } from 'styles/constants/primitives';

export const IconWrap = View.withConfig({
  alignItems: 'center',
  justifyContent: 'center',
  height: '20px',
});

export const NavLinkText = Text.withConfig<{ $isActive?: boolean }>(p => ({
  marginTop: 1,
  marginBottom: 0,
  fontSize: 'xs',
  color: p.$isActive ? primitive.bk.$bbqBrown : theme.token('text-default'),
  bold: p.$isActive,
}));

export const FloatingLink = Link.withConfig({
  minW: '109px',
  maxH: '50',
  bgColor: theme.token('icon-button-secondary'),
  position: 'absolute',
  bottom: '66px',
  right: '11px',
  borderRadius: '9999px',
  paddingX: 4,
  paddingY: 3,
  alignItems: 'center',
  alignContent: 'center',
  shadow: '1',
  borderWidth: 0,
});

export const Badge = UCLBadge.withConfig({
  bgColor: primitive.bk.$meltyYellow,
  _text: {
    fontFamily: 'heading',
    fontSize: 'xs',
    color: primitive.bk.$bbqBrown,
  },
  width: '$5',
  height: '$5',
  style: {
    minWidth: 'auto',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
});

export const BadgeContainer = Box.withConfig({
  position: 'absolute',
  bottom: '$3',
  left: '$6',
  width: '$5',
  height: '$5',
  zIndex: 1,
});
