import { Svg, SvgPath, SvgProps, registerSVG } from '../../../components/svg';

export function CashAppLogoMark(props: SvgProps) {
  return (
    <Svg {...props} width="2139" height="299" viewBox="0 0 2139 299" fill="none">
      <SvgPath
        d="M0 143.52C0 93.2833 0 68.1649 9.77671 48.9771C18.3766 32.0989 32.0989 18.3766 48.977 9.77672C68.1649 0 93.2833 0 143.52 0H155.48C205.717 0 230.835 0 250.023 9.77672C266.901 18.3766 280.623 32.0989 289.223 48.9771C299 68.1649 299 93.2833 299 143.52V155.48C299 205.717 299 230.835 289.223 250.023C280.623 266.901 266.901 280.623 250.023 289.223C230.835 299 205.717 299 155.48 299H143.52C93.2833 299 68.1649 299 48.977 289.223C32.0989 280.623 18.3766 266.901 9.77671 250.023C0 230.835 0 205.717 0 155.48V143.52Z"
        fill="#00D64F"
      />
      <SvgPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M157.268 97.5315C172.761 97.5315 187.611 103.805 197.314 112.417C199.768 114.595 203.441 114.582 205.744 112.248L217.288 100.54C219.724 98.0683 219.635 94.022 217.004 91.7637C209.258 85.1042 198.858 78.5556 186.058 74.3629L189.679 57.1218C190.464 53.3639 187.602 49.8322 183.76 49.8322H161.452C158.599 49.8322 156.132 51.8287 155.538 54.6194L152.277 69.9528C122.622 71.4346 97.4878 86.2311 97.4878 116.592C97.4878 142.875 118.283 154.14 140.231 161.935C161.026 169.73 171.998 172.623 171.998 183.595C171.998 194.86 161.026 201.502 144.85 201.502C130.102 201.502 114.654 196.635 102.665 184.815C100.314 182.495 96.5517 182.477 94.2002 184.797L81.7951 197.034C79.3683 199.425 79.3904 203.356 81.8351 205.725C91.5071 215.114 103.752 221.911 117.728 225.717L114.325 241.69C113.531 245.434 116.366 248.966 120.199 248.997L142.543 249.165C145.418 249.188 147.916 247.178 148.501 244.36L151.727 229.005C187.234 226.813 208.965 207.513 208.965 179.265C208.965 153.274 187.309 142.298 161.026 133.345C146.008 127.852 133.008 124.103 133.008 112.843C133.008 101.871 145.143 97.5315 157.268 97.5315"
        fill="white"
      />
      <SvgPath
        d="M554.494 105.618C551.267 107.613 547.071 106.791 544.842 103.74C533.987 88.221 516.033 79.1853 497.111 79.7427C460.675 79.7427 438.261 108.581 438.261 149.74C438.261 190.9 461.027 220.295 497.463 220.295C516.708 220.735 534.838 211.201 545.37 195.095C547.541 191.985 551.707 191.076 554.992 192.953L572.242 202.986C575.733 205.011 576.936 209.499 574.912 213.019C574.795 213.225 574.677 213.43 574.531 213.606C557.339 239.393 528.091 254.502 497.111 253.651C439.112 253.651 399.625 211.377 399.625 149.74C399.625 88.1037 439.112 46.3868 496.495 46.3868C526.917 45.5361 555.784 59.8524 573.562 84.5539C575.968 87.8397 575.264 92.4455 571.978 94.8805C571.832 94.9978 571.685 95.0858 571.538 95.1738L554.464 105.618H554.494Z"
        fill="white"
      />
      <SvgPath
        d="M689.265 249.985H708.393C712.412 249.985 715.668 246.728 715.668 242.709V147.512C715.668 113.041 691.172 98.7832 656.467 98.7832C636.987 98.8419 617.801 103.682 600.639 112.894C597.206 114.772 595.886 119.055 597.646 122.546L604.013 135.483C604.013 135.483 604.101 135.659 604.159 135.747C606.095 139.268 610.496 140.529 613.987 138.622C626.279 132.227 639.862 128.795 653.709 128.56C665.766 128.56 680.083 131.875 680.083 144.226C680.083 158.161 661.865 159.628 640.595 162.708C620.705 165.495 588.493 171.685 588.493 207.798C588.493 236.813 609.499 253.358 639.745 253.358C660.192 253.358 672.954 246.083 682.048 234.319V242.709C682.048 246.728 685.275 249.985 689.294 249.985H689.265ZM680.053 193.834C680.053 215.983 664.065 225.489 646.991 225.489C633.291 225.489 623.756 219.328 623.756 206.185C623.756 191.869 634.64 188.378 647.812 186.001C660.984 183.625 673.306 181.953 680.053 176.878V193.834Z"
        fill="white"
      />
      <SvgPath
        d="M747.15 212.285C749.702 209.293 754.19 208.852 757.271 211.317C769.416 220.529 784.349 225.34 799.574 224.988C815.299 224.988 826.916 219.942 826.916 207.62C826.916 196.414 816.267 193.949 790.48 189.666C763.138 185.119 736.97 175.995 736.97 144.84C736.97 113.684 764.986 98.6637 796.905 98.6637C816.942 98.3703 836.48 104.766 852.439 116.882C855.608 119.317 856.194 123.864 853.73 127.032C853.642 127.15 853.525 127.267 853.437 127.384L844.313 138.092C841.819 141.085 837.419 141.583 834.28 139.236C823.161 131.051 809.696 126.68 795.878 126.768C782.207 126.768 770.678 130.699 770.678 141.29C770.678 153.083 787.224 155.313 803.74 158.1C839.003 164.29 861.416 174.352 861.416 203.66C861.416 234.493 836.773 253.532 797.638 253.532C776.252 254.178 755.305 247.342 738.437 234.17C735.298 231.618 734.828 226.983 737.38 223.844L737.469 223.726L747.12 212.256L747.15 212.285Z"
        fill="white"
      />
      <SvgPath
        d="M882.799 242.709V57.3297C882.799 53.3106 886.055 50.0542 890.075 50.0542H911.08C915.099 50.0542 918.355 53.3106 918.355 57.3297V120.58C928.946 106.41 945.756 98.2545 963.446 98.7532C994.543 98.7532 1012.73 120.902 1012.73 152.498V242.679C1012.73 246.698 1009.48 249.955 1005.46 249.955H984.451C980.432 249.955 977.175 246.698 977.175 242.679V160.683C977.175 144.959 972.159 130.701 951.975 130.701C932.936 130.701 918.355 144.137 918.355 166.55V242.65C918.355 246.669 915.099 249.925 911.08 249.925H890.075C886.055 249.925 882.799 246.669 882.799 242.65V242.709Z"
        fill="white"
      />
      <SvgPath
        d="M1250.75 249.982H1276.8C1277.65 249.982 1278.5 249.836 1279.29 249.542C1283.07 248.164 1285.01 243.968 1283.63 240.184L1215.07 54.7758C1213.96 51.9888 1211.23 50.1993 1208.24 50.2286H1174.38C1171.39 50.1993 1168.66 52.0182 1167.55 54.7758L1098.78 240.155C1098.49 240.976 1098.31 241.827 1098.31 242.707C1098.31 246.726 1101.57 249.982 1105.62 249.982H1130.2C1133.34 249.982 1136.13 247.988 1137.12 245.025L1152.23 200.433H1228.57L1243.79 245.025C1244.79 247.988 1247.58 249.982 1250.72 249.982H1250.75ZM1219.18 169.365H1161.5L1190.87 82.499L1219.15 169.365H1219.18Z"
        fill="white"
      />
      <SvgPath
        d="M1336.29 109.05C1336.2 105.031 1332.85 101.833 1328.81 101.95H1307.98C1303.96 101.95 1300.7 105.207 1300.7 109.226V291.407C1300.7 295.426 1303.96 298.683 1307.98 298.683H1328.98C1333 298.683 1336.26 295.426 1336.26 291.407V230.768C1346.7 245.436 1363.8 253.856 1381.82 253.181C1422.19 253.181 1448.24 219.444 1448.24 175.762C1448.24 132.079 1422.19 98.7528 1381.82 98.7528C1363.89 97.99 1346.76 106.322 1336.26 120.902V109.431C1336.26 109.431 1336.26 109.197 1336.26 109.05H1336.29ZM1336.29 175.791C1336.29 150.297 1349.72 129.82 1374.37 129.82C1399.01 129.82 1411.07 150.327 1411.07 175.791C1411.07 201.255 1399.01 222.26 1374.37 222.26C1349.72 222.26 1336.29 201.314 1336.29 175.791Z"
        fill="white"
      />
      <SvgPath
        d="M1505.74 109.168C1505.74 105.178 1502.45 101.951 1498.43 101.981H1477.48C1477.48 101.981 1477.43 101.981 1477.4 101.981C1473.41 101.981 1470.18 105.266 1470.21 109.256V291.437C1470.21 295.456 1473.47 298.713 1477.48 298.713H1498.43C1502.45 298.713 1505.71 295.456 1505.71 291.437V230.798C1516.15 245.437 1533.28 253.857 1551.27 253.212C1591.58 253.212 1617.63 219.474 1617.63 175.792C1617.63 132.109 1591.58 98.783 1551.27 98.783C1533.34 98.0496 1516.24 106.381 1505.71 120.932V109.462C1505.71 109.462 1505.71 109.286 1505.71 109.198L1505.74 109.168ZM1543.84 222.261C1518.94 222.261 1505.71 201.315 1505.71 175.792C1505.71 150.269 1519.41 129.821 1543.84 129.821C1568.28 129.821 1580.28 150.328 1580.28 175.792C1580.28 201.256 1568.49 222.261 1543.84 222.261Z"
        fill="white"
      />
      <SvgPath
        d="M1747.27 238.336C1747.27 243.646 1744.19 246.726 1738.88 246.726H1718.43C1713.12 246.726 1710.04 243.646 1710.04 238.336V55.2157C1710.04 49.9058 1713.12 46.8254 1718.43 46.8254H1780.21C1825.57 46.8254 1851.91 72.3191 1851.91 110.662C1851.91 149.005 1825.86 174.499 1780.21 174.499H1747.27V238.336V238.336ZM1814.1 110.369C1814.1 91.3293 1802.07 80.68 1779.95 80.68H1747.3V140.615H1779.95C1802.07 140.615 1814.1 129.702 1814.1 110.369Z"
        fill="white"
      />
      <SvgPath
        d="M1948.78 238.925V231.092C1939.83 242.856 1926.92 250.131 1906.5 250.131C1876.26 250.131 1855.25 233.615 1855.25 204.483C1855.25 168.37 1887.46 162.209 1907.32 159.393C1928.59 156.312 1946.81 154.904 1946.81 140.911C1946.81 128.589 1932.52 125.245 1920.5 125.245C1908.47 125.245 1899.75 127.528 1887.43 133.689C1882.39 136.212 1878.49 135.361 1875.96 130.315L1870.65 119.402C1868.42 114.649 1869.24 110.454 1874.03 107.931C1889.13 99.805 1903.19 95.5853 1923.34 95.5853C1958.08 95.5853 1982.43 109.872 1982.43 144.314V238.397C1982.43 243.707 1979.35 246.787 1974.04 246.787H1957.23C1951.92 246.787 1948.84 244 1948.84 238.954L1948.78 238.925ZM1914.6 182.627C1901.72 185.15 1890.51 188.524 1890.51 202.782C1890.51 215.954 1900.05 222.115 1913.75 222.115C1930.82 222.115 1946.78 212.58 1946.78 190.46V173.65C1940.06 178.696 1927.74 180.368 1914.57 182.598L1914.6 182.627Z"
        fill="white"
      />
      <SvgPath
        d="M2022.04 268.025C2023.71 268.582 2025.67 268.582 2027.35 268.582C2037.99 268.582 2043.31 262.421 2050.02 244.497L1996.81 108.697C1994.58 102.829 1997.07 98.8983 2003.23 98.8983H2025.64C2030.4 98.8983 2033.48 101.128 2035.18 105.616L2068.51 201.665L2101.54 105.616C2103.21 101.128 2106.29 98.8983 2111.07 98.8983H2131.52C2137.68 98.8983 2140.47 102.829 2138.24 108.697L2085.02 248.721C2070.18 287.915 2056.45 298.3 2031.8 298.3C2025.64 298.3 2020.6 297.743 2016.7 296.892C2011.65 295.777 2009.98 291.846 2011.39 286.536L2014.76 273.921C2016.17 268.875 2018.69 267.203 2022.04 268.054V268.025Z"
        fill="white"
      />
    </Svg>
  );
}

registerSVG('CashAppLogoMark', CashAppLogoMark);
