import { useRoute } from 'hooks/navigation/use-route';
import { RunningExperiments, useExperiments } from 'state/experiments';

/**
 * Returns the `variantName` for the ongoing Menu A/B experiment, if any.
 *
 * The `OngoingMenuExperiment` flag on Amplitude tell us what's the ongoing
 * experiment that is currently running.
 *
 * The ongoing experiment has the `variantName` that's used to match
 * the menu content from Sanity and the StoreMenu.
 * Different users might get different values depending if they are on
 * Control or Treatment groups.
 *
 * @returns The ongoing variantName under experimentation, if any.
 */
export const useMenuExperimentVariantName = (): string | undefined => {
  const { pathname } = useRoute();
  const { getVariantValue, logExposure } = useExperiments();

  // Ask Amplitude what's the ongoing experiment
  const { value: menuExperimentId = '', payload } =
    getVariantValue(RunningExperiments.OngoingMenuExperiment) || {};

  // Ask Amplitude what's the variantName associated with that experiment
  const { value: menuVariantName } = getVariantValue(menuExperimentId) || {};

  // Log the exposure once if the page matches
  if (payload?.pathname === pathname && menuExperimentId) {
    logExposure(menuExperimentId);
  }

  return menuVariantName;
};
