import { DependencyList, useCallback } from 'react';

import { debounce } from 'lodash-es';

/**
 * Prevents multiple presses from getting through to onPress handler which can cause performance issues or even crashes
 * NOTE: will not debounce multiple clicks if those clicks change @param deps due to internal useCallback
 * @param onPress the onPress we want to debounce
 * @param deps useCallback dependency list
 * @param waitMs millseconds to wait before allowing @param onPress to be called again
 */
const useDebounceOnPressCallback = (
  onPress: (...args: any) => void,
  deps: DependencyList,
  waitMs = 500
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    debounce(onPress, waitMs, {
      leading: true,
      trailing: false,
    }),
    deps
  );
};

export default useDebounceOnPressCallback;
