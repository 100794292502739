import React, { useContext, useState } from 'react';

// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import createDOMProps from 'react-native-web/dist/modules/createDOMProps';
import { Location, Link as RRLink, useLocation } from 'react-router-dom';

import { IBaseProps } from '@rbi-ctg/frontend';
import noop from 'utils/noop';
import { useMemoAll } from 'utils/use-memo-all';

import { useNavigateBack } from './hooks/use-navigate-back';
import { usePriorValue } from './hooks/use-prior-value';
import { ILocation } from './types';

export { useMatch, useParams, useSearchParams, Routes, Route } from 'react-router-dom';

export const Link = (props: any) => {
  const domProps = createDOMProps('a', props);

  return <RRLink {...domProps} />;
};

/**
 * Useful for preventing re-renders for analytics/loggers etc. that care about routes but not real-time
 * DO NOT USE FOR RENDER-TIME LOCATION INFO as this will not trigger re-renders
 */
export const staticLocation: { current: Location } = {
  // should be set very quick at app start but just in case...
  current: { hash: '', key: '', pathname: '', search: '', state: '' },
};

export const getStaticLocationString = () => {
  const loc = staticLocation.current;
  return `${loc.pathname}${loc.search || ''}${loc.hash || ''}`;
};

export const LocationContext = React.createContext<ILocation>({} as ILocation);
export const useLocationContext = () => useContext(LocationContext);

export function LocationProvider(props: IBaseProps) {
  const [storeLocatorCallbackUrl, setStoreLocatorCallbackUrl] = useState('');
  const [addPaymentCallbackUrl, setAddPaymentCallbackUrl] = useState('');

  const { addNavigateBackListener } = useNavigateBack();
  const addHardwareBackButtonListener = noop;
  const removeHardwareBackButtonListener = noop;
  const [signUpEmail, setSignUpEmail] = useState('');
  const location = useLocation();
  staticLocation.current = location;
  const referrerLocation = usePriorValue(location.pathname + location.search, undefined);

  const value = useMemoAll({
    addNavigateBackListener,
    addHardwareBackButtonListener,
    signUpEmail,
    location,
    referrerLocation,
    removeHardwareBackButtonListener,
    setSignUpEmail,
    setStoreLocatorCallbackUrl,
    storeLocatorCallbackUrl,
    addPaymentCallbackUrl,
    setAddPaymentCallbackUrl,
  });

  return <LocationContext.Provider value={value}>{props.children}</LocationContext.Provider>;
}

export default LocationContext.Consumer;
