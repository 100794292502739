import React from 'react';

import { StaticImage, StaticImages } from 'components/picture/static-image';

export const StoreLocatorLoader = () => (
  <StaticImage
    alt="Store locator loading"
    style={{ width: 100, height: 130, marginTop: -15 }}
    source={StaticImages.StoreLocatorLoader}
  />
);
