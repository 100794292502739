import { IComboSlot, IComboSlotSelection, IComboSlotSelections } from '@rbi-ctg/menu';
import { clamp } from 'utils/calc';

/**
 * Adds a new property called `excludedAmount` on each comboSlot selections entry.
 * The prop `excludedAmount` describes how many of the selected items are to be included in the combo,
 * and what is to be charged as a separate item.
 * @param comboSlotSelections the comboSlot selections
 * @param availableQuantity the available quantity for that comboSlot
 * @returns
 */
export function assignInclusionComboSlotSelections(
  comboSlotSelections: IComboSlotSelection[],
  availableQuantity: number
): Array<
  IComboSlotSelection & {
    excludedAmount: number;
  }
> {
  let consumedQuantity = 0;

  return (comboSlotSelections || []).map(comboSlotSelection => {
    const exceedingQuantity = consumedQuantity + comboSlotSelection.quantity - availableQuantity;

    // If exceedingQuantity is 0 or negative, nothing gets excluded.
    // If exceedingQuantity is positive, that amount is marked as excluded.
    // The excludedAmount can never be less than 0 or more than the comboSlotSelection quantity.
    const excludedAmount = clamp(0, comboSlotSelection.quantity)(exceedingQuantity);

    // Add up the consumed quantity
    consumedQuantity += comboSlotSelection.quantity - excludedAmount;
    return { ...comboSlotSelection, excludedAmount };
  });
}

export function partitionComboSlots(
  comboSlots: IComboSlot[],
  comboSlotSelections: IComboSlotSelections
) {
  return comboSlots.map(comboSlot => ({
    id: comboSlot._id,
    data: comboSlotSelections[comboSlot._id].data,
    selections:
      assignInclusionComboSlotSelections(
        comboSlotSelections[comboSlot._id].selections,
        comboSlot.maxAmount
      ) || [],
  }));
}
