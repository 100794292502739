import React, { PropsWithChildren } from 'react';

import useAuthRedirects from 'hooks/auth/use-auth-redirects';
import { useAuthContext } from 'state/auth';

const AuthRequired = ({ children }: PropsWithChildren) => {
  const { isAuthenticated, isReAuthenticating } = useAuthContext();

  useAuthRedirects({ skip: isReAuthenticating });

  return isAuthenticated || isReAuthenticating ? <>{children}</> : null;
};

export default AuthRequired;

/**
 * HOC that requires a user to be signed in before rendering the passed
 * in <Component />. If a user is not signed in it redirects to /signup.
 */
export const withAuthRequired = (Component: React.ComponentType) => (props: any) => {
  return (
    <AuthRequired>
      <Component {...props} />
    </AuthRequired>
  );
};
