import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import isEqual from 'lodash-es/isEqual';
import { useIntl } from 'react-intl';
import { FlatList } from 'react-native';

import { IRestaurant } from '@rbi-ctg/store';
import { StoreLocatorEmptyStates } from 'components/store-locator-empty-states';
import { useGeolocation } from 'state/geolocation';
import { OrderLocatorTab } from 'state/launchdarkly/variations';
import { ClickEventComponentNames, CustomEventNames, useMParticleContext } from 'state/mParticle';
import { useStoreContext } from 'state/store';

import { LoadingIndicator } from '../loading-indicator';
import { StoreCard } from '../store-card';

interface IStoreCardListProps {
  currentTabHasError?: boolean;
  isSearching?: boolean;
  restaurants: IRestaurant[];
  tab: OrderLocatorTab;
  onCardPress?: (storeId?: string, storeNumber?: string, storeAddress?: string) => void;
  handleSelectStore?: (storeId: string) => void;
  isInSupportForm?: boolean;
  restaurantFocused?: IRestaurant;
  setRestaurantFocused: Dispatch<SetStateAction<IRestaurant | undefined>>;
  mapRestaurantFocused?: IRestaurant;
}

const StoreCardList: React.FC<IStoreCardListProps> = ({
  currentTabHasError = false,
  isSearching,
  restaurants = [],
  tab,
  onCardPress,
  handleSelectStore,
  isInSupportForm,
  restaurantFocused,
  setRestaurantFocused,
  mapRestaurantFocused,
}) => {
  const { formatMessage } = useIntl();
  const { logButtonClick } = useMParticleContext();
  const { store, noStoreSelected } = useStoreContext();
  const flatListRef = useRef<FlatList<IRestaurant> | null>(null);
  const { coordinatesAvailable, isPermissionGranted } = useGeolocation();
  const [shouldMoveAtTop, setShouldMoveAtTop] = React.useState(false);
  const [restaurantExpanded, setRestaurantExpanded] = useState<IRestaurant | undefined>(
    restaurants.find(
      restaurant =>
        restaurant.id === mapRestaurantFocused?.id ||
        restaurant.id === restaurantFocused?.id ||
        restaurant.id === store?.id
    )
  );

  useEffect(() => {
    if (mapRestaurantFocused) {
      setRestaurantExpanded(mapRestaurantFocused);
      setShouldMoveAtTop(true);
    }
  }, [mapRestaurantFocused]);

  useEffect(() => {
    if (mapRestaurantFocused && flatListRef.current) {
      flatListRef.current.scrollToIndex({ animated: true, index: 0 });
    }
  }, [mapRestaurantFocused, flatListRef]);

  useEffect(() => {
    if (noStoreSelected || !store._id) {
      return;
    }

    setRestaurantFocused(store);
  }, [store, noStoreSelected, setRestaurantFocused]);

  const finalList = useMemo(() => {
    if (!restaurantExpanded || !shouldMoveAtTop) {
      return restaurants;
    }

    return [
      restaurantExpanded,
      ...restaurants.filter(restaurant => restaurant.id !== restaurantExpanded?.id),
    ];
  }, [shouldMoveAtTop, restaurants, restaurantExpanded]);

  const handleOnCardPress = useCallback(
    ({ restaurant }: { restaurant: IRestaurant }) => {
      if (onCardPress && restaurant) {
        onCardPress(
          String(restaurant.id),
          String(restaurant.number),
          String(restaurant.physicalAddress?.address1)
        );
        setRestaurantExpanded(restaurant);
        setShouldMoveAtTop(false);
        return;
      }

      setRestaurantFocused(prevRestaurant => {
        if (!restaurant._id) {
          return prevRestaurant;
        }
        logButtonClick({
          attributes: {
            StoreId: restaurant._id,
            Name: CustomEventNames.BUTTON_CLICK_STORE_SELECTED,
            name: CustomEventNames.BUTTON_CLICK_STORE_SELECTED,
            component: ClickEventComponentNames.BUTTON,
          },
        });
        return restaurant;
      });
      setRestaurantExpanded(restaurant);
      setShouldMoveAtTop(false);
    },
    [onCardPress, setRestaurantFocused, logButtonClick]
  );

  const renderItem = useCallback(
    ({ item }: { item: IRestaurant }) => {
      return (
        <StoreCard
          restaurant={item}
          onCardPress={handleOnCardPress}
          isSelected={item.id === restaurantExpanded?.id}
          handleSelectStore={handleSelectStore}
          isInSupportForm={isInSupportForm}
        />
      );
    },
    [restaurantExpanded, handleOnCardPress, handleSelectStore, isInSupportForm]
  );

  if (isSearching) {
    return <LoadingIndicator ariaLabel={formatMessage({ id: 'locationSearch' })} />;
  }

  if (restaurants.length === 0) {
    return (
      <StoreLocatorEmptyStates
        tab={tab}
        locationPermissionsGranted={isPermissionGranted}
        coordinatesAvailable={coordinatesAvailable}
        currentTabError={currentTabHasError}
      />
    );
  }

  return (
    <FlatList
      keyExtractor={(item, index) => item?.id || index + '_restaurant'}
      data={finalList}
      renderItem={renderItem}
      ref={ref => (flatListRef.current = ref)}
    />
  );
};

// StoreCardList.whyDidYouRender = true;

export default React.memo(StoreCardList, (prevProps, nextProps) => {
  if (prevProps.restaurantFocused !== nextProps.restaurantFocused) {
    return false;
  }
  if (prevProps.tab !== nextProps.tab) {
    return false;
  }
  if (prevProps.isSearching !== nextProps.isSearching) {
    return false;
  }
  if (prevProps.currentTabHasError !== nextProps.currentTabHasError) {
    return false;
  }
  if (
    !isEqual(
      prevProps?.restaurants?.map(x => x._id),
      nextProps?.restaurants?.map(x => x._id)
    )
  ) {
    return false;
  }

  return true;
});
