import { Box, HStack, Header as SectionHeader, Text } from '@rbilabs/universal-components';

import Link from 'components/link';

export const CardWrap = Box.withConfig(() => ({
  borderRadius: 16,
  flex: 1,
  shadow: 2,
  marginY: 2,
  marginX: 1,
}));

export const ImageLink = Link.withConfig(() => ({
  width: 'full',
}));

export const TextWrapper = Box.withConfig(() => ({
  justifyContent: 'space-between',
  flex: 1,
  width: 'full',
  padding: {
    base: 4,
    sm: 5,
  },
}));

export const ButtonWrapper = HStack.withConfig(() => ({
  alignSelf: {
    base: 'flex-start',
    sm: 'flex-end',
  },
  marginTop: 4,
}));

export const Header = SectionHeader.withConfig(() => ({
  variant: 'headerTwo',
  marginBottom: 2,
  focusable: true,
  accessible: true,
  accessibilityRole: 'header',
}));

export const Description = Text.withConfig(() => ({
  variant: 'copyTwo',
  accessible: true,
  focusable: true,
}));

export const Terms = Link.withConfig(() => ({
  isUnderlined: true,
  isInline: true,
  variant: 'copyTwo',
  marginY: 2,
}));
