import { IntlFormatters } from 'react-intl';

import { SignUpFieldsVariations } from 'state/launchdarkly/variations';
import { Region } from 'utils/environment/types';
import {
  COUNTRY_BASED_ZIP_CODE_REGEX,
  getPhoneNumberErrorMessage,
  isEmailValid,
  isNameInputValid,
  isPhoneNumberValid,
} from 'utils/form';
import { ISOs, ISOsToRegions } from 'utils/form/constants';
import { ISignupErrors, ISignupForm } from 'utils/form/types';
import { validationConfig } from 'utils/form/user-profile-validation-config/validation';

import { getDobErrors, getLegacyDobErrors } from './dobValidation';
import { IOverrides, OverridableFields } from './types';

export { prepareErrorKeys } from './prepare-error-keys';
export { generateDisplayedErrors } from './generate-displayed-errors';

export const validateSignupFormError = ({
  formatMessage,
  region,
  overrides,
  signUpFieldsVariations,
  simpleValidation,
}: {
  formatMessage: IntlFormatters['formatMessage'];
  region: Region;
  overrides?: IOverrides;
  signUpFieldsVariations: SignUpFieldsVariations;
  simpleValidation?: boolean;
}) => async (values: ISignupForm) => {
  const errors: ISignupErrors = {};
  const isRequired = (
    required: boolean | { readonly locales: readonly string[] },
    field?: OverridableFields
  ) => {
    if (field && overrides?.[field]) {
      return overrides[field].required;
    }
    return required === true || (required !== false && required.locales.includes(region));
  };

  if (signUpFieldsVariations.agreesToTermsOfService && !values.agreesToTermsOfService) {
    errors.agreesToTermsOfService = formatMessage({ id: 'agreeToTermsOfServiceError' });
  }
  if (signUpFieldsVariations.name) {
    if (!values.name.trim()) {
      errors.name = formatMessage({ id: 'nameRequiredError' });
    } else if (!isNameInputValid(values.name)) {
      errors.name = formatMessage({ id: 'notValidNameError' });
    }
  }

  if (values.email === '') {
    errors.email = formatMessage({ id: 'emailRequiredError' });
  } else if (!isEmailValid(values.email.trim())) {
    errors.email = formatMessage({ id: 'notValidEmailError' });
  }

  if (signUpFieldsVariations.favoriteStoreSelector) {
    if (!values.favoriteLocation?.storeId || !values.favoriteLocation?.storeNumber) {
      errors.favoriteLocation = formatMessage({ id: 'favoriteLocationRequiredError' });
    }
  }

  const selectedCountryRegion = ISOsToRegions[values.country];

  const phoneNumberValidation =
    isRequired(validationConfig.phoneNumber.required, OverridableFields.PHONE) || values.phoneNumber
      ? await isPhoneNumberValid({
          phoneNumber: values.phoneNumber,
          country: selectedCountryRegion,
          simpleValidation,
        })
      : { valid: true };

  if (!phoneNumberValidation.valid) {
    errors.phoneNumber = getPhoneNumberErrorMessage(phoneNumberValidation.error, formatMessage);
  }

  let dobError;
  if (typeof values.dob === 'object') {
    dobError = getDobErrors(values.dob, formatMessage, region) ?? undefined;
  } else {
    dobError = getLegacyDobErrors(values.dob, formatMessage) ?? undefined;
  }

  if (dobError) {
    errors.dob = dobError;
  }

  const zipCodeError = (() => {
    if (isRequired(validationConfig.zipcode.required) && !values.zipcode) {
      return values.country === ISOs.USA
        ? formatMessage({ id: 'zipCodeRequiredError' })
        : formatMessage({ id: 'postalCodeRequiredError' });
    }

    const regex = COUNTRY_BASED_ZIP_CODE_REGEX[values.country];

    if (values.zipcode && values.country && !regex.test(values.zipcode.toUpperCase())) {
      return values.country === 'USA'
        ? formatMessage({ id: 'zipCodeInvalid' })
        : formatMessage({ id: 'postalCodeInvalid' });
    }

    return undefined;
  })();

  if (zipCodeError) {
    errors.zipcode = zipCodeError;
  }

  return errors;
};
