import React from 'react';

import { Box, Pressable } from '@rbilabs/universal-components';

import Picture from 'components/picture';

import { ICard } from './types';

const Container = Pressable.withConfig({
  backgroundColor: '__legacyToken.background-pattern',
  shadow: '1',
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  borderBottomRightRadius: 8,
  borderBottomLeftRadius: 8,
  paddingY: {
    base: '$2',
    md: '$3',
  },
  paddingX: {
    base: '$3',
    md: '$4',
  },
  _web: {
    cursor: 'pointer',
  },
  _hover: {
    shadow: '3',
  },
  margin: 1,
});

const ImageContainer = Box.withConfig<{ imageHeightWidthRatio: number }>(p => ({
  aspectRatio: 1 / p.imageHeightWidthRatio,
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
  borderBottomRightRadius: 4,
  borderBottomLeftRadius: 4,
  width: 'full',
  alignSelf: 'center',
}));

const Content = Box.withConfig({
  flex: 1,
});

export const Card: React.FC<React.PropsWithChildren<ICard>> = ({
  image,
  children,
  imageHeightWidthRatio = 0.75,
  onPress,
  size,
}) => {
  return (
    <Container onPress={onPress} width={size}>
      <ImageContainer imageHeightWidthRatio={imageHeightWidthRatio} height="$32">
        <Picture image={image} height="full" w="100%" alt={''} objectFitContain />
      </ImageContainer>
      <Content>{children}</Content>
    </Container>
  );
};

export default React.memo(Card);
