import { SanityMenuObject } from '@rbi-ctg/menu';
import {
  IGetComboPlusQuery,
  IGetItemPlusQuery,
  IGetPickerPlusQuery,
} from 'generated/sanity-graphql';
import { GetVariantContentOptionsFn } from 'pages/menu/hooks/use-get-variant-content-options';
import { fixTypes } from 'remote/api/menu-objects';
import { remapMenuObject } from 'state/menu/menu-options/hooks/remap-menu-object';
import { IMenuObject } from 'state/menu/types';

export const getValidDataFromQuery = (
  data: IGetComboPlusQuery | IGetItemPlusQuery | IGetPickerPlusQuery | undefined,
  getVariantContentOptions: GetVariantContentOptionsFn
): IMenuObject | null | undefined => {
  if (!data) {
    return;
  }

  let returnValue;

  if ('Picker' in data) {
    returnValue = data.Picker as SanityMenuObject;
  } else if ('Combo' in data) {
    returnValue = data.Combo as SanityMenuObject;
  } else if ('Item' in data) {
    returnValue = data.Item as SanityMenuObject;
  }
  return returnValue
    ? remapMenuObject({
        menuObject: fixTypes(returnValue) as IMenuObject | null,
        getVariantContentOptions,
      })
    : null;
};
