import { Observable } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';

import { forceRefreshGetCurrentSession } from 'remote/auth/get-current-session';
import logger from 'utils/logger';

// Generally the app doesn't attempt to make requests that fail unauthorized but it happens to ~5K users a week. Try to recover.
export const refreshAndRetryBadAuthLink = onError(({ graphQLErrors, forward, operation }) => {
  const isUnauthenticatedError = graphQLErrors?.some(
    x => x?.extensions?.code === 'UNAUTHENTICATED'
  );

  if (isUnauthenticatedError) {
    return new Observable(observer => {
      forceRefreshGetCurrentSession()
        .then(session => {
          logger.info({
            message: 'Retrying request - forceRefreshGetCurrentSession succeeded',
            operationName: operation.operationName,
            isForceRefreshUserSession: true,
          });

          operation.setContext(({ headers = {} }) => ({
            headers: {
              ...headers,
              authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
            },
          }));

          // Retry the request
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };
          forward(operation).subscribe(subscriber);
        })
        .catch(error => {
          logger.error({
            error,
            operationName: operation.operationName,
            message: 'Cannot retry request - forceRefreshGetCurrentSession failed',
            isForceRefreshUserSession: true,
          });
          observer.error(graphQLErrors);
        });
    });
  }

  return undefined;
});
