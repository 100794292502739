import React, {
  ComponentType,
  Dispatch,
  SetStateAction,
  createContext,
  memo,
  useContext,
  useEffect,
  useState,
} from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import useEffectOnUnmount from 'hooks/use-effect-on-unmount';
import { useEffectOnUrlChange } from 'hooks/use-effect-on-url-change';
import { useIsMobileBreakpoint } from 'hooks/use-media-query';
import { useMemoAll } from 'utils/use-memo-all';

export interface IMobileFooterContext {
  FooterComponent: JSX.Element | null;
  __setFooterComponent: Dispatch<SetStateAction<JSX.Element | null>>;
}

export const MobileFooterContext = createContext<IMobileFooterContext>({
  FooterComponent: null,
  __setFooterComponent: () => null,
});

export function portalToFooter<Props>(Component: ComponentType<React.PropsWithChildren<Props>>) {
  return memo<ComponentType<Props>>(function PortalToFooter(props: Props) {
    const { __setFooterComponent } = useContext(MobileFooterContext);

    useEffect(() => {
      // @ts-expect-error TS(2322) Type 'Props' is not assignable to type 'IntrinsicAttributes & Props & { children?: ReactNode; }'.
      __setFooterComponent(<Component {...props} />);
    }, [props, __setFooterComponent]);

    useEffectOnUnmount(() => {
      __setFooterComponent(null);
    });

    return null;
  });
}

export const MobileFooterProvider = ({ children }: IBaseProps) => {
  const [FooterComponentInterface, __setFooterComponent] = useState<JSX.Element | null>(null);

  const isMobile = useIsMobileBreakpoint();

  const FooterComponent = FooterComponentInterface;

  useEffectOnUrlChange(() => {
    __setFooterComponent(null);
  });

  useEffect(() => {
    if (!isMobile) {
      __setFooterComponent(null);
    }
  }, [isMobile]);

  const value = useMemoAll({
    FooterComponent,
    __setFooterComponent,
  });

  return <MobileFooterContext.Provider value={value}>{children}</MobileFooterContext.Provider>;
};

export const useMobileFooterComponent = () => useContext(MobileFooterContext).FooterComponent;
