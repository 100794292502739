// eslint-disable-next-line no-restricted-imports
import { keyBy } from 'lodash-es';
import type { AccessibilityActionEvent } from 'react-native';

type HandledAccessibilityAction<NameType extends string> = {
  handler: (event: AccessibilityActionEvent) => void;
  label: string;
  name: NameType;
};

type AccessibilityActionsHandler = (event: AccessibilityActionEvent) => void;

export function useHandledAccessibilityActions<ActionNameType extends string>(
  actions: Array<HandledAccessibilityAction<ActionNameType>>
): readonly [
  Array<{
    label: string;
    name: ActionNameType;
  }>,
  AccessibilityActionsHandler
] {
  const accessibilityActions = actions.map(action => ({
    name: action.name,
    label: action.label,
  }));

  const handledActionsByName = keyBy(actions, 'name');
  const handleAccessibilityAction = (event: AccessibilityActionEvent) => {
    handledActionsByName[event.nativeEvent.actionName]?.handler(event);
  };

  return [accessibilityActions, handleAccessibilityAction] as const;
}
