import { Box } from '@rbilabs/universal-components';

export const FabContainer = Box.withConfig({
  position: 'absolute',
  right: '-2.5px',
  bottom: 0,
  paddingRight: '$5',
  paddingBottom: '$7',
  zIndex: Styles.zIndex.top,
});

export const Badge = Box.withConfig({
  backgroundColor: Styles.color.contrastBackground,
  borderRadius: Styles.borderRadius,
  height: '$5',
  width: '$5',
  position: 'absolute',
  right: '$4',
  top: '-2px',
  _text: {
    color: Styles.color.white,
    fontFamily: 'heading',
    fontSize: 'xs',
    textAlign: 'center',
  },
  _android: {
    style: {
      elevation: Styles.zIndex.top,
    },
  },
  zIndex: Styles.zIndex.top,
});
