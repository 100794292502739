import { Box, View } from '@rbilabs/universal-components';

export const StyledLogo = View.withConfig({
  marginX: 'auto',
  padding: '$1.5',
});

export const StyledBox = Box.withConfig({
  padding: '$4',
});
