import { Svg, SvgPath, SvgProps, SvgRect, registerSVG } from '../../../components/svg';

export function CashAppNoBorder(props: SvgProps) {
  return (
    <Svg {...props} width="275" height="194" viewBox="0 0 275 194" fill="none">
      <SvgRect width="275" height="194" rx="33" fill="#00D64F" />
      <SvgPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M141.554 65.5117C150.752 65.5117 159.568 69.2363 165.329 74.349C166.785 75.6424 168.966 75.6345 170.334 74.2489L177.187 67.2976C178.634 65.8304 178.581 63.4281 177.019 62.0874C172.42 58.1336 166.245 54.2457 158.646 51.7565L160.796 41.5204C161.262 39.2894 159.563 37.1926 157.282 37.1926H144.038C142.344 37.1926 140.879 38.378 140.526 40.0348L138.59 49.1382C120.984 50.018 106.062 58.8027 106.062 76.8277C106.062 92.432 118.408 99.12 131.439 103.748C143.785 108.376 150.299 110.094 150.299 116.608C150.299 123.296 143.785 127.239 134.181 127.239C125.425 127.239 116.253 124.349 109.136 117.332C107.74 115.954 105.506 115.944 104.11 117.321L96.7452 124.586C95.3044 126.006 95.3175 128.34 96.7689 129.746C102.511 135.32 109.781 139.356 118.079 141.616L116.058 151.098C115.587 153.322 117.27 155.418 119.546 155.437L132.811 155.537C134.518 155.55 136.001 154.357 136.349 152.684L138.264 143.568C159.344 142.266 172.246 130.808 172.246 114.037C172.246 98.6063 159.389 92.0896 143.785 86.774C134.868 83.513 127.15 81.2872 127.15 74.6019C127.15 68.0878 134.355 65.5117 141.554 65.5117"
        fill="white"
      />
    </Svg>
  );
}

registerSVG('CashAppNoBorder', CashAppNoBorder);
