import React, { createContext, useCallback, useContext, useEffect, useRef } from 'react';

import { useApolloClient } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { merge } from 'lodash-es';

import { IBaseProps } from '@rbi-ctg/frontend';
import { MenuObject, SanityMenuObject } from '@rbi-ctg/menu';
import { ItemAvailabilityStatus, MenuObjectTypes } from 'enums/menu';
import {
  GetComboAvailabilityDocument,
  GetComboDocument,
  GetItemAvailabilityDocument,
  GetItemDocument,
  GetPickerAvailabilityDocument,
  GetPickerDocument,
  GetSectionAvailabilityDocument,
  GetSectionDocument,
} from 'generated/sanity-graphql';
import usePosVendor from 'hooks/menu/use-pos-vendor';
import { useForceUpdate } from 'hooks/use-force-update';
import { fixTypes } from 'remote/api/menu-objects';
import { MenuDataError, NotFoundMenuItemError } from 'remote/exceptions';
import { useLocale } from 'state/intl';
import { useMainMenuContext } from 'state/menu/main-menu';
import { useStoreContext } from 'state/store';
import { IMenuObjectWithDaypart, isAvailableForActiveDayParts } from 'utils/availability';
import { useSanityGqlEndpoint } from 'utils/network';
import { useMemoAll } from 'utils/use-memo-all';

import { applyFiltersForAvailability } from './apply-filters-for-availability';
import {
  ICustomFilter,
  IGetMenuObjectQueryResult,
  IMenuOptionsContext,
  ItemAvailability,
} from './types';

export const MenuOptionsContext = createContext<IMenuOptionsContext>({} as IMenuOptionsContext);
export const useMenuOptionsContext = () => useContext(MenuOptionsContext);

export const MenuOptionsProvider = ({ children }: IBaseProps) => {
  const { activeDayParts, dayParts } = useMainMenuContext();
  const { prices, store } = useStoreContext();
  const { vendor: posVendor } = usePosVendor();
  const menu = useRef<Record<string, IGetMenuObjectQueryResult>>({});
  const { locale } = useLocale();
  const client = useApolloClient();
  const uri = useSanityGqlEndpoint();

  // a small hack until our menu data store is more reactive
  // forces a re-render upon successful menu data load
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const forceUpdate = useCallback(useForceUpdate(), [useForceUpdate]);

  const filterForAvailability = React.useMemo(
    () =>
      applyFiltersForAvailability({
        activeDayParts,
        dayParts,
        prices,
        vendor: posVendor,
        storeHasBurgersForBreakfast: Boolean(store.hasBurgersForBreakfast),
      }),
    [activeDayParts, dayParts, prices, posVendor, store]
  );

  const filterForAvailabilityMemo = useCallback(filterForAvailability, [filterForAvailability]);

  const setMenuData = useCallback(
    (
      menuData: any,
      menuDataLoading: any,
      itemID: any,
      skipForceUpdate = false,
      customFilter?: ICustomFilter
    ) => {
      const fixedData = menuData && fixTypes(menuData);
      const filter = customFilter || filterForAvailabilityMemo;
      const filteredData = fixedData && filter(fixedData);

      let sectionOptions = {};
      // if its a section we can pull out the pickers, combos and items to preload the data into their own keys (This saves wizard from having to make the same queries again)
      // NOTE: Checking if filteredData is an array here as a typecheck... We should never hit that case here. I am assuming its because the filter function is used somewhere else and could return an array of data
      if (!Array.isArray(filteredData) && filteredData?._type === MenuObjectTypes.SECTION) {
        // typecasting as the filterForStaticMenu returns sanity types while applyFiltersForAvailability returns manual menu type
        sectionOptions = (filteredData.options as MenuObject[]).reduce(
          (mappedOptions: any, option) => {
            return {
              ...mappedOptions,
              [option._id]: {
                loading: menuDataLoading,
                data: option,
                id: option._id,
              },
            };
          },
          {}
        );
      }

      menu.current = {
        ...menu.current,
        // preload all the pickers, combos, and items inside a section so we don't need to query again for them
        ...sectionOptions,
        [itemID]: {
          loading: menuDataLoading,
          data: filteredData,
          id: itemID,
        },
      };
      if (!skipForceUpdate) {
        forceUpdate();
      }
    },
    [filterForAvailabilityMemo, forceUpdate]
  );

  const getItemIdAndType = useCallback((id: string) => {
    const [type, ...idSplit] = id.split('-');
    const itemID = idSplit.join('-');
    return { type, itemID };
  }, []);

  const maybeGetCurrentItem = useCallback(
    (itemID: string, customFilter?: ICustomFilter) => {
      const { current: currentMenu } = menu;

      const filter = customFilter || filterForAvailabilityMemo;

      if (currentMenu[itemID]) {
        currentMenu[itemID] = {
          ...currentMenu[itemID],
          data:
            currentMenu[itemID].data && filter(currentMenu[itemID].data as SanityMenuObject)
              ? currentMenu[itemID]?.data
              : null,
        };

        return currentMenu[itemID];
      }
      return undefined;
    },
    [filterForAvailabilityMemo]
  );

  /**
   * @deprecated Use `useGetMenuObjectLazyQuery` instead
   * Like getMenuObject but async instead of reactive
   */
  const asyncGetMenuObject = useCallback(
    async (id: string, customFilter?: ICustomFilter) => {
      const { type, itemID } = getItemIdAndType(id);
      const currentMenuItem = maybeGetCurrentItem(itemID, customFilter);
      if (currentMenuItem) {
        return currentMenuItem;
      }
      const queryMenuData = async (
        menuItemId: string,
        dataQuery: DocumentNode,
        availabilityQuery: DocumentNode
      ) => {
        await Promise.all([
          client.query({
            fetchPolicy: 'no-cache',
            context: { uri },
            query: dataQuery,
            variables: {
              id: menuItemId,
            },
          }),
          client.query({
            fetchPolicy: 'no-cache',
            context: { uri },
            query: availabilityQuery,
            variables: {
              id: menuItemId,
            },
          }),
        ])
          .then(response => {
            const { data: rawData, loading } = response.reduce(
              // @ts-expect-error TS(2769) FIXME: No overload matches this call.
              (finalResponse, res) => {
                const capitalizedType = type[0].toUpperCase() + type.slice(1);
                const data = res?.data;

                return {
                  data: data?.[capitalizedType]
                    ? [...finalResponse.data, data[capitalizedType]]
                    : finalResponse.data,
                  loading: res.loading || finalResponse.loading,
                };
              },
              { data: [], loading: false }
            );
            const [uiData, availability] = rawData;

            const mergedData = merge(uiData, availability);
            setMenuData(mergedData, loading, menuItemId, true, customFilter);
          })
          .catch(err => {
            throw new MenuDataError(`An error occurred loading menu ${type}`, err);
          });
      };
      switch (type) {
        case MenuObjectTypes.ITEM:
          await queryMenuData(itemID, GetItemDocument, GetItemAvailabilityDocument);
          break;
        case MenuObjectTypes.COMBO:
          await queryMenuData(itemID, GetComboDocument, GetComboAvailabilityDocument);
          break;
        case MenuObjectTypes.PICKER:
          await queryMenuData(itemID, GetPickerDocument, GetPickerAvailabilityDocument);
          break;
        case MenuObjectTypes.SECTION:
          await queryMenuData(itemID, GetSectionDocument, GetSectionAvailabilityDocument);
          break;
        default:
          throw new NotFoundMenuItemError(type);
      }

      return menu.current[itemID];
    },
    [client, getItemIdAndType, maybeGetCurrentItem, setMenuData, uri]
  );

  const { vendor } = usePosVendor();

  const checkItemAvailability: IMenuOptionsContext['checkItemAvailability'] = useCallback(
    async (itemId: string): Promise<ItemAvailability> => {
      try {
        if (!prices) {
          return { availabilityStatus: ItemAvailabilityStatus.UNAVAILABLE };
        }

        const availabilityFilter = applyFiltersForAvailability({
          activeDayParts,
          dayParts,
          prices,
          vendor,
          storeHasBurgersForBreakfast: Boolean(store.hasBurgersForBreakfast),
        });
        const { data } = await asyncGetMenuObject(itemId, availabilityFilter);

        if (
          dayParts.length &&
          activeDayParts.length &&
          !isAvailableForActiveDayParts({
            activeDayParts,
            menuData: data as IMenuObjectWithDaypart,
          })
        ) {
          return {
            availabilityStatus: ItemAvailabilityStatus.OUT_OF_DAYPART,
            data: data ?? undefined,
          };
        }

        // If selected item is picker, make sure there are at least some options available.
        if (data) {
          const notPicker = data._type !== MenuObjectTypes.PICKER;
          if (notPicker || Boolean(data.options?.length)) {
            return {
              availabilityStatus: ItemAvailabilityStatus.AVAILABLE,
              data: data ?? undefined,
            };
          }
        }

        return {
          availabilityStatus: ItemAvailabilityStatus.OUT_OF_MENU,
          data: data ?? undefined,
        };
      } catch (err) {
        throw new NotFoundMenuItemError(itemId);
      }
    },
    [activeDayParts, asyncGetMenuObject, dayParts, prices, store, vendor]
  );

  // There are a few specs that cause us to force remove all items.
  //
  // 1. When the store changes, we have to remove all loaded items.
  //    This is because a store might have different menu data.
  // 2. When then language changes, we wipe the data so that the user
  //    refetches the data and then gets it in the new language
  // 3. When the prices are refetched, we clear all loaded items since
  //    the filtered data will be completely different
  useEffect(() => {
    menu.current = {};
  }, [locale, store, prices]);

  const value = useMemoAll({
    filterForAvailability,
    asyncGetMenuObject,
    checkItemAvailability,
  });

  return <MenuOptionsContext.Provider value={value}>{children}</MenuOptionsContext.Provider>;
};
