import { GLOBAL_UUID_REGEX } from 'utils/constants';

import { getMessage } from './shared';
import { ILoggerMiddleware } from './types';

const scrubUuids = (message: string) => message.replace(GLOBAL_UUID_REGEX, '%uuid%');

const uuidScrubberMiddleware = (attributes => {
  const { message, originalDeveloperMessage } = getMessage(attributes);

  const scrubbed = scrubUuids(message);

  // Set `message: (error.message || message)` and scrub uuid's
  // Save original `message` property as `originalDeveloperMessage`
  return {
    ...attributes,
    message: scrubbed,
    originalDeveloperMessage,
    originalMessage: message,
  };
}) as ILoggerMiddleware;

export default uuidScrubberMiddleware;
