import * as React from 'react';

import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';

const GoogleIcon = () => (
  <Svg width={17} height={18} viewBox="0 0 17 18" fill="none">
    <G clipPath="url(#clip0_1777_966)">
      <Path
        d="M16.4914 9.15349C16.4914 8.48281 16.4369 7.99338 16.3191 7.48584H8.65918V10.513H13.1554C13.0648 11.2653 12.5753 12.3982 11.4875 13.1595L11.4722 13.2608L13.8941 15.1367L14.0619 15.1535C15.603 13.7305 16.4914 11.6369 16.4914 9.15349Z"
        fill="#4285F4"
      />
      <Path
        d="M8.6594 17.1292C10.8622 17.1292 12.7114 16.4041 14.0622 15.1534L11.4877 13.1594C10.7987 13.6398 9.87409 13.9751 8.6594 13.9751C6.50194 13.9751 4.67082 12.5522 4.01807 10.5854L3.92239 10.5936L1.40403 12.5422L1.37109 12.6337C2.71271 15.2984 5.46848 17.1292 8.6594 17.1292Z"
        fill="#34A853"
      />
      <Path
        d="M4.01818 10.5851C3.84594 10.0776 3.74627 9.53374 3.74627 8.97184C3.74627 8.40987 3.84594 7.86609 4.00911 7.35854L4.00455 7.25045L1.45463 5.27051L1.3712 5.31018C0.818256 6.41593 0.500977 7.65765 0.500977 8.97184C0.500977 10.286 0.818256 11.5277 1.3712 12.6334L4.01818 10.5851Z"
        fill="#FBBC05"
      />
      <Path
        d="M8.6594 3.96899C10.1914 3.96899 11.2248 4.63061 11.814 5.18352L14.1165 2.93578C12.7024 1.62159 10.8622 0.814941 8.6594 0.814941C5.46848 0.814941 2.71271 2.64574 1.37109 5.31036L4.00901 7.35872C4.67082 5.39196 6.50194 3.96899 8.6594 3.96899Z"
        fill="#EB4335"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_1777_966">
        <Rect width={16} height={16.3704} fill="white" transform="translate(0.5 0.814941)" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default GoogleIcon;
