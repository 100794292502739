import { isNil } from 'lodash-es';

import { IPrices, IQuantityBasedPlu } from '@rbi-ctg/menu';
import { isCombo } from 'utils/menu';
import {
  IWithVendorConfig,
  PluTypes,
  PosVendors,
  bestPlusForQuantity,
  concatenateSizePlu,
  getConstantPlu,
  getVendorConfig,
} from 'utils/vendor-config';

import { isCartEntryWithTypeCombo } from './is-cart-entry-combo';

/**
 * Returns the price of a menu object without any selections
 */
export const pricingFunctionNoSelections = (
  item: IWithVendorConfig,
  prices: IPrices,
  vendor: PosVendors | null,
  quantity?: number
): number => {
  const vendorConfig = getVendorConfig(item, vendor);
  if (!vendorConfig || vendorConfig.pluType === PluTypes.IGNORE) {
    return 0;
  }

  // for quantity based plus: if price exists for plu, use it -
  // otherwise compute best price for quantity selected from
  // available plus
  // Note: if quantity is undefined we should allow
  // `priceForQuantityPlu` to find the minimum quantity
  // plu to use for pricing, so we don't default to 1
  // until after this check
  if (vendorConfig.pluType === PluTypes.QUANTITY) {
    return priceForQuantityPlu(item, quantity, prices, vendor);
  }

  const itemQuantity = quantity || 1;

  /**
   * If item is a combo, and there is a main item with a constant plu,
   * we want to check if there exists a price `<comboPlu>-<mainItemPlu>`,
   * and if it does, use that price instead of `<comboPlu>`
   */

  const mainItemComboPlu =
    isCombo(item) && item.mainItem ? getConstantPlu(getVendorConfig(item.mainItem, vendor)) : null;

  /**
   * This is due to repricing of cart entries.
   * The correct way would be to reprice everything based on requests from sanity,
   * and not count exclusively with the data from local storage.
   * This should be temporary fix.
   */
  const mainItemCartEntry =
    isCartEntryWithTypeCombo(item) &&
    item.cartId &&
    item.children.find(entry => entry.type === 'Item');

  const mainItemCartEntryPlu = mainItemCartEntry
    ? getConstantPlu(getVendorConfig(mainItemCartEntry, vendor))
    : null;

  const mainItemPlu = mainItemComboPlu || mainItemCartEntryPlu;

  if (vendorConfig.pluType === PluTypes.MULTI_CONSTANT) {
    return (
      (vendorConfig.multiConstantPlus || []).reduce(
        (acc, { plu }) => acc + getPriceForPlus(plu, mainItemPlu, prices),
        0
      ) * itemQuantity
    );
  }

  let price = 0;
  if (vendorConfig.pluType === PluTypes.CONSTANT) {
    price = getPriceForPlus(vendorConfig.constantPlu, mainItemPlu, prices);
  }

  if (vendorConfig.pluType === PluTypes.SIZE_BASED) {
    const plu = concatenateSizePlu(vendorConfig.sizeBasedPlu);
    price = plu ? getPriceForPlus(plu, mainItemPlu, prices) : 0;
  }

  if (vendorConfig.pluType === PluTypes.PARENT_CHILD && !!vendorConfig.parentChildPlu) {
    const { plu: parentPlu, childPlu } = vendorConfig.parentChildPlu;
    price =
      getPriceForPlus(parentPlu, mainItemPlu, prices) +
      getPriceForPlus(parentPlu, childPlu, prices);
  }

  return price * itemQuantity;
};

/**
 * hack - this is an attempt to reconcile bad data in
 * the ui layer - there is a chance that injection will
 * fail if the price for a plu is not found, but this
 * is a temporary fix to display a derived price.
 * if minPlu.quantity is not 1 this may be a bad price.
 * it will only be used for display - orders must be validated
 * and priced before injection.
 **/
const priceForQuantityPlu = (
  item: IWithVendorConfig,
  quantity: number = 1,
  prices: IPrices,
  vendor: PosVendors | null
) => {
  const vendorConfig = getVendorConfig(item as IWithVendorConfig, vendor) as IQuantityBasedPlu;
  if (!vendorConfig || !prices) {
    return 0;
  }
  const plus = bestPlusForQuantity(vendorConfig.quantityBasedPlu, prices, quantity);
  if (!plus.length) {
    return 0;
  }
  return plus.reduce((acc, { plu }) => acc + prices[plu], 0);
};

const getPriceForPlus = (
  plu: string,
  mainItemPlu: string | null = null,
  prices: IPrices
): number => {
  const combinedPlu = `${plu}-${mainItemPlu}`;

  if (mainItemPlu && prices && !isNil(prices[combinedPlu])) {
    return prices[combinedPlu];
  }

  return prices?.[plu] || 0;
};
