import { IntlFormatters } from 'react-intl';

import { LoyaltyUser } from '../types';

import { getNextQuarterDate, getTomorrowDate } from './helpers';

enum WalmartEngineOfferIds {
  QUARTERLY_FREE_WHOPPER = '4e789a40-3de8-4cc5-b06e-8b0bc77e9579',
  DAILY_TWENTY_FIVE_PERCENT_OFF = '1659a35d-5933-410c-9718-736c3b78c122',
}

enum WalmartSanityOfferIds {
  QUARTERLY_FREE_WHOPPER = '8f059309-e9f2-4c3f-92a6-9dea0a5fce06',
  DAILY_TWENTY_FIVE_PERCENT_OFF = 'a48ac94b-298b-49ae-a1fe-ef576b644712',
}

export const walmartOfferEngineIds: string[] = [
  WalmartEngineOfferIds.QUARTERLY_FREE_WHOPPER,
  WalmartEngineOfferIds.DAILY_TWENTY_FIVE_PERCENT_OFF,
];

export const walmartOfferSanityIds: string[] = [
  WalmartSanityOfferIds.QUARTERLY_FREE_WHOPPER,
  WalmartSanityOfferIds.DAILY_TWENTY_FIVE_PERCENT_OFF,
];

export const isWalmartOffer = (offer: Record<string, any>): boolean => {
  return [...walmartOfferEngineIds, ...walmartOfferSanityIds].includes(
    offer.id || offer._id || offer.loyaltyEngineId
  );
};

export const isWalmartOfferEngineId = (loyaltyEngineId: WalmartEngineOfferIds): boolean => {
  return walmartOfferEngineIds.includes(loyaltyEngineId);
};

export const isActiveWalmartUser = (loyaltyUser: LoyaltyUser): boolean => {
  return (
    loyaltyUser?.partnerUserData?.some(
      data => data.partner === 'WALMART' && data.status === 'ACTIVE'
    ) || false
  );
};

/**
 * Push Walmart Offers to the top of the list
 */
export const pushWalmartOffersToTop = (a: Record<string, any>, b: Record<string, any>) => {
  if (isWalmartOffer(a) && !isWalmartOffer(b)) {
    return -1;
  } else if (!isWalmartOffer(a) && isWalmartOffer(b)) {
    return 1;
  }
  return 0;
};

export const getWalmartOfferUnavailableMessage = (
  sanityOfferId: string,
  formatters: IntlFormatters
): string => {
  const date =
    sanityOfferId === WalmartSanityOfferIds.QUARTERLY_FREE_WHOPPER
      ? getNextQuarterDate()
      : getTomorrowDate();
  const message = formatters.formatMessage(
    { id: 'availableAgainOn' },
    {
      date: formatters.formatDate(date),
    }
  );
  return message;
};

export const PARTNER_USER_ALREADY_LINKED = 'PARTNER_USER_ALREADY_LINKED';
export const LOYALTY_ID_ALREADY_LINKED = 'LOYALTY_ID_ALREADY_LINKED';

export const WALMART_PLUS_URL =
  'https://www.walmart.com/plus/program?benefitName=dining-benefit-burgerking&programId=burgerking';
