import React, { useEffect, useState } from 'react';

import { Icon } from '@rbilabs/universal-components';
import { differenceInMinutes } from 'date-fns';
import { useIntl } from 'react-intl';

import { useRoutesDisplayBottomServiceNotification } from 'components/top-service-mode-notification/hooks/use-routes-display-bottom-service-notification';
import { DeliveryStatus, RbiOrderStatus, useGetUserOrdersQuery } from 'generated/graphql-gateway';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { OrderStatus, ServiceMode, useOrderContext } from 'state/order';
import { useServiceModeContext } from 'state/service-mode';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';
import { deliveryCompleteTimeout } from 'utils/delivery-complete-timeout';
import { STATUS_MESSAGES, getDeliveryHeading } from 'utils/get-delivery-heading';
import { routes } from 'utils/routing';

import { Close } from '../styled';
import {
  IOrderInProgress,
  IOrderInProgressRecentOrder,
  IOrderInProgressResult,
  IServiceNotification,
} from '../types';

const DELIVERY_COMPLETED = [
  DeliveryStatus.ORDER_DROPPED_OFF,
  DeliveryStatus.ORDER_ABANDONED,
  DeliveryStatus.ORDER_CANCELLED,
];

const ORDER_PROGRESS_POLLING_DURATION_IN_MS = 10000;

const isPollingForDelivery = ({
  hasDelivery,
  isDeliveryOrderCompleted,
  deliveryStatus,
}: {
  hasDelivery: boolean;
  isDeliveryOrderCompleted: boolean;
  deliveryStatus: DeliveryStatus | undefined;
}) =>
  hasDelivery &&
  !isDeliveryOrderCompleted &&
  (!deliveryStatus || !DELIVERY_COMPLETED.includes(deliveryStatus));

export const getInfoForServiceMode = (serviceMode: ServiceMode): IOrderInProgressResult | null => {
  let info: IOrderInProgressResult | null;
  switch (serviceMode) {
    case ServiceMode.DRIVE_THRU:
      info = {
        icon: 'driveThru',
        headingId: 'driveThruOrderPlaced',
        detailsId: 'tapToSeeDetails',
      };
      break;
    case ServiceMode.TAKEOUT:
      info = { icon: 'store', headingId: 'pickUpOrderPlaced', detailsId: 'tapToSeeDetails' };
      break;
    case ServiceMode.EAT_IN:
      info = {
        icon: 'restaurant',
        headingId: 'dineInOrderPlaced',
        detailsId: 'tapToSeeDetails',
      };
      break;
    default:
      info = null;
  }
  return info;
};

const orderInProgress = ({
  recentOrder,
  isDeliveryOrderCompleted,
  isPreConfirmCurbside,
  isCurbside,
  isMobileOrderForDriveThruLaneEnabled,
  isOrderBayEnabled,
}: IOrderInProgress): IOrderInProgressResult | null => {
  // CURBSIDE - Before order is commited on confirming arrival , selecting "I'm here!"
  if (isPreConfirmCurbside) {
    return {
      headingId: 'curbsideOrderSaved',
      detailsId: 'tapWhenYourAreHere',
      icon: (
        <Icon variant="driveThru" color="white" size="6" {...hiddenAccessibilityPlatformProps} />
      ),
    };
  }
  // CURBSIDE - After order is placed
  if (isCurbside) {
    return {
      // TODO: BKPE-6812 ORDER_BAY_PILOT. Remove or consolidate this logic once the pilot is done
      headingId: isOrderBayEnabled ? 'orderBayOrderPlaced' : 'curbsideOrderPlaced',
      detailsId: 'curbsideOrderReadyInstructions',
      icon: (
        <Icon variant="driveThru" color="white" size="6" {...hiddenAccessibilityPlatformProps} />
      ),
    };
  }
  // PICKUP
  const info = getInfoForServiceMode(recentOrder?.cart.serviceMode as ServiceMode);
  if (recentOrder && info) {
    // DRIVE_THRU when Mobile Pickup is Enabled
    if (
      recentOrder?.cart.serviceMode === ServiceMode.DRIVE_THRU &&
      isMobileOrderForDriveThruLaneEnabled
    ) {
      info.headingId = 'driveThruMobileOrderPlaced';
    }
    return {
      headingId: info?.headingId,
      detailsId: info.detailsId,
      icon: (
        <Icon
          variant={info.icon as any}
          color="white"
          size="6"
          {...hiddenAccessibilityPlatformProps}
        />
      ),
    };
  }
  // DELIVERY
  if (recentOrder?.delivery && !isDeliveryOrderCompleted) {
    const deliveryStatus = recentOrder.delivery.status;
    const headingId = getDeliveryHeading({ deliveryStatus });
    const detailsId = STATUS_MESSAGES[deliveryStatus];
    return {
      headingId,
      detailsId,
      icon: (
        <Icon variant="delivery" color="white" size="6" {...hiddenAccessibilityPlatformProps} />
      ),
    };
  }

  return null;
};

export const useServiceModeNotification = (): IServiceNotification | null => {
  const { formatMessage } = useIntl();
  const isMobileOrderForDriveThruLaneEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_MOBILE_ORDER_DRIVE_THRU_LANE
  );
  const { isAuthenticated } = useAuthContext();
  const { curbsidePickupOrderId, isPreConfirmCurbside, serverOrder } = useOrderContext();
  const { isCurbside, isOrderBayEnabled } = useServiceModeContext();
  const { linkTo } = useNavigation();
  const shouldShowServiceNotification = useRoutesDisplayBottomServiceNotification();
  const notificationExpiredInMinutes = useFlag(LaunchDarklyFlag.TOP_ORDER_STATUS_BAR_TIME_OUT);

  const [hasSeenCancelModal, setHasSeenCancelModal] = useState(false);
  const [shouldDisplayNotification, setShouldDisplayNotification] = useState<boolean>(true);
  const [currentOrderId, setCurrentOrderId] = useState<string>('');
  const [currentDeliveryStatus, setCurrentDeliveryStatus] = useState<DeliveryStatus>();

  // start polling for recent user orders
  const { data, startPolling, stopPolling, refetch } = useGetUserOrdersQuery({
    variables: {
      limit: 1,
      orderStatuses: [
        RbiOrderStatus.INSERT_SUCCESSFUL,
        RbiOrderStatus.UPDATE_SUCCESSFUL,
        RbiOrderStatus.REFUND_SUCCESSFUL,
      ],
    },
    skip: !isAuthenticated,
  });

  // Upon order confirmation, data from `useGetUseOrdersQuery` is not updated with newly placed order
  // Force a refetch to update query with new confirm order instead of previous order
  const isServerOrderSuccessful: boolean = serverOrder?.status === OrderStatus.INSERT_SUCCESSFUL;
  const isCurbsideOrderCompleted = isCurbside && isServerOrderSuccessful;
  useEffect(() => {
    if (isCurbsideOrderCompleted) {
      refetch();
    }
  }, [isCurbsideOrderCompleted, refetch]);

  useEffect(() => {
    // Update notification for new orders being placed
    const order = data?.userOrders?.orders?.[0];
    if (order && order.rbiOrderId !== currentOrderId) {
      setShouldDisplayNotification(true);
      setCurrentOrderId(order.rbiOrderId);
    }
  }, [currentOrderId, data, isCurbside]);

  useEffect(() => {
    // Update notification for delivery status changes
    const order = data?.userOrders?.orders?.[0];
    if (order?.delivery?.status && order?.delivery.status !== currentDeliveryStatus) {
      setShouldDisplayNotification(true);
      setCurrentDeliveryStatus(order?.delivery?.status);
    }
  }, [currentDeliveryStatus, data]);

  useEffect(() => {
    // reactivate shouldDisplayNotification after closing first Curbside notification
    if (isServerOrderSuccessful) {
      setShouldDisplayNotification(true);
    }
  }, [isServerOrderSuccessful]);

  const recentOrder: IOrderInProgressRecentOrder =
    isCurbsideOrderCompleted && serverOrder
      ? { ...serverOrder, updatedAt: serverOrder?.createdAt || '' }
      : data?.userOrders?.orders?.[0];

  if (!shouldShowServiceNotification) {
    return null;
  }

  const notificationIsExpired =
    recentOrder?.updatedAt &&
    Math.abs(differenceInMinutes(new Date(), new Date(recentOrder.updatedAt))) >
      notificationExpiredInMinutes;

  if (
    isAuthenticated &&
    recentOrder?.status !== RbiOrderStatus.REFUND_SUCCESSFUL &&
    !notificationIsExpired
  ) {
    const isDeliveryOrderCompleted =
      !!recentOrder?.delivery &&
      DELIVERY_COMPLETED.includes(recentOrder.delivery.status) &&
      (hasSeenCancelModal ||
        deliveryCompleteTimeout(recentOrder?.updatedAt, notificationExpiredInMinutes));

    if (
      isPollingForDelivery({
        hasDelivery: !!recentOrder?.delivery,
        isDeliveryOrderCompleted,
        deliveryStatus: recentOrder?.delivery?.status,
      })
    ) {
      if (hasSeenCancelModal) {
        setHasSeenCancelModal(false);
      }
      startPolling(ORDER_PROGRESS_POLLING_DURATION_IN_MS);
    }

    const inProgress = orderInProgress({
      recentOrder,
      isPreConfirmCurbside: isPreConfirmCurbside(),
      isDeliveryOrderCompleted,
      isCurbside,
      isMobileOrderForDriveThruLaneEnabled,
      isOrderBayEnabled,
    });

    if (inProgress && shouldDisplayNotification) {
      const { headingId, detailsId, icon } = inProgress;
      const orderId = isPreConfirmCurbside() ? curbsidePickupOrderId : currentOrderId;
      return {
        heading: formatMessage({ id: headingId }),
        details: formatMessage({ id: detailsId }),
        icon,
        cta: (
          <Close onPress={() => linkTo(`${routes.orderConfirmation}/${orderId}`)}>
            <Icon color="white" variant="forward" size="6" {...hiddenAccessibilityPlatformProps} />
          </Close>
        ),
        orderId,
      };
    }
  }

  // Stop polling for recent user orders
  stopPolling();

  return null;
};
