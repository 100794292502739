import { ActionButtonVariants } from 'components/action-button';

export default {
  itemNameFontWeight: undefined,
  itemImageBackgroundColor: Styles.color.grey.five,
  itemBorderTopWidth: '1px',
  itemBorderTopColor: Styles.color.grey.five,
  itemPaddingY: '$2.5',
  itemPaddingX: '$2',
  emptyCartBtnVariant: ActionButtonVariants.OUTLINE,
  buttonContainerMarginY: '$6',
  buttonContainerMarginX: '$2',
  headerFontSize: '3xl',
  headerColor: '__legacyToken.text-default',
  headerFontWeight: undefined,
  headerBorderBottomWidth: 0,
};
