import {
  CartPaymentCardType,
  IApplePay,
  IBillingAddressInput,
  IGooglePay,
} from 'generated/graphql-gateway';
import { IFraudPreventionValues, IPaymentState } from 'utils/payment';

export type CardType = keyof typeof CartPaymentCardType;

export { CartPaymentCardType as CardTypes };

/**
 * These card types are the ones supported by our Apple Pay plugin (ios/App/App/ApplePayPlugin.swift)
 */
export enum ApplePayCardTypes {
  AMEX = 'AmEx',
  DISCOVER = 'Discover',
  MASTERCARD = 'MasterCard',
  VISA = 'Visa',
}

export enum PaymentType {
  CASH = 'CASH',
  DIGITAL = 'DIGITAL',
  GIFT = 'GIFT',
}

export interface ICredit {
  alias: string;
  /**
   * The BE does not currently standardize card type, for example upper case vs lower case.
   * Eventually we want to align the FE and BE.
   *
   * TODO: Use type from `IUserAccountsQuery` and not force our own.
   */
  cardType: CartPaymentCardType | string;
  expiryMonth: string;
  expiryYear: string;
  panToken: string;
}

export interface IPrepaid {
  alias: string;
  cardNumber: string;
  currentBalance: number;
  expiryMonth: string;
  expiryYear: string;
  feFormattedCurrentBalance: number | null;
  shuffledCardNumber: string;
}

export interface IPaymentMethod {
  accountIdentifier: string | null;
  fdAccountId: string | null;
  orbitalIdentifier: string | null;
  credit: ICredit | null;
  selected?: boolean;
  prepaid: IPrepaid | null;
  // make cash required eventually
  cash?: boolean | null;
  cashapp: boolean | null;
  paypal: boolean | null;
  venmo: boolean | null;
}

export interface IAddAccount {
  addAccount: IPaymentMethod;
}

export interface IAddress {
  locality: string;
  postalCode: string;
  region: string;
  streetAddress: string;
  unitNumber?: string;
}

export interface IAddPaymentMethodOptions {
  skipErrorDialogOnError?: boolean;
}

export interface IDeleteAccount {
  deleteAccount: boolean;
}

export interface IGetAccounts {
  userAccounts?: {
    count?: number;
    accounts?: IPaymentMethod[];
  };
}

// @todo validate type
export interface IGetNonce {
  token: {
    tokenId: string;
  };
}

export interface IApplePayDetails extends IApplePay {
  signature: string;
  applicationData: string;
  data: string;
  decryptAlias: string;
  ephemeralPublicKey: string;
  formatted: string;
  paymentData: string;
  primary: boolean;
  publicKeyHash: string;
  transactionId: string;
  type: string;
  version: string;
  billingAddress: IBillingAddressInput;
  PaymentMethodData?: IPaymentMethodData;
}

export interface IGooglePayDetails extends IGooglePay {
  signature: string;
  data: string;
  formatted: string;
  primary: boolean;
  type: string;
  version: string;
  billingAddress: IBillingAddressInput;
  PaymentMethodData?: IPaymentMethodData;
  paymentData: string;
}

export interface IBraintreeDetails {
  /**
   * Stringified object representing additional details related to the payment and user.
   * It is used for Forter fraud prevention and is inconsistent between platforms and users.
   * The BE will parse this string and handle accordingly based on the data available.
   */
  details: string;
  /**
   * Unique one-time identifier representing the device used to make the payment as a result
   * of the successful PayPal/Venmo payment method selection. This value is used by Braintree
   * for fraud prevention.
   */
  deviceData: string;
  /**
   * Unique one-time token representing the payment method as a result of a successful PayPal/Venmo
   * payment method selection. This value is used  to finalize the payment in the  BE and is then discarded.
   */
  nonce: string;
}

export interface IPayPalDetails extends IBraintreeDetails {}

export interface IVenmoDetails extends IBraintreeDetails {}

export interface ICashAppDetails {
  virtualCard: {
    cardType: string;
    cardNumber: string;
    cvc: string;
    expiry: string;
    expiryMonth: string;
    expiryYear: string;
  };
  status: string;
  token: string;
  cashtag: string;
  fdAccessToken: string;
  fdNonce: string;
}

interface IPaymentMethodData {
  paymentType: string;
  displayName: string;
}

export interface IReloadPrepaidCard {
  countryCode: string;
  fundValue: number;
  prepaidBalance: number;
  fdAccountId?: string | null;
  prepaidFdAccountId?: string | null;
  currencyCode?: string;
  cardType?: string | null;
  applePayDetails?: IApplePayDetails;
  googlePayDetails?: IGooglePayDetails;
}

export interface IReloadPrepaidCardConfig {
  swallowError?: boolean;
}

export interface IReloadDetails {
  prepaidsReload: {
    currentBalance: number;
    fdAccountId: string;
    fdCorrelationId: string;
    transactionId: string;
  };
}

/**
 * Vendor specific payment state
 */
export type PaymentState = IPaymentState;

export interface IPaymentDetails {
  accountIdentifier?: string;
  encryptedCN?: string;
  encryptedEM?: string;
  encryptedEY?: string;
  encryptedSC?: string;
  fdAccessToken?: string;
  fdNonce?: string;
  creditType?: CartPaymentCardType | null;
  fraudPreventionValues?: IFraudPreventionValues;
  applePayDetails?: IApplePayDetails;
  googlePayDetails?: IGooglePayDetails;
  paypalDetails?: IPayPalDetails;
  venmoDetails?: IVenmoDetails;
  cashappDetails?: ICashAppDetails;
  saveCard?: boolean;
  /**
   * Vendor specific payment state
   */
  state?: PaymentState;
}

export interface IRevaultInformation {
  isInvalid: boolean;
  isExpired: boolean;
}
