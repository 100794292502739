import { Svg, SvgPath, SvgProps, SvgRect, registerSVG } from '../../../components/svg';

export function Venmo(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 24 24" fill="white">
      <SvgRect y="4" width="24" height="16" rx="2" fill="white" />
      <SvgRect x="0.5" y="4.5" width="23" height="15" rx="1.5" stroke="black" strokeOpacity="0.3" />
      <SvgPath
        d="M15.5501 8C15.8607 8.48 16 8.97495 16 9.6C16 11.5934 14.1851 14.1823 12.712 16H9.34853L8 8.43672L10.9461 8.17443L11.6623 13.5559C12.3282 12.5377 13.1516 10.9377 13.1516 9.84761C13.1516 9.25036 13.0425 8.84433 12.8718 8.5099L15.5501 8Z"
        fill="#3396CD"
      />
    </Svg>
  );
}

registerSVG('Venmo', Venmo);
