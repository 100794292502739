import * as React from 'react';

import { useIntl } from 'react-intl';

import type { IQuestIncentiveOfferFragment } from 'generated/sanity-graphql';
import { maybeLocaleImages } from 'utils/graphql';
import { blockContentToPlainText } from 'utils/sanity';

import { QuestItemIncentive } from './quest-item-incentive';

export const QuestOfferIncentive = ({
  cmsQuestOfferIncentive,
  loyaltyOfferId = '',
  onCloseDrawer,
}: {
  cmsQuestOfferIncentive: IQuestIncentiveOfferFragment;
  loyaltyOfferId?: string;
  onCloseDrawer: () => void;
}) => {
  const { formatMessage } = useIntl();
  const image = maybeLocaleImages(cmsQuestOfferIncentive.localizedImage);
  const name = blockContentToPlainText(cmsQuestOfferIncentive.name?.localeRaw);
  const link = `${formatMessage({ id: 'routes.loyaltyOfferList' })}/${loyaltyOfferId}`;

  return (
    <QuestItemIncentive
      title={formatMessage({ id: 'offer' })}
      linkTo={link}
      image={image}
      altImage={name}
      name={name}
      onCloseDrawer={onCloseDrawer}
    />
  );
};
