import * as React from 'react';
import { FC } from 'react';

import { useNavigation } from 'hooks/navigation/use-navigation';

import { OperationStatus } from '../../../../../generated/graphql-gateway';
import ActionsModal from '../actions-modal';

import { useShortCodePaymentErrorModal } from './use-short-code-payment-error-modal';
import { useShortCodePaymentSuccessModal } from './use-short-code-payment-success-modal';

interface IShortCodePaymentResultModalProps {
  onAction: () => void;
  onDismiss: () => void;
  paymentResult: OperationStatus;
}

const ShortCodePaymentResultModal: FC<React.PropsWithChildren<
  IShortCodePaymentResultModalProps
>> = ({ onAction, onDismiss, paymentResult }) => {
  const { navigate } = useNavigation();

  const { data: successData, loading: loadingSuccess } = useShortCodePaymentSuccessModal();
  const { data: errorData, loading: loadingFailure } = useShortCodePaymentErrorModal();
  const successContent = successData?.shortCodePaymentSuccessModalContent || {};
  const failureContent = errorData?.shortCodePaymentErrorModalContent || {};
  const parsedImage = successContent.image?.locale?.app;

  const actionButton = OperationStatus.SUCCESSFUL
    ? successContent.actionButton
    : failureContent.actionButton;

  const handleAction = () => {
    if (onAction) {
      onAction();
    }
    navigate(actionButton?.buttonPath?.locale || '');
  };

  if (loadingSuccess || loadingFailure || !successData) {
    return null;
  }

  const successActions = [
    {
      dataTestId: 'short-code-confirmation-action',
      actionLabel: successContent.actionButton?.buttonText?.locale || '',
      actionHandler: handleAction,
    },
    ...(successContent.dismissButtonText?.locale
      ? [
          {
            dataTestId: 'short-code-dismiss-action',
            actionLabel: successContent.dismissButtonText?.locale || '',
            actionHandler: onDismiss,
            textOnly: true,
          },
        ]
      : []),
  ];

  const failureAction = [
    {
      dataTestId: 'short-code-confirmation-action',
      actionLabel: failureContent.actionButton?.buttonText?.locale || '',
      actionHandler: handleAction,
    },
  ];

  const headerText =
    paymentResult === OperationStatus.SUCCESSFUL
      ? successContent.header?.locale
      : failureContent.header?.locale;

  return (
    <ActionsModal
      actions={paymentResult === OperationStatus.SUCCESSFUL ? successActions : failureAction}
      bodyText={
        paymentResult === OperationStatus.SUCCESSFUL
          ? successContent.body?.locale
          : failureContent.body?.locale
      }
      testID="short-code-payment-result-modal"
      headerText={headerText || ''}
      headerImage={
        paymentResult === OperationStatus.SUCCESSFUL
          ? successContent.headerImage?.locale?.app
          : failureContent.headerImage?.locale?.app
      }
      image={parsedImage}
      imageAlt="short-code-payment-result"
      modalAppearanceEventMessage="Loyalty Shortcode Payment result Modal"
    />
  );
};

export default ShortCodePaymentResultModal;
