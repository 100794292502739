import { useEffect } from 'react';

import { useGeolocation } from 'state/geolocation';
import { EventName, emitEvent } from 'utils/event-hub';

interface IUseSearchingProps {
  searchingNearby: boolean;
  searchingRecent: boolean;
  searchingFavs: boolean;
  rawIsSearching: boolean | undefined;
}
interface ISearching {
  isSearchingFavorites: boolean;
  isSearchingNearby: boolean;
  isSearchingRecent: boolean;
}

export const useSearching = ({
  searchingNearby,
  searchingRecent,
  searchingFavs,
  rawIsSearching,
}: IUseSearchingProps): ISearching => {
  const { coordinatesAvailable, isPermissionChecking, isPermissionGranted } = useGeolocation();

  /**
   *   - searchingNearby
   *     - AND we have a coordinates to search
   *       (we need manual|automatic coord to search something - without that we don't search)
   *       (this also handles the case where we know permissions are denied, but we have manual coords)
   *   - we're checking permissions
   *   - we have granted permissions
   *     - AND we have no available coords to search
   *     (ex. we're in the waiting time between getting granted permissions
   *      and actually receiving the user's coords)
   */
  const isSearchingNearby =
    (searchingNearby && coordinatesAvailable) ||
    isPermissionChecking ||
    (isPermissionGranted && !coordinatesAvailable);

  const isSearchingRecent = searchingRecent || isPermissionChecking;

  const isSearchingFavorites = searchingFavs || isPermissionChecking;

  useEffect(() => {
    if (rawIsSearching) {
      emitEvent(EventName.STORE_LOCATOR_SEARCHING);
    } else {
      emitEvent(EventName.STORE_LOCATOR_RESTAURANTS_SEARCHING_FINISHED);
    }
  }, [rawIsSearching]);

  return {
    isSearchingFavorites,
    isSearchingNearby,
    isSearchingRecent,
  };
};
