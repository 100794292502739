import { Svg, SvgImage, SvgProps, registerSVG } from '../../../components/svg';

export function GooglePay(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 120 120">
      <SvgImage href={require('./GooglePay.png')} />
    </Svg>
  );
}

registerSVG('GooglePay', GooglePay);
