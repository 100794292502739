import React, { useMemo, useState } from 'react';

import { Box, Header, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import NutritionModal from 'components/nutrition';
import { Allergens } from 'components/nutrition/allergens';
import { AllergenGroup, NutritionFacts } from 'components/nutrition/types';
import { getAllergenGroups } from 'components/nutrition/utils';
import { useFormatCalories } from 'hooks/use-format-calories';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { stylesForMaxLinesOfText } from 'utils/style/max-lines-text';

import { Card } from './card';
import { IProductCard, ITitle } from './types';

const Body = Box.withConfig({
  flex: 1,
  paddingY: {
    base: '$2',
    md: '$3',
  },
  paddingX: {
    base: '$3',
    md: '$4',
  },
});

const CaloriesContainer = Text.withConfig({
  margin: 0,
  variant: 'copyTwo',
  textAlign: 'center',
  marginBottom: '$4',
});

const Title = Header.withConfig<ITitle>(p => ({
  margin: 0,
  textTransform: Styles.textTransform.headlines,
  letterSpacing: '1px',
  accessibilityLevel: 4,
  textAlign: 'center',
  ...stylesForMaxLinesOfText({
    maxLines: 3,
    lineHeight: 1.2,
    fontSizeInRem: 1.1,
    useFullHeight: p.useFullHeight,
  }),
  fontSize: {
    md: 'lg',
  },
  variant: 'headerTwo',
}));

const ProductCard: React.FC<React.PropsWithChildren<IProductCard>> = ({ product, size }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const enableAllergens = useFlag(LaunchDarklyFlag.ENABLE_NUTRITION_EXPLORER_ALLERGENS);
  const formatCalories = useFormatCalories();

  const nutritionText = useMemo(() => formatCalories(product.nutritionWithModifiers.calories), [
    formatCalories,
    product.nutritionWithModifiers.calories,
  ]);

  let allergenGroups: AllergenGroup[] | undefined;
  if (enableAllergens && product.allergens) {
    allergenGroups = getAllergenGroups(product.allergens, formatMessage);
  }

  const nutritionFacts: NutritionFacts[] = [
    {
      itemName: product.name,
      itemImage: product.image,
      facts: product.nutrition || {},
      allergenGroups,
    },
  ];

  const nutritionInfoCard = (
    <Card key={product._id} image={product.image} size={size} onPress={() => setIsModalOpen(true)}>
      <Title useFullHeight>{product.name}</Title>
      <Body>
        {nutritionText && <CaloriesContainer>{nutritionText}</CaloriesContainer>}
        {allergenGroups && <Allergens allergenGroups={allergenGroups} />}
      </Body>
    </Card>
  );

  return (
    <>
      {nutritionInfoCard}
      {isModalOpen && (
        <NutritionModal nutritionFacts={nutritionFacts} onDismiss={() => setIsModalOpen(false)} />
      )}
    </>
  );
};

export default React.memo(ProductCard);
