import { useCallback } from 'react';

import { useFormatCalories } from 'hooks/use-format-calories';

export type FormatCaloriesFn = (calories: number | string | null | undefined) => string;

export const useMenuCalories = () => {
  const formatCaloriesHook = useFormatCalories();

  const formatCalories = useCallback<FormatCaloriesFn>(
    (calories: number | string | null | undefined) => {
      const formattedCalories = formatCaloriesHook(Number(calories));
      return formattedCalories ?? '';
    },
    [formatCaloriesHook]
  );

  const formatCalorieRange = (calorieRange?: string) => {
    return calorieRange ?? '';
  };

  return {
    formatCalories,
    formatCalorieRange,
  };
};
