import * as React from 'react';
import { useMemo } from 'react';

import { LoyaltyQuestIncentiveType } from 'generated/graphql-gateway';

import { useQuestContext } from '../../loyalty-quest';

import { QuestOfferIncentive } from './quest-offer-incentive';
import { QuestPointsIncentive } from './quest-points-incentive';
import { QuestRewardIncentive } from './quest-reward-incentive';
import { QuestIncentiveWrapper } from './styled';
import type { IQuestRewardProps } from './types';

export const QuestIncentive = ({
  cmsIncentive,
  cmsQuestDetail,
  onCloseDrawer,
}: IQuestRewardProps) => {
  const { quest } = useQuestContext();
  const benefitId = quest.lastCompletion?.benefitId;

  const incentiveComponent = useMemo(() => {
    let component = null;

    switch (quest.incentiveType) {
      case LoyaltyQuestIncentiveType.OFFER: {
        if (cmsIncentive?.offer) {
          component = (
            <QuestOfferIncentive
              cmsQuestOfferIncentive={cmsIncentive.offer}
              loyaltyOfferId={benefitId}
              onCloseDrawer={onCloseDrawer}
            />
          );
        }
        break;
      }
      case LoyaltyQuestIncentiveType.REWARD: {
        if (cmsIncentive?.reward) {
          component = (
            <QuestRewardIncentive
              cmsQuestRewardIncentive={cmsIncentive.reward}
              loyaltyRewardId={benefitId}
              onCloseDrawer={onCloseDrawer}
            />
          );
        }
        break;
      }
      case LoyaltyQuestIncentiveType.BONUS_POINTS: {
        component = (
          <QuestPointsIncentive
            description={cmsQuestDetail.bonusPointsIncentiveDescription?.localeRaw || ''}
            image={cmsQuestDetail.bonusPointsIncentiveImage}
            challengeCompletedMessage={
              cmsQuestDetail.completedAndRedeemedBonusPointChallengeDescription?.localeRaw
            }
          />
        );
        break;
      }
      default: {
        break;
      }
    }

    return component;
  }, [
    benefitId,
    cmsIncentive,
    cmsQuestDetail.bonusPointsIncentiveDescription,
    cmsQuestDetail.bonusPointsIncentiveImage,
    cmsQuestDetail.completedAndRedeemedBonusPointChallengeDescription,
    quest.incentiveType,
  ]);

  return (
    <QuestIncentiveWrapper padding="$4" borderRadius="md">
      {incentiveComponent}
    </QuestIncentiveWrapper>
  );
};
