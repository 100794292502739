import { useState } from 'react';

type ValueChangeHandler<ValueType> = (nextValue: ValueType) => void;

type UseControllableStateParams<ValueType> = {
  defaultValue: ValueType;
  handleValueChange?: ValueChangeHandler<ValueType>;
  value?: ValueType;
};

type UseControllableStateResult<ValueType> = [
  // eslint-disable-next-line prettier/prettier
  value: ValueType,
  handleValueChange: ValueChangeHandler<ValueType>
];

/*
 * useControllableState encapsulates logic for allowing for a piece of state to be controlled
 * or uncontrolled
 */
/* eslint-disable-next-line complexity */
export function useControllableState<StateValueType>(
  params: UseControllableStateParams<StateValueType>,
): UseControllableStateResult<StateValueType> {
  const [uncontrolledValue, setUncontrolledValue] = useState(params.defaultValue);
  const value = params.value ?? uncontrolledValue;
  const handleValueChange = params.handleValueChange ?? setUncontrolledValue;

  return [value, handleValueChange];
}
