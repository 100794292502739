import { useIntl } from 'react-intl';
import { IResolveParams, LoginSocialFacebook } from 'reactjs-social-login';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { ProviderType } from 'generated/graphql-gateway';
import AuthButton from 'pages/authentication/components/social-auth-buttons/auth-button';
import FacebookIcon from 'pages/authentication/components/social-auth-buttons/facebook/icon';
import {
  SocialLoginParams,
  UserAuth,
} from 'pages/authentication/components/social-auth-buttons/types';
import {
  ACCESS_TOKEN_NOT_RETRIEVED_ERROR_MESSAGE,
  SocialLoginError,
  USER_DECLINED_EMAIL_ACCESS_PERMISSION_ERROR_MESSAGE,
} from 'state/auth/errors';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { facebookClientIds } from 'utils/environment';

const FacebookAuthButton = ({
  isLoading,
  isDisabled,
  onSuccess,
  onError,
  onStart,
}: SocialLoginParams) => {
  const { logEvent } = useMParticleContext();
  const { formatMessage } = useIntl();
  const authButton = (
    <AuthButton
      label={formatMessage({ id: 'logInWithFacebook' })}
      icon={<FacebookIcon />}
      loading={isLoading}
      disabled={isLoading || isDisabled}
    />
  );

  const handleError = (error: any) => {
    logEvent(CustomEventNames.SOCIAL_LOGIN_FACEBOOK_ERROR, EventTypes.Other, {
      error,
    });

    onError(error);
  };

  return (
    <ActionButton
      fullWidth
      borderColor={Styles.color.black}
      marginY="$2"
      variant={ActionButtonVariants.OUTLINE}
      disabled={isLoading || isDisabled}
    >
      {/*On IOS mobile web, the LoginSocial component doesn't take care about disabling the child button, and
          it fires the click event, to solve this we just return the disabled button without the LoginSocial component*/}
      {isDisabled ? (
        authButton
      ) : (
        <LoginSocialFacebook
          appId={facebookClientIds.appID}
          onResolve={({ data }: IResolveParams) => {
            try {
              if (
                typeof data?.grantedScopes === 'string' &&
                !data.grantedScopes.split(',').includes('email')
              ) {
                throw new SocialLoginError(
                  USER_DECLINED_EMAIL_ACCESS_PERMISSION_ERROR_MESSAGE,
                  ProviderType.FACEBOOK
                );
              }

              if (!data?.accessToken) {
                throw new SocialLoginError(
                  ACCESS_TOKEN_NOT_RETRIEVED_ERROR_MESSAGE,
                  ProviderType.FACEBOOK
                );
              }

              logEvent(CustomEventNames.SOCIAL_LOGIN_FACEBOOK_SUCCESS, EventTypes.Other);

              const userInfo: UserAuth = {
                name: data?.name,
                email: data?.email,
                token: data?.accessToken,
              };

              onSuccess(userInfo);
            } catch (error) {
              handleError(error);
            }
          }}
          onReject={handleError}
          onLoginStart={() => {
            logEvent(CustomEventNames.SOCIAL_LOGIN_FACEBOOK_CLICK, EventTypes.Other);
            onStart();
          }}
        >
          {authButton}
        </LoginSocialFacebook>
      )}
    </ActionButton>
  );
};

export default FacebookAuthButton;
