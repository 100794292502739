import React from 'react';

import { OnboardingScreensModal } from 'components/onboarding-screens-modal';

import { OnboardingProvider } from './onboarding';

export const OnboardingContent = () => {
  return (
    <OnboardingProvider>
      <OnboardingScreensModal />
    </OnboardingProvider>
  );
};
