import React, { createContext, useContext, useMemo } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import usePosVendor from 'hooks/menu/use-pos-vendor';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { IUsePricingFunctionHookReturnType } from 'state/menu/types';
import { useStoreContext } from 'state/store';

import { usePricingFunction } from './hooks/use-pricing-function';
import { useStoreMenuPricing } from './hooks/use-store-menu-pricing';

export type IMenuPricingContext = IUsePricingFunctionHookReturnType;

export const MenuPricingContext = createContext<IMenuPricingContext>({} as IMenuPricingContext);
export const useMenuPricingContext = () => useContext(MenuPricingContext);

export const MenuPricingProvider = ({ children }: IBaseProps) => {
  const { prices } = useStoreContext();
  const { vendor: posVendor } = usePosVendor();

  const legacyPricingMethods = usePricingFunction({
    prices,
    vendor: posVendor,
  });

  const storeMenuPricingMethods = useStoreMenuPricing();

  const enableStoreMenuPricing = useFlag(LaunchDarklyFlag.ENABLE_STORE_MENU_PRICING);

  const value = useMemo(
    () => (enableStoreMenuPricing ? storeMenuPricingMethods : legacyPricingMethods),
    [enableStoreMenuPricing, legacyPricingMethods, storeMenuPricingMethods]
  );

  return <MenuPricingContext.Provider value={value}>{children}</MenuPricingContext.Provider>;
};

export default MenuPricingContext.Consumer;
