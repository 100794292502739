import { IFeatureOnboardingScreen, OnboardingStorage } from 'state/onboarding/types';
import LocalStorage from 'utils/local-storage';
import { Keys } from 'utils/local-storage/constants';

export const addOrUpdateScreenState = (
  featureOnboardingTriggerRule: string,
  featureOnboardingScreen: IFeatureOnboardingScreen,
  route?: string
) => {
  const onboardingState = LocalStorage.getItem<OnboardingStorage>(Keys.ONBOARDING_STATE);
  if (onboardingState) {
    const docIdx = onboardingState.onboardingDocs.findIndex(
      doc => doc.id === featureOnboardingTriggerRule
    );
    if (docIdx === -1) {
      onboardingState.onboardingDocs.push({ id: featureOnboardingTriggerRule, screens: [] });
    }

    onboardingState.onboardingDocs = onboardingState.onboardingDocs.map(doc => {
      if (doc.id === featureOnboardingTriggerRule) {
        const screenIdx = doc.screens.findIndex(
          screen => screen.id === featureOnboardingScreen?.id
        );
        if (screenIdx !== -1) {
          doc.screens[screenIdx].completed = true;
        } else if (featureOnboardingScreen?.id) {
          doc.screens.push({
            id: featureOnboardingScreen?.id,
            completed: !!route,
          });
        }
      }
      return doc;
    });

    LocalStorage.setItem<OnboardingStorage>(Keys.ONBOARDING_STATE, onboardingState);
  } else {
    const onboardingState: OnboardingStorage = {
      loyaltyEnrolled: false,
      onboardingDocs: [
        {
          id: featureOnboardingTriggerRule,
          screens: [
            {
              id: featureOnboardingScreen?.id ?? '',
              completed: true,
            },
          ],
        },
      ],
    };
    LocalStorage.setItem<OnboardingStorage>(Keys.ONBOARDING_STATE, onboardingState);
  }
};
