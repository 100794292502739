import {
  format as dateFnsFormat,
  formatRelative as dateFnsFormatRelative,
  formatDistanceToNowStrict as dateFnsformatDistanceToNowStrict,
} from 'date-fns';

import { LANGUAGES } from 'state/intl/types';
import logger from 'utils/logger';

import { Locales } from './locales';

// a wrapper around date-fns formatRelative
// based on the i18n recommendations in their docs:
// https://date-fns.org/v2.8.0/docs/I18n
export function formatRelative(
  date: Date | number,
  baseDate: Date | number,
  language: LANGUAGES
): string {
  try {
    return dateFnsFormatRelative(date, baseDate, {
      locale: Locales[language] || Locales.en,
    });
  } catch (error) {
    logger.error({ baseDate, date, error, language });
    return '';
  }
}

export function formatDistanceToNow(date: Date | number, language: LANGUAGES): string {
  try {
    return dateFnsformatDistanceToNowStrict(date, {
      locale: Locales[language] || Locales.en,
    });
  } catch (error) {
    logger.error({ error, date, language });
    return '';
  }
}

export function format(date: Date | number, formatString: string, language: LANGUAGES): string {
  try {
    return dateFnsFormat(date, formatString, {
      locale: Locales[language] || Locales.en,
    });
  } catch (error) {
    logger.error({ error, date, formatString, language });
    return '';
  }
}

export default formatRelative;
