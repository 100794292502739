import { isMobile, isWeb, region } from 'utils/environment';
import { Region } from 'utils/environment/types';

export const isCashAppSupported = () => {
  if (region !== Region.US) {
    return false;
  }

  // Cashapp not currently supported on mobile web environments
  if (isMobile() && isWeb) {
    return false;
  }

  return true;
};
