import React from 'react';

import { useIntl } from 'react-intl';

import { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { useHideTsmPointsNotificationLoyaltyBannerExperiment } from 'experiments/hide-tsm-pointsnotification-loyaltybanner/use-enable-item-detail-reorder-experiment';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { selectors, useAppSelector } from 'state/global-state';
import { useFlag } from 'state/launchdarkly';
import { LaunchDarklyFlag } from 'state/launchdarkly/flags';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { useReversedUIContext } from 'state/reversed-ui';
import { theme } from 'styles/configure-theme';

import { StyledLoyaltyButton, StyledLoyaltyPointsIcon } from './styled';
import componentTheme from './theme';

export const LoyaltyPointsButton = ({ reversed = false }: { reversed?: boolean }) => {
  const enableLoyaltyPointsInHeader = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_POINTS_IN_HEADER);
  const hidePointsNotificationOnHome = useHideTsmPointsNotificationLoyaltyBannerExperiment();
  const { navigate } = useNavigation();
  const { formatMessage } = useIntl();
  const { reversedUI } = useReversedUIContext();
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { loyaltyUser, loading: loyaltyLoading } = useLoyaltyUser();
  const availablePoints = useAppSelector(selectors.loyalty.selectStagedCartPoints);

  const showLoyaltyCta = Boolean(
    enableLoyaltyPointsInHeader && !loyaltyLoading && loyaltyEnabled && loyaltyUser
  );

  const handleOnPress = () => {
    navigate(formatMessage({ id: 'routes.rewardsHistory' }));
  };

  if (!showLoyaltyCta || hidePointsNotificationOnHome) {
    return null;
  }

  return (
    <StyledLoyaltyButton
      variant={ActionButtonVariants.OUTLINE}
      size={ActionButtonSizes.SMALL}
      testID="app-header-loyalty-cta"
      onPress={handleOnPress}
      reversed={reversedUI || reversed}
      endIcon={
        <StyledLoyaltyPointsIcon
          reversed={reversedUI || reversed}
          color={reversed ? theme.token('crown-color') : undefined}
        />
      }
      accessibilityLabel={`${String(availablePoints)} ${formatMessage({
        id: componentTheme.points,
      })}`}
    >
      {String(availablePoints)}
    </StyledLoyaltyButton>
  );
};
