import type { FunctionComponent } from 'react';

import type { SvgProps } from './types';

export {
  default as Svg,
  Circle as SvgCircle,
  ClipPath as SvgClipPath,
  Defs as SvgDefs,
  Ellipse as SvgEllipse,
  ForeignObject as SvgForeignObject,
  G as SvgG,
  Image as SvgImage,
  Line as SvgLine,
  LinearGradient as SvgLinearGradient,
  Marker as SvgMarker,
  Mask as SvgMask,
  Path as SvgPath,
  Pattern as SvgPattern,
  Polygon as SvgPolygon,
  Polyline as SvgPolyline,
  RadialGradient as SvgRadialGradient,
  Rect as SvgRect,
  Stop as SvgStop,
  Symbol as SvgSymbol,
  TSpan as SvgTSpan,
  Text as SvgText,
  TextPath as SvgTextPath,
  Use as SvgUse,
} from './Svg';
export { SvgUri, SvgXml } from './Xml';

export type {
  SvgProps,
  UriProps as SvgUriProps,
  UriState as SvgUriState,
  XmlProps as SvgXmlProps,
  XmlState as SvgXmlState,
  AstProps as SvgAstProps,
} from './types';

const internalCache = {} as Record<string, FunctionComponent<SvgProps>>;

export function registerSVG(svgKey: string, component: FunctionComponent<SvgProps>) {
  if (internalCache[svgKey] && internalCache[svgKey] !== component) {
    throw new Error(`A different SVG has already been registered with this name: ${svgKey}`);
  }
  internalCache[svgKey] = component;
}

export const svgLibrary = internalCache as Readonly<typeof internalCache>;
