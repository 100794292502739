export const storeDisabledMarkerSvg = `
<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
   <g filter="url(#filter0_d_6014_7555)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14 24C19.5228 24 24 19.5228 24 14C24 8.47715 19.5228 4 14 4C8.47715 4 4 8.47715 4 14C4 19.5228 8.47715 24 14 24Z" fill="white"/>
   </g>
   <g filter="url(#filter1_d_6014_7555)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14 21C17.866 21 21 17.866 21 14C21 10.134 17.866 7 14 7C10.134 7 7 10.134 7 14C7 17.866 10.134 21 14 21Z" fill="black" fill-opacity="0.5"/>
   </g>
   <defs>
      <filter id="filter0_d_6014_7555" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
         <feFlood flood-opacity="0" result="BackgroundImageFix"/>
         <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
         <feOffset/>
         <feGaussianBlur stdDeviation="2"/>
         <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
         <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6014_7555"/>
         <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6014_7555" result="shape"/>
      </filter>
      <filter id="filter1_d_6014_7555" x="3" y="3" width="22" height="22" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
         <feFlood flood-opacity="0" result="BackgroundImageFix"/>
         <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
         <feOffset/>
         <feGaussianBlur stdDeviation="2"/>
         <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
         <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6014_7555"/>
         <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6014_7555" result="shape"/>
      </filter>
   </defs>
</svg>
`;

export const storeDisabledMarkerSvgRevamp = `
<svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.5 2.443c-6.02 0-10.89 4.87-10.89 10.89 0 8.167 10.89 20.224 10.89 20.224S29.39 21.5 29.39 13.333c0-6.02-4.87-10.89-10.89-10.89" fill="#999"/></svg>
`;
