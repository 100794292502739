import { useBirthdayLegalTextQuery, useSingleLoyaltyContentQuery } from 'generated/sanity-graphql';

export const useLoyaltyContent = () => {
  const {
    data: loyaltyContentData,
    loading: loyaltyContentIsLoading,
  } = useSingleLoyaltyContentQuery();

  const loyaltyContentId = loyaltyContentData?.allLoyaltyContents?.[0]?._id || '';

  const { data: birthdayLegalTextData } = useBirthdayLegalTextQuery({
    skip: loyaltyContentIsLoading || !loyaltyContentId,
    variables: { loyaltyContentId },
  });

  const birthdayLegalText = birthdayLegalTextData?.LoyaltyContent?.birthdayLegalText?.locale;

  return { birthdayLegalText };
};
