import { FC, useCallback, useState } from 'react';

import {
  Box,
  Divider,
  Header,
  Icon,
  Image,
  Pressable,
  ScrollView,
  Switch,
  Text,
} from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { useCardNumber } from 'components/gift-card-modal/use-giftcard-number';
import { LayoutContainer } from 'components/layout/styled';
import { CurrentSelectedMethodType } from 'components/payment-method/types';
import { KeyboardAwareView } from 'components/ucl/keyboard-aware-view';
import { useNavigation } from 'hooks/navigation/use-navigation';
import useErrorModal from 'hooks/use-error-modal';
import { useLoyaltyUserState } from 'state/loyalty/hooks/use-loyalty-user-state';
import { portalToFooter } from 'state/mobile-footer';
import { portalToReplaceHeader } from 'state/mobile-header-nav';
import { usePaymentContext } from 'state/payment';
import { primitive } from 'styles/constants/primitives';
import logger from 'utils/logger';

import { AddGiftCardInput } from '../../add-gift-card-input';

import {
  BackgroundAndLogoContainer,
  DefaultContainer,
  FieldsContainer,
  GiftCardContainer,
  LogoAndNameContainer,
  StyledActionButton,
  StyledBackIcon,
} from './styled';

const crownsImg = require('./Frame.png');

export const AddGiftCardRedesign: FC<React.PropsWithChildren<{}>> = () => {
  const { goBack } = useNavigation();
  const { formatMessage } = useIntl();
  const {
    getPrepaidPaymentMethod,
    setCheckoutPaymentMethodId,
    setDefaultPaymentMethodId,
    mergePrepaidCardBalances,
  } = usePaymentContext();
  const {
    giftCardNumber,
    errors,
    handleCardNumberChange,
    isValidGiftCardNumberCheck,
  } = useCardNumber();
  // @ts-ignore
  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Vaulting Gift Card',
  });

  const { loading: loyaltyUserLoading } = useLoyaltyUserState();
  const [isCardLoading, setIsCardLoading] = useState(false);
  const [isDefaultPayment, setIsDefaultPayment] = useState(false);
  const addNewCard = useCallback(
    async (newGiftCardNumber: string) => {
      setIsCardLoading(true);

      if (getPrepaidPaymentMethod()) {
        setIsCardLoading(false);
        return openErrorDialog({ message: formatMessage({ id: 'giftCardExists' }) });
      }

      try {
        if (!isCardLoading && !errors.giftCardNumber && newGiftCardNumber) {
          await mergePrepaidCardBalances({
            // Source pin is not required, BK crown cards don't have it
            sourceCardNumber: newGiftCardNumber,
          });
          if (isDefaultPayment) {
            setCheckoutPaymentMethodId(CurrentSelectedMethodType.ADD_NEW_GIFT_CARD);
            setDefaultPaymentMethodId(CurrentSelectedMethodType.ADD_NEW_GIFT_CARD);
          }
          goBack();
        }
      } catch (error) {
        // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Error | ... Remove this comment to see the full error message
        openErrorDialog({ message: formatMessage({ id: 'giftCardAddingError' }), error });
        logger.error({ error, message: 'Error adding gift card' });
      } finally {
        setIsCardLoading(false);
      }
    },
    [
      errors.giftCardNumber,
      formatMessage,
      getPrepaidPaymentMethod,
      goBack,
      isCardLoading,
      isDefaultPayment,
      mergePrepaidCardBalances,
      openErrorDialog,
      setCheckoutPaymentMethodId,
      setDefaultPaymentMethodId,
    ]
  );

  const handleGiftCardChange = useCallback(async () => {
    setIsCardLoading(true);
    await handleCardNumberChange(giftCardNumber);

    if (isValidGiftCardNumberCheck(giftCardNumber)) {
      addNewCard(giftCardNumber?.replace(/\s+/g, ''));
    } else if (errors) {
      setIsCardLoading(false);
    }
  }, [addNewCard, errors, giftCardNumber, handleCardNumberChange, isValidGiftCardNumberCheck]);

  const HiddenAppHeader = portalToReplaceHeader(() => {
    return (
      <Box _ios={{ marginTop: '$12' }} _android={{ marginTop: '$8' }} marginTop="$0">
        <Pressable
          onPress={() => {
            goBack();
          }}
          padding="$4"
        >
          <StyledBackIcon variant="back" />
        </Pressable>
        <Header alignSelf="center" variant="headerTwo" top="$4" fontSize="lg" position="absolute">
          {formatMessage({ id: 'addGiftCard' })}
        </Header>
      </Box>
    );
  });

  const HiddenAppFooter = portalToFooter(() => {
    if (loyaltyUserLoading || isCardLoading) {
      return (
        <Box padding="$4" bgColor={primitive.$white}>
          <StyledActionButton isLoading={true}></StyledActionButton>
        </Box>
      );
    }

    return (
      <Box padding="$4" bgColor={primitive.$white}>
        <StyledActionButton onPress={handleGiftCardChange}>
          {formatMessage({ id: 'addToCart' })}
        </StyledActionButton>
      </Box>
    );
  });

  return (
    <LayoutContainer isFullContainer={true}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }} height="full" width="full">
        <GiftCardContainer>
          <BackgroundAndLogoContainer>
            <LogoAndNameContainer>
              <Icon variant="giftCard" size="xl" mb="$1" />
              <Text
                color={primitive.bk.$bbqBrown}
                variant="headerTwo"
                lineHeight="24px"
                fontSize="2xl"
              >
                {formatMessage({ id: 'giftCard' })}
              </Text>
            </LogoAndNameContainer>
            <Image source={crownsImg} width={162} height={145} marginTop="$7" />
          </BackgroundAndLogoContainer>
        </GiftCardContainer>
        <KeyboardAwareView withScroll>
          <FieldsContainer>
            <AddGiftCardInput
              onChange={handleCardNumberChange}
              errorMessage={errors?.giftCardNumber}
              label={formatMessage({ id: 'cardNumber' })}
              testID="giftcard-input"
              isRedesign={true}
            />
            <Divider mb="$6" backgroundColor="blackOpacity.30" />
            <DefaultContainer>
              <Text>{formatMessage({ id: 'setAsDefaultPayment' })}</Text>
              <Switch
                value={isDefaultPayment}
                onToggle={() => {
                  setIsDefaultPayment(!isDefaultPayment);
                }}
                offTrackColor={primitive.$blackOpacity50}
                onTrackColor={primitive.bk.$crunchyGreen}
              />
            </DefaultContainer>
          </FieldsContainer>
        </KeyboardAwareView>

        <HiddenAppHeader />
        <HiddenAppFooter />
        <ErrorDialog />
      </ScrollView>
    </LayoutContainer>
  );
};
