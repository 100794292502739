import { Box, Button, Link, Text } from '@rbilabs/universal-components';

import Trophy from 'components/icons/trophy';
import { theme } from 'styles/configure-theme';
import { primitive } from 'styles/constants/primitives';

export const QuestCallToAction = Button.withConfig({
  backgroundColor: primitive.bk.$meltyYellow,
  textDecorationColor: primitive.bk.$meltyYellow,
  _text: {
    color: primitive.bk.$bbqBrown,
  },
  _hover: {
    backgroundColor: '#E59900',
  },
  _pressed: {
    backgroundColor: '#CC8800',
  },
});

export const QuestBadgeWrapper = Box.withConfig({
  flexDirection: 'row',
  alignItems: 'center',
  alignSelf: 'flex-start',
  backgroundColor: theme.token('background-pattern'),
  position: 'absolute',
  top: '$4',
  left: '$4',
  padding: '$1.5',
  borderRadius: 'sm',
  shadow: '2',
});

export const QuestBadgeIcon = Trophy.withConfig({
  height: '$3',
  marginRight: '$1.5',
});

export const QuestBadgeText = Text;

export const ImageWrapper = Box.withConfig({
  height: '$48',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.token('background-default'),
});

export const ReloadButton = Button.withConfig({
  variant: 'outline',
  borderColor: theme.token('border-color-error'),
  _text: {
    variant: 'copyTwo',
    fontSize: 'sm',
    color: theme.token('text-error'),
  },
});

export const QuestCardContainer = Box.withConfig({
  borderRadius: 'xl',
  backgroundColor: theme.token('background-pattern'),
  shadow: '1',
  width: {
    base: 'full',
    md: '$96',
  },
  maxHeight: 'auto',
});

export const StyledLink = Link.withConfig({ _web: { cursor: 'pointer' }, marginBottom: '$2' });
