import { IntlFormatters } from 'react-intl';

import { IAllergens, IItem, INutritionInfo } from '@rbi-ctg/menu';
import { AllergenIncludes } from 'enums/menu';

import { AllergenGroup } from './types';

export const getAllergenNamesForInclude = (
  allergens: IAllergens,
  allergensInclude: AllergenIncludes,
  formatMessage: IntlFormatters['formatMessage']
): string[] =>
  Object.keys(allergens)
    .filter(allergenKey => allergens[allergenKey] === allergensInclude)
    .map(key => formatMessage({ id: key }));

export const getAllergenGroupForInclude = (
  allergens: IAllergens,
  allergenIncludes: AllergenIncludes,
  formatMessage: IntlFormatters['formatMessage']
): AllergenGroup => ({
  allergenIncludes,
  allergenNames: getAllergenNamesForInclude(allergens, allergenIncludes, formatMessage),
});

export const getAllergenGroups = (
  allergens: IAllergens,
  formatMessage: IntlFormatters['formatMessage']
): AllergenGroup[] => {
  const groups: AllergenGroup[] = [];
  const contains = getAllergenGroupForInclude(allergens, AllergenIncludes.Contains, formatMessage);
  if (contains.allergenNames.length > 0) {
    groups.push(contains);
  }

  const mayContain = getAllergenGroupForInclude(
    allergens,
    AllergenIncludes.MayContain,
    formatMessage
  );
  if (mayContain.allergenNames.length > 0) {
    groups.push(mayContain);
  }

  const sameEquipment = getAllergenGroupForInclude(
    allergens,
    AllergenIncludes.CookedInSameEquipment,
    formatMessage
  );
  if (sameEquipment.allergenNames.length > 0) {
    groups.push(sameEquipment);
  }

  return groups;
};

export const allergenIncludesToIntlId = (allergenIncludes: AllergenIncludes): string | null => {
  switch (allergenIncludes) {
    case AllergenIncludes.Contains:
      return 'containsAllergens';
    case AllergenIncludes.MayContain:
      return 'mayContainAllergens';
    case AllergenIncludes.CookedInSameEquipment:
      return 'mayContainAllergensViaSharedEquipment';
    default:
      return null;
  }
};

export const roundToOneDecimalIfNecessary = (value: number | string): number =>
  Math.round((+value + Number.EPSILON) * 10) / 10;

export const emptyNutritionFacts: INutritionInfo = {
  calories: 0,
  energyKJ: 0,
  fat: 0,
  saturatedFat: 0,
  transFat: 0,
  cholesterol: 0,
  sodium: 0,
  salt: 0,
  carbohydrates: 0,
  fiber: 0,
  sugar: 0,
  proteins: 0,
  weight: 0,
  caloriesPer100: 0,
  energyKJPer100: 0,
  carbohydratesPer100: 0,
  sugarPer100: 0,
  fatPer100: 0,
  saturatedFatPer100: 0,
  proteinsPer100: 0,
  saltPer100: 0,
};

const nutritionKeys = Object.keys(emptyNutritionFacts);

/**
 * Sum the nutrition info of an items default modifier options with it's own nutrition.
 *
 * @param item {IItem}
 * The item to calcuate nutrition info for
 *
 * @returns INutritionInfo
 */
export function sumModifierNutrition(item: IItem): INutritionInfo {
  return (item.options || []).reduce(
    (map, next) => {
      const defaultOpt = next.options.find(o => o.default);
      nutritionKeys.forEach(nutritionKey => {
        if (!map[nutritionKey]) {
          map[nutritionKey] = 0;
        }
        return (map[nutritionKey] += defaultOpt?.nutrition?.[nutritionKey] ?? 0);
      });
      return map;
    },
    { ...emptyNutritionFacts, ...item.nutrition }
  );
}
