import { useIntl } from 'react-intl';

import { IHistoricalOrder } from '@rbi-ctg/menu';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { getOrderNumber } from 'utils/order';

interface ICreateAriaLabel {
  buttonType?: 'add' | 'view' | 'reorder';
  name?: string;
  modifiers?: string[];
  order?: IHistoricalOrder;
}

export const useCreateOrderAriaLabel = () => {
  const { formatMessage } = useIntl();
  const enableOrderNumberGeneration = useFlag(LaunchDarklyFlag.ENABLE_ORDER_NUMBER_GENERATION);
  return ({ buttonType, name, modifiers, order }: ICreateAriaLabel) => {
    const isAddButtonType = buttonType === 'add';
    const isViewButtonType = buttonType === 'view';
    const isReorderButtonType = buttonType === 'reorder';

    if (isAddButtonType) {
      if (modifiers?.length) {
        const modString = modifiers.join(' ');
        return formatMessage({ id: 'addItemToCartWithModifiers' }, { name, modifiers: modString });
      }
      return formatMessage({ id: 'addItemToCart' }, { name });
    }

    if (!order) {
      return undefined;
    }
    if (order.cart.ticketNumber) {
      const ticketNumber = getOrderNumber(order, enableOrderNumberGeneration);

      if (isViewButtonType) {
        return formatMessage({ id: 'viewDetailsWithOrderNumber' }, { ticketNumber });
      }

      if (isReorderButtonType) {
        return formatMessage({ id: 'reorderWithOrderNumber' }, { ticketNumber });
      }
    } else {
      const orderDesc = order.cart.cartEntries.map(({ name: entryName }) => entryName).join(' ');
      if (isReorderButtonType) {
        return formatMessage({ id: 'reorderWithOrderDescription' }, { orderDesc });
      }
      if (isViewButtonType) {
        return formatMessage({ id: 'viewOrderWithDescription' }, { orderDesc });
      }
    }
    return undefined;
  };
};
