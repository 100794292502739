import { useCallback, useEffect, useMemo, useState } from 'react';

import { isEqual, unionBy } from 'lodash-es';

import { ICartEntry, IServerOrder } from '@rbi-ctg/menu';
import { ItemAvailabilityStatus } from 'enums/menu';
import { useMenuContext } from 'state/menu';
import { getUnavailableItemsFromServerOrder } from 'state/order/utils';
import { maybeMapCartEntryToMenuObjectIdentifier } from 'utils/menu';

type IUseUnavailableCartEntriesHook = (args: {
  serverOrder: IServerOrder | null;
  cartEntries: ICartEntry[];
}) => {
  unavailableCartEntries: ICartEntry[];
  setUnavailableCartEntries: React.Dispatch<React.SetStateAction<ICartEntry[]>>;
};

export const useUnavailableCartEntries: IUseUnavailableCartEntriesHook = ({
  cartEntries,
  serverOrder,
}) => {
  const [unavailableMenuCartEntries, setUnavailableMenuCartEntries] = useState<ICartEntry[]>([]);
  const [unavailableCartEntries, setUnavailableCartEntries] = useState<ICartEntry[]>(
    getUnavailableItemsFromServerOrder({ cartEntries, serverOrder })
  );

  const unavailableMenuCartEntriesIds = useMemo(
    () => new Set(unavailableMenuCartEntries.map(({ _id }) => _id)),
    [unavailableMenuCartEntries]
  );

  const { checkItemAvailability } = useMenuContext();

  const getUnavailableItemsFromMenu = useCallback(
    async (cartEntries: ICartEntry[]) => {
      const items: ICartEntry[] = [];
      let newUnavailableItems = false;

      for (const cartEntry of cartEntries) {
        const itemId = maybeMapCartEntryToMenuObjectIdentifier(cartEntry);
        if (!itemId) {
          continue;
        }

        const { availabilityStatus } = await checkItemAvailability(itemId);

        if (availabilityStatus !== ItemAvailabilityStatus.AVAILABLE) {
          items.push(cartEntry);
          // If the unavailable entry does not exist in unavailableMenuCartEntriesIds
          // then we need to update state
          newUnavailableItems =
            newUnavailableItems || !unavailableMenuCartEntriesIds.has(cartEntry._id);
        }
      }

      return {
        items,
        newUnavailableItems,
      };
    },
    [checkItemAvailability, unavailableMenuCartEntriesIds]
  );

  // set unavailable cart entries based on serverOrder
  useEffect(() => {
    setUnavailableCartEntries(oldEntries => {
      const newEntries = unionBy(
        getUnavailableItemsFromServerOrder({ cartEntries, serverOrder }),
        oldEntries,
        'cartId'
      );

      return isEqual(newEntries, oldEntries) ? oldEntries : newEntries;
    });
  }, [cartEntries, serverOrder]);

  // Check menu for unavailable cartEntries
  useEffect(() => {
    let mounted = true;
    async function evaluateMenuAvailability() {
      const { items, newUnavailableItems } = await getUnavailableItemsFromMenu(cartEntries);

      // If the unavailable items array length has changed this may indicate an unavailable item have been removed
      const lengthChanged = items.length !== unavailableMenuCartEntries.length;
      const shouldUpdate = newUnavailableItems || lengthChanged;
      if (mounted && shouldUpdate) {
        setUnavailableMenuCartEntries(items);
      }
    }

    if (cartEntries?.length) {
      evaluateMenuAvailability();
    }

    return () => {
      mounted = false;
    };
  }, [cartEntries, getUnavailableItemsFromMenu, unavailableMenuCartEntries.length]);

  // If all cart entries were removed, clean up the unavailableMenuCartEntries state
  useEffect(() => {
    let mounted = true;
    if (!cartEntries?.length && unavailableMenuCartEntries?.length && mounted) {
      setUnavailableMenuCartEntries([]);
    }
    return () => {
      mounted = false;
    };
  }, [cartEntries?.length, unavailableMenuCartEntries?.length]);

  return useMemo(
    () => ({
      unavailableCartEntries: unionBy(unavailableCartEntries, unavailableMenuCartEntries, 'cartId'),
      setUnavailableCartEntries,
    }),
    [unavailableCartEntries, unavailableMenuCartEntries]
  );
};
