import { Box, Icon, View, makeUclComponent } from '@rbilabs/universal-components';

import ActionButton from 'components/action-button';
import { theme } from 'styles/configure-theme';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

export const StyledLogo = View.withConfig({
  marginX: 'auto',
  padding: '$1.5',
});

export const StyledBox = Box.withConfig({
  padding: '$4',
});

export const StyledActionButton = makeUclComponent(ActionButton).withConfig({
  width: {
    base: 'full',
    md: '300px',
  },
  alignSelf: 'center',
  marginTop: '$4',
});

export const StyledBackIcon = Icon.withConfig({
  color: theme.token('icon-header-contrast'),
  size: '5',
  ...hiddenAccessibilityPlatformProps,
});

export const HeaderContainer = Box.withConfig({
  position: 'relative',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '$14',
});

export const BackButtonContainer = Box.withConfig({
  position: 'absolute',
  left: 0,
});

export const BlockContainer = Box.withConfig({
  paddingLeft: '$4',
  paddingRight: '$4',
  maxWidth: '912px',
  alignSelf: 'center',
  width: '100%',
});
