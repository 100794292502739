import React, { useCallback, useMemo, useRef, useState } from 'react';

import { Box, addWithConfig } from '@rbilabs/universal-components';
import LottieView from 'lottie-react-native';
import { usePropsResolution, useStyledSystemPropsResolver } from 'native-base';
import { LayoutChangeEvent, Platform, useWindowDimensions } from 'react-native';
import { ImageStyle } from 'react-native';

import { parseAssetID } from 'remote/build-image-url';
import { useLocale } from 'state/intl';
import { useUIContext } from 'state/ui';
import { PictureBoundaryDimensions, generateSrcUri } from 'utils/image';

import Blurhash from './blurhash';
import { SvgOrImage } from './svgOrImage';
import { IPictureProps, LoadState, MeasuredDimensions, ResizeMode } from './types';
import { getBoundaryDimensions, isSVG } from './utils';

const Boundary = Box.withConfig({
  overflow: 'hidden',
  width: 'full',
});

const imageStyle: ImageStyle = {
  width: '100%',
  height: '100%',
};

const imagePropsSizedByChildren: ImageStyle = {
  width: 'auto',
  height: 'auto',
};

const imagePlaceholder: ImageStyle = {
  ...imageStyle,
  opacity: 0.8,
};

const isWeb = Platform.OS === 'web';

// have we encountered this URL before during this app's lifetime?
// this doesn't account for images that are in cache from previous sessions
// we could use Image.queryCache() but it's async and would cause additional re-renders
// (likely same as just loading the image which will come from cache)
const imageIsInCacheSet = new Set();

// A react native extension file for the picture component has been created
// since react-native-web does not currently support source sets properly for web
// through the RN Image component.
// Reference: https://github.com/necolas/react-native-web/issues/515
const Picture = ({
  alt,
  children,
  image,
  lottie,
  objectFitContain = false,
  quality = 80,
  resolutionMultiplier = 1, // Not sure if this is necessary anymore. Should we remove it?
  onImageLoaded,
  sizedByChildren = false,
  cache = 'force-cache', // per sanity if an image changes its UUID will always change so this is a sensible default
  isBlurhashEnabled = true,
  ...boundaryBoxProps
}: IPictureProps) => {
  // The Image cache check can take a while, so we are storing a local cache too
  const { buildImageUrl } = useUIContext();
  const { width: screenWidth } = useWindowDimensions();
  const { locale, prevLocale } = useLocale();
  const metadata = image?.asset?.metadata;

  const hasPreloadImage = !!metadata && 'blurHash' in metadata;

  const [resolvedBoundaryBoxStyleProps] = useStyledSystemPropsResolver(
    usePropsResolution('Picture', boundaryBoxProps)
  );

  const [measuredDimensions, setMeasuredDimensions] = useState<MeasuredDimensions>({
    width: 0,
    height: 0,
  });

  const { format, dimensions } = parseAssetID(image?.asset?._id || undefined);
  const isFinalFormatSvg = isSVG(format);

  const [loadState, setLoadState] = useState<LoadState>(
    hasPreloadImage && !isWeb ? LoadState.loading : LoadState.loaded
  );

  const boundaryDimensions: PictureBoundaryDimensions = useMemo(() => {
    return sizedByChildren
      ? { height: null, width: null }
      : getBoundaryDimensions({
          extrinsicDimensions: {
            width: resolvedBoundaryBoxStyleProps.width,
            height: resolvedBoundaryBoxStyleProps.height,
          },
          intrinsicDimensions: dimensions,
          measuredDimensions,
        });
  }, [
    dimensions,
    measuredDimensions,
    resolvedBoundaryBoxStyleProps.height,
    resolvedBoundaryBoxStyleProps.width,
    sizedByChildren,
  ]);

  const prevUri = useRef('');
  const prevImage = useRef('');

  const resolvedURI = useMemo(() => {
    // if the locale has changed, reset the uri so that the image will be reloaded
    if (locale !== prevLocale) {
      prevUri.current = '';
    }

    if (JSON.stringify(prevImage.current) !== JSON.stringify(image)) {
      prevUri.current = '';
      prevImage.current = JSON.stringify(image);
    }

    if (!image?.asset?._id) {
      return '';
    }

    // if this image has a size specified (not just 100%) ignore handleLayoutEvent changes that may change the requested URI's size
    // leading to duplicate requests and longer image loading time
    // TODO - find a better way to implement this component / big refactor of it
    if (
      boundaryDimensions.height &&
      boundaryDimensions.width &&
      (boundaryDimensions.height !== '100%' || boundaryDimensions.width !== '100%') &&
      prevUri.current
    ) {
      return prevUri.current;
    }

    const uri = isFinalFormatSvg
      ? buildImageUrl(image)
      : generateSrcUri({
          buildImageUrl,
          image,
          boundaryDimensions,
          measuredDimensions,
          screenWidth,
          quality,
          resolutionMultiplier,
        });

    prevUri.current = uri;

    return uri;
  }, [
    boundaryDimensions,
    buildImageUrl,
    image,
    isFinalFormatSvg,
    locale,
    measuredDimensions,
    prevLocale,
    quality,
    resolutionMultiplier,
    screenWidth,
  ]);

  const shouldShowPreloadImage =
    !imageIsInCacheSet.has(resolvedURI) &&
    !isWeb && // DO NOT ENABLE FOR WEB WITHOUT PROVIDING A BLURHASH IMPLEMENTATION
    loadState === LoadState.loading &&
    hasPreloadImage &&
    !isFinalFormatSvg &&
    isBlurhashEnabled;

  const handleLayoutEvent = useCallback(
    (event: LayoutChangeEvent) => {
      const layout = event.nativeEvent.layout;
      if (
        layout.width !== measuredDimensions.width ||
        layout.height !== measuredDimensions.height
      ) {
        setMeasuredDimensions({
          width: event.nativeEvent.layout.width,
          height: event.nativeEvent.layout.height,
        });
      }
    },
    [measuredDimensions.height, measuredDimensions.width]
  );

  const wrappedOnLoad = useCallback(() => {
    setLoadState(LoadState.loaded);
    imageIsInCacheSet.add(resolvedURI);
    onImageLoaded?.();
  }, [onImageLoaded, resolvedURI]);

  const lottieUrl = lottie?.asset?.url;

  if (!image?.asset && !lottieUrl) {
    return <>{children}</>;
  }

  if (!image?.asset?._id && !lottieUrl) {
    return null;
  }

  // TODO we are currently missing a ton of alt images (just look at all the console warnings on web), sub this in for now if missing
  const accessibilityLabel = alt || '';

  const placeholderStyle = sizedByChildren ? imagePropsSizedByChildren : imagePlaceholder;
  const finalImageStyle = sizedByChildren ? imagePropsSizedByChildren : imageStyle;

  return (
    <Boundary
      width={boundaryDimensions.width}
      height={boundaryDimensions.height}
      onLayout={handleLayoutEvent}
      {...(lottie ? { 'aria-label': accessibilityLabel } : {})}
      {...boundaryBoxProps}
    >
      {lottieUrl && (
        <LottieView
          style={finalImageStyle}
          source={{
            uri: lottieUrl,
          }}
          autoPlay
          loop
        />
      )}
      {!lottie && (
        <SvgOrImage
          testID="picture-img"
          cache={cache}
          accessibilityLabel={accessibilityLabel}
          isSVG={isFinalFormatSvg}
          uri={resolvedURI}
          style={finalImageStyle}
          onLoad={wrappedOnLoad}
          resizeMode={objectFitContain ? ResizeMode.contain : ResizeMode.cover}
        >
          {children && <Boundary>{children}</Boundary>}
          {shouldShowPreloadImage && metadata?.blurHash && (
            <Blurhash
              accessibilityLabel={accessibilityLabel + ' Loading Placeholder'}
              blurhash={metadata.blurHash}
              style={placeholderStyle}
            />
          )}
        </SvgOrImage>
      )}
    </Boundary>
  );
};

export default addWithConfig(React.memo(Picture));
