import { View, makeUclComponent } from '@rbilabs/universal-components';

import ActionButton from 'components/action-button';

export const FixedCallToAction = View.withConfig({
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  bottom: 0,
  width: 'full',
});

export const FixedActionButton = makeUclComponent(ActionButton).withConfig({
  width: '$80',
  marginY: '$4',
  paddingX: '$1',
});
