export interface ICapsule {
  channel: string;
  payload: {
    event: string;
  };
}

export enum CapsuleEvents {
  SIGN_IN = 'signIn',
  SIGN_UP = 'signUp',
  SIGN_OUT = 'signOut',
}

export enum CapsuleChannels {
  AUTH = 'authentication',
}
