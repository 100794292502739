import { LOCALE_SEPARATOR } from 'state/intl/constants';
import { LANGUAGES, LanguageAndRegionFromLocaleString } from 'state/intl/types';
import { Region } from 'utils/environment/types';

export const getLanguageAndRegionFromLocaleString = (
  localeString: string = ''
): LanguageAndRegionFromLocaleString => {
  const [languageStr, regionStr] = localeString.split(LOCALE_SEPARATOR, 2);
  return [LANGUAGES[languageStr], Region[regionStr]];
};
