import * as React from 'react';
import { FC } from 'react';

import { useMatch } from 'state/location';
import { routes } from 'utils/routing';

import { useServiceModeNotification } from './hooks/use-service-mode-notification';
import TopServiceModeNotificationView from './top-service-mode-notification.view';
import { ITopServiceModeNotificationProps } from './types';

const StickyServiceNotification = TopServiceModeNotificationView.withConfig({
  zIndex: Styles.zIndex.overlay + 100,
  width: 'full',
});

/**
 *
 * TopServiceModeNotification purpose
 *
 */
export const TopServiceModeNotification: FC<React.PropsWithChildren<
  ITopServiceModeNotificationProps
>> = () => {
  const topServiceModeNotificationDetails = useServiceModeNotification();

  const isOnMenuPage = useMatch({
    path: routes.menu,
    end: false,
  });

  return (
    !isOnMenuPage &&
    !!topServiceModeNotificationDetails && (
      <StickyServiceNotification {...topServiceModeNotificationDetails} />
    )
  );
};
