import React from 'react';

import { Box } from '@rbilabs/universal-components';
import { FlatList } from 'react-native';

import { QuestCardWrapper } from '../quest-card-wrapper';
import { useUserQuest } from '../use-user-quest';

import { QuestCardListSkeleton } from './quest-card-list-skeleton';
import { QuestLibraryHeader } from './quest-library-header';

export const StepSeparator = Box.withConfig({
  height: '$4',
});

const QuestCardList = ({ showHeader = true }: { showHeader?: boolean }) => {
  const { isLoading, userQuest, cmsQuestCardUI } = useUserQuest();

  if (isLoading) {
    return <QuestCardListSkeleton />;
  }

  if (!userQuest || !cmsQuestCardUI) {
    return null;
  }

  return (
    <FlatList
      ListHeaderComponent={showHeader ? QuestLibraryHeader : null}
      data={[userQuest]}
      keyExtractor={({ loyaltyQuest }) => loyaltyQuest.id}
      ItemSeparatorComponent={StepSeparator}
      renderItem={({ item }) => {
        const { loyaltyQuest, cmsQuestCard } = item;

        return (
          <QuestCardWrapper
            quest={loyaltyQuest}
            cmsQuestCard={cmsQuestCard}
            cmsQuestCardUI={cmsQuestCardUI}
          />
        );
      }}
    />
  );
};

export default QuestCardList;
