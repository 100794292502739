import { useMemo, useRef } from 'react';

/**
 * For simple useMemo cases that the useMemo deps array is the same as each field on the incoming object
 * @param depsObj an object defining the change dependencies of a useMemo
 * @returns a normal useMemo result
 */
export const useMemoAll = <T extends Object>(depsObj: T) => {
  const fieldsRef = useRef(Object.keys(depsObj));

  return useMemo(
    () => depsObj,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fieldsRef.current.map(fieldName => depsObj[fieldName])
  );
};
