import { useCallback } from 'react';

import { Platform, View } from 'react-native';

import { IBaseProps } from '@rbi-ctg/frontend';
import { SplashScreen } from 'components/splash-screen/splash-screen';

import { Deferred } from './Deferred';

// there is a bug on iOS if you display a modal before splash screen is gone you can't get off of the splash screen
// expose splash screen status to Modals across the app to avoid
export const splashScreenHiddenDeferred = new Deferred();

export const HideSplashScreen: React.FC = ({ children }: IBaseProps) => {
  return (
    <View
      onLayout={useCallback(() => {
        if (Platform.OS === 'web') {
          return splashScreenHiddenDeferred.resolve();
        }

        SplashScreen.hide();
        splashScreenHiddenDeferred.resolve();
      }, [])}
    >
      {children}
    </View>
  );
};
