import React, { FC, createContext, useContext, useMemo } from 'react';

import { IServerOrder } from '@rbi-ctg/menu';
import { IRestaurant } from '@rbi-ctg/store';

import { IFlyBuyContext } from './types';

export const FlyBuyContext = createContext<IFlyBuyContext>({} as IFlyBuyContext);

export const useFlyBuyContext = () => useContext(FlyBuyContext);

export const FlyBuyProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const value = useMemo(
    () => ({
      isAvailable: false,
      isReady: false,
      isReadyWithTimedFire: false,
      init: async () => {},
      needPermissions: false,
      checkPermissions: async () => false,
      isCheckingPermissions: false,
      createOrder: async (serverOrder: IServerOrder, store: IRestaurant) => {},
      permissionsModalShown: false,
      setPermissionsModalShown: () => {},
    }),
    []
  );

  return <FlyBuyContext.Provider value={value}>{children}</FlyBuyContext.Provider>;
};
