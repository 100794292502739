import { useCallback, useEffect, useMemo, useState } from 'react';

import useEffectOnce from 'hooks/use-effect-once';
import { useLDContext } from 'state/launchdarkly';
import en from 'state/translations/en.json';
import * as DatadogLogger from 'utils/datadog';
import { Region } from 'utils/environment/types';
import { ISOs } from 'utils/form/constants';
import {
  findSupportedLanguage,
  getMessagesForLanguage,
  inferLanguage,
  loadLanguage,
} from 'utils/intl/language';
import {
  getLanguageAndRegionFromLocaleString,
  hydrateInitialLocale,
  inferSupportedLocale,
} from 'utils/intl/locale';
import {
  inferHasShownLocaleSelector,
  inferHasShownLocaleSelectorFromUrlParams,
} from 'utils/intl/locale/infer-has-shown-locale-selector';
import { findSupportedRegion, loadRegion } from 'utils/intl/region';
import { clearRegionSpecificStorage } from 'utils/intl/region/clear-region-specific-storage';
import LocalStorage, { StorageKeys } from 'utils/local-storage';

import {
  PROD_DEFAULT_REGION as DEFAULT_REGION,
  LOCALE_DATA,
  READABLE_LANGUAGES,
  READABLE_REGIONS,
  REGION_DOMAIN_VALUES,
  SORTED_PROD_SUPPORTED_LOCALES,
  PROD_SUPPORTED_LANGUAGES as SUPPORTED_LANGUAGES,
  PROD_SUPPORTED_REGIONS as SUPPORTED_REGIONS,
} from './constants';
import { LANGUAGES, LanguageAndRegionFromLocaleString, LocaleData } from './types';

export { SUPPORTED_REGIONS };
export { SUPPORTED_LANGUAGES };

const reloadWindow = () => document.location.reload();

/*
 * This Region hook has a few specs to it:
 *
 * 1. It is preloaded with the users inferred region from domain or url params
 * 2. It allows the UI to update the region manually
 * 3. When the users intentionally changes the region, we store that in local storage.
 *    so that we can preload with that selected region in the future.
 * 4. The preloaded locale (language + region) must exist in our list of `SORTED_PROD_SUPPORTED_LOCALES` or fallback to a value on that list.
 * 5. If the user updates their browser region, we should update too.
 */
const useIntl = () => {
  const [messages, setMessages] = useState(en);
  const { updateUserLocale } = useLDContext();
  const [locale, setLocale] = useState<string>(hydrateInitialLocale());
  const [language, region] = useMemo<LanguageAndRegionFromLocaleString>(
    () => getLanguageAndRegionFromLocaleString(locale),
    [locale]
  );

  const [hasShownLocaleSelector, setInternalHasShownLocaleSelector] = useState<boolean>(
    inferHasShownLocaleSelector()
  );

  const setHasShownLocaleSelector = useCallback(() => {
    setInternalHasShownLocaleSelector(true);
  }, [setInternalHasShownLocaleSelector]);

  const setCurrentLocale = useCallback(
    (newLanguage: LANGUAGES, newRegion: Region, reloadPage: boolean = true) => {
      const newLocale = inferSupportedLocale(newLanguage, newRegion);
      const [newLocaleLanguage, newLocaleRegion] = getLanguageAndRegionFromLocaleString(newLocale);

      LocalStorage.setItem(StorageKeys.LANGUAGE, newLocaleLanguage);
      LocalStorage.setItem(StorageKeys.REGION, newLocaleRegion);

      if (newRegion !== region) {
        // Wipe out region specific keys
        clearRegionSpecificStorage();
      }

      // todo: make sure the locale is valid so we dont end up with fr-US
      setLocale(newLocale);
      getMessagesForLanguage(newLocaleLanguage, newLocaleRegion).then(setMessages);
      updateUserLocale({ region: newRegion, language: newLanguage });
      // reload to refresh content that is pulled from Sanity - edge case
      // eg if user's language is english, but they click an external link to french static page
      if (reloadPage) {
        reloadWindow();
      }
    },
    [region, updateUserLocale]
  );

  useEffectOnce(() => {
    window.addEventListener('languagechange', () => {
      setCurrentLocale(loadLanguage(), loadRegion());
    });
  });

  // We track the user's locale (region + language) in logger errors
  // some errors might occur in specific languages, so its crucial
  // to know all info on how to reproduce.
  useEffect(() => {
    DatadogLogger.addContext('page_locale', locale);
  }, [locale]);

  useEffectOnce(() => {
    // Make sure we never show the user the dialog again if the params we passed
    if (inferHasShownLocaleSelectorFromUrlParams()) {
      setHasShownLocaleSelector();
    }
    // remove the region from localStorage so that it doesn't override the domain's region
    localStorage.removeItem(StorageKeys.REGION);
    setCurrentLocale(loadLanguage(), loadRegion(), false);
  });

  const { dateFormat } = LOCALE_DATA[locale];

  const localeDetermined = inferHasShownLocaleSelector();

  const [determiningLocaleComplete, setDeterminingLocaleCompleteInternal] = useState(
    localeDetermined
  );

  const setDeterminingLocaleComplete = useCallback(() => {
    setDeterminingLocaleCompleteInternal(true);
  }, []);

  return {
    region,
    feCountryCode: ISOs[region] || ISOs[DEFAULT_REGION],
    language,
    locale,
    inferLanguage,
    findSupportedRegion,
    readableRegions: READABLE_REGIONS[language],
    regionDomainValues: REGION_DOMAIN_VALUES,
    clearRegionSpecificStorage,
    findSupportedLanguage,
    readableLanguages: READABLE_LANGUAGES[language],
    setCurrentLocale,
    hasShownLocaleSelector,
    setHasShownLocaleSelector,
    messages,
    dateFormat,
    determiningLocaleComplete,
    setDeterminingLocaleComplete,
    supportedLocales: SORTED_PROD_SUPPORTED_LOCALES.map(
      supportedLocale => LOCALE_DATA[supportedLocale] as LocaleData
    ),
  };
};

export default useIntl;
