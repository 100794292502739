import '@expo/match-media';
import 'styles/index';

import { decode, encode } from 'base-64';
import { Text } from 'react-native';

if (!global.btoa) {
  global.btoa = encode;
}

if (!global.atob) {
  global.atob = decode;
}

// This is a temporary hack for making our build system module mocking work with React Native.
// when components render their children but it's Text.
// @ts-expect-error TS(7017) FIXME: Element implicitly has an 'any' type because type ... Remove this comment to see the full error message
global.__REACT_NATIVE_TEXT_COMPONENT__ = Text;
