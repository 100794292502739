import { Box, Header, Text } from '@rbilabs/universal-components';

export const ContentWrapper = Box.withConfig({
  width: '100%',
  justifyContent: 'center',
  padding: '$4',
  textAlign: 'center',
  alignItems: 'center',
});

export const ContentText = Text.withConfig({
  textAlign: 'center',
  marginBottom: '$10',
});

export const ContentHeader = Header.withConfig({
  textAlign: 'center',
  marginBottom: '$4',
});
