import NodeRSA from 'node-rsa';

import { IEncryptCreditArgs } from 'utils/encryption/types';
import { IPaymentPayload } from 'utils/payment';

type EncryptFunction = (field: string) => string;

const encrypt = (publicKey: string, algorithm?: string): EncryptFunction => {
  const ALGORITHM_PKCS = 'RSA/NONE/PKCS1Padding';

  // PKCS1
  if (!algorithm || algorithm === ALGORITHM_PKCS) {
    const key = new NodeRSA(`-----BEGIN PUBLIC KEY-----\n${publicKey}\n-----END PUBLIC KEY-----`);
    key.setOptions({ encryptionScheme: 'pkcs1' });
    return (field: string) => key.encrypt(field, 'base64');
  }

  // RSA-OAEP
  const key = new NodeRSA(`-----BEGIN PUBLIC KEY-----\n${publicKey}\n-----END PUBLIC KEY-----`);
  key.setOptions({
    encryptionScheme: {
      scheme: 'pkcs1_oaep',
      hash: 'sha512',
    },
  });
  return (field: string) => `ENC_[${key.encrypt(field, 'base64')}]`;
};

export const encryptCredit = async ({
  credit,
  fdPublicKey,
  isUsingTokenEx = false,
  algorithm,
}: IEncryptCreditArgs): Promise<IPaymentPayload> => {
  const encryptWithKey = encrypt(fdPublicKey, algorithm);
  const cardNumber = encryptWithKey(credit.cardNumber);
  const expiryDate = credit.expiryDate
    ? {
        month: isUsingTokenEx ? credit.expiryDate.month : encryptWithKey(credit.expiryDate.month),
        year: isUsingTokenEx ? credit.expiryDate.year : encryptWithKey(credit.expiryDate.year),
      }
    : null;
  const securityCode = credit.securityCode ? encryptWithKey(credit.securityCode) : null;
  return {
    ...credit,
    cardNumber,
    expiryDate,
    securityCode,
  };
};

export { getStoredPrepaidCard, storePrepaidCard } from './store-prepaid-card';
