import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash-es';

import { IPrices } from '@rbi-ctg/menu';
import { ItemAvailabilityStatus } from 'enums/menu';
import usePosVendor from 'hooks/menu/use-pos-vendor';
import { useLoyaltyRewardsList } from 'hooks/use-loyalty-rewards-list';
import { IEngineRewardsMap, ISanityRewardsMap, LoyaltyReward } from 'state/loyalty/types';
import { useMainMenuContext } from 'state/menu/main-menu';
import { IDayPartBoundary, IValidDayPart } from 'state/menu/main-menu/types';
import { useStoreContext } from 'state/store';
import { IMenuObjectWithDaypart, getAvailabilityStatus, itemIsAvailable } from 'utils/availability';
import { IWithVendorConfig, PosVendors } from 'utils/vendor-config';

import { IIncentiveEvaluationResult, IncentiveEvaluationMap } from './types';
import {
  buildOutOfDayPartEvaluationResult,
  buildVendorEvaluationResult,
} from './utils/incentives-availability';

interface IBaseRewardEvaluationParams {
  activeDayParts: IDayPartBoundary[];
  dayParts: readonly IValidDayPart[];
  prices: IPrices;
  vendor: PosVendors | null;
}
interface CheckRewardAvailabilityFunctionParams extends IBaseRewardEvaluationParams {
  reward: LoyaltyReward;
  storeHasBurgersForBreakfast?: boolean;
}

type CheckRewardAvailabilityFunction = (
  params: CheckRewardAvailabilityFunctionParams
) => IIncentiveEvaluationResult | null;

interface IRewardsMaps {
  sanityRewardsMap: ISanityRewardsMap | null;
  engineRewardsMap: IEngineRewardsMap;
}

type BuildAvailabilityMapParams = IRewardsMaps &
  IBaseRewardEvaluationParams & { storeHasBurgersForBreakfast?: boolean };

export const useRewardsEvaluation = () => {
  const { engineRewardsMap, sanityRewardsMap } = useLoyaltyRewardsList();
  const { prices, store } = useStoreContext();
  const { vendor } = usePosVendor();
  const { activeDayParts, dayParts } = useMainMenuContext();
  const [rewardsEvaluationMap, setRewardsEvaluationMap] = useState<IncentiveEvaluationMap>({});

  useEffect(() => {
    if (!isEmpty(sanityRewardsMap) && !isEmpty(engineRewardsMap) && !!prices) {
      const evaluationsMap = buildEvaluationResultsMap({
        sanityRewardsMap: sanityRewardsMap!,
        engineRewardsMap,
        activeDayParts,
        dayParts,
        prices,
        vendor,
        storeHasBurgersForBreakfast: Boolean(store.hasBurgersForBreakfast),
      });

      setRewardsEvaluationMap(evaluationsMap);
    }
  }, [
    prices,
    activeDayParts,
    dayParts,
    sanityRewardsMap,
    engineRewardsMap,
    setRewardsEvaluationMap,
    vendor,
    store.hasBurgersForBreakfast,
  ]);

  return {
    rewardsEvaluationMap,
    engineRewardsMap,
    sanityRewardsMap,
  };
};

const buildEvaluationResultsMap = ({
  sanityRewardsMap,
  engineRewardsMap,
  activeDayParts,
  dayParts,
  prices,
  vendor,
  storeHasBurgersForBreakfast,
}: BuildAvailabilityMapParams) => {
  const rewardsArray = Object.values(sanityRewardsMap ?? {});
  const evaluationsMap = rewardsArray.reduce((evaluationMap, sanityReward) => {
    const loyaltyEngineId = sanityReward?.loyaltyEngineId ?? '';
    const engineReward = engineRewardsMap[loyaltyEngineId];

    if (!engineReward || engineReward?.locked) {
      evaluationMap[loyaltyEngineId] = [];
    } else {
      const evaluationsResult = checkIncentiveBasicAvailability({
        activeDayParts,
        dayParts,
        prices,
        reward: sanityReward,
        vendor,
        storeHasBurgersForBreakfast,
      });

      if (evaluationsResult) {
        evaluationMap[loyaltyEngineId] = [evaluationsResult];
      }
    }

    return evaluationMap;
  }, {});

  return evaluationsMap;
};

// This function checks the reward availability and wraps `getAvailabilityStatus` that creates a shallow evaluation of menu items
// It is not an extensive deep evaluation of menu items/combos
const checkIncentiveBasicAvailability: CheckRewardAvailabilityFunction = ({
  activeDayParts,
  dayParts,
  prices,
  reward,
  vendor,
  storeHasBurgersForBreakfast,
}) => {
  // the reward should be available to check the incentive availability
  const isRewardAvailable = itemIsAvailable(reward as IWithVendorConfig, vendor, prices);
  if (!isRewardAvailable) {
    return buildVendorEvaluationResult();
  }

  const incentive = reward?.incentives?.[0];

  const status = getAvailabilityStatus({
    data: incentive as IMenuObjectWithDaypart,
    activeDayParts,
    prices,
    vendor,
    isExtra: false,
    storeHasBurgersForBreakfast,
  });

  let returnValue = null;

  switch (status) {
    case ItemAvailabilityStatus.AVAILABLE:
    case ItemAvailabilityStatus.STORE_NOT_SELECTED:
      break;
    case ItemAvailabilityStatus.OUT_OF_DAYPART:
      returnValue = buildOutOfDayPartEvaluationResult(reward, dayParts);
      break;
    default:
      returnValue = buildVendorEvaluationResult();
  }

  return returnValue;
};

export default useRewardsEvaluation;
