import React from 'react';

import { Box, Link, Text } from '@rbilabs/universal-components';

import Picture from 'components/picture';

import theme from './theme';

const ImageContainer = Box.withConfig({
  width: 'full',
  justifyContent: 'center',
  alignItems: 'center',
  paddingY: '$6',
});

const Image = Picture.withConfig({
  width: '5/6',
  borderRadius: Styles.borderRadius,
});

const ImageCaption = Text.withConfig({
  paddingTop: '$4',
  textAlign: theme.imageWidgetCaptionAlign,
});

const ImageCaptionLink = Link.withConfig({
  color: Styles.color.primary,
  marginRight: '$2',
  fontSize: 'sm',
});

export default class ImageWidget extends React.Component {
  render() {
    return (
      <ImageContainer>
        <Image image={this.props.image?.locale} alt={this.props.caption?.locale ?? ''} />
        {this.props.caption && (
          <ImageCaption>
            {this.props.attributionLink && ( // TODO: RN - ensure styling is correct
              <ImageCaptionLink href={this.props.attributionLink}>Image Source</ImageCaptionLink>
            )}
            {this.props.caption?.locale}
          </ImageCaption>
        )}
      </ImageContainer>
    );
  }
}
