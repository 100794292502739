import { useMenuVariant } from 'experiments/menu-variant/use-menu-variant';
import { IFeatureMenuQuery, useFeatureMenuQuery } from 'generated/sanity-graphql';
import { withSimpleCache } from 'state/graphql/with-simple-cache';

interface IUseFeatureMenuValue {
  menuId: string;
  featureMenu: IFeatureMenuQuery['FeatureMenu'];
  featureMenuLoading: boolean;
}

const FEATURE_MENU_SINGLETON_ID = 'feature-menu-singleton';

const useCachedFeatureMenuQuery = withSimpleCache(useFeatureMenuQuery);

export const useFeatureMenu = (): IUseFeatureMenuValue => {
  const { data, loading } = useCachedFeatureMenuQuery({
    variables: { featureMenuId: FEATURE_MENU_SINGLETON_ID },
  });

  const featureMenu = data?.FeatureMenu ?? null;
  const menuVariant = useMenuVariant(featureMenu);
  const menuId = menuVariant?.menuVariantId || featureMenu?.defaultMenu?._id || '';

  const featureMenuLoading = loading;

  return {
    menuId,
    featureMenu,
    featureMenuLoading,
  };
};
