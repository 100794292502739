import React, { useState } from 'react';

import { Pressable, PressableProps, makeUclComponent } from '@rbilabs/universal-components';

const Container = Pressable.withConfig({
  _web: {
    cursor: 'pointer',
  },
});

const _ClickableContainer = React.forwardRef(
  (props: PressableProps & { activeProps?: PressableProps }, ref) => {
    const [isActive, setIsActive] = useState(false);

    return (
      <Container
        ref={ref}
        {...props}
        {...(isActive ? props.activeProps : {})}
        onTouchStart={() => setIsActive(true)}
        onTouchCancel={() => setIsActive(false)}
        onTouchEnd={() => setIsActive(false)}
      >
        {props.children}
      </Container>
    );
  }
);

export const ClickableContainer = makeUclComponent(_ClickableContainer);
