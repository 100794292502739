import { FC, useMemo, useState } from 'react';

import { Box, Header, Pressable } from '@rbilabs/universal-components';
import { isBefore, parseISO } from 'date-fns';
import { useIntl } from 'react-intl';
import { Linking } from 'react-native';

import Picture from 'components/picture';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useAppStateChangeListener } from 'hooks/use-app-state-change-listener';
import useEffectOnce from 'hooks/use-effect-once';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyUserState } from 'state/loyalty/hooks/use-loyalty-user-state';
import { isActiveWalmartUser } from 'state/loyalty/hooks/utils/walmart';
import { ClickEventComponentNames, CustomEventNames, useMParticleContext } from 'state/mParticle';
import { portalToFooter } from 'state/mobile-footer';
import { portalToReplaceHeader } from 'state/mobile-header-nav';
import { routes } from 'utils/routing';

import { useLoyaltyWalmart } from '../walmart/use-loyalty-walmart';

import { CardsContainer, LogoContainer, StyledBackIcon, StyledBox } from './styled';
import { UnlinkWalmartUserModal } from './unlink-walmart-user-modal';
import { WalmartDistributionOfferItem } from './walmart-distribution-offer-item';
import { WalmartOfferItem } from './walmart-offer-item';

const TIME_TO_REFETCH_LOYALTY_USER_AFTER_UNLINK = 3000;

export const WalmartOffer: FC<React.PropsWithChildren<{}>> = () => {
  const enableWalmartPlusIntegration = useFlag(LaunchDarklyFlag.ENABLE_WALMART_PLUS_TRIAL_OFFER);
  const enableWalmartUnlinkFlow = useFlag(LaunchDarklyFlag.ENABLE_UNLINK_WALMART_FLOW);
  const { logButtonClick } = useMParticleContext();
  const { formatMessage } = useIntl();
  const { closeModalAuth, setAccountCallbackUrl } = useAuthContext();
  const { navigate } = useNavigation();
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);
  const [isVerifyingUnlink, setIsVerifyingUnlink] = useState(false);
  const {
    loyaltyUser,
    loading: isLoadingLoyaltyUser,
    refetch: refetchLoyaltyUser,
  } = useLoyaltyUserState();
  const isWalmartUser = isActiveWalmartUser(loyaltyUser);
  const { data, loading } = useLoyaltyWalmart();
  const { addAppStateChangeListeners, removeAppStateChangeListeners } = useAppStateChangeListener();

  const showDistributionOfferCard = useMemo(() => {
    // The Distribution Offer module is only shown to users who were linked
    // before the distributionOfferDate
    const distributionOfferDateStr = data?.partnershipPage?.distributionOfferDate;
    const userJoinedAtStr = loyaltyUser?.partnerUserData?.find(
      data => data.partner === 'WALMART' && data.status === 'ACTIVE'
    )?.createdAt;

    if (distributionOfferDateStr && userJoinedAtStr) {
      const distributionOfferDateDate = parseISO(distributionOfferDateStr);
      const userJoinedAtDate = parseISO(userJoinedAtStr);
      return isBefore(userJoinedAtDate, distributionOfferDateDate);
    }
    return false;
  }, [data?.partnershipPage?.distributionOfferDate, loyaltyUser?.partnerUserData]);

  const handleActivateAccount = () => {
    logButtonClick({
      attributes: {
        name: CustomEventNames.ACTIVATE_WALMART_PLUS_ACCOUNT,
        component: ClickEventComponentNames.BUTTON,
      },
    });
    navigate(routes.walmart);
  };

  const handleRefetchLoyaltyUserAfterUnlink = async (retry: boolean = true) => {
    setIsVerifyingUnlink(true);
    await refetchLoyaltyUser();

    if (!isWalmartUser) {
      removeAppStateChangeListeners();
      setIsVerifyingUnlink(false);
    } else if (retry) {
      // If the user is still linked, we try to verify once again after a few seconds.
      setTimeout(
        () => handleRefetchLoyaltyUserAfterUnlink(false),
        TIME_TO_REFETCH_LOYALTY_USER_AFTER_UNLINK
      );
    } else {
      setIsVerifyingUnlink(false);
    }
  };

  const handleRedirectToUnlink = () => {
    const unlinkWalmartSanityUrl = data?.walmartUnlinkUrl;

    if (unlinkWalmartSanityUrl) {
      /**
       * Before redirecting the user, we add an event listener to detect when the user
       * returns to the app. This way, we can fetch the loyaltyUser again and verify that
       * the user was successfully unlinked.
       */
      addAppStateChangeListeners(handleRefetchLoyaltyUserAfterUnlink);
      Linking.openURL(unlinkWalmartSanityUrl);
    }
  };

  useEffectOnce(() => {
    return () => {
      // TODO: Consider closing the modal using state in the useAuth context on mount.
      closeModalAuth();
    };
  });

  const handleClickWalmartOfferItemCard = () => {
    if (!isWalmartUser) {
      return;
    }

    navigate(enableWalmartPlusIntegration ? routes.offers : routes.walmart);
  };

  const WalmartLogo = () => (
    <LogoContainer>
      <Picture
        width="$24"
        image={data?.logo?.locale}
        alt={formatMessage({ id: 'deliveryLogoAlt' })}
      />
    </LogoContainer>
  );

  const AppHeader = portalToReplaceHeader(() => {
    return (
      <Box _ios={{ marginTop: '$12' }} _android={{ marginTop: '$3' }} marginTop={'$0'}>
        <Pressable
          onPress={() => {
            setAccountCallbackUrl(routes.base);
            navigate(routes.account);
          }}
          padding="$4"
        >
          <StyledBackIcon variant="back" />
        </Pressable>
        <Header alignSelf="center" variant="headerTwo" top="$4" position="absolute">
          {formatMessage({ id: 'partnerOffers' })}
        </Header>
      </Box>
    );
  });

  const AppFooter = portalToFooter(() => null);

  return (
    <>
      <AppHeader />
      {(!loading && !isLoadingLoyaltyUser && (
        <CardsContainer>
          <StyledBox>
            <Pressable onPress={handleClickWalmartOfferItemCard}>
              <WalmartOfferItem
                name={formatMessage({ id: 'partnershipWalmartTitle' })}
                description={formatMessage({ id: 'partnershipWalmartDescription' })}
                descriptionL2={
                  enableWalmartPlusIntegration && !isWalmartUser
                    ? formatMessage({ id: 'walmartPlusMembershipDiscount' })
                    : undefined
                }
                image={<WalmartLogo />}
                activateButtonLabel={formatMessage({ id: 'activate' })}
                linkedButtonLabel={formatMessage({ id: 'active' })}
                onPartnershipActivate={handleActivateAccount}
                isLoadingUser={isLoadingLoyaltyUser}
                isWalmartUser={isWalmartUser}
                showUnlinkButton={enableWalmartUnlinkFlow}
                unlinkButtonLabel={formatMessage({ id: 'walmartDeactivateButton' })}
                onUnlinkUser={() => setShowUnlinkModal(true)}
                isUnlinkingUser={isVerifyingUnlink}
              />
            </Pressable>
          </StyledBox>

          {enableWalmartPlusIntegration && isWalmartUser && showDistributionOfferCard && (
            <StyledBox>
              <Pressable>
                <WalmartDistributionOfferItem
                  name={formatMessage({ id: 'royalPerksMembers' })}
                  description={formatMessage({ id: 'royalPerksMembershipDiscount' })}
                  image={<WalmartLogo />}
                  onConfirm={() => navigate(routes.walmart)}
                  confirmButtonLabel={formatMessage({ id: 'learnMore' })}
                />
              </Pressable>
            </StyledBox>
          )}
        </CardsContainer>
      )) ||
        null}
      <AppFooter />
      <UnlinkWalmartUserModal
        open={showUnlinkModal}
        onConfirm={handleRedirectToUnlink}
        onClose={() => setShowUnlinkModal(false)}
      />
    </>
  );
};
