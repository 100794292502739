import { ModalContent } from 'components/modal';

export const ContentContainer = ModalContent.withConfig({
  paddingX: {
    base: '6',
    md: '15%',
  },
  paddingTop: {
    md: '12',
  },
  paddingBottom: {
    md: '8',
  },
});
