import { useEffect, useRef } from 'react';

import { Nullable } from '@rbi-ctg/frontend';

const useDebouncedEffect = (debouncedEffect: () => void, delay: number, inputs: unknown[]) => {
  const timeoutRef = useRef<Nullable<number>>();

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    timeoutRef.current = window.setTimeout(debouncedEffect, delay);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, inputs); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useDebouncedEffect;
