import * as React from 'react';

import { Divider, HStack, Skeleton, VStack } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';

const QuestStepSkeleton = () => (
  <HStack space="$4" alignItems="center" width="100%">
    <Skeleton h="$13.5" w="$20" />
    <Skeleton.Text lines={2} flexShrink={1} />
  </HStack>
);

const QuestIncentiveSkeleton = () => (
  <VStack padding="$4" backgroundColor={theme.token('background-default')} borderRadius="xl">
    <HStack space="$4" alignItems="center">
      <Skeleton h="$16" w="$16" rounded="full" />
      <Skeleton.Text lines={2} flexShrink={1} />
    </HStack>
  </VStack>
);

export const QuestDrawerSkeleton = () => (
  <VStack space="$6" padding="$4">
    <Skeleton.Text />
    <VStack
      space="$8"
      padding="$4"
      backgroundColor={theme.token('background-default')}
      borderRadius="xl"
    >
      <Skeleton.Text lines={2} />
      <VStack padding="$2" space="$2">
        <QuestStepSkeleton />
        <QuestStepSkeleton />
        <QuestStepSkeleton />
      </VStack>
    </VStack>
    <QuestIncentiveSkeleton />
    <Divider />
    <Skeleton.Text lines={4} />
  </VStack>
);
