import React, { useCallback, useEffect } from 'react';

import { Box, Header, Icon, ScrollView } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { ActionsheetContent, ActionsheetWrapper } from 'components/action-sheet';
import { SearchBar, StoreCardList } from 'pages/store-locator/components';
import { useSearchRestaurants } from 'pages/store-locator/hooks/use-search-restaurants';
import { useSearching } from 'pages/store-locator/hooks/use-searching';
import { useStoresReducer } from 'pages/store-locator/hooks/use-stores-reducer';
import { useGeolocation } from 'state/geolocation';
import { OrderLocatorTab } from 'state/launchdarkly/variations';
import { isValidPosition } from 'utils/geolocation';
import noop from 'utils/noop';

import {
  HeaderWrapper,
  IconWrapper,
  SearchBarLabel,
  SearchBarWrapper,
  SearchContainer,
} from './styled';
import { IFavoriteStoreSearch } from './types';

export const FavoriteStoreSearch = ({ isOpen, onClose, onStoreSelect }: IFavoriteStoreSearch) => {
  const { formatMessage } = useIntl();
  const {
    errorNearby,
    searchingNearby,
    storesNearby,
    storeLocatorDispatch,
    rawIsSearching,
  } = useStoresReducer();

  const { isSearchingNearby } = useSearching({
    searchingNearby,
    searchingRecent: false,
    searchingFavs: false,
    rawIsSearching,
  });

  const { searchNearbyRestaurants } = useSearchRestaurants({
    storeLocatorDispatch,
  });

  const {
    activeCoordinates,
    manualAddress,
    setManualAddress,
    updateManualCoordinates,
  } = useGeolocation();

  const onSearchBarValueChange = useCallback(
    (address: string | undefined | null) => {
      setManualAddress(address || '');
    },
    [setManualAddress]
  );

  const handleFavoriteSelect = (storeId = '', storeNumber = '', storeAddress = '') => {
    onStoreSelect({ storeId, storeNumber, storeAddress });
    setManualAddress('');
    updateManualCoordinates('');
    onClose();
  };

  useEffect(() => {
    if (isValidPosition(activeCoordinates)) {
      searchNearbyRestaurants({
        location: activeCoordinates,
      });
    }
  }, [activeCoordinates, searchNearbyRestaurants]);

  return (
    <ActionsheetWrapper onOpenEventMessage="Scan Successful" isOpen={isOpen} onClose={onClose}>
      <ActionsheetContent width="full">
        <ScrollView
          contentContainerStyle={{ flexGrow: 1 }}
          height="full"
          width="full"
          keyboardShouldPersistTaps="handled"
        >
          <HeaderWrapper>
            <IconWrapper onPress={onClose}>
              <Icon variant="back" color="token.icon-default" />
            </IconWrapper>
            <Header variant="headerTwo" justifyContent="center" alignItems="center">
              {formatMessage({ id: 'searchLocation' })}
            </Header>
            <Box />
          </HeaderWrapper>
          <SearchContainer>
            <SearchBarWrapper>
              <SearchBarLabel>{formatMessage({ id: 'location' })}</SearchBarLabel>
              <SearchBar
                value={manualAddress}
                onInputValueChange={onSearchBarValueChange}
                onAddressSelected={updateManualCoordinates}
              />
            </SearchBarWrapper>
          </SearchContainer>
          <Box flex={1} marginTop="$8" paddingX="0">
            <StoreCardList
              tab={OrderLocatorTab.NEARBY}
              currentTabHasError={!!errorNearby}
              restaurants={storesNearby || []}
              isSearching={isSearchingNearby}
              onCardPress={handleFavoriteSelect}
              setRestaurantFocused={noop}
            />
          </Box>
        </ScrollView>
      </ActionsheetContent>
    </ActionsheetWrapper>
  );
};
