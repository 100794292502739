import { Box, Header as HeaderBase } from '@rbilabs/universal-components';

import Link from 'components/link';

export const SectionContainer = Box.withConfig({
  width: 'full',
  paddingTop: '$4',
  marginX: 'auto',
  marginBottom: '$5',
  minHeight: '$4',
  paddingX: '$4',
});

export const Header = HeaderBase.withConfig({
  variant: 'headerOne',
  margin: 0,
  focusable: true,
});

export const HeaderLink = Link.withConfig({
  position: 'absolute',
  right: 0,
  bottom: 0,
  _text: {
    fontSize: 'sm',
    textTransform: 'capitalize',
  },
});

export const HeaderTextWrapper = Box.withConfig({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '$2',
});

export const IconWrapper = Box.withConfig({
  marginLeft: '$2',
});
