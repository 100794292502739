import { useMemo } from 'react';

import { Region } from 'utils/environment/types';
import { IValidationConfig } from 'utils/form/user-profile-validation-config/types';

export const useFieldIsRequired = (
  region: Region,
  validationConfig: IValidationConfig,
  fieldName: string
) => {
  const fieldIsRequired = useMemo(() => {
    const required = validationConfig[fieldName].required;

    return typeof required === 'boolean' ? required : required.locales.includes(region);
  }, [region, validationConfig]);

  return fieldIsRequired;
};
