import React, { FC } from 'react';

import { makeUclComponent } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { LoyaltyPromotionType } from 'generated/graphql-gateway';
import { useCart } from 'hooks/use-cart';
import { IIncentiveEvaluationResult } from 'state/loyalty/hooks/types';
import { getEvaluationResultMessage } from 'state/loyalty/hooks/utils/helpers';

import {
  IncentiveEvaluationMessagesContainer,
  SingleMessage,
} from './incentive-evaluation-messages.styled';

interface IIncentiveEvaluationMessageContentProps {
  message?: React.ReactNode;
  hideIcon?: boolean;
  isSmall?: boolean;
}

const IncentiveEvaluationMessageContent = ({
  message,
  isSmall,
}: IIncentiveEvaluationMessageContentProps) => {
  return <SingleMessage message={message} status="info" size={isSmall ? 'sm' : 'md'} />;
};

interface IIncentiveEvaluationMessageProps {
  message?: React.ReactNode;
  hideIcon?: boolean;
  isSmall?: boolean;
}

const _IncentiveEvaluationMessage: FC<React.PropsWithChildren<
  IIncentiveEvaluationMessageProps
>> = ({ message = '', hideIcon = false, isSmall = false }) => {
  return (
    <IncentiveEvaluationMessagesContainer>
      <IncentiveEvaluationMessageContent message={message} hideIcon={hideIcon} isSmall={isSmall} />
    </IncentiveEvaluationMessagesContainer>
  );
};

interface IIncentiveEvaluationMessagesProps {
  incentiveEvaluationResults: IIncentiveEvaluationResult[];
  hideIcon?: boolean;
  isSmall?: boolean;
}

const _IncentiveEvaluationMessages: FC<React.PropsWithChildren<
  IIncentiveEvaluationMessagesProps
>> = ({ incentiveEvaluationResults = [], hideIcon = false, isSmall = false }) => {
  const formatters = useIntl();
  const { cartEntries } = useCart();

  return (
    <IncentiveEvaluationMessagesContainer>
      {incentiveEvaluationResults.map(evaluationResult => {
        const message = getEvaluationResultMessage({
          evaluationResult,
          formatters,
          incentiveType: LoyaltyPromotionType.OFFER,
          cartEntries,
        });

        return (
          <IncentiveEvaluationMessageContent
            key={evaluationResult.code}
            hideIcon={hideIcon}
            message={message}
            isSmall={isSmall}
          />
        );
      })}
    </IncentiveEvaluationMessagesContainer>
  );
};

export const IncentiveEvaluationMessage = makeUclComponent(_IncentiveEvaluationMessage);
export const IncentiveEvaluationMessages = makeUclComponent(_IncentiveEvaluationMessages);
