import type { ComponentType } from 'react';

import { makeNBComponent } from '../make-nb-component';
import { addWithConfig } from '../with-config';

/**
 * In UCL. Exported
 */
export const makeUclComponent = <P extends object>(
  component: ComponentType<P>,
  themeName?: string
) => addWithConfig(makeNBComponent(component, themeName));
