import { datadogRum } from '@datadog/browser-rum';

import {
  brand,
  env,
  getConfigValue,
  getCurrentCommitHash,
  platform,
  region,
} from 'utils/environment';

import { SESSION_SAMPLE_RATE } from './constants';

export const initDatadogRum = () => {
  const dataDogConfig = getConfigValue({ key: 'datadog', region, defaultValue: { rum: {} } });
  const awsConfig = getConfigValue({ key: 'aws', defaultValue: {} });

  const applicationId = dataDogConfig.rum.applicationId;
  const clientToken = dataDogConfig.rum.clientToken;
  const datacenter = dataDogConfig.rum.dataCenter || 'us';
  const allowedTracingOrigins = [awsConfig.gqlApiUrl, awsConfig.gqlGatewayApiUrl];
  const currentEnv = env();

  datadogRum.init({
    applicationId,
    clientToken,
    datacenter,
    service: `${brand()}_${platform()}`,
    env: currentEnv,
    version: getCurrentCommitHash(),
    sampleRate: SESSION_SAMPLE_RATE,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins,
  });
};
