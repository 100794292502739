import type { ComponentProps, ComponentType, ElementRef } from 'react';
import { forwardRef, useRef } from 'react';

import { isEmpty, isObject } from 'lodash-es';
import { StyledProps, usePropsResolution, useStyledSystemPropsResolver } from 'native-base';
import { PlatformProps } from 'native-base/lib/typescript/components/types';
import isEqual from 'react-fast-compare';

import type { ForwardRefReturnType } from '../react';

export type NBStyledComponentProps<
  C extends ComponentType,
  ExtraProps = StyledProps & PlatformProps<ComponentProps<C> & StyledProps>
> = Omit<ComponentProps<C>, keyof ExtraProps> & ExtraProps;

const emptyObject = {};

/**
 * Makes a NativeBase compatible styled component from any component
 * that accepts a `styled` prop.
 *
 * ```tsx
 * const CustomView = makeNBComponent(View);
 *
 * <CustomView p="6"/>
 * ```
 */
export const makeNBComponent = <C extends ComponentType<any>>(
  Comp: C,
  themeName = 'fake_component'
): ForwardRefReturnType<ElementRef<C>, NBStyledComponentProps<C>> => {
  return forwardRef<ElementRef<C>, NBStyledComponentProps<C>>(
    (props: NBStyledComponentProps<C>, ref: any) => {
      const resolvedProps = usePropsResolution(themeName as any, props);
      const [style, restProps] = useStyledSystemPropsResolver(resolvedProps);

      // lets not store the same emptyObject in memory for no reason
      const styleRef = useRef(isEmptyObject(style) ? emptyObject : style);

      // only change style if it really changes, unfortunately the way we pass styles as
      // props causes them to change when they aren't actually changing causing re-renders
      const stylesEqual = isEqual(styleRef.current, style);
      if (!stylesEqual) {
        styleRef.current = style;
      }

      // dataSet is never set from what I can tell so don't pass, have object equality change if its empty
      const dataSet = isEmptyObject(restProps.dataSet) ? undefined : restProps.dataSet;

      return <Comp {...restProps} style={styleRef.current} ref={ref} dataSet={dataSet} />;
    }
  );
};

const isEmptyObject = (o: object) => o && isObject(o) && isEmpty(o);
