import Constants from 'expo-constants';
import { MMKV } from 'react-native-mmkv';

import { Keys } from 'utils/local-storage/constants';

import { Region } from './types';

interface IGetConfigValueProps {
  key: string;
  defaultValue?: any;
  region?: Region;
  disallowEnvPickerValue?: boolean;
}

/**
 * @note
 * !!DO NOT USE THIS FUNCTION DIRECTLY!!
 * should use `useConfigValue` hook instead where possible
 * this function should only be used directly by `useConfigValue`
 *  and in cases where LocaleProvider is not yet available
 *  (ex. LDProvider)
 */
export const getConfigValue = ({
  key,
  region,
  defaultValue = '',
  disallowEnvPickerValue = false,
}: IGetConfigValueProps): any => {
  const configDataLocationOne = Constants.manifest2?.extra?.expoClient?.extra?.eas;
  const configDataLocationTwo = Constants.expoConfig?.extra?.eas;
  let actualValue = null;
  const pickedEnv = disallowEnvPickerValue || !allowEnvPicker ? null : pickedEnvConfig;
  if (region) {
    actualValue =
      pickedEnv?.[region]?.[key] ??
      configDataLocationOne?.[region]?.[key] ??
      configDataLocationTwo?.[region]?.[key];
  } else {
    actualValue = pickedEnv?.[key] ?? configDataLocationOne?.[key] ?? configDataLocationTwo?.[key];
  }
  const result = actualValue || defaultValue;
  return result;
};

export const allowEnvPicker = getConfigValue({
  key: 'allowEnvPicker',
  disallowEnvPickerValue: true,
});

// we have to use MMKV to store these values because it is not async. AsyncStorage gets it's data too late to affect key config values at runtime
// don't initialize MMKV when picker isn't allowed (aka real prod) since it wont be used
export const mmkvStorage = allowEnvPicker ? new MMKV() : null;

export const pickedEnvConfig = JSON.parse(
  mmkvStorage?.getString(Keys.INTERNAL_BUILD_ONLY_ENV_PICKER_CONFIG) || 'null'
);

export const shouldShowEnvPickerAtStart = () => allowEnvPicker && !pickedEnvConfig;

export const setPickedEnvConfig = (envConfig: object) =>
  mmkvStorage?.set(Keys.INTERNAL_BUILD_ONLY_ENV_PICKER_CONFIG, JSON.stringify(envConfig));
