import { IFeatureDisclaimersQuery, useFeatureDisclaimersQuery } from 'generated/sanity-graphql';

interface IUseFeatureDisclaimerValue {
  featureDisclaimerLoading: boolean;
  featureDisclaimers: IFeatureDisclaimersQuery['FeatureDisclaimers'];
}

const FEATURE_DISCLAIMERS_SINGLETON_ID = 'feature-disclaimers-singleton';

export const useFeatureDisclaimers = (): IUseFeatureDisclaimerValue => {
  const { data, loading } = useFeatureDisclaimersQuery({
    variables: { featureDisclaimersId: FEATURE_DISCLAIMERS_SINGLETON_ID },
  });

  const featureDisclaimerLoading = loading;
  const featureDisclaimers = data?.FeatureDisclaimers ?? null;

  return {
    featureDisclaimerLoading,
    featureDisclaimers,
  };
};
