import { useCallback, useState } from 'react';

import { useIntl } from 'react-intl';

import { sanitizeNumber } from 'utils/form';
import { initialErrorState, isGiftCardNumberValid } from 'utils/payment';

const isValidGiftCardNumberCheck = (newGiftCardNumber: string) =>
  isGiftCardNumberValid(sanitizeNumber(newGiftCardNumber));

export const useCardNumber = () => {
  const { formatMessage } = useIntl();
  const [giftCardNumber, setGiftCardNumber] = useState('');
  const [errors, setErrors] = useState(initialErrorState);
  const [isValidGiftCardNumber, setIsValidGiftCardNumber] = useState(false);

  const handleCardNumberChange = useCallback(
    (newGiftCardNumber: any) => {
      const formattedNumber = sanitizeNumber(newGiftCardNumber);
      setGiftCardNumber(formattedNumber);

      const isValid = isValidGiftCardNumberCheck(newGiftCardNumber);
      let giftCardNumberErrorMessage: string | undefined;
      if (!isValid) {
        giftCardNumberErrorMessage = newGiftCardNumber
          ? formatMessage({ id: 'giftCardNumberIsNotValid' })
          : formatMessage({ id: 'giftCardNumberIsRequired' });
      }
      setErrors(prevState => {
        return {
          ...prevState,
          giftCardNumber: giftCardNumberErrorMessage,
        };
      });
      setIsValidGiftCardNumber(isValid);
    },
    [formatMessage]
  );

  return {
    giftCardNumber,
    errors,
    isValidGiftCardNumber,
    handleCardNumberChange,
    isValidGiftCardNumberCheck,
  };
};
