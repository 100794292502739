import { Box, HStack, Icon, Pressable, Text } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

export const Container = HStack.withConfig({
  alignItems: 'center',
});

export const ClickableWrapper = Pressable.withConfig({
  flexDirection: 'row',
  alignItems: 'center',
  height: '$9',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
  marginRight: '$1',
  _web: {
    cursor: 'pointer',
  },
});

export const IconContainer = Box.withConfig({
  marginLeft: { base: 0, md: '$1' },
  marginRight: '$2',
});

export const DetailsContainer = HStack.withConfig({
  alignItems: 'center',
  flex: 1,
});

export const Heading = Text.withConfig<{ reversedUI: boolean }>(p => ({
  fontFamily: 'heading',
  numberOfLines: 1,
  fontSize: 'md',
  color: p.reversedUI ? theme.token('text-reversed') : theme.token('text-default'),
}));

export const Details = Text.withConfig<{ reversedUI: boolean }>(p => ({
  flex: 1,
  numberOfLines: 1,
  overflow: 'hidden',
  paddingLeft: '$2',
  fontSize: 'md',
  maxWidth: '2/3',
  marginRight: {
    md: '$2',
  },
  paddingRight: '$1',
  color: p.reversedUI ? theme.token('text-reversed') : theme.token('text-default'),
  _web: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export const ButtonWrapper = Box.withConfig({});

export const IconStyled = Icon.withConfig({
  size: '5',
  ...hiddenAccessibilityPlatformProps,
});

export const ArrowRightIcon = Icon.withConfig({
  variant: 'back',
  size: {
    base: 'xs',
    md: 'sm',
  },
  mt: {
    base: '$0.5',
    md: '$1',
  },
  style: {
    transform: [{ rotate: '180deg' }],
  },
});
