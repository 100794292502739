import * as React from 'react';
import { useCallback } from 'react';

import { Icon, Text } from '@rbilabs/universal-components';

import { PressableItemCard } from 'components/quests/components/pressable-item-card';
import { useQuestContext } from 'components/quests/loyalty-quest';
import { isQuestCompleted } from 'components/quests/loyalty-quest/utils';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { primitive } from 'styles/constants/primitives';

import { IQuestItemIncentiveProps } from './types';

const PressableItem = PressableItemCard.withConfig({
  background: primitive.$white,
  borderRadius: 8,
});

export const QuestItemIncentive = ({
  title,
  image,
  altImage,
  price,
  name,
  linkTo: link,
  onCloseDrawer,
}: IQuestItemIncentiveProps) => {
  const { quest } = useQuestContext();
  const { linkTo } = useNavigation();
  const questCompleted = isQuestCompleted(quest.status);
  const onPressHandler = useCallback(() => {
    onCloseDrawer();
    linkTo(link);
  }, [link, linkTo]);

  return (
    <>
      <Text variant="headerThree" marginBottom="$4" color={primitive.$white}>
        {title}
      </Text>

      <PressableItem
        onPress={onPressHandler}
        name={name}
        price={price}
        image={image}
        altImage={altImage}
        icon={<Icon size="5" variant={questCompleted ? 'forward' : 'locked'} />}
        disabled={!questCompleted}
      />
    </>
  );
};
