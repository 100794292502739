import React, { Dispatch, SetStateAction } from 'react';

import { useIntl } from 'react-intl';
import { SceneRendererProps } from 'react-native-tab-view';

import { IRestaurant } from '@rbi-ctg/store';
import { TabView } from 'components/tab-view';
import { useIsDesktopBreakpoint } from 'hooks/use-media-query';
import useStoreLocatorTabs from 'pages/store-locator/hooks/use-store-locator-tabs';
import { useEnableMapListExperiment } from 'pages/store-locator/use-enable-map-list-experiment';
import { OrderLocatorTab } from 'state/launchdarkly/variations';
import { primitive } from 'styles/constants/primitives';

import { useSearching } from '../../hooks/use-searching';
import { StoreCardList } from '../store-card-list';

import { StoreCardListContainer } from './list-view.styled';

const intlIdMap: { [K in OrderLocatorTab]: string } = {
  [OrderLocatorTab.NEARBY]: 'nearby',
  [OrderLocatorTab.RECENT]: 'recents',
  [OrderLocatorTab.FAVORITE]: 'favorite',
  [OrderLocatorTab.DELIVERY]: 'delivery',
};

interface IListViewProps {
  errorFavs: null | Error;
  errorNearby: null | Error;
  errorRecent: null | Error;
  searchingNearby: boolean;
  searchingRecent: boolean;
  searchingFavs: boolean;
  rawIsSearching: boolean | undefined;
  storesFavs?: IRestaurant[];
  storesNearby?: IRestaurant[];
  storesRecent?: IRestaurant[];
  handleSelectStore?: (storeId: string) => void;
  isInSupportForm?: boolean;
  restaurantFocused?: IRestaurant;
  mapRestaurantFocused?: IRestaurant;
  setRestaurantFocused: Dispatch<SetStateAction<IRestaurant | undefined>>;
}

export const ListView: React.FC<IListViewProps> = ({
  errorFavs,
  errorNearby,
  errorRecent,
  searchingNearby,
  searchingRecent,
  rawIsSearching,
  searchingFavs,
  storesFavs = [],
  storesNearby = [],
  storesRecent = [],
  handleSelectStore,
  isInSupportForm,
  restaurantFocused,
  setRestaurantFocused,
  mapRestaurantFocused,
}) => {
  const { isSearchingFavorites, isSearchingNearby, isSearchingRecent } = useSearching({
    searchingNearby,
    searchingRecent,
    searchingFavs,
    rawIsSearching,
  });
  const { formatMessage } = useIntl();
  const isDesktop = useIsDesktopBreakpoint();
  const enableMapListExperiment = useEnableMapListExperiment();

  const {
    currentTabHasError,
    currentTabIndex,
    currentTabKey,
    handleTabChange,
    locatorTabs,
  } = useStoreLocatorTabs({ errorFavs, errorNearby, errorRecent });

  const storesMap: {
    [K in OrderLocatorTab]: IRestaurant[];
  } = {
    [OrderLocatorTab.NEARBY]: storesNearby,
    [OrderLocatorTab.RECENT]: storesRecent,
    [OrderLocatorTab.FAVORITE]: storesFavs,
    [OrderLocatorTab.DELIVERY]: [],
  };

  const searchingMap: {
    [K in OrderLocatorTab]: boolean;
  } = {
    [OrderLocatorTab.NEARBY]: isSearchingNearby,
    [OrderLocatorTab.RECENT]: isSearchingRecent,
    [OrderLocatorTab.FAVORITE]: isSearchingFavorites,
    [OrderLocatorTab.DELIVERY]: false,
  };

  const tabs = locatorTabs.map(key => ({
    key,
    title: formatMessage({ id: intlIdMap[key] }),
  }));

  // As we need to pass additional props, we have to use a custom renderScene function instead of SceneMap
  // Do not return inline functions because  If you pass inline functions, it'll re-create the component every render, which will cause the entire route to unmount and remount every change. It's very bad for performance and will also cause any local state to be lost.
  const renderScene = ({ route }: SceneRendererProps & { route: { key: OrderLocatorTab } }) => {
    // Setting display none for desktop we ensure the non current tabs will be completeley disable in terms of accessibility.
    // With this hack we ensure the user will be able to properly navigate the desktop site using the keyboard.
    const desktopDisplay = isDesktop && route.key !== currentTabKey ? 'none' : 'flex';
    return (
      <StoreCardListContainer
        display={desktopDisplay}
        backgroundColor={enableMapListExperiment ? primitive.$white : undefined}
      >
        <StoreCardList
          tab={route.key}
          currentTabHasError={currentTabHasError}
          restaurants={storesMap[route.key]}
          isSearching={searchingMap[route.key]}
          handleSelectStore={handleSelectStore}
          isInSupportForm={isInSupportForm}
          restaurantFocused={restaurantFocused}
          setRestaurantFocused={setRestaurantFocused}
          mapRestaurantFocused={mapRestaurantFocused}
        />
      </StoreCardListContainer>
    );
  };

  return (
    <TabView
      activeTabIndex={currentTabIndex}
      handleTabIndexChange={(index: number) => handleTabChange(index)}
      tabs={tabs}
      // @ts-expect-error TS(2322) FIXME: Type '({ route }: SceneRendererProps & { route: { ... Remove this comment to see the full error message
      renderScene={renderScene}
      backgroundColor={enableMapListExperiment ? primitive.$white : undefined}
    />
  );
};
