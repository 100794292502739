import React from 'react';

import { Header, Text, VStack } from '@rbilabs/universal-components';
import { Linking } from 'react-native';

import ActionButton from 'components/action-button';
import Picture from 'components/picture';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { isExternalLink } from 'utils/is-external-link';

const CallToActionHeaderContainer = VStack;

const CallToActionHeading = Header.withConfig({
  variant: 'headerTwo',
  textAlign: 'center',
  marginTop: '$4',
});

const CallToActionSubheading = Header.withConfig({
  variant: 'headerThree',
  textAlign: 'center',
  color: Styles.color.primary,
  fontWeight: 'normal',
});

const CallToActionBody = Text.withConfig({
  marginBottom: '$8',
});

const Image = Picture.withConfig({
  width: '66%',
  objectFitContain: true,
  marginX: 'auto',
});

const CallsToAction = VStack.withConfig({
  width: '90%',
  maxWidth: '920px',
  marginTop: '0',
  marginX: 'auto',
  space: '$16',
  marginBottom: '$16',
});

const CallToAction = VStack.withConfig({
  width: '100%',
});

export default function CallToActionWidget(props) {
  const { navigate } = useNavigation();
  const navigateToUrl = url => {
    if (isExternalLink(url)) {
      Linking.openURL(url);
    } else {
      navigate(url);
    }
  };

  return (
    <CallsToAction>
      {props.callToActionContent.map((cta, index) => {
        return (
          <CallToAction key={index}>
            {cta.image && <Image objectFitContain image={cta.image?.locale} alt="" />}
            <CallToActionHeaderContainer>
              {cta.heading && <CallToActionHeading>{cta.heading?.locale}</CallToActionHeading>}
              {cta.subheading?.locale && (
                <CallToActionSubheading>{cta.subheading?.locale}</CallToActionSubheading>
              )}
              {cta.body && <CallToActionBody>{cta.body?.locale}</CallToActionBody>}
              {cta.buttonText && (
                <ActionButton
                  fullWidth
                  variant="solid"
                  color="primary"
                  onPress={() => navigateToUrl(cta.link?.locale)}
                  wrapText
                >
                  {cta.buttonText?.locale}
                </ActionButton>
              )}
            </CallToActionHeaderContainer>
          </CallToAction>
        );
      })}
    </CallsToAction>
  );
}
