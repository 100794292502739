export const storeFavoriteClosedMarkerSvg = `
<svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 24.3098C19.5228 24.3098 24 19.8326 24 14.3098C24 8.78691 19.5228 4.30975 14 4.30975C8.47715 4.30975 4 8.78691 4 14.3098C4 19.8326 8.47715 24.3098 14 24.3098Z"
      fill="#FFFFFF"
    />
  </g>
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13.8694 21.5348L8.03192 15.5223C6.95928 14.3937 6.69561 12.7197 7.36942 11.316C7.94174 10.0961 9.16566 9.315 10.5132 9.30975C11.2454 9.3093 11.9581 9.54613 12.5444 9.98475L13.5007 10.6848L13.8694 10.9598L14.2382 10.6848L15.2007 9.98475C15.7851 9.54701 16.4955 9.3102 17.2257 9.30975C18.5668 9.32069 19.7824 10.1011 20.3507 11.316C21.0343 12.7121 20.7857 14.3852 19.7257 15.5223L13.8694 21.5348Z"
    fill="#838383"
  />
</svg>
`;

export const storeFavoriteClosedMarkerSvgRevamp = `<svg width="39" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.5 3.444a10.88 10.88 0 0 0-10.889 10.89C8.611 22.5 19.5 34.555 19.5 34.555S30.389 22.5 30.389 14.333A10.88 10.88 0 0 0 19.5 3.444" fill="#999"/><path d="m19.5 20.556-.902-.784c-3.205-2.77-5.32-4.599-5.32-6.842 0-1.827 1.505-3.263 3.422-3.263a3.8 3.8 0 0 1 2.8 1.24 3.8 3.8 0 0 1 2.8-1.24c1.916 0 3.422 1.436 3.422 3.263 0 2.243-2.115 4.071-5.32 6.848z" fill="#fff"/></svg>`;
