import { PayloadAction } from '@reduxjs/toolkit';

import { IFeatureWalmartUiQuery } from 'generated/sanity-graphql';
import { createAppSlice } from 'state/global-state/utils';

import { IWalmartState } from './walmart.types';

export const initialState: IWalmartState = {
  loyaltyWalmartUI: null,
  loyaltyWalmartUILoading: false,
};

export const loyaltyWalmartSlice = createAppSlice({
  name: 'walmart-ui',
  initialState,
  reducers: {
    setLoyaltyWalmartUI: (state, { payload }: PayloadAction<IFeatureWalmartUiQuery | null>) => {
      state.loyaltyWalmartUI = payload;
    },
    setLoyaltyWalmartUILoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loyaltyWalmartUILoading = payload;
    },
  },
});
