import { useMarketingCardGroupsQuery } from 'generated/sanity-graphql';

export const useMarketingCardGroups = (ids?: string[]) => {
  const marketingCardGroupIds = ids ?? [];
  const { data, loading } = useMarketingCardGroupsQuery({
    variables: {
      where: {
        _id_in: marketingCardGroupIds,
      },
    },
    skip: !marketingCardGroupIds?.length,
  });

  const marketingCardGroups = data?.allMarketingCardGroups;

  return {
    marketingCardGroups,
    marketingCardGroupsLoading: loading,
  };
};
