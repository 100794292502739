import React, { useEffect } from 'react';

import LottieView from 'lottie-react-native';

import { StaticImages, useStaticLottieUri } from 'components/picture/static-image';
import { HapticsNotificationType, hapticNotification } from 'utils/haptic';

const SuccessAnimation = ({ onAnimationFinish }: { onAnimationFinish?: () => void }) => {
  const uri = useStaticLottieUri(StaticImages.LoyaltyOptInAnimation);

  useEffect(() => {
    hapticNotification({ type: HapticsNotificationType.SUCCESS });
  }, []);

  if (!uri) {
    return null;
  }

  return (
    <LottieView source={{ uri }} autoPlay loop={false} onAnimationFinish={onAnimationFinish} />
  );
};

export default SuccessAnimation;
