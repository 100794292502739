import { VStack } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';

export const QuestStepsSectionWrapper = VStack.withConfig({
  padding: '$4',
  space: '$4',
  borderRadius: 'lg',
  borderWidth: '1px',
  borderColor: theme.token('background-contrast-loyalty-reversed'),
});
