import { useEffect } from 'react';

import { useLocale } from 'state/intl';
import { appVersion } from 'utils/app-version-info';

import { Header, setHeaders } from '../headers';

/**
 * Synchronizes dynamic context values with http headers to be sent with each GraphQL request
 */
export const useHeadersSync = () => {
  const { language, region } = useLocale();

  useEffect(() => {
    setHeaders(Header.LANGUAGE, language);
    setHeaders(Header.REGION, region);
    setHeaders(Header.UI_VERSION, appVersion);
  }, [language, region]);
};
