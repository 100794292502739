import { Observable } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { NetInfoState } from '@react-native-community/netinfo';

import {
  addNetworkStatusListener,
  announceApiCallNetworkFailure,
  staticConnectionStatus,
} from 'state/network/status';
import { logger } from 'utils/logger';
import { isBadNetworkError } from 'utils/network/utils';

import { getGqlQueryInfo } from '../utils';

// FIFO limit on bad requests stored to avoid potential unknown/memory situations.
const MAX_BAD_REQUESTS_TO_RETRY = 25;

const retryWhenConnectedCalls: (() => void)[] = [];

/** replay GQL calls when internet is restored to try to salvage the UI */
addNetworkStatusListener(maybeDoRetries);

function maybeDoRetries(info: NetInfoState) {
  if (info.isInternetReachable && retryWhenConnectedCalls.length > 0) {
    while (retryWhenConnectedCalls.length > 0) {
      const retry = retryWhenConnectedCalls.pop();
      retry?.();
    }
  }
}

/** this handler occurs AFTER all retries are exhausted
 * if it is a network error that could be retried after network comes back
 * add to the queue... */
export const retryWhenNetworkRestored = onError(({ operation, networkError: error, forward }) => {
  const { operationType, operationName, isRetryAllowed } = getGqlQueryInfo(operation);

  const getLogCommon = (message: string, extraRetryInfo: object) => ({
    message,
    error,
    retryInfo: {
      ...extraRetryInfo,
      operationName,
      operationType,
      isRetryWhenNetworkRestored: true,
    },
  });

  if (!isRetryAllowed) {
    return;
  }

  if (!isBadNetworkError(error)) {
    return;
  }

  announceApiCallNetworkFailure();

  logger.info(
    getLogCommon(
      `[RetryWhenNetworkRestored] - Adding ${operationType} ${operationName} to the queue`,
      {
        isQueuingRetryWhenNetworkRestored: true,
      }
    )
  );
  return new Observable(observer => {
    if (retryWhenConnectedCalls.length > MAX_BAD_REQUESTS_TO_RETRY) {
      retryWhenConnectedCalls.shift();
    }
    retryWhenConnectedCalls.push(() => {
      logger.info(
        getLogCommon(
          `[RetryWhenNetworkRestored] - Network is back! Retrying ${operationType} ${operationName} from queue.`,
          {
            isDoingRetryWhenNetworkRestored: true,
          }
        )
      );
      forward(operation).subscribe(observer);
    });

    // to avoid a potential race condition where network is back by the time we get here
    // due to potential async nature of apollo middlware/new Observable
    maybeDoRetries(staticConnectionStatus.current);
  });
});
