import React from 'react';

import { Box, Icon } from '@rbilabs/universal-components';

import { RotateAnimatedView } from 'components/ucl/rotate-animated-view';
import { theme } from 'styles/configure-theme';

import { IDimensions, IOrientation, ISize } from './types';

const defaultMobileDimensions: IDimensions = {
  width: 8,
  height: 14,
};
const defaultDesktopDimensions: IDimensions = {
  width: 12,
  height: 21,
};

export const defaultSize: ISize = {
  mobile: defaultMobileDimensions,
  desktop: defaultDesktopDimensions,
};

export const defaultOrientation: IOrientation = {
  initialAngleDeg: 0,
  finalAngleDeg: 90,
};

const maxDimension = (dimension: IDimensions) => Math.max(dimension.height, dimension.width);

interface IArrowContainer {
  dimensionSizes: ISize;
}
const ArrowContainer = Box.withConfig<IArrowContainer>(p => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 1,

  height: {
    base: `${maxDimension(p.dimensionSizes.mobile)}px`,
    md: `${maxDimension(p.dimensionSizes.desktop)}px`,
  },

  width: {
    base: `${maxDimension(p.dimensionSizes.mobile)}px`,
    md: `${maxDimension(p.dimensionSizes.desktop)}px`,
  },
}));

interface IDropdownArrow {
  open: boolean;
  disabled: boolean;
  size: ISize;
  orientation: IOrientation;
}

export const DropdownArrow: React.FC<React.PropsWithChildren<IDropdownArrow>> = ({
  open,
  disabled,
  orientation,
  size,
}) => {
  return (
    <ArrowContainer dimensionSizes={size}>
      <RotateAnimatedView
        isRotated={open}
        fromDeg={orientation.initialAngleDeg}
        toDeg={orientation.finalAngleDeg}
      >
        <Icon
          variant="next"
          color={theme.token(disabled ? 'icon-disabled' : 'icon-default')}
          aria-hidden
        />
      </RotateAnimatedView>
    </ArrowContainer>
  );
};
