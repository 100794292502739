import cssConstants from './constants';

const Styles = {
  ...cssConstants,
};

const _global = typeof window === 'undefined' ? global : window;
_global.Styles = Styles;

export default Styles;
