import { Header, Icon } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

export const StyledBackIcon = Icon.withConfig({
  color: theme.token('icon-header-contrast'),
  size: '5',
  ...hiddenAccessibilityPlatformProps,
});

export const StyledHeader = Header.withConfig({
  alignSelf: 'center',
  textAlign: 'center',
  variant: 'headerTwo',
  top: '$4',
  _android: {
    top: '$7',
  },
  width: '3/4',
  position: 'absolute',
});
