import { createContext, useContext } from 'react';

import { PermissionResponse } from 'expo-modules-core';

export interface IAttPermissionContext {
  isAttPermissionKnown: boolean;
  isLoadingAttPermission: boolean;
  trackingPermissionGranted: boolean;
  requestAttPermission: () => Promise<PermissionResponse>;
}

export const AttPermissionContext = createContext<IAttPermissionContext>(
  {} as IAttPermissionContext
);

export const useAttPermission = () => useContext<IAttPermissionContext>(AttPermissionContext);
