import React, { FC } from 'react';

import { Text, TextProps, addWithConfig } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { StyledRewardPointCost } from './reward-point-cost.styled';

export interface IRewardPointCost extends TextProps {
  pointCost: number;
  usePrimaryColor?: boolean;
}

const RewardPointCostText: FC<React.PropsWithChildren<IRewardPointCost>> = ({
  pointCost,
  usePrimaryColor,
  children,
  ...textProps
}) => {
  const { formatMessage } = useIntl();

  return pointCost === 0 ? (
    <StyledRewardPointCost usePrimaryColor={usePrimaryColor} {...textProps}>
      {formatMessage({ id: 'free' }).toLocaleLowerCase()}
    </StyledRewardPointCost>
  ) : (
    <Text {...textProps}>{children}</Text>
  );
};

export const RewardPointCost = addWithConfig(RewardPointCostText);
