/* eslint-disable global-require */
import { merge } from 'lodash-es';

import { sharedCachedFonts } from '../shared';

export const bkCachedFonts = merge({}, sharedCachedFonts, {
  'Flame-Bold': require('./fonts/FlameBold.ttf'),
  'Flame-Regular': require('./fonts/FlameRegular.ttf'),
  'Flame-RegularSans': require('./fonts/FlameRegularSans.ttf'),
});
