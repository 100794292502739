import { Box } from '@rbilabs/universal-components';

export const Container = Box.withConfig({
  flexDir: 'row',
  width: 'full',
  justifyContent: 'center',
});

export const MeasureBox = Box.withConfig({
  flex: 1,
});

export const CardContainerWrapper = Box.withConfig({
  flexDirection: 'row',
  alignSelf: 'center',
  marginBottom: '$3',
});

const SideButtonWrapped = Box.withConfig({
  flexDirection: 'row',
  width: '$10',
  alignItems: 'center',
});

export const RightButtonWrapper = SideButtonWrapped.withConfig({
  justifyContent: 'flex-end',
});

export const LeftButtonWrapper = SideButtonWrapped.withConfig({
  justifyContent: 'flex-start',
});
