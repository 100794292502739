import { Box } from '@rbilabs/universal-components';

export const MapWrapper = Box.withConfig<{ hide?: boolean }>(p => ({
  backgroundColor: Styles.color.white,
  display: p.hide ? 'none' : 'flex',
  flexGrow: 1,
  flexShrink: 1,
  justifyContent: 'center',
  height: {
    md: 'full',
  },
}));
