import { useFormikContext } from 'formik';
import type { GestureResponderEvent } from 'react-native';

import { addWithConfig } from '../../utils';
import { Button } from '../button';

import type { FormikSubmitButtonProps } from './types';

export const FormikSubmitButton = (props: FormikSubmitButtonProps) => {
  const { children, onPress, isDisabled, ...rest } = props;
  const { isSubmitting, handleSubmit } = useFormikContext();

  const handlePress = (pressEvt: GestureResponderEvent) => {
    handleSubmit();
    onPress?.(pressEvt);
  };

  return (
    <Button
      isLoading={isSubmitting}
      isDisabled={isDisabled ?? isSubmitting}
      onPress={handlePress}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default addWithConfig(FormikSubmitButton);
