import * as React from 'react';
import { useCallback } from 'react';

import { ErrorRemovingLastFavoriteStoreModal } from 'components/modal-error-removing-last-favorite-store';
import { InternalStoreDiagnosticModal } from 'components/store-diagnostic-modal';
import { useFavoriteStore } from 'hooks/favorite-stores';
import { useDistanceText } from 'hooks/geolocation/use-distance-text';
import { LogUserErrorSeverity, useLogUserErrorMessage } from 'hooks/log-user-error-message';
import { useServiceModeStatus } from 'hooks/use-service-mode-status';
import { useNonTraditionalStoreUI } from 'pages/store-locator/hooks/use-non-traditional-store';
import { useStoreActions } from 'pages/store-locator/hooks/use-store-actions';
import { useEnableMapListExperiment } from 'pages/store-locator/use-enable-map-list-experiment';
import { useStoreContext } from 'state/store';

import { NonTraditionalStoreDialogDrawer } from '../non-traditional-store-dialog-drawer';

import { useDiagnostics } from './hooks/use-diagnostics';
import { useErrorMessage } from './hooks/use-error-message';
import { useOpenClosedText } from './hooks/use-open-closed-text';
import { useServiceModeText } from './hooks/use-service-mode-text';
import { Wrapper } from './store-card.styled';
import { StoreCardView as StoreCardViewOrig } from './store-card.view';
import { StoreCardView as StoreCardViewNew } from './store-card.view.experiment';
import { StoreCardProps } from './types';

/**
 * StoreCard container component. This component is intended to be used on the
 * store locator. Data orchestration should happen at this level while keeping
 * the view layer more presentational.
 */
export const StoreCard: React.FC<StoreCardProps> = React.memo(
  ({ restaurant, onCardPress, isSelected, isInMapView, isInSupportForm, handleSelectStore }) => {
    const { distanceText } = useDistanceText({ restaurant });
    const errorMessage = useErrorMessage({ restaurant });
    const { store } = useStoreContext();
    const { restaurantCanBeSelected } = useServiceModeStatus(restaurant);
    const storeIsSelectedOnServiceMode = store._id === restaurant._id;
    const serviceModesText = useServiceModeText({ restaurant });
    const enableMapListExperiment = useEnableMapListExperiment();
    const {
      data: diagnosticsData,
      showModal: showDiagnosticsModal,
      onClick: onDiagnosticsClick,
      onDismiss: showDiagnosticsModalDismiss,
    } = useDiagnostics({ restaurant });

    const { onInfoPress, onOrderPress } = useStoreActions({ restaurant });

    const {
      errorFavoriteStoreDialog,
      favStoreLoading,
      isFavorite,
      toggleFavorite: onFavoriteClick,
      hasErrorRemovingLastFavStore,
      onDismissErrorRemovingLastFavStoreModal,
    } = useFavoriteStore(restaurant._id ?? '', restaurant.number ?? '');

    const { isOpen, isWithinOneHourOfClose, text } = useOpenClosedText(restaurant);

    const nonTraditionalStoreUI = useNonTraditionalStoreUI({ restaurant });

    useLogUserErrorMessage({
      message: errorMessage,
      logWhenTrue: !!errorMessage && isOpen,
      severity: LogUserErrorSeverity.Informational,
      context: {
        storeId: restaurant?.storeId,
      },
    });

    const isActive = (isOpen || isWithinOneHourOfClose) && restaurantCanBeSelected;

    const handleOnCardPress = useCallback(() => onCardPress?.({ restaurant }), [
      isActive,
      onCardPress,
      restaurant,
    ]);

    // Determines whether to pre-fill a store selection form or proceed with a direct order.
    const handleOrderOrSelectStore = () => {
      if (handleSelectStore && restaurant._id) {
        handleSelectStore(restaurant._id);
      } else {
        onOrderPress();
      }
    };

    const handleOnOrderPress = () => {
      // If non-traditional store, prompt user with details before proceeding to order
      if (nonTraditionalStoreUI?.isNonTraditionalStore) {
        nonTraditionalStoreUI.openDrawer();
        return;
      }
      handleOrderOrSelectStore();
    };

    const handleOnConfirmNonTraditionalStoreDialog = () => {
      nonTraditionalStoreUI?.closeDrawer();
      handleOrderOrSelectStore();
    };

    const StoreCardView = enableMapListExperiment ? StoreCardViewNew : StoreCardViewOrig;

    return (
      <Wrapper
        focusable={false}
        testID="store-card"
        isSelected={!enableMapListExperiment && storeIsSelectedOnServiceMode}
      >
        <StoreCardView
          onCardPress={handleOnCardPress}
          onDiagnosticsClick={onDiagnosticsClick}
          onFavoriteClick={onFavoriteClick}
          onInfoPress={onInfoPress}
          onOrderPress={handleOnOrderPress}
          isFavorite={isFavorite}
          isUpdatingFavorites={favStoreLoading}
          isSelected={isSelected}
          storeDetails={{
            serviceModesText,
            distanceText,
            restaurantStatus: {
              isOpen,
              isWithinOneHourOfClose,
              text,
            },
            address: restaurant.physicalAddress?.address1,
            city: `${restaurant.physicalAddress?.city}, ${
              restaurant.physicalAddress?.stateProvinceShort ||
              restaurant.physicalAddress?.stateProvince ||
              ''
            }`,
            isHalal: Boolean(restaurant.isHalal),
            restaurant,
            isActive,
            isNonTraditionalStore: nonTraditionalStoreUI?.isNonTraditionalStore,
            accessInstructions: nonTraditionalStoreUI?.accessInstructions,
          }}
          restaurantCanBeSelected={restaurantCanBeSelected}
          errorMessage={errorMessage}
          isInMapView={isInMapView}
          isInSupportForm={isInSupportForm}
        />
        {showDiagnosticsModal && (
          <InternalStoreDiagnosticModal
            onModalDismiss={showDiagnosticsModalDismiss}
            storeDiagnosticData={diagnosticsData}
          />
        )}
        {hasErrorRemovingLastFavStore && (
          <ErrorRemovingLastFavoriteStoreModal
            onDismiss={onDismissErrorRemovingLastFavStoreModal}
          />
        )}
        {errorFavoriteStoreDialog}
        {nonTraditionalStoreUI && (
          <NonTraditionalStoreDialogDrawer
            onConfirm={handleOnConfirmNonTraditionalStoreDialog}
            onCancel={nonTraditionalStoreUI.closeDrawer}
            isOpen={nonTraditionalStoreUI.isDialogDrawerOpen}
            accessInstructions={nonTraditionalStoreUI.accessInstructions}
          />
        )}
      </Wrapper>
    );
  }
);
