import { IRestaurant } from '@rbi-ctg/store';
import { DeliveryStatus, DeliveryStoreStatus } from 'generated/graphql-gateway';

import { GroqArgs, groqQuery } from './groq';

const projection = `
  _id,
  accessInstructions,
  isNonTraditional,
  chaseMerchantId,
  deliveryHours,
  diningRoomHours,
  curbsideHours,
  drinkStationType,
  driveThruHours,
  driveThruLaneType,
  email,
  fastestServiceMode,
  franchiseGroupId,
  franchiseGroupName,
  frontCounterClosed,
  hasBreakfast,
  hasBurgersForBreakfast,
  hasCurbside,
  hasDineIn,
  hasDelivery,
  hasDriveThru,
  hasMobileOrdering,
  hasParking,
  hasPlayground,
  hasTakeOut,
  hasWifi,
  hasLoyalty,
  isDarkKitchen,
  isHalal,
  isOrderEtaEnabled,
  latitude,
  longitude,
  mobileOrderingStatus,
  name,
  number,
  orderEtaValue,
  parkingType,
  phoneNumber,
  physicalAddress,
  playgroundType,
  pos,
  posRestaurantId,
  status,
  restaurantImage{..., asset->},
  vatNumber
`;

const storeQuery = `
  *[ _type == 'restaurant' && _id == $id ]{
    ${projection}
  }
`;

const storesByIdsWithOutLocationQuery = `
  *[ _type == 'restaurant' && _id in $storeIds ] {
    ${projection}
  }
`;

const storesByNumbersWithOutLocationQuery = `
  *[ _type == 'restaurant' && number in $storeNumbers ] {
    ${projection}
  }
`;

const storesByNumbersWithLocationQuery = `
*[ _type == 'restaurant' && number in $storeNumbers ] |
order(($userLat - latitude) ** 2 + ($userLng - longitude) ** 2) {
  ${projection}
}
`;

const storesByIdsQuery = `
  *[ _type == 'restaurant' && _id in $storeIds ] |
  order(($userLat - latitude) ** 2 + ($userLng - longitude) ** 2) {
    ${projection}
  }
`;

const storesByNumberQuery = `
  *[ _type == 'restaurant' && number == $storeNumber ] {
    ${projection}
  }
`;

export enum StoreStatus {
  OPEN = 'deliveryStoreOpen',
  CLOSED = 'deliveryStoresClosed',
  NO_DELIVERY = 'noDeliveryStores',
}

export declare interface IGetClosestAvailableDeliveryRestaurantResult {
  deliveryQuote: DeliveryStatus | null;
  storeStatus: StoreStatus;
  store: IRestaurant | null;
  nextEarliestOpen?: Date;
}

export const DeliveryStoreStatusToStoreStatusMap: Record<DeliveryStoreStatus, StoreStatus> = {
  [DeliveryStoreStatus.CLOSED]: StoreStatus.CLOSED,
  [DeliveryStoreStatus.NO_DELIVERY]: StoreStatus.NO_DELIVERY,
  [DeliveryStoreStatus.OPEN]: StoreStatus.OPEN,
};

export type QueryClosestAvailableRestaurantTriple = [
  IGetClosestAvailableDeliveryRestaurantResult | undefined,
  string | undefined
];

type GetRestaurantArgs = GroqArgs<{ id: string }>;

export const getRestaurant = (endpoint: string, { id }: GetRestaurantArgs) =>
  groqQuery<[IRestaurant], GetRestaurantArgs>(endpoint, storeQuery, { id });

type GetRestaurantsByStoreNumberArgs = GroqArgs<{ storeNumber: string }>;

export const getRestaurantsByStoreNumber = (
  endpoint: string,
  { storeNumber }: GetRestaurantsByStoreNumberArgs
) =>
  groqQuery<IRestaurant[], GetRestaurantsByStoreNumberArgs>(endpoint, storesByNumberQuery, {
    storeNumber,
  });

type GetRestaurantsByIdsArgs = {
  storeIds: string[];
  userLat: number;
  userLng: number;
};

type GetAllRestaurantsByIdsArgs = {
  storeIds: string[];
};

type GetAllRestaurantsByStoreNumbersArgs = {
  storeNumbers: string[];
  userLat?: number;
  userLng?: number;
};

export const getAllRestaurantsByIdsOrderedByLocation = (
  endpoint: string,
  { storeIds, userLat, userLng }: GroqArgs<GetRestaurantsByIdsArgs>
) =>
  groqQuery<IRestaurant[], GetRestaurantsByIdsArgs>(endpoint, storesByIdsQuery, {
    storeIds,
    userLat,
    userLng,
  });

export const getAllRestaurantsByIds = (
  endpoint: string,
  { storeIds }: GroqArgs<GetAllRestaurantsByIdsArgs>
) =>
  groqQuery<IRestaurant[], GetAllRestaurantsByIdsArgs>(endpoint, storesByIdsWithOutLocationQuery, {
    storeIds,
  });

export const getAllRestaurantsByStoreNumbers = (
  endpoint: string,
  { storeNumbers }: GroqArgs<GetAllRestaurantsByStoreNumbersArgs>
) =>
  groqQuery<IRestaurant[], GetAllRestaurantsByStoreNumbersArgs>(
    endpoint,
    storesByNumbersWithOutLocationQuery,
    {
      storeNumbers,
    }
  );

export const getAllRestaurantsByStoreNumbersOrderedByLocation = (
  endpoint: string,
  { storeNumbers, userLat, userLng }: GroqArgs<GetAllRestaurantsByStoreNumbersArgs>
) =>
  groqQuery<IRestaurant[], GetAllRestaurantsByStoreNumbersArgs>(
    endpoint,
    storesByNumbersWithLocationQuery,
    {
      storeNumbers,
      userLat,
      userLng,
    }
  );
