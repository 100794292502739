import { useEffect, useRef } from 'react';

import * as Updates from 'expo-updates';
import { omit } from 'lodash-es';

import { appVersion, binaryRuntimeAppVersionWithBuildNumber } from 'utils/app-version-info';
import { isWeb } from 'utils/environment';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { logger } from 'utils/logger';

export const useOtaLogger = () => {
  const updates = Updates.useUpdates();
  const didLog = useRef(false);

  useEffect(() => {
    if (isWeb) {
      return;
    }

    doCheck();

    async function doCheck() {
      const doneChecking = !!updates.lastCheckForUpdateTimeSinceRestart;
      if (!doneChecking) {
        return;
      }

      await LocalStorage.sync();

      // this seems to hit multiple times as the "updates" object changes. only log once.
      if (didLog.current) {
        return;
      }
      didLog.current = true;

      const currentVersionInfo = {
        ...omit(updates.currentlyRunning, 'manifest'),
        appVersion,
      };

      const oldVersionInfo = LocalStorage.getItem(StorageKeys.LAST_VERSION_INFO);

      if (
        updates.availableUpdate &&
        oldVersionInfo?.updateId !== currentVersionInfo?.updateId &&
        !currentVersionInfo.isEmbeddedLaunch &&
        oldVersionInfo?.appVersion !== appVersion
      ) {
        if (oldVersionInfo) {
          oldVersionInfo.createdAt = new Date(oldVersionInfo.createdAt);
        }

        logger.info({
          message: '[OTA] Update applied!',
          isOtaLog: true,
          otaMetadata: {
            appVersion,
            binaryRuntimeAppVersionWithBuildNumber,
            currentVersionInfo,
            oldVersionInfo,
          },
        });
      } else {
        logger.info({
          message: '[OTA] No updates found',
          isOtaLog: true,
          otaMetadata: {
            currentVersionInfo,
            appVersion,
            binaryRuntimeAppVersionWithBuildNumber,
          },
        });
      }

      LocalStorage.setItem(StorageKeys.LAST_VERSION_INFO, currentVersionInfo);
    }
  }, [updates]);
};
