/**
 * NOTE!!!!
 * the contents of this file were stolen from "@rbilabs/common" https://github.com/rbilabs/bk-packages/blob/master/packages/common/src/ to reduce bundle size of unused imports
 */

/**
 * Attempt to convert a string to a 2 character ISO country code
 * @param country Country in an unknown format
 * @returns ISO Country Code. Returns "undefined" if no country could be determined
 */
export function convertCountryStringToIsoCountryCode2Char(
  country: string
): IsoCountryCode2Char | undefined {
  const normalizedCountry = country.toUpperCase().trim();

  // Country is in format "US"
  if (ISO2_ISO_MAPPING[normalizedCountry as IsoCountryCode2Char]) {
    return normalizedCountry as IsoCountryCode2Char;
  }

  // Country is in format "USA"
  if (ISO_ISO2_MAPPING[normalizedCountry as IsoCountryCode]) {
    return ISO_ISO2_MAPPING[normalizedCountry as IsoCountryCode];
  }

  // Country is in format "UNITED STATE"
  return UPPER_CASE_COUNTRY_NAME_TO_ISO2_MAPPING[normalizedCountry];
}

/**
 * Any valid ISO 3166-1 alpha-2 (2 character) country code
 */
export enum IsoCountryCode2Char {
  CA = 'CA',
  US = 'US',
}

/**
 * Any valid ISO 3166-1 alpha-3 (3 character) country code
 */
export enum IsoCountryCode {
  CAN = 'CAN',
  USA = 'USA',
}

/**
 * Record mapping ISO 3166-1 alpha-2 (2 character) country code to ISO 3166-1 alpha-3 (3 character) country code
 */
export const ISO2_ISO_MAPPING: Record<IsoCountryCode2Char, IsoCountryCode> = {
  [IsoCountryCode2Char.CA]: IsoCountryCode.CAN,
  [IsoCountryCode2Char.US]: IsoCountryCode.USA,
};

/**
 * Record mapping ISO 3166-1 alpha-3 (3 character) country code to ISO 3166-1 alpha-2 (2 character) country code
 */
export const ISO_ISO2_MAPPING: Record<IsoCountryCode, IsoCountryCode2Char> = {
  [IsoCountryCode.CAN]: IsoCountryCode2Char.CA,
  [IsoCountryCode.USA]: IsoCountryCode2Char.US,
};

/**
 * Maps an upper case country name to the ISO 3166-1 alpha-2 (2 character) country code
 */
export const UPPER_CASE_COUNTRY_NAME_TO_ISO2_MAPPING: Record<string, IsoCountryCode2Char> = {
  CANADA: IsoCountryCode2Char.CA,
  'UNITED STATES': IsoCountryCode2Char.US,
};

export declare enum LANGUAGES {
  en = 'en',
  es = 'es',
  fr = 'fr',
}

export interface ICoordinates {
  latitude: number;
  longitude: number;
}

export const DEGREES_PER_PI = 180;
export const EARTH_RADIUS_METERS = 6378137;
export const METERS_PER_MILE = 1609.34;

/**
 * Convert degrees to radians.
 *
 * @param {number} degrees
 * The value, in degrees to convert to radians.
 *
 * @returns {number}
 * The value, in degrees converted to radians.
 */
function toRadians(degrees: number): number {
  return degrees * (Math.PI / DEGREES_PER_PI);
}

/**
 * Convert meters to miles.
 *
 * @param {number} meters
 * The value, in meters to convert to miles.
 *
 * @returns {number}
 * The value, in meters converted to miles.
 */
function toMiles(meters: number): number {
  return meters / METERS_PER_MILE;
}

/**
 * Compute the distance between two sets of coordinates, defined using latitude and longitude.
 * By default, the distance is computed in miles.
 *
 * See https://www.movable-type.co.uk/scripts/latlong.html
 *
 * @param {ICoordinates} point1
 * The first set of coordinates.
 *
 * @param {ICoordinates} point2
 * The second set of coordinates.
 *
 * @param {boolean} inMiles
 * Optionally return the distance in miles.
 *
 * @returns {number}
 * The distance between the two sets of coordinates.
 */
export function coordinateDistance(
  point1: ICoordinates,
  point2: ICoordinates,
  inMiles = true
): number {
  // convert the latitude values to radians
  const latitude1 = toRadians(point1.latitude);
  const latitude2 = toRadians(point2.latitude);

  // compute the delta of latitude and longitude, in radians
  const latitudeDelta = toRadians(point2.latitude - point1.latitude);
  const longitudeDelta = toRadians(point2.longitude - point1.longitude);

  /* eslint-disable @typescript-eslint/no-magic-numbers */

  const a: number =
    Math.sin(latitudeDelta / 2) ** 2 +
    Math.cos(latitude1) * Math.cos(latitude2) * Math.sin(longitudeDelta / 2) ** 2;

  const c: number = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  /* eslint-enable @typescript-eslint/no-magic-numbers */

  const distance: number = EARTH_RADIUS_METERS * c;
  return inMiles ? toMiles(distance) : distance;
}
