import React from 'react';

import { Button, View } from '@rbilabs/universal-components';
import { GestureResponderEvent } from 'react-native';

import { useIsMobileBreakpoint } from 'hooks/use-media-query';
import { theme } from 'styles/configure-theme';

import {
  ButtonWrapper,
  CardWrap,
  Description,
  Header,
  ImageLink,
  Terms,
  TextWrapper,
} from './styled';
import { CardProps } from './types';

/**
 * Displays a link comprised of an image and text, used for marketing
 */
const Card = ({
  image,
  header,
  description,
  callToAction,
  href,
  termsAccessibilityLabel,
  termsLinkText,
  termsLinkHref,
  marketingId,
  variant = 'default',
  bgColor,
  textColor,
  buttonBgColor,
  buttonTextColor,
  onPress,
  onPressTermsLink,
}: CardProps) => {
  const isMobile = useIsMobileBreakpoint();

  const onPressMainLink = (event: GestureResponderEvent | undefined) =>
    onPress?.(event, { marketingId });

  const colorTweaks =
    variant === 'campaign'
      ? { color: textColor, bgColor }
      : {
          color: theme.token('text-default'),
          bgColor: theme.token('background-pattern'),
        };

  const buttonProps = {
    onPress: onPressMainLink,
    size: 'md',
    variant: variant === 'default' ? 'outline' : 'solid',
    ...(variant === 'campaign' && {
      bgColor: buttonBgColor,
      _text: {
        color: buttonTextColor,
      },
    }),
  } as const;

  return (
    <CardWrap {...colorTweaks} flexBasis={isMobile ? 'auto' : '49%'} flexGrow={isMobile ? 1 : 0}>
      <ImageLink linkPath={href} onPress={onPressMainLink}>
        {image}
      </ImageLink>
      <TextWrapper>
        <View>
          <Header {...colorTweaks} accessibilityLabel={header}>
            {header}
          </Header>
          <Description {...colorTweaks} accessibilityLabel={description}>
            {description}
          </Description>

          {termsLinkText && (
            <Terms
              {...colorTweaks}
              accessibilityRole="link"
              accessibilityLabel={termsAccessibilityLabel}
              linkPath={termsLinkHref || ''}
              onPress={(event: GestureResponderEvent | undefined) =>
                onPressTermsLink?.(event, { marketingId })
              }
            >
              {termsLinkText}
            </Terms>
          )}
        </View>
        <ButtonWrapper>
          <Button {...buttonProps}>{callToAction}</Button>
        </ButtonWrapper>
      </TextWrapper>
    </CardWrap>
  );
};

export default Card;
