import { BrandLogo, Text, VStack } from '@rbilabs/universal-components';

import Link from 'components/link';
import RewardsLogo from 'pages/loyalty/loyalty-rewards-logo';

export const GetStartedText = Text.withConfig({
  variant: 'copyTwo',
  textAlign: 'center',
  _web: {
    fontSize: 'lg',
  },
});

export const LogoLink = Link.withConfig({
  justifyContent: 'center',
});

export const SignUpHeadlineContainer = VStack.withConfig({
  paddingTop: {
    sm: '6',
  },
});

export const StyledBKLogo = BrandLogo.withConfig({
  width: '24',
  height: '24',
  marginBottom: '1',
});

export const StyledRewardsLogo = RewardsLogo.withConfig({
  height: {
    base: '12',
    sm: '16',
  },
  minWidth: '200px',
  _web: {
    height: '64px',
    width: '260px',
    cursor: 'pointer',
  },
});

export const RewardsLinkStyled = Link.withConfig({
  isInline: true,
  isUnderlined: true,
  _text: {
    variant: 'copyTwo',
    textDecoration: 'underline',
    fontSize: 'xs',
    paddingTop: '3px',
  },
});
