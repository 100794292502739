export const enum HeaderVersion {
  Version1,
  Version2,
  Version3,
}

export interface WalmartHeaderProps {
  headerVersion: HeaderVersion;
}

export interface WalmartLandingOffersProps {
  isAlreadyLinked: boolean;
}

export const enum LandingPageType {
  DISTRO = 'distro',
  OFFERS = 'offers',
}
