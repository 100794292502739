import { useCallback, useMemo, useState } from 'react';

import { StaticNutritionPageDocument } from 'generated/sanity-graphql';
import {
  GetStaticPageQuery,
  IGetStaticPageQuery,
  IGetStaticPageVariables,
  IStaticPage,
} from 'remote/queries/static-page';
import { useErrorContext } from 'state/errors';
import { useLazySanityQuery } from 'state/graphql/hooks/use-sanity-query';

export const useStaticPage = () => {
  const { setSanityDataRef } = useErrorContext();
  const [
    isNutritionExplorerMostRecentlyFetchedPage,
    setIsNutritionExplorerMostRecentlyFetchedPage,
  ] = useState(false);

  const [
    getPage,
    {
      data: normalPageData,
      error: normalPageError,
      loading: normalPageLoading,
      refetch: normalPageRefetch,
    },
  ] = useLazySanityQuery<IGetStaticPageQuery, IGetStaticPageVariables>(GetStaticPageQuery);

  const [
    getNutritionPage,
    {
      data: nutritionPageData,
      error: nutritionPageError,
      loading: nutritionPageLoading,
      refetch: nutritionPageRefetch,
    },
  ] = useLazySanityQuery<IGetStaticPageQuery, IGetStaticPageVariables>(StaticNutritionPageDocument);

  const retrieveStaticPageById = useCallback(
    (id: string, isNutritionPage = false): void => {
      setIsNutritionExplorerMostRecentlyFetchedPage(isNutritionPage);
      if (isNutritionPage) {
        /**
         * Nutrition query fetches items from the Sanity menu.
         * Apollo will try to normalize all data and store them in the cache,
         * overriding any existing entries with the same '__typename:_id'.
         *
         * fetchPolicy: 'no-cache' is a fix to prevent Apollo from overriding Menu data on the cache,
         * that it was already requested on /menu by the GetMenuSectionsQuery.
         */
        getNutritionPage({ variables: { id }, fetchPolicy: 'no-cache' });
      } else {
        getPage({ variables: { id } });
      }
    },
    [getNutritionPage, getPage]
  );

  const currentPage = useMemo<IStaticPage | undefined>(() => {
    const staticPage = isNutritionExplorerMostRecentlyFetchedPage
      ? nutritionPageData?.StaticPage
      : normalPageData?.StaticPage;
    setSanityDataRef(staticPage);
    return staticPage;
  }, [
    isNutritionExplorerMostRecentlyFetchedPage,
    nutritionPageData,
    normalPageData,
    setSanityDataRef,
  ]);

  const error = isNutritionExplorerMostRecentlyFetchedPage ? nutritionPageError : normalPageError;
  const loading = isNutritionExplorerMostRecentlyFetchedPage
    ? nutritionPageLoading
    : normalPageLoading;
  const refetch = isNutritionExplorerMostRecentlyFetchedPage
    ? nutritionPageRefetch
    : normalPageRefetch;

  return { currentPage, error, loading, refetch, retrieveStaticPageById };
};
