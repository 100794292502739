import { ReactNode, createContext, useMemo } from 'react';

import { LinearGradient } from 'expo-linear-gradient';
import { NativeBaseProvider } from 'native-base';

import type { UclTheme } from './theme-types';

export interface IUclContext {
  /**
   * Universal Component Library theme.
   *
   * Use this to access the theme tokens.
   */
  theme: UclTheme;
}

/**
 * Context for the universal component library
 */
export const uclContext = createContext<IUclContext>({
  theme: {} as UclTheme,
});

interface IInitialWindowMetrics {
  frame?: {
    height: number;
    width: number;
    x: number;
    y: number;
  };
  insets?: {
    bottom: number;
    left: number;
    right: number;
    top: number;
  };
}

export interface IUclProviderProps {
  children?: ReactNode;
  initialWindowMetrics?: IInitialWindowMetrics;
  theme: UclTheme;
}

/**
 * Provides Universal Component Library context.
 *
 * This includes a provider for NativeBase.
 * Use this theme to inject those themes into scope.
 *
 * If using for a specific theme, or the theme, also declare the UclCustomTheme override.
 * this will create types for NativeBase props.
 *
 * ```typescript
 *
 * declare module '@rbilabs/universal-components'{
 *  export interface UclCustomTheme extends BkTheme{}
 * }
 * ````
 */
export const UclProvider = ({ children, theme, initialWindowMetrics }: IUclProviderProps) => (
  <uclContext.Provider value={useMemo(() => ({ theme }), [theme])}>
    <NativeBaseProvider
      theme={theme.nativeBaseTheme}
      initialWindowMetrics={initialWindowMetrics}
      config={{
        dependencies: {
          'linear-gradient': LinearGradient,
        },
        // TODO remove when NB fixes accessibility warning bug
        suppressColorAccessibilityWarning: true,
      }}
    >
      {children}
    </NativeBaseProvider>
  </uclContext.Provider>
);
