import * as React from 'react';

import { Box, HStack, Header, Icon, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { LogUserErrorSeverity, useLogUserErrorMessage } from 'hooks/log-user-error-message';
import { theme } from 'styles/configure-theme';

import { getQuestCheckConnectionText, getQuestLoadErrorText } from '../custom-text';

import { ImageWrapper, ReloadButton } from './styled';

export const QuestCardError = ({ refetch }: { refetch: () => void }) => {
  const { formatMessage } = useIntl();

  const errorHeaderMessage = getQuestLoadErrorText(formatMessage);

  useLogUserErrorMessage({
    message: errorHeaderMessage,
    severity: LogUserErrorSeverity.GeneralError,
    context: {
      moreInfo: 'quest-card-error.tsx',
    },
  });

  return (
    <Box borderRadius="xl" backgroundColor={theme.token('background-pattern')} shadow="2">
      <ImageWrapper>
        <Icon variant="errorFill" size="12" color={theme.token('icon-disabled')} />
      </ImageWrapper>
      <Box padding="$4">
        <Header variant="headerTwo">{errorHeaderMessage}</Header>
        <Text marginBottom="$4">{getQuestCheckConnectionText(formatMessage)}</Text>
        <HStack flexShrink={1}>
          <ReloadButton
            leftIcon={
              <Icon variant="refresh" size="sm" color={theme.token('border-color-error')} />
            }
            onPress={refetch}
          >
            {formatMessage({ id: 'tryAgain' })}
          </ReloadButton>
        </HStack>
      </Box>
    </Box>
  );
};
