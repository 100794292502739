import React, { FC, useMemo, useRef, useState } from 'react';

import { IconButton, View } from '@rbilabs/universal-components';
import { FlatList, LayoutChangeEvent } from 'react-native';

import { useIsMobileBreakpoint } from 'hooks/use-media-query';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

import { ResponsiveCarouselSkeletonItem } from './responsive-carousel.skeleton';
import {
  CardContainerWrapper,
  Container,
  LeftButtonWrapper,
  MeasureBox,
  RightButtonWrapper,
} from './responsive-carousel.styled.web';
import { IResponsiveCarouselProps, Sizes } from './types';

const SKELETON_ITEMS = [null, null, null, null, null] as const;

const skeletonRenderItem = () => <ResponsiveCarouselSkeletonItem width={150} />;

const ResponsiveCarousel: FC<React.PropsWithChildren<IResponsiveCarouselProps<any>>> = ({
  data: propData,
  renderItem: propRenderItem,
  bgColor = 'transparent',
  itemWidth = 150,
  keyExtractor,
  isLoading,
  centerCards = false,
  activeItemIndex,
}) => {
  const [initialPageItem, setInitialPageItem] = useState(0);

  const flatListRef = useRef<FlatList>(null);
  const isMobile = useIsMobileBreakpoint();
  const [carouselWidth, setCarouselWidth] = useState<number>(0);

  const data = isLoading && !propData?.length ? SKELETON_ITEMS : propData ?? SKELETON_ITEMS;
  const renderItem = propRenderItem ?? skeletonRenderItem;

  const cardsPerPage = itemWidth
    ? Math.floor(carouselWidth / itemWidth)
    : Sizes.CARDS_PER_SLIDE_DESKTOP;

  const totalCards = data.length;
  const disableRightButton = useMemo(() => {
    return initialPageItem + cardsPerPage >= totalCards;
  }, [initialPageItem, cardsPerPage, totalCards]);

  const showMore = () => {
    setInitialPageItem(initialPageItem => initialPageItem + cardsPerPage);
    flatListRef.current?.scrollToIndex({
      animated: true,
      index: initialPageItem + cardsPerPage,
    });
  };

  const showLess = () => {
    setInitialPageItem(initialPageItem => initialPageItem - cardsPerPage);
    if (initialPageItem < cardsPerPage) {
      flatListRef.current?.scrollToIndex({
        animated: true,
        index: 0,
      });
    } else {
      flatListRef.current?.scrollToIndex({
        animated: true,
        index: initialPageItem - cardsPerPage,
      });
    }
  };

  const findCarouselDimensions = (event: LayoutChangeEvent) => {
    const layout = event.nativeEvent.layout;
    setCarouselWidth(layout.width);
  };

  const getItemLayout = (_: any, index: number) => ({
    length: itemWidth,
    offset: itemWidth * index,
    index,
  });

  const cardContainerWidth = isMobile ? 'full' : (itemWidth + Sizes.SPACER) * cardsPerPage;

  return (
    <Container bgColor={bgColor} justifyContent={isMobile ? 'flex-start' : 'center'}>
      {!isMobile && (
        <LeftButtonWrapper bgColor={bgColor}>
          <IconButton
            isDisabled={initialPageItem === 0}
            variant="outline"
            iconName="back"
            onPress={showLess}
            {...hiddenAccessibilityPlatformProps}
          />
        </LeftButtonWrapper>
      )}

      <MeasureBox onLayout={findCarouselDimensions}>
        <CardContainerWrapper width={cardContainerWidth}>
          <FlatList
            ref={flatListRef}
            horizontal
            centerContent={centerCards || totalCards < cardsPerPage}
            showsHorizontalScrollIndicator={false}
            scrollEnabled={isMobile}
            data={data}
            keyExtractor={keyExtractor}
            renderItem={isLoading ? skeletonRenderItem : renderItem}
            ItemSeparatorComponent={Separator}
            initialScrollIndex={activeItemIndex}
            getItemLayout={getItemLayout}
          />
        </CardContainerWrapper>
      </MeasureBox>

      {!isMobile && (
        <RightButtonWrapper bgColor={bgColor}>
          <IconButton
            variant="outline"
            iconName="forward"
            onPress={showMore}
            isDisabled={disableRightButton}
            {...hiddenAccessibilityPlatformProps}
          />
        </RightButtonWrapper>
      )}
    </Container>
  );
};

const Separator = () => <View style={{ marginRight: Sizes.SPACER }} />;

export default ResponsiveCarousel;
