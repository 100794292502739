import { Svg, SvgImage, SvgProps, registerSVG } from '../../../components/svg';

export function WalmartLogo(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 75 26">
      <SvgImage width="75" height="26" href={require('./WalmartLogo.png')} />
    </Svg>
  );
}

registerSVG('WalmartLogo', WalmartLogo);
