import { IOptInModalFragment } from 'generated/sanity-graphql';

/**
 * Validate the opt-in modal cms data
 *
 * * Avoid get stuck on the opt-in flow having an empty button string.
 */
export const isModalDataValid = (optInModal: IOptInModalFragment | null | undefined) => {
  return !!optInModal?.buttonText?.locale;
};
