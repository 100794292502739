import React from 'react';

import { Box } from '@rbilabs/universal-components';

import { ISanityImage } from '@rbi-ctg/menu';
import { TypographyBlock } from 'components/features/components/typography-block';
import { IMarketingTileBasicFragment } from 'generated/sanity-graphql';

import { StyledTileContainer, TileImage, TileTitle } from './styled';

interface MarketingTileListElementProps {
  basicTile: IMarketingTileBasicFragment;
}

const MarketingTileListElement = ({ basicTile }: MarketingTileListElementProps) => {
  const description = basicTile.marketingBasicTileDescription?.localeRaw;
  const title = basicTile.marketingBasicTileTitle?.locale || '';
  const image = basicTile.marketingBasicTileImage?.locale?.app as ISanityImage;
  return (
    <StyledTileContainer key={`MarketingTileList_${basicTile._id}`} testID="marketing-tile-element">
      {image && <TileImage image={image} alt={title} />}
      <Box testID="marketing-tile-element-text-container" marginLeft="$4" flex="1">
        <TileTitle>{title}</TileTitle>
        <TypographyBlock color={Styles.color.black} content={description} />
      </Box>
    </StyledTileContainer>
  );
};
export default MarketingTileListElement;
