// TODO: RN - fix dependency cycle causing app crash
//      when changing import to state/intl/constants
import {
  PROD_DEFAULT_REGION as DEFAULT_REGION,
  PROD_SUPPORTED_REGIONS as SUPPORTED_REGIONS,
} from 'state/intl/constants/supported-regions';
import { Region } from 'utils/environment/types';

export function findSupportedRegion(requestedRegion: string): Region {
  return SUPPORTED_REGIONS[requestedRegion] || DEFAULT_REGION;
}
