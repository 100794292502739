import { IItem, IModifierSelection, IPrices } from '@rbi-ctg/menu';
import { IPriceForItemOptionModifierFn } from 'state/menu/types';
import logger from 'utils/logger';
import { isItem } from 'utils/menu';

export const priceForItemModifierSelections = ({
  item,
  quantity = 1,
  modifierSelections = [],
  prices,
  priceForItemOptionModifier,
}: {
  item: IItem | null;
  quantity: number;
  modifierSelections: Array<IModifierSelection>;
  prices?: IPrices;
  priceForItemOptionModifier: IPriceForItemOptionModifierFn;
}): number => {
  if (!isItem(item)) {
    return 0;
  }

  return (
    (modifierSelections as Array<IModifierSelection>).reduce<number>(
      (price, { _key, modifier }) => {
        const itemOption = (item.options || []).find(
          ({ _key: itemOptionKey }) => _key === itemOptionKey
        );

        // illegal case, modifier selection
        // does not correspond to this item's
        // options. discard it when pricing
        if (!itemOption) {
          logger.warn({
            itemId: item._id,
            message: 'No ItemOption found for modifier',
            modifier,
          });
          return price;
        }

        const modifierPrice = priceForItemOptionModifier({
          item,
          itemOption,
          modifier,
          prices,
        });

        return price + modifierPrice;
      },
      0
    ) * quantity
  );
};
