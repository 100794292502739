import * as React from 'react';
import { FC } from 'react';

import { useIntl } from 'react-intl';

import Dialog from 'components/dialog';

import {
  CloseButton,
  ConfirmButton,
  DialogContent,
  DialogIconContainer,
  DialogTitle,
  ErrorIcon,
  ModalActionsContainer,
} from './styled';

type UnlinkWalmartUserModalProps = {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

export const UnlinkWalmartUserModal: FC<React.PropsWithChildren<UnlinkWalmartUserModalProps>> = ({
  open,
  onConfirm,
  onClose,
}) => {
  const { formatMessage } = useIntl();

  const handleConfirm = () => {
    onClose();
    onConfirm();
  };

  return (
    <Dialog
      showDialog={open}
      headingComponent={
        <DialogTitle>{formatMessage({ id: 'walmartUnlinkTitleDialog' })}</DialogTitle>
      }
      bodyComponent={
        <DialogContent>{formatMessage({ id: 'walmartUnlinkBodyDialog' })}</DialogContent>
      }
      onDismiss={onClose}
      modalAppearanceEventMessage="Walmart+ unlink user modal"
      image={
        <DialogIconContainer>
          <ErrorIcon />
        </DialogIconContainer>
      }
      actions={
        <ModalActionsContainer>
          <ConfirmButton onPress={handleConfirm}>
            {formatMessage({ id: 'walmartUnlinkConfirmBtnDialog' })}
          </ConfirmButton>
          <CloseButton onPress={onClose}>
            {formatMessage({ id: 'walmartUnlinkCancelBtnDialog' })}
          </CloseButton>
        </ModalActionsContainer>
      }
    />
  );
};
