import { IStaticPageRoute } from 'remote/queries/static-page';
import { PROD_DEFAULT_LANGUAGE } from 'state/intl/constants/supported-languages';

export interface LocaleRoutes {
  acceptGift: string;
  account: string;
  sendGift: string;
  exploreMenu: string;
  signUp: string;
  signIn: string;
  storeLocator: string;
  hockeyChallenge: string;
  scan: string;
}

export const localeRoutes: Record<string, LocaleRoutes> = {
  en: {
    acceptGift: '/accept-gift',
    account: '/account',
    sendGift: '/gift',
    exploreMenu: '/explore-menu',
    signUp: '/signup',
    signIn: '/signin',
    storeLocator: '/store-locator',
    hockeyChallenge: '/hockey-challenge',
    scan: '/scan',
  },
};

export function getAllLocalizedVersionsOfRoute(routeKey: keyof LocaleRoutes) {
  const routes = Object.values(localeRoutes).map(routeMaps => routeMaps[routeKey]);
  return routes.filter(Boolean);
}

export const getStaticPagesLocalizedRouteForPath = (
  path: string,
  staticRoutes: IStaticPageRoute[],
  language: string
) => {
  if (!staticRoutes || !Array.isArray(staticRoutes)) {
    return null;
  }

  let localizedRelativePath = null;

  staticRoutes.forEach((route: any) => {
    if (route?.path?.current === path) {
      const localizedPathWithFallback = route?.localePath[language]?.current || route.path.current;
      localizedRelativePath = `/${localizedPathWithFallback}`;
    }
  });

  return localizedRelativePath;
};

export const getInCodeLocalizedRouteForPath = (
  relativePath: string,
  language: string,
  region: string
) => {
  const defaultLanguageLocaleRoutes = localeRoutes[PROD_DEFAULT_LANGUAGE[region]];

  const defaultLanguageRouteKey = Object.keys(defaultLanguageLocaleRoutes).find(
    key => defaultLanguageLocaleRoutes[key] === relativePath
  );

  if (defaultLanguageRouteKey) {
    return (
      localeRoutes[language]?.[defaultLanguageRouteKey] ||
      defaultLanguageLocaleRoutes[defaultLanguageRouteKey]
    );
  }

  return null;
};
