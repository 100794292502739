import { NetInfoState } from '@react-native-community/netinfo';
import { ASTNode, GraphQLError as GQLError, stripIgnoredCharacters } from 'graphql';
import { print } from 'graphql/language/printer';

import { Omit } from '@rbi-ctg/frontend';
import { NetworkConnectionError } from 'remote/exceptions';
import { LANGUAGES } from 'state/intl/types';
import { useNetworkContext } from 'state/network';
import logger from 'utils/logger';

import { isBadNetworkError, withNetworkRetries } from './utils';

// Chrome starts truncating urls at around 7500 so
// we put a max limit on it. Sanity supports up to 11KB
const MAX_QUERY_SIZE = 7500;

export class GraphQLError extends Error {
  originalError: GQLError[];

  constructor(errors: GQLError[]) {
    super(`GraphQLError occurred: ${errors.map(({ message }) => message).join('\n')}`);
    this.originalError = errors;
  }
}

export function isSanityGraphqlUrl(url: string): Boolean {
  return url.includes('sanity.io') && url.includes('graphql');
}

export type FetchConfig<TResult, TVars> = Omit<Parameters<GlobalFetch['fetch']>[1], 'headers'> & {
  body?: string;
  /**
   * Because typescript has no notion of throwing,
   * we can use a default return type because the assumption
   * is that every catch function will either throw an error
   * or return an object of the correct type. The third type
   * argument to FetchConfig can be used to provide a
   * "failure" or "left" type if this is not the case.
   */
  connection?: NetInfoState;
  headers?: {
    [header: string]: string;
  };
  language?: LANGUAGES;
  maxRetries?: number;
  method?: string;
  query?: ASTNode;
  sessionId?: string;
  region?: string;
  then?: (res: object) => TResult;
  variables?: TVars;
};

function getQs(query: string, variables: object) {
  const baseQs = `?query=${encodeURIComponent(query)}`;
  const varsQs = variables ? `&variables=${encodeURIComponent(JSON.stringify(variables))}` : '';
  return `${baseQs}${varsQs}`;
}

export const useSanityGqlEndpoint = () => useNetworkContext().sanityEndpoints.graphql;

export function fetch<TResult, TVars>(
  URL: string,
  config: FetchConfig<TResult, TVars>
): Promise<TResult> {
  const { body, headers, language = 'en', method = 'POST', query: queryAst, then } = config;

  // localize all queries
  const query = queryAst
    ? stripIgnoredCharacters(
        print(queryAst)
          // Replaces _LANG_ with `en` if language is english
          .replace(/_LANG_/g, language)
      )
    : '';

  const encodedQueryString = getQs(query, config.variables || {});

  const requestOpts: RequestInit = {
    headers,
    method: method === 'GET' ? 'GET' : 'POST',
  };

  if (isSanityGraphqlUrl(URL)) {
    // Wipe all headers when hitting the sanity CDN
    delete requestOpts.headers;

    if (encodedQueryString.length < MAX_QUERY_SIZE) {
      URL += encodedQueryString;

      requestOpts.method = 'GET';
    } else {
      requestOpts.method = 'POST';

      requestOpts.headers = { 'Content-Type': 'application/json' };
    }

    requestOpts.credentials = 'omit';
    requestOpts.referrerPolicy = 'no-referrer';
  }

  if (requestOpts.method === 'POST') {
    requestOpts.body =
      body ||
      JSON.stringify({
        query,
        variables: config.variables || {},
      });
  }

  const run = () => {
    return window
      .fetch(URL, requestOpts)
      .then(res => {
        const contentType = res.headers.get('content-type') || '';
        if (!contentType.includes('application/json')) {
          const errorRes: Promise<any> = res.text().then(responseText => {
            const error = new Error(`[NetworkResponseNotOk]: Url: ${URL}`);
            logger.error({
              error,
              responseText,
            });

            throw new NetworkConnectionError();
          });

          return errorRes;
        }

        if (res.status === 404) {
          throw new Error(res.statusText);
        }

        return res.json();
      })
      .then(res => {
        if (res.errors) {
          throw new GraphQLError(res.errors);
        }
        if (res.data) {
          return res.data;
        }

        return res;
      })
      .then(then)
      .catch(error => {
        // bad network logged by withNetworkRetries
        if (!isBadNetworkError(error)) {
          logger.error({ error, URL });
        }
        throw error;
      });
  };

  return withNetworkRetries(run, {
    operationName: URL,
    typeName: 'Fetch',
    canRetry: error => isBadNetworkError(error),
  });
}
