import { IPrices } from '@rbi-ctg/menu';
import { IWithVendorConfig, PosVendors, computeCompositeModifierPlu } from 'utils/vendor-config';

import { priceComboSlotUpsellModifier } from './price-combo-slot-upsell-modifier';

interface IPriceComboSlotComposite {
  combo: IWithVendorConfig;
  selectedItem: IWithVendorConfig;
  prices: IPrices;
  vendor: PosVendors | null;
}

export const priceComboSlotComposite = ({
  combo,
  selectedItem,
  prices,
  vendor,
}: IPriceComboSlotComposite): number | undefined => {
  if (!prices || !vendor) {
    return;
  }

  const compositePlu = computeCompositeModifierPlu({ item: combo, modifier: selectedItem, vendor });
  const compositePrice = compositePlu ? prices[compositePlu] : undefined;
  if (compositePrice === undefined) {
    return;
  }

  // Some drinks modifiers have its own extra price, e.g. Oreo Milkshake on NCR/QDI
  const modifierPrice = priceComboSlotUpsellModifier({ modifier: selectedItem, prices, vendor });
  return compositePrice + Number(modifierPrice ?? 0);
};
