import { registerTheme } from '..';
import type { TBrand } from '../types';

import { bkCachedFonts } from './cached-fonts';
import { bkComponentTokens } from './components';
import { bkNativeBaseTheme } from './native-base-theme';
import { bkPrimitives } from './primitives';
import { bkTokens } from './tokens';

export const bkTheme = {
  key: 'bk' as TBrand,
  primitives: bkPrimitives,
  tokens: bkTokens,
  components: bkComponentTokens,
  nativeBaseTheme: bkNativeBaseTheme,
  cachedFonts: bkCachedFonts,
};

registerTheme(bkTheme);
