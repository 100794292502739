import * as Application from 'expo-application';
import { Platform } from 'react-native';
import { v4 as uuidv4 } from 'uuid';

export const getDeviceId = async (): Promise<string> => {
  let deviceId: string;
  if (Platform.OS === 'ios') {
    const iosId = await Application.getIosIdForVendorAsync();
    deviceId = iosId || '';
  } else if (Platform.OS === 'android') {
    const androidId = Application.getAndroidId();
    deviceId = androidId || '';
  } else {
    // expo removed support from this for web so keep it the same as it was in prior SDK versions
    const KEY_NAME = 'EXPO_CONSTANTS_INSTALLATION_ID';

    const id = localStorage.getItem(KEY_NAME);
    if (id) {
      return id;
    }

    const uuid = uuidv4();
    localStorage.setItem(KEY_NAME, uuid);
    deviceId = uuid;
  }
  return deviceId;
};
