import {
  IFeatureAppHomePageQuery,
  IFeatureWebHomePageQuery,
  useFeatureAppHomePageQuery,
  useFeatureWebHomePageQuery,
} from 'generated/sanity-graphql';
import { withSimpleCache } from 'state/graphql/with-simple-cache';
import { isNative } from 'utils/environment';
import { isHuaweiWebview } from 'utils/is-huawei-webview';

export type IFeatureHomePageQuery = IFeatureAppHomePageQuery | IFeatureWebHomePageQuery;

export const useFeatureHomePageQuery =
  isNative || isHuaweiWebview()
    ? withSimpleCache(useFeatureAppHomePageQuery)
    : withSimpleCache(useFeatureWebHomePageQuery);
