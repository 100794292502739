import { useMemo } from 'react';

import { useConfigValue } from 'hooks/configs/use-config-value';
import { useRoute } from 'hooks/navigation/use-route';
import { useIsDesktopBreakpoint } from 'hooks/use-media-query';
import { useLocale } from 'state/intl';
import { useLDContext } from 'state/launchdarkly';
import { isRecentItemsRoute } from 'utils/routing';

import { ISanityNavigationTab, NavigationMobileTab } from './types';
import { generateNavigationMobileTabs } from './utils-sanity';

export const useSanityNavigationTabs = () => {
  const { pathname } = useRoute();
  const { language } = useLocale();
  const { flags: LDFlags } = useLDContext();
  const isLocationInRecentItemsRoute = isRecentItemsRoute(pathname);
  const sanityTabs = useConfigValue({ key: 'tabs', defaultValue: [] }) as ISanityNavigationTab[];
  const isDesktop = useIsDesktopBreakpoint();

  const navigationTabs: NavigationMobileTab[] = useMemo(
    () =>
      generateNavigationMobileTabs({
        LDFlags,
        isLocationInRecentItemsRoute,
        language,
        sanityTabs,
        isDesktop,
      }),
    [LDFlags, isLocationInRecentItemsRoute, language, sanityTabs, isDesktop]
  );

  return [navigationTabs];
};
