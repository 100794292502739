import { ISanityVendorConfigs } from '@rbi-ctg/menu';
import { IMenuItem } from 'state/menu/types';

export enum PluTypes {
  CONSTANT = 'constantPlu',
  IGNORE = 'ignore',
  MULTI_CONSTANT = 'multiConstantPlus',
  PARENT_CHILD = 'parentChildPlu',
  QUANTITY = 'quantityBasedPlu',
  SIZE_BASED = 'sizeBasedPlu',
}

export enum PosVendors {
  BRINK = 'brink',
  BRINK_DELIVERY = 'brinkDelivery',
  CARROLLS = 'carrols',
  CARROLS_DELIVERY = 'carrolsDelivery',
  NCR = 'ncr',
  NCR_DELIVERY = 'ncrDelivery',
  QDI = 'qdi',
  QDI_DELIVERY = 'qdiDelivery',
  SICOM = 'sicom',
  SICOM_DELIVERY = 'sicomDelivery',
}

export enum PluQualifier {
  ADD = 'ADD',
  HALF = '1/2',
  NO = 'NO',
}

export interface IWithVendorConfig {
  _type?: string | null;
  type?: string | null;
  name?: IMenuItem['name'] | { locale: string } | string;
  vendorConfigs?: IMenuItem['vendorConfigs'] | ISanityVendorConfigs; // TODO: remove ISanityVendorConfigs type
  children?: any;
}

export interface IComboWithVendorConfig extends IWithVendorConfig {
  mainItem?: IWithVendorConfig;
}
