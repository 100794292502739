import React from 'react';

import { Box, Image as UclImage } from '@rbilabs/universal-components';

import { ISvgOrImageProps } from './types';

const StyledImage = UclImage.withConfig({
  display: 'flex',
  position: 'absolute',
  height: '100%',
  width: '100%',
});

export const SvgOrImage: React.FC<ISvgOrImageProps> = ({
  accessibilityLabel,
  uri,
  resizeMode,
  onLoad,
  children,
  style,
  ...props
}) => {
  if (!children) {
    return (
      <StyledImage
        alt={accessibilityLabel}
        src={uri}
        resizeMode={resizeMode}
        onLoad={onLoad}
        style={style}
        {...props}
      />
    );
  }

  // react-native-web <Image /> does not support Image with children
  return (
    <Box style={style}>
      <StyledImage
        alt={accessibilityLabel}
        src={uri}
        resizeMode={resizeMode}
        onLoad={onLoad}
        {...props}
      />
      {children}
    </Box>
  );
};
