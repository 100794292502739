import { Io } from './types';

type ISanityNavigationTabBase = {
  iconPath: string;
  iconActivePath: string;
  link: {
    _type: 'localeUrl';
    [key: string]: string;
  };
  text: {
    _type: 'localeString';
    [key: string]: string;
  };
  hideOnDesktop?: boolean;
};

export type ISanityNavigationTab = ISanityNavigationTabBase & {
  hideLinkBasedOnLdFlag?: {
    launchDarklyFlag: string;
  };
  launchDarklyAlternateMobileTab: {
    enabled: boolean;
    launchDarklyFlagDependency?: {
      launchDarklyFlag: string;
    };
  } & ISanityNavigationTabBase;
};

const CYAN = '\x1b[36m%s\x1b[0m'

export async function fetchSanityNavbar({
  sanityProjectId,
  sanityDataset,
  workingDirectory,
  io
}: {
  sanityProjectId: string;
  sanityDataset: string;
  workingDirectory: string;
  io: Io
}): Promise<ISanityNavigationTab[]> {
  console.log(CYAN, '[RN Sanity Prebuild] Generating sanity nav');
  console.log(CYAN, `[RN Sanity Prebuild] Using dataset "${sanityDataset}" in project id "${sanityProjectId}"`);

  const resp = await io.fetch(
    `https://${sanityProjectId}.api.sanity.io/v1/data/query/${sanityDataset}?query=*%5B_type%20%3D%3D%20'featureNavigation'%5D.navigationMobile.navigationTabs%20%20%7C%20order(priority%20desc%2C%20_updatedAt%20desc)%20`
  );

  const json = await resp.json()

  const {
    // @todo Type the configuration based on FE config schema
    result: [navigationTabs],
  } = json

  if (!navigationTabs || Object.keys(navigationTabs).length === 0) {
    throw new Error('GROQ query for front end nav bar did not contain a valid result');
  }

  const tabs: ISanityNavigationTab[] = await Promise.all(
    navigationTabs.map(async (sanityTab: any) => {
      const tab: ISanityNavigationTab = {
        iconPath: '',
        iconActivePath: '',
        link: sanityTab.tabLink.link,
        text: sanityTab.tabLink.text,
        hideLinkBasedOnLdFlag: sanityTab.hideLinkBasedOnLdFlag,
        hideOnDesktop: sanityTab.hideOnDesktop ?? false,
        launchDarklyAlternateMobileTab: {
          enabled: sanityTab.launchDarklyAlternateMobileTab?.enabled ?? false,
          launchDarklyFlagDependency: {
            launchDarklyFlag:
              sanityTab.launchDarklyAlternateMobileTab?.launchDarklyFlagDependency
                ?.launchDarklyFlag ?? '',
          },
          iconPath: '',
          iconActivePath: '',
          link: sanityTab.launchDarklyAlternateMobileTab?.tabLink?.link,
          text: sanityTab.launchDarklyAlternateMobileTab?.tabLink?.text,
        },
      };

      if (sanityTab.tabIconActive.en) {
        tab.iconActivePath = await fetchImageAsset(
          sanityTab.tabIconActive.en.asset._ref,
          sanityProjectId,
          sanityDataset,
          workingDirectory,
          io
        );
      }

      if (sanityTab.tabIcon.en) {
        tab.iconPath = await fetchImageAsset(
          sanityTab.tabIcon.en.asset._ref,
          sanityProjectId,
          sanityDataset,
          workingDirectory,
          io
        );
      }

      if (sanityTab.launchDarklyAlternateMobileTab?.tabIcon?.en) {
        tab.launchDarklyAlternateMobileTab.iconPath = await fetchImageAsset(
          sanityTab.launchDarklyAlternateMobileTab.tabIcon.en.asset._ref,
          sanityProjectId,
          sanityDataset,
          workingDirectory,
          io
        );
      }

      if (sanityTab.launchDarklyAlternateMobileTab?.tabIconActive?.en) {
        tab.launchDarklyAlternateMobileTab.iconActivePath = await fetchImageAsset(
          sanityTab.launchDarklyAlternateMobileTab.tabIconActive.en.asset._ref,
          sanityProjectId,
          sanityDataset,
          workingDirectory,
          io
        );
      }

      return tab;
    })
  );

  console.log(CYAN, '[RN Sanity Prebuild] Created sanity nav');
  return tabs;
}

// TODO: It would be nice if we used the sanity image url builder
async function fetchImageAsset(
  asset: string,
  sanityProjectId: string,
  sanityDataset: string,
  workingDirectory: string,
  io: Io
): Promise<string> {
  const _ref = asset
    .replace(/^image\-/, '')
    .replace('-svg', '.svg')
    .replace('-png', '.png');
  const iconPath = `assets/images/sanity/${_ref}`;
  const sanityUrl = `https://cdn.sanity.io/images/${sanityProjectId}/${sanityDataset}/${_ref}?fm=png&w=50&h=50`;

  return new Promise<string>(async (resolve, reject) => {
    const response = await io.fetch(sanityUrl);

    console.log(CYAN, `[RN Sanity Prebuild] Downloaded ${sanityUrl}!`);
    const pngPath = iconPath.replace('.svg', '.png');

    const result = io.writeFile!(io.pathJoin!(workingDirectory, `/${pngPath}`), (response as any).body);
    result.then(() => {
      resolve(pngPath);
      console.log(CYAN, `[RN Sanity Prebuild] Created ${pngPath}!`);
    });
    result.catch((error: any) => {
      console.log(CYAN, '[RN Sanity Prebuild]', { error });
    });
  });
}
