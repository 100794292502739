export const userMarkerSvg = `
<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 24C19.5228 24 24 19.5228 24 14C24 8.47715 19.5228 4 14 4C8.47715 4 4 8.47715 4 14C4 19.5228 8.47715 24 14 24Z"
      fill="#FFFFFF"
    />
  </g>
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 21C17.866 21 21 17.866 21 14C21 10.134 17.866 7 14 7C10.134 7 7 10.134 7 14C7 17.866 10.134 21 14 21Z"
      fill="#3780D6"
    />
  </g>
</svg>
`;
