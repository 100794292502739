import React from 'react';

import { makeUclComponent } from '@rbilabs/universal-components';

import LoadingAnimation from 'components/loading-animation';
import { useIsDesktopBreakpoint } from 'hooks/use-media-query';

import { LoyaltyLoaderContainer } from './loyalty-loader.styled';

const LoyaltyLoader = ({ ...props }) => {
  const isDesktop = useIsDesktopBreakpoint();

  return (
    <LoyaltyLoaderContainer {...props} width={isDesktop ? 180 : 140}>
      <LoadingAnimation />
    </LoyaltyLoaderContainer>
  );
};

export default makeUclComponent(LoyaltyLoader);
