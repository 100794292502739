import React, { forwardRef } from 'react';

import { TextInput as RNTextInput } from 'react-native';
import { useMaskedInputProps } from 'react-native-mask-input';

import TextInput from '../text-input';

import { TextInputMaskProps } from './types';

const TextInputMask = forwardRef<RNTextInput, TextInputMaskProps>(
  (
    {
      value = '',
      onChangeText,
      mask,
      showObfuscatedValue,
      placeholderFillCharacter,
      obfuscationCharacter,
      ...rest
    },
    ref
  ) => {
    const maskedInputProps = useMaskedInputProps({
      value,
      onChangeText,
      mask,
      showObfuscatedValue,
      placeholderFillCharacter,
      obfuscationCharacter,
    });

    return <TextInput ref={ref} {...maskedInputProps} {...rest} />;
  }
);
export default TextInputMask;
