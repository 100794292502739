import { useRoute } from 'hooks/navigation/use-route';
import { RunningExperiments, useExperiments } from 'state/experiments';
import { routes } from 'utils/routing';

enum HideTsmPointsNotificationLoyaltyBannerVariants {
  Treatment = 'treatment',
}

export const useHideTsmPointsNotificationLoyaltyBannerExperiment = () => {
  const { variant } = useExperiments();
  const { pathname } = useRoute();

  return (
    pathname === routes.base &&
    variant(
      RunningExperiments.HideTsmPointsNotificationLoyaltyBanner,
      HideTsmPointsNotificationLoyaltyBannerVariants.Treatment
    )
  );
};
