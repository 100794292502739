// TODO remove most of this file because email is how we are now doing this with no intent to change near-term

export enum OTPAuthDeliveryMethod {
  None = 'None',
  Email = 'Email',
  SMS = 'SMS',
  All = 'All',
}

export function isOTPSMSEnabled(
  flagValue?: OTPAuthDeliveryMethod
): flagValue is OTPAuthDeliveryMethod.All | OTPAuthDeliveryMethod.SMS {
  return flagValue === OTPAuthDeliveryMethod.SMS || flagValue === OTPAuthDeliveryMethod.All;
}
