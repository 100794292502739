import { useRef } from 'react';

export function usePriorValue<TValue, TInitialValue>(
  currentValue: TValue,
  initialValue: TInitialValue
): TValue | TInitialValue {
  const { current: valueHistory } = useRef<[TValue | TInitialValue, TValue | TInitialValue]>([
    initialValue,
    initialValue,
  ]);
  if (valueHistory[1] !== currentValue) {
    valueHistory[0] = valueHistory[1];
    valueHistory[1] = currentValue;
  }
  return valueHistory[0];
}
