import { Box, Header, Text, VStack, makeUclComponent } from '@rbilabs/universal-components';

import Picture from 'components/picture';

export const Section = Box.withConfig({
  width: 'full',
  marginX: {
    base: 'auto',
    md: '$0',
  },
  paddingY: '$6',
  flexDirection: {
    base: 'column',
    md: 'row',
  },
});

export const CardTitle = Header.withConfig({
  variant: 'headerTwo',
  margin: '$0',
  padding: '$0',
  fontWeight: 'bold',
});

export const CardContent = VStack.withConfig({
  flex: 1,
  padding: {
    base: '$0',
    md: '$2',
  },
});

export const Card = Box.withConfig<{ $isFirst: boolean; $isLast: boolean }>(
  ({ $isFirst, $isLast }) => ({
    flex: 1,
    flexDirection: {
      base: 'row-reverse',
      md: 'column',
    },
    flexWrap: {
      base: 'nowrap',
      md: 'wrap',
    },
    color: Styles.color.black,
    background: {
      md: Styles.color.grey.five,
    },
    marginBottom: {
      base: '$5',
      md: 0,
    },
    marginLeft: {
      base: '$0',
      md: $isFirst ? '$0' : '$2',
    },
    marginRight: {
      base: '$0',
      md: $isLast ? '$0' : '$2',
    },
    borderTopRadius: {
      md: 'sm',
    },
  })
);

export const CardImageContainer = Box.withConfig({
  minWidth: {
    base: '100px',
    md: '100%',
  },
  alignItems: {
    base: 'flex-end',
    md: 'flex-start',
  },
  justifyContent: {
    base: 'center',
    md: 'flex-start',
  },
  marginLeft: {
    base: '$4',
    md: '$0',
  },
});

export const CardImage = makeUclComponent(Picture).withConfig({
  width: 'full',
  marginBottom: '$4',
  borderRadius: 'sm',
  borderBottomRadius: {
    md: '$0',
  },
});

export const CardBody = Text.withConfig({
  marginY: '$2',
  marginX: '$0',
});
