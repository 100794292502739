import React from 'react';

import { Helmet } from 'components/helmet';
import { useLocale } from 'state/intl';
import { fullBrandName } from 'utils/environment';

const titleTemplate = `%s - ${fullBrandName()}`;

export function HelmetHTML() {
  const { language } = useLocale();

  return (
    <Helmet titleTemplate={titleTemplate} defaultTitle={fullBrandName()}>
      <html lang={language} />
    </Helmet>
  );
}
