import React from 'react';

import AppHeader from 'components/app-header';
import Layout from 'components/layout';
import LayoutBottomContainer from 'components/layout/layout-bottom-container';
import MobileNavigation from 'components/mobile-web-navigation-bar';
import { GlobalStylesheet } from 'styles/global';

export const AppContainerInner: React.FC = React.memo(() => {
  return (
    <>
      <GlobalStylesheet />
      <AppHeader />
      <Layout />
      <LayoutBottomContainer>
        <MobileNavigation />
      </LayoutBottomContainer>
    </>
  );
});
