import { setContext } from '@apollo/client/link/context';

import { getCurrentSession } from 'remote/auth';

import { AUTHORIZED_KEY } from './auth-required-directive';

export const withAuthHeaders = setContext((_, previousContext) =>
  getCurrentSession().then(session => {
    if (!session) {
      return previousContext;
    }

    const headers = typeof previousContext.headers === 'object' ? previousContext.headers : null;
    return {
      ...previousContext,
      headers: {
        ...headers,
        authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
      },
      [AUTHORIZED_KEY]: true,
    };
  })
);
