import { useCallback, useEffect, useRef, useState } from 'react';

import {
  addDays,
  getISODay,
  isWithinInterval,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
  subDays,
} from 'date-fns';
import { isEqual } from 'lodash-es';
import { compose } from 'utils';

import { IWeekDays } from 'generated/sanity-graphql';
import useInterval from 'hooks/use-interval';
import logger from 'utils/logger';

import { IDayPartBoundary, IFeatureMenuDayPart, IValidDayPart } from '../types';

export const isValidDayPart = (dayPart: IFeatureMenuDayPart): dayPart is IValidDayPart => {
  return Boolean(dayPart && dayPart.key && dayPart.endTime && dayPart.startTime);
};

// returns a function that converts
// a Date object to the given hour
// and minute on the same day
const setTime = (hour: number, minute: number) =>
  compose<[Date], Date, Date, Date, Date>(
    date => setHours(date, hour),
    date => setMinutes(date, minute),
    date => setSeconds(date, 0),
    date => setMilliseconds(date, 0)
  );

export const transformDateWithTimeString = (timeString: string): Date => {
  const now = new Date();
  const time = timeString.split(':').map(t => parseInt(t, 10));

  if (time.length !== 2 || time.some(t => isNaN(t))) {
    logger.error({ message: 'Received invalid daypart time-string', timeString });
    return now;
  }

  const [hours, minutes] = time;

  return setTime(hours, minutes)(now);
};

function dayPartTimeStringToTodayTime(
  timeString: string,
  tomorrow?: boolean,
  yesterday?: boolean
): Date {
  const transformedDate = transformDateWithTimeString(timeString);
  if (tomorrow) {
    return addDays(transformedDate, 1);
  }

  if (yesterday) {
    return subDays(transformedDate, 1);
  }

  return transformedDate;
}

export const getWeekDay = (date: Date) => {
  const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  const weekDayIndex = getISODay(date) - 1;
  return weekDays[weekDayIndex];
};

export const isDisabledOnWeekDay = (
  date: Date,
  disabledWeekDays: IWeekDays | null | undefined
): boolean => {
  if (!disabledWeekDays) {
    return false;
  }
  const weekDay = getWeekDay(date);
  return Boolean(disabledWeekDays[weekDay]);
};

/**
 * Computes the boundaries of day parts based on the provided day parts.
 *
 * @param {ReadonlyArray<IValidDayPart>} dayParts - Array of valid day parts.
 * @returns {IDayPartBoundary[]} - Array of day part boundaries.
 */
const computeDayPartBoundaries = (dayParts: ReadonlyArray<IValidDayPart>) => {
  const now = new Date();
  return dayParts.map<IDayPartBoundary>(({ endTime, key, startTime, weekDays }) => {
    const startTimeDate = transformDateWithTimeString(startTime);
    const endTimeDate = transformDateWithTimeString(endTime);

    const isStartingAfterEndTime = startTimeDate > endTimeDate;

    // If startTime is after endTime, the endTime is tomorrow. However, it's only tomorrow if we are currently past the endTime
    const isEndTimeTomorrow = isStartingAfterEndTime && now > endTimeDate;
    // If startTime is after endTime, the startTime is tomorrow. However, it's only tomorrow if we are currently before the endTime
    const isStartTimeTomorrow = isStartingAfterEndTime && now < endTimeDate;

    return {
      endTime: dayPartTimeStringToTodayTime(endTime, isEndTimeTomorrow),
      key,
      startTime: dayPartTimeStringToTodayTime(startTime, false, isStartTimeTomorrow),
      weekDays,
    };
  });
};

/**
 * Computes the active day parts based on the current time and day part boundaries.
 *
 * @param {IDayPartBoundary[]} dayPartBoundaries - Array of day part boundaries.
 * @param {Date} now - Current date and time.
 * @returns {IDayPartBoundary[]} - Array of active day part boundaries.
 */
const computeActiveDayParts = (
  dayPartBoundaries: IDayPartBoundary[],
  now: Date
): IDayPartBoundary[] => {
  if (!dayPartBoundaries.length) {
    return [];
  }

  try {
    return dayPartBoundaries.filter(
      ({ endTime, startTime, weekDays }) =>
        isWithinInterval(now, { start: startTime, end: endTime }) &&
        !isDisabledOnWeekDay(now, weekDays)
    );
  } catch (error) {
    logger.error({ dayPartBoundaries, error, message: 'Error finding active dayparts' });
    return [];
  }
};

// 60 (s) * 1000 (ms)
export const REFRESH_INTERVAL = 60 * 1000;

/**
 * Custom hook to compute and manage active day parts based on the current time and provided day parts.
 *
 * @param {ReadonlyArray<IValidDayPart>} dayParts - Array of valid day parts.
 * @returns {[IDayPartBoundary[], () => void]} - Array of active day part boundaries and a function to force refresh.
 */
export default function useActiveDayParts({
  dayParts,
}: {
  dayParts: ReadonlyArray<IValidDayPart>;
}): [IDayPartBoundary[], () => void] {
  const [dayPartBoundaries, setDayPartBoundaries] = useState<IDayPartBoundary[]>([]);
  const [activeDayParts, setActiveDayParts] = useState<IDayPartBoundary[]>([]);
  const lastRefreshTimeRef = useRef<Date | null>(null);

  // Compute and set dayPartBoundaries when dayParts changes
  useEffect(() => {
    const newBoundaries = computeDayPartBoundaries(dayParts);
    setDayPartBoundaries(newBoundaries);
  }, [dayParts]);

  // Compute and set activeDayParts when dayPartBoundaries changes
  useEffect(() => {
    const now = new Date();
    setActiveDayParts(computeActiveDayParts(dayPartBoundaries, now));
  }, [dayPartBoundaries]);

  // Updates the active day parts state if the new active day parts are different from the current ones.
  const setActiveDayPartsIfChanged = useCallback((newActiveDayParts: IDayPartBoundary[]) => {
    setActiveDayParts(currentlyActiveDayParts =>
      !isEqual(currentlyActiveDayParts, newActiveDayParts)
        ? newActiveDayParts
        : currentlyActiveDayParts
    );
  }, []);

  // Forces a refresh of the active day parts if more than REFRESH_INTERVAL seconds have passed since the last refresh.
  const forceRefreshActiveDayParts = useCallback(() => {
    const now = new Date();
    if (
      activeDayParts.length === 0 ||
      !lastRefreshTimeRef.current ||
      now.getTime() - lastRefreshTimeRef.current.getTime() > REFRESH_INTERVAL
    ) {
      lastRefreshTimeRef.current = now;

      // Compute new day part boundaries
      const newBoundaries = computeDayPartBoundaries(dayParts);
      setDayPartBoundaries(newBoundaries);

      // Compute new active day parts based on updated boundaries
      setActiveDayPartsIfChanged(computeActiveDayParts(newBoundaries, now));
    }
  }, [activeDayParts, dayParts, setActiveDayPartsIfChanged]);

  // Periodically calls the force refresh function every REFRESH_INTERVAL seconds
  useInterval(() => {
    forceRefreshActiveDayParts();
  }, REFRESH_INTERVAL);

  return [activeDayParts, forceRefreshActiveDayParts];
}
