import { useFeatureLoyaltyShortCodePaymentSuccessModalQuery } from 'generated/sanity-graphql';
import { FEATURE_LOYALTY_UI_SINGLETON } from 'hooks/use-feature-loyalty-ui/use-feature-loyalty-ui';

export const useShortCodePaymentSuccessModal = () => {
  const { data, loading } = useFeatureLoyaltyShortCodePaymentSuccessModalQuery({
    variables: { featureLoyaltyUIId: FEATURE_LOYALTY_UI_SINGLETON },
  });

  return { data: data?.LoyaltyUI, loading };
};
