import { useMemo } from 'react';

import { useIntl } from 'react-intl';

import logger from 'utils/logger';

import type { ICmsQuestCard, ICmsQuestCardUI, ICmsQuestDetail } from '../cms-hooks/types';
import { getLinkText, getQuestStartedBadgeText } from '../custom-text';
import type { IQuest } from '../loyalty-quest/types';
import { isIncentiveRedeemed, isQuestActivated, isQuestCompleted } from '../loyalty-quest/utils';

import type { IUseParseQuestCardData } from './types';
import {
  formatCompletedChallengeMessage,
  getButtonText,
  getRangeDateDescription,
  getRedeemedDescription,
} from './utils';

// TODO: remove this hook and use the context in the components that need the IQuest.
export const useParseQuestCardData = (
  quest: IQuest | undefined,
  cmsQuestCard: ICmsQuestCard | undefined,
  cmsQuestCardUI: ICmsQuestCardUI | null | undefined,
  cmsQuestDetail: ICmsQuestDetail | null
): IUseParseQuestCardData => {
  const { formatMessage } = useIntl();

  const { startDate, endDate, status, incentiveType, lastCompletion } = quest || {};
  const { image, name, imageDescription } = cmsQuestCard || {};
  const incentiveRedeemed = isIncentiveRedeemed(lastCompletion);

  return useMemo(() => {
    const isCompleted = isQuestCompleted(status);
    const buttonText = getButtonText({ questStatus: status, incentiveRedeemed, cmsQuestCardUI });
    let badgeText = '';
    let description = '';

    try {
      if (isCompleted) {
        description = incentiveRedeemed
          ? getRedeemedDescription({ incentiveType, cmsQuestDetail })
          : cmsQuestCardUI?.completedDescription?.locale ?? '';

        description = formatCompletedChallengeMessage({ description, quest });

        badgeText = formatMessage({ id: 'completed' });
      } else if (startDate && endDate) {
        description = getRangeDateDescription({ startDate, endDate, formatMessage });
      }
      if (isQuestActivated(status)) {
        badgeText = getQuestStartedBadgeText(formatMessage);
      }
    } catch (e) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      logger.error(e);
    }

    const questSectionData: IUseParseQuestCardData = {
      image: image?.locale || null,
      imageDescription: imageDescription?.locale || '',
      badgeText,
      buttonText,
      title: name?.locale || '',
      description,
      linkText: getLinkText(formatMessage),
    };

    return questSectionData;
  }, [
    cmsQuestCardUI,
    cmsQuestDetail,
    endDate,
    formatMessage,
    image,
    imageDescription,
    incentiveRedeemed,
    incentiveType,
    name,
    startDate,
    status,
  ]);
};
