import Link from 'components/link';

export const NavLinkStyles = {
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '64px',
  paddingY: '8px',
  flex: 1,
};

const StyledLink = Link.withConfig(NavLinkStyles);

export default StyledLink;
