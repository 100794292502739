import { Box, BrandLogo } from '@rbilabs/universal-components';

export const PictureContainer = Box.withConfig({
  minWidth: '$32',
  minHeight: '$6',
});

export const DefaultLoyaltyLogo = BrandLogo.withConfig({
  padding: 0,
});
