import { useCallback } from 'react';

import { MenuObjectTypes } from 'enums/menu';
import {
  IGetComboPlusQuery,
  IGetItemPlusQuery,
  IGetPickerPlusQuery,
  useGetComboPlusLazyQuery,
  useGetItemPlusLazyQuery,
  useGetPickerPlusLazyQuery,
} from 'generated/sanity-graphql';
import { useGetVariantContentOptions } from 'pages/menu/hooks/use-get-variant-content-options';
import { withLazySimpleCache } from 'state/graphql/with-simple-cache';
import { IMenuObject } from 'state/menu/types';

import { getValidDataFromQuery } from './get-valid-data-from-query';

const useComboQuery = withLazySimpleCache(useGetComboPlusLazyQuery);
const useItemQuery = withLazySimpleCache(useGetItemPlusLazyQuery);
const usePickerQuery = withLazySimpleCache(useGetPickerPlusLazyQuery);

export const useGetMenuObjectLazyQuery = () => {
  const [fetchCombo] = useComboQuery();
  const [fetchItem] = useItemQuery();
  const [fetchPicker] = usePickerQuery();

  const getQueryFn = useCallback(
    (type: string) => {
      if (type === MenuObjectTypes.COMBO) {
        return fetchCombo;
      }
      if (type === MenuObjectTypes.ITEM) {
        return fetchItem;
      }
      if (type === MenuObjectTypes.PICKER) {
        return fetchPicker;
      }
      return null;
    },
    [fetchCombo, fetchItem, fetchPicker]
  );

  const getVariantContentOptions = useGetVariantContentOptions();

  return useCallback(
    (menuObjectId: string) => {
      const [type, sanityId] = menuObjectId.split(/-(.*)/);

      const queryFn = getQueryFn(type);

      if (!queryFn) {
        return Promise.resolve(null);
      }

      return new Promise<IMenuObject | null>((resolve, reject) => {
        queryFn({
          variables: { id: sanityId },
          allowFromCache: true,
          onCompleted: (data: IGetComboPlusQuery | IGetItemPlusQuery | IGetPickerPlusQuery) => {
            const menuObject = getValidDataFromQuery(data, getVariantContentOptions);
            if (menuObject) {
              resolve(menuObject);
            } else {
              reject(null);
            }
          },
          onError: () => {
            reject(null);
          },
        });
      });
    },
    [getQueryFn, getVariantContentOptions]
  );
};
