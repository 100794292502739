import { Icon } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';

import { Container, Cta, Details, ServiceModeMessage } from './styled';
import { IOrderStatusBarData } from './types';

export const TopOrderStatusBar = ({ data }: { data: IOrderStatusBarData }) => {
  if (!data) {
    return null;
  }

  return (
    <Container>
      <Icon size={5} color={theme.token('icon-button-primary')} variant={data.icon} />
      <ServiceModeMessage>{data.heading}</ServiceModeMessage>
      <Cta onPress={data.cta?.action}>
        <Details>{data.cta.text}</Details>
        <Icon size="xs" name="custom:Next" color={theme.token('icon-button-primary')} />
      </Cta>
    </Container>
  );
};
