import { getDeviceId } from 'utils/device-id';

import { ILoggerMiddleware } from './types';

const deviceIdWrapper = { current: 'NOT_SET' };

// It is possible for log(s) to be called before this promise resolves (likely not though)
// this is better than rewrite logging to make it async / potentially introduce event loop delay
getDeviceId().then(deviceId => {
  deviceIdWrapper.current = deviceId || 'UNKNOWN';
});

const deviceIdMiddleware = (attributes => {
  return { ...attributes, deviceId: deviceIdWrapper.current };
}) as ILoggerMiddleware;

export default deviceIdMiddleware;
