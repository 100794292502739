import React, { forwardRef } from 'react';

import { Icon, makeNBComponent, withConfig } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import Currency from 'components/currency';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { theme } from 'styles/configure-theme';

import { FloatingLink } from './styled';

const CurrencyBold = withConfig(makeNBComponent(Currency), {
  fontWeight: 'bold',
  fontSize: 'sm',
});

export interface ICheckoutButtonLink {
  amount: number;
  to: string;
  logMobileCheckoutEvent: () => void;
}

const CheckoutButtonLink = forwardRef(
  ({ amount, to, logMobileCheckoutEvent }: ICheckoutButtonLink, ref) => {
    const { formatMessage } = useIntl();
    const enableHomeMenuFabButton = useFlag(LaunchDarklyFlag.ENABLE_MAIN_FAB_BUTTON);

    if (enableHomeMenuFabButton) {
      return null;
    }

    return (
      <FloatingLink
        // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; underlayColor: any; tes... Remove this comment to see the full error message
        underlayColor={theme.token('icon-button-secondary')}
        testID="cart-button-mobile"
        aria-label={formatMessage({ id: 'checkout' })}
        onPress={logMobileCheckoutEvent}
        to={to}
        ref={ref}
      >
        <>
          <CurrencyBold amount={amount} />
          <Icon variant="cart" color={theme.token('icon-button-primary')} width="4" aria-hidden />
        </>
      </FloatingLink>
    );
  }
);

export default CheckoutButtonLink;
