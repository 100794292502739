import { AppState } from 'react-native';

import { UI_RESPONSE_TIME_TO_REACHABILITY_FAILED } from './status';

// docs say its null at app start, lets force as active
export const appStateInfo = {
  previous: AppState.currentState || 'active',
  current: AppState.currentState || 'active',
  wasRecentlyBackgrounded: false,
};

// there is some weird things that happen with network health check / network offline timers
// around app or website being backgrounded. this is an attempt to avoid this by
// making this info available to offline reporting
AppState.addEventListener('change', nextAppState => {
  if (nextAppState !== 'active') {
    appStateInfo.wasRecentlyBackgrounded = true;
  }

  if (nextAppState === 'active' && appStateInfo.previous !== 'active') {
    setTimeout(() => {
      appStateInfo.wasRecentlyBackgrounded = false;
      // set to be slightly before UI value to avoid UI messaging flake related to app backgrounding and timers
    }, UI_RESPONSE_TIME_TO_REACHABILITY_FAILED - 1000);
  }

  appStateInfo.previous = appStateInfo.current;
  appStateInfo.current = nextAppState;
});
