import React from 'react';

import LottieView from 'lottie-react-native';

import { StaticImages, useStaticLottieUri } from 'components/picture/static-image';

import {
  OptInDialogBody,
  OptInDialogBodyContent,
  OptInDialogBodyText,
  OptInDialogHeader,
  OptInDialogHeading,
} from './styled';
import type { IOptInLoadingContentProps } from './types';

export const OptInLoadingContent = ({ heading, description }: IOptInLoadingContentProps) => {
  const uri = useStaticLottieUri(StaticImages.BurgerBounceLoader);

  return (
    <>
      {uri && <LottieView source={{ uri }} autoPlay style={{ width: '100%' }} />}

      <OptInDialogHeader>
        <OptInDialogHeading>{heading}</OptInDialogHeading>
      </OptInDialogHeader>

      <OptInDialogBodyContent>
        <OptInDialogBody>
          <OptInDialogBodyText>{description}</OptInDialogBodyText>
        </OptInDialogBody>
      </OptInDialogBodyContent>
    </>
  );
};
