/* eslint-disable max-len */

export const bkTokens = {
  // 'background-active': bkPrimitives.colors.fieryRed,
  // 'background-button-hover-reversed': bkPrimitives.colors.whiteOpacity[10],
  // 'background-button-primary-default': bkPrimitives.colors.fieryRed,
  // 'background-button-primary-default-reversed': bkPrimitives.colors.white,
  // 'background-button-primary-hover': bkPrimitives.colors.fieryRedDarken10,
  // 'background-button-secondary-hover': bkPrimitives.colors.fieryRedOpacity10,
  // 'background-checkbox-disabled': bkPrimitives.colors.blackOpacity[30],
  // 'background-checked': bkPrimitives.colors.bbqBrown,
  // 'background-chip': bkPrimitives.colors.bbqBrown,
  // 'background-chip-rewards': bkPrimitives.colors.bbqBrown,
  // 'background-chip-rewards-locked': bkPrimitives.colors.mayoEggWhite,
  // 'background-default': bkPrimitives.colors.mayoEggWhite,
  // 'background-dialog': bkPrimitives.colors.white,
  // 'background-error': bkPrimitives.$error,
  // 'background-focus': bkPrimitives.colors.blackOpacity[4],
  // 'background-focus-reversed': bkPrimitives.colors.whiteOpacity[4],
  // 'background-footer': bkPrimitives.colors.bbqBrown,
  // 'background-hero-light': bkPrimitives.colors.mayoEggWhite,
  // 'background-hero-reversed': bkPrimitives.colors.bbqBrown,
  // 'background-icon-hover-focus': bkPrimitives.colors.blackOpacity[4],
  // 'background-icon-hover-focus-reversed': bkPrimitives.colors.whiteOpacity[4],
  // 'background-inline-alert': bkPrimitives.colors.blackOpacity[4],
  // 'background-input': bkPrimitives.colors.white,
  // 'background-input-disabled': bkPrimitives.colors.blackOpacity[4],
  // 'background-pattern': bkPrimitives.colors.white,
  // 'background-switch-thumb': bkPrimitives.colors.white,
  // 'background-switch-track': bkPrimitives.colors.bbqBrown,
  // 'background-switch-track-disabled': bkPrimitives.colors.blackOpacity[30],
  // 'border-color-active': bkPrimitives.colors.fieryRed,
  // 'border-color-button-secondary': bkPrimitives.colors.fieryRed,
  // 'border-color-button-secondary-reversed': bkPrimitives.colors.white,
  // 'border-color-checked': bkPrimitives.colors.bbqBrown,
  // 'border-color-default': bkPrimitives.colors.blackOpacity[10],
  // 'border-color-disabled': bkPrimitives.colors.blackOpacity[30],
  // 'border-color-disabled-reversed': bkPrimitives.colors.whiteOpacity[30],
  // 'border-color-error': bkPrimitives.$error,
  // 'border-color-hover': bkPrimitives.colors.bbqBrown,
  // 'border-color-input': bkPrimitives.colors.blackOpacity[30],
  // 'border-color-input-default': bkPrimitives.colors.blackOpacity[30],
  // 'border-color-options': bkPrimitives.colors.blackOpacity[10],
  // 'border-color-reversed': bkPrimitives.colors.whiteOpacity[10],
  // 'border-radius-button': '100%',
  // 'border-radius-chip': '4px',
  // 'border-radius-input': '4px',
  // 'border-radius-input-icon-hover-focus': '100%',
  // 'border-radius-pattern': '20px',
  // 'border-radius-pattern-interactive': '12px',
  // 'border-radius-toggle-default': '0',
  // 'border-radius-toggle-end': '0 4px 4px 0',
  // 'border-radius-toggle-start': '4px 0 0 4px',
  // 'border-width-button-secondary': '1px',
  // 'border-width-input': '1px',
  // 'border-width-tabs': '1px',
  // 'box-shadow-button': `0 0 0 4px ${bkPrimitives.colors.blackOpacity[10]}`,
  // 'box-shadow-component': `0 0 0 4px ${bkPrimitives.colors.blackOpacity[10]}`,
  // 'box-shadow-component-reversed': `0 0 0 4px ${bkPrimitives.colors.whiteOpacity[30]}`,
  // 'box-shadow-pattern': `0 0 0 4px ${bkPrimitives.colors.blackOpacity[10]}`,
  // 'box-shadow-pattern-reversed': `0 0 0 4px ${bkPrimitives.colors.whiteOpacity[30]}`,
  // 'box-shadow-toggle': `inset 0 0 0 4px ${bkPrimitives.colors.blackOpacity[10]}`,
  // 'drop-shadow-bottom-nav': `0 -1px 1px ${bkPrimitives.colors.blackOpacity[10]}`,
  // 'drop-shadow-component': `0 4px 4px ${bkPrimitives.colors.blackOpacity[10]}`,
  // 'drop-shadow-top-nav': `0 1px 1px ${bkPrimitives.colors.blackOpacity[10]}`,
  // 'height-button-large': '48px',
  // 'height-button-small': '32px',
  // 'height-checkbox-radio': '20px',
  // 'height-chip': '24px',
  // 'height-icon': '24px',
  // 'height-icon-small': '16px',
  // 'height-input': '48px',
  // 'height-tabs': '56px',
  // 'icon-active': bkPrimitives.colors.fieryRed,
  // 'icon-button-primary': bkPrimitives.colors.white,
  // 'icon-button-primary-reversed': bkPrimitives.colors.bbqBrown,
  // 'icon-button-reversed': bkPrimitives.colors.white,
  // 'icon-button-secondary': bkPrimitives.colors.fieryRed,
  // 'icon-button-tertiary-default': bkPrimitives.colors.bbqBrown,
  // 'icon-button-tertiary-hover': bkPrimitives.colors.fieryRed,
  // 'icon-default': bkPrimitives.colors.bbqBrown,
  // 'icon-disabled': bkPrimitives.colors.blackOpacity[30],
  // 'icon-disabled-reversed': bkPrimitives.colors.whiteOpacity[30],
  // 'icon-form-checkbox': bkPrimitives.colors.white,
  // 'icon-form-input-action': bkPrimitives.colors.bbqBrown,
  // 'icon-form-input-default': bkPrimitives.colors.blackOpacity[30],
  // 'icon-reversed': bkPrimitives.colors.mayoEggWhite,
  // 'padding-button-icon-end': `${bkPrimitives.spacing.spacing0} ${bkPrimitives.spacing.spacing2} ${bkPrimitives.spacing.spacing0} ${bkPrimitives.spacing.spacing4}`,
  // 'padding-button-icon-start': `${bkPrimitives.spacing.spacing0} ${bkPrimitives.spacing.spacing4} ${bkPrimitives.spacing.spacing0} ${bkPrimitives.spacing.spacing2}`,
  // 'padding-button-large': `${bkPrimitives.spacing.spacing0} ${bkPrimitives.spacing.spacing5}`,
  // 'padding-button-small': `${bkPrimitives.spacing.spacing0} ${bkPrimitives.spacing.spacing4}`,
  // 'padding-input': `${bkPrimitives.spacing.spacing0} ${bkPrimitives.spacing.spacing4}`,
  // 'text-active': bkPrimitives.colors.fieryRed,
  // 'text-button-primary': bkPrimitives.colors.white,
  // 'text-button-primary-reversed': bkPrimitives.colors.bbqBrown,
  // 'text-button-reversed': bkPrimitives.colors.white,
  // 'text-button-secondary': bkPrimitives.colors.fieryRed,
  // 'text-button-tertiary-default': bkPrimitives.colors.bbqBrown,
  // 'text-button-tertiary-hover': bkPrimitives.colors.fieryRed,
  // 'text-default': bkPrimitives.colors.bbqBrown,
  // 'text-disabled': bkPrimitives.$disabledText,
  // 'text-disabled-reversed': bkPrimitives.$disabledTextReversed,
  // 'text-error': bkPrimitives.$error,
  // 'text-hero-light': bkPrimitives.colors.bbqBrown,
  // 'text-hero-reversed': bkPrimitives.colors.mayoEggWhite,
  // 'text-link-default': bkPrimitives.colors.bbqBrown,
  // 'text-link-hover': bkPrimitives.colors.fieryRed,
  // 'text-link-reversed': bkPrimitives.colors.mayoEggWhite,
  // 'text-reversed': bkPrimitives.colors.mayoEggWhite,
  // 'text-style-back-nav': 'var(--font-copyOne)',
  // 'text-style-bottom-nav': 'var(--font-copyTwo)',
  // 'text-style-button-large': 'var(--font-headerThree)',
  // 'text-style-button-small': bkPrimitives.colors.headerFour,
  // 'text-style-card-copy': 'var(--font-copyTwo)',
  // 'text-style-card-header': 'var(--font-headerThree)',
  // 'text-style-chip': 'var(--font-copyTwo)',
  // 'text-style-chip-rewards': bkPrimitives.colors.headerFour,
  // 'text-style-dialog-copy': 'var(--font-copyOne)',
  // 'text-style-dialog-header': 'var(--font-headerOne)',
  // 'text-style-footer-large': 'var(--font-headerThree)',
  // 'text-style-footer-location': bkPrimitives.colors.headerFour,
  // 'text-style-footer-small': 'var(--font-copyTwo)',
  // 'text-style-form-checkbox': 'var(--font-copyOne)',
  // 'text-style-form-checkbox-header': 'var(--font-headerThree)',
  // 'text-style-form-hint': bkPrimitives.colors.formUtility,
  // 'text-style-form-input': 'var(--font-copyOne)',
  // 'text-style-form-label-default': 'var(--font-copyOne)',
  // 'text-style-form-label-value': bkPrimitives.colors.formUtility,
  // 'text-style-form-legend': 'var(--font-headerThree)',
  // 'text-style-form-options': bkPrimitives.colors.formUtility,
  // 'text-style-hero-header': 'var(--font-hero)',
  // 'text-style-hero-subheader': 'var(--font-headerThree)',
  // 'text-style-hero-terms': 'var(--font-copyTwo)',
  // 'text-style-inline-alert': 'var(--font-copyTwo)',
  // 'text-style-item-description-copy': 'var(--font-copyTwo)',
  // 'text-style-item-description-header': 'var(--font-headerThree)',
  // 'text-style-item-description-price': bkPrimitives.colors.headerFour,
  // 'text-style-item-description-subheader': bkPrimitives.colors.formUtility,
  // 'text-style-list-item': 'var(--font-copyOne)',
  // 'text-style-list-item-info': 'var(--font-copyTwo)',
  // 'text-style-list-item-restaurant': 'var(--font-headerThree)',
  // 'text-style-module': 'var(--font-headerOne)',
  // 'text-style-segment-header': 'var(--font-headerThree)',
  // 'text-style-tabs': 'var(--font-copyOne)',
  // 'text-style-top-nav': 'var(--font-copyOne)',
  // 'text-style-top-nav-right': bkPrimitives.colors.headerFour,
  // 'text-tabs-default': bkPrimitives.colors.bbqBrown,
  // 'text-toggle-selected': bkPrimitives.colors.white,
  // 'transition-component': 'all .3s ease',
  // 'width-checkbox-radio': '20px',
  // 'width-icon': '24px',
  // 'width-icon-small': '16px',
};
