import {
  selectBrazeContentCards,
  selectContentCards,
  selectUnreadContentCards,
  selectWebContentCards,
} from './content-cards/content-cards.selectors';
import {
  selectAppliedOffers,
  selectCmsOffers,
  selectDiscountAppliedCmsOffer,
  selectExtendedCmsOffersMap,
  selectIncentiveErrorsFilteredByCode,
  selectIncentiveErrorsWithCode,
  selectIncentivesIds,
  selectLoyaltyOffersInCooldown,
  selectOfferFeedbackMap,
  selectOffers,
  selectOffersLoading,
  selectPersonalizedOffers,
  selectSelectedOffer,
  selectShouldRefetchOffers,
  selectUserOffers,
  selectValidAppliedOffers,
} from './offers/offers.selectors';
import {
  selectAppliedLoyaltyRewards,
  selectAppliedLoyaltyRewardsArray,
  selectAppliedPoints,
  selectAvailableLoyaltyRewardsMap,
  selectIsPricingRewardApplication,
  selectShouldRefetchRewards,
  selectStagedCartPoints,
  selectTotalTimesRewardApplied,
} from './rewards/rewards.selectors';
import {
  select12DigitSwipeCard,
  selectLoyaltyLoading,
  selectLoyaltyUser,
  selectUser,
} from './user/user.selectors';
import {
  selectLoyaltyWalmart,
  selectLoyaltyWalmartUI,
  selectLoyaltyWalmartUILoading,
} from './walmart/walmart.selectors';

export const loyaltySelectors = {
  // Offer actions
  selectAppliedOffers,
  selectCmsOffers,
  selectExtendedCmsOffersMap,
  selectIncentiveErrorsFilteredByCode,
  selectIncentiveErrorsWithCode,
  selectIncentivesIds,
  selectOfferFeedbackMap,
  selectOffers,
  selectOffersLoading,
  selectLoyaltyOffersInCooldown,
  selectPersonalizedOffers,
  selectSelectedOffer,
  selectUserOffers,
  selectValidAppliedOffers,
  selectDiscountAppliedCmsOffer,
  selectShouldRefetchOffers,
  // Rewards actions
  selectAppliedLoyaltyRewards,
  selectAppliedLoyaltyRewardsArray,
  selectAppliedPoints,
  selectAvailableLoyaltyRewardsMap,
  selectIsPricingRewardApplication,
  selectShouldRefetchRewards,
  selectStagedCartPoints,
  selectTotalTimesRewardApplied,
  // User actions
  select12DigitSwipeCard,
  selectLoyaltyLoading,
  selectUser,
  selectLoyaltyUser,
  // Walmart UI
  selectLoyaltyWalmartUI,
  selectLoyaltyWalmartUILoading,
  selectLoyaltyWalmart,
  // Content Cards
  selectContentCards,
  selectBrazeContentCards,
  selectUnreadContentCards,
  selectWebContentCards,
};
