import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { PosVendors } from 'utils/vendor-config';

import { getPosVendorFromStore } from './get-pos-vendor-from-store';

interface IUsePosVendor {
  vendor: PosVendors | null;
}

const usePosVendor = (): IUsePosVendor => {
  const { serviceMode } = useServiceModeContext();
  const { store } = useStoreContext();
  return {
    vendor: getPosVendorFromStore(store, serviceMode),
  };
};

export default usePosVendor;
