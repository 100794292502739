import { FC, PropsWithChildren } from 'react';

import { useIntl } from 'react-intl';

import {
  Container,
  ContentContainer,
  ItemContainer,
  ItemIconContainer,
  ItemText,
  ItemsContainer,
  ItemsSection,
  SectionTitle,
  StyledIcon,
  StyledImage,
  Title,
} from './styled';

const walmartMembershipImg = require('./royal-perks-shot.png');

export const WalmartRoyalPerksMember: FC<PropsWithChildren<{}>> = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <SectionTitle>{formatMessage({ id: 'notARoyalPerksMember' })}</SectionTitle>
      <Container>
        <StyledImage source={walmartMembershipImg} />
        <ContentContainer>
          <Title>{formatMessage({ id: 'signUpRoyalPerksForFree' })}</Title>
          <ItemsSection>
            <ItemsContainer>
              <ItemContainer>
                <ItemIconContainer>
                  <StyledIcon variant="bkCrown" />
                </ItemIconContainer>
                <ItemText>{formatMessage({ id: 'earnCrowns' })}</ItemText>
              </ItemContainer>
              <ItemContainer>
                <ItemIconContainer>
                  <StyledIcon variant="offer" />
                </ItemIconContainer>
                <ItemText>{formatMessage({ id: 'exclusiveDeals' })}</ItemText>
              </ItemContainer>
              <ItemContainer>
                <ItemIconContainer>
                  <StyledIcon variant="bkOrder" />
                </ItemIconContainer>
                <ItemText>{formatMessage({ id: 'redeemFreeBK' })}</ItemText>
              </ItemContainer>
            </ItemsContainer>
          </ItemsSection>
        </ContentContainer>
      </Container>
    </>
  );
};
