import { InMemoryCache } from '@apollo/client/cache';
import { format } from 'date-fns';

import { StrictTypedTypePolicies as IGatewayTypePolicies } from 'generated/gateway-apollo';
import possibleTypesJson from 'generated/possibleTypes.json';
import { StrictTypedTypePolicies as ISanityTypePolicies } from 'generated/sanity-apollo';

import { readCmsOffers } from './utils';

const possibleTypes = possibleTypesJson.possibleTypes;

// Sanity CMS exposes RootQuery instead of Query
type ITypePolicies = Omit<ISanityTypePolicies, 'RootQuery'> &
  Omit<IGatewayTypePolicies, 'Query'> & {
    Query?: ISanityTypePolicies['RootQuery'] & IGatewayTypePolicies['Query'];
  };

const typePolicies: ITypePolicies = {
  // tokenId is used for personalized offers, couponId for national offers, in some cases there won't be a couponId but instead a cartEntry (which is an id of the cartEntry for the coupon)
  CouponUserOffersFeedbackEntry: {
    keyFields: ['tokenId', 'couponId', 'cartEntry'],
  },
  // Using `lineId` to simplify things, but this causes lots of duplicate data (https://rbictg.atlassian.net/browse/GST-2318)
  CartEntries: {
    keyFields: ['lineId'],
  },
  // Using `rbiOrderId` causes lots of duplicate data
  // Two options to improve:
  // 1. Evict cache entries for orders you no longer will use (https://rbictg.atlassian.net/browse/GST-2304)
  // 2. Find a better way to identify if an order is the same (Crypto generate a key for the contents of the entire order??)
  Order: {
    keyFields: ['rbiOrderId'],
    fields: {
      // A better custom merge function may be needed here at some point. Keep an eye on order carts displaying bad data
      cart: {
        merge: true,
      },
    },
  },
  Item: {
    fields: {
      name: {
        merge: true,
      },
      description: {
        merge: true,
      },
    },
  },
  // Enables caching loyalty User queries
  User: {
    keyFields: [],
  },
  // Don't normalize vendorConfigs, normalizing this causes issues with historical orders overriding with new data
  // Always merge new data with the assumption that the parent is normalizing in a way that guarantees the vendorConfigs will always be the same
  // ---
  // For example, Orders/CartEntries can have different vendorConfigs for the same items based on when the order was placed
  // This is why Orders/CartEntries duplicate entries using a `uuid` that is specific to that order.
  // They duplicate a lot of the same data but in doing that we can guarantee that fields like `vendorConfigs` won't be overwritten with data that doesn't match that specific order.
  VendorConfigs: {
    keyFields: false,
    merge: true,
  },
  ConfigOffer: {
    keyFields: ['loyaltyEngineId'],
  },
  OfferTemplate: {
    keyFields: ['loyaltyEngineId'],
  },
  // Cache Redirects. Requests data that already exists in the cache. Whenever a query includes a specific
  // mentioned below, the read function below executes and returns a reference to the object.
  // Apollo Client uses this reference to look up the object in its cache and return it if it's present.
  // If it isn't present, Apollo Client knows it needs to execute the query over the network.
  Query: {
    fields: {
      allConfigOffers: {
        read(_, { args, toReference, canRead }) {
          return readCmsOffers({
            toReference,
            canRead,
            offerEngineIds: args?.where?.loyaltyEngineId_in,
            typename: 'ConfigOffer',
          });
        },
      },
      allOfferTemplates: {
        read(_, { args, toReference, canRead }) {
          return readCmsOffers({
            toReference,
            canRead,
            offerEngineIds: args?.where?.loyaltyEngineId_in,
            typename: 'OfferTemplate',
          });
        },
      },
    },
  },
};

const cache = new InMemoryCache({
  possibleTypes,
  typePolicies,
});

// Alert on slow CPU blocking apollo cache operations during development.
//
// If you encounter this message consider wrapping queries with with-simple-cache functions.
//
// Q: How do I find the slow operation?
//   A: Look at network activity (likely graphql call) occurring in flipper (etc) right before the logged time of the log message
//
// Q: This log happens all the time on my machine
//   A: We may want to bump up the ALERT_TIME_MS below to be less annoying for varying dev machine CPUs
//
if (__DEV__) {
  const ALERT_TIME_MS = 300;

  let didAlertLastRound = false;
  let totals = {};

  [
    'restore',
    'extract',
    'read',
    'write',
    'modify',
    'diff',
    'watch',
    'gc',
    'retain',
    'release',
    'identify',
    'evict',
    'reset',
    'removeOptimistic',
    'batch',
    'performTransaction',
    'transformDocument',
    'transformForLink',
    'broadcastWatches',
  ].forEach(key => {
    const original = cache[key];

    if (!original) {
      return;
    }

    cache[key] = function () {
      const start = global.performance.now();
      const result = original.apply(this, arguments);
      const end = global.performance.now();
      const operationTimeMs = end - start;

      if (operationTimeMs > ALERT_TIME_MS) {
        didAlertLastRound = true;
        // eslint-disable-next-line no-console
        console.warn(
          `🐢 [ApolloCache] SLOW  ${key} - ${Math.round(operationTimeMs)}ms`,
          arguments,
          `@ ${format(new Date(), 'HH:mm:ss.SSS')}`
        );
      }

      totals[key] = (totals[key] || 0) + operationTimeMs;
      return result;
    };
  });

  setInterval(() => {
    if (Object.keys(totals).length && didAlertLastRound) {
      // eslint-disable-next-line no-console
      console.log(
        '\nRecent Apollo Cache operation times:\n',
        Object.entries(totals)
          .map(([key, value]) => `${key}: ${Math.round(value as any)} ms`)
          .join('\n'),
        '\nApollo Cache total size in JSON bytes: ',
        JSON.stringify(cache.extract()).length.toLocaleString()
      );
      didAlertLastRound = false;
      totals = {};
    }
  }, 10000);
}

export const getConfiguredCache = () => cache;
