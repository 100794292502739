import { useCallback, useState } from 'react';

import { useLocale } from 'state/intl';

export const useLayoutLanguageSelectorModal = () => {
  const { setHasShownLocaleSelector } = useLocale();

  const [show, setShow] = useState(false);

  const handleModalDismiss = useCallback(() => {
    setHasShownLocaleSelector();
    setShow(false);
  }, [setHasShownLocaleSelector]);

  return {
    handleModalDismiss,
    showLanguageSelectorModal: show,
  };
};
