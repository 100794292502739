import type { ComponentProps, ComponentType } from 'react';
import { forwardRef, isValidElement } from 'react';

import mergeWith from 'lodash/mergeWith';

const customizer = (objValue: any, srcValue: any) => {
  if (
    // prevents merging children
    isValidElement(srcValue) ||
    isValidElement(objValue) ||
    // prevents merging arrays
    Array.isArray(objValue) ||
    Array.isArray(srcValue)
  ) {
    return srcValue;
  }

  return undefined;
};

const mergeProps = (
  props: Record<string, any>,
  appliedProps: Record<string, any> | ((input: Record<string, any>) => any)
) =>
  typeof appliedProps === 'function'
    ? mergeWith({}, props, appliedProps(props), customizer)
    : mergeWith({}, appliedProps, props, customizer);

/**
 * Applies props to a component as a new component. Similar to
 * `Styled(Component)` but only with props.
 *
 * Features:
 * - Deep merges props with overwrite order of `JSX > outer > inner`
 * - Overwrites arrays instead of merging.
 * - Overwrites function props instead of composing them.\
 *   *(Note: this can be changed on feature request.)*
 * - Passing a function as prop to use and modify props in order of
 *   `Original props > outer > inner`.
 * - Passes `refs` automatically if source component is forwardRef.
 * ## Example
 * ```tsx
 * const NewComponent = withConfig(SampleComponent,{someProp:true});
 * ```
 *
 * ## Custom Props Example
 * ```tsx
 * const NewComponent = withConfig<typeof NewComponent, {a:boolean}>
 *  ({a, props}) => ({
 *    color: a ? 'black': props.color
 *  });
 * ```
 */
export const withConfig = <
  C extends ComponentType<any> | React.ForwardRefExoticComponent<any & React.RefAttributes<any>>,
  Props extends {}
>(
  component: C,
  appliedProps:
    | Partial<ComponentProps<C> & Omit<Props, keyof ComponentProps<C>>>
    | ((props: ComponentProps<C> & Props) => Partial<ComponentProps<C>>)
) => {
  const Component = component as any;

  return (forwardRef((props: ComponentProps<C>, ref: any) => (
    <Component {...mergeProps(props, appliedProps)} ref={ref} />
  )) as unknown) as ComponentType<ComponentProps<C> & Props>;
};

type WithConfigMethod<C extends ComponentType<any>> = <Props>(
  props:
    | Partial<ComponentProps<C> & Omit<Props, keyof ComponentProps<C>>>
    | ((input: ComponentProps<C> & Props) => Partial<ComponentProps<C>>)
) => ComponentWithConfig<ComponentType<Props & ComponentProps<C>>>;

export type ComponentWithConfig<C extends ComponentType<any>> = C & {
  withConfig: WithConfigMethod<C>;
};

/**
 * Adds a `.withConfig()` method that applies props to a component as a new component.
 *
 * Features:
 * - Deep merges props with overwrite order of `JSX > outer > inner`
 * - Overwrites arrays instead of merging.
 * - Overwrites function props instead of composing them.\
 *   *(Note: this can be changed on feature request.)*
 * - Passing a function as prop to use and modify props in order of
 *   `Original props > outer > inner`.
 * - Passes `refs` automatically if source component is forwardRef.
 *
 * ## Example
 * ```tsx
 * const NewComponent = addWithConfig(SampleComponent);
 *
 * const StyledComponent = NewComponent.withConfig({someProp:true});
 * ```
 */
export const addWithConfig = <C extends ComponentType<any>>(
  component: C
): ComponentWithConfig<C> => {
  const newcomponent = component as ComponentWithConfig<C>;

  newcomponent.withConfig = props => addWithConfig(withConfig(newcomponent, props));

  return newcomponent;
};

/**
 * Adds `Component.withConfig` to every component in an object of components.
 */
export const addWithConfigToMap = <ComponentList extends Record<string, ComponentType<any>>>(
  components: ComponentList
): {
  [ComponentName in keyof ComponentList]: ComponentWithConfig<ComponentList[ComponentName]>;
} =>
  Object.keys(components).reduce(
    (componentsList, key) => ({
      ...componentsList,
      [key]: addWithConfig(components[key]),
    }),
    {} as {
      [ComponentName in keyof ComponentList]: ComponentWithConfig<ComponentList[ComponentName]>;
    }
  );
