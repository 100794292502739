import { ISanityVendorConfigs } from '@rbi-ctg/menu';

import { PosVendors } from './types';

/**
 * Merges multiple VendorConfigs onto one.
 * The order of the array is taken as order of precedence.
 */
export const mergeVendorConfigs = (
  vendorConfigsList: Array<ISanityVendorConfigs | null | undefined>
): ISanityVendorConfigs | null =>
  Object.values(PosVendors).reduce<ISanityVendorConfigs>(
    (venforConfigs: ISanityVendorConfigs, posVendor: PosVendors) => ({
      ...venforConfigs,
      [posVendor]: vendorConfigsList.reduce((acc, vendorConfigObj) => {
        if (acc) {
          return acc;
        }
        if (vendorConfigObj && vendorConfigObj[posVendor]) {
          return vendorConfigObj[posVendor] as ISanityVendorConfigs;
        }
        return null;
      }, null),
    }),
    {}
  );
