import React from 'react';

import { Box, Icon, addWithConfig } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useCart } from 'hooks/use-cart';
import { useIsMobileBreakpoint } from 'hooks/use-media-query';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { primitive } from 'styles/constants/primitives';
import { routes } from 'utils/routing';

const StyledButton = addWithConfig(ActionButton).withConfig({
  _text: {
    color: primitive.bk.$mayoEggWhite,
  },
  _hover: {
    _text: {
      color: primitive.bk.$mayoEggWhite,
    },
  },
});

const Badge = Box.withConfig({
  position: 'absolute',
  backgroundColor: Styles.color.contrastBackground,
  w: '$5',
  h: '$5',
  borderRadius: Styles.borderRadius,
  right: '-16px',
  bottom: '$3',
  zIndex: Styles.zIndex.top,
  _text: { textAlign: 'center', fontSize: '13px' },
});

export const CartButton = () => {
  const { navigate } = useNavigation();
  const { formatMessage } = useIntl();
  const { numberOfItemsInCart } = useCart();
  const hideCartMenu = useIsMobileBreakpoint();
  const enableMoveSignUpAfterCart = useFlag(LaunchDarklyFlag.ENABLE_MOVE_SIGNUP_AFTER_CART);

  const { openSignInModal, isAuthenticated } = useAuthContext();

  if (hideCartMenu) {
    return null;
  }

  const handleOnPress = () => {
    if (isAuthenticated || enableMoveSignUpAfterCart) {
      navigate(routes.cart);
    } else {
      openSignInModal({
        navigateOnSuccessRoute: routes.cart,
      });
    }
  };

  return (
    <StyledButton
      keepIconSize
      variant={ActionButtonVariants.PRIMARY}
      size={ActionButtonSizes.SMALL}
      data-testid="cart-button-desktop"
      onPress={handleOnPress}
      startIcon={<Icon variant="cart" aria-hidden size={4} />}
    >
      {!!numberOfItemsInCart && <Badge>{numberOfItemsInCart}</Badge>}

      {formatMessage({ id: 'viewCart' })}
    </StyledButton>
  );
};
