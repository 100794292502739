import { Box, Header, Text } from '@rbilabs/universal-components';

import Dialog from 'components/dialog';

export const DialogContainer = Dialog.withConfig({
  background: 'blackOpacity.80',
});

export const ContentContainer = Box.withConfig({
  paddingY: '0',
  marginBottom: '$6',
});

export const ModalHeading = Header.withConfig({
  variant: 'headerOne',
  marginTop: '$2',
});

export const ModalBody = Text.withConfig({
  fontSize: 'sm',
  marginY: '$2',
});
