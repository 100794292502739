import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

export const useCanUseGooglePay = () => {
  const isGooglePayEnabled = useFlag(LaunchDarklyFlag.ENABLE_GOOGLE_PAY);
  const isGooglePayForWebEnabled = useFlag(LaunchDarklyFlag.ENABLE_GOOGLE_PAY_WEB);

  return {
    canUseGooglePay: isGooglePayEnabled && isGooglePayForWebEnabled,
  };
};
