import { IEntity } from 'generated/graphql-gateway';

import { getMenuEntityKey } from './get-menu-entity-key';

interface IBaseModifierSelection {
  _key: string;
  modifier: {
    _key: string;
  };
}

export const computeModifiersPrice = ({
  storeMenuOptions,
  modifierSelections,
}: {
  storeMenuOptions: IEntity['options'];
  modifierSelections: Array<IBaseModifierSelection>;
}): number => {
  let modifiersPrice = 0;

  if (!storeMenuOptions?.length || !modifierSelections?.length) {
    return modifiersPrice;
  }

  const itemOptionMap = new Map(
    storeMenuOptions?.map(itemOption => [
      itemOption.id,
      new Map(itemOption.options?.map(modifier => [modifier.id, modifier])),
    ])
  );

  for (const itemOption of modifierSelections) {
    const itemOptionKey = getMenuEntityKey(itemOption);
    const modifierMap = itemOptionMap.get(itemOptionKey);
    if (!modifierMap) {
      continue;
    }

    const selectedModifierKey = getMenuEntityKey(itemOption.modifier);
    const storeMenuModifier = modifierMap.get(selectedModifierKey);
    if (!storeMenuModifier) {
      continue;
    }

    const modifierPrice = storeMenuModifier?.price || 0;
    modifiersPrice += modifierPrice;
  }
  return modifiersPrice;
};
