import * as React from 'react';
import { FC, useCallback, useState } from 'react';

import { Icon } from '@rbilabs/universal-components';
import { GestureResponderEvent } from 'react-native';

import { VisuallyHidden } from 'components/ucl/visually-hidden';

import { DisplayedValue, IconButton, Input, Wrapper } from './number-input.styled';
import { INumberInputProps } from './types';

const noop = () => ({});

/**
 * Quickly enable a user to enter a value.
 */
const NumberInput: FC<React.PropsWithChildren<INumberInputProps>> = ({
  // Required fields
  value,
  onChange,
  // Min/Max/Step/id
  min = 0,
  max = Infinity,
  step = 1,
  // Disabled props
  disabled,
  disableDecrement,
  disableIncrement,
  // Custom UI/UX props
  onStepButtonClick,
  hideNumberInput,
  displayedValue,
  valueWidth,
  // Accessibility
  ariaLabel,
  decrementAriaLabel,
  incrementAriaLabel,
  // Adding the possibility to override the native accessibility added to the component
  screenReaderTextOverride,
  testID,
  displayValueStyleProps = noop,
  incrementorStyleProps = noop,
  isReadOnly = false,
}) => {
  // Temporary input value when the user is typing the number input
  const [inputValue, setInputValue] = useState(value);

  // Test ids for the increment and decrement buttons
  const inputTestId = testID ?? 'number-input';
  const decrementTestId = `decrement-button-for-${inputTestId}`;
  const incrementTestId = `increment-button-for-${inputTestId}`;

  // Handling step up and step down button click
  const handleChange = useCallback(
    (e: GestureResponderEvent, isIncrementing: boolean) => {
      e.preventDefault();
      const newValue = isIncrementing ? value + step : value - step;
      setInputValue(newValue);

      if (onStepButtonClick) {
        onStepButtonClick();
      }
      onChange(newValue);
    },
    [value, onChange, onStepButtonClick, step]
  );

  // Handle the number input change
  // This should only call the onChange function on input blur
  const handleInputChange = useCallback(
    (newValue: string) => {
      const parsedValue = parseInt(newValue);
      setInputValue(parsedValue || min);
    },
    [min]
  );

  // Updating the input on blur and calling the onChange function if needed
  const handleInputBlur = useCallback(
    (e: any) => {
      // Rounding the inputValue by step
      const inverse = 1.0 / step;
      const roundedValue = Math.round(inputValue * inverse) / inverse;

      // Making sure the new value is not out of boundaries
      let newValue = inputValue;
      if (disableDecrement && newValue < value) {
        // Dont allow decrementation if we disable the decrement
        newValue = value;
      } else if (disableIncrement && newValue > value) {
        // Dont allow incrementation if we disable the increment
        newValue = value;
      } else if (inputValue < min) {
        // Dont allow value lower than the min
        newValue = min;
      } else if (inputValue > max) {
        // Dont allow value higher than the min
        newValue = max;
      } else if (roundedValue !== inputValue) {
        // If the value are differents, update them
        newValue = roundedValue;
      }

      // Only call the onChange function if it's a new value
      // This prevents re-rendering input when the value is unchanged
      if (value !== newValue) {
        setInputValue(newValue);
        onChange(newValue);
      }

      // Updating the input value
      // This prevents showing negative zeros, leading zeros and "e" (ex: 0e1)
      if (roundedValue !== newValue || e.target.value !== newValue.toString()) {
        setInputValue(roundedValue);
      }
    },
    [inputValue, min, max, onChange, step, value, disableDecrement, disableIncrement]
  );

  // Handle touching the enter keybord to update the input
  const handleKeyDown = useCallback((e: any) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  }, []);

  // Disabled step up and down based on max/min
  const stepDownBtnDisabled = disabled || disableDecrement || value <= min;
  const stepUpBtnDisabled = disabled || disableIncrement || value >= max;

  return (
    <Wrapper>
      <IconButton
        variant="outline"
        onPress={e => handleChange(e, false)}
        icon={<Icon variant={'remove'} />}
        disabled={stepDownBtnDisabled}
        testID={decrementTestId}
        accessibilityLabel={decrementAriaLabel}
        $overrides={incrementorStyleProps({ disabled: stepDownBtnDisabled })}
      />

      <Input
        type="number"
        value={inputValue.toString(10)}
        testID={inputTestId}
        onChangeText={handleInputChange}
        onBlur={handleInputBlur}
        onKeyPress={handleKeyDown}
        isDisabled={disabled}
        accessibilityLabel={ariaLabel}
        $isHidden={hideNumberInput}
        isReadOnly={isReadOnly}
      />

      {hideNumberInput && (
        <DisplayedValue
          testID={`displayed-value-${inputTestId}`}
          accessible={true}
          importantForAccessibility="yes"
          accessibilityLabel={ariaLabel}
          $width={valueWidth}
          $disabled={disabled}
          $overrides={displayValueStyleProps({ disabled })}
        >
          {displayedValue}
        </DisplayedValue>
      )}

      <IconButton
        variant="outline"
        onPress={e => handleChange(e, true)}
        icon={<Icon variant={'add'} />}
        disabled={stepUpBtnDisabled}
        testID={incrementTestId}
        accessibilityLabel={incrementAriaLabel}
        $overrides={incrementorStyleProps({ disabled: stepUpBtnDisabled })}
      />

      <VisuallyHidden
        accessibilityLabel={
          screenReaderTextOverride ?? displayedValue?.toString() ?? value.toString()
        }
      />
    </Wrapper>
  );
};

export default NumberInput;
