import React, { FC } from 'react';

import { HStack } from '@rbilabs/universal-components';

const StoreHStack = HStack.withConfig({
  justifyContent: 'center',
  width: 'full',
  paddingX: 0,
  paddingTop: 3,
  margin: 0,
});

export const StoreActionsCard: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <StoreHStack>{children}</StoreHStack>;
};
