import React, { useMemo } from 'react';

import { Box, Header, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { INutritionInfo } from '@rbi-ctg/menu';
import Picture from 'components/picture';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  ItemNutritionInfoToHide,
  defaultItemNutritionInfoToHide,
} from 'state/launchdarkly/variations';
import { getUnitForNutrient } from 'utils/menu/units';

import { Allergens } from '../allergens';
import { TableHeader } from '../v2/table-header';

import { Nutrient } from './styled';
import { IItemNutritionProps } from './types';

export const ItemNutrition: React.FC<React.PropsWithChildren<IItemNutritionProps>> = ({
  itemImage,
  itemName,
  nutritionInfo,
  allergenGroups,
}) => {
  const { formatNumber, formatMessage } = useIntl();

  const enableNutritionExplorerAllergens = useFlag(
    LaunchDarklyFlag.ENABLE_NUTRITION_EXPLORER_ALLERGENS
  );
  const itemsFromNutritionInfoToHide =
    useFlag<ItemNutritionInfoToHide>(LaunchDarklyFlag.HIDE_ITEMS_FROM_NUTRITION_INFO) ||
    defaultItemNutritionInfoToHide;

  const nutritionIds = useMemo(
    () =>
      [
        'weight',
        'calories',
        'fat',
        'saturatedFat',
        'transFat',
        'cholesterol',
        'sodium',
        'carbohydrates',
        'fiber',
        'sugar',
        'proteins',
      ] as const,
    []
  );

  const perServing = useMemo(() => {
    return (
      <>
        <TableHeader testID="per-serving-header" title={formatMessage({ id: 'perServing' })} />
        {/* Todo: Remove both `any` casts once on Typescript v4 */}
        {(nutritionIds as any).map((key: keyof INutritionInfo) => {
          const value = nutritionInfo[key];
          if ((key === 'weight' && value === 0) || itemsFromNutritionInfoToHide[key]) {
            return null;
          }

          return (
            <Nutrient key={key}>
              <Text textTransform="capitalize">{formatMessage({ id: 'us-' + key })}</Text>
              <Text>
                {`${formatNumber(value, {
                  maximumFractionDigits: 1,
                })} ${getUnitForNutrient(key)}`}
              </Text>
            </Nutrient>
          );
        })}
      </>
    );
  }, [formatMessage, nutritionIds, nutritionInfo, itemsFromNutritionInfoToHide, formatNumber]);

  return (
    <>
      <Picture
        image={itemImage}
        alt="itemImage"
        objectFitContain
        width={300}
        height={300}
        alignSelf="center"
      />
      <Box marginBottom="$4">
        <Header textAlign="center" focusable>
          {itemName}
        </Header>

        <>{perServing}</>

        {enableNutritionExplorerAllergens && !!allergenGroups?.length && (
          <>
            <TableHeader
              testID="allergens-header"
              title={formatMessage({ id: 'allergenInformation' })}
            />
            <Allergens allergenGroups={allergenGroups} />
          </>
        )}
      </Box>
    </>
  );
};
