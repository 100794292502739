import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { isEmpty } from 'lodash-es';
import isEqual from 'react-fast-compare';

import { IRestaurant } from '@rbi-ctg/store';
import { LANGUAGES } from 'state/intl/types';
import {
  LDContextUpdates,
  clearLDUserCore,
  getAllLDFlags,
  initLaunchDarklyAndGetAllFlags,
  staticLDContextUserAttrs,
  staticLDFlags,
  updateLDUserCore,
} from 'state/launchdarkly/launchdarkly-core';
import { getName } from 'utils/attributes';
import { isProduction, sanityDataset } from 'utils/environment';
import { Region } from 'utils/environment/types';
import { loadRegion } from 'utils/intl/region';
import logger from 'utils/logger';
import noop from 'utils/noop';

import { addLDChangeListener } from './client';
import { getFeatureFlagOverridesFromQueryParameters } from './feature-flag-overrides';
import {
  BooleanFlags,
  FlagType,
  LaunchDarklyFlag,
  LaunchDarklyFlagsObject,
  NumericFlags,
  StringFlags,
  VariationFlags,
} from './flags';
import safeFlagDefaults from './safe-flag-defaults';

export { LaunchDarklyFlag };

interface ILogin {
  cognitoId?: string;
  details: { name?: string; email: string };
}

enum FlagsLoadStatus {
  Cache = 'cache',
  Network = 'network',
  SafeDefaults = 'safe-defaults',
  Loading = 'loading',
}

export interface ILaunchDarklyCtx {
  flags: LaunchDarklyFlagsObject;
  areFlagsFullyLoaded: boolean;
  flagsLoadStatus: FlagsLoadStatus;
  flagOverrides: { [id: string]: boolean };
  /**
   * this allows us to determine whether we are receiving the correct value for
   * user-targeted flags over just the default set in LD
   */
  ldUserIsAuthenticated: boolean;
  login: (u: ILogin) => void;
  logout: () => void;
  updateLDUser: (u: LDContextUpdates) => void;
  updateUserLocale: (locale: { region: Region; language: LANGUAGES }) => void;
  updateUserStore: (store: IRestaurant | null) => Promise<any>;
}

export const LdReactContext = React.createContext<ILaunchDarklyCtx>({
  flags: {},
  flagOverrides: {},
  areFlagsFullyLoaded: false,
  flagsLoadStatus: FlagsLoadStatus.Loading,
  ldUserIsAuthenticated: false,
  login: noop,
  logout: noop,
  updateLDUser: noop,
  updateUserLocale: noop,
  updateUserStore: () => Promise.resolve(),
});

type CoreState = {
  flags: LaunchDarklyFlagsObject;
  areFlagsFullyLoaded: boolean;
  flagsLoadStatus: FlagsLoadStatus;
  ldUserIsAuthenticated: boolean;
};

// start loading LD flags immediately
export const initLaunchDarklyAndGetAllFlagsPromise = initLaunchDarklyAndGetAllFlags();

export const useLDContext = () => useContext(LdReactContext);

export function LDProvider(props: { children: ReactNode }) {
  // keep all state together to avoid excessive app halting re-renders (react does not batch async setStates w/o new architecture enabled)
  const [state, setState] = useState<CoreState>({
    flags: staticLDFlags.current ?? safeFlagDefaults,
    areFlagsFullyLoaded: staticLDFlags.isLoadedFromServer,
    ldUserIsAuthenticated: !staticLDContextUserAttrs.current?.anonymous,
    flagsLoadStatus: staticLDFlags.isLoadedFromServer
      ? FlagsLoadStatus.Network
      : isEmpty(staticLDFlags.current)
      ? FlagsLoadStatus.Loading
      : FlagsLoadStatus.Cache,
  });

  const flagOverrides = useRef(getFeatureFlagOverridesFromQueryParameters());

  // update state once LD inits
  useEffect(() => {
    initLaunchDarklyAndGetAllFlagsPromise
      .then(ldFlags => {
        if (!ldFlags) {
          // only set ld flags if they are non null, this isn't correct and they might be set in initial setState above
          return;
        }
        setStateIfChanged(setState, {
          flags: ldFlags,
          areFlagsFullyLoaded: true,
          flagsLoadStatus: FlagsLoadStatus.Network,
        });
      })
      .catch(error => {
        // we need flags or the app doesn't work right. if all else fails (and no cache) use safe defaults.
        const shouldUseSafeDefaults =
          !staticLDFlags.current || Object.keys(staticLDFlags.current).length === 0;

        if (shouldUseSafeDefaults) {
          setStateIfChanged(setState, {
            flags: safeFlagDefaults,
            areFlagsFullyLoaded: true,
            flagsLoadStatus: FlagsLoadStatus.SafeDefaults,
          });
          // set them on static in case static users need it (but don't set to cache because this isn't a great state)
          staticLDFlags.current = safeFlagDefaults;
        } else {
          setStateIfChanged(setState, {
            // in case cached flags weren't set in time on initial useState (due to LocalStorage.getItem(StorageKeys.LAUNCH_DARKLY_FLAGS) being async) make sure they get set here.
            flags: staticLDFlags.current,

            areFlagsFullyLoaded: true,
            flagsLoadStatus: FlagsLoadStatus.Cache,
          });
        }
        logger.error({ error, message: 'LaunchDarkly init failed', shouldUseSafeDefaults });
      });
  }, []);

  useEffect(() => {
    const unsubscribeListener = addLDChangeListener(async () => {
      const allFlags = await getAllLDFlags();
      setStateIfChanged(setState, { flags: allFlags });
    });

    return unsubscribeListener;
  }, []);

  const updateLDUser = useCallback(async (changes: LDContextUpdates | null) => {
    const isLogout = !changes;
    const { newFlags, userAttributes: newAttributes } = isLogout
      ? await clearLDUserCore()
      : await updateLDUserCore(changes);

    // TODO stolen from old code. my suspicion this isn't used anymore but more investigation needed
    const ldUserIsAuthenticated = Boolean(
      newAttributes && !newAttributes.anonymous && newAttributes.email
    );

    setStateIfChanged(setState, {
      flags: newFlags,
      ldUserIsAuthenticated,
    });

    return { newFlags };
  }, []);

  const login = useCallback(
    ({ cognitoId, details: { name = '', email } }: ILogin) => {
      updateLDUser({
        ...getName({ name }, { firstName: '', lastName: '' }),
        key: cognitoId,
        name,
        email,
        anonymous: false,
        sanityDataset: `${sanityDataset()}_${loadRegion().toLowerCase()}`,
      });
    },
    [updateLDUser]
  );

  const logout = useCallback(() => updateLDUser(null), [updateLDUser]);

  const updateUserLocale = useCallback(
    (locale: { region: Region; language: LANGUAGES }) => {
      updateLDUser({
        language: locale.language,
        sanityDataset: `${sanityDataset()}_${loadRegion().toLowerCase()}`,
        country: locale.region,
      });
    },
    [updateLDUser]
  );

  const updateUserStore = useCallback(
    (store: IRestaurant | null) => {
      return updateLDUser({
        storeNumber: store?.number ?? '',
        storeCity: store?.physicalAddress?.city ?? '',
        storeCountry: store?.physicalAddress?.country ?? '',
        storePostalCode: store?.physicalAddress?.postalCode ?? '',
        storeStateProvince: store?.physicalAddress?.stateProvince ?? '',
        storeStateProvinceShort: store?.physicalAddress?.stateProvinceShort ?? '',
        storeFranchiseGroupName: store?.franchiseGroupName ?? '',
        storePosVendor: store?.pos?.vendor ?? '',
        storeVatNumber: store?.vatNumber ?? '',
      });
    },
    [updateLDUser]
  );

  const value = useMemo<ILaunchDarklyCtx>(
    () => ({
      flags: state.flags,
      areFlagsFullyLoaded: state.areFlagsFullyLoaded,
      flagsLoadStatus: state.flagsLoadStatus,
      ldUserIsAuthenticated: state.ldUserIsAuthenticated,
      flagOverrides: flagOverrides.current,
      updateLDUser,
      updateUserLocale,
      updateUserStore,
      login,
      logout,
    }),
    [login, logout, updateLDUser, state, flagOverrides, updateUserLocale, updateUserStore]
  );

  return <LdReactContext.Provider value={value}>{props.children}</LdReactContext.Provider>;
}

export function useFlag<T>(flag: VariationFlags): T | null | undefined;
export function useFlag(flag: NumericFlags): FlagType<NumericFlags>;
export function useFlag(flag: StringFlags): FlagType<StringFlags> | undefined;
export function useFlag(flag: BooleanFlags): FlagType<BooleanFlags>;
export function useFlag(flag: LaunchDarklyFlag): FlagType<LaunchDarklyFlag> {
  const { flags, flagOverrides } = useLDContext();

  if (!isProduction && flagOverrides[flag] !== undefined) {
    return flagOverrides[flag];
  }

  // @todo update code to handle missing flags or update function to accept a fallback
  // TODO return network status here and update all 387 uses of this signature.
  // the goal is to make this very apparent flags are async when people add flags
  return flags && flags[flag]!;
}

// dont setState on flags unless they effectively change to avoid re-renders
const setStateIfChanged = (
  setState: React.Dispatch<React.SetStateAction<CoreState>>,
  newState: Partial<CoreState>
) => {
  setState(oldState => {
    const updatedState = {
      ...oldState,
      ...newState,
      ldUserIsAuthenticated: !staticLDContextUserAttrs.current?.anonymous,
      flags: {
        ...oldState.flags,
        ...newState.flags,
      },
    };

    if (isEqual(oldState, updatedState)) {
      return oldState;
    }

    return updatedState;
  });
};
