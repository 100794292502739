import React from 'react';

import { useIntl } from 'react-intl';

import ConfirmDialog from 'components/confirm-dialog';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useAuthContext } from 'state/auth';
import { routes } from 'utils/routing';

interface IExistingModalDialogProps {
  showDialog: boolean;
  dismiss: () => void;
}

const ExistingEmailDialog: React.FC<React.PropsWithChildren<IExistingModalDialogProps>> = ({
  showDialog,
  dismiss,
}) => {
  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();
  const { openSignInModal, modalAuthIsOpen } = useAuthContext();

  return (
    <ConfirmDialog
      showDialog={showDialog}
      body={formatMessage({ id: 'logInWithExistingEmail' })}
      confirmLabel={formatMessage({ id: 'logIn' })}
      heading={formatMessage({ id: 'welcomeBack' })}
      onCancel={dismiss}
      onConfirm={() => {
        dismiss();

        // TODO: investigate if attemptedSignUpWithExistingEmail flow works for modal authentication.
        if (modalAuthIsOpen) {
          openSignInModal();
          return;
        }

        navigate(routes.signUp, {
          state: {
            attemptedSignUpWithExistingEmail: true,
            activeRouteIsSignIn: true,
          },
        });
      }}
      onDismiss={dismiss}
      modalAppearanceEventMessage="Email exists sign in prompt modal"
    />
  );
};

export default ExistingEmailDialog;
