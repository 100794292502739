import { Svg, SvgImage, SvgProps, SvgRect, registerSVG } from '../../../components/svg';

export function VenmoLogo(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 1000 563">
      <SvgRect width="1000" height="563" fill="none" />
      <SvgImage href={require('./VenmoLogo.png')} />
    </Svg>
  );
}

registerSVG('VenmoLogo', VenmoLogo);
