import { Header as UCHeader, makeUclComponent } from '@rbilabs/universal-components';

import Modal, { ModalContent as UCModalContent } from 'components/modal';

export const ModalContent = UCModalContent.withConfig({
  alignSelf: 'center',
  width: 'full',
  mt: '$0',
  maxWidth: {
    md: '50%',
    lg: '50%',
  },
});

export const Header = UCHeader.withConfig({
  mb: '$8',
  variant: 'headerOne',
  testID: 'modal-heading',
  textAlign: 'center',
});

export const StyledModal = makeUclComponent(Modal).withConfig({
  headerStyle: {
    _web: {
      height: '$13.5',
      shadow: '1',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
  },
});
