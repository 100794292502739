import { Box, Text } from '@rbilabs/universal-components';

import { isNative } from 'utils/environment';

export const StyledButtonWidthLimit = Box.withConfig({
  width: {
    md: '335px',
  },
  marginX: {
    md: 'auto',
  },
  _web: {
    width: 'full',
  },
});

export const TextInputWrapper = Box.withConfig({
  paddingX: '1',
  width: 'full',
});

export const LabelStyled = Text.withConfig({
  color: Styles.color.black,
  fontWeight: isNative ? 'bold' : 'normal',
  alignSelf: 'center',
});
