import { forwardRef } from 'react';

import { ScrollView as NbScrollView } from 'native-base';

import { addWithConfig } from '../../utils';

import type { ScrollViewProps } from './types';

export const ScrollView = forwardRef((props: ScrollViewProps, ref) => (
  <NbScrollView showsVerticalScrollIndicator={false} {...props} ref={ref} />
));
export default addWithConfig(ScrollView);
