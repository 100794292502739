import { useMarketingTileGroupsQuery } from 'generated/sanity-graphql';

export const useMarketingTileGroups = (ids?: string[]) => {
  const marketingTileGroupIds = ids ?? [];
  const { data, loading } = useMarketingTileGroupsQuery({
    variables: {
      where: {
        _id_in: marketingTileGroupIds,
      },
    },
    skip: !marketingTileGroupIds?.length,
  });

  const marketingTileGroups = data?.allMarketingTileGroups;

  return {
    marketingTileGroups,
    marketingTileGroupsLoading: loading,
  };
};
