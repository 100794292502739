import { Box, HStack, Header as HeaderUCL, Pressable } from '@rbilabs/universal-components';

import { InnerLayout, LayoutContainer } from 'components/layout/styled';
import { theme } from 'styles/configure-theme';

export const HeaderBackground = Box.withConfig({
  safeAreaTop: true,
  backgroundColor: {
    base: theme.token('store-locator-header-background'),
    md: 'transparent',
  },
});

export const TopSelectionContainer = Box.withConfig({
  _ios: {
    zIndex: 1,
  },
  _web: {
    zIndex: 1,
  },
});

export const HeaderWrapper = LayoutContainer.withConfig({
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  textAlign: 'center',
  backgroundColor: theme.token('store-locator-header-background'),
  zIndex: Styles.zIndex.overlay,
});

export const Header = HeaderUCL.withConfig({
  color: theme.token('text-reversed'),
  fontSize: 'xl',
});

export const MaxWidthFullHeight = Box.withConfig({
  flex: 1,
});

export const ToggleViewButtonWrapper = Box.withConfig({
  justifyContent: 'center',
  minWidth: '$10',
});

export const ServiceModeCategoryToggleWrapper = Box.withConfig({
  justifyContent: 'center',
  width: 'full',
});

export const ToggleButtonsWrapper = HStack.withConfig({
  borderRadius: 'sm',
  overflow: 'hidden',
  borderColor: theme.token('border-color-input'),
  borderWidth: 0.5,
  marginX: { base: '$4', md: 0 },
});

export const ToggleButton = Pressable.withConfig<{ selected: boolean }>(p => ({
  borderColor: theme.token('border-color-input'),
  borderWidth: 0.5,
  flex: '1',
  height: theme.token('height-input'),
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: p.selected ? theme.token('background-checked') : theme.token('background-input'),
  _web: {
    _hover: {
      borderColor: theme.token('border-color-hover'),
      cursor: 'pointer',
    },
  },
}));

export const ToggleButtonLabel = Box.withConfig<{ selected: boolean }>(p => ({
  _text: {
    color: p.selected ? theme.token('text-reversed') : theme.token('text-default'),
    font: theme.token('text-style-form-input'),
  },
}));

export const SearchBarWrapper = Box.withConfig({
  paddingX: { base: '$4', md: 0 },
  marginTop: '$4',
  marginBottom: '$0',
  width: 'full',
  height: theme.token('height-input'),
});

export const SearchContainer = Box.withConfig({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StoreLocatorContainer = InnerLayout.withConfig({
  paddingBottom: '$0',
});
