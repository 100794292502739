import React from 'react';

import { Box } from '@rbilabs/universal-components';

import { IBaseProps } from '@rbi-ctg/frontend';
import LoadingAnimation from 'components/loading-animation';

interface ILoadingIndicatorProps extends IBaseProps {
  ariaLabel?: string;
}

const LoadingContainer = Box.withConfig({
  alignItems: 'center',
  height: 'full',
  // FIXME: BKPE-1489: issue with scroll views
  minHeight: '300px',
  justifyContent: 'center',
  margin: 0,
  width: 'full',
  marginTop: '$12',
});

export const LoadingIndicator = ({ ariaLabel }: ILoadingIndicatorProps) => {
  return (
    <LoadingContainer testID="store-locator-loading">
      <LoadingAnimation ariaLabel={ariaLabel} />
    </LoadingContainer>
  );
};
