import { IFeatureLayoutQuery, useFeatureLayoutQuery } from 'generated/sanity-graphql';

interface IUseFeatureLayoutValue {
  featureLayout: IFeatureLayoutQuery['FeatureLayout'];
  featureLayoutLoading: boolean;
}

const FEATURE_LAYOUT_SINGLETON_ID = 'feature-layout-singleton';

export const useFeatureLayout = (): IUseFeatureLayoutValue => {
  const { data, loading } = useFeatureLayoutQuery({
    variables: { featureLayoutId: FEATURE_LAYOUT_SINGLETON_ID },
  });

  const featureLayout = data?.FeatureLayout ?? null;

  return {
    featureLayout,
    featureLayoutLoading: loading,
  };
};
