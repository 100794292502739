import { useControllableState } from '../../hooks';
import { addWithConfig } from '../../utils';
import { Alert } from '../alert';
import { Box } from '../box';
import { HStack } from '../h-stack';
import { Header } from '../header';
import { Icon } from '../icon';
import { IconButton } from '../icon-button';
import { Text } from '../text';
import { VStack } from '../v-stack';

import type { InlineAlertProps } from './types';

const iconSize = ['$4', '$6'];

const iconsByStatus = (iconStyles: any) => {
  return {
    info: <Icon variant="infoFill" size={iconSize} {...iconStyles} />,
    infoOutline: <Icon variant="info" size={iconSize} {...iconStyles} />,
    success: <Icon variant="checkFill" size={iconSize} {...iconStyles} />,
    // These icons are purposefully set so that the 'warning' status uses the 'errorFill' variant
    // and the 'error' status uses the 'warning' variant. Design wanted it that way.
    warning: <Icon variant="errorFill" size={iconSize} {...iconStyles} />,
    error: <Icon variant="warning" size={iconSize} {...iconStyles} />,
  } as const;
};

const backgroundColorByStatus = {
  info: 'token.background-inline-alert',
  infoOutline: 'token.background-inline-alert',
  success: 'token.background-inline-alert-success',
  warning: 'token.background-inline-alert-warning',
  error: 'token.background-inline-alert-error',
};

const dismissIcon = <Icon variant="cancel" size={iconSize} />;

export const InlineAlert = ({
  isDismissable = false,
  isVisible: isVisibleProp,
  onVisibilityChange: onVisibilityChangeProp,
  status = 'info',
  title,
  message,
  size,
  alertContainerStyles,
  titleStyles,
  messageStyles,
  iconStyles,
  ...boxProps
}: InlineAlertProps) => {
  const [isVisible, setIsVisible] = useControllableState({
    defaultValue: true,
    value: isVisibleProp,
    handleValueChange: onVisibilityChangeProp,
  });
  const icon = iconsByStatus(iconStyles)[status];

  if (!isVisible) {
    return null;
  }

  return (
    <Alert
      status={status}
      paddingY={size === 'sm' ? ['$2', '$2'] : ['$3', '$4']}
      paddingX={size === 'sm' ? ['$2', '$2'] : ['6%', '$4']}
      backgroundColor={backgroundColorByStatus[status]}
      alignItems="flex-start"
      borderRadius="none"
      {...alertContainerStyles}
      {...boxProps}
    >
      <HStack space="$2" alignItems="stretch" {...alertContainerStyles}>
        <Box alignItems="flex-start">{icon}</Box>
        <VStack space="$1" justifyContent="center" flexShrink="1">
          {title && (
            <Header variant="headerThree" marginBottom="$0" {...titleStyles}>
              {title}
            </Header>
          )}
          {message && (
            <Text
              variant="copyTwo"
              marginBottom="$0"
              fontSize={size === 'sm' ? 11 : 12}
              {...messageStyles}
            >
              {message}
            </Text>
          )}
        </VStack>
        {isDismissable && (
          <Box alignItems="flex-start">
            <IconButton
              variant="ghost"
              icon={dismissIcon}
              onPress={() => setIsVisible(false)}
              marginLeft="auto"
              marginRight="$0"
              padding="$0"
            />
          </Box>
        )}
      </HStack>
    </Alert>
  );
};

export default addWithConfig(InlineAlert);
