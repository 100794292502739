import React from 'react';

import { Box } from '@rbilabs/universal-components';

import Picture from 'components/picture';

import type { IOptInModalImage } from './types';

export const OptInModalImage: IOptInModalImage = ({ image, alt }) =>
  image ? (
    <Box alignItems="center" mt="$4">
      <Picture maxW="30%" image={image} alt={alt} objectFitContain />
    </Box>
  ) : null;
