import type { IQuest } from 'components/quests/loyalty-quest/types';

export const getProductRequirementCmsIds = (quest: IQuest | undefined): string[] => {
  if (!quest || !quest?.steps) {
    return [];
  }

  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const uniqueCmsIds = quest.steps.reduce((stepIdSet, step) => {
    return step.cartRequirements?.reduce((cartReqIdSet, cartReq) => {
      return cartReq?.reduce((prodReqIdSet, prodReq) => {
        return prodReqIdSet.add(prodReq?.id);
      }, cartReqIdSet);
    }, stepIdSet);
  }, new Set<string>());

  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  return Array.from(uniqueCmsIds ?? []);
};
