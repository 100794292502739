import React, { useState } from 'react';

import { FormikFormControl, Icon, Pressable } from '@rbilabs/universal-components';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { NativeSyntheticEvent, NativeTouchEvent } from 'react-native';

import { FavoriteStoreSearch } from 'components/favorite-store-search';
import { ISignupFormData } from 'utils/form/types';

import { FavoriteInputText, FieldContainer, StyledFavoriteStoreInput } from './signup.styled';
import { IFavoriteLocationField, IStoreInfo } from './types';

export default function FavoriteLocationField({
  setFieldValue,
  isInvalid,
  disabled,
}: IFavoriteLocationField) {
  const [storeSearchModalOpen, setStoreSearchModalOpen] = useState(false);

  const { formatMessage } = useIntl();
  const { values } = useFormikContext<ISignupFormData>();
  const [favoriteStoreInputText, setFavoriteStoreInputText] = useState(
    values?.favoriteLocation?.storeAddress || formatMessage({ id: 'favoriteLocationPlaceholder' })
  );

  const handleStoreSelect = (storeInfo: IStoreInfo) => {
    const { storeAddress, storeId, storeNumber } = storeInfo;

    setFieldValue('favoriteLocation', {
      storeId,
      storeNumber,
      storeAddress,
    });

    setFavoriteStoreInputText(storeAddress || '');
  };

  const openSearchModal = (e: NativeSyntheticEvent<NativeTouchEvent>) => {
    e?.preventDefault();
    setStoreSearchModalOpen(true);
  };

  return (
    <>
      <FieldContainer>
        <FormikFormControl
          label={formatMessage({ id: 'favoriteLocationLabel' })}
          name="favoriteLocation"
          isRequired
        >
          <Pressable onPressIn={openSearchModal} disabled={disabled}>
            <StyledFavoriteStoreInput
              testID={'signup-favorite-location-input'}
              hasError={isInvalid}
              disabled={disabled}
            >
              <FavoriteInputText variant="copyTwo" disabled={disabled}>
                {favoriteStoreInputText}
              </FavoriteInputText>
              <Icon variant="forward" size="18px" width="18px" height="18px" ml={3} />
            </StyledFavoriteStoreInput>
          </Pressable>
        </FormikFormControl>
      </FieldContainer>
      <FavoriteStoreSearch
        isOpen={storeSearchModalOpen}
        onClose={() => setStoreSearchModalOpen(false)}
        onStoreSelect={handleStoreSelect}
      />
    </>
  );
}
