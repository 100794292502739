import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { GraphQLError } from 'graphql';
import { useIntl } from 'react-intl';

import { IEncryptionResult } from 'components/payments/integrations/orbital/components/encryption/types';
import {
  IAddAccountInput,
  IAddOrbitalAccountInput,
  IMergePrepaidInput,
  IMergePrepaidPayload,
  IsoCountryCode,
  PaymentProcessor,
  UserAccountsDocument,
  useDeletePaymentMethodMutation,
  useMergePrepaidMutation,
  useUserAccountsQuery,
} from 'generated/graphql-gateway';
import { useConfigValue } from 'hooks/configs/use-config-value';
import { usePrepaidsReload } from 'hooks/prepaid';
import { getNonce } from 'remote/api/first-data';
import { HttpErrorCodes } from 'remote/constants';
import useApplePay from 'state/apple-pay/hooks/use-apple-pay';
import useCanUseApplePay from 'state/apple-pay/hooks/use-can-use-apple-pay';
import { useCanUseGooglePay } from 'state/google-pay/hooks/use-can-use-google-pay';
import { useGooglePay } from 'state/google-pay/hooks/use-google-pay';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  PaymentFieldVariations,
  defaultPaymentFieldVariation,
} from 'state/launchdarkly/variations';
import { CustomEventNames, EventTypes } from 'state/mParticle';
import { storePrepaidCard } from 'utils/encryption';
import { sanitizeAlphanumeric, sanitizeNumber } from 'utils/form';
import { ISOs } from 'utils/form/constants';
import logger from 'utils/logger';
import { IPaymentPayload, IPaymentState, splitExpiry } from 'utils/payment';

import { CASH_ACCOUNT_IDENTIFIER } from '../constants';
import { IAddPaymentMethodOptions, IPaymentMethod, IReloadPrepaidCard } from '../types';

import { getPaymentMethodsState } from './getPaymentMethodsState';
import { IInitPaymentMethods, IPayment } from './types';

const cashAccount: IPaymentMethod = {
  cash: true,
  fdAccountId: CASH_ACCOUNT_IDENTIFIER,
  accountIdentifier: CASH_ACCOUNT_IDENTIFIER,
  orbitalIdentifier: null,
  credit: null,
  prepaid: null,
  paypal: null,
  venmo: null,
  cashapp: null,
};

const usePayment = ({
  getEncryptionDetailsMutation,
  logEvent,
  logRBIEvent,
  updateUserAttributes,
  openErrorDialog,
  user,
  isReAuthenticating,
  updateUserInfo,
  addCreditAccountMutation,
}: IPayment) => {
  const { formatMessage } = useIntl();
  const { feCountryCode } = useLocale();
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
  const [hasGetPaymentMethodsError, setHasGetPaymentMethodsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const canUseApplePay = useCanUseApplePay();
  const { applePayCardDetails } = useApplePay({});
  const { canUseGooglePay } = useCanUseGooglePay();
  const { googlePayCardDetails } = useGooglePay();
  const enableCashPayment = useFlag(LaunchDarklyFlag.ENABLE_CASH_PAYMENT);
  const enableCashApp = useFlag(LaunchDarklyFlag.ENABLE_CASH_APP_PAYMENTS);
  const enablePayPal = useFlag(LaunchDarklyFlag.ENABLE_PAYPAL_PAYMENTS);
  const enableVenmo = useFlag(LaunchDarklyFlag.ENABLE_VENMO_PAYMENTS);
  const onlySendPostalCode = useFlag(LaunchDarklyFlag.SEND_POSTAL_CODE_ONLY_FOR_FIRST_DATA_PAYMENT);
  const [fireDeleteAccountMutation] = useDeletePaymentMethodMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: UserAccountsDocument, variables: { feCountryCode } }],
  });
  const [fireMergePrepaidMutation] = useMergePrepaidMutation({
    refetchQueries: [{ query: UserAccountsDocument, variables: { feCountryCode } }],
  });

  const fdAccessTokenFirstRequest = useRef<string | null>(null);

  const urlsConfig = useConfigValue({ key: 'urls', defaultValue: {} });
  const { braintreeAuthorizationKey } = useConfigValue({ key: 'firstData', defaultValue: {} });
  const fdUrl = useMemo(() => urlsConfig.firstData, [urlsConfig.firstData]);

  const {
    data: userAccountsData,
    loading: accountsLoading,
    refetch: refetchPaymentMethods,
  } = useUserAccountsQuery({
    variables: { feCountryCode: IsoCountryCode[feCountryCode] },
    // Apollo client loading state gets stuck: https://github.com/apollographql/react-apollo/issues/3425
    // Temporary fix while we wait for a stable 3.0.0 version
    fetchPolicy: 'cache-and-network',
    skip: !user,
  });

  const { cognitoId, details } = user || {};
  const { defaultPaymentAccountId: defaultPrepaidPaymentMethodId } = details || {};

  // note: defaultFdAccountId is deprecated. use defaultAccountIdentifier if present
  const defaultAccountIdentifier = details?.defaultAccountIdentifier || details?.defaultFdAccountId;

  const [checkoutPaymentMethodId, setCheckoutPaymentMethodId] = useState('');
  const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState('');
  const [defaultReloadPaymentMethodId, setDefaultReloadPaymentMethodId] = useState('');
  const [prepaidReloadPaymentMethodId, setPrepaidReloadPaymentMethodId] = useState('');
  const [paymentMethodHasBeenInit, setPaymentMethodHasBeenInit] = useState(false);

  const saveNewPaymentMethodEvent = useCallback(
    (success: boolean, message?: string, context: Record<string, any> = {}) => {
      /// Log to mParticle
      logEvent(CustomEventNames.SAVE_NEW_PAYMENT_METHOD, EventTypes.Other, {
        Response: success ? 'Successful' : 'Failure',
        'Response Description': success ? 'Successful' : message,
        'Payment Processor': context.paymentProcessor,
      });
      if (success) {
        logger.info({
          message: 'Payment method added',
          context,
        });
      } else {
        logger.error({
          message: 'Error adding payment method',
          context,
        });
      }
    },
    [logEvent]
  );

  const deletePaymentMethodEvent = useCallback(
    (success: boolean, message?: string) => {
      logEvent(CustomEventNames.DELETE_PAYMENT_METHOD, EventTypes.Other, {
        Response: success ? 'Successful' : 'Failure',
        'Response Description': success ? 'Successful' : message,
      });
    },
    [logEvent]
  );

  const logUserPaymentIdentity = useCallback(
    (accountIdentifier: string) => {
      if (!user || !details) {
        return;
      }
      const selectedMethod = paymentMethods.find(
        method =>
          method.accountIdentifier === accountIdentifier || method.fdAccountId === accountIdentifier
      );
      if (!selectedMethod) {
        return;
      }
      let ccToken;
      if (selectedMethod.credit) {
        ccToken = selectedMethod.credit.panToken;
      } else if (selectedMethod.prepaid) {
        ccToken = selectedMethod.prepaid.cardNumber;
      }

      if (ccToken) {
        updateUserAttributes({
          ccToken,
        });
      }
    },
    [updateUserAttributes, details, paymentMethods, user]
  );

  const setAndLogCheckoutPaymentMethodId = useCallback(
    (fdAccountId: string) => {
      logUserPaymentIdentity(fdAccountId);
      setCheckoutPaymentMethodId(fdAccountId);
    },
    [logUserPaymentIdentity, setCheckoutPaymentMethodId]
  );

  const setAndLogDefaultReloadPaymentMethodId = useCallback(
    (accountId: string) => {
      setPrepaidReloadPaymentMethodId(accountId);
      setDefaultReloadPaymentMethodId(accountId);
      if (user) {
        updateUserInfo({
          ...user.details,
          defaultReloadPaymentMethodId: accountId,
        });
      }
    },
    [user, updateUserInfo]
  );

  const updatePaymentMethodsState = useCallback(
    (
      validDefaultPaymentMethodId: string,
      validDefaultReloadPaymentMethodId: string,
      shouldMuteUpdateUserInfoErrors?: boolean
    ) => {
      /*
      call updateMe query only if user has a invalid defaultAccountIdentifier
      or if it's different than the validDefaultPaymentMethodId returned by the getPaymentMethodsState hook
      */
      const shouldUpdateUser =
        !user?.details.defaultAccountIdentifier ||
        validDefaultPaymentMethodId !== user?.details.defaultAccountIdentifier;

      setDefaultPaymentMethodId(validDefaultPaymentMethodId);
      setCheckoutPaymentMethodId(validDefaultPaymentMethodId);
      setPrepaidReloadPaymentMethodId(validDefaultReloadPaymentMethodId);
      setDefaultReloadPaymentMethodId(validDefaultReloadPaymentMethodId);

      if (user && shouldUpdateUser) {
        // Update the checkout and reload payment method when adding a new CC
        updateUserInfo(
          {
            ...user.details,
            defaultCheckoutPaymentMethodId: validDefaultPaymentMethodId,
            defaultReloadPaymentMethodId: validDefaultReloadPaymentMethodId,
          },
          {
            shouldMuteUserInfoErrors: shouldMuteUpdateUserInfoErrors,
          }
        );
      }
    },
    [updateUserInfo, user]
  );

  const setAndLogDefaultPaymentMethodId = useCallback(
    (accountId: string, isAddingNewCc?: boolean) => {
      logUserPaymentIdentity(accountId);
      setDefaultPaymentMethodId(accountId);
      // Set the checkout payment method when the user change its default one
      setCheckoutPaymentMethodId(accountId);

      if (user) {
        // Update the checkout and reload payment method when adding a new CC
        updateUserInfo({
          ...user.details,
          defaultCheckoutPaymentMethodId: accountId,
          ...(isAddingNewCc ? { defaultReloadPaymentMethodId: accountId } : {}),
        });
      }
    },
    [logUserPaymentIdentity, setDefaultPaymentMethodId, user, updateUserInfo]
  );

  const getEncryptionDetails = useCallback(async () => {
    const encryptionDetailsResponse = await getEncryptionDetailsMutation();

    if (!encryptionDetailsResponse.data) {
      throw new Error('Missing encryption details');
    }

    return encryptionDetailsResponse.data.encryptionDetails;
  }, [getEncryptionDetailsMutation]);

  const clearPaymentMethods = useCallback(() => {
    setPaymentMethods(old => {
      // if old 0 new 0 no need to cause re-renders
      if (old?.length === 0) {
        return old;
      }
      return [];
    });
    setHasGetPaymentMethodsError(false);
    setLoading(false);
  }, []);

  const getPaymentMethods = useCallback(async () => {
    try {
      if (cognitoId) {
        await refetchPaymentMethods();
      }
    } catch (error) {
      logger.error({
        error,
        message: 'Error getting payment methods',
      });
      clearPaymentMethods();
      setHasGetPaymentMethodsError(true);
      openErrorDialog({
        message: formatMessage({ id: 'paymentLoadingError' }),
        modalAppearanceEventMessage: 'Error: Fetching Payment Methods Failure',
        // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Error | ... Remove this comment to see the full error message
        error,
      });
    }
  }, [refetchPaymentMethods, clearPaymentMethods, openErrorDialog, formatMessage, cognitoId]);

  /**
   * set up a bunch of state related to a customer's payment accounts and methods
   * @param {IPaymentMethod[]} accounts - list of ways a customer could pay (prepaid, credit card, mobile wallets etc)
   * @param {string} paymentMethodId - attempt to use this as the default payment method
   * @param {string} prepaidPaymentMethodId - attempt to use this as the default prepaid payment method
   */
  const initPaymentMethods = useCallback(
    (
      { accounts, paymentMethodId, prepaidPaymentMethodId }: IInitPaymentMethods,
      shouldMuteUpdateUserInfoErrors?: boolean
    ) => {
      // Get valid payment methods
      const {
        availablePaymentMethodList,
        validDefaultPaymentMethodId,
        validDefaultReloadPaymentMethodId,
      } = getPaymentMethodsState({
        paymentMethodId,
        prepaidPaymentMethodId,
        accounts,
        canUseApplePay,
        enableCashPayment,
        enableCashApp,
        enablePayPal,
        enableVenmo,
        applePayCardDetails,
        canUseGooglePay,
        googlePayCardDetails,
        paymentMethodHasBeenInit,
        braintreeAuthorizationKey,
      });

      // Setup the payment methods into a valid state
      setPaymentMethods(old => {
        // if old 0 new 0 no need to cause re-renders
        if (old?.length === 0 && availablePaymentMethodList?.length === 0) {
          return old;
        }
        return availablePaymentMethodList;
      });
      updatePaymentMethodsState(
        validDefaultPaymentMethodId,
        validDefaultReloadPaymentMethodId,
        shouldMuteUpdateUserInfoErrors
      );
    },
    [
      applePayCardDetails,
      braintreeAuthorizationKey,
      canUseGooglePay,
      canUseApplePay,
      enableCashPayment,
      enableCashApp,
      enablePayPal,
      enableVenmo,
      googlePayCardDetails,
      paymentMethodHasBeenInit,
      updatePaymentMethodsState,
    ]
  );

  useEffect(() => {
    // To avoid a race condition detected with the updateUserInfo while the user is re-authenticating, payment method initialization is skipped.
    // Additionally, we need to prevent the clearing of payment methods since we are not initializing them during user re-authentication.
    if (!user && !isReAuthenticating) {
      clearPaymentMethods();
      return;
    }
    if (userAccountsData) {
      const accounts: IPaymentMethod[] =
        (userAccountsData.userAccounts &&
          userAccountsData.userAccounts.accounts &&
          userAccountsData.userAccounts.accounts.filter<IPaymentMethod>(
            (account): account is IPaymentMethod =>
              !!account && (!!account.fdAccountId || !!account.accountIdentifier)
          )) ||
        [];

      setHasGetPaymentMethodsError(false);

      if (accounts.length) {
        storePrepaidCard({ accounts });
      }
    }
    setLoading(false);
  }, [clearPaymentMethods, user, userAccountsData, isReAuthenticating]);

  const { reloadPrepaidsCardMutation, loading: prepaidLoading } = usePrepaidsReload({
    openErrorDialog,
  });

  const getPrepaidPaymentMethod = useCallback(() => {
    return paymentMethods.find(method => Boolean(method.prepaid)) || null;
  }, [paymentMethods]);

  const getBalanceFromPaymentMethods = useCallback((prepaidPaymentMethod: IPaymentMethod) => {
    if (prepaidPaymentMethod && prepaidPaymentMethod.prepaid) {
      if (prepaidPaymentMethod.prepaid.feFormattedCurrentBalance) {
        return prepaidPaymentMethod.prepaid.feFormattedCurrentBalance;
      } else if (prepaidPaymentMethod.prepaid.currentBalance) {
        return prepaidPaymentMethod.prepaid.currentBalance;
      }
    }
    return 0;
  }, []);

  const getPrepaidCardNumber = useCallback(() => {
    const prepaidPaymentMethod = getPrepaidPaymentMethod();

    if (prepaidPaymentMethod && prepaidPaymentMethod.prepaid) {
      // add spaces between every 4 characters in the card number
      return (prepaidPaymentMethod.prepaid.cardNumber.match(/.{1,4}/g) || []).join(' ');
    }
    return null;
  }, [getPrepaidPaymentMethod]);

  const deletePaymentMethod = useCallback(
    async (accountIdentifier: string) => {
      try {
        setIsDeleting(true);
        const { data } = await fireDeleteAccountMutation({
          variables: { input: { storedPaymentMethodId: accountIdentifier } },
        });
        if (data?.deletePaymentMethod.success) {
          // Strip the paymentMethods list from the deleted CC
          const updatedPaymentMethods = paymentMethods.filter(
            method => method.accountIdentifier !== accountIdentifier
          );
          // If the default payment method is the deleted one, reset the payment method using the 1st in the updated array
          const paymentMethodIdAfterDelete =
            defaultAccountIdentifier !== accountIdentifier
              ? defaultAccountIdentifier
              : updatedPaymentMethods[0]?.accountIdentifier;

          // Re-init the payment methods state to make sure we're in a fully working state after deleting a CC
          initPaymentMethods({
            accounts: updatedPaymentMethods,
            paymentMethodId: paymentMethodIdAfterDelete || '',
            prepaidPaymentMethodId: paymentMethodIdAfterDelete || '',
          });

          deletePaymentMethodEvent(true);
        }
      } catch (error) {
        logger.error({ error, message: 'Error deleting payment method' });
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        deletePaymentMethodEvent(false, error.message);
        openErrorDialog({
          message: formatMessage({ id: 'paymentDeletingError' }),
          modalAppearanceEventMessage: 'Error: Deleting Payment Method Failure',
          // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Error | ... Remove this comment to see the full error message
          error,
        });
      } finally {
        setIsDeleting(false);
      }
    },
    [
      fireDeleteAccountMutation,
      paymentMethods,
      defaultAccountIdentifier,
      initPaymentMethods,
      deletePaymentMethodEvent,
      openErrorDialog,
      formatMessage,
    ]
  );

  const paymentProcessor = userAccountsData?.userAccounts?.paymentProcessor;
  const isFirstData = paymentProcessor === PaymentProcessor.FIRSTDATA;
  const isOrbital = paymentProcessor === PaymentProcessor.ORBITAL;

  // Expected value that is applied to hidden form fields for payment method
  const hiddenFieldValue = null;

  const transformPaymentValues = useCallback(
    ({
      paymentValues,
      paymentFieldVariations = defaultPaymentFieldVariation,
    }: {
      paymentValues: IPaymentState;
      paymentFieldVariations?: PaymentFieldVariations;
    }): IPaymentPayload => {
      let expiryDate = null;
      const cardNumber = sanitizeNumber(paymentValues.cardNumber);
      const { expiryMonth, expiryYear } = splitExpiry(paymentValues.expiry ?? '');
      expiryDate = paymentValues.expiry
        ? {
            month: expiryMonth,
            year: expiryYear,
          }
        : null;

      const country = paymentValues.billingCountry ? ISOs[paymentValues.billingCountry] : null;
      const postalCode = sanitizeAlphanumeric(paymentValues.billingZip);
      return {
        billingAddress: {
          country: paymentFieldVariations.country ? country : hiddenFieldValue,
          postalCode: paymentFieldVariations.zip ? postalCode : '',
          unitNumber: paymentFieldVariations.addressLine2
            ? paymentValues.billingApt
            : hiddenFieldValue,
          locality: paymentFieldVariations.city ? paymentValues.billingCity : hiddenFieldValue,
          region: paymentFieldVariations.state ? paymentValues.billingState : hiddenFieldValue,
          streetAddress: paymentFieldVariations.addressLine1
            ? paymentValues.billingStreetAddress
            : hiddenFieldValue,
        },
        cardNumber,
        cardType: paymentValues.cardType ?? '',
        expiryDate,
        nameOnCard: paymentValues.nameOnCard,
        securityCode: paymentValues.cvv,
      };
    },
    [hiddenFieldValue]
  );

  const [encryptionResult, setEncryptionResult] = useState<IEncryptionResult | undefined>();

  const addPaymentMethod = useCallback(
    async (
      paymentValues: IPaymentPayload,
      { skipErrorDialogOnError = false }: IAddPaymentMethodOptions = {},
      encryptionResult?: IEncryptionResult
    ) => {
      const { billingAddress, cardNumber, nameOnCard: fullName } = paymentValues;
      const { accountToDelete, ...cleanedPaymentValues } = paymentValues;

      if (!loading) {
        setLoading(true);
      }

      try {
        // If we're re-vaulting, delete the payment method before adding the new one
        if (accountToDelete) {
          await deletePaymentMethod(accountToDelete);
        }

        let addAccount;
        let accountIdentifier;

        // Handles adding a payment method when the payment processor is Orbital
        if (isOrbital) {
          if (!encryptionResult) {
            throw new Error(
              'Orbital encryption results encryption results not available for add payment method'
            );
          }
          const orbitalEncryptionResult = encryptionResult;

          if (!orbitalEncryptionResult || !('cryptCard' in orbitalEncryptionResult)) {
            throw new Error('Orbital Encryption Failure');
          }

          const orbitalInput: IAddOrbitalAccountInput = {
            encryptedCardNum: orbitalEncryptionResult.cryptCard,
            encryptedCvv: orbitalEncryptionResult.cryptCvv,
            expiryMonth: paymentValues.expiryDate?.month ?? '',
            expiryYear: paymentValues.expiryDate?.year ?? '',
            pieFormat: orbitalEncryptionResult.pieFormat,
            pieMode: orbitalEncryptionResult.mode,
            piePhaseId: String(orbitalEncryptionResult.phase),
            pieIntegrityCheck: orbitalEncryptionResult.integrityCheck,
            pieSubscriberId: orbitalEncryptionResult.subscriberId,
            pieKeyID: orbitalEncryptionResult.keyId,
            cardBrand: paymentValues.cardType.toLowerCase(),
            bin: cardNumber.substring(0, 6),
          };
          const addAccountResponse = await addCreditAccountMutation({
            variables: {
              input: {
                billingAddress,
                orbitalInput,
                fullName: paymentValues.nameOnCard,
              },
            },
          });

          // Assertion of minimum data for add account.
          if (!addAccountResponse.data?.addCreditAccount.accountIdentifier) {
            throw new Error('Add Account Failure');
          }

          addAccount = addAccountResponse.data.addCreditAccount;
          accountIdentifier = addAccount.accountIdentifier ?? '';
        } else {
          // Handles adding a payment method when the payment processor is First Data
          const ccBin = cardNumber.slice(0, 6);

          let firstDataInput: IAddAccountInput = { ccBin: '', fdAccessToken: '', fdNonce: '' };
          let addAccountResponse: any = {};

          // If we're re-vaulting, delete the payment method before adding the new one
          if (accountToDelete) {
            await deletePaymentMethod(accountToDelete);
          }

          const {
            fdPublicKey,
            fdApiKey,
            fdAccessToken,
            fdCustomerId, // Necessary second request
            algorithm,
          } = await getEncryptionDetails();

          if (!fdAccessTokenFirstRequest.current) {
            fdAccessTokenFirstRequest.current = fdAccessToken;
          }

          const nonceResponse = await getNonce(
            cleanedPaymentValues,
            fdPublicKey,
            fdApiKey,
            fdAccessToken,
            // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null | undefined' is no... Remove this comment to see the full error message
            fdCustomerId,
            fdUrl,
            onlySendPostalCode,
            algorithm
          );
          const { token } = await nonceResponse.json();
          const fdNonce = token.tokenId;

          firstDataInput = {
            fdAccessToken,
            fdNonce,
            ccBin,
          };

          try {
            addAccountResponse = await addCreditAccountMutation({
              variables: {
                input: {
                  billingAddress,
                  firstDataInput,
                  fullName,
                },
              },
            });
          } catch (error) {
            throw error;
          }

          // Assertion of minimun data for add account.
          if (
            !addAccountResponse.data?.addCreditAccount.accountIdentifier &&
            !addAccountResponse.data?.addCreditAccount.fdAccountId
          ) {
            throw new Error('Add Account Failure');
          }

          addAccount = addAccountResponse.data.addCreditAccount;
          accountIdentifier = addAccount.accountIdentifier ?? addAccount.fdAccountId ?? '';
        }

        saveNewPaymentMethodEvent(true, undefined, { accountIdentifier });

        logRBIEvent(
          {
            name: CustomEventNames.NEW_PAYMENT_METHOD_SAVED,
            type: EventTypes.Other,
            attributes: {
              paymentMethod: 'CreditCard',
              cardBrand: addAccount?.credit?.cardType,
            },
          },
          { skipLoggingToBraze: true }
        );

        // Re-init the payment methods state to make sure we're in a fully working state after adding a CC
        initPaymentMethods({
          accounts: [...paymentMethods, addAccount as IPaymentMethod],
          paymentMethodId: accountIdentifier,
          prepaidPaymentMethodId: accountIdentifier,
        });

        // When a user adds a CC, set the Checkout and Reload Default payment method to the newly added one
        await setAndLogDefaultPaymentMethodId(accountIdentifier, true);

        return accountIdentifier;
      } catch (error) {
        logger.error({
          error,
          message: 'Error adding payment method',
        });
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        saveNewPaymentMethodEvent(false, error.message, {
          accountIdentifier: undefined,
          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
          message: error.message,
        });
        if (!skipErrorDialogOnError) {
          const statusCode:
            | number
            // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
            | undefined = error.graphQLErrors?.find((graphQLError: GraphQLError) =>
            Number.isInteger(graphQLError?.extensions?.statusCode)
          )?.extensions?.statusCode;
          openErrorDialog({
            message:
              statusCode === HttpErrorCodes.TooManyRequests
                ? formatMessage({ id: 'tooManyAddAccountAttempts' })
                : formatMessage({ id: 'paymentAddingError' }),
            modalAppearanceEventMessage: 'Error: Adding Payment Method Failure',
            // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Error | ... Remove this comment to see the full error message
            error,
          });
        }
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [
      addCreditAccountMutation,
      deletePaymentMethod,
      fdUrl,
      formatMessage,
      getEncryptionDetails,
      initPaymentMethods,
      isOrbital,
      loading,
      onlySendPostalCode,
      openErrorDialog,
      paymentMethods,
      saveNewPaymentMethodEvent,
      setAndLogDefaultPaymentMethodId,
    ]
  );

  const reloadPrepaidCard = useCallback(
    async (reloadInfo: IReloadPrepaidCard) => {
      try {
        const { currencyCode, cardType, fundValue } = reloadInfo;

        const { data } = await reloadPrepaidsCardMutation(reloadInfo);

        logEvent(CustomEventNames.BUTTON_CLICK_TIMS_CARD_RELOAD, EventTypes.Transaction, {
          Currency: currencyCode,
          'Card Type': cardType,
          'Reload Amount': fundValue / 100,
        });

        return data?.prepaidsReload?.currentBalance;
      } catch (error) {
        logger.error({
          error,
          message: 'Error reloading prepaid card',
        });
        return undefined;
      }
    },
    [logEvent, reloadPrepaidsCardMutation]
  );

  const mergePrepaidCardBalances = useCallback(
    async ({ sourceCardNumber, sourcePin }: IMergePrepaidInput): Promise<IMergePrepaidPayload> => {
      if (!loading) {
        setLoading(true);
      }

      try {
        const { data: wrapper } = await fireMergePrepaidMutation({
          variables: {
            input: {
              sourceCardNumber: sourceCardNumber.replace(/\s/g, ''),
              sourcePin,
            },
          },
        });

        const data = wrapper!.mergePrepaid;

        await getPaymentMethods();

        // Set the newly added GC, or the desitination GC as the checkout payment method.
        setCheckoutPaymentMethodId(data.storedPaymentMethodId || '');

        return data;
      } finally {
        setLoading(false);
      }
    },
    [fireMergePrepaidMutation, getPaymentMethods, loading]
  );

  const checkoutPaymentMethod = paymentMethods.find(
    ({ accountIdentifier, fdAccountId }) =>
      accountIdentifier === checkoutPaymentMethodId || fdAccountId === checkoutPaymentMethodId
  );

  useEffect(
    () => {
      // Initialize the payment method when the app loads
      if (userAccountsData?.userAccounts?.accounts && !isReAuthenticating) {
        const { accounts } = userAccountsData.userAccounts;

        /**
         * Unlike Apple Pay and Google Pay, the cash method can be a default payment method
         * so add it before `initPaymentMethods` runs so it can be sorted & stored as the default method in FE state
         */
        const accountsMaybeWithCash = enableCashPayment ? [...accounts, cashAccount] : accounts;

        setPaymentMethodHasBeenInit(true);
        // When we have the user's payment method list, initialize the payment methods state
        initPaymentMethods(
          {
            accounts: accountsMaybeWithCash as IPaymentMethod[],
            paymentMethodId: defaultAccountIdentifier || '',
            prepaidPaymentMethodId: defaultPrepaidPaymentMethodId || '',
          },
          true
        );
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      cognitoId,
      enableCashPayment,
      defaultAccountIdentifier,
      userAccountsData?.userAccounts?.accounts,
      enablePayPal,
      enableVenmo,
    ]
    /* eslint-enable react-hooks/exhaustive-deps */
  );

  return {
    loading: loading || accountsLoading || prepaidLoading || isDeleting,
    paymentMethods,
    getPaymentMethods,
    getBalanceFromPaymentMethods,
    getPrepaidPaymentMethod,
    getPrepaidCardNumber,
    getEncryptionDetails,
    checkoutPaymentMethod,
    checkoutPaymentMethodId,
    setCheckoutPaymentMethodId: setAndLogCheckoutPaymentMethodId,
    defaultPaymentMethodId,
    defaultReloadPaymentMethodId,
    encryptionResult,
    setEncryptionResult,
    setDefaultPaymentMethodId: setAndLogDefaultPaymentMethodId,
    setDefaultReloadPaymentMethodId: setAndLogDefaultReloadPaymentMethodId,
    setPrepaidReloadPaymentMethodId,
    prepaidReloadPaymentMethodId,
    addPaymentMethod,
    deletePaymentMethod,
    reloadPrepaidCard,
    mergePrepaidCardBalances,
    hasGetPaymentMethodsError,
    canUseApplePay,
    canUseGooglePay,
    paymentProcessor,
    isFirstData,
    isOrbital,
    transformPaymentValues,
    setLoading,
  };
};

export default usePayment;
