//import logger from 'utils/logger';

import { computeSimpleModifierPlu } from './compute-simple-modifier-plu';
import { concatenateSizePlu } from './get-size-based-plu';
import { getVendorConfig } from './get-vendor-config';
import { shouldUseMainItemCompositePlu } from './should-use-main-item-composite-plu';
import { IComboWithVendorConfig, IWithVendorConfig, PluTypes, PosVendors } from './types';

interface IComputeCompositeModifierPlu {
  item: IWithVendorConfig | IComboWithVendorConfig;
  modifier: IWithVendorConfig;
  vendor: PosVendors | null;
}

const computeParentPluForComposite = (
  parent: IWithVendorConfig | IComboWithVendorConfig,
  vendor: PosVendors | null,
  mainItemPlu: string | null
): string | null => {
  const vendorConfig = getVendorConfig(parent, vendor);
  if (!vendorConfig) {
    return null;
  }

  let plu;
  switch (vendorConfig.pluType) {
    case PluTypes.CONSTANT: {
      plu = vendorConfig.constantPlu;
      break;
    }
    case PluTypes.SIZE_BASED: {
      plu = concatenateSizePlu(vendorConfig.sizeBasedPlu) ?? null;

      // Some POS Vendors append the mainItem PLU
      if (plu && mainItemPlu) {
        plu = `${plu}-${mainItemPlu}`;
      }

      break;
    }
  }

  // if (!plu) {
  //   logger.warn({
  //     message: `Unable to compute parent item PLU with PluType ${vendorConfig.pluType}`,
  //     item: parent,
  //   });
  // }
  return plu ?? null;
};

const computeChildPluForComposite = (
  child: IWithVendorConfig,
  vendor: PosVendors | null,
  mainItemPlu: string | null
): string | null => {
  const vendorConfig = getVendorConfig(child, vendor);
  if (!vendorConfig) {
    return null;
  }

  let plu;
  switch (vendorConfig.pluType) {
    case PluTypes.CONSTANT: {
      plu = vendorConfig.constantPlu;
      break;
    }
    case PluTypes.MULTI_CONSTANT: {
      const [firstPlu] = vendorConfig.multiConstantPlus || [];
      plu = firstPlu?.plu ? firstPlu.plu : null;
      break;
    }
    case PluTypes.PARENT_CHILD: {
      const { parentChildPlu } = vendorConfig;
      if (parentChildPlu?.plu && parentChildPlu?.childPlu) {
        // If mainItemPlu was already appended, this modifier is identified by parentPlu
        if (mainItemPlu) {
          plu = parentChildPlu.plu;
        } else {
          plu = `${parentChildPlu.plu}-${parentChildPlu.childPlu}`;
        }
      }
      break;
    }
  }

  // Disable logging because it was triggered too many times
  // if (!plu) {
  //   logger.warn({
  //     message: `Unable to compute modifier PLU with PluType ${vendorConfig.pluType}`,
  //     modifier: child,
  //   });
  // }
  return plu ?? null;
};

const computeMainItemPluForComposite = (
  item: IWithVendorConfig | IComboWithVendorConfig,
  vendor: PosVendors | null
): string | null => {
  if (shouldUseMainItemCompositePlu(vendor) && 'mainItem' in item && !!item.mainItem) {
    return computeSimpleModifierPlu({ modifier: item.mainItem, vendor });
  }
  return null;
};

/**
 * Computes a plu for a modifier using its parent item's plu.
 * item (parent) - constantPlu 123
 * modifier (child) - constantPlu 345
 *  returns 123-345
 *
 * currently this is only supported if:
 *   - the parent item has a constant plu OR it's a combo with size based plu
 *   - the modifier has a constant or multi-constant plu
 *
 * @returns {string | null} the composite plu or null
 */
export const computeCompositeModifierPlu = ({
  item,
  modifier,
  vendor,
}: IComputeCompositeModifierPlu): string | null => {
  const mainItemPlu = computeMainItemPluForComposite(item, vendor);

  const parentPlu = computeParentPluForComposite(item, vendor, mainItemPlu);
  if (!parentPlu) {
    return null;
  }

  const childPlu = computeChildPluForComposite(modifier, vendor, mainItemPlu);
  if (!childPlu) {
    return null;
  }

  return `${parentPlu}-${childPlu}`;
};
