import { Platform } from 'react-native';
import * as semver from 'semver';

import { appVersion } from 'utils/app-version-info';
import { isMobile, isWeb, region } from 'utils/environment';
import { Region } from 'utils/environment/types';

const MINIMUM_ANDROID_VERSION = '7.11.0';
const MINIMUM_IOS_VERSION = '7.13.0';

export const isPayPalSupported = (): boolean => {
  // PayPal is only supported in the US
  if (region !== Region.US) {
    return false;
  }

  // Validate app version
  if (!semver.valid(appVersion)) {
    return false;
  }

  // Check minimum Android version
  if (Platform.OS === 'android' && semver.lt(appVersion, MINIMUM_ANDROID_VERSION)) {
    return false;
  }

  // Check minimum iOS version
  if (Platform.OS === 'ios' && semver.lt(appVersion, MINIMUM_IOS_VERSION)) {
    return false;
  }

  return true;
};

export const isVenmoSupported = (): boolean => {
  // Venmo is only supported in the US
  if (region !== Region.US) {
    return false;
  }

  // Validate app version
  if (!semver.valid(appVersion)) {
    return false;
  }

  // Venmo is not supported on mobile web environments
  if (isMobile() && isWeb) {
    return false;
  }

  // Check minimum Android version
  if (Platform.OS === 'android' && semver.lt(appVersion, MINIMUM_ANDROID_VERSION)) {
    return false;
  }

  // Check minimum iOS version
  if (Platform.OS === 'ios' && semver.lt(appVersion, MINIMUM_IOS_VERSION)) {
    return false;
  }

  return true;
};
