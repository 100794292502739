import { useEffect, useState } from 'react';

import { Platform } from 'react-native';

import { useAttPermission } from 'state/app-tracking-transparency-permissions/common';
import { useGeolocation } from 'state/geolocation';
import { OsPrompts } from 'state/location/routes';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { addOrUpdateScreenState } from 'state/onboarding/utils';
import { usePushPermission } from 'state/push-permissions';
import { isIOS, isWeb } from 'utils/environment';
import LocalStorage from 'utils/local-storage';
import { Keys } from 'utils/local-storage/constants';

import { Channel, OnboardingScreen, OnboardingStorage, Onboardings, TriggerRule } from '../types';

import { useOnboardingScreens } from './use-onboarding-screens';

export const useOnboardingContent = () => {
  const onboardingState: OnboardingStorage | null = LocalStorage.getItem(Keys.ONBOARDING_STATE);
  const channel = isWeb ? Channel.WEB : isIOS() ? Channel.IOS : Channel.ANDROID;
  const [triggerRule, setTriggerRule] = useState<string>(TriggerRule.ON_FIRST_APP_LOAD_NEW_USER);
  const [onboardings, setOnboardings] = useState<Onboardings>();
  const [onboardingContentData, setOnboardingContentData] = useState<any>({
    firstAppLoadOnboarding: {
      id: '',
      featureOnboardingChannel: '',
      featureOnboardingTriggerRule: '',
      featureOnboardingScreens: [],
    },
    triggerRule,
  });

  const {
    isPermissionKnown: isLocationPermissionKnown,
    isLoadingPermission: isLoadingLocationPermission,
  } = useGeolocation();
  const { isPushPermissionKnown, isLoadingPushPermission, settings } = usePushPermission();
  const { featureOnboardings, loading: isOnboardingScreensLoading } = useOnboardingScreens();
  const { isAttPermissionKnown, isLoadingAttPermission } = useAttPermission();
  const { logRBIEvent } = useMParticleContext();

  useEffect(() => {
    if (!onboardings) {
      setOnboardings(featureOnboardings);
      logRBIEvent({
        name: CustomEventNames.ONBOARDING_DATA,
        type: EventTypes.Other,
        attributes: {
          data: JSON.stringify({
            screensReceived: featureOnboardings?.[0]?.featureOnboardingScreens?.map(
              screen => screen.id
            ),
            savedState: onboardingState?.onboardingDocs,
          }),
        },
      });
    }
  }, [featureOnboardings]);

  const firstAppLoadOnboarding = onboardings?.find(
    onboarding =>
      onboarding.featureOnboardingChannel === channel &&
      onboarding.featureOnboardingTriggerRule === triggerRule
  );

  const everyScreenSavedIsCompleted = onboardingState?.onboardingDocs
    .filter(doc => doc.id === triggerRule)
    .every(doc => doc.screens.every(screen => screen.completed));

  const onboardingDocsScreensIds = new Set(
    onboardingState?.onboardingDocs
      ?.find(doc => doc.id === triggerRule)
      ?.screens.map(screen => screen.id)
  );

  const everySanityScreenIsCompleted = firstAppLoadOnboarding?.featureOnboardingScreens
    ?.map(docScreen => docScreen.id)
    ?.every(id => id && onboardingDocsScreensIds.has(id));

  useEffect(() => {
    if (
      isLoadingPushPermission ||
      isLoadingLocationPermission ||
      isLoadingAttPermission ||
      isOnboardingScreensLoading
    ) {
      return;
    }
    // filter screens that asks for permission that we already have
    if (firstAppLoadOnboarding && firstAppLoadOnboarding?.featureOnboardingScreens) {
      firstAppLoadOnboarding.featureOnboardingScreens = firstAppLoadOnboarding.featureOnboardingScreens.filter(
        screen => {
          switch (screen.buttonUrl) {
            case `/OS/${OsPrompts.push}`:
              if (isPushPermissionKnown && settings?.granted) {
                addOrUpdateScreenState(triggerRule || '', screen, screen.buttonUrl);
              }
              return (!isPushPermissionKnown || settings?.canAskAgain) && !settings?.granted;
            case `/OS/${OsPrompts.location}`:
              if (isLocationPermissionKnown) {
                addOrUpdateScreenState(triggerRule || '', screen, screen.buttonUrl);
              }
              return !isLocationPermissionKnown;
            case `/OS/${OsPrompts.att}`:
              if (Platform.OS !== 'ios') {
                addOrUpdateScreenState(triggerRule || '', screen, screen.buttonUrl);
                return false;
              }
              if (isAttPermissionKnown) {
                addOrUpdateScreenState(triggerRule || '', screen, screen.buttonUrl);
              }
              return !isAttPermissionKnown;
            default:
              return true;
          }
        }
      );
    }

    // remove screen completed
    if (
      onboardingState &&
      firstAppLoadOnboarding &&
      firstAppLoadOnboarding?.featureOnboardingScreens
    ) {
      firstAppLoadOnboarding.featureOnboardingScreens = firstAppLoadOnboarding.featureOnboardingScreens.filter(
        screen => {
          const completed = onboardingState?.onboardingDocs
            ?.find(doc => doc?.id === firstAppLoadOnboarding?.featureOnboardingTriggerRule)
            ?.screens.some((value: OnboardingScreen) => {
              return value.id === screen.id && value.completed;
            });
          return !completed;
        }
      );
    }
    if (
      !firstAppLoadOnboarding &&
      (everySanityScreenIsCompleted ||
        isLoadingPushPermission ||
        isLoadingLocationPermission ||
        isLoadingAttPermission)
    ) {
      setOnboardingContentData({
        firstAppLoadOnboarding: {
          id: '',
          featureOnboardingChannel: '',
          featureOnboardingTriggerRule: '',
          featureOnboardingScreens: [],
        },
        triggerRule,
      });
    } else {
      setOnboardingContentData({
        firstAppLoadOnboarding,
        triggerRule,
      });
    }
    // we need to execute this hook just when triggerRule or firstAppLoadOnboarding change
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [triggerRule, firstAppLoadOnboarding]);

  useEffect(() => {
    if (
      isLoadingPushPermission ||
      isLoadingLocationPermission ||
      isLoadingAttPermission ||
      isOnboardingScreensLoading
    ) {
      return;
    }

    if (
      everySanityScreenIsCompleted &&
      everyScreenSavedIsCompleted &&
      triggerRule === TriggerRule.ON_FIRST_APP_LOAD_NEW_USER
    ) {
      setTriggerRule(TriggerRule.ON_FIRST_APP_LOAD_EXISTING_USER);
    }

    // We do not want the triggerRule to change once defined or while permissions are loading
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    triggerRule,
    isLoadingLocationPermission,
    isLoadingPushPermission,
    isLoadingAttPermission,
    firstAppLoadOnboarding,
    everySanityScreenIsCompleted,
    everyScreenSavedIsCompleted,
  ]);

  return onboardingContentData;
};
