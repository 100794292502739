import { IDayPartsOperatingHoursFragment } from 'generated/graphql-gateway';

import { DAY_PART_IDS, DAY_PART_SELECTIONS, IValidDayPart } from './types';

/**
 * Returns the current day of the week as a three-letter abbreviation.
 *
 * @returns {string} today's weekday as an abbreviation
 */
export const todayWeekdayAbbreviation = (): string => {
  const today = new Date();
  const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'];
  const dayIndex = today.getDay();
  return daysOfWeek[dayIndex];
};

/**
 * Returns the day part selection based on the day part id.
 *
 * @param id - Day part id
 * @returns Day part selection key
 */
export function retrieveDayPartKeyFromId(id?: string | null): string {
  const idToSelectionMap: { [key: string]: string } = {
    [DAY_PART_IDS.ALL_KEY]: DAY_PART_SELECTIONS.ALL_KEY,
    [DAY_PART_IDS.BREAKFAST_KEY]: DAY_PART_SELECTIONS.BREAKFAST_KEY,
    [DAY_PART_IDS.BURGERS_FOR_BREAKFAST]: DAY_PART_SELECTIONS.BURGERS_FOR_BREAKFAST,
    [DAY_PART_IDS.MAIN_MENU_KEY]: DAY_PART_SELECTIONS.MAIN_MENU_KEY,
  };

  return id && idToSelectionMap.hasOwnProperty(id) ? idToSelectionMap[id] : '';
}

/**
 * Remaps the store's day parts to the IValidDayPart type.
 *
 * @param dayParts - Store day parts
 * @returns Day parts as an IValidDayParts array
 */
export const remapDayParts = (
  dayParts: IDayPartsOperatingHoursFragment[] | undefined | null
): IValidDayPart[] => {
  const today = todayWeekdayAbbreviation();

  const remappedDayParts =
    dayParts?.map(dayPart => {
      let dayPartDisplayName = dayPart.displayName;

      if (!dayPartDisplayName) {
        // If the day part display name is undefined or null, use the id to get the display name.
        dayPartDisplayName = retrieveDayPartKeyFromId(dayPart.id);
      }

      return {
        displayName: { locale: dayPartDisplayName },
        key: dayPartDisplayName as DAY_PART_SELECTIONS,
        endTime: dayPart[`${today}Close`],
        startTime: dayPart[`${today}Open`],
      };
    }) || [];

  return remappedDayParts;
};
