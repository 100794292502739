import React, {
  ComponentType,
  Dispatch,
  SetStateAction,
  createContext,
  memo,
  useContext,
  useEffect,
  useState,
} from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import { useRoute } from 'hooks/navigation/use-route';
import useEffectOnUnmount from 'hooks/use-effect-on-unmount';
import { useEffectOnUrlChange } from 'hooks/use-effect-on-url-change';
import { useIsMobileBreakpoint } from 'hooks/use-media-query';
import { routes } from 'utils/routing';
import { useMemoAll } from 'utils/use-memo-all';

type PortaledComponent = null | (() => JSX.Element);

export interface IMobileHeaderNavContext {
  BackButtonComponent: PortaledComponent;
  __setBackButtonComponent: Dispatch<SetStateAction<PortaledComponent>>;
  HeaderComponent: PortaledComponent;
  __setHeaderComponent: Dispatch<SetStateAction<PortaledComponent>>;
  hideHeaderExceptNavigation: boolean;
  __setHideHeaderExceptNavigation: Dispatch<SetStateAction<boolean>>;
}

export const MobileHeaderNavContext = createContext<IMobileHeaderNavContext>({
  BackButtonComponent: null,
  __setBackButtonComponent: () => null,
  HeaderComponent: null,
  __setHeaderComponent: () => null,
  hideHeaderExceptNavigation: false,
  __setHideHeaderExceptNavigation: () => false,
});

/**
 * Caution - We need to render the portalHeader component as soon as possible to ensure
 * the correct header is sent to the portal. Otherwise, default header components
 * may be visible while the page is loading.
 *
 */
export function portalToHeader<Props>(Component: ComponentType<React.PropsWithChildren<Props>>) {
  return memo<ComponentType<Props>>(function PortalToHeader(props: Props) {
    const { __setBackButtonComponent } = useContext(MobileHeaderNavContext);

    useEffect(() => {
      __setBackButtonComponent(() => () => (
        // @ts-expect-error TS(2322) Type 'Props' is not assignable to type 'IntrinsicAttributes & Props & { children?: ReactNode; }'.
        <Component {...props} />
      ));
    }, [props, __setBackButtonComponent]);

    useEffectOnUnmount(() => {
      __setBackButtonComponent(null);
    });

    return null;
  });
}

/**
 * Caution - We need to render the portalToReplaceHeader component as soon as possible to ensure
 * the correct header is sent to the portal. Otherwise, default header components
 * may be visible while the page is loading.
 *
 */
export function portalToReplaceHeader<Props>(
  Component: ComponentType<React.PropsWithChildren<Props>>
) {
  return memo<ComponentType<Props>>(function PortalToHeader(props: Props) {
    const { __setHeaderComponent } = useContext(MobileHeaderNavContext);
    const { pathname } = useRoute();

    useEffectOnUrlChange(() => {
      // @ts-expect-error TS(2322) Type 'Props' is not assignable to type 'IntrinsicAttributes & Props & { children?: ReactNode; }'.
      __setHeaderComponent(() => () => <Component {...props} />);
    });

    useEffectOnUnmount(() => {
      __setHeaderComponent(null);
    });

    // This is needed bc on cart page if we change the service mode the header doesn't get updated
    // also needed on store locator bc the toggle button for map to list doesn't work just with the first click
    // https://rbictg.atlassian.net/browse/BKPE-5740
    useEffect(() => {
      if ([routes.cart, routes.storeLocator, routes.serviceMode].includes(pathname)) {
        // @ts-expect-error TS(2322) Type 'Props' is not assignable to type 'IntrinsicAttributes & Props & { children?: ReactNode; }'.
        __setHeaderComponent(() => () => <Component {...props} />);
      }
    }, [__setHeaderComponent, pathname, props]);

    return null;
  });
}

export const MobileHeaderNavProvider = ({ children }: IBaseProps) => {
  const isBackButtonVisible = useIsMobileBreakpoint();
  const [BackButtonComponent, __setBackButtonComponent] = useState<PortaledComponent>(null);
  const [HeaderComponent, __setHeaderComponent] = useState<PortaledComponent>(null);
  const [hideHeaderExceptNavigation, __setHideHeaderExceptNavigation] = useState<boolean>(false);

  // When the viewport is no longer mobile, we need to clear the back button from memory
  // as it should not be rendered any longer.
  useEffect(() => {
    if (!isBackButtonVisible) {
      __setBackButtonComponent(null);
    }
  }, [isBackButtonVisible]);

  const value = useMemoAll({
    BackButtonComponent,
    __setBackButtonComponent,
    HeaderComponent,
    __setHeaderComponent,
    hideHeaderExceptNavigation,
    __setHideHeaderExceptNavigation,
  });

  return (
    <MobileHeaderNavContext.Provider value={value}>{children}</MobileHeaderNavContext.Provider>
  );
};

export const useMobileHeaderBackButton = () =>
  useContext(MobileHeaderNavContext).BackButtonComponent;

export const useMobileHeaderComponent = () => useContext(MobileHeaderNavContext).HeaderComponent;
